import { useState, useEffect, useMemo, useCallback } from "react";
import Avatar from "avataaars";
import { Image } from "react-bootstrap";

import axios from "axios";
import styles from "./styles.module.scss";
import options from "./options";

function randomItem(items) {
  return items[Math.floor(Math.random() * items.length)];
}

function getOpt(what) {
  return randomItem(options[what]);
}

export default function AvatarBox({
  src,
  className,
  width = "28px",
  height = "28px",
  marginRight = "6px",
  verticalAlign = "middle",
}) {
  const [url, setUrl] = useState();
  const [ts, setTs] = useState();

  const style = useMemo(() => ({ width, height, marginRight, verticalAlign, cursor: "pointer" }), [
    height,
    marginRight,
    verticalAlign,
    width,
  ]);

  useEffect(() => {
    if (url === undefined) {
      axios
        .get(src)
        .then((response) => {
          setUrl(response.request.responseURL);
        })
        .catch((error) => setUrl(false));
    }
  }, [src, url]);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setTs(Date.now());
  }, []);

  const avatar = useMemo(() => {
    return (
      <Avatar
        style={style}
        avatarStyle="Transparent"
        topType={getOpt("topType")}
        accessoriesType={getOpt("accessoriesType")}
        hairColor={getOpt("hairColor")}
        facialHairType={getOpt("facialHairType")}
        clotheType={getOpt("clotheType")}
        //clotheColor={getOpt("clotheColor")}
        eyeType={getOpt("eyeType")}
        eyebrowType={getOpt("eyebrowType")}
        mouthType={getOpt("mouthType")}
        skinColor={getOpt("skinColor")}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style, ts]);

  if (url) return <Image src={url} style={style} className={`${className} ${styles.picture}`} />;

  return <span onClick={onClick}>{avatar}</span>;
}
