import { useMemo, useState, useCallback } from "react";
import { useFormikContext } from "formik";
import { Dropdown } from "react-bootstrap";
import { db } from "../../db";
import Select from "../selects/selectWithSearch";
import { Transformer } from "./utils";

const strip = (value) => {
  if (typeof value === "string") return String(value).replace(/"/g, "");
  return value;
};

export function ManualKeySelect({ handleChange, className, value, excludes = [] }) {
  const { values } = useFormikContext();
  const [filter, setFilter] = useState("");
  const data = useMemo(
    () =>
      Object.keys(values.keysAndValues?.keys ?? {})
        .filter(
          (k) =>
            values.keysAndValues?.keys?.[k]?.active && !excludes.includes(k) && k.includes(filter)
        )
        .map((key, index) => (
          <Dropdown.Item eventKey={key} key={`${key}-${index}`} value={key}>
            {key}
          </Dropdown.Item>
        )),
    [excludes, filter, values.keysAndValues?.keys]
  );

  return (
    <Select
      name={`select-key`}
      value={value}
      disabled={false}
      canFilter={true}
      onSelect={handleChange}
      onFilter={(f) => setFilter(f)}
      className={className}
    >
      {data}
    </Select>
  );
}

export function ManualValueSelect({ chiave, handleChange, className, value, style }) {
  const { values } = useFormikContext();
  const data = useMemo(
    () =>
      values.keysAndValues?.keys?.[chiave]?.values?.map((key, index) => (
        <Dropdown.Item eventKey={key} key={`${key}-${index}`} value={key}>
          {key}
        </Dropdown.Item>
      )),
    [chiave, values.keysAndValues?.keys]
  );

  return (
    <Select
      name={`select-key`}
      value={value}
      disabled={false}
      canFilter={true}
      onSelect={handleChange}
      className={className}
      style={style}
    >
      {data}
    </Select>
  );
}

export function SynthKeySelect({ value, handleChange, className, valori, excludes = [] }) {
  const { values: v } = useFormikContext();
  const values = useMemo(() => valori ?? v, [v, valori]);
  const [filter, setFilter] = useState("");
  const data = useMemo(() => {
    const options =
      values.synthesizer?.rules
        ?.filter(
          ({ active, key }) =>
            active &&
            key !== value &&
            !excludes.includes(key) &&
            String(key).toLowerCase().includes(filter.toLowerCase())
        )
        ?.map(({ key }, index) => (
          <Dropdown.Item eventKey={key} key={`${key}-${index}`} value={key}>
            {key}
          </Dropdown.Item>
        )) ?? [];

    options.unshift(<Dropdown.Item eventKey="" key="empty" value="" />);
    return options;
  }, [excludes, filter, value, values.synthesizer?.rules]);

  return (
    <Select
      name={`select-key`}
      value={value}
      disabled={false}
      canFilter={true}
      onSelect={handleChange}
      onFilter={(f) => setFilter(f)}
      className={className}
    >
      {data}
    </Select>
  );
}

export function SynthValuesSelect({
  chiave,
  handleChange,
  className,
  value,
  valori,
  excludes = [],
}) {
  const { values: v } = useFormikContext();
  const values = useMemo(() => valori ?? v, [v, valori]);
  const [filter, setFilter] = useState("");

  const data = useMemo(() => {
    if (values && chiave) {
      const trans = new Transformer(values.transformer?.rules);
      const valori = trans.transform(
        chiave,
        values?.keysAndValues?.mode === "manual"
          ? values?.keysAndValues?.keys?.[chiave]?.values
          : db.getFromId("metadata", chiave)?.values
      );

      const options = (valori ?? [])
        ?.filter(
          (key) =>
            key !== strip(value) &&
            !excludes.includes(key) &&
            String(key).toLowerCase().includes(filter.toLowerCase())
        )
        ?.map((key, index) => (
          <Dropdown.Item eventKey={key} key={`${key}-${index}`} value={key}>
            {key}
          </Dropdown.Item>
        ));

      options.unshift(<Dropdown.Item eventKey="" key="empty" value="" />);
      return options;
    }
  }, [chiave, excludes, filter, value, values]);

  const onSelect = useCallback(
    (value) => {
      let v = value;
      if (typeof value === "string" && value.split(" ").length > 1) v = `"${value}"`;
      handleChange(v);
    },
    [handleChange]
  );

  return (
    <Select
      name={`select-key`}
      value={strip(value)}
      disabled={false}
      canFilter={true}
      onSelect={onSelect}
      onFilter={(f) => setFilter(f)}
      className={className}
    >
      {data}
    </Select>
  );
}

export function TagsSelect({ value, handleChange, className, excludes = [] }) {
  const { values } = useFormikContext();
  const [filter, setFilter] = useState("");
  const data = useMemo(
    () =>
      values.tags?.rules
        ?.filter(
          ({ active, key }) =>
            active &&
            !excludes.includes(key) &&
            String(key).toLowerCase().includes(filter.toLowerCase())
        )
        ?.map(({ label, id }, index) => (
          <Dropdown.Item eventKey={id} key={`${id}-${index}`} value={id}>
            {label}
          </Dropdown.Item>
        )),
    [excludes, filter, values.tags?.rules]
  );

  const translate = useCallback(
    (id) => {
      const tag = values.tags?.rules?.find((t) => t.id === id);
      return tag?.label ?? "";
    },
    [values.tags?.rules]
  );

  return (
    <Select
      name={`select-key`}
      value={translate(value)}
      disabled={false}
      canFilter={true}
      onSelect={handleChange}
      onFilter={(f) => setFilter(f)}
      className={className}
    >
      {data}
    </Select>
  );
}
