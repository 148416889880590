/* eslint-disable default-case */
/* eslint-disable eqeqeq */
export class Transformer {
  constructor(rules = []) {
    this.rules = rules.filter((r) => r.active);
  }

  transformSingle(key, v, nullValue = null) {
    const rules = this.rules.filter((r) => r.key === key);
    const valore = v.value ? v.origin ?? v.value : v;

    if (rules.length > 0) {
      for (const { operator, action, value, subValue } of rules) {
        let valid = false;

        switch (operator) {
          case "equal": {
            valid = valore == value;
            break;
          }
          case "gt": {
            valid = valore > value;
            break;
          }
          case "gte": {
            valid = valore >= value;
            break;
          }
          case "lt": {
            valid = valore < value;
            break;
          }
          case "lte": {
            valid = valore <= value;
            break;
          }
        }

        if (valid) {
          switch (action) {
            case "substitute": {
              if (v.value) {
                v.value = subValue;
                return v;
              }
              return subValue;
            }
            case "filter": {
              return nullValue;
            }
          }
        }
      }
    }

    return v;
  }

  transform(key, values) {
    if (!values) return [];
    return [...new Set(values.map((v) => this.transformSingle(key, v)))].filter(Boolean);
  }

  transformX(key, values) {
    const rules = this.rules.filter((r) => r.key === key);

    if (rules.length > 0) {
      return [
        ...new Set(
          values
            .map((v) => {
              const valore = v.value ? v.origin ?? v.value : v;

              for (const { operator, action, value, subValue } of rules) {
                let valid = false;

                switch (operator) {
                  case "equal": {
                    valid = valore == value;
                    break;
                  }
                  case "gt": {
                    valid = valore > value;
                    break;
                  }
                  case "gte": {
                    valid = valore >= value;
                    break;
                  }
                  case "lt": {
                    valid = valore < value;
                    break;
                  }
                  case "lte": {
                    valid = valore <= value;
                    break;
                  }
                }

                if (valid) {
                  switch (action) {
                    case "substitute": {
                      if (v.value) {
                        v.value = subValue;
                        return v;
                      }
                      return subValue;
                    }
                    case "filter": {
                      return null;
                    }
                  }
                }
              }

              return v;
            })
            .filter(Boolean)
        ),
      ];
    }

    return values;
  }

  getOrigin(key, value) {
    const rules = this.rules.filter(
      (r) => r.key === key && r.action === "substitute" && r.subValue === value
    );

    if (rules.length > 0) {
      const { operator, value } = rules[0];
      switch (operator) {
        case "lte":
        case "gte":
        case "equal": {
          return value;
        }
        case "gt": {
          return value + 1;
        }

        case "lt": {
          return value - 1;
        }
      }
    }
    return value;
  }
}

export const flattenObjToMap = (obj, parent, res = new Map()) => {
  for (const key of Object.keys(obj)) {
    const propName = (() => {
      if (!isNaN(key)) return parent ?? "";
      return parent ? parent + "." + key : key;
    })();

    if (typeof obj[key] === "object") {
      flattenObjToMap(obj[key], propName, res);
    } else {
      if (!res.has(propName)) res.set(propName, new Set());

      if (Array.isArray(obj[key])) {
        obj[key].forEach((value) => res.get(propName).add(value));
      } else {
        res.get(propName).add(obj[key]);
      }
    }
  }
  return res;
};

export const flattenObj = (obj, parent, res = {}) => {
  if (obj === undefined || obj === null) return;

  for (const key of Object.keys(obj)) {
    const propName = (() => {
      if (!isNaN(key)) return parent || "";
      return parent ? parent + "." + key : key;
    })();

    if (typeof obj[key] === "object") {
      flattenObj(obj[key], propName, res);
    } else {
      if (!res[propName]) res[propName] = [];

      if (Array.isArray(obj[key])) {
        obj[key].forEach((value) => res[propName].push(value));
      } else {
        res[propName].push(obj[key]);
      }
    }
  }

  return Object.keys(res)
    .sort()
    .reduce(function (acc, key) {
      acc[key] = res[key].length > 1 ? res[key] : res[key][0];
      return acc;
    }, {});
};

export const mapToObj = (map = new Map(), obj = {}) => {
  for (const [key, value] of map.entries()) {
    if (!obj[key])
      obj[key] = { id: key, active: true, values: [], dbNestedFields: key.includes(".") };
    const values = [...new Set([...value, ...obj[key].values])];
    obj[key].values = values;
  }

  return Object.keys(obj)
    .sort()
    .reduce(function (acc, key) {
      acc[key] = obj[key];
      return acc;
    }, {});
};
