import { Fragment, useMemo, useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import {
  faCamera,
  faPlusSquare,
  faEllipsisH,
  faExclamationCircle,
  faTrashAlt,
  faSyncAlt,
  faHeartbeat,
} from "@fortawesome/free-solid-svg-icons";
import { TabPane } from "rc-tabs";
import Container from "../container";
import idGen from "../../utils/idGen";
import { fetchPage } from "../../utils/fetch";
import { explorer } from "../../transport";

import cloneDeep from "lodash.clonedeep";
import moment from "moment";

import DraggableTabs from "../draggableTabs";
import RequestBox from "../requestBox";

import styles from "./styles.module.scss";

const tabsStyle = { marginLeft: "-10px" };

export default function Snapshots(props) {
  const { itemId, endpointId, id_token, tenant } = props;
  const snapId = useMemo(() => idGen(), []);
  const emptySnap = useMemo(() => ({ id: snapId, title: "New Snapshot", isEmpty: true }), [snapId]);
  const [currentTab, setCurrentTab] = useState(snapId);
  const [deletingSnapIndex, setDeletingSnapIndex] = useState(-1);
  const [snapshots, setSnapshots] = useState({
    snapshots: [emptySnap],
  });

  const loadSnapshot = useCallback(() => {
    explorer
      .get(`snapshot/${tenant}/${endpointId}:${itemId}`, {
        headers: { id_token },
      })
      .then((response) => {
        if (response.data?.snapshots?.length > 0) {
          setCurrentTab(response.data.snapshots[0].id);
          setSnapshots(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [endpointId, id_token, itemId, tenant]);

  const saveData = useCallback(
    (data, cb, cbError) => {
      data.id = `${endpointId}:${itemId}`;

      explorer
        .post(`snapshot/${tenant}/${endpointId}:${itemId}`, data, {
          headers: { id_token },
        })
        .then((response) => {
          loadSnapshot();
          if (cb) cb(response);
        })
        .catch((error) => {
          if (cbError) cbError(error);
        });
    },
    [endpointId, id_token, itemId, loadSnapshot, tenant]
  );

  const saveSnapshot = useCallback(
    (snapIndex, snap, cb, cbError) => {
      const newData = { ...snapshots };
      newData.snapshots[snapIndex] = snap;

      saveData(newData, cb, cbError);
    },
    [saveData, snapshots]
  );

  const removeSnap = useCallback(
    (index) => {
      setDeletingSnapIndex(index);
      const newSnaps = { ...snapshots };
      newSnaps.snapshots.splice(index, 1);

      explorer
        .post(`snapshot/${tenant}/${endpointId}:${itemId}`, newSnaps, {
          headers: { id_token },
        })
        .then((response) => {
          if (newSnaps.snapshots.length === 0) {
            newSnaps.snapshots.push(emptySnap);
          }

          loadSnapshot();
          // setSnapshots(newSnaps);
          setDeletingSnapIndex(-1);
          // setCurrentTab(newSnaps.snapshots?.[0]?.id);
        })
        .catch((error) => {
          console.log(error);
          setDeletingSnapIndex(-1);
        });
    },
    [emptySnap, endpointId, id_token, itemId, loadSnapshot, snapshots, tenant]
  );

  const changeSnapTitle = useCallback(
    (index, title) => {
      const newSnaps = cloneDeep(snapshots);
      newSnaps.snapshots[index].title = title;
      setSnapshots(newSnaps);
    },
    [snapshots]
  );

  const snapTabs = useMemo(() => {
    const tabs = [];

    snapshots.snapshots.forEach((snap, snapIndex) => {
      const [icon, iconStatus] = (() => {
        if (deletingSnapIndex === snapIndex) return [faSyncAlt, ""];
        if (!snap.paramsId) return [faExclamationCircle, styles.snapNoActive];
        if (snap.status === "OK") return [faCamera, styles.snapOK];
        if (snap.status === "KO") return [faCamera, styles.snapKO];

        return [faCamera, ""];
      })();

      tabs.push(
        <TabPane
          key={snap.id}
          tab={
            <Fragment>
              <FontAwesomeIcon
                icon={icon}
                className={`${styles.tabIcon} ${iconStatus}`}
                spin={deletingSnapIndex === snapIndex}
              />
              {snap.title}

              <FontAwesomeIcon
                icon={faTrashAlt}
                className={styles.tabDeleteIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSnap(snapIndex);
                }}
              />
            </Fragment>
          }
        >
          <Snapshot
            data={snap}
            snapIndex={snapIndex}
            changeSnapTitle={changeSnapTitle}
            saveSnapshot={saveSnapshot}
            {...props}
          />
        </TabPane>
      );
    });
    return tabs;
  }, [changeSnapTitle, deletingSnapIndex, props, removeSnap, saveSnapshot, snapshots.snapshots]);

  const addSnapshot = useCallback(() => {
    const id = idGen();
    const newSnaps = { ...snapshots };
    newSnaps.snapshots.push({
      id,
      title: `New Snapshot #${newSnaps.snapshots.length + 1}`,
    });
    setSnapshots(newSnaps);
    setCurrentTab(id);
  }, [snapshots]);

  useEffect(() => {
    if (endpointId && itemId) {
      loadSnapshot();
    }
  }, [endpointId, itemId, loadSnapshot]);

  const title = useMemo(() => {
    return (
      <Fragment>
        <FontAwesomeIcon icon={faHeartbeat} className={styles.healthIcon} />
        Snapshots
      </Fragment>
    );
  }, []);

  const header = useMemo(() => {
    let status = "NOT VERIFIED";

    for (const s of snapshots?.snapshots ?? []) {
      if (s.status !== "OK") {
        status = "KO";
        break;
      }
      status = "OK";
    }

    return (
      <div className={styles.compact}>
        <span className={styles.label}>Snaps</span>
        <span className={styles.value}>
          {snapshots?.snapshots?.length > 0 && snapshots.snapshots[0].isEmpty !== true ? (
            <span>{snapshots.snapshots.length}</span>
          ) : (
            <span className={styles.tbd}>No Snapshots Taken yet</span>
          )}
        </span>

        <span className={styles.label}>Status</span>
        <span className={styles.value}>{status}</span>
        <span className={styles.label}>#id</span>
        <span className={styles.value}>{`${endpointId}:${itemId || "TBD"}`}</span>
      </div>
    );
  }, [endpointId, itemId, snapshots.snapshots]);

  return (
    <Container title={title} help="snapshots" color="#240000" header={header} folded={true}>
      <div className={styles.extended}>
        <DraggableTabs
          tabBarGutter={0}
          style={tabsStyle}
          tabBarExtraContent={
            <span className={styles.addSnap} onClick={addSnapshot}>
              <span className={styles.label}>Add Snapshot</span>
              <FontAwesomeIcon icon={faPlusSquare} className={styles.icon} />
            </span>
          }
          activeKey={currentTab}
          moreIcon={<FontAwesomeIcon icon={faEllipsisH} />}
          onTabClick={setCurrentTab}
          destroyInactiveTabPane="rlt"
          moveTab={() => {}}
        >
          {snapTabs}
        </DraggableTabs>
      </div>
    </Container>
  );
}

function Snapshot(props) {
  const {
    itemId,
    propertyId,
    propertyRef,
    endpointId,
    endpointRef,
    id_token,
    tenant,
    user,
    snapIndex,
    saveSnapshot,
    changeSnapTitle,
  } = props;
  const [data, setData] = useState(props.data);
  const [snapState, setSnapState] = useState("still");
  const [description, setDescription] = useState(props.data?.description);
  const [uiSets, setUISets] = useState(data.uiSets);

  const canSnap = useMemo(() => {
    const paramsId = data?.paramsId;
    const userId = data?.userId;
    const contentId = data?.contentId;

    if (!paramsId || (!userId && !contentId)) return false;

    return true;
  }, [data]);

  const variant = useMemo(() => {
    if (snapState === "still") return "outline-primary";
    if (snapState === "progress") return "outline-warning";
    if (snapState === "done") return "outline-success";

    return "outline-danger";
  }, [snapState]);

  const label = useMemo(() => {
    if (snapState === "still") return "Create Snapshot";
    if (snapState === "progress") return "Creating...";
    if (snapState === "done") return "Snaphost Created!";
    return `Error:  ${snapState}`;
  }, [snapState]);

  const handleChangeTitle = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const value = e.target.value;
      //setTitle(value);
      changeSnapTitle(snapIndex, value);
    },
    [changeSnapTitle, snapIndex]
  );

  const snap = useCallback(() => {
    if (!canSnap) return;

    const params = cloneDeep(data);
    delete params.snapshot;
    delete params.payload;

    const body = {
      ...params,
      tenant,
      blockId: itemId,
      endpointId: endpointRef,
      propertyId: propertyRef,
      avoidDecorators: true,
    };

    setSnapState("progress");
    fetchPage(
      body,
      id_token,
      (snapshot, payload) => {
        delete payload.query?.id;
        delete payload.query?.title;

        const snap = {
          ...params,
          id: data.id,
          title: props.data.title,
          description: description,
          isEmpty: false,
          taken: new Date(),
          shooter: user,
          itemId,
          propertyId,
          propertyRef,
          endpointId,
          endpointRef,
          uiSets,
          snapIndex,
          payload,
          snapshot,
        };

        saveSnapshot(
          snapIndex,
          snap,
          () => {
            setSnapState("done");
            setTimeout(() => setSnapState("still"), 2000);
          },
          (error) => {
            setSnapState(error);
            setTimeout(() => setSnapState("still"), 5000);
          }
        );
      },
      (error) => {
        console.log(error);
        setSnapState(error);
        setTimeout(() => setSnapState("still"), 5000);
      }
    );
  }, [
    canSnap,
    data,
    description,
    endpointId,
    endpointRef,
    id_token,
    itemId,
    propertyId,
    propertyRef,
    props.data.title,
    saveSnapshot,
    snapIndex,
    tenant,
    uiSets,
    user,
  ]);

  return (
    <div className={styles.snapBox}>
      {data.taken ? (
        <div className={styles.row}>
          <span className={styles.label}>Shooter</span>
          <span className={styles.value}>{data.shooter}</span>
          <span className={styles.label}>Taken</span>
          <span className={styles.value}>
            {moment(data.taken).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </span>
          <span className={styles.label}>Status</span>
          <span className={styles.value}>{data.status ? data.status : "Not Verified"}</span>
        </div>
      ) : null}
      <div className={styles.row}>
        <span className={styles.label}>Title</span>
        <Form.Control type="text" value={props.data?.title || ""} onChange={handleChangeTitle} />
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Description</span>
        <Form.Control
          as="textarea"
          rows="3"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <RequestBox
        parameters={data.uiParams}
        onChange={({ uiParams, currentParams }) => setData({ ...data, ...currentParams, uiParams })}
        allowCollection={false}
        forceOpen={false}
      />

      {canSnap && (
        <div className={styles.footer}>
          <Button size="sm" className={styles.snapBtn} onClick={snap} variant={variant}>
            <FontAwesomeIcon icon={faCamera} className={styles.icon} />
            <span className={styles.label}>{label}</span>
          </Button>
        </div>
      )}
    </div>
  );
}
