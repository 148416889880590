import { useCallback, useState, useMemo, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import moment from "moment";

import Picker from "rc-calendar/lib/Picker";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import Calendar from "rc-calendar";

import "./style.scss";
import "../../scss/calendar.css";
import "../../scss/time.css";

function isValidRange(v) {
  return v && v[0] && v[1];
}

const now = moment();
const getValue = (value, isRange) => {
  if (value) {
    if (isRange) {
      const [d1, d2] = value.split("÷");
      return [moment(d1), moment(d2)];
    } else {
      return moment(value);
    }
  }

  return isRange ? [now, now] : now;
};

export default function DateTime(props) {
  const { handleChange, value, isRange, errored } = props;

  const [_value, setValue] = useState(() => getValue(value, isRange));
  const [hoverValue, setHoverValue] = useState([]);

  const onChange = useCallback(
    (value) => {
      if (!value || (isRange && !isValidRange)) return;

      let ret = isRange
        ? `${value[0].toISOString()}÷${value[1].toISOString()}`
        : `${value.toISOString()}`;

      handleChange(ret);
      setValue(value);
    },
    [handleChange, isRange]
  );

  useEffect(() => setValue(getValue(value, isRange)), [isRange, value]);

  const timePickerElement = useMemo(
    () => (
      <TimePickerPanel
        defaultValue={
          isRange
            ? [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")]
            : moment("00:00:00", "HH:mm:ss")
        }
      />
    ),
    [isRange]
  );

  const rangeCalendar = useMemo(
    () => (
      <RangeCalendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder={["start", "end"]}
        defaultValue={[now, now.clone().add(1, "months")]}
        //locale={cn ? zhCN : enUS}
        timePicker={timePickerElement}
      />
    ),
    [hoverValue, timePickerElement]
  );

  const calendar = useMemo(
    () => (
      <Calendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder="Pick a Date..."
        defaultValue={now}
        //locale={cn ? zhCN : enUS}
        timePicker={timePickerElement}
      />
    ),
    [hoverValue, timePickerElement]
  );

  return (
    <Picker
      value={_value ?? []}
      onChange={onChange}
      animation="slide-up"
      calendar={isRange ? rangeCalendar : calendar}
    >
      {({ value: currentValue }) => {
        if (!value) {
          const _label = isRange ? "Pick a Date Range..." : "Pick a Date...";

          return (
            <Button
              size="sm"
              className="pick-date"
              variant={errored ? "outline-danger" : "outline-light"}
            >
              {_label}
            </Button>
          );
        }

        if (isRange) {
          if (!isValidRange(currentValue)) return null;

          return (
            <div className="date-range-value-container">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <i className="far fa-clock"></i> <b>{currentValue[0].format("HH:mm")}</b>
                  </Tooltip>
                }
              >
                <span>{currentValue[0].format("DD-MM-YYYY")}</span>
              </OverlayTrigger>
              <i className="fas fa-arrow-right range-arrow"></i>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <i className="far fa-clock"></i> <b>{currentValue[1].format("HH:mm")}</b>
                  </Tooltip>
                }
              >
                <span>{currentValue[1].format("DD-MM-YYYY")}</span>
              </OverlayTrigger>
            </div>
          );
        } else {
          const dataValue = Array.isArray(currentValue) ? currentValue[0] : currentValue;

          return (
            <div>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <i className="far fa-clock"></i> <b>{dataValue.format("HH:mm")}</b>
                  </Tooltip>
                }
              >
                <div className="date-range-value-container">
                  <span>{dataValue.format("DD-MM-YYYY")}</span>
                </div>
              </OverlayTrigger>
            </div>
          );
        }
      }}
    </Picker>
  );
}
