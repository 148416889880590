export default function validate(values) {
  let errors = false;

  const check = (conditions, nesting = 0) => {
    if (conditions?.length === 0) {
      errors = true;
      return;
    }

    if (conditions.and || conditions.or) return check(conditions.and || conditions.or);

    for (const condition of conditions) {
      //console.log("*", nesting, condition);
      if (condition.and || condition.or) {
        return check(condition.and || condition.or, ++nesting);
      }
      if (!condition.operator) {
        errors = true;
        break;
      }

      if (condition.operator === "*") break;

      if (!condition.values) {
        errors = true;
        break;
      }

      const [v1, v2] = condition.values;

      const isEmpty = ["isEmpty", "!isEmpty"].includes(condition.operator);

      if (isEmpty && !v1.value) {
        errors = true;
        break;
      }

      if (!isEmpty && (!v1.value || !v2.value || v2.value === `""`)) {
        errors = true;
        break;
      }
    }
  };
  if (values) check(values);

  return errors;
}
