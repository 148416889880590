import { Fragment, useMemo, useCallback, useEffect, useRef, useState } from "react";
import useDragAndDrop from "../../hooks/useDragAndDrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faThumbtack,
  faRandom,
  faFingerprint,
  faLink,
  faTh,
  faClipboard,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import Link from "../link";
import { NumericFormat } from "react-number-format";
import { useFormikContext } from "formik";
import { useItemContext } from "../../hooks/useItem";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import { useDataSettings } from "../../hooks/useData";
import useClipboard from "../../hooks/useClipboard";

import get from "lodash.get";
import shortid from "shortid";
import getMetadata, { getCatalogs } from "../../utils/getMetadata";

import { Form, Row, Col, Button } from "react-bootstrap";
import ContentType from "../contentType";
import Metadata from "../metadata";
import ConditionsPlus from "../conditionsPlus";
import SelectAll from "../selects/selectAll";
import SelectAllMultiple from "../selects/selectAllMultiple";
import Help from "../help";

import Filters from "./filters";
import Injects from "./injects";
import RotaryKnob from "../rotaryKnob";
import LabelSelect from "../selects/selectLabel";
import Container from "../container";
import UserPref from "../userPref";
import ViewAll from "../viewAll";
import ConversionsPackChooser from "../endpoint/filters";
import set from "lodash.set";

import styles from "./styles.module.scss";

const margin4Style = { marginRight: "4px" };
const metadataStyle = { marginTop: "15px" };
const selectStyle = { width: "366px" };
const placeholderMainArray = ["placeholderMain"];
const placeholderSubArray = ["placeholderSub"];
const groupMainArray = ["groupMain"];
const groupSubArray = ["groupSub"];
const smartListTypes = ["smartPages"];

const push = (elements, label, value) => {
  elements.push(
    <span key={label}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </span>
  );
};

export default function SubBlock(props) {
  const {
    block,
    blockIndex,
    blocks,
    info,
    masterId,
    smartSortable,
    shuffleable,
    dispatch,
    onTypeChange,
    flatten,
  } = props;

  const maxItems = useDataSettings("maxItems");
  const { errors } = useFormikContext();
  const { permissions, isCopy } = useItemContext();
  const getExternalSource = useGetMemItem("externalSources");
  const { copy } = useClipboard();
  const blockPath = useMemo(() => `blocks[${blockIndex}]`, [blockIndex]);
  const blockPathRes = useMemo(() => `${blockPath}.results.`, [blockPath]);
  const errorPath = useMemo(() => `blocks_${block._id}_`, [block._id]);
  const ref = useRef(null);
  const groupMain = useGetMemItem("labelsAll", block.group);
  const groupSub = useGetMemItem("labelsAll", block.groupSub);
  const placeholderMain = useGetMemItem("labelsAll", block.placeholderMain);
  const placeholderSub = useGetMemItem("labelsAll", block.placeholderSub);
  const responseField = useGetMemItem("responseFields", block.results?.responseFieldsId);
  const fallback = useGetMemItem("blocks", block.results?.fallbackBlockId);
  const query = useGetMemItem("queries", block.queryId);
  const list = useGetMemItem("lists", block.smartPageListId);
  const endpoint = useGetMemItem("endpoints", block.endpointId);
  const externalSource = useGetMemItem("externalSources", block.externalSourceId);
  const decoratorsFilter = useMemo(() => ({ objectTypes: ["block-source"] }), []);
  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    blockPath,
    blockIndex,
    "block",
    blocks,
    (_, data) => dispatch({ type: "UPDATE-ALL", payload: { data } })
  );

  const stile = useMemo(() => {
    const style = { opacity };

    if (highlighted && !hovered) {
      style.borderColor = "deepskyblue";

      return style;
    }
    if (hovered) {
      style.borderColor = "darkorange";
      style.borderBox = "4px 4px 8px deeppink";
      style.marginTop = "20px";
      style.marginBottom = "20px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const updateField = useCallback(
    (key, value) => {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: { [key]: value },
        },
      });
    },
    [blockIndex, dispatch]
  );

  const updateDeepField = useCallback(
    (path, value) => {
      setImmediate(() =>
        dispatch({
          type: "UPDATE-DEEP",
          payload: {
            path,
            blockIndex,
            value,
          },
        })
      );
    },
    [blockIndex, dispatch]
  );

  const isQuery = useMemo(
    () => block.type === "query" || block.type === "conversions",
    [block.type]
  );

  const isExternalCompatible = useMemo(() => {
    if (block.externalSourceId) {
      const ext = getExternalSource(block.externalSourceId);
      return ext?.compliant;
    }

    return false;
  }, [block.externalSourceId, getExternalSource]);

  const isConfigurable = useMemo(
    () => ["placeholder", "script", "deferred", "external"].includes(block.type),
    [block.type]
  );

  const editResults = useMemo(() => {
    if (["query", "conversions", "smart"].includes(block.type)) return true;
    if (block.type === "external" && isExternalCompatible) return true;
    if (block.type === "script" && block.compliant) return true;

    return false;
  }, [block.compliant, block.type, isExternalCompatible]);

  const currentType = useMemo(() => get(block, "results.contentType"), [block]);

  const setBlockType = useCallback(
    (type) => {
      onTypeChange && onTypeChange(type, blockIndex);

      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: {
            type,
            blockId: undefined,
            queryId: undefined,
            listId: undefined,
            viewAll: undefined,
            //results: { contentType: undefined }
          },
        },
      });
    },
    [blockIndex, dispatch, onTypeChange]
  );

  const setPacks = useCallback(
    ({ packs }) => {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: {
            packs,
          },
        },
      });
    },
    [blockIndex, dispatch]
  );

  const setIdExtractMethod = useCallback(
    (mode) => {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: {
            idExtractMethod: mode,
          },
        },
      });
    },
    [blockIndex, dispatch]
  );

  const setIdExtractPosition = useCallback(
    ({ floatValue }) => {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: {
            idExtractPosition: floatValue - 1,
          },
        },
      });
    },
    [blockIndex, dispatch]
  );

  useEffect(() => {
    if (get(block, "results.min") === undefined) updateDeepField("results.min", 0);
    if (get(block, "results.max") === undefined) updateDeepField("results.max", 20);
  }, [block, updateDeepField]);

  useEffect(() => {
    const min = Number(get(block, "results.min"));
    const max = Number(get(block, "results.max"));

    if (min > max) updateDeepField("results.min", max);
  }, [block, updateDeepField]);

  useEffect(() => {
    onTypeChange(block.type, blockIndex);
    if (block.pinned === undefined) updateField("pinned", true);
    if (isCopy) updateField("_id", shortid.generate());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flatten && block.viewAll) {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: { viewAll: undefined },
        },
      });
    }
  }, [flatten, block.viewAll, dispatch, blockIndex]);

  // *******************************
  // Fallbacks
  // *******************************
  const blockSelectExcludes = useMemo(() => [masterId], [masterId]);
  const blockSelectFilters = useMemo(() => {
    const loopIds = info.loopIds ?? [];

    return {
      catalogs: [currentType],
      exclude: [...new Set([...blockSelectExcludes, ...loopIds])],
      isFallback: true,
    };
  }, [blockSelectExcludes, currentType, info.loopIds]);

  // *******************************
  // Static Handlers
  // *******************************
  const fallbackHandleChange = useCallback(
    (blockId) => updateDeepField("results.fallbackBlockId", blockId),
    [updateDeepField]
  );
  // const blockSelectHandleChange = useCallback((blockId) => updateField("blockId", blockId), [
  //   updateField,
  // ]);
  const querySelectHandleChange = useCallback(
    (queryId) => updateField("queryId", queryId),
    [updateField]
  );
  const externalSourcesHandleChange = useCallback(
    (extId) => updateField("externalSourceId", extId),
    [updateField]
  );
  const endpointHandleChange = useCallback(
    (endPId) => updateField("endpointId", endPId),
    [updateField]
  );
  const contentTypeOnChange = useCallback(
    (value) => {
      const data = { ...block };
      set(data, "results.contentType", value);
      data.results = {
        ...(data.results || {}),
        contentType: value,
        responseFieldsId: undefined,
        fallbackBlockId: undefined,
      };

      delete data.blockId;
      delete data.queryId;
      delete data.listId;

      dispatch({
        type: "UPDATE-INDEX",
        payload: { blockIndex, data },
      });
    },
    [block, blockIndex, dispatch]
  );
  const filtersHandleChange = useCallback(
    (key, value) => updateDeepField(`results.filters.${key}`, value),
    [updateDeepField]
  );
  const pinHandleChange = useCallback(
    (e) => {
      e.stopPropagation();
      updateField("pinned", !block.pinned);
    },
    [block.pinned, updateField]
  );

  const handleInjectsUpdate = useCallback(
    (values) => updateDeepField(`results.inject`, values),
    [updateDeepField]
  );

  const handleMetadataUpdate = useCallback(
    (metadata) => updateField("metadata", metadata),
    [updateField]
  );

  const handelUpdatePlaceholderSub = useCallback(
    (v) => updateField("placeholderSub", v),
    [updateField]
  );

  const handleScriptChange = useCallback((v) => updateField("scripts", v), [updateField]);

  const handelUpdatePlaceholderMain = useCallback(
    (v) => updateField("placeholderMain", v),
    [updateField]
  );

  const handleChangeCondition = useCallback(
    ({ condition, conditionRaw, scheduled, scheduling }) => {
      const data = { ...block };
      data.condition = condition;
      data.conditionRaw = conditionRaw;
      data.scheduled = scheduled;
      data.scheduling = scheduling;

      dispatch({
        type: "UPDATE-INDEX",
        payload: { blockIndex, data },
      });

      // updateField("condition", condition);
      // updateField("conditionRaw", conditionRaw);
    },
    [block, blockIndex, dispatch]
  );

  const realms = useMemo(() => getMetadata(block?.metadata || {}), [block]);
  const catalogs = useMemo(() => getCatalogs(block?.metadata, currentType), [block, currentType]);

  const onIdCopy = useCallback(
    (e) => {
      e.stopPropagation();
      copy(block._id);
    },
    [block._id, copy]
  );
  const title = useMemo(() => {
    return (
      <div className={styles.title}>
        <div className={styles.smartIcon}>
          {shuffleable && (
            <div className={styles.blockPin}>
              <FontAwesomeIcon icon={faRandom} />
            </div>
          )}
          {smartSortable && (
            <div className={block.pinned ? styles.pinned : styles.smart} onClick={pinHandleChange}>
              <FontAwesomeIcon icon={block.pinned ? faThumbtack : faFingerprint} />
            </div>
          )}
          {!smartSortable && !shuffleable && (
            <div className={styles.defaultPin}>
              <FontAwesomeIcon icon={faTh} />
            </div>
          )}
        </div>
        <div className={styles.titleLabel}>{block.title}</div>
        <div className={styles.idLabel}>#Id</div>
        <div className={styles.id}>{block._id}</div>
        <div className={styles.copyIcon}>
          <FontAwesomeIcon icon={faClipboard} onClick={onIdCopy} />
        </div>
      </div>
    );
  }, [block._id, block.pinned, block.title, onIdCopy, pinHandleChange, shuffleable, smartSortable]);

  const titleBoxExtra = useMemo(() => {
    const labels = [];
    if (groupMain) {
      const { background, color, value } = groupMain;
      if (value) {
        labels.push(
          <div
            className={styles.label}
            key={value}
            style={{ backgroundColor: background, color: color }}
          >
            {value}
          </div>
        );
      }
    }
    if (groupSub) {
      const { background, color, value } = groupSub;
      if (value) {
        labels.push(
          <div
            className={styles.label}
            key={value}
            style={{ backgroundColor: background, color: color }}
          >
            {value}
          </div>
        );
      }
    }

    return <div className={styles.boxExtra}>{labels}</div>;
  }, [groupMain, groupSub]);

  const footer = useMemo(() => {
    return (
      <Button
        variant="outline-danger"
        onClick={() =>
          dispatch({
            type: "DELETE",
            payload: { blockIndex },
          })
        }
      >
        <FontAwesomeIcon icon={faTrashAlt} style={margin4Style} /> Delete Block
      </Button>
    );
  }, [blockIndex, dispatch]);

  const settingsExtra = useMemo(() => {
    if (block.type === "placeholder") {
      const labels = [];

      if (placeholderMain) {
        const { background, color, value } = placeholderMain;
        if (value) {
          labels.push(
            <div
              className={styles.label}
              key={value}
              style={{ backgroundColor: background, color: color }}
            >
              {value}
            </div>
          );
        }
      }

      if (placeholderSub) {
        const { background, color, value } = placeholderSub;
        if (value) {
          labels.push(
            <div
              className={styles.label}
              key={value}
              style={{ backgroundColor: background, color: color }}
            >
              {value}
            </div>
          );
        }
      }

      return <div className={styles.boxExtra}>{labels}</div>;
    }
  }, [block.type, placeholderMain, placeholderSub]);

  const extra = useMemo(() => {
    return (
      <div className={styles.mainExtra}>
        {settingsExtra}
        <div className={styles.extraBadge}>
          {["placeholder", "script", "deferred", "external"].includes(block.type) &&
          !isExternalCompatible
            ? "Ext"
            : `${get(block, `results.max`) || 1} Elements`}
        </div>
        {block.scheduled ? (
          <FontAwesomeIcon icon={faCalendar} className={styles.scheduledBlockIcon} />
        ) : null}
      </div>
    );
  }, [block, isExternalCompatible, settingsExtra]);

  const settingsHeader = useMemo(() => {
    const e = [];
    push(e, "Type", block.type);
    if (query?.title) push(e, "Query", query.title);
    if (block.results?.contentType) push(e, "Catalog", block.results.contentType);

    if (["smart"].includes(block.type)) {
      if (block.userContentType) push(e, "User Catalog", block.userContentType);
      if (block.event) push(e, "Event", block.event);
      if (block.perUserBasisType)
        push(e, "Fingerprint", block.perUserBasisType.replace(/_|-/g, " "));
    }
    if (block.type === "smart") {
      if (list?.title) push(e, "List", list.title);
      if (block.results?.smartStartFrom) push(e, "Start From", block.results.smartStartFrom);
    }

    if (block.type === "script" && block.scripts?.length > 0)
      push(e, "Scripts", block.scripts.length);
    if (block.type === "external" && externalSource?.title)
      push(e, "External Source", externalSource.title);
    if (block.type === "deferred" && endpoint?.title) push(e, "Endpoint", endpoint.title);

    // if (["smart", "history", "query"].includes(block.type) && block.viewAll) {
    //   push(e, "View All", block.viewAll.reference);
    // }
    return <div className={styles.generalHeader}>{e}</div>;
  }, [block, query, list, endpoint, externalSource]);

  const subBlockHeader = useMemo(() => {
    return (
      <div className={styles.subBlockHeader}>
        {settingsHeader}
        {titleBoxExtra}
      </div>
    );
  }, [settingsHeader, titleBoxExtra]);

  const titleHeader = useMemo(() => {
    return <div className={styles.titleHeader}>{block.title}</div>;
  }, [block.title]);

  const responseHeader = useMemo(() => {
    const rez = block.results;

    if (rez) {
      const e = [];

      push(e, "Min", rez.min || 0);
      push(e, "Max", rez.max || 20);
      push(e, "Start", rez.startFrom || 1);
      if (rez.skipOnEmpty) push(e, "Skip on Empty", "true");
      if (rez.shuffleItems) push(e, "Shuffle", "true");
      if (rez.logResults) push(e, "Log", "true");
      if (responseField?.title) push(e, "Fields", responseField.title);

      return <div className={styles.generalHeader}>{e}</div>;
    }

    return null;
  }, [block.results, responseField]);

  const injectsHeader = useMemo(() => {
    return (
      <div className={styles.injectsHeader}>
        {block.results?.inject?.length || 0} <span>Injected</span>
      </div>
    );
  }, [block.results]);

  const filterHeader = useMemo(() => {
    const rez = block.results?.filters;

    if (rez) {
      const e = [];

      if (rez.alreadyConsumed) push(e, "Consumed", "true");
      if (rez.alreadyRecommendedMoreThen) push(e, "Recommended", rez.alreadyRecommendedMoreThen);
      if (rez.keepSourcePool) push(e, "Keep Source", "true");
      if (rez.repeatedMetadata && rez.repeatedMetadata.length > 0)
        push(e, "Metadata", rez.repeatedMetadata.length);
      if (rez.scripts && rez.scripts.length > 0) push(e, "Scripts", rez.scripts.length);

      return <div className={styles.generalHeader}>{e}</div>;
    }

    return null;
  }, [block.results]);

  const fallbackHeader = useMemo(() => {
    if (fallback?.title) {
      return (
        <div className={styles.generalHeader}>
          <span key="fallbackHeader">
            <div className={styles.value}>{fallback.title}</div>
          </span>
        </div>
      );
    }

    return null;
  }, [fallback]);

  const updateResultsMax = useCallback(
    (max) => {
      const injects = block.results?.inject || [];
      const length = injects?.length;
      const outOfBoundary = [];

      for (let i = 0; i < length; i++) {
        const { position, injectIndex, numResults = 0 } = injects[i];
        const bound = position + numResults - 1;
        if (bound > max) outOfBoundary.push(injectIndex);
      }

      if (outOfBoundary.length > 0) {
        const inject = injects.filter((i) => !outOfBoundary.includes(i.injectIndex));
        updateDeepField("results", { ...block.results, max, inject });
      } else updateDeepField("results.max", max);
    },
    [block.results, updateDeepField]
  );

  const handleViewAll = useCallback(
    (viewAll) => {
      dispatch({
        type: "UPDATE",
        payload: {
          blockIndex,
          data: { viewAll },
        },
      });
    },
    [blockIndex, dispatch]
  );

  const responseFieldFilters = useMemo(() => {
    return {
      catalogs: [currentType],
      exclude: [block.results?.responseFieldsId],
    };
  }, [block.results?.responseFieldsId, currentType]);

  const handleResponseFieldChange = useCallback(
    (value) => updateDeepField("results.responseFieldsId", value),
    [updateDeepField]
  );

  const handleListChange = useCallback(
    (listId) => updateField("smartPageListId", listId),
    [updateField]
  );
  const listSelectFilters = useMemo(() => {
    const filters = {
      objectTypes: smartListTypes,
    };
    if (block.results?.contentType) filters.catalogs = [block.results.contentType];
    return filters;
  }, [block.results?.contentType]);

  const selectFilters = useMemo(() => {
    const filters = { catalogs: [currentType] };
    if (info.restricted) filters.canBeFallback = true;

    return filters;
  }, [currentType, info.restricted]);

  return (
    <Container
      title={title}
      help="sub-block"
      color="#111"
      coloredBars={false}
      ref={ref}
      style={stile}
      header={subBlockHeader}
      extra={extra}
      footer={footer}
      warning={errors[block._id]}
      folded={!errors[block._id]} //false
    >
      <Container
        title="Title"
        help="block-title"
        color="#020f0c"
        header={titleHeader}
        extra={titleBoxExtra}
        warning={errors[block._id]?.title}
        folded={!errors[block._id]?.title}
      >
        <Row>
          <Col sm="1">Title</Col>
          <Col sm={11}>
            <SubBlockTitle value={block.title} onChange={(v) => updateField("title", v)} />
          </Col>
        </Row>

        <Row>
          <Col sm={1}>Group</Col>
          <Col sm={11}>
            <LabelSelect
              types={groupMainArray}
              style={selectStyle}
              value={block.group}
              handleChange={(v) => updateField("group", v)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={1}>Sub Group</Col>
          <Col sm={11}>
            <LabelSelect
              types={groupSubArray}
              style={selectStyle}
              value={block.groupSub}
              handleChange={(v) => updateField("groupSub", v)}
            />
          </Col>
        </Row>
      </Container>

      <Container
        title="Settings"
        help="block-main-params"
        color="#06141a"
        extra={settingsExtra}
        header={settingsHeader}
        warning={errors[block._id]?.settings}
        folded={!errors[block._id]?.settings} //false
      >
        <div className="settings-box">
          <Row>
            <Col sm={1}>
              Type <Help code={`block-${block.type}`} />
            </Col>
            <Col sm={6}>
              <Form.Control
                as="select"
                value={block.type}
                onChange={(e) => setBlockType(e.target.value)}
                style={selectStyle}
              >
                <option value="query">Query</option>
                <option value="conversions">Conversions</option>
                {!info.isFallback && <option value="smart">Smart Block Container</option>}
                <option value="deferred">Deferred</option>
                <option value="placeholder">Placeholder</option>
                <option value="external">External Source</option>
                <option value="script">Script</option>
              </Form.Control>
            </Col>
          </Row>

          <ContentType
            rootNode={block.results || {}}
            rootNodePath={blockPathRes}
            errorPath={errorPath}
            fixedValue={currentType} //isQuery && currentType ? blockContentType : null
            onChange={contentTypeOnChange}
            style={selectStyle}
            isInvalid={errors[block._id]?.contentType}
          />
          {block.type === "placeholder" ? (
            <>
              <Row>
                <Col sm="1">Label</Col>
                <Col sm={6}>
                  <LabelSelect
                    types={placeholderMainArray}
                    style={selectStyle}
                    value={block.placeholderMain}
                    handleChange={handelUpdatePlaceholderMain}
                    isInvalid={errors[block._id]?.placeholderMain}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={1}>SubLabel</Col>
                <Col sm={6}>
                  <LabelSelect
                    types={placeholderSubArray}
                    style={selectStyle}
                    value={block.placeholderSub}
                    handleChange={handelUpdatePlaceholderSub}
                  />
                </Col>
              </Row>
            </>
          ) : null}
          {currentType && block.type === "script" ? (
            <>
              <Row>
                <Col sm={1}>Scripts</Col>
                <Col sm={8}>
                  <SelectAllMultiple
                    accessor="decorators"
                    filters={decoratorsFilter}
                    values={block.scripts}
                    isInvalid={errors[block._id]?.scripts}
                    style={selectStyle}
                    handleChange={handleScriptChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={{ col: 3, offset: 1 }}>
                  <Form.Check
                    type="switch"
                    id="compliant-switch"
                    checked={block.compliant || false}
                    value="true"
                    onChange={(e) => updateField("compliant", e.target.checked)}
                    name="compliant"
                    label="ARES Compliant Response"
                  />
                </Col>
              </Row>
            </>
          ) : null}

          {block.type === "external" ? (
            <Row>
              <Col sm={1}>Source</Col>
              <Col sm={3} className={styles.colWithLink}>
                <SelectAll
                  accessor="externalSources"
                  value={block.externalSourceId}
                  handleChange={externalSourcesHandleChange}
                  style={selectStyle}
                  isInvalid={errors[block._id]?.external}
                />

                {block.externalSourceId && (
                  <Link
                    to={`/blocks/externalsources/${block.externalSourceId}`}
                    className={styles.btnLink}
                  >
                    <Button size="sm" variant="dark">
                      <FontAwesomeIcon icon={faLink} />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          ) : null}

          {block.type === "deferred" ? (
            <Fragment>
              <Row>
                <Col sm={1}>Endpoint</Col>
                <Col sm={3} className={styles.colWithLink}>
                  <SelectAll
                    accessor="endpoints"
                    filters={selectFilters}
                    value={block.endpointId}
                    handleChange={endpointHandleChange}
                    style={selectStyle}
                    labelsFilter={true}
                    isInvalid={errors[block._id]?.deferred}
                  />

                  {block.endpointId && (
                    <Link to={`/endpoints/${block.endpointId}`} className={styles.btnLink}>
                      <Button size="sm" variant="dark">
                        <FontAwesomeIcon icon={faLink} />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={{ col: 4, offset: 1 }}>
                  {block.endpointId && (
                    <>
                      <Form.Check
                        type="switch"
                        checked={get(block, "fillDeferWithPayload") || false}
                        value="true"
                        onChange={(e) => updateField("fillDeferWithPayload", e.target.checked)}
                        id={`fillDeferWithPayload`}
                        label="Fill Deferred Request With Original Payload"
                      />
                      {block.fillDeferWithPayload && (
                        <>
                          <Form.Check
                            type="switch"
                            checked={get(block, "usePlaceholderForPayload") || false}
                            value="true"
                            onChange={(e) =>
                              updateField("usePlaceholderForPayload", e.target.checked)
                            }
                            id={`usePlaceholderForPayload`}
                            label="Use Placeholder if values are not present"
                          />
                          {block.usePlaceholderForPayload && (
                            <>
                              <span className="small-label">Place Holder Char</span>
                              <Form.Control
                                type="text"
                                placeholder="$"
                                value={get(block, "placeholderChar") || ""}
                                onChange={(e) => updateField("placeholderChar", e.target.value)}
                                className={styles.placeHolderChar}
                              />
                              {block.placeholderChar && (
                                <Form.Control
                                  as="select"
                                  value={get(block, "placeholderCharPosition") || ""}
                                  onChange={(e) =>
                                    updateField("placeholderCharPosition", e.target.value)
                                  }
                                  className={styles.placeHolderCharPosition}
                                >
                                  <option></option>
                                  <option value="start">Beginning</option>
                                  <option value="end">End</option>
                                  <option value="both">Both</option>
                                </Form.Control>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Fragment>
          ) : null}

          {isQuery && currentType ? (
            <Row>
              <Col sm={1}>Query</Col>
              <Col sm={4} className={styles.colWithLink}>
                <SelectAll
                  accessor="queries"
                  filters={selectFilters}
                  value={block.queryId}
                  handleChange={querySelectHandleChange}
                  style={selectStyle}
                  labelsFilter={true}
                  isInvalid={errors[block._id]?.queryId}
                />
                {block.queryId && (
                  <Link to={`/queries/${block.queryId}`} className={styles.btnLink}>
                    <Button size="sm" variant="dark">
                      <FontAwesomeIcon icon={faLink} />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          ) : null}

          {block.type === "conversions" && block.results?.contentType ? (
            <>
              <ConversionsPackChooser
                data={block}
                onChange={setPacks}
                contentType={block.results?.contentType}
                title="Pick Id From"
                showCatalog={false}
                parent={info}
                error={errors?.[block._id]?.packs}
                extra={
                  <div className={styles.packChooserExtra}>
                    <span className={styles.mode}>
                      Mode <Help code={`block-conversions-query-mode`} />
                    </span>
                    <Form.Control
                      as="select"
                      value={block.idExtractMethod}
                      onChange={(e) => setIdExtractMethod(e.target.value)}
                      style={selectStyle}
                      isInvalid={errors[block._id]?.idExtractMethod}
                    >
                      <option value=""></option>
                      <option value="random">Random</option>
                      <option value="recent">Recent</option>
                      <option value="older">Older</option>
                      <option value="positional">From Position</option>
                    </Form.Control>
                    {block.idExtractMethod === "positional" && (
                      <div className={styles.packModePositionalInput}>
                        <NumericFormat
                          defaultValue="1"
                          value={block.idExtractPosition > -1 ? block.idExtractPosition + 1 : 1}
                          displayType={"input"}
                          thousandSeparator={false}
                          allowNegative={false}
                          isAllowed={({ floatValue }) => {
                            return floatValue ? floatValue > 0 : true;
                          }}
                          onValueChange={setIdExtractPosition}
                          className={styles.idExtractPosition}
                        />
                      </div>
                    )}
                  </div>
                }
              />
            </>
          ) : null}

          {block.type === "smart" ? (
            <UserPref
              values={block}
              errors={errors[block._id]}
              catalog={block.results?.contentType}
              onChange={(key, value) => updateField(key, value)}
              showFingerprint={!["randomIdFromUserHistory"].includes(block.historyMode)}
            />
          ) : null}

          {block.type === "smart" && currentType ? (
            <>
              <Row>
                <Col sm={1}>List</Col>
                <Col sm={3} className={styles.colWithLink}>
                  <SelectAll
                    accessor="lists"
                    startWithEmptyOption={true}
                    value={block.smartPageListId}
                    handleChange={handleListChange}
                    filters={listSelectFilters}
                    style={selectStyle}
                    isInvalid={errors[block._id]?.smartPageListId}
                  />
                  {block.smartPageListId && (
                    <Link to={`/lists/${block.smartPageListId}`} className={styles.btnLink}>
                      <Button size="sm" variant="dark">
                        <FontAwesomeIcon icon={faLink} />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 3, offset: 1 }}>
                  <RotaryKnob
                    value={Number(get(block, `results.smartStartFrom`) || 1)}
                    min={1}
                    max={(list?.elements?.length || 21) - 1}
                    step={1}
                    label="Start From"
                    labelPosition="right"
                    skin="s10"
                    onChange={(value) => updateDeepField("results.smartStartFrom", value)}
                    className={styles.rotaryStartFromSmart}
                  />
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </Container>

      {!flatten && (
        <ViewAll
          data={block.viewAll}
          onChange={handleViewAll}
          uniqueId={block._id}
          folded={true}
          info={info}
        />
      )}

      {currentType || isConfigurable ? (
        <>
          {editResults || isConfigurable ? (
            <Metadata
              style={metadataStyle}
              rootNode={block.metadata}
              rootNodeName={`blocks_${block._id}_metadata`}
              rootNodePath={`blocks.${block._id}.metadata.`}
              contentType={block.results ? block.results.contentType : null}
              handleChange={handleMetadataUpdate}
              //excludeFallbackMetadata={isFallback}
              uniqueId={`metatadata-${masterId}-${block._id}`}
            />
          ) : null}

          <ConditionsPlus
            folded={true} //false
            catalogs={catalogs}
            realms={realms}
            condition={block.condition}
            conditionRaw={block.conditionRaw}
            onChangeCondition={handleChangeCondition}
            warning={errors[block._id]?.conditions}
            enableReinitialize={true}
            info={info}
          />
        </>
      ) : null}
      {editResults ? (
        <>
          {isExternalCompatible || block.type !== "script" ? (
            <Container
              title="Response"
              help="block-response"
              color="#100310"
              header={responseHeader}
              folded={!errors[block._id]?.response}
              warning={errors[block._id]?.response}
            >
              <Row>
                <Col sm={1}>How Many</Col>
                <Col sm={11}>
                  <div className={styles.responseParams}>
                    <div className={styles.knobs}>
                      <RotaryKnob
                        value={Number(get(block, `results.min`) || 0)}
                        min={0}
                        max={Number(get(block, `results.max`) || 20) + 1}
                        step={1}
                        label="Min"
                        labelPosition="top"
                        skin="s10"
                        onChange={(value) => updateDeepField("results.min", value)}
                      />
                      <RotaryKnob
                        value={Number(get(block, `results.max`) || 20)}
                        min={1}
                        max={maxItems || 100}
                        step={1}
                        label="Max"
                        labelPosition="top"
                        skin="s10"
                        onChange={updateResultsMax}
                      />
                      <RotaryKnob
                        value={Number(get(block, `results.startFrom`) || 1)}
                        min={1}
                        max={Number(get(block, `results.max`) || 1) - 1}
                        step={1}
                        label="Start"
                        labelPosition="top"
                        skin="s10"
                        onChange={(value) => updateDeepField("results.startFrom", value)}
                      />
                    </div>
                    <div className={styles.switches}>
                      <Form.Check
                        type="switch"
                        checked={get(block, "results.skipOnEmpty") || false}
                        value="true"
                        onChange={(e) => updateDeepField("results.skipOnEmpty", e.target.checked)}
                        id={`block[${blockIndex}].results.skipOnEmpty`}
                        label="Skip On Empty"
                      />

                      <Form.Check
                        type="switch"
                        checked={get(block, "results.shuffleItems") || false}
                        value="true"
                        onChange={(e) => updateDeepField("results.shuffleItems", e.target.checked)}
                        id={`block[${blockIndex}].results.shuffleItems`}
                        label="Shuffle Results"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {isExternalCompatible || (isQuery && currentType) ? (
                <>
                  {isQuery && block.queryId && (
                    <>
                      <Row>
                        <Form.Label column sm={1}>
                          Fields
                        </Form.Label>
                        <Col sm={8}>
                          <SelectAll
                            accessor="responseFields"
                            style={selectStyle}
                            isInvalid={errors[block._id]?.responseFieldsId}
                            filters={responseFieldFilters}
                            value={block.results?.responseFieldsId}
                            handleChange={handleResponseFieldChange}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : null}
            </Container>
          ) : null}

          {permissions.expert.includes("block-inject") ? (
            <Container
              title="Injects"
              help="block-injects"
              color="#0c1301"
              folded={true}
              header={injectsHeader}
              headerOnlyOnFolded={false}
            >
              <Injects
                block={block}
                info={info}
                contentType={currentType}
                excludes={blockSelectExcludes}
                handleUpdate={handleInjectsUpdate}
              />
            </Container>
          ) : null}

          <Container
            title="Filters"
            color="#081217"
            help="block-filters"
            header={filterHeader}
            folded={true}
          >
            <Filters
              block={block}
              blockIndex={blockIndex}
              handleUpdate={filtersHandleChange}
              canFilterRecommended={get(block, "results.logResults")}
            />
          </Container>
          <Container
            title="Fallback"
            help="block-fallback"
            color="#000d13"
            warning={errors[block._id]?.fallback}
            header={fallbackHeader}
            folded={!errors[block._id]?.fallback}
          >
            <Row>
              <Col sm="1">Block</Col>
              <Col sm="11">
                <SelectAll
                  accessor="blocks"
                  filters={blockSelectFilters}
                  isInvalid={errors[block._id]?.fallback}
                  value={(block.results || {}).fallbackBlockId}
                  handleChange={fallbackHandleChange}
                  style={selectStyle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 11, offset: 1 }}>
                {(block.results || {}).fallbackBlockId ? (
                  <Form.Check
                    type="switch"
                    checked={get(block, "results.fullSubstituteFallback") || false}
                    value="true"
                    onChange={(e) =>
                      updateDeepField("results.fullSubstituteFallback", e.target.checked)
                    }
                    id={`block.results.fullSubstituteFallback`}
                    label="Full Substitute Fallback"
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
    </Container>
  );
}

const titleStyle = { cursor: "pointer" };
function SubBlockTitle(props) {
  const { value, onChange } = props;
  const [title, setTitle] = useState(value);
  const [edit, setEdit] = useState(value ? false : true);

  const handleClick = useCallback(
    (e) => {
      onChange(title);
      setEdit((state) => !state);
    },
    [onChange, title]
  );

  return (
    <Fragment>
      {!edit ? (
        <Fragment>
          <div
            className="sub-title-closed"
            onDoubleClick={() => setEdit((state) => !state)}
            style={titleStyle}
          >
            {title}
          </div>
        </Fragment>
      ) : (
        <div className="sub-title-edit">
          <Form.Control
            type="text"
            placeholder="Block Title"
            isInvalid={!title}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="sub-title"
            style={selectStyle}
          />
          <Button
            size="sm"
            variant="outline-primary"
            onClick={handleClick}
            className="sub-title-btn"
          >
            OK
          </Button>
        </div>
      )}
    </Fragment>
  );
}
