/* eslint-disable import/no-named-as-default-member */
import { useCallback } from "react";
import Tabs from "rc-tabs";
import "../../scss/tabs.scss";

import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";

function DraggableTab({ children, moveTab }) {
  const { key, ref, _owner } = children;
  const index = _owner ? _owner.index : 0;

  useDragAndDrop(
    ref,
    key,
    index,
    "tabs",
    [],
    (_, __, drag, hover) => moveTab && moveTab(drag - 1, hover - 1)
  );

  return children;
}

export default function DraggableTabs(props) {
  const { moveTab, style, ...rest } = props;

  const renderTabBar = useCallback(
    (props, DefaultTabBar) => {
      return (
        <DefaultTabBar {...props}>
          {(node, k, j) => {
            return (
              <span>
                <DraggableTab moveTab={moveTab}>{node}</DraggableTab>
              </span>
            );
          }}
        </DefaultTabBar>
      );
    },
    [moveTab]
  );

  return (
    <DragAndDrop>
      <Tabs destroyInactiveTabPane={true} renderTabBar={renderTabBar} {...rest}>
        {props.children}
      </Tabs>
    </DragAndDrop>
  );
}
