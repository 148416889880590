import { Fragment, useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import get from "lodash.get";
import RotaryKnob from "../rotaryKnob";

export default function Boost(props) {
  const { accessor, dataAccessor, rule, ruleIndex } = props;

  const { queryClauses, boostMaxValue } = useDataSettings("queryClauses", "boostMaxValue");
  const { setFieldValue, values } = useFormikContext();

  const boostable = useMemo(
    () => queryClauses.filter((q) => q.boostable).map((q) => q.accessor),
    [queryClauses]
  );

  const boost = useMemo(() => {
    if (!boostable.includes(accessor) || !rule) return;

    return (
      get(values[dataAccessor || accessor], `${ruleIndex}.overides.${rule._id}.boost`) || rule.boost
    );
  }, [accessor, boostable, dataAccessor, rule, ruleIndex, values]);

  // const label = useMemo(() => queryClauses.find((q) => q.accessor === accessor).boostLabel, [
  //   accessor,
  //   queryClauses,
  // ]);

  const handleBoostChange = useCallback(
    (boost) => {
      if (!rule._id) return;

      const newValues = cloneDeep(values[dataAccessor || accessor]);
      set(newValues, `${ruleIndex}.overides.${rule._id}.boost`, boost);
      setFieldValue(dataAccessor || accessor, newValues);
    },
    [accessor, dataAccessor, rule._id, ruleIndex, setFieldValue, values]
  );
  return (
    <Fragment>
      {boost && (
        <RotaryKnob
          value={Number(boost)}
          min={1}
          max={boostMaxValue || 200}
          step={1}
          label="Boost"
          labelPosition="bottom"
          skin="s10"
          onChange={(v) => handleBoostChange(v)}
          className="knob-group-generic"
          disabled={rule.notOverwritable}
        />
      )}
    </Fragment>
  );
}
