export default function validate(values) {
  const errors = {};

  if (!values.contentType) {
    errors.settings = true;
    errors.contentType = "You must specify a Catalog";
  }

  if (!values.clause) {
    errors.settings = true;
    errors.clause = "You must specify a Clause";
  }

  if (!values.rules || values.rules.length === 0) {
    errors.rules = "You must insert at least one rule";
  }

  return errors;
}
