import { Button, Modal } from "react-bootstrap";

function ConfirmDelete(props) {
  const { message, onConfirm, onClose, show } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onConfirm}>
          Delete
        </Button>
        <Button variant="primary" onClick={onClose}>
          Do Not Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDelete;
