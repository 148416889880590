import { Form } from "react-bootstrap";

import styles from "./styles.module.scss";

function Search(props) {
  const { handleChange, execute, showPhraseOption, name } = props;

  return (
    <div className={styles.searchBox}>
      <div className={styles.select}>
        <div className={styles.label}>Fuzziness</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            className="form-control form-control-sm"
            value={execute.fuzziness}
            onChange={(e) => handleChange("fuzziness", e)}
            id={`${name}-multimatch-fuzziness`}
          >
            <option>AUTO</option>
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </div>
      </div>
      <div className={styles.select}>
        <div className={styles.label}>Operator</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            className="form-control form-control-sm"
            value={execute.operator || "OR"}
            onChange={(e) => handleChange("operator", e)}
            id={`${name}-multimatch-operator`}
          >
            <option value="AND">And</option>
            <option value="OR">Or</option>
          </Form.Control>
        </div>
      </div>
      <div>
        {showPhraseOption && (
          <Form.Check
            type="switch"
            value={true}
            id={`${name}-multimatch-whole-phrase`}
            checked={execute.phrase || false}
            onChange={(e) => handleChange("phrase", e)}
            label="Whole Phrase"
          />
        )}
      </div>
    </div>
  );
}

export default Search;
