import { useCallback, useMemo, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useDataStateSelector, useDataSettings } from "../../hooks/useData";
import usePermissions from "../../hooks/usePermissions";
import useSession from "../../hooks/useSession";
import useConfirmNavigation from "../../hooks/useConfirmNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faUserAstronaut,
  faSpinner,
  faCog,
  faFingerprint,
  faUser,
  faUsers,
  faWifi,
  faBug,
  faFlask,
} from "@fortawesome/free-solid-svg-icons";

import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
//import { userLogOut, checkUserLogged } from "../../redux/actions/user";

import Nick from "./nick";
import DataPanel from "./dataPanel";
import UsersPanel from "./usersPane";
import SubmitBug from "./submitBug";
import ListBug from "./listBug";

import "./style.scss";
const url = require("url");
const navStyle = { color: "greenyellow" };

export default function NavigationBar(props) {
  const submitBugRef = useRef({});
  const listBugRef = useRef({});

  const confirmNavigation = useConfirmNavigation();
  const { saving, loading } = useDataStateSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, isLogged } = useSession();
  const menuItems = useDataSettings("menuItems");
  const {
    nickname,
    tenant,
    picture,
    loggedStatus,
    loggedUsers: logged,
  } = useSelector((state) => state.user);
  const permissions = usePermissions();

  useEffect(() => {
    setTimeout(() => isLogged(tenant), 500);
    const checker = setInterval(() => isLogged(tenant), 15000);

    return () => clearInterval(checker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = useCallback(
    (href) => {
      if (href === "/") return location.pathname === "/";
      return location.pathname.includes(href);
    },
    [location.pathname]
  );

  const navClick = useCallback(
    (e) => {
      e.preventDefault();
      const destination = url.parse(e.target.href).pathname;

      confirmNavigation(destination);
    },
    [confirmNavigation]
  );

  const doLogout = useCallback(
    () => confirmNavigation("", () => logout()),
    [confirmNavigation, logout]
  );

  const menuBar = useMemo(() => {
    return (
      <Nav className="mr-auto" defaultActiveKey="/">
        {(menuItems || []).map((item, i) => {
          if (item.divider) return <NavDropdown.Divider key={`menu-nav-item-${item.label}-${i}`} />;

          if (!item.permission.includes("*")) {
            const itemPerm = Array.isArray(item.permission) ? item.permission : [item.permission];

            if (!permissions.read.some((k) => itemPerm.includes(k))) return null;
          }

          if (item.sub) {
            return (
              <NavDropdown
                key={`nav-drop-${item.label}-${i}`}
                title={
                  <>
                    {item.analiticaIcon ? (
                      <FontAwesomeIcon
                        icon={faFlask}
                        style={{ marginRight: "4px", color: "#ff1493", fontSize: "0.8em" }}
                      />
                    ) : null}
                    {item.analiticaIcon ? (
                      <span style={{ color: "#ff1493", fontWeight: 500 }}>{item.label}</span>
                    ) : (
                      item.label
                    )}
                  </>
                }
                id={`nav-drop-${item.label}-${i}`}
                className="menu-label-item"
              >
                {item.sub.map((d, k) => {
                  if (d.divider)
                    return <NavDropdown.Divider key={`menu-item-drop-${item.label}-${k}`} />;

                  if (d.header)
                    return (
                      <NavDropdown.Header key={`menu-item-drop-${d.header}-${k}`}>
                        {d.header}
                      </NavDropdown.Header>
                    );

                  if (!d.permission.includes("*")) {
                    const itemPerm = Array.isArray(d.permission) ? d.permission : [d.permission];
                    if (!permissions.read.some((k) => itemPerm.includes(k))) return null;
                  }

                  return (
                    <NavDropdown.Item
                      key={`menu-item-drop-${d.label}-${k}`}
                      href={d.href}
                      onClick={navClick}
                    >
                      {d.label}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            );
          }
          return (
            <NavItem
              key={`menu-nav-item-${item.label}-${i}`}
              eventkey={i}
              className="menu-label-item"
            >
              <Nav.Link
                key={`menu-item-${item.label}-${i}`}
                href={item.href}
                onClick={navClick}
                active={isActive(item.href)}
              >
                {item.label}
              </Nav.Link>
            </NavItem>
          );
        })}
      </Nav>
    );
  }, [isActive, menuItems, navClick, permissions]);

  const userMenu = useMemo(() => {
    return (
      <Nav>
        <NavDropdown
          title={
            <span>
              <Nick name={nickname} tenant={tenant} picture={picture} />
            </span>
          }
          id="user-drop-down"
          //alignright
        >
          <NavDropdown.Item onClick={() => navigate(`/users/${nickname}`)}>
            <FontAwesomeIcon icon={faUserAstronaut} className="menuIcon" />
            Profile
          </NavDropdown.Item>

          {permissions.expert.includes("bug-report") && (
            <>
              <NavDropdown.Divider />
              <NavDropdown.Header key={`menu-item-drop-bugs`}>
                <FontAwesomeIcon icon={faBug} /> Bugs & Issues
              </NavDropdown.Header>
              <NavDropdown.Item onClick={() => submitBugRef.current.show()}>
                Submit a Bug
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => listBugRef.current.show()}>
                Show all Bugs
              </NavDropdown.Item>
            </>
          )}

          <NavDropdown.Divider />
          <NavDropdown.Item onClick={doLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="menuIcon" />
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }, [doLogout, navigate, nickname, permissions.expert, picture, tenant]);

  const loggedUsersPane = useMemo(() => {
    if (!logged?.length > 0) return null;
    //{({ placement, arrowProps, show: _show, popper, ...props }) => (
    return (
      <OverlayTrigger
        key="data-panel"
        placement="bottom"
        trigger="click"
        overlay={
          <Popover id={`tooltip-logged-panel`} bsPrefix="ares-logged-panel">
            <UsersPanel />
          </Popover>
        }
      >
        <div className="loggedUsers">
          <FontAwesomeIcon icon={logged.length > 1 ? faUsers : faUser} className="userIcon" />
          {logged.length}
        </div>
      </OverlayTrigger>
    );

    // return (
    //   <OverlayTrigger
    //     key="data-panel"
    //     placement="bottom"
    //     trigger="click"
    //     overlay={(props) => {
    //       return (
    //         <Popover id={`tooltip-logged-panelX`} {...props} bsPrefix="ares-logged-panel">
    //           <UsersPanel />
    //         </Popover>
    //       );
    //     }}
    //   >
    //     <div className="loggedUsers">
    //       <FontAwesomeIcon icon={logged.length > 1 ? faUsers : faUser} className="userIcon" />
    //       {logged.length}
    //     </div>
    //   </OverlayTrigger>
    // );
  }, [logged]);

  const loggedPane = useMemo(() => {
    //if (loggedStatus === undefined) return null;

    return (
      <div className="signal-pane">
        <FontAwesomeIcon
          icon={faWifi}
          className={loggedStatus?.status ? "OK" : loggedStatus?.reason ? "KK" : "KO"}
          onDoubleClick={() => navigate(`/eastereggs/sudares`)}
        />
        {!loggedStatus?.status ? (
          <Button size="sm" variant="outline-danger" onClick={() => logout()}>
            {loggedStatus?.reason || "Not Logged"}
            {loggedStatus?.API ? <div className="API-VERSION">{loggedStatus?.API}</div> : null}
          </Button>
        ) : (
          <div className="API-VERSION">{loggedStatus?.API}</div>
        )}
      </div>
    );
  }, [loggedStatus?.API, loggedStatus?.reason, loggedStatus?.status, logout, navigate]);

  return (
    <>
      <SubmitBug ref={submitBugRef} />
      <ListBug ref={listBugRef} />
      <div className="row">
        <Navbar expand="xl" fixed="top" variant="dark">
          <Navbar.Brand href="#home" style={navStyle}>
            <OverlayTrigger
              key="data-panel"
              placement="bottom"
              trigger="click"
              overlay={
                permissions.read.includes("admin") ? (
                  <Popover id={`tooltip-data-panel`} bsPrefix="ares-data-panel">
                    <DataPanel />
                  </Popover>
                ) : (
                  <div></div>
                )
              }
            >
              <div>
                <FontAwesomeIcon
                  icon={loading ? faCog : saving ? faSpinner : faFingerprint}
                  spin={saving || loading}
                />{" "}
                <span className="logo-main">4R3S</span>
              </div>
            </OverlayTrigger>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="nav-toggle-button" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {menuBar}
            <div className="toolbar">
              {loggedUsersPane}
              {loggedPane}
            </div>
            {userMenu}
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
}
