import { db } from "../../db";

export default function validate(values, validateUI = true) {
  const ENDPOINTS = [...(db.getTable("endpoints")?.values() ?? [])];
  const existingReference = ENDPOINTS.find(
    ({ reference }) => reference === values.reference
  );
  const referenceError = this?.isNew && existingReference ? true : false;

  const errors = {};

  if (validateUI) {
    if (!values.blockId) {
      errors.blockId = "You Must Specify a Block";
      errors.settings = true;
    }

    if (!values.reference) {
      errors.reference = "You Must Endpoint UX Reference";
      errors.settings = true;
    }
  }

  if (!values.paramsId) {
    errors.paramsId = "You Must Specify Params";
    errors.settings = true;
  }

  if (referenceError) {
    errors.reference = "Reference Already Exists";
    errors.settings = true;
  }

  return errors;
}
