import { useMemo, useState, useCallback } from "react";
import { Form } from "react-bootstrap";

import styles from "./knob.module.scss";

export default function RotaryKnob(props) {
  const {
    label,
    labelPosition = "bottom",
    min = 1,
    max = 10,
    step = 1,
    decimals = 0,
    onChange,
    value,
  } = props;

  const [valore, setValore] = useState(value);

  const theLabel = useMemo(() => {
    if (!label) return null;

    return (
      <div className={styles[labelPosition]}>
        {label} {decimals ? value : ""}
      </div>
    );
  }, [decimals, label, labelPosition, value]);

  const handleChange = useCallback(
    (e) => {
      const value = Number(e.target.value);
      setValore(value);
      if (onChange) onChange(value);
    },
    [onChange]
  );

  return (
    <div
      className={`${styles.container} ${styles[`container_${labelPosition}`]} ${props.className}`}
      style={props.style}
    >
      {theLabel}
      <Form.Control
        type="number"
        className={styles.knob}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
      />
    </div>
  );
}
