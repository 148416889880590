import { useMemo } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

export default function Duration(props) {
  const { value, unit = "seconds", decimalSeconds = false, showIcon = true } = props;

  const [seconds, minutes, hours, days, months, years] = useMemo(() => {
    if (value) {
      const duration = moment.duration(value, unit);

      const minutes = duration.minutes();
      const hours = duration.hours();
      const days = duration.days();
      const seconds = decimalSeconds
        ? (duration.asSeconds() - minutes * 60).toFixed(2)
        : duration.seconds();
      const months = duration.months();
      const years = duration.years();

      return [seconds, minutes, hours, days, months, years];
    }
    return [0, 0, 0, 0, 0, 0];
  }, [value, unit, decimalSeconds]);

  return (
    <div className="duration-box" style={{ marginLeft: !showIcon ? "0px" : "" }}>
      {showIcon ? <FontAwesomeIcon icon={faExchangeAlt} className="duration-icon" /> : null}
      {years > 0 && (
        <>
          <span className="duration-item">{years}</span>years
        </>
      )}
      {months > 0 && (
        <>
          <span className="duration-item">{months}</span>months
        </>
      )}
      {days > 0 && (
        <>
          <span className="duration-item">{days}</span>days
        </>
      )}
      {hours > 0 && (
        <>
          <span className="duration-item">{hours}</span>hours
        </>
      )}
      {minutes > 0 && (
        <>
          <span className="duration-item">{minutes}</span>minutes
        </>
      )}
      {seconds > 0 && (
        <>
          <span className="duration-item">{seconds}</span>seconds
        </>
      )}
    </div>
  );
}
