import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";

import Item from "../item";
import Preset from "./preset";
import validate from "./validation";

export function doPresetRefs(values) {
  const newValues = cloneDeep(values);
  const rules = new Set();

  newValues.rules?.forEach((rule) => rules.add(rule.id));
  set(newValues, "refs.require", [{ type: "rules", items: [...rules] }]);

  return newValues;
}

export default function ListContainer(props) {
  return (
    <Item name="Rule Preset" accessor="rulesPresets" onValidate={validate} onSubmit={doPresetRefs}>
      <Preset />
    </Item>
  );
}
