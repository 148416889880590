import { useState, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import { Row, Col, Form } from "react-bootstrap";
//import { useItemContext } from "../../hooks/useItem";
import { useDataSettings } from "../../hooks/useData";

import ErrorMessage from "../errorMessage";
import "./style.scss";

function Clauses() {
  const { values, setFieldValue } = useFormikContext();
  //const { setItemErrors } = useItemContext();
  const [clauses, setClauses] = useState();
  const clausesLabels = useDataSettings("queryClauses");

  useEffect(() => {
    const ret = {};
    if (values.clauses) {
      values.clauses.map((c) => (ret[c] = 1));
    }

    setClauses(ret);
  }, [values.clauses]);

  const handleChange = useCallback(
    (e) => {
      const newClauses = { ...clauses };

      if (e.target.checked) {
        newClauses[e.target.value] = 1;
      } else {
        delete newClauses[e.target.value];
      }

      setClauses(newClauses);
      setFieldValue("clauses", Object.keys(newClauses));
    },
    [clauses, setFieldValue]
  );

  return (
    <Row className="settings-row">
      <Col sm="1">Clauses</Col>
      <Col sm="10">
        <div className="clauses-box">
          {clausesLabels?.map((clause, i) => (
            <Form.Check
              type="checkbox"
              key={`clause-${i}`}
              value={clause.accessor}
              onChange={handleChange}
              checked={(values.clauses && values.clauses.includes(clause.accessor)) || false}
              label={<span className="clause-label">{clause.label}</span>}
              inline
            />
          ))}
        </div>
        <ErrorMessage name="clauses" />
      </Col>
    </Row>
  );
}

export default Clauses;
