import { cloneElement, forwardRef, useCallback, useState, useMemo } from "react";
import { Dropdown, FormControl, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Editor from "@monaco-editor/react";

import styles from "./styles.module.scss";
import "./style.scss";
import set from "lodash.set";
const defTextColor = "#fff";

const CustomToggle = forwardRef((props, ref) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      props.onClick(e);
    },
    [props]
  );

  const style = useMemo(() => ({ color: props.textColor || defTextColor }), [props.textColor]);
  return (
    <div
      className={`${
        props.className !== "dropdown-toggle" ? props.className : ""
      } custom-select-button ${props.errored ? "select-errored" : ""}`}
      variant={props.errored ? "outline-danger select-errored" : "dark"}
      style={{
        textAlign: "left",
        width: "100%",
        ...props.style,
      }}
      disabled={props.disabled}
      onClick={handleClick}
      ref={ref}
    >
      <span className="select-selection">
        <span className="select-selection-text" style={style}>
          {props.children}
        </span>
      </span>
      <FontAwesomeIcon
        icon={faChevronDown}
        style={{
          float: "right",
          marginTop: "4px",
          color: props.errored ? "red" : "white",
        }}
      />
    </div>
  );
});

const CustomMenu = forwardRef((props, ref) => {
  const {
    children,
    style,
    className,
    "aria-labelledby": labeledBy,
    onFilter,
    onSubFilter,
    canFilter,
    filters,
    info,
    show,
  } = props;
  const [value, setValue] = useState("");
  const [subFilter, setSubFilter] = useState({});

  const onChangeFilter = useCallback(
    (e) => {
      e.preventDefault();

      const value = e.target.value.toLowerCase(); //.trim();
      setValue(value);
      onFilter(value);
    },
    [onFilter]
  );

  const onUpdateSubFilters = useCallback(
    (value) => {
      const newSub = { ...subFilter, ...value };

      setSubFilter(newSub);
      if (onSubFilter) onSubFilter(newSub);
    },
    [onSubFilter, subFilter]
  );

  const selectFilters = useMemo(() => {
    return filters?.map((filter, i) =>
      cloneElement(filter, {
        key: `${i}-filter`,
        filters: subFilter,
        onUpdateFilters: onUpdateSubFilters,
      })
    );
  }, [filters, onUpdateSubFilters, subFilter]);

  return (
    <div style={style} className={className} aria-labelledby={labeledBy} ref={ref}>
      <div className={styles.filterBox}>
        {canFilter ? (
          <FormControl
            autoFocus
            className={`mx-3 my-2 ${styles.search}`}
            placeholder="Type to filter..."
            onChange={onChangeFilter}
            value={value}
            size="sm"
          />
        ) : null}
        {info && (
          <OverlayTrigger
            trigger="click"
            key={`refbox-select`}
            placement="right"
            show={show ? undefined : false}
            overlay={
              <Popover
                id={`refbox-positioned-bottom`}
                bsPrefix="ares-refbox-select-pop"
                placement="bottom"
              >
                <Popover.Header as="h3">Select Filters</Popover.Header>
                <Popover.Body>
                  <Editor
                    width="100%"
                    height="220px"
                    defaultLanguage="json"
                    value={JSON.stringify(info, null, 1)}
                    defaultValue="// No Data Are you in DEV?"
                    theme="vs-dark"
                    options={{ minimap: { enabled: false }, folding: true }}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <span>
              <FontAwesomeIcon icon={faCircleQuestion} className={styles.icon} />
            </span>
          </OverlayTrigger>
        )}
      </div>

      {selectFilters}
      <div className="dropdown-items-box">{children}</div>
    </div>
  );
});

export default function Select(props) {
  const {
    name,
    value,
    children,
    disabled,
    errored,
    isInvalid,
    style,
    filters,
    backgroundColor,
    canFilter,
    onFilter,
    onSubFilter,
    onSelect,
    className,
    textColor,
    info,
  } = props;

  return (
    <Dropdown onSelect={onSelect} style={style} className={className}>
      <Dropdown.Toggle
        as={CustomToggle}
        disabled={disabled}
        errored={isInvalid || errored}
        id={name}
        textColor={textColor}
        //className={className}
        style={{
          ...style,
          background: `${backgroundColor}`,
        }}
      >
        {value}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        onFilter={onFilter}
        onSubFilter={onSubFilter}
        canFilter={canFilter}
        filters={filters}
        flip={true}
        info={info}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}
