/* eslint-disable no-undef */
import { useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";
import { userLogOut, setLoggedStatus, setLoggedUsers } from "../redux/actions/user";

import { playSound } from "../utils/audio";
import { useAuth0 } from "@auth0/auth0-react";
import { socket } from "../transport";

const disconnected = { status: false, loggedUsers: [] };

const disconnect = () => socket.disconnect();

export default function useSession() {
  const dispatch = useDispatch();
  const auth0 = useAuth0();

  const logout = useCallback(() => {
    auth0.logout({ returnTo: window.location.origin, federated: false });
    dispatch(userLogOut());
    disconnect();
  }, [auth0, dispatch]);

  const login = useCallback((tenant, id_token) => {
    socket.emit("login", { tenant, id_token });
  }, []);

  const isLogged = useCallback(
    (tenant) => {
      socket.emit("isLogged", tenant, (data) => {
        dispatch(setLoggedStatus(data));
        return data?.status;
      });
    },
    [dispatch]
  );

  const isActive = useCallback((body, cb) => socket.emit("isActive", body, (data) => cb(data)), []);

  useEffect(() => {
    socket.on("disconnect", (reason) => {
      playSound("ko");
      dispatch(setLoggedStatus({ ...disconnected, reason }));
    });

    socket.on("logout", () => logout());

    socket.on("logged-users", (logged) => {
      dispatch(setLoggedUsers(logged));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { logout, login, isLogged, isActive };
}
