import {
  Fragment,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from "react";
import { useSelector } from "react-redux";
import {
  Button,
  NavDropdown,
  OverlayTrigger,
  Popover,
  Tooltip
} from "react-bootstrap";
import useItem, { ItemContext } from "../../hooks/useItem";
import { useDataSetStatus, useDataStateSelector } from "../../hooks/useData";
import { useNavigate, useLocation } from "react-router";
import useClipboard from "../../hooks/useClipboard";
import useSession from "../../hooks/useSession";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faSpinner,
  faAngleDoubleLeft,
  faClipboard,
  faCalendar,
  faCopy,
  faPaste,
  faExclamationCircle,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import niceTry from "nice-try";
import capitalize from "lodash.capitalize";
import Container from "../container";
import NameAndDescription from "../nameAndDescription";
import RefBox from "./ref";
import cloneDeep from "lodash.clonedeep";
import diff from "variable-diff";
import Editor from "@monaco-editor/react";
import styles from "./styles.module.scss";
import "./style.scss";

import Scheduling from "../scheduleDiagram";

const emptyObj = {};

export default function Item(props) {
  const [initiated, setInitiated] = useState(false);
  const emptyValuesObj = useMemo(() => ({}), []);
  const formRef = useRef();
  const nameRef = useRef();
  const activeBodyRef = useRef(false);
  const [isSaving, setIsSaving] = useState(false);
  const setDataStatus = useDataSetStatus();
  const tenant = useSelector((state) => state.user.tenant);
  const { clipboard, copy } = useClipboard();
  const clip = useMemo(
    () => niceTry(() => JSON.parse(clipboard)) ?? emptyObj,
    [clipboard]
  );
  const canSave = useDataStateSelector((state) => state.canSave);
  const item = useItem(props);
  const navigate = useNavigate();
  const location = useLocation();
  const showTitleAndDesc =
    props.showTitleAndDesc === undefined ? true : props.showTitleAndDesc;
  const [locked, setLocked] = useState(false);
  const { isActive } = useSession();
  const [currentValues, setCurrentValues] = useState(
    item.item ?? emptyValuesObj
  );

  const { onInit, onValidate, onSubmit, onSubmitted, accessor } = props;

  const handleInit = useCallback(() => {
    let values = item.item ?? emptyValuesObj;

    if (!initiated) {
      if (onInit) {
        const newValues = onInit(cloneDeep(values), item);
        if (newValues) {
          values = newValues;
          setCurrentValues(newValues);
          item.setItemValues(newValues);
        }
      }
      setInitiated(true);
    }

    return values;
  }, [emptyValuesObj, initiated, item, onInit]);

  const logActive = useCallback(
    (action) => {
      const body = { action, ...activeBodyRef.current };

      isActive(body, (data) => {
        if (data) {
          setLocked(data.currentActive ? data.currentActive.username : false);
        }
      });
    },
    [isActive]
  );

  useEffect(() => {
    if (!activeBodyRef.current && !item.isNew && item.item) {
      activeBodyRef.current = {
        id: item.itemId,
        title: item.item.title,
        type: props.name,
        accessor,
        tenant
      };
      logActive("enter");
    }
  }, [
    accessor,
    item.isNew,
    item.item,
    item.itemId,
    logActive,
    props.name,
    tenant
  ]);

  useEffect(() => {
    return () => {
      if (activeBodyRef.current) {
        logActive("exit");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmission = useCallback(
    (values, { setSubmitting }) => {
      setIsSaving(true);
      setSubmitting(false);
      const data = onSubmit ? onSubmit(cloneDeep(values)) : values;

      return new Promise((resolve) => {
        item.onSubmission(data, (id, isNew) => {
          if (onSubmitted) onSubmitted(id, isNew);

          if (isNew) {
            const pathname = location.pathname;
            const path = `${pathname.substring(
              0,
              pathname.lastIndexOf("/")
            )}/${id}`;
            navigate(path);
          }

          setDataStatus("diff", emptyObj);
          setIsSaving(false);
          resolve(data);
        });
      });
    },
    [item, location.pathname, navigate, onSubmit, onSubmitted, setDataStatus]
  );

  const handleValidation = useCallback(
    (values) => {
      setCurrentValues(values);
      const errors = {};

      if (!values.title) errors.title = "You Must insert a Title";
      const validation = onValidate
        ? onValidate.call({ ...item, formik: formRef.current, tenant }, values)
        : emptyObj;

      if (validation.then) {
        return validation.then((validationErrors) => ({
          ...validationErrors,
          ...errors
        }));
      } else return { ...validation, ...errors };
    },
    [item, onValidate, tenant]
  );

  const title = useMemo(() => {
    return (
      <div className="title-box" onClick={() => navigate(-1)}>
        <span className="go-back">
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </span>
        <span className="title">{props.name}</span>
      </div>
    );
  }, [navigate, props.name]);

  const copyId = useCallback(() => {
    copy(item.itemId);
  }, [copy, item.itemId]);

  const header = useMemo(() => {
    return (
      <div className="header">
        <span className="id-label">#Id:</span>{" "}
        <span className="id">{item.itemId}</span>{" "}
        <FontAwesomeIcon
          icon={faClipboard}
          className="copyId"
          onClick={copyId}
        />
        {item.item && item.item.scheduled ? (
          <OverlayTrigger
            trigger="click"
            key={`scheduling-${item.accessor}`}
            placement="bottom"
            overlay={
              <Popover
                id={`popover-positioned-bottom`}
                bsPrefix="ares-scheduling-item-pop"
                placement="bottom"
              >
                <Popover.Body>
                  <Scheduling itemType={item.accessor} itemId={item.itemId} />
                </Popover.Body>
              </Popover>
            }
          >
            <span className="schedule-block">
              <FontAwesomeIcon icon={faCalendar} />
              <span className="schedule-item-label">Scheduled</span>
            </span>
          </OverlayTrigger>
        ) : null}
      </div>
    );
  }, [copyId, item]);

  const copyItem = useCallback(
    () =>
      copy(JSON.stringify({ _ares: item.accessor, item: item.item, tenant })),
    [copy, item.accessor, item.item, tenant]
  );

  const pasteItem = useCallback(() => {
    const data = clip.item;
    data.id = item.itemId;
    data.lastSaved = undefined;
    data.version = undefined;
    data.refs = undefined;
    data.refsComputed = undefined;
    data.title = `${data.title} [Copy]`;

    nameRef?.current?.setFold?.(true);
    formRef.current.setValues(data);
  }, [clip.item, item.itemId]);

  const extra = useMemo(() => {
    const label = capitalize(item.name);

    return (
      <div className={styles.extraBox}>
        <RefBox
          accessor={item.accessor}
          data={currentValues}
          formikRef={formRef}
        />

        {locked ? (
          <div className={styles.locked}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.lockIcon}
            />
            <b>{locked}</b> is editing this {props.name}
          </div>
        ) : null}
        <div className={styles.toolbar}>
          <NavDropdown
            title={<FontAwesomeIcon icon={faClipboard} />}
            id="nav-dropdown-conditions-clip"
            className={styles.extraMenu}
          >
            <NavDropdown.Item eventKey="1.1" onClick={copyItem}>
              <FontAwesomeIcon icon={faCopy} className={styles.copyIcon} />
              Copy {label}
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="1.2"
              onClick={pasteItem}
              disabled={clip._ares !== item.accessor || clip.tenant !== tenant}
              className={
                clip._ares !== item.accessor ? styles.disabledMenuItem : ""
              }
            >
              <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
              Paste {label}
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    );
  }, [
    clip._ares,
    clip.tenant,
    copyItem,
    currentValues,
    item.accessor,
    item.name,
    locked,
    pasteItem,
    props.name,
    tenant
  ]);

  if (!item) return null;

  const errors = formRef.current?.errors ?? emptyObj;
  const isInvalid = Object.keys(errors).length > 0;

  return (
    <Container
      title={title}
      help={`item-${props.accessor}`}
      color="#111"
      coloredBars={true}
      coloredHeader={false}
      foldable={false}
      header={header}
      extra={extra}
      footer={
        <>
          <Button
            type="reset"
            variant="outline-secondary"
            size="sm"
            onClick={() => {
              if (formRef.current) formRef.current.handleReset();
            }}
            disabled={!formRef.current?.dirty}
          >
            Reset
          </Button>
          &nbsp;
          <Button
            type="submit"
            size="sm"
            variant={
              isInvalid
                ? "outline-danger"
                : canSave
                ? "success"
                : "outline-success"
            }
            onClick={() => {
              if (canSave && !isInvalid && formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            {isSaving ? (
              <FontAwesomeIcon icon={faSpinner} pulse />
            ) : item.permissions.write.includes(props.accessor) ? (
              <>
                {canSave && locked && (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className={styles.lockIconSave}
                    />
                  </>
                )}
                Save
              </>
            ) : (
              <FontAwesomeIcon icon={faLock} />
            )}
          </Button>
          <SaveButton
            errors={errors}
            isInvalid={isInvalid}
            formik={formRef.current}
          />
        </>
      }
      headerOnlyOnFolded={false}
      hover={false}
    >
      <Fragment>
        {item.isNew !== true && item.item === undefined ? null : (
          <Formik
            initialValues={handleInit()}
            enableReinitialize={true}
            validateOnMount={true}
            validate={handleValidation}
            onSubmit={handleSubmission}
            innerRef={formRef}
          >
            {(formikProps) => {
              const dirty = props.dirty
                ? props.dirty(formikProps.values, item.item ?? emptyValuesObj)
                : formikProps.dirty;

              const stopSave =
                !item.permissions.write.includes(props.accessor) ||
                !formikProps.isValid ||
                formikProps.isSubmitting ||
                !dirty;

              setDataStatus("unsavedData", dirty);
              setDataStatus("canSave", !stopSave);
              setDataStatus("diff", diff(item.item, formikProps.values));

              //dirty && console.log(require("variable-diff")(item.item, formikProps.values).text);

              return (
                <Fragment>
                  <Form>
                    <ItemContext.Provider value={item}>
                      {showTitleAndDesc ? (
                        <Fragment>
                          <NameAndDescription
                            isNew={item.isNew}
                            ref={nameRef}
                            color={props.NameAndDescriptionColor}
                          />
                        </Fragment>
                      ) : null}

                      {props.children}
                    </ItemContext.Provider>
                  </Form>
                </Fragment>
              );
            }}
          </Formik>
        )}
      </Fragment>
    </Container>
  );
}

function SaveButton(props) {
  const { errors, isInvalid } = props;
  const { changed, text } = useDataStateSelector(
    (state) => state.diff ?? emptyObj
  );

  if (!changed && !isInvalid) return null;

  return (
    <div className={styles.differenceBox}>
      <OverlayTrigger
        key="save-differences"
        placement="top"
        trigger={["click"]}
        overlay={
          <Tooltip id="tooltip-save-differences">
            <div className={styles.differenceEditor}>
              <Editor
                width="700px"
                height="30vh"
                defaultLanguage="ruby"
                value={isInvalid ? JSON.stringify(errors, null, 2) : text}
                defaultValue="// Insert your code"
                theme="vs-dark"
                options={{ minimap: { enabled: false }, folding: true }}
              />
            </div>
          </Tooltip>
        }
      >
        <span className={styles.diffIcon}>
          <FontAwesomeIcon icon={faGripVertical} />
        </span>
      </OverlayTrigger>
    </div>
  );
}
