import { memo, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import get from "lodash.get";

import { useFormikContext } from "formik";

import "./style.scss";

export default memo(function ErrorMessage(props) {
  const { name, errorMessage, itemErrors, onClick, style } = props;
  const { errors } = useFormikContext() || {};

  const isInError = useMemo(() => get(itemErrors || errors, name), [errors, itemErrors, name]);
  if (!isInError) return null;

  return (
    <div className="error-message" style={style} onClick={onClick}>
      {get(itemErrors || errors, name) ? (
        <span className="error-message-text">
          <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />{" "}
          {errorMessage || get(itemErrors || errors, name)}
        </span>
      ) : null}
    </div>
  );
});
