import { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import set from "lodash.set";
import Container from "../container";
import SelectAll from "../selects/selectAll";
import RotaryKnob from "../rotaryKnob";
import { db } from "../../db";

import styles from "./styles.module.scss";

export default function PackChooser() {
  const { values, errors, setFieldValue, setValues } = useFormikContext();

  const handlePackId = useCallback(
    (id) => {
      const valori = { ...values };

      const pack = db.get(
        values.pack?.type === "conversions"
          ? "conversionPacks"
          : values.pack?.type === "list"
          ? "lists"
          : values.pack?.type === "query"
          ? "queries"
          : "contentPacks",
        id
      );

      set(valori, "pack.id", id);
      set(valori, "userBased", pack.userBased ?? false);
      set(valori, "useSimilarity", pack.useSimilarity ?? false);
      set(valori, "contentType", pack.contentType);
      set(valori, "paramsId", pack.paramsId ?? valori.paramsId);
      set(valori, "writeOutput", false);
      set(valori, "fetchUserMetadata", false);

      if (!valori.userBased) set(valori, "pipelines.dynamic", []);

      setValues(valori);
    },
    [setValues, values]
  );

  const handleType = useCallback(
    (type) => {
      const valori = { ...values };
      set(valori, "pack.type", type);
      set(valori, "pack.id", undefined);
      set(valori, "userBased", undefined);
      set(valori, "useSimilarity", undefined);
      set(valori, "contentType", undefined);
      set(valori, "paramsId", undefined);
      set(valori, "writeOutput", false);
      set(valori, "fetchUserMetadata", false);
      set(valori, "numElements", 20);

      setValues(valori);
    },
    [setValues, values]
  );

  const filters = useMemo(() => {
    const filters = { exclude: [] };
    if (values.pack?.id) filters.exclude.push(values.pack.id);
    if (values.pack?.type === "list") filters.objectTypes = ["contentId"];

    return filters;
  }, [values.pack?.id, values.pack?.type]);

  return (
    <Container
      title="Ids From"
      help="meta-pack-chooser"
      coloredBars={false}
      color="#04233b"
      headerOnlyOnFolded={false}
      folded={true}
      foldable={false}
      warningMessage={errors.pack}
      header={
        <div className={styles.header}>
          <div className={styles.radio}>
            {["conversions", "content", "query", "list"].map((type) => (
              <Form.Check
                name="groupOptions"
                type="radio"
                id={`check-${type}`}
                checked={values.pack?.type === type || false}
                label={`${type[0].toUpperCase()}${type.slice(1)} ${
                  ["conversions", "content"].includes(type) ? "Pack" : ""
                }`}
                inline
                onChange={() => handleType(type)}
              />
            ))}
          </div>
          <div className={styles.selects}>
            {values.pack?.type && (
              <SelectAll
                accessor={
                  values.pack?.type === "conversions"
                    ? "conversionPacks"
                    : values.pack?.type === "list"
                    ? "lists"
                    : values.pack?.type === "query"
                    ? "queries"
                    : "contentPacks"
                }
                handleChange={handlePackId}
                value={values.pack?.id}
                filters={filters}
              />
            )}
            {["list", "query"].includes(values.pack?.type) && values.pack?.id && (
              <>
                <span className={styles.paramsLabel}>Params</span>
                <SelectAll
                  accessor="params"
                  value={values.paramsId}
                  handleChange={(v) => setFieldValue("paramsId", v)}
                  isInvalid={errors.paramsId}
                />
              </>
            )}
            {values.pack?.type === "query" && values.pack?.id && (
              <div className={styles.numElements}>
                <RotaryKnob
                  value={values.numElements ? Number(values.numElements) : 20}
                  min={1}
                  max={100}
                  step={1}
                  label="Num"
                  labelPosition="left"
                  skin="s10"
                  onChange={(v) => setFieldValue("numElements", v)}
                  className="repeated-meta-knob"
                />
              </div>
            )}
          </div>
        </div>
      }
      extra={
        <div className={styles.extra}>
          <div className={styles.contentType}>{values.contentType}</div>
          {values.userBased && <div className={styles.userBased}>user based</div>}
          {values.useSimilarity && <div className={styles.userBased}>use similarity</div>}
        </div>
      }
    />
  );
}
