import { useCallback } from "react";
import db from "./useMemoryDB";

export function useUpdateRequest() {
  const cb = useCallback((owner, metadata) => {
    if (owner) {
      db.deleteByQuery("metadata", (i) => {
        if (i.owner !== owner) return false;
        if (i.realm !== "request") return false;
        if (!i.volatile) return false;
        return true;
      });
    }

    if (metadata) {
      for (const data of metadata) {
        db.put("metadata", {
          ...data,
          owner,
          volatile: true,
        });
      }
    }
  }, []);
  return cb;
}

export function useUpdateMetadata() {
  const cb = useCallback(({ nextId, types, realm, catalog, owner }, cb) => {
    if (owner) {
      db.deleteByQuery("metadata", (i) => {
        if (i.owner !== owner) return false;
        if (i.realm !== realm) return false;
        return true;
      });
    }

    if (Array.isArray(nextId)) {
      for (const id of nextId) {
        const data = {
          realm,
          catalog,
          types,
          owner,
          id,
          values: [],
          volatile: true,
        };
        db.put("metadata", data);
        cb && cb(data);
      }
    } else if (nextId) {
      const data = {
        realm,
        catalog,
        types,
        owner,
        id: nextId,
        values: [],
        volatile: true,
      };
      db.put("metadata", data);
      cb && cb(data);
    }
  }, []);
  return cb;
}
