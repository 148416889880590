import { Fragment, useState, useRef, useCallback, useMemo } from "react";
import { Overlay, Tooltip, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import { useDataSaveHelp, useDataSaveNotes } from "../../hooks/useData";
import usePermissions from "../../hooks/usePermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEdit,
  faStickyNote,
  faPlusSquare,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import styles from "./help.module.scss";
import "./general.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const defaultStyle = { marginLeft: "6px" };
const emptyArray = [];
export default function Help(props) {
  const { code, placement = "right", style = defaultStyle } = props;
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const permissions = usePermissions();
  const saveNotes = useDataSaveNotes({ notify: true });
  const saveHelp = useDataSaveHelp({ notify: true });

  const target = useRef(null);

  const rawData = useGetMemItem("help", code)?.description || "";
  const rawNotes = useGetMemItem("notes", code)?.notes ?? emptyArray;
  const notesNum = useMemo(() => rawNotes?.length, [rawNotes]);

  const updateData = useCallback(
    (dx, cb) => {
      saveHelp({ code, dx }, (data) => {
        if (data.code === code) {
          // setEdit(false);
        }
        cb && cb(data);
      });
    },
    [code, saveHelp]
  );

  const upsertNotes = useCallback(
    (dx, cb) => {
      saveNotes({ code, dx }, (data) => {
        if (data.code === code) {
          //setShowNotes(false);
        }
        cb && cb(data);
      });
    },
    [code, saveNotes]
  );

  return (
    <div className={styles.container} style={style}>
      <div ref={target} onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
      </div>

      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose={true}
        onHide={() => true}
      >
        {(props) => (
          <Tooltip id={code} {...props} className={styles.tooltip}>
            {edit ? (
              <HelpEditor data={rawData} updateData={updateData} reset={() => setEdit(false)} />
            ) : showNotes ? (
              <HelpNotes close={() => setShowNotes(false)} data={rawNotes} update={upsertNotes} />
            ) : (
              <Fragment>
                <div
                  dangerouslySetInnerHTML={{ __html: rawData || "<p>TBD</p>" }}
                  className={styles.body}
                />
                <div className={styles.toolbar}>
                  {permissions.write?.includes("help") ? (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className={styles.iconEdit}
                      onClick={(e) => setEdit(true)}
                    />
                  ) : null}

                  <span className={styles.comments} onClick={() => setShowNotes(true)}>
                    <FontAwesomeIcon icon={faStickyNote} className={styles.icon} />
                    <span className={styles.num}>{notesNum}</span>
                  </span>
                </div>
              </Fragment>
            )}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
}

function HelpEditor(props) {
  const { data, updateData, reset } = props;
  const [saving, setSaving] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(data || "<p></p>");
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const onSave = useCallback(() => {
    setSaving(true);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    updateData(html, () => setSaving(false));
  }, [editorState, updateData]);

  return (
    <div className={styles.editorContainer}>
      <Editor
        editorState={editorState}
        wrapperClassName={styles.editorWrapper}
        editorClassName={styles.editor}
        toolbarClassName={styles.editorToolbar}
        onEditorStateChange={(e) => setEditorState(e)}
      />
      <div className={styles.editorToolBtn}>
        <Button size="sm" variant="outline-light" className={styles.reset} onClick={reset}>
          Close
        </Button>
        <Button size="sm" variant="outline-success" className={styles.save} onClick={onSave}>
          {saving && <FontAwesomeIcon icon={faSpinner} pulse />} Save
        </Button>
      </div>
    </div>
  );
}

function HelpNotes(props) {
  const { data, update, close } = props;
  const [edit, setEdit] = useState(false);
  const author = useSelector((state) => state.user.nickname);

  const addNew = useCallback(
    (body, cb) => {
      const notes = [{ date: new Date(), author, body }, ...data];
      update(notes, cb);
      //setEdit(false);
    },
    [author, data, update]
  );

  const updateNote = useCallback(
    (index, body, cb) => {
      const notes = [...data];
      notes[index] = { date: new Date(), author, body };
      update(notes, cb);
    },
    [author, data, update]
  );

  return (
    <div className={styles.notesContainer}>
      {edit ? (
        <HelpEditor updateData={addNew} reset={() => setEdit(false)} />
      ) : (
        <Fragment>
          <div className={styles.box}>
            {data?.map((note, index) => (
              <Note key={`note-${index}`} index={index} data={note} update={updateNote} />
            ))}
          </div>
          <div className={styles.toolbar}>
            <div className={styles.add}>
              <Button
                variant="outline-success"
                className={styles.btn}
                onClick={() => setEdit(true)}
              >
                <FontAwesomeIcon icon={faPlusSquare} className={styles.icon} />
                Add
              </Button>
            </div>
            <div className={styles.close}>
              <Button size="sm" variant="outline-primary" className={styles.btn} onClick={close}>
                Close
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

function Note(props) {
  const { index, data, update } = props;
  const [edit, setEdit] = useState(false);
  const permissions = usePermissions();
  const theData = useMemo(() => moment(data.date).format("dddd, D MMM YYYY"), [data.date]);

  return (
    <Fragment>
      {edit ? (
        <div className={styles.noteEditingBox}>
          <HelpEditor
            updateData={(value, cb) => update(index, value, cb)}
            reset={() => setEdit(false)}
            data={data.body}
          />
        </div>
      ) : (
        <div className={styles.note}>
          <div className={styles.header}>
            <div className={styles.author}>{data.author}</div>
            <div className={styles.date}>{theData}</div>
          </div>
          <div className={styles.body} dangerouslySetInnerHTML={{ __html: data.body }}></div>
          <div className={styles.toolbar}>
            {permissions.write?.includes("notes") ? (
              <FontAwesomeIcon
                icon={faEdit}
                className={styles.iconEdit}
                onClick={(e) => setEdit(true)}
              />
            ) : null}
          </div>
        </div>
      )}
    </Fragment>
  );
}
