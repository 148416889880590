import { useMemo, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useGetMemItem } from "../../../hooks/useMemoryDB";
import moment from "moment";

import Container from "../../container";
import Image from "../../image";
import Loading from "../../loading";
import DiffIcon from "../diffIcon";

import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBahai, faUsers } from "@fortawesome/free-solid-svg-icons";
import { explorer } from "../../../transport";
import set from "lodash.set";

export default function Carousel(props) {
  const { catalogs } = props;
  const tenant = useSelector((state) => state.user.tenant);
  const isSingle = useMemo(() => (catalogs || []).length > 0, [catalogs]);

  if (!catalogs && !tenant) return null;

  return (catalogs || []).map((catalog, index) => (
    <Box catalog={catalog} key={index} isSingle={isSingle} tenant={tenant} />
  ));
}

function Box(props) {
  const { catalog, isSingle, tenant } = props;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const working = useRef(false);
  const analitica = useGetMemItem("analitica", "settings");

  useEffect(() => {
    if (working.current) return;

    working.current = true;
    if (catalog && analitica && tenant && catalog.content.length > 0) {
      const fields = analitica?.content?.catalogs?.[catalog.catalog]?.fields;

      if (!fields) {
        console.error(
          `No Fields Settings for catalog ${JSON.stringify(catalog)}`
        );
        return;
      }

      setLoading(true);
      const payload = {
        tenant,
        index: catalog.catalog,
        _source: Object.values(fields),
        ids: catalog.content.map((c) => c.id),
      };

      explorer.post("content/ids", payload).then(({ data }) => {
        setData(data);
        setLoading(false);
      });
    }
    setTimeout(() => (working.current = false), 1000);
  }, [catalog, analitica, tenant]);

  const carousel = useMemo(() => {
    if (data) {
      const fields = analitica?.content?.catalogs?.[catalog.catalog].fields;
      return (
        <div className={styles.container} key={catalog.catalog}>
          <div
            className={styles.array}
            style={{ width: ((data.length || 0) + 4) * 252 }}
          >
            {data.map((item, pos) => {
              const title = item[fields.title];
              const imageUrl = item[fields.imageUrl];
              const data = item[fields.data];
              const description = item[fields.description];
              const category = item[fields.category];
              const catItem = catalog.content[pos];

              return (
                <div className={styles.item} key={`${catItem.id}-${pos}`}>
                  <div className={styles.imageBox}>
                    <Image src={imageUrl} className={styles.thumb} />
                    <div className={styles.title}>{title}</div>
                    {catItem.count && (
                      <div className={styles.contentId}>
                        <FontAwesomeIcon
                          icon={faBahai}
                          className={styles.count}
                        />{" "}
                        {catItem.count}
                        <FontAwesomeIcon
                          icon={faUsers}
                          className={styles.eye}
                        />{" "}
                        {catItem.userCount}
                        <DiffIcon data={catItem.diffPercentage} />
                      </div>
                    )}
                  </div>
                  <div className={styles.data}>
                    {moment(data).format("dddd, MMM-D-YYYY #h:mm")}
                  </div>
                  <div className={styles.description}>{description}</div>
                  <div className={styles.category}>{category}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }, [analitica?.content?.catalogs, catalog?.catalog, catalog?.content, data]);

  if (!catalog?.catalog || catalog.catalog === "EMPTY") return null;
  if (loading) return <Loading width="100%" height="250px" />;

  if (!data) return null;
  return isSingle ? (
    carousel
  ) : (
    <Container title={catalog.catalog}>{carousel}</Container>
  );
}
