import { Fragment, useMemo, useEffect, useCallback } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";
import ErrorMessage from "../errorMessage";
import Select from "../selects/selectWithSearch";
import groupBy from "../../utils/groupBy";
import stubs from "../../utils/stubs";

function ContentType(props) {
  const {
    value,
    rootNode,
    rootNodePath,
    errorPath,
    isInvalid,
    allowAllType,
    onChange,
    typeOfType,
    style,
    autoSetRealm = true,
    onlySelect = false,
    displayErrorMessage = true,
  } = props;
  const { values, errors, setFieldValue } = useFormikContext() || stubs.formik;
  //const { setItemErrors } = useItemContext() || {};
  const types = useDataSettings((settings) => {
    if (typeOfType === "content") return settings.contentDataTypes;
    if (typeOfType === "user") return settings.userDataTypes;
    return settings.contentTypes;
  });

  const typesGrouped = useMemo(() => groupBy(types, "realm"), [types]);
  const name = useMemo(
    () => `${rootNodePath || ""}contentType`,
    [rootNodePath]
  );
  const errorTypePath = useMemo(
    () => (errorPath ? `${errorPath}.contentType` : name),
    [errorPath, name]
  );

  const typesDropDown = useMemo(() => {
    const results = [];
    if (allowAllType) {
      results.push(
        <Fragment key={`drop-frag-empty}`}>
          <Dropdown.Header
            className="drop-header"
            key={`drop-header-all-types`}
          >
            Generic
          </Dropdown.Header>
          <Dropdown.Item value="*" eventKey="*" className="select-item-result">
            All Catalogs
          </Dropdown.Item>
          <div className="drop-divider" />
        </Fragment>
      );
    }
    if (typesGrouped) {
      Object.entries(typesGrouped).forEach(([head, items]) => {
        const filtered = items.filter((k) => k.value !== rootNode.contentType);
        if (filtered.length === 0) return;

        results.push(
          <Fragment key={`drop-frag-${head}`}>
            <Dropdown.Header
              className="drop-header"
              key={`drop-header-${head}`}
            >
              {head}
            </Dropdown.Header>
            {filtered.map((item, i) => {
              return (
                <Dropdown.Item
                  eventKey={item.value}
                  key={`${head}-${item.value}-${item.label}-${i}`}
                  value={item.value}
                  className="select-item-result"
                  onClick={() => true}
                >
                  {item.label}
                </Dropdown.Item>
              );
            })}
            <div className="drop-divider" />
          </Fragment>
        );
      });
    }

    return results;
  }, [allowAllType, rootNode.contentType, typesGrouped]);

  const doChange = useCallback(
    (value) => {
      const realm = types?.find((t) => t.value === value)?.realm;
      if (onChange && value && value !== rootNode.contentType)
        onChange(value, realm);
      //else handleChange({ target: { value } });
    },
    [onChange, rootNode.contentType, types]
  );

  useEffect(() => {
    if (!values || !autoSetRealm) return;

    if (!values.realm) {
      const realm = types?.find((t) => t.value === rootNode.contentType)?.realm;
      if (realm !== values?.realm) setFieldValue("realm", realm);
    }
  }, [autoSetRealm, rootNode.contentType, setFieldValue, types, values]);

  const catalogSelect = useMemo(() => {
    return (
      <>
        <Select
          key={`select-catalog`}
          name={`select-catalog`}
          value={value ?? rootNode.contentType}
          canFilter={false}
          onSelect={doChange}
          style={style || { width: "400px" }}
          className={props.className}
          errored={isInvalid || errors.contentType}
        >
          {typesDropDown}
        </Select>
        {displayErrorMessage ? <ErrorMessage name={errorTypePath} /> : null}
      </>
    );
  }, [
    displayErrorMessage,
    doChange,
    errorTypePath,
    errors.contentType,
    isInvalid,
    props.className,
    rootNode.contentType,
    style,
    typesDropDown,
    value,
  ]);

  if (onlySelect) return catalogSelect;

  return <Columned>{catalogSelect}</Columned>;
}

function Columned(props) {
  return (
    <Row>
      <Col sm="1">Catalog</Col>
      <Col sm="11">{props.children}</Col>
    </Row>
  );
}

export default ContentType;
