import { useEffect, useRef, useCallback } from "react";

const warning = process.env.NODE_ENV === "development" ? 100 * 60 * 1000 : 15 * 60 * 1000;
const signout = process.env.NODE_ENV === "development" ? 101 * 60 * 1000 : 16 * 60 * 1000;

export default function useTimeout({
  onWarning,
  onRestart,
  onEnded,
  onTick,
  warningTime = warning,
  signoutTime = signout,
  tick = 1000,
  trackMouseMove = false,
}) {
  const warnTimeout = useRef(null);
  const logoutTimeout = useRef(null);
  const warnStart = useRef();
  const logoutStart = useRef();
  const tickInterval = useRef();

  const clearTimeouts = useCallback(() => {
    warnStart.current = 0;
    logoutStart.current = 0;
    if (warnTimeout.current) clearTimeout(warnTimeout.current);
    if (logoutTimeout.current) clearTimeout(logoutTimeout.current);
  }, []);

  const setTimeouts = useCallback(() => {
    clearTimeouts();
    warnStart.current = new Date().getTime();
    logoutStart.current = new Date().getTime();
    warnTimeout.current = setTimeout(onWarning, warningTime);
    logoutTimeout.current = setTimeout(onEnded, signoutTime);
    if (onTick && !tickInterval.current) {
      tickInterval.current = setInterval(() => {
        const now = new Date().getTime();
        const timeToWarn = warningTime - (now - warnStart.current);
        const timeToWarnPerc = (timeToWarn / warningTime).toFixed(2);
        const timeToSignout = signoutTime - (now - logoutStart.current);
        const timeToSignoutPerc = (timeToSignout / signoutTime).toFixed(2);
        if (timeToWarn < 0 && timeToSignout < 0) clearInterval(tickInterval.current);
        else onTick({ timeToWarn, timeToWarnPerc, timeToSignout, timeToSignoutPerc });
      }, tick);
    }
  }, [clearTimeouts, onEnded, onTick, onWarning, signoutTime, tick, warningTime]);

  const resetTimeout = useCallback(() => {
    clearTimeouts();
    setTimeouts();
    if (onRestart) onRestart();
  }, [clearTimeouts, onRestart, setTimeouts]);

  useEffect(() => {
    const events = ["load", "mousedown", "click", "scroll", "keypress"];
    if (trackMouseMove) events.push("mousemove");
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
