import { forwardRef, useCallback, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import db from "../../../hooks/useMemoryDB";
import { useItemContext } from "../../../hooks/useItem";
import { useUpdateMetadata } from "../../../hooks/useUpdateMetadata";
import get from "lodash.get";

import Container from "../../container";
import SelectAllMultiple from "../../selects/selectAllMultiple";
import stubs from "../../../utils/stubs";

const checkStyle = { display: "inline-block" };

const CPack = forwardRef((props, ref) => {
  const { metadata, updateMetadata, rootNodeName, contentType, info } = props;
  const updateFieldMetadata = useUpdateMetadata();
  const { itemId } = useItemContext() || stubs.item;
  const { errors: errori } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);

  const required = useMemo(() => get(metadata, "conversionPacks.required"), [metadata]);
  const handleRequired = useCallback(
    (e) => {
      const required = e.target.checked;
      if (!required) updateMetadata("conversionPacks", undefined);
      else updateMetadata("conversionPacks.required", required);
    },
    [updateMetadata]
  );

  const ids = useMemo(() => {
    const ids = get(metadata, "conversionPacks.ids");

    return ids ? [...ids] : undefined;
  }, [metadata]);

  const conversionPackFilters = useMemo(() => {
    const filters = {
      catalogs: [contentType],
    };

    if (info.isFallback || info.isInFallback) {
      filters.isUserBased = false;
    }

    return filters;
  }, [contentType, info?.isFallback, info?.isInFallback]);

  const handleChange = useCallback(
    (ids) => {
      const data = db.bulkGet("conversionPacks", ids);
      const userBased = data.some((p) => p.userBased);

      const update = {
        ids,
        required: true,
        userBased,
      };

      updateMetadata("conversionPacks", update);
    },
    [updateMetadata]
  );

  useEffect(() => {
    if (ids) {
      const data = db.bulkGet("conversionPacks", ids);
      const fields = data.map((p) => p.outputMetadataField);

      updateFieldMetadata({
        owner: itemId,
        nextId: fields,
        types: ["string"],
        realm: "conversionPacks",
        catalog: contentType,
      });
    }
  }, [contentType, ids, itemId, updateFieldMetadata]);

  return (
    <Container
      folded={true}
      foldable={required || false}
      coloredBars={false}
      color="#030b0e"
      margin="4px"
      warning={errors?.[rootNodeName]?.conversionPacks}
      title={
        <Form.Check
          type="switch"
          id="conversionPacksSwitch"
          checked={get(metadata, "conversionPacks.required") || false}
          value="true"
          onChange={handleRequired}
          name="conversionPacksSwitch"
          label="Conversion Packs"
          style={checkStyle}
        />
      }
      help="meta-conversion-packs"
    >
      <SelectAllMultiple
        accessor="conversionPacks"
        filters={conversionPackFilters}
        values={ids}
        handleChange={handleChange}
      />
    </Container>
  );
});

export default CPack;
