const colors = [
  { stop1: "#b5179e", stop2: "#b51700", stroke: "#888" },
  { stop1: "#8884d8", stop2: "#8884d8", stroke: "#8884d8" },
  { stop1: "#82ca9d", stop2: "#82ca9d", stroke: "#82ca9d" },
  { stop1: "#ffbe0b", stop2: "#ffbe0b", stroke: "#8884d8" },
  { stop1: "#3a86ff", stop2: "#3a86ff", stroke: "#8884d8" },
  { stop1: "#fb5607", stop2: "#fb5607", stroke: "#8884d8" },
  { stop1: "#8338ec", stop2: "#8338ec", stroke: "#8884d8" },
  { stop1: "#ff006e", stop2: "#ff006e", stroke: "#8884d8" },

  { stop1: "#ffbc42", stop2: "#ffbc42", stroke: "#8884d8" },
  { stop1: "#d81159", stop2: "#d81159", stroke: "#8884d8" },
  { stop1: "#8f2d56", stop2: "#8f2d56", stroke: "#8884d8" },
  { stop1: "#218380", stop2: "#218380", stroke: "#8884d8" },
  { stop1: "#73d2de", stop2: "#73d2de", stroke: "#8884d8" },

  { stop1: "#ffe169", stop2: "#ffe169", stroke: "#8884d8" },
  { stop1: "#fad643", stop2: "#fad643", stroke: "#8884d8" },
  { stop1: "#edc531", stop2: "#edc531", stroke: "#8884d8" },
  { stop1: "#dbb42c", stop2: "#dbb42c", stroke: "#8884d8" },
  { stop1: "#c9a227", stop2: "#c9a227", stroke: "#8884d8" },
  { stop1: "#b69121", stop2: "#b69121", stroke: "#8884d8" },
  { stop1: "#a47e1b", stop2: "#a47e1b", stroke: "#8884d8" },
  { stop1: "#926c15", stop2: "#926c15", stroke: "#8884d8" },
  { stop1: "#805b10", stop2: "#805b10", stroke: "#8884d8" },
  { stop1: "#76520e", stop2: "#76520e", stroke: "#8884d8" },
];

export default colors;
