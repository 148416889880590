import { useState, useMemo, useCallback, useEffect } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import db from "../../../hooks/useMemoryDB";
import Picker from "rc-calendar/lib/Picker";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendar,
  faCalendarWeek,
  faClock,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import capitalize from "lodash.capitalize";
import SelectAll from "../../selects/selectAll";
import MetricsSelect from "../../selects/selectMetrics";
import Container from "../../container";
import ConversionsSelect from "../../selects/selectConversions";
import AnaliticaFilters from "../analiticaFilters";
import RangePane from "../../rangePane";

import styles from "./styles.module.scss";
import "../../../scss/calendar.css";
import "../../../scss/time.css";
import "./global.scss";

const selectStyle = { width: "366px" };

export default function Parameters(props) {
  const mode = props.mode ?? "conversions";
  const { values, setFieldValue } = props.formik;
  useEffect(() => {
    if (!values?.compareRange) {
      setFieldValue("compareRange", values.dateRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Ranges {...props} mode={mode} />
      <Endpoint {...props} mode={mode} />
      <Conversions {...props} mode={mode} />
      <Filters {...props} mode={mode} />
    </>
  );
}

export function Ranges(props) {
  const { values } = props.formik;

  return values.rangeMode === "relative" ? <RelativeRange {...props} /> : <DateRange {...props} />;
}

function RangeSWitch(props) {
  const className = props.className;
  const { values, setFieldValue } = props.formik;

  if (values.rangeMode === "relative") {
    return (
      <FontAwesomeIcon
        icon={faCalendarWeek}
        onClick={() => setFieldValue("rangeMode", "absolute")}
        className={className}
      />
    );
  }

  return (
    <FontAwesomeIcon
      icon={faCalendar}
      onClick={() => setFieldValue("rangeMode", "relative")}
      className={className}
    />
  );
}

function RelativeRange(props) {
  const { values, errors, setFieldValue } = props.formik;

  const onChangeStartDate = useCallback(
    (v) => setFieldValue("relativeRange.startDate", v),
    [setFieldValue]
  );
  const onChangePeriod = useCallback(
    (v) => setFieldValue("relativeRange.period", v),
    [setFieldValue]
  );
  const onChangeUnit = useCallback((v) => setFieldValue("relativeRange.unit", v), [setFieldValue]);

  const extra = useMemo(() => {
    return (
      <div className={styles.relativeRangeHeader}>
        <Form.Check
          type="switch"
          id="range-compare"
          checked={values.relativeRange?.comparePrevious || false}
          value="true"
          onChange={(e) => setFieldValue("relativeRange.comparePrevious", e.target.checked)}
          name="range-trending"
          label="Compare"
        />
        <RangeSWitch formik={props.formik} className={styles.rangeModeIcon} />
      </div>
    );
  }, [props.formik, setFieldValue, values.relativeRange?.comparePrevious]);

  return (
    <RangePane
      startDate={values.relativeRange?.startDate}
      onChangeStartDate={onChangeStartDate}
      period={values.relativeRange?.period}
      onChangePeriod={onChangePeriod}
      unit={values.relativeRange?.unit}
      onChangeUnit={onChangeUnit}
      extra={extra}
      errors={errors}
      color="#4d194d"
    />
  );
}

function CalendarPicker(props) {
  const { data, onChange, onReset, forcePicker } = props;
  const [hoverValue, setHoverValue] = useState([]);

  const timePickerElement = useMemo(
    () => (
      <TimePickerPanel
        defaultValue={[moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")]}
      />
    ),
    []
  );

  const rangeCalendar = useMemo(() => {
    const now = moment();

    return (
      <RangeCalendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder={["start", "end"]}
        defaultValue={[now, now.clone().add(1, "months")]}
        //locale={cn ? zhCN : enUS}
        timePicker={timePickerElement}
        showClear={false}
      />
    );
  }, [hoverValue, timePickerElement]);

  const value = useMemo(() => {
    if (data) {
      return [moment(data.start), moment(data.end)];
    }

    return [];
  }, [data]);

  const onChangeRange = useCallback(
    ([start, end]) => {
      onChange({ start: start.toISOString(), end: end.toISOString() });
    },
    [onChange]
  );

  return (
    <>
      <Picker
        value={value}
        onChange={onChangeRange}
        animation="slide-up"
        //align={alignConfig}
        calendar={rangeCalendar}
      >
        {({ value: currentValue }) => {
          if (forcePicker || !currentValue.length) {
            return (
              <Button size="sm" className={styles.pickDateBtn} variant={"outline-dark"}>
                Pick a Date Range...
              </Button>
            );
          }
          return (
            <div className={styles.scheduling}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <div className={styles.timeBox}>
                      <span>
                        <FontAwesomeIcon icon={faClock} className={styles.clockIcon} />
                      </span>
                      <b>{currentValue[0].format("HH:mm")}</b>
                    </div>
                  </Tooltip>
                }
              >
                <span>{currentValue[0].format("DD-MM-YYYY")}</span>
              </OverlayTrigger>
              <span>
                <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
              </span>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <div className={styles.timeBox}>
                      <span>
                        <FontAwesomeIcon icon={faClock} className={styles.clockIcon} />
                      </span>
                      <b>{currentValue[1].format("HH:mm")}</b>
                    </div>
                  </Tooltip>
                }
              >
                <span>{currentValue[1].format("DD-MM-YYYY")}</span>
              </OverlayTrigger>
            </div>
          );
        }}
      </Picker>
      {!forcePicker && onReset && value.length ? (
        <FontAwesomeIcon icon={faPowerOff} className={styles.resetIcon} onClick={onReset} />
      ) : null}
    </>
  );
}

function setRangeToMidnight(range) {
 return {
    start: moment(range.start).add("day").startOf("day").format("YYYY-MM-DD"),
    end: moment(range.end).startOf("day").format("YYYY-MM-DD"),
  };
}



function DateRange(props) {
  const { values, setFieldValue } = props.formik;

  const forcePicker = useMemo(() => {
    return (
      values.dateRange.start === values.compareRange?.start &&
      values.dateRange.end === values.compareRange?.end
    );
  }, [
    values.compareRange?.end,
    values.compareRange?.start,
    values.dateRange.end,
    values.dateRange.start,
  ]);

  const onChangeScheduling = useCallback(
    (range) => {
      range = setRangeToMidnight(range);
      setFieldValue("dateRange", range);
      setFieldValue("compareRange", range);
    },
    [setFieldValue]
  );

  const onChangeCompare = useCallback(
    (range) => {
      range = setRangeToMidnight(range);
      setFieldValue("compareRange", range);
    },
    [setFieldValue]
  );

  const onResetCompare = useCallback(() => {
    setFieldValue("compareRange", values.dateRange);
  }, [setFieldValue, values.dateRange]);

  const header = useMemo(() => {
    return (
      <div className={styles.header}>
        <CalendarPicker data={values.dateRange} onChange={onChangeScheduling} />

        <div className={styles.label}>Compare</div>
        <CalendarPicker
          data={values.compareRange}
          onChange={onChangeCompare}
          onReset={onResetCompare}
          forcePicker={forcePicker}
        />
      </div>
    );
  }, [
    forcePicker,
    onChangeCompare,
    onChangeScheduling,
    onResetCompare,
    values.compareRange,
    values.dateRange,
  ]);

  return (
    <Container
      title="Date Range"
      help="analitica-explorer-date-range"
      color="#4d194d"
      coloredBars={false}
      folded={true}
      foldable={false}
      header={header}
      headerOnlyOnFolded={false}
      extra={<RangeSWitch formik={props.formik} />}
    />
  );
}

export function Endpoint(props) {
  const { values, errors, setValues, setFieldValue } = props.formik;
  const [variants, setVariants] = useState([]);
  const [subBlocks, setSubBlocks] = useState([]);
  const [canChange, setCanChange] = useState(props.disableSelect ?? true);

  const getVariants = useCallback((id, cb) => {
    const endpoint = db.get("endpoints", id);
    const ids = [endpoint.blockId];
    endpoint.variants?.forEach((v) => ids.push(v.blockId));
    const blocks = db.bulkGet("blocks", ids);

    if (ids.length > 1) setVariants(blocks.map((b) => ({ value: b.id, label: b.title })));
    else setVariants([]);

    setSubBlocks([]);

    if (cb) cb(endpoint);
  }, []);

  const handleEndpointChange = useCallback(
    (id) => {
      if (!id) {
        setValues({
          ...values,
          endpoint: undefined,
          endpointId: undefined,
          propertyId: undefined,
          property: undefined,
          variantId: undefined,
          subBlockId: undefined,
        });

        setVariants([]);
      } else {
        getVariants(id, ({ reference, propertyId }) => {
          if (propertyId) {
            const property = db.get("properties", propertyId);
            setValues({
              ...values,
              endpoint: reference,
              endpointId: id,
              propertyId: propertyId,
              property: property.title,
              variantId: undefined,
              subBlockId: undefined,
            });
          } else {
            setValues({
              ...values,
              endpoint: reference,
              endpointId: id,
              propertyId: undefined,
              property: undefined,
              variantId: undefined,
              subBlockId: undefined,
            });

            setVariants([]);
          }
        });
      }
    },
    [getVariants, setValues, values]
  );
  const header = useMemo(() => {
    return (
      <div className={styles.header}>
        {canChange ? (
          <SelectAll
            accessor="endpoints"
            isInvalid={errors.endpoint}
            value={values?.endpointId}
            handleChange={handleEndpointChange}
            startWithEmptyOption={true}
            style={selectStyle}
          />
        ) : (
          <div className={styles.x}>
            {values.property ? `${values.property}:` : null}
            {values.endpoint}
          </div>
        )}

        {variants.length ? (
          <>
            <div className={styles.label}>Variant</div>
            <Form.Control
              as="select"
              onChange={(e) => {
                const variantId = e.target.value ? e.target.value : undefined;
                setFieldValue("variantId", variantId);
                if (variantId) {
                  const variant = db.get("blocks", variantId);

                  if (variant.isPage) {
                    setSubBlocks(variant.blocks.map((b) => ({ value: b.id, label: b.title })));
                  } else setSubBlocks([]);
                } else setSubBlocks([]);
              }}
              value={values.variantId}
              className={styles.width}
            >
              <option></option>
              {variants.map((v) => (
                <option value={v.value} key={v.label}>
                  {v.label}
                </option>
              ))}
            </Form.Control>
          </>
        ) : null}

        {subBlocks.length ? (
          <>
            <div className={styles.label}>SubBlock</div>
            <Form.Control
              as="select"
              onChange={(e) => {
                const subBlockId = e.target.value ? e.target.value : undefined;
                setFieldValue("subBlockId", subBlockId);
              }}
              value={values.subBlockId}
              className={styles.width}
            >
              <option></option>
              {subBlocks.map((v) => (
                <option value={v.value} key={v.label}>
                  {v.label}
                </option>
              ))}
            </Form.Control>
          </>
        ) : null}
      </div>
    );
  }, [
    canChange,
    errors.endpoint,
    values?.endpointId,
    values.property,
    values.endpoint,
    values.variantId,
    values.subBlockId,
    handleEndpointChange,
    variants,
    subBlocks,
    setFieldValue,
  ]);

  useEffect(() => {
    if (values.endpointId && props.disableSelect === true) {
      setCanChange(false);
      handleEndpointChange(values.endpointId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      title="Endpoint"
      help="analitica-explorer-endpoint"
      color="#3e1f47"
      coloredBars={false}
      folded={true}
      foldable={false}
      header={header}
      headerOnlyOnFolded={false}
      warningMessage={errors.endpoint}
    />
  );
}

function Filters(props) {
  const { values, setFieldValue, handleChange } = props.formik;

  const onChangContentIds = useCallback(
    (e) => {
      const values = e.target.value;
      const ids = values
        .split(/[ ,]+/)
        .map((v) => v.trim())
        .filter(Boolean);

      setFieldValue("contentIds", ids);
    },
    [setFieldValue]
  );

  const onChangUserIds = useCallback(
    (e) => {
      const values = e.target.value;
      const ids = values
        .split(/[ ,]+/)
        .map((v) => v.trim())
        .filter(Boolean);

      setFieldValue("userIds", ids);
    },
    [setFieldValue]
  );

  const handleFiltersChange = useCallback(
    (filters) => setFieldValue("filters", filters),
    [setFieldValue]
  );

  const header = useMemo(() => {
    return (
      <div className={styles.header}>
        <div className={styles.label}>Content IDs</div>
        <Form.Control
          type="text"
          placeholder=""
          name="contentIds"
          onChange={onChangContentIds}
          value={values.contentIds}
          className={styles.width}
        />
        <div className={styles.label}>User IDs</div>
        <Form.Control
          type="text"
          placeholder=""
          name="userIds"
          onChange={onChangUserIds}
          value={values.userIds}
          className={styles.width}
        />
        {props.isSearch ? (
          <>
            <div className={styles.label}>Query</div>
            <Form.Control
              type="text"
              placeholder=""
              name="query"
              onChange={handleChange}
              value={values.query}
              className={styles.width}
              size="sm"
            />
          </>
        ) : null}
      </div>
    );
  }, [
    handleChange,
    onChangContentIds,
    onChangUserIds,
    props.isSearch,
    values.contentIds,
    values.query,
    values.userIds,
  ]);

  if (props.mode !== "conversions") return null;

  return (
    <Container
      title="Filters"
      help="analitica-explorer-filters"
      color="#312244"
      coloredBars={false}
      folded={true}
      foldable={true}
      header={header}
      headerOnlyOnFolded={false}
    >
      <AnaliticaFilters
        color="#1a1224"
        foldable={false}
        hover={false}
        title="Context & Params"
        onChange={handleFiltersChange}
        data={values.filters}
      />
    </Container>
  );
}

export function Conversions(props) {
  const mode = props.mode ?? "conversions";
  const { values, errors, setFieldValue } = props.formik;

  const handleChange = useCallback(
    (data) => {
      setFieldValue(mode, data);
    },
    [mode, setFieldValue]
  );

  const header = useMemo(() => {
    if (mode === "metrics") {
      return <MetricsSelect value={values.metrics} onChange={handleChange} type="multiple" />;
    }

    if (mode === "conversions") {
      if (props.conversions)
        return (
          <div className={styles.conversions}>
            {props.conversions.map((c) => (
              <span className={styles.conversionBadge}>{c}</span>
            ))}
          </div>
        );

      return (
        <>
          <ConversionsSelect
            value={values.conversions}
            onChange={handleChange}
            forceSearch={props.forceSearch}
            forceRecommended={props.forceRecommended}
          />
        </>
      );
    }
  }, [
    handleChange,
    mode,
    props.conversions,
    props.forceRecommended,
    props.forceSearch,
    values.conversions,
    values.metrics,
  ]);

  return (
    <Container
      title={capitalize(mode)}
      help="analitica-explorer-filters"
      color="#272640"
      coloredBars={false}
      folded={true}
      foldable={false}
      header={header}
      headerOnlyOnFolded={false}
      warningMessage={errors.conversions || errors.metrics}
    />
  );
}
