import { useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./between.module.scss";

export default function Between(props) {
  const { value, onChange } = props;
  const [from, to] = value.split("÷");

  const handleChange = useCallback(
    (direction, e) => {
      let [from, to] = value.split("÷");
      if (direction === "from") from = e.target.value;
      if (direction === "to") to = e.target.value;

      onChange(`${from}÷${to}`);
    },
    [onChange, value]
  );

  return (
    <div className={styles.container}>
      <Form.Control
        type="number"
        placeholder="From"
        className={styles.from}
        value={from}
        onChange={(e) => handleChange("from", e)}
      />
      <div className={styles.separator}>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <Form.Control
        type="number"
        placeholder="To"
        className={styles.to}
        value={to}
        onChange={(e) => handleChange("to", e)}
      />
    </div>
  );
}
