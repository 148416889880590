export function getCatalogs(metadata, contentType) {
  const ret = new Set([contentType]);

  if (metadata) {
    for (const [key, value] of Object.entries(metadata)) {
      if (value.required !== undefined && !value.required) continue;
      if (value) {
        let v = key;

        if (key === "content") {
          v = contentType;
        } else if (key === "extra") {
          if (value.contentType) v = value.contentType;
          else v = null;
        } else if (key.includes("similar")) {
          v = value.userContentType || value.contentType || contentType;
        } else if (value.userContentType) {
          v = value.userContentType;
        }

        if (v) ret.add(v);
      }
    }
  }
  return [...ret];
}
//["similar", "collaborative"].some((k) => key.includes(k))
export default function getMetadata(metadata) {
  const ret = new Set(["request"]);

  if (metadata) {
    for (const [key, value] of Object.entries(metadata)) {
      if (value.required !== undefined && !value.required) continue;
      if (value) {
        if (key === "extra") {
          if (value.contentType) ret.add("content");
        } else ret.add(key);
      }
    }
  }
  return [...ret];
}

export function getRealms(metadata) {
  return getMetadata(metadata);
}
