import { useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import isNil from "lodash.isnil";
import styles from "./range.module.scss";

export default function Range(props) {
  const { handleChange, value, errored } = props;
  const [range, setRange] = useState(value.split(" ")?.[0]);
  const [period, setPeriod] = useState(value.split(" ")?.[1]);

  const onUpdate = useCallback(
    (r, p) => {
      const rng = r || range;
      const prd = p || period;
      const val = `${rng} ${prd}`;

      if (rng > 0 && prd && prd.length) {
        value !== val && handleChange(val);
      } else {
        if (value) handleChange("");
      }
    },
    [handleChange, period, range, value]
  );

  const handleChangeRange = useCallback(
    (e) => {
      const range = e.target.value;
      setRange(range);
      onUpdate(range);
    },
    [onUpdate]
  );
  const handleChangePeriod = useCallback(
    (e) => {
      const period = e.target.value;
      setPeriod(period);
      onUpdate(null, period);
    },
    [onUpdate]
  );

  return (
    <div className={styles.rangeBox}>
      <Form.Control
        type="number"
        min={0}
        isInvalid={errored}
        value={isNil(value) ? 0 : range ?? 0}
        onChange={handleChangeRange}
        className={styles.number}
      />

      <Form.Control as="select" isInvalid={errored} value={period} onChange={handleChangePeriod}>
        <option></option>
        <option value="s">Seconds</option>
        <option value="m">Minute</option>
        <option value="h">Hour</option>
        <option value="d">Day</option>
        <option value="w">Week</option>
        <option value="M">Month</option>
        <option value="y">Year</option>
      </Form.Control>
    </div>
  );
}
