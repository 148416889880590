import { findNodeId } from "../../utils/tree";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";

const isEqual = require("lodash.isequal");

const clean = (obj) => {
  const loop = (obj) => {
    Object.keys(obj).forEach((key) => {
      const elm = obj[key];
      if (typeof elm === "object") {
        if (Object.keys(elm).length === 0) delete obj[key];
        else loop(elm);
      }
    });

    return obj;
  };

  loop(obj);

  return loop(obj);
};

export const dataActionsTypes = {
  SET_DATA_STATUS: "SET_DATA_STATUS",
  UPDATE_MODIFICHE: "UPDATE_MODIFICHE",
  RESET_MODIFICHE: "RESET_MODIFICHE",
  INSERT_MODIFICHE: "INSERT_MODIFICHE",
};

export const setDataStatus = (key, value) => ({
  type: dataActionsTypes.SET_DATA_STATUS,
  key,
  value,
});

const sideActions = ["update", "create"];

export const dataReducer = (state, action) => {
  //console.log("DataReducer", state, action);
  switch (action.type) {
    case dataActionsTypes.SET_DATA_STATUS: {
      if (isEqual(state[action.key], action.value)) return state;
      return { ...state, [action.key]: action.value };
    }
    case dataActionsTypes.RESET_MODIFICHE: {
      if (action.cb) action.cb({});
      return { ...state, modifiche: {} };
    }
    case dataActionsTypes.INSERT_MODIFICHE: {
      if (action.cb) action.cb(action.payload);
      return { ...state, modifiche: action.payload };
    }
    case dataActionsTypes.UPDATE_MODIFICHE: {
      const newState = cloneDeep(state.modifiche || {});
      for (const { azione, tipo, id, value = {}, props: data } of action.payload) {
        const [existingNode] = findNodeId(id, data);

        let goInsert = true;

        if (azione === "substitute") {
          goInsert = false;

          sideActions.forEach((act) => {
            const existing = newState[act]?.["page"]?.[id];

            if (existing) {
              delete newState[act]["page"][id];
            }
          });

          const { newId, parentId } = value;

          sideActions.forEach((act) => {
            const existing = newState[act]?.["endpoint"]?.[parentId];

            if (existing) {
              if (existing.blockId === id) existing.blockId = newId;
              if (existing.variants?.includes(id)) {
                const index = existing.variants.findIndex((k) => k === id);
                existing.variants[index] = newId;
              }
            }
          });
        }

        if (azione === "delete") {
          sideActions.forEach((act) => {
            if (newState[act]?.[tipo]?.[id]) {
              goInsert = false;
              delete newState[act][tipo][id];
            }
          });

          if (tipo === "endpoint") {
            if (value.existing) goInsert = true;
            else goInsert = false;
          }
          if (tipo === "page") {
            if (value.existing) goInsert = true;
            else goInsert = false;

            const parentId = value.parentId;
            let checkParent = true;

            sideActions.forEach((act) => {
              const existing = newState[act]?.["endpoint"]?.[parentId];

              if (existing) {
                checkParent = false;
                if (existing.hasOwnProperty("variants")) {
                  const index = existing.variants.findIndex((idV) => idV === id);
                  if (index > -1) {
                    existing.variants.splice(index, 1);
                  }
                }

                if (existing.variants.length === 0) {
                  delete newState[act]["endpoint"][parentId];
                }
              }
            });

            if (checkParent) {
              const [existingParentNode] = findNodeId(id, data, true);
              const parentId = existingParentNode.value;
              const variants = existingParentNode.children.map((k) => k.value);
              const blockId = variants.shift();
              const reference = existingParentNode.label;

              const index = variants.findIndex((k) => k === id);
              if (index > -1) {
                variants.splice(index, 1);
                set(newState, `update.endpoint.${parentId}`, { blockId, reference, variants });
              }
            }
          }
        }

        if (azione === "update") {
          ["endpoint", "page"].forEach((type) => {
            const existing = newState["create"]?.[type]?.[id];

            if (existing) {
              newState["create"][type][id] = {
                ...newState["create"][type][id],
                ...value,
              };
              goInsert = false;
            }
          });

          if (goInsert) {
            if (tipo === "endpoint") {
              const existing = newState["update"]?.["endpoint"]?.[id];
              if (existing) {
                if (existing.blockId) {
                  if (!existing.variants) existing.variants = [];
                  existing.variants.push(value.blockId);
                  goInsert = false;
                }
              }

              if (existingNode) {
                const variants = existingNode.children.map((k) => k.value);
                const blockId = variants.shift();
                const reference = value.reference || existingNode.label;
                const propertyId = value.propertyId;

                if (value.blockId) variants.push(value.blockId);

                set(newState, `update.endpoint.${id}`, {
                  blockId,
                  reference,
                  propertyId,
                  variants,
                });
                goInsert = false;
              }
            }
          }
        }

        if (azione === "create") {
          if (tipo === "endpoint") {
            const existing = newState["create"]?.[tipo]?.[id];
            if (existing) {
              if (existing.hasOwnProperty("blockId")) {
                if (!existing.hasOwnProperty("variants")) existing.variants = [];
                existing.variants.push(value.blockId);
                goInsert = false;
              }
            }
          }
        }

        if (goInsert) {
          if (!newState[azione]) newState[azione] = {};
          if (!newState[azione][tipo]) newState[azione][tipo] = {};

          newState[azione][tipo][id] = {
            ...newState[azione][tipo][id],
            ...value,
          };
        }
      }

      const cleanState = clean(newState);

      if (action.cb) action.cb(cleanState);
      return { ...state, modifiche: cleanState };
    }
    default: {
      return state;
    }
  }
};
