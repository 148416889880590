import get from "lodash.get";
import has from "lodash.has";
import isEmpty from "lodash.isempty";
import compose from "compose-function";

import catalog from "../contentType/validation";
import validateMetadata from "../metadata/validation";
import validateConditions from "../conditionsBox/validate";

const notEmpty = [
  [`max_query_terms`, "Max Query Terms"],
  [`min_term_freq`, "Min Term Freq"],
  [`range`, "Range"],
];

export default function validate(values, excludeFallbackMetadata) {
  const validator = compose(metadata, settings, catalog, statements);
  const { errors } = validator({ values, excludeFallbackMetadata, errors: {} });
  return errors;
}

function metadata(props) {
  const { values, errors, excludeFallbackMetadata } = props;

  if (values.metadata) {
    const metadataErrors = validateMetadata(
      values.metadata,
      excludeFallbackMetadata
    );
    if (Object.keys(metadataErrors).length > 0) {
      errors.metadata = metadataErrors;
    }
  }

  return props;
}

function statements(props) {
  const { values, errors } = props;

  if (values.rule) {
    values.rule.forEach((stat, index) => {
      const statErrors = {};

      if (validateConditions(stat.conditionRaw)) statErrors.conditions = true;

      if (isEmpty(stat.field)) {
        statErrors.field = "You Must Select the Outer Field to Build the Query";
        statErrors.execute = true;
      }

      if (!stat.queryType) {
        statErrors.queryType = "Statement Must Have a Query Type";
        statErrors.query = true;
        statErrors.execute = true;
      }

      if (
        !["sortOrder", "checkExists", "random", "range", "distance"].includes(
          stat.queryType
        ) &&
        isEmpty(stat.value)
      ) {
        statErrors.value = "Statement Value Should Not Be Empty";
        statErrors.query = true;
        statErrors.execute = true;
      }

      for (const [mqt, label] of notEmpty) {
        if (has(stat, mqt)) {
          if (!get(stat, mqt)) {
            statErrors[mqt] = `${label} Must Be Defined`;
            statErrors.execute = true;
          }
        }
      }

      if (Object.keys(statErrors).length > 0) {
        statErrors.error = true;
        errors.statements = true;
        if (!errors.statementsList) errors.statementsList = {};
        errors.statementsList[stat._id] = statErrors;
      }
    });
  }

  return props;
}

function settings(props) {
  const { values, errors } = props;
  if (!values.clauses || Object.keys(values.clauses) === 0) {
    errors.clauses = "You Must Specify at least One Clause";
    errors.settings = true;
  }

  return props;
}
