import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useGetMemItem } from "../../../hooks/useMemoryDB";
import { MultiSelect } from "react-multi-select-component";

import styles from "./styles.module.scss";

function sortMetrics(a, b) {
  var x = a.label;
  var y = b.label;
  return x < y ? -1 : x > y ? 1 : 0;
}

export default function MetricsSelect(props) {
  const { onChange, value, className, type = "simple", allowEmpty = true } = props;
  const analitica = useGetMemItem("analitica", "metrics");

  const metrics = useMemo(() => {
    if (!analitica) return [];
    return analitica.metrics.map((m) => ({ label: m.label, value: m.id })).sort(sortMetrics);
  }, [analitica]);

  if (!metrics || metrics.length === 0) return null;

  if (type === "simple") {
    return (
      <Form.Control
        as="select"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className={className ?? styles.select}
      >
        {allowEmpty && <option></option>}
        {metrics.map((m) => (
          <option key={m.value} value={m.value}>
            {m.label}
          </option>
        ))}
      </Form.Control>
    );
  }

  if (type === "multiple") {
    return (
      <MultiSelect
        options={metrics}
        value={value || []}
        onChange={onChange}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: "All Metrics Selected.",
          selectSomeItems: "Select Metrics...",
        }}
      />
    );
  }

  return null;
}
