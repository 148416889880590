import { useCallback, useRef } from "react";

import set from "lodash.set";
import Item from "../item";
import BusinessRule from "./businessRule";
import validate from "./validation";
import { getOperatorsAndValues } from "../conditionsBox";

import "./style.scss";

const listOperators = ["inList", "not inList", "!inList"];

export function doRuleRefs(values) {
  const lists = new Set();
  const externalSources = [];
  let packs;
  let contentPacks;
  let enrichers;

  if (values.metadata?.request?.length > 0) {
    enrichers = values.metadata.request.map(({ id }) => id);
  }

  if (values.metadata?.external && values.metadata?.external?.required) {
    externalSources.push(values.metadata.external.externalSourceId);
  }

  if (values.metadata?.conversionPacks && values.metadata?.conversionPacks?.required) {
    packs = values.metadata?.conversionPacks.ids;
  }

  if (values.metadata?.contentPacks && values.metadata?.contentPacks?.required) {
    contentPacks = values.metadata?.contentPacks?.packs?.map?.(({ id }) => id);
  }

  values.rule?.forEach((rule) => {
    if (rule.listTermsId) lists.add(rule.listTermsId);
    if (rule.listId) lists.add(rule.listId);
    if (rule.valueType === "list") lists.add(rule.value);

    if (rule.conditionRaw) {
      const operators = getOperatorsAndValues(rule.conditionRaw);
      listOperators.forEach((operator) => {
        if (operators.has(operator)) {
          const values = operators.get(operator);
          [...values].forEach((v) => lists.add(v));
        }
      });
    } else {
      rule.condition.split(/ AND | OR /).forEach((c) => {
        const operator = c.substring(0, c.indexOf("("));

        if (listOperators.includes(operator)) {
          const k = c.match(/\(([^)]+)\)/);
          const params = k && k[1] ? k[1].split(",").map((k) => k.trim()) : ["", ""];
          lists.add(params[1].replace(/"/g, ""));
        }
      });
    }
  });

  const refArray = [
    {
      type: "lists",
      items: [...lists],
    },
    {
      type: "externalSources",
      items: externalSources,
    },
  ];

  if (enrichers) {
    refArray.push({
      type: "enrichers",
      items: enrichers,
    });
  }

  if (packs) {
    refArray.push({
      type: "conversionPacks",
      items: packs,
    });
  }

  if (contentPacks) {
    refArray.push({
      type: "contentPacks",
      items: contentPacks,
    });
  }

  if (values.label) {
    refArray.push({
      type: "labels",
      items: [values.label],
    });
  }

  set(values, "refs.require", refArray);

  return values;
}

export default function BusinessRuleContainer() {
  const ruleRef = useRef({});

  const onValidate = useCallback(
    (values) => validate(values, ruleRef.current.info?.isInFallback ?? false),
    []
  );

  return (
    <Item name="Rule" accessor="rules" onSubmit={doRuleRefs} onValidate={onValidate}>
      <BusinessRule ref={ruleRef} />
    </Item>
  );
}
