import { useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { Row, Col, Form } from "react-bootstrap";

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import get from "lodash.get";

import RotaryKnob from "../rotaryKnob";

export default function DateRange(props) {
  const { accessor, dataAccessor, rule, conditionIndex, ruleIndex } = props;
  const { setFieldValue, values } = useFormikContext();

  const range = useMemo(() => {
    const range = get(values[dataAccessor || accessor], `${ruleIndex}.overides.${rule._id}.range`);

    const period = get(
      values[dataAccessor || accessor],
      `${ruleIndex}.overides.${rule._id}.period`
    );

    if (range || period) {
      // return {
      //   range: (range && range[conditionIndex]) || rule.range || 1,
      //   period: (period && period[conditionIndex]) || rule.period || "y",
      // };

      return {
        range: range || 1,
        period: period || "y",
      };
    }

    if (rule)
      return {
        range: rule.range || 1,
        period: rule.period || "y",
      };
  }, [accessor, dataAccessor, rule, ruleIndex, values]);

  const handleChange = useCallback(
    (what, value) => {
      const newValues = cloneDeep(values[dataAccessor || accessor]);

      set(newValues, `${ruleIndex}.overides.${rule._id}.${what}`, value);
      setFieldValue(dataAccessor || accessor, newValues);
    },
    [accessor, dataAccessor, rule._id, ruleIndex, setFieldValue, values]
  );

  //if (!range || !range.range) return null;
  if (rule?.queryType !== "range") return null;

  return (
    <div className="range-override">
      <RotaryKnob
        value={Number(range.range) || 1}
        min={1}
        max={1000}
        step={1}
        label={"Period"}
        labelPosition="bottom"
        skin="s10"
        onChange={(v) => handleChange("range", v)}
        className="knob-group-generic"
        disabled={rule.notOverwritable}
      />

      <Form.Control
        as="select"
        value={range.period || "y"}
        onChange={(e) => handleChange("period", e.target.value)}
        disabled={rule.notOverwritable}
        className="range-override-select"
      >
        <option value="s">Seconds</option>
        <option value="m">Minute</option>
        <option value="h">Hour</option>
        <option value="d">Day</option>
        <option value="w">Week</option>
        <option value="M">Month</option>
        <option value="y">Year</option>
      </Form.Control>
    </div>
  );
}
