import { useCallback, useRef, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import Container from "../container";
import SelectAll from "../selects/selectAll";
import SelectAllMultiple from "../selects/selectAllMultiple";
import idGen from "../../utils/idGen";
import { db } from "../../db";
import Conditions from "../conditionsBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeCommit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDataSettings } from "../../hooks/useData";
import { SynthKeySelect, SynthValuesSelect } from "../pipelines/selects";
import AndOrSelector from "../query/andOrSelector";
import styles from "./styles.module.scss";

const quote = (value, type) => {
  if (type === "number") return Number(value);
  return value.includes('"') ? value : `"${value}"`;
};

export default function Pipelines(props) {
  const showDynamic = props.showDynamic ?? true;
  const { values, errors, setFieldValue } = useFormikContext();
  const operatori = useDataSettings("operators");

  const operatorsFilter = useCallback(() => {
    return [operatori, false];
  }, [operatori]);

  const addPipe = useCallback(() => {
    const pipes = values.pipelines?.dynamic ? values.pipelines.dynamic.slice() : [];
    pipes.unshift({
      condition: {
        and: [
          {
            operator: "*",
            values: [
              {
                type: "metadata",
                value: "",
              },
              {
                type: "",
                value: "",
              },
            ],
            id: idGen(),
          },
        ],
      },
    });

    setFieldValue("pipelines.dynamic", pipes);
  }, [setFieldValue, values.pipelines]);

  const onChangeStatic = useCallback(
    (pipes) => {
      setFieldValue("pipelines.static", pipes);
    },
    [setFieldValue]
  );

  const [filters, existingLen] = useMemo(() => {
    if (values.pipelines) {
      const statici = values.pipelines?.static ?? [];
      const dynamic = values.pipelines?.dynamic?.map?.(({ id }) => id).filter(Boolean) ?? [];
      const exclude = [...statici, ...dynamic];

      return [{ exclude }, exclude.length];
    }
    return [{ exclude: [] }, 0];
  }, [values.pipelines]);

  const canAdd = useMemo(() => {
    if (existingLen) {
      const pipes = db.getTable("pipelines");
      if (pipes.size === existingLen) return false;
    }

    return true;
  }, [existingLen]);

  return (
    <Container
      title="Pipelines"
      help="loader-pipelines"
      folded={true}
      warningMessage={props.errori?.pipelines || errors.pipelines}
      headerOnlyOnFolded={false}
      color={props.color}
      coloredBars={props.coloredBars}
      extra={
        props.extra || (
          <Form.Check
            type="switch"
            value="true"
            checked={values.pipelines?.active ?? false}
            onChange={(e) => setFieldValue("pipelines.active", e.target.checked)}
            id="activate-pipelines"
            name="activate-pipelines"
            label="Active"
            className={styles.switch}
          />
        )
      }
    >
      <Container
        title="Static"
        help="loader-static-pipeline"
        headerOnlyOnFolded={false}
        header={<AndOrSelector defaultOperator="and" showTie={false} />}
        folded={true}
      >
        <SelectAllMultiple
          accessor="pipelines"
          filters={filters}
          handleChange={onChangeStatic}
          values={values.pipelines?.static}
        />
      </Container>
      {showDynamic && (
        <Container
          title="Dynamic"
          help="loader-dynamic-pipeline"
          headerOnlyOnFolded={false}
          header={<AndOrSelector defaultOperator="or" showTie={false} />}
          folded={true}
          footer={
            <>
              {canAdd && (
                <div className={styles.pipeFooter}>
                  <Button variant="outline-secondary" size="sm" onClick={addPipe}>
                    Add Pipeline
                  </Button>
                </div>
              )}
            </>
          }
        >
          <DragAndDrop>
            {values.pipelines?.dynamic?.map?.((pipe, index) => (
              <Pipeline
                index={index}
                pipe={pipe}
                key={`pipe-${pipe.id}-${index}`}
                filters={filters}
                operatorsFilter={operatorsFilter}
                getKeyComponent={props.getKeyComponent}
                getValueComponent={props.getValueComponent}
              />
            ))}
          </DragAndDrop>
        </Container>
      )}
    </Container>
  );
}

function Pipeline(props) {
  const { index, pipe, filters, operatorsFilter } = props;
  const ref = useRef();
  const { values, setFieldValue, setValues } = useFormikContext();
  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    "pipelines",
    index,
    "pipeline",
    values.pipelines?.dynamic ?? [],
    (_, data) => setFieldValue("pipelines.dynamic", data)
  );

  const stile = useMemo(() => {
    const style = { opacity };

    if (highlighted && !hovered) {
      style.borderColor = "deepskyblue";

      return style;
    }
    if (hovered) {
      style.borderColor = "darkorange";
      style.borderBox = "4px 4px 8px deeppink";
      style.marginTop = "20px";
      style.marginBottom = "20px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const setPipe = useCallback(
    (id) => {
      setFieldValue(`pipelines.dynamic[${index}].id`, id);
    },
    [index, setFieldValue]
  );

  // const onChangeCondition = useCallback(
  //   ({ conditionRaw }) => {
  //     const pipelines = cloneDeep(values.pipelines);
  //     pipelines.dynamic[index].condition = conditionRaw;
  //     setFieldValue("pipelines", pipelines);
  //   },
  //   [index, setFieldValue, values.pipelines]
  // );

  const onChangeCondition = useCallback(
    ({ conditionRaw }) => {
      const valori = cloneDeep(values);
      valori.pipelines.dynamic[index].condition = conditionRaw;
      set(valori, "lastSaved.date", new Date().toISOString());
      setValues(valori);
    },
    [index, setValues, values]
  );

  const getKeyComponent = useCallback(
    ({ value, handleChangeValue }) => {
      if (props.getKeyComponent) return props.getKeyComponent({ value, handleChangeValue });

      return (
        <SynthKeySelect
          value={value}
          className={styles.keySelect}
          handleChange={handleChangeValue}
          valori={db.get("pipelines", pipe.id)}
        />
      );
    },
    [pipe.id, props]
  );

  const getValueComponent = useCallback(
    ({ field, value, handleChangeValue }) => {
      const change = (v) => handleChangeValue(quote(v));

      if (props.getValueComponent)
        return props.getValueComponent({ field, value, handleChangeValue: change });

      return (
        <SynthValuesSelect
          className={styles.keySelect}
          handleChange={change}
          value={value}
          chiave={field}
          valori={db.get("pipelines", pipe.id)}
        />
      );
    },
    [pipe.id, props]
  );

  const deletePipe = useCallback(() => {
    const pipes = values.pipelines.dynamic.slice();
    pipes.splice(index, 1);
    setFieldValue("pipelines.dynamic", pipes);
  }, [index, setFieldValue, values.pipelines?.dynamic]);

  return (
    <Container
      title={<FontAwesomeIcon icon={faCodeCommit} className={styles.pipeIcon} />}
      coloredBars={false}
      header={
        <SelectAll
          accessor="pipelines"
          filters={filters}
          handleChange={setPipe}
          value={pipe.id ?? ""}
        />
      }
      extra={
        <FontAwesomeIcon icon={faTrashAlt} className={styles.deleteIcon} onClick={deletePipe} />
      }
      ref={ref}
      style={stile}
      headerOnlyOnFolded={false}
      folded={true}
      foldable={pipe.id ?? false}
    >
      <Conditions
        conditionRaw={pipe.condition}
        onChangeCondition={onChangeCondition}
        getKeyComponent={getKeyComponent}
        getValueComponent={getValueComponent}
        operatorsFilter={operatorsFilter}
        enableReinitialize={true}
        defaultOperator={""}
      />
    </Container>
  );
}
