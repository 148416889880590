import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import MainContextProvider, { Loading } from "../../hooks/useMainContext";
import { DataContextProvider } from "../../hooks/useData";
import NavBar from "./navBar";
import "./style.scss";

const Monitor = lazy(() => import("../dbMonitor"));
const Dashboard = lazy(() => import("../dashboard"));
const BusinessRuleList = lazy(() => import("../businessRulesList"));
const BusinessRule = lazy(() => import("../businessRule"));
const QueriesList = lazy(() => import("../queriesList"));
const Query = lazy(() => import("../query"));
const BlockList = lazy(() => import("../blockList"));
const Block = lazy(() => import("../block"));
const EndpointList = lazy(() => import("../endpointList"));
const Endpoint = lazy(() => import("../endpoint"));
const ListList = lazy(() => import("../listList"));
const List = lazy(() => import("../list"));
const ScheduleDiagram = lazy(() => import("../scheduleDiagram"));
const ResposeFieldsList = lazy(() => import("../responseFieldsList"));
const ResponseField = lazy(() => import("../resposeFields"));
const DecoratorList = lazy(() => import("../decoratorList"));
const Decorator = lazy(() => import("../decorator"));
const Explorer = lazy(() => import("../explorer"));
const Migrator = lazy(() => import("../migration"));
const UserList = lazy(() => import("../userList"));
const User = lazy(() => import("../user"));
const Policies = lazy(() => import("../policies"));
const ConfigTenant = lazy(() => import("../configEditor/tenant"));
const ConfigClientUI = lazy(() => import("../configEditor/uiClient"));
const ConfigServerUI = lazy(() => import("../configEditor/uiServer"));
const ConfigMigrationUI = lazy(() => import("../configEditor/uiMigration"));
const ConfigAnalitica = lazy(() => import("../configEditor/analitica"));
const RulePresetsList = lazy(() => import("../rulesPresetList"));
const RulePreset = lazy(() => import("../rulesPreset"));
const LabelList = lazy(() => import("../labelList"));
const Label = lazy(() => import("../label"));
const ExternalSourcesList = lazy(() => import("../externalSourcesList"));
const ExternalSource = lazy(() => import("../externalSource"));
const PropertiesList = lazy(() => import("../propertiesList"));
const Property = lazy(() => import("../property"));
const Variants = lazy(() => import("../property/variants"));
const PropertiesSettings = lazy(() => import("../propertiesSettings"));
const CatalogExplorer = lazy(() => import("../catalogExplorer"));
const MetadataExplorer = lazy(() => import("../metadataExplorer"));
const ConditionTester = lazy(() => import("../conditionTester"));
const Tracking = lazy(() => import("../conversions/tracking"));
const ConversionsList = lazy(() => import("../conversions/list"));
const Conversion = lazy(() => import("../conversions"));
const ExperimentsList = lazy(() => import("../experiments/list"));
const Experiment = lazy(() => import("../experiments"));
const AnaliticaExplorer = lazy(() => import("../analitica/analiticaExplorer"));
const ConversionPacksList = lazy(() => import("../conversionPacks/list"));
const ConversionPack = lazy(() => import("../conversionPacks"));
const SudAres = lazy(() => import("../eastereggs/sudares"));
const PipelinesList = lazy(() => import("../pipelines/list"));
const Pipeline = lazy(() => import("../pipelines"));
const LoadersList = lazy(() => import("../loaders/list"));
const Loader = lazy(() => import("../loaders"));

const ContentPackList = lazy(() => import("../contentPacks/list"));
const ContentPack = lazy(() => import("../contentPacks"));
const MetaPackList = lazy(() => import("../metaPacks/list"));
const MetaPack = lazy(() => import("../metaPacks"));
const Personas = lazy(() => import("../personas"));

const JobsList = lazy(() => import("../jobs/list"));
const Job = lazy(() => import("../jobs"));
const Metrics = lazy(() => import("../metrics"));

export default function MainScreen(props) {
  const { tenant, id_token, nickname } = useSelector((state) => state.user);

  useEffect(() => {
    const current = window.document.title.split(" : ")[0];
    window.document.title = `${current} : ${tenant.toUpperCase()} `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <Router>
        <Suspense fallback={<Loading message="LOADING" />}>
          <DataContextProvider tenant={tenant} id_token={id_token} username={nickname}>
            <MainContextProvider>
              <NavBar />
              <Monitor />
              <div className="content-pane">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/index.html" element={<Dashboard />} />
                  <Route path="/properties" element={<PropertiesList />} />
                  <Route path="/properties/:itemId" element={<Property />} />
                  <Route
                    path="/property/:propertyId/endpoint/:endpointId/variant/:pageId"
                    element={<Variants />}
                  />
                  <Route exact path="/propertiesSettings" element={<PropertiesSettings />} />
                  <Route exact path="/rules" element={<BusinessRuleList />} />
                  <Route exact path="/rules/:itemId" element={<BusinessRule />} />
                  <Route exact path="/presets" element={<RulePresetsList />} />
                  <Route exact path="/presets/:itemId" element={<RulePreset />} />
                  <Route exact path="/rulesPresets/:itemId" element={<RulePreset />} />
                  <Route
                    exact
                    path="/schedulingRules"
                    element={<ScheduleDiagram itemType="rules" />}
                  />
                  <Route exact path="/queries" element={<QueriesList />} />
                  <Route exact path="/queries/:itemId" element={<Query />} />
                  <Route exact path="/blocks" element={<BlockList />} />
                  <Route exact path="/blocks/:itemId" element={<Block />} />
                  <Route exact path="/blocks/responsefields" element={<ResposeFieldsList />} />
                  <Route exact path="/blocks/responsefields/:itemId" element={<ResponseField />} />
                  <Route exact path="/blocks/externalsources" element={<ExternalSourcesList />} />
                  <Route
                    exact
                    path="/blocks/externalsources/:itemId"
                    element={<ExternalSource />}
                  />
                  <Route exact path="/blocks/explorer" element={<Explorer />} />
                  <Route
                    exact
                    path="/schedulingBlocks"
                    element={<ScheduleDiagram itemType="blocks" />}
                  />
                  <Route exact path="/endpoints" element={<EndpointList />} />
                  <Route exact path="/endpoints/:itemId" element={<Endpoint />} />
                  <Route exact path="/endpoints/decorators" element={<DecoratorList />} />
                  <Route exact path="/endpoints/decorators/:itemId" element={<Decorator />} />
                  <Route exact path="/config/tenant" element={<ConfigTenant />} />
                  <Route exact path="/config/clientui" element={<ConfigClientUI />} />
                  <Route exact path="/config/serverui" element={<ConfigServerUI />} />
                  <Route exact path="/config/migrationui" element={<ConfigMigrationUI />} />
                  <Route exact path="/config/analitica" element={<ConfigAnalitica />} />

                  <Route exact path="/pipelines/list" element={<PipelinesList />} />
                  <Route exact path="/pipelines/:itemId" element={<Pipeline />} />
                  <Route exact path="/loaders/list" element={<LoadersList />} />
                  <Route exact path="/loaders/:itemId" element={<Loader />} />

                  <Route exact path="/migration" element={<Migrator />} />
                  <Route exact path="/lists" element={<ListList />} />
                  <Route exact path="/lists/:itemId" element={<List />} />
                  <Route exact path="/labels" element={<LabelList />} />
                  <Route exact path="/labels/:itemId" element={<Label />} />
                  <Route exact path="/conversionPacks" element={<ConversionPacksList />} />
                  <Route exact path="/conversionPacks/:itemId" element={<ConversionPack />} />
                  <Route exact path="/contentPacks" element={<ContentPackList />} />
                  <Route exact path="/contentPacks/:itemId" element={<ContentPack />} />
                  <Route exact path="/personas" element={<Personas />} />
                  <Route exact path="/metaPacks" element={<MetaPackList />} />
                  <Route exact path="/metaPacks/:itemId" element={<MetaPack />} />

                  <Route exact path="/users" element={<UserList />} />
                  <Route exact path="/users/:itemId" element={<User />} />
                  <Route exact path="/users/policies" element={<Policies />} />
                  <Route exact path="/catalogexplorer" element={<CatalogExplorer />} />
                  <Route exact path="/metadataexplorer" element={<MetadataExplorer />} />
                  <Route exact path="/conditiontester" element={<ConditionTester />} />
                  <Route exact path="/experiments/list" element={<ExperimentsList />} />
                  <Route exact path="/experiments/:itemId" element={<Experiment />} />
                  <Route exact path="/conversions/list" element={<ConversionsList />} />
                  <Route exact path="/conversions/:itemId" element={<Conversion />} />
                  <Route exact path="/conversions/tracking" element={<Tracking />} />

                  <Route exact path="/jobs/list" element={<JobsList />} />
                  <Route exact path="/jobs/:itemId" element={<Job />} />
                  <Route exact path="/metrics" element={<Metrics />} />

                  <Route exact path="/analiticaExplorer" element={<AnaliticaExplorer />} />

                  <Route exact path="/eastereggs/sudares" element={<SudAres />} />
                </Routes>
              </div>
            </MainContextProvider>
          </DataContextProvider>
        </Suspense>
      </Router>
    </div>
  );
}
