/* eslint-disable import/no-webpack-loader-syntax */
import { useSelector } from "react-redux";
import Login from "./components/login";
import MainScreen from "./components/mainScreen";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/App.scss";

function App() {
  const tenant = useSelector((state) => state.user.tenant);

  return <div className="App">{tenant ? <MainScreen /> : <Login />}</div>;
}

export default App;
