import { Fragment, useEffect, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import RotaryKnob from "../rotaryKnob";
import Container from "../container";
import DeduplicateRBB from "../deduplicateRBB";

import get from "lodash.get";
import unset from "lodash.unset";
import isEqual from "lodash.isequal";

export default function Results(props) {
  const maxItems = useDataSettings("maxItems");
  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext();
  const isMulti = useMemo(() => (get(values, "blocks") || []).length > 1, [values]);
  const isFlat = useMemo(() => get(values, "results.flatAllBlocks"), [values]);

  const [max, maxMax] = useMemo(() => {
    const maxMax = values.blocks?.reduce((tot, block) => {
      const max = Number(get(block, "results.max") || 0);
      return tot + max;
    }, 0);

    return [get(values, "results.max") || 20, maxMax || maxItems];
  }, [maxItems, values]);

  const resetMax = useCallback(() => {
    const computedMax = get(values, "blocks").reduce((tot, block) => {
      const max = Number(get(block, "results.max") || 0);
      return tot + max;
    }, 0);

    setFieldValue("results.max", computedMax);
  }, [setFieldValue, values]);

  useEffect(() => {
    const newRes = { ...values.results };

    if (isMulti) {
      if (newRes.min === undefined) newRes.min = 0;

      if (!isFlat) unset(newRes, "max");
      else newRes.max = max;
    } else {
      const min = get(values, "blocks[0].results.min");
      if (newRes.min === undefined) newRes.min = min;

      const firstFallback = get(values, "blocks[0].results.fallbackBlockId");
      if (!newRes.fallbackBlockId && !values.canBeFallback && firstFallback) {
        newRes.fallbackBlockId = firstFallback;
      }

      // unset(newRes, "max");
      // const min = get(values, "blocks[0].results.min");
      // //min = newRes.min > min ? min : newRes.min;
      // newRes.min = min || 0;
      // //  newRes.flatAllBlocks = false;
      // if (!newRes.fallbackBlockId && isMulti) {
      //   const defaultFallbackId = get(values, "blocks[0].results.fallbackBlockId");
      //   if (defaultFallbackId) {
      //     newRes.fallbackBlockId = defaultFallbackId;
      //   }
      // }
    }

    if (!isEqual(newRes, values.results)) {
      setFieldValue("results", newRes);
    }
  }, [setFieldValue, max, values, isFlat, isMulti]);

  useEffect(() => {
    if (isFlat) {
      if (get(values, "results.shuffleBlocks") === true) {
        setFieldValue("results.shuffleBlocks", false);
      }
      if (get(values, "results.deduplicatedResultsBetweenBlocks") === true) {
        setFieldValue("results.deduplicatedResultsBetweenBlocks", false);
      }
      if (get(values, "results.smartOrderBlocks") === true) {
        setFieldValue("results.smartOrderBlocks", false);
      }
    }

    if (!isFlat) {
      if (get(values, "results.shuffleItems") === true) {
        setFieldValue("results.shuffleItems", false);
      }
      if (get(values, "results.deduplicateFlattedResults") === true) {
        setFieldValue("results.deduplicateFlattedResults", false);
      }

      if (get(values, "results.shuffleBlocks") === true) {
        setFieldValue("results.smartOrderBlocks", false);
      }
      if (get(values, "results.smartOrderBlocks") === true) {
        setFieldValue("results.shuffleBlocks", false);
      }
    }
  }, [isFlat, setFieldValue, values]);

  // if (values.blocks?.length < 2) {
  //   return <small className="text-muted">No Result Settings Available</small>;
  // }

  return (
    <Container
      title="Results"
      help="block-global-results"
      foldable={false}
      folded={false}
      className="extra-container-window"
    >
      <div className="global-results">
        <div className="result-knobs">
          <RotaryKnob
            value={Number(get(values, "results.min") || 0)}
            min={0}
            max={Number(max - 1)}
            step={1}
            label={isFlat ? "Min" : "Min Blocks"}
            labelPosition="bottom"
            skin="s10"
            onChange={(value) => setFieldValue("results.min", value)}
            className="margin-right"
          />
          {isFlat ? (
            <Fragment>
              <RotaryKnob
                value={Number(max)}
                min={1}
                max={maxMax}
                step={1}
                label="Max"
                labelPosition="bottom"
                skin="s10"
                onChange={(value) => setFieldValue("results.max", value)}
              />
              <span className={"max-label-manual"} onClick={resetMax}>
                <FontAwesomeIcon icon={faPowerOff} />
              </span>
            </Fragment>
          ) : null}
        </div>
        {values.blocks?.length >= 2 ? (
          <div className="results-switches">
            {isFlat ? (
              <Fragment>
                <Form.Check
                  type="switch"
                  checked={(values.results || {}).shuffleItems || false}
                  value="true"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={`results.shuffleItems`}
                  id={`results.shuffleItems`}
                  label="Shuffle Results"
                />

                <Form.Check
                  type="switch"
                  checked={(values.results || {}).deduplicateFlattedResults || false}
                  value="true"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={`results.deduplicateFlattedResults`}
                  id={`results.deduplicateFlattedResults`}
                  label="Deduplicate Results"
                />
              </Fragment>
            ) : (
              <Fragment>
                <Container
                  title={
                    <Form.Check
                      type="switch"
                      checked={(values.results || {}).shuffleBlocks || false}
                      value="true"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`results.shuffleBlocks`}
                      id={`results.shuffleBlocks`}
                      label="Shuffle Block Positions"
                      disabled={(values.results || {}).smartOrderBlocks || false}
                    />
                  }
                  foldable={false}
                  folded={true}
                  coloredBars={false}
                />

                <DeduplicateRBB />
              </Fragment>
            )}
          </div>
        ) : null}
      </div>
    </Container>
  );
}
