import { useCallback, useMemo } from "react";
import db, { useGetMemTable } from "../../hooks/useMemoryDB";
import set from "lodash.set";

import Item from "../item";
import Experiment from "./experiment";
import validate from "./validation";

export function doExperimentRefs(values) {
  const endpoints =
    values.variations
      ?.map(({ payload }) => payload?.endpointId)
      .filter(Boolean) ?? [];
  const properties =
    values.variations
      ?.map(({ payload }) => payload?.propertyId)
      .filter(Boolean) ?? [];

  // Add Rewrite Endpoint
  if (values.referenceId) endpoints.push(values.referenceId);
  const property = db.get("endpoints", values.referenceId)?.propertyId;
  if (property) properties.push(property);

  const array = [];

  if (endpoints.length > 0)
    array.push({
      type: "endpoints",
      items: [...new Set(endpoints)],
    });

  if (properties.length > 0) {
    array.push({
      type: "properties",
      items: [...new Set(properties)],
    });
  }

  set(values, "refs.require", array);

  return values;
}

export default function ExperimentsIndex(props) {
  const expTable = useGetMemTable("experiments");
  const experiments = useMemo(() => {
    const experiments = new Set();
    if (expTable) expTable.forEach((v) => experiments.add(v.reference));
    return experiments;
  }, [expTable]);

  const onValidate = useCallback(
    (data) => {
      if (data.id) {
        const origin = db.get("experiments", data.id);
        return validate(data, experiments, origin);
      } else return validate(data, experiments);
    },
    [experiments]
  );

  return (
    <Item
      name="Experiment"
      accessor="experiments"
      onSubmit={doExperimentRefs}
      onValidate={onValidate}
      NameAndDescriptionColor="#4d194d"
    >
      <Experiment />
    </Item>
  );
}
