import { Fragment, useState, useCallback, useEffect, useMemo } from "react";
import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faDiceOne, faDiceSix } from "@fortawesome/free-solid-svg-icons";

import Container from "../../../container";
import SelectMeta from "../../../selects/selectMeta";
import ErrorMessage from "../../../errorMessage";

const realms = ["special", "content"];
export default function OuterField(props) {
  const { onOuterFieldChange, onMultiChange, value, catalogs, error } = props;
  const [isMulti, setIsMulti] = useState();
  const [currentValue, setCurrentValue] = useState(() => {
    if (!value) return new Set();

    const val = Array.isArray(value) ? value : [value];
    return new Set(val);
  });
  const [currentSelection, setCurrentSelection] = useState(() =>
    Array.isArray(value) ? "" : value
  );

  const switchMulti = useCallback(() => {
    if (isMulti) {
      const val = [...currentValue][0].value;
      setCurrentSelection(val);
      setCurrentValue(new Set([val]));
      onOuterFieldChange(val);
    } else {
      const value = [...currentValue][0];
      const multi = [{ value, boost: 1 }];

      setCurrentSelection("");
      setCurrentValue(new Set(multi));
      onOuterFieldChange(multi);
    }

    onMultiChange(!isMulti);
    setIsMulti(!isMulti);
  }, [currentValue, isMulti, onMultiChange, onOuterFieldChange]);

  const onChange = useCallback(
    (val) => {
      if (!isMulti) {
        setCurrentValue((state) => new Set([val]));
        onOuterFieldChange(val);
      }
      setCurrentSelection(val);
    },
    [isMulti, onOuterFieldChange]
  );

  const addField = useCallback(() => {
    if (currentSelection) {
      const value = isMulti ? { value: currentSelection, boost: 1 } : currentSelection;
      const newValue = new Set(currentValue);
      newValue.add(value);

      setCurrentValue(newValue);
      setCurrentSelection("");
      onOuterFieldChange([...newValue]);
    }
  }, [currentSelection, currentValue, isMulti, onOuterFieldChange]);

  const removeField = useCallback(
    (index) => {
      const arr = [...currentValue];
      arr.splice(index, 1);
      const newValue = new Set(arr);

      setCurrentValue(newValue);
      onOuterFieldChange([...newValue]);
    },
    [currentValue, onOuterFieldChange]
  );

  const updateField = useCallback(
    (index, boost) => {
      const arr = [...currentValue];
      arr[index] = { value: arr[index].value || arr[index], boost };
      const newValue = new Set(arr);

      setCurrentValue(newValue);
      onOuterFieldChange([...newValue]);
    },
    [currentValue, onOuterFieldChange]
  );

  // useEffect(() => {
  //   if (!isMulti) {
  //     if (Array.isArray(values.field)) {
  //       setIsMulti(true);
  //       onMultiChange(true);
  //     }
  //   }
  // }, [isMulti, onMultiChange, values.field]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setIsMulti(true);
      onMultiChange(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const realms = useMemo(() => (realm ? [realm] : null), [realm]);
  const excludes = useMemo(
    () => [...currentValue].map((v) => (v.value ? v.value : v)),
    [currentValue]
  );

  //values.contentType
  // excludes={[...currentValue]}

  return (
    <Fragment>
      <div className="outer-field-container">
        <div className="outer-field-select">
          <SelectMeta
            name="field"
            value={currentSelection}
            handleChange={onChange}
            catalogs={catalogs}
            realms={realms}
            excludes={excludes}
            excludeNested={isMulti}
            errored={error}
          />
        </div>
        {isMulti ? (
          <div className="outer-field-add-button">
            <Button size="sm" onClick={addField} variant="outline-primary">
              <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: "4px" }} /> Add Field
            </Button>
          </div>
        ) : null}

        <div className="outer-field-multi-button">
          <Button size="sm" onClick={switchMulti} variant="secondary">
            <FontAwesomeIcon icon={isMulti ? faDiceSix : faDiceOne} />
          </Button>
        </div>
      </div>
      <div>
        <ErrorMessage name="field" />
      </div>
      {isMulti ? (
        <div className="outer-fields-container">
          {[...currentValue].map((val, index) => (
            <Multi
              key={`${index}-${val.value ? val.value : val}`}
              data={val}
              index={index}
              canDelete={currentValue.size > 1}
              onDelete={removeField}
              onChange={updateField}
            />
          ))}
        </div>
      ) : null}
    </Fragment>
  );
}

function Multi(props) {
  const { data, index, onChange, canDelete, onDelete } = props;
  const [bst, setBst] = useState(data.boost || 1);

  const [value, boost] = useMemo(() => {
    if (data.value) {
      return [data.value, data.boost];
    }

    return [null, 1];
  }, [data]);

  const extra = useMemo(() => {
    if (canDelete) {
      return (
        <span
          aria-hidden="true"
          onClick={() => onDelete(index)}
          style={{ cursor: "pointer", marginLeft: "4px" }}
        >
          &times;
        </span>
      );
    }
  }, [canDelete, index, onDelete]);

  const header = useMemo(() => {
    return (
      <span
        className={`multi-boost ${boost > 1 ? "positive" : boost < 1 ? "negative" : "neutral"}`}
      >
        {boost !== 1 ? " x" : ""}
        {boost}
      </span>
    );
  }, [boost]);

  const updateBoost = useCallback((e) => {
    setBst(e.target.value);
  }, []);

  const onConfirmBoost = useCallback(() => {
    onChange(index, bst);
  }, [bst, index, onChange]);

  if (!value) return null;

  return (
    <Container
      title={value}
      color="#335145"
      header={header}
      extra={extra}
      folded={true}
      isButton={true}
      headerOnlyOnFolded={false}
    >
      <div className={`multi-boost-changer`}>
        <Form.Control
          type="number"
          value={bst}
          min={0.1}
          max={10}
          step={0.1}
          onChange={updateBoost}
          className={bst < 1 ? "negative" : "positive"}
        />
        <Button size="sm" variant="outline-success" onClick={onConfirmBoost}>
          OK
        </Button>
      </div>
    </Container>
  );
}
