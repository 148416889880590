import Container from "../../../../container";
import Carousel from "../../../carousel";

import styles from "./styles.module.scss";
export default function Catalogs(props) {
  return (
    <Container title="Content" className={styles.catalogsBox} folded={true}>
      <Carousel catalogs={props.data} />
    </Container>
  );
}
