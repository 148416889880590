import { useCallback, useRef, useState, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import CSVReader from "react-csv-reader";
import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTrashAlt, faPlusSquare, faPaste } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { Form, Button } from "react-bootstrap";
import useClipboard from "../../hooks/useClipboard";
import niceTry from "nice-try";

import get from "lodash.get";

import Container from "../container";
import ContentType from "../contentType";
import SelectMeta from "../selects/selectMeta";
import SelectAll from "../selects/selectAll";

import "./style.scss";
import { explorer } from "../../transport";

export default function List(props) {
  const { values, errors, setFieldValue, validateForm } = useFormikContext();
  const types = useDataSettings("listTypes");
  const { clipboard, copy } = useClipboard();
  const settings = useGetMemItem("ui", "server");
  const getParams = useGetMemItem("params");
  const [listMetadata, setListMetadata] = useState();

  const canHaveMeta = useCallback(
    () => values.type === "contentId" && values.contentType && values.metadataField,
    [values.contentType, values.metadataField, values.type]
  );

  const getItemMetadata = useCallback(
    (id) => {
      if (listMetadata && canHaveMeta()) {
        const item = listMetadata.find((k) => k._id === id);
        if (item) {
          return <div className="item-metadata">{item[values.metadataField] ?? "NA"}</div>;
        }

        return <div className="item-metadata-missing">Missing</div>;
      }
    },
    [canHaveMeta, listMetadata, values.metadataField]
  );

  useEffect(() => {
    if (canHaveMeta() && values.elements && settings) {
      getParams(settings.paramsId, (params) => {
        if (params) {
          const index = params.content.types[values.contentType].index;
          const payload = { index, _source: values.metadataField, ids: values.elements };

          explorer.post("content/ids", payload).then(({ data }) => {
            setListMetadata(data);
          });
        }
      });
    }
  }, [canHaveMeta, getParams, settings, values.contentType, values.elements, values.metadataField]);

  const addElement = useCallback(() => {
    const newValues = [...(values.elements || []), ""];
    setFieldValue("elements", newValues);
  }, [setFieldValue, values.elements]);

  const onDelete = useCallback(
    (index) => {
      const newValues = [...values.elements];
      newValues.splice(index, 1);
      setFieldValue("elements", newValues);
    },
    [setFieldValue, values.elements]
  );

  const handleDND = useCallback((data) => setFieldValue("elements", data), [setFieldValue]);

  const handlePasteList = useCallback(() => {
    const newValues = [...(values.elements || [])];
    const list = niceTry(() => JSON.parse(clipboard).list) || [];

    list.forEach((value) => newValues.push(value));
    setFieldValue("elements", newValues);
    setImmediate(validateForm);
  }, [clipboard, setFieldValue, validateForm, values.elements]);

  const handleCopyList = useCallback(() => {
    copy(JSON.stringify({ _ares: "simpleList", list: values.elements }));
  }, [copy, values.elements]);

  const pasteIcon = useMemo(() => {
    const clip = niceTry(() => JSON.parse(clipboard)) || {};

    if (clip._ares === "simpleList") {
      return (
        <Button
          size="sm"
          variant="outline-secondary"
          className="pasteBtn"
          onClick={handlePasteList}
        >
          <FontAwesomeIcon icon={faPaste} />
        </Button>
      );
    }

    return null;
  }, [clipboard, handlePasteList]);

  const copyIcon = useMemo(() => {
    const numElm = (get(values, "elements") || []).length;

    if (numElm > 0) {
      return (
        <Button size="sm" variant="outline-dark" className="copyBtn" onClick={handleCopyList}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      );
    }

    return null;
  }, [handleCopyList, values]);

  const handleContentType = useCallback(
    (type) => setFieldValue("contentType", type),
    [setFieldValue]
  );

  const onChangeType = useCallback(
    (e) => {
      setFieldValue("type", e.target.value);
    },
    [setFieldValue]
  );

  const handleMetaField = useCallback(
    (field) => setFieldValue("metadataField", field),
    [setFieldValue]
  );

  const header = useMemo(() => {
    if (!types) return null;

    return (
      <>
        <div className="elements-header">
          <div className="label">Type</div>
          <Form.Control as="select" value={values.type} onChange={onChangeType}>
            <option></option>
            {types.map((type, i) => (
              <option value={type.value} key={`${i}-${type}`}>
                {type.label}
              </option>
            ))}
          </Form.Control>
          <div className="label">Catalog</div>
          <ContentType
            onlySelect={true}
            rootNode={values}
            fixedValue={values.contentType}
            allowAllType={true}
            onChange={handleContentType}
          />
          {values.type === "contentId" && values.contentType && (
            <>
              <div className="label">Field</div>
              <SelectMeta
                value={values.metadataField}
                catalogs={[values.contentType]}
                realms={["content"]}
                handleChange={handleMetaField}
                className="meta-field-select"
              />
            </>
          )}
        </div>
      </>
    );
  }, [handleContentType, handleMetaField, onChangeType, types, values]);

  const footer = useMemo(() => {
    return (
      <div className="footer">
        <CSVImporter />
        <Button size="sm" variant="outline-primary" onClick={addElement}>
          <FontAwesomeIcon icon={faPlusSquare} className="addIcon" />
          Add Element
        </Button>
      </div>
    );
  }, [addElement]);

  const title = useMemo(() => {
    return (
      <div className="header-title">
        Elements <div className="count-badge">{(get(values, "elements") || []).length}</div>
      </div>
    );
  }, [values]);

  const extra = useMemo(() => {
    return (
      <div className="extra-box">
        <div className="extra-copy-and-paste">
          {pasteIcon}
          {copyIcon}
        </div>
      </div>
    );
  }, [copyIcon, pasteIcon]);

  return (
    <Container
      title={title}
      help="list-elements"
      header={header}
      headerOnlyOnFolded={false}
      extra={extra}
      footer={footer}
      warningMessage={errors.elements}
    >
      <div className="elements-container">
        <DragAndDrop>
          {(get(values, "elements") || []).map((value, index) => (
            <Element
              index={index}
              handleDelete={onDelete}
              handleDND={handleDND}
              getMetadata={getItemMetadata}
              key={`element-${index}`}
              exclude={values.elements ?? []}
              type={values.type}
              catalog={values.contentType}
            />
          ))}
        </DragAndDrop>
      </div>
    </Container>
  );
}

function CSVImporter(props) {
  const { setFieldValue } = useFormikContext();
  const [data, setData] = useState();
  const [column, setColumn] = useState();

  const importList = useCallback(
    (dt) => {
      const dxs = (dt || data).map((d) => d[column || 0]);
      setFieldValue("elements", [...new Set(dxs)]);
    },
    [data, setFieldValue, column]
  );

  const dataLoaded = useCallback(
    (data) => {
      setData(data);

      if (data?.[0].length === 1) importList(data);
    },
    [importList]
  );

  const dataColumns = useMemo(() => data?.[0].length, [data]);

  return (
    <div className="css-box">
      <CSVReader cssClass="css-reader-style" onFileLoaded={dataLoaded} />
      {dataColumns > 1 ? (
        <div className="csv-col">
          <Form.Control
            as="select"
            className="data-column-select"
            onChange={(e) => setColumn(e.target.value)}
          >
            {Array(dataColumns)
              .fill(1)
              .map((c, i) => (
                <option key={i} value={i}>
                  {i + 1}) {data[0][i]}
                </option>
              ))}
          </Form.Control>
          <Button
            className="data-column-btn"
            variant="outline-primary"
            onClick={() => importList()}
          >
            Import
          </Button>
        </div>
      ) : null}
    </div>
  );
}

function Element(props) {
  const { index, handleDelete, handleDND, getMetadata, exclude, type, catalog } = props;
  const { handleChange, setFieldValue, values } = useFormikContext();
  const ref = useRef();

  const onDelete = useCallback(() => handleDelete(index), [handleDelete, index]);
  const smartFilters = useMemo(() => {
    return {
      canBeSmart: true,
      exclude,
      catalogs: [catalog],
    };
  }, [catalog, exclude]);

  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    `elements[${index}]`,
    index,
    "elements",
    values.elements || [],
    (_, data) => handleDND(data)
  );

  const stile = useMemo(() => {
    const style = { opacity, cursor: "pointer" };

    if (highlighted && !hovered) {
      style.backgroundColor = "#111";

      return style;
    }
    if (hovered) {
      style.backgroundColor = "#222";
      style.marginTop = "5px";
      style.marginBottom = "5px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  return (
    <div className="element-row" ref={ref} style={stile}>
      <FontAwesomeIcon icon={faBars} className="list-drag-icon" />
      {type === "smartPages" ? (
        <SelectAll
          accessor="blocks"
          filters={smartFilters}
          handleChange={(v) => setFieldValue(`elements[${index}]`, v)}
          value={get(values, `elements[${index}]`) || ""}
          key={`elements-${index}`}
          className="select-style"
        />
      ) : (
        <Form.Control
          type="text"
          placeholder="Add Value..."
          key={`elements[${index}]`}
          name={`elements[${index}]`}
          value={get(values, `elements[${index}]`) || ""}
          onChange={handleChange}
          className="list-element"
        />
      )}

      {getMetadata(values?.elements?.[index])}
      <Button size="sm" variant="outline-danger" onClick={onDelete} className="delete-btn">
        <FontAwesomeIcon icon={faTrashAlt} className="icon" />
        Remove
      </Button>
    </div>
  );
}
