import { useState, useCallback, useRef, useEffect } from "react";

export default function useClipboard({ onCopied, onRead, refresh = 1000 } = {}) {
  const [clipboard, setClipboard] = useState();
  const readRef = useRef();

  useEffect(() => {
    if (!readRef.current && navigator.clipboard.readText) {
      readRef.current = setInterval(() => {
        navigator.clipboard
          .readText()
          .then((data) => {
            setClipboard(data);
            onRead && onRead(data);
          })
          .catch((e) => {});
      }, refresh);
    }

    return () => readRef.current && clearInterval(readRef.current);
  }, [onRead, refresh]);

  const copy = useCallback(
    (text) => {
      if (!navigator.clipboard.writeText || !typeof text === "string") return;
      navigator.clipboard
        .writeText(text)
        .then((data) => {
          setClipboard(data);
          onCopied && onCopied(data);
        })
        .catch((e) => console.log(e));
    },
    [onCopied]
  );

  return { clipboard, copy };
}
