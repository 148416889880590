import get from "lodash.get";
import camelcase from "lodash.camelcase";

export const checkIfHavePages = (root) => {
  if (!root.children) return false;
  for (const page of root.children) {
    if (!page?.children) return true;
  }

  return false;
};

export const checkIfHaveFolder = (root) => {
  for (const page of root) {
    if (page.children) return true;
  }

  return false;
};

export const countNumOfPages = (root) => {
  let count = 0;
  for (const page of root) {
    if (!page.children) count += 1;
  }

  return count;
};

export const checkDeep = (data) => {
  const child = data.children;

  if (!child) return true;
  if (checkIfHaveFolder(child)) return false;
  if (countNumOfPages(child) > 1) return false;

  return true;
};

export const findNode = (path, data, findParent = false) => {
  const steps = path.split("-");
  const lastIndex = Number(steps[steps.length - 1]);

  if (findParent) steps.pop();

  let node = "";
  let camel = [];

  if (steps.length === 1) {
    node = `[${steps[0]}]`;
    camel.push(get(data, node).label);
  } else {
    steps.forEach((i) => {
      if (node.endsWith("]")) node += `.children`;
      node += `[${i}]`;
      camel.push(get(data, node).label);
    });
  }

  return [get(data, node), node, camelcase(camel.join(" ")), lastIndex];
};

export const findNodeId = (id, data, findParent = false) => {
  const loop = (data, parent, path = []) => {
    for (let index = 0, length = data.length; index < length; index++) {
      const node = data[index];

      path[path.length] = index;

      if (node.value === id) {
        if (findParent) path.splice(path.length - 1, 1);

        return [findParent ? parent : node, path];
      }

      if (node.children?.length > 0) {
        const [n, p] = loop(node.children, node, [...path]);
        if (n) {
          return [n, p];
        }
      }
      path.splice(path.length - 1, 1);
    }

    return [];
  };

  let [node, path] = loop(data);

  return [node, path?.map((n) => `[${n}]`).join(".children"), path?.[path.length - 1]];
};

export function cleanDuplicates(data) {
  const ids = new Set();

  const loop = (data) => {
    for (let index = 0, length = data.length; index < length; index++) {
      const node = data[index];

      if (node) {
        if (!node.value || ids.has(node.value)) data.splice(index, 1);
        else {
          ids.add(node.value);

          if (node.children?.length > 0) loop(node.children);
        }
      }
    }
  };

  loop(data);
}
