import { useMemo, useState, useCallback, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import capitalize from "lodash.capitalize";
import cloneDeep from "lodash.clonedeep";
import { useItemContext } from "../../hooks/useItem";
import { useDataContext } from "../../hooks/useData";
import Container from "../container";
import Loading from "../loading";
import Carousel from "../analitica/carousel";
import InputPersonas from "../personas/inputPersonas";
import { explorer } from "../../transport";
import { db } from "../../db";

import styles from "./styles.module.scss";

// Cookie Expire Time
const expires = new Date();
expires.setFullYear(expires.getFullYear() + 10);

export default function Preview(props) {
  const { dataWorker } = useDataContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { nickname, tenant } = useSelector((state) => state.user);
  const dataKey = useMemo(() => `ares-meta-pack-${nickname}`, [nickname]);
  const [cookies, setCookie] = useCookies([dataKey]);
  const [userId, setUserId] = useState(props.userId ?? cookies[dataKey]);
  const [contentId, setContentId] = useState();
  const { values: valori, dirty } = useFormikContext() ?? {};
  const { isNew } = useItemContext() ?? {};

  const values = props.values ?? valori;

  const [tagsMap, segmentsMap] = useMemo(() => {
    if (!values) return [[], []];

    const valori = cloneDeep(values);
    const tags = new Map();
    const segments = new Map();
    let ids = valori.pipelines?.static ?? [];

    valori.pipelines?.dynamic?.forEach(({ id }) => ids.push(id));
    if (ids.length > 0) {
      const pipes = db.bulkGet("pipelines", ids);
      if (pipes.length > 0) {
        pipes.forEach((pipe) => {
          pipe.tags?.rules?.forEach?.(({ id, label }) => tags.set(id, label));
          pipe.segments?.rules?.forEach?.(({ id, label }) => segments.set(id, label));
        });
      }
    }

    return [tags, segments];
  }, [values]);

  const handleSetUserId = useCallback(
    (e) => {
      const value = e.target.value;
      setCookie(dataKey, value, { expires });
      setUserId(value);
    },
    [dataKey, setCookie]
  );

  const reload = useCallback(() => {
    if (!values) return;
    if (values.userBased && !userId) return;
    if (values.useSimilarity && !contentId) return;

    if (!isNew) {
      setLoading(true);
      explorer.post(`getMetaPack`, { tenant, id: values.id, userId }).then(async ({ data }) => {
        const resp = {};
        const dati = data.metadata;

        if (values.injects?.fingerprint?.active) {
          const raw = dati[values.injects?.fingerprint?.key];
          const tokens = raw.split(" ");
          const translate = await dataWorker.translateFingerprints(tokens);
          resp.fingerprint = { raw, translate: [...translate.values()] };
        }
        if (values.injects?.synthesis?.active) {
          const prefix = values.injects.synthesis.key;
          resp.synthesis = Object.entries(dati)
            .filter(([key, value]) => key.includes(prefix) && value.length > 0)
            .map((arr) => ({ key: arr[0], value: arr[1].split(",") }));
        }
        if (values.injects?.tags?.active) {
          const key = values.injects.tags.key;
          resp.tags = dati[key].split(",").map((key) => ({ key, value: tagsMap.get(key) }));
        }
        if (values.injects?.segments?.active) {
          const key = values.injects.segments.key;
          resp.segments = dati[key].split(",").map((key) => ({ key, value: segmentsMap.get(key) }));
        }

        resp.catalogs = [
          {
            catalog: dati.__meta_pack_catalog,
            content: dati.__meta_pack_contentIds.split(",").map((id) => ({ id })),
          },
        ];

        setData(resp);
        setLoading(false);
      });
    }
  }, [contentId, dataWorker, isNew, segmentsMap, tagsMap, tenant, userId, values]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reload(), []);

  const header = useMemo(() => {
    if (!values) return null;
    if (props.showUserId === false) return null;

    if (values.userBased) {
      return (
        <div className={styles.previewHeader}>
          <Form.Label>UserId:</Form.Label>
          <InputPersonas
            value={userId}
            onChange={handleSetUserId}
            className={styles.previewUserId}
          />
        </div>
      );
    }

    if (values.useSimilarity) {
      return (
        <div className={styles.previewHeader}>
          <Form.Label>ContentId:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ContentId"
            value={contentId ?? ""}
            onChange={(e) => setContentId(e.target.value)}
            className={styles.previewUserId}
            isInvalid={!contentId}
          />
        </div>
      );
    }
    return null;
  }, [contentId, handleSetUserId, userId, values]);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faSyncAlt}
        className={`${styles.previewReload} ${loading ? styles.previewLoading : ""}`}
        spin={loading}
        onClick={reload}
      />
    );
  }, [loading, reload]);

  if (isNew || dirty)
    return (
      <Container
        title="Save Pack for Preview"
        folded={true}
        foldable={false}
        coloredBars={false}
        color="#0a4672"
      />
    );

  return (
    <Container
      title="Preview"
      help="meta-pack-preview"
      foldable={data !== undefined}
      folded={data !== undefined ? false : true}
      coloredBars={false}
      color="#0a4672"
      headerOnlyOnFolded={false}
      header={header}
      extra={extra}
    >
      {loading ? (
        <Loading width={"100%"} height="150px" />
      ) : (
        <div className={styles.preview}>
          <ContentPreview data={data?.catalogs} />
          <TagSeg type="segments" data={data?.segments} />
          <TagSeg type="tags" data={data?.tags} />
          <Synthesis data={data?.synthesis} />
          <Fingerprint data={data?.fingerprint} />
        </div>
      )}
    </Container>
  );
}

function ContentPreview(props) {
  const data = props.data;

  if (!data) return null;

  return (
    <Container
      title="Content"
      help="meta-pack-content"
      folded={false}
      foldable={false}
      headerOnlyOnFolded={false}
      header={<span className={styles.contentCount}>{data[0].content.length}</span>}
    >
      <Carousel catalogs={data} />
    </Container>
  );
}

function TagSeg(props) {
  const { data, type } = props;

  const header = useMemo(() => {
    if (!data) return null;

    return (
      <div className={styles.tagSegHeader}>
        {data.map(({ key, value }) => (
          <span className={styles.tagSeg} key={key}>
            {value}
          </span>
        ))}
      </div>
    );
  }, [data]);

  if (!data) return null;

  return (
    <Container
      title={capitalize(type)}
      help={`meta-pack-${type}`}
      header={header}
      folded={true}
      headerOnlyOnFolded={false}
      foldable={false}
    />
  );
}

function Synthesis(props) {
  const data = props.data;

  if (!data) return null;

  return (
    <Container title="Synthesis" help="meta-pack-synthesis" folded={true}>
      <div className={styles.scrollContainer}>
        <Table variant="dark" size="sm" striped hover>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ key, value }, i) => {
              return (
                <tr key={`${i}-${key}`}>
                  <td>{key}</td>
                  <td>
                    <div className={styles.synthValueBox}>
                      {value.map((v, i) => (
                        <span key={`${i}-${v}`} className={styles.synthValue}>
                          {v}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

function Fingerprint(props) {
  const data = props.data;

  if (!data) return null;

  return (
    <Container title="Fingerprint" help="meta-pack-fingerprint" folded={true}>
      <Container title="Raw" folded={false}>
        <Form.Control as="textarea" rows={3} defaultValue={data.raw} />
      </Container>
      <Container title="Translated" folded={false}>
        <div className={styles.scrollContainer}>
          <Table variant="dark" size="sm" striped hover>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody className={styles.scrollContainer}>
              {data.translate.map(({ key, value }, i) => {
                return (
                  <tr key={`${i}-${key}`}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </Container>
  );
}
