import styled, { createGlobalStyle, keyframes, css } from "styled-components";

const Global = createGlobalStyle`
  @import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
`;

const colors = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
`;

const Box = styled.div`
  ${({ width, height, marginTop, opacity }) => {
    return `
        width: ${width};
        height: ${height};
        margin-top: ${marginTop};
        opacity: ${opacity};
    `;
  }}

  ${({ position, top, bottom }) => {
    if (position) {
      return `
        position: ${position};
        top: ${top};
        bottom: ${bottom};
    `;
    }
  }}
`;

const Gradient = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({ opacity }) => {
    return `
        opacity: ${opacity};
        background: linear-gradient(
            270deg,
            hsla(210, 100%, 20%, ${opacity}),
            hsla(38, 100%, 35%, ${opacity}),
            hsla(194, 91%, 26%, ${opacity}),
            hsla(178, 90%, 24%, ${opacity})
        );
    `;
  }}

  background-size: 800% 800%;

  ${({ duration }) =>
    css`
      animation: ${colors} ${duration} ease infinite;
    `}
`;

const Headline = styled.h1`
  display: flex;
  justify-content: center;
  align-content: center;
  border: 3px solid #fff;
  padding: 20px;
  font-size: 4vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -5px;
  color: #fff;
`;

export default function Loading({
  width = "100vw",
  height = "100vh",
  marginTop = "0px",
  opacity = "0.5",
  duration = "2s",
  top = 0,
  bottom = 0,
  position,
  message = "LOADING",
}) {
  return (
    <>
      <Global />
      <Box
        width={width}
        height={height}
        marginTop={marginTop}
        opacity={opacity}
        position={position}
        top={top}
        bottom={bottom}
      >
        <Gradient opacity={opacity} duration={duration}>
          <Headline>{message}</Headline>
        </Gradient>
      </Box>
    </>
  );
}
