import { useEffect, useState, useMemo } from "react";
import { useMainContext } from "../../hooks/useMainContext";
//import { Image } from "react-bootstrap";
import Avatar from "../avatar";

export default function Nick2(props) {
  const { picture, tenant, name } = props;
  const [opacity, setOpacity] = useState(1);
  const { getTimeoutCounter } = useMainContext();

  const backgroundColor = useMemo(() => {
    const low = tenant.toLowerCase();
    return low.includes("prod") ? "deeppink" : "deepskyblue";
  }, [tenant]);

  useEffect(() => {
    const timeoutInterval = setInterval(
      () => setOpacity(getTimeoutCounter().timeToSignoutPerc),
      5000
    );

    return () => clearInterval(timeoutInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span style={{ opacity }}>
      <Avatar src={picture} />

      <span className="user-name">{name.toUpperCase()}</span>
      <span className="user-tenant" style={{ backgroundColor }}>
        {tenant}
      </span>
    </span>
  );
}
