import { forwardRef, createRef, Fragment, useCallback, useRef, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { useItemContext } from "../../hooks/useItem";
import { useDataRefObj } from "../../hooks/useDataRef";
import { DragAndDrop } from "../../hooks/useDragAndDrop";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import { Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { formatCondition } from "../conditionsBox";
import { formatStatement } from "./rule";

import idGen from "../../utils/idGen";

import Container from "../container";
import Metadata from "../metadata";
import ContentType from "../contentType";
import Rule from "./rule";
import Clauses from "./clauses";
import LabelSelect from "../selects/selectLabel";
import AndOrSelector from "../query/andOrSelector";

const labelStyle = { width: "400px" };
const labelTypes = ["rules"];

const BusinessRule = forwardRef((props, ref) => {
  const refsMap = useRef(new Map());
  const errorsMap = useRef(new Set());
  const { values, errors, setFieldValue, setValues, handleChange } = useFormikContext();
  const info = useDataRefObj("rules", values);
  const { isCopy } = useItemContext();
  const labelMain = useGetMemItem("labelsAll", values.label);
  const scheduleRef = useRef(new Map(values.schedulingMap));

  ref.current.info = info;

  const emptyRule = useCallback(
    () => ({
      _id: idGen(),
      condition: `*`,
      boost: 1,
      type: "I2I",
    }),
    []
  );

  const addEmptyRule = useCallback(() => {
    const newValue = [...(values.rule || []), emptyRule()];
    setFieldValue("rule", newValue);
  }, [emptyRule, setFieldValue, values.rule]);

  useEffect(() => {
    if (values.contentType && !values.rule) {
      addEmptyRule();
    }
  }, [addEmptyRule, values.contentType, values.rule]);

  const onScheduled = useCallback(
    (values, scheduled, scheduling, index) => {
      if (scheduled) scheduleRef.current.set(index, scheduling);
      else scheduleRef.current.delete(index);

      const sumSchedule = [];
      [...scheduleRef.current.values()].forEach((arr) => {
        arr.forEach((sked) => sumSchedule.push(sked));
      });

      setValues({
        ...values,
        scheduled: scheduleRef.current.size > 0,
        scheduling: sumSchedule,
        schedulingMap: Array.from(scheduleRef.current.entries()),
      });
    },
    [setValues]
  );

  useEffect(() => {
    if (isCopy) {
      const rules = values?.rule || [];
      rules.forEach((rule) => {
        rule._id = idGen();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeContentType = useCallback(
    (contentType, realm) => {
      setValues({ ...values, contentType, realm, field: undefined });
    },
    [setValues, values]
  );

  const doDelete = useCallback(
    (ruleIndex) => {
      const newValue = [...values.rule];
      newValue.splice(ruleIndex, 1);
      setFieldValue("rule", newValue);
    },
    [setFieldValue, values.rule]
  );

  const footer = useCallback(
    (index) => {
      if (values.rule?.length <= 1) return null;
      return (
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => doDelete(index)}
          style={{ float: "right" }}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="delete-icon" /> Delete Statement
        </Button>
      );
    },
    [doDelete, values.rule]
  );

  const mainFooter = useMemo(() => {
    return (
      <Button size="sm" variant="outline-primary" onClick={addEmptyRule}>
        Add Statement
      </Button>
    );
  }, [addEmptyRule]);

  const title = useMemo(() => {
    return (
      <Fragment>
        Statements <span className="statements-count-badge">{values?.rule?.length || 0}</span>
      </Fragment>
    );
  }, [values.rule]);

  const extraLabel = useMemo(() => {
    if (labelMain) {
      const { background, color, value } = labelMain;
      return (
        <div className="label-main" style={{ backgroundColor: background, color: color }}>
          {value}
        </div>
      );
    }
  }, [labelMain]);

  const mainHeader = useMemo(() => {
    return (
      <div className="main-header">
        <span className="label">Catalog</span>
        <span className="clause">{values.contentType}</span>
        <span className="label">Clauses</span>
        {values.clauses?.map((c) => {
          return (
            <span className={`clause clause-${c}`} key={c}>
              {c === "filter" ? "Keep Only" : c}
            </span>
          );
        })}
      </div>
    );
  }, [values]);

  const ruleHeader = useCallback((rule) => {
    return (
      <div className="rule-header">
        <span className="if">if</span>
        {formatCondition(rule.condition)}
        <span className="then">then</span>
        {formatStatement(rule)}
      </div>
    );
  }, []);

  const statementsExtra = useMemo(() => {
    if (!values.rule?.length > 1) return null;

    return (
      <div className="statements-extra">
        {values.grouped && (
          <div className="and-or">
            <AndOrSelector
              operator={values.operator}
              defaultOperator="or"
              onChangeOperator={(e) => setFieldValue("operator", e)}
              showTie={false}
            />
          </div>
        )}
        <Form.Check
          type="switch"
          id="group-statements"
          checked={values.grouped ?? false}
          value="true"
          onChange={(e) => setFieldValue("grouped", e.target.checked)}
          name="group-statements"
          label="Group"
        />
      </div>
    );
  }, [setFieldValue, values.grouped, values.operator, values.rule?.length]);

  const settingsStartFolded = useRef(values.contentType !== undefined);
  const handleLabelChange = useCallback((v) => setFieldValue("label", v), [setFieldValue]);

  return (
    <span>
      <Container
        title="Settings"
        help="rule-main-params"
        color="#06141a"
        folded={settingsStartFolded.current}
        header={mainHeader}
        extra={extraLabel}
        warning={errors.settings}
      >
        <Row className="settings-row">
          <Col sm="1">Label</Col>
          <Col sm="3">
            <LabelSelect
              types={labelTypes}
              style={labelStyle}
              value={values.label}
              handleChange={handleLabelChange}
            />
          </Col>
        </Row>
        <Row className="settings-row">
          <Col sm="1">Catalog</Col>
          <Col sm="3">
            <ContentType
              rootNode={values}
              fixedValue={values.contentType}
              onChange={handleChangeContentType}
              onlySelect={true}
            />
          </Col>
        </Row>

        <Clauses />
      </Container>

      {values.contentType ? (
        <span>
          <span>
            <Metadata
              rootNode={values.metadata}
              rootNodeName="metadata"
              rootNodePath="metadata."
              contentType={values.contentType}
              uniqueId={`rule-${values.id}`}
            />

            <Container
              title={title}
              help="rule-statements"
              color="#0b111a"
              footer={mainFooter}
              warning={errors.statements}
              extra={statementsExtra}
            >
              <DragAndDrop>
                {values.rule &&
                  values.rule.map((r, i) => {
                    if (!refsMap.current.has(r._id)) {
                      refsMap.current.set(r._id, createRef());
                    }
                    const error = errors.statementsList?.[r._id]?.error;
                    if (error && !errorsMap.current.has(r._id)) errorsMap.current.add(r._id);

                    const title = (
                      <>
                        {r.scheduled ? (
                          <FontAwesomeIcon icon={faCalendar} className="statement-scheduled-icon" />
                        ) : null}
                        {`#id: ${r._id}`}
                      </>
                    );

                    return (
                      <Container
                        id={r._id}
                        key={r._id}
                        ref={refsMap.current.get(r._id)}
                        title={title}
                        help="rule-statement"
                        color="#180c0c"
                        coloredBars={false}
                        coloredTitle={true}
                        hideBodyOnFold={true}
                        footer={footer(i)}
                        warning={error}
                        folded={!errorsMap.current.has(r._id)} //!error
                        header={ruleHeader(r)}
                        extra={
                          i !== values.rule.length - 1 ? (
                            <Form.Check
                              type="switch"
                              checked={r.break === true ? true : false}
                              value={true}
                              onChange={handleChange}
                              name={`rule[${i}].break`}
                              id={`rule[${i}].break`}
                              label="Skip Others If Executed"
                              className="skip-switch"
                            />
                          ) : null
                        }
                      >
                        <Rule
                          ref={refsMap.current.get(r._id)}
                          rule={r}
                          ruleIndex={i}
                          rootNode={values["rule"][i]}
                          rootNodePath={`rule[${i}].`}
                          onScheduled={(values, scheduled, scheduling) =>
                            onScheduled(values, scheduled, scheduling, i)
                          }
                          info={info}
                        />
                      </Container>
                    );
                  })}
              </DragAndDrop>
            </Container>
          </span>
        </span>
      ) : null}
    </span>
  );
});

export default BusinessRule;
