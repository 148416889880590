import { DndProvider, useDrag, useDrop } from "react-dnd"; //createDndContext
import { HTML5Backend } from "react-dnd-html5-backend";

import { useCallback } from "react";
import { useFormikContext } from "formik";

import move from "lodash-move";

export default function useDragAndDrop(ref, id, index, accessor, itemValues, handleSetValues) {
  const { values, setFieldValue } = useFormikContext() || {};

  const moveItem = useCallback(
    (dragIndex, hoverIndex, id, accessor) => {
      const ArrayToMove = itemValues ? itemValues : values[accessor];

      (handleSetValues || setFieldValue)(
        accessor,
        move(ArrayToMove, dragIndex, hoverIndex),
        dragIndex,
        hoverIndex
      );
    },
    [itemValues, values, handleSetValues, setFieldValue]
  );

  const [{ highlighted, hovered }, drop] = useDrop({
    accept: `rule-${accessor}`,
    // canDrop(item, monitor) {
    //   //console.log("CAN DROP", monitor.getItem().ref.current !== null && item.ref.current !== null);
    //   return monitor.getItem().ref.current !== null && item.ref.current !== null;
    // },

    drop(item, monitor) {
      if (!ref.current || !monitor.getItem().ref.current || !monitor.canDrop()) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

      if (upwards && (leftwards || rightwards)) {
        return;
      }

      if (downwards && (leftwards || rightwards)) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex, id, accessor);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect(monitor) {
      return {
        highlighted: monitor.canDrop(),
        hovered: monitor.isOver(),
      };
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id, index, ref },
    type: `rule-${accessor}`,
    // isDragging(monitor) {
    //   //console.log("DRaG", monitor.getItem().ref.current);
    //   return monitor.getItem().ref.current !== null;
    // },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.3 : 1;
  drag(drop(ref));

  return { opacity, highlighted, hovered };
}

// const RNDContext = createDndContext(HTML5Backend);

// function useDNDProviderElement(props) {
//   const manager = useRef(RNDContext);

//   if (!props.children) return null;

//   return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>;
// }

// export function DragAndDrop(props) {
//   const DNDElement = useDNDProviderElement(props);
//   return <Fragment>{DNDElement}</Fragment>;
// }

export function DragAndDrop(props) {
  return <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>;
}
