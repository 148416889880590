import { useMemo, useCallback, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import get from "lodash.get";
import stubs from "../../utils/stubs";
import Container from "../container";
import UserPref from "../userPref";

export default function UserMeta(props) {
  const {
    metadata,
    updateMetadata,
    accessor,
    label,
    rootNodePath,
    rootNodeName,
    uniqueId,
    contentType,
    userTypes,
    showCatalog,
    showFingerprint,
    showEvent,
    forceUserCatalog,
    hideTabs,
  } = props;
  const { handleBlur, errors } = useFormikContext() || stubs.formik;
  const userContentTypeError = useMemo(
    () => errors[rootNodeName]?.[`${accessor}_userContentType`],
    [accessor, errors, rootNodeName]
  );
  const [edit, setEdit] = useState(
    userContentTypeError || metadata?.[accessor]?.userContentType === undefined
  );
  const required = useMemo(
    () => get(metadata, `${accessor}.required`) || false,
    [accessor, metadata]
  );
  const [folded, setFolded] = useState(true);
  const reject = useMemo(
    () => !accessor.includes("similar") && userTypes.includes(contentType),
    [accessor, contentType, userTypes]
  );

  useEffect(() => {
    if (userContentTypeError && !edit) {
      setEdit(true);
    }
  }, [edit, userContentTypeError]);

  // const updateType = useCallback(
  //   (contentType) => {
  //     if (!contentType) return;

  //     updateMetadata(`${accessor}.userContentType`, contentType);

  //     setEdit(false);
  //   },
  //   [accessor, updateMetadata]
  // );

  const onUpdate = useCallback(
    (key, value) => {
      if (!value) return;
      updateMetadata(`${accessor}.${key}`, value);
      setEdit(false);
    },
    [accessor, updateMetadata]
  );

  const onChange = useCallback(
    (e) => {
      const check = e.target.checked;
      if (check && folded) setFolded(false);
      updateMetadata(`${accessor}.required`, check);
    },
    [accessor, folded, updateMetadata]
  );

  useEffect(() => {
    if (!required) setFolded(true);
  }, [required]);

  if (reject) return null;

  return (
    <Container
      color="#030b0e"
      help={`metadata-${accessor}`}
      folded={folded} //
      foldable={required}
      onFold={setFolded}
      coloredBars={!folded}
      margin={"4px"}
      title={
        <Form.Check
          type="switch"
          checked={get(metadata, `${accessor}.required`) || false}
          onChange={onChange}
          onBlur={handleBlur}
          id={`${rootNodePath}${accessor}.required-${uniqueId}`}
          value="true"
          name={`${rootNodePath}${accessor}.required`}
          label={label}
        />
      }
      warning={errors[rootNodeName]?.[accessor]}
    >
      {get(metadata, `${accessor}.required`) === true ? (
        <>
          <UserPref
            values={metadata?.[accessor]}
            errors={errors[rootNodeName]}
            errorsPrefix={`${accessor}_`}
            catalog={metadata[accessor].contentType || contentType}
            onChange={onUpdate}
            showCatalog={showCatalog}
            showEvent={showEvent}
            showFingerprint={showFingerprint}
            forceUserCatalog={forceUserCatalog}
            hideTabs={hideTabs}
          />
          {props.children ? props.children : null}
        </>
      ) : null}
    </Container>
  );
}
