import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useGetMemTable } from "../../hooks/useMemoryDB";
import {
  useDataContext,
  useDataStateSelector,
  useDataSetStatus,
  useDataSaveItem,
} from "../../hooks/useData";
import usePermissions from "../../hooks/usePermissions";
import { Row, Col, Button, Badge, Form } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import LZString from "lz-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faDatabase,
  faFlask,
  faCircleNotch,
  faDownLeftAndUpRightToCenter,
  faTriangleExclamation,
  faDownload,
  faFileExport,
  faLinkSlash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { db, refManager } from "../../db";
import DataMapExplorer from "../dataMapExplorer";
import { RefPane } from "../item/ref";
import Pills from "../pills";
import Container from "../container";

import styles from "./data.module.scss";

const apiEndp = process.env.REACT_APP_API_ENDPOINT;

export default function DataPanel(props) {
  const points = useGetMemTable("aresInternals");
  const set = useDataSetStatus();
  const { expert } = usePermissions();
  const { tenant, id_token } = useSelector((state) => state.user);
  const active = useDataStateSelector((state) => state.dbStats);
  const toggleDB = useCallback((e) => set("dbStats", e.target.checked), [set]);
  const [isPending, startTransition] = useTransition();
  const [warns, setWarns] = useState([]);

  useEffect(() => {
    startTransition(() => {
      setImmediate(() => {
        const [map, array] = refManager.getAllWarnings();
        setWarns({ map, array });
      });
    });
  }, []);

  const deleteMetadataCache = useCallback(() => {
    axios
      .get(`${apiEndp}/metadata/refresh/${tenant}`, {
        headers: { id_token },
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id_token, tenant]);

  const pills = useMemo(() => {
    const tabs = [];

    if (points) {
      tabs.push({
        label: (
          <div className={styles.pageTab}>
            <span className={styles.icon}>
              <FontAwesomeIcon
                icon={faDownLeftAndUpRightToCenter}
                className={styles.icon}
              />
            </span>
            <span className={styles.label}>Updates</span>
          </div>
        ),
        pane: (
          <div className={styles.updateBox}>
            <div className={styles.body}>
              {points ? (
                <Fragment>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Settings</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("settingsFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                    </Col>
                    <ReloadButton realm="settings" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Configurations</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("dataFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div className={styles.realms}>
                        {points.get("dataFetch")?.realms?.map((realm) => (
                          <Badge
                            key={realm}
                            bg="secondary"
                            className={styles.realm}
                          >
                            {realm}
                          </Badge>
                        ))}
                      </div>
                    </Col>
                    <ReloadButton realm="configurations" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>
                        Metadata Volatile
                      </span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {points.has("metadataVolatile")
                          ? moment(
                              points.get("metadataVolatile")?.lastUpdate
                            ).format("dddd, MMMM Do YYYY, h:mm:ss a")
                          : "No Update Needed"}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Volatiles {points.get("metadataVolatile")?.length}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="volatile" />
                  </Row>

                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Metadata</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {points.has("metadataFetch")
                          ? moment(
                              points.get("metadataFetch")?.lastUpdate
                            ).format("dddd, MMMM Do YYYY, h:mm:ss a")
                          : "No Data"}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Metadata {points.get("metadataFetch")?.metadataLength}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="metadata">
                      {expert.includes("metadata-cache-refresh") ? (
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={deleteMetadataCache}
                        >
                          <FontAwesomeIcon
                            icon={faSyncAlt}
                            className={styles.icon}
                          />{" "}
                          Refresh
                        </Button>
                      ) : null}
                    </ReloadButton>
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Health</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("healthFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Regressions Critical{" "}
                          {points.get("healthFetch")?.regressions
                            ? "TRUE"
                            : "FALSE"}
                        </Badge>
                        <Badge bg="secondary" className={styles.realm}>
                          Versions Critical{" "}
                          {points.get("healthFetch")?.versions
                            ? "TRUE"
                            : "FALSE"}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="health" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Policies</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("policiesFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Tenants {points.get("policiesFetch")?.tenants}
                        </Badge>
                        <Badge bg="secondary" className={styles.realm}>
                          Permissions {points.get("policiesFetch")?.permissions}
                        </Badge>
                        <Badge bg="secondary" className={styles.realm}>
                          Groups {points.get("policiesFetch")?.groups}
                        </Badge>
                        <Badge bg="secondary" className={styles.realm}>
                          Roles {points.get("policiesFetch")?.roles}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="policies" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Users</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("usersFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Users {points.get("usersFetch")?.userLength}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="users" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Help</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("helpFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                    </Col>
                    <ReloadButton realm="help" />
                  </Row>
                  <Row className={styles.row}>
                    <Col sm="3">
                      <span className={styles.dataTitle}>Notes</span>
                    </Col>
                    <Col>
                      <div className={styles.lastUpdate}>
                        {moment(points.get("notesFetch")?.lastUpdate).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div className={styles.realms}>
                        <Badge bg="secondary" className={styles.realm}>
                          Notes {points.get("notesFetch")?.length}
                        </Badge>
                      </div>
                    </Col>
                    <ReloadButton realm="notes" />
                  </Row>
                </Fragment>
              ) : null}
            </div>
          </div>
        ),
      });
    }

    if (warns.map.size > 0) {
      tabs.push({
        label: (
          <div className={styles.pageTab}>
            <span className={styles.icon}>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className={styles.icon}
              />
            </span>
            <span className={styles.label}>
              Warnings{" "}
              <span className={styles.waringCounter}>{warns.array.length}</span>
            </span>
          </div>
        ),
        pane: (
          <div className={styles.warningBox}>
            <RefPane data={warns.array} />
          </div>
        ),
      });
    }

    tabs.push({
      label: (
        <div className={styles.pageTab}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faDatabase} className={styles.icon} />
          </span>
          <span className={styles.label}>DB</span>
        </div>
      ),
      pane: <DataMapExplorer data={db.getDB()} canSave={true} />,
    });

    tabs.push({
      label: (
        <div className={styles.pageTab}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faLinkSlash} className={styles.icon} />
          </span>
          <span className={styles.label}>References</span>
        </div>
      ),
      pane: <DataMapExplorer data={refManager.getRefMap()} />,
    });

    tabs.push({
      label: (
        <div className={styles.pageTab}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faFlask} className={styles.icon} />
          </span>
          <span className={styles.label}>Analitica</span>
        </div>
      ),
      pane: <Analitica tenant={tenant} />,
    });

    tabs.push({
      label: (
        <div className={styles.pageTab}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faFileExport} className={styles.icon} />
          </span>
          <span className={styles.label}>Export </span>
        </div>
      ),
      pane: <Export tenant={tenant} />,
    });
    return tabs;
  }, [points, deleteMetadataCache, expert, warns, tenant]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.box}>
          <div className={styles.title}>
            <FontAwesomeIcon
              icon={isPending ? faCircleNotch : faDatabase}
              className={styles.icon}
              spin={isPending}
            />
            Data Panel
          </div>
          <div className={styles.switch}>
            <Form.Check
              type="switch"
              value={true}
              checked={active || false}
              onChange={toggleDB}
              id={`dbSwitch`}
              name={`dbSwitch`}
              label="DB Stats"
            />
          </div>
        </div>
      </div>
      <Pills
        id="data-pane-pills"
        defaultActiveTab={5}
        className={styles.pills}
        enableReinitialize={true}
        showHiddenItemsMenu={false}
        tabs={pills}
      />
    </div>
  );
}

function ReloadButton(props) {
  const realm = props.realm;
  const { update } = useDataContext();
  const loading = useDataStateSelector((state) => state[`loading-${realm}`]);

  const handleUpdate = useCallback(() => {
    update[realm]();
  }, [realm, update]);

  return (
    <Col className={styles.action} sm="2">
      <Button size="sm" variant="outline-dark" onClick={handleUpdate}>
        <FontAwesomeIcon icon={faCog} spin={loading} className={styles.icon} />{" "}
        Update
      </Button>
      {props.children}
    </Col>
  );
}

const downloadData = (filename, data) => {
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(data))], {
      type: contentType,
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href = "data:" + contentType + "," + encodeURIComponent(data);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

function Export({ tenant }) {
  const [working, setWorking] = useState(false);

  const download = useCallback(() => {
    setWorking(true);
    setTimeout(() => {
      const database = db.export();
      const refs = refManager.export();
      const data = LZString.compressToUTF16(
        JSON.stringify({ db: database, refs })
      );
      downloadData(`${tenant}.data.ares.cc`, data);
      setWorking(false);
    }, 150);
  }, [tenant]);

  return (
    <div className={styles.exportBox}>
      <Button
        className={styles.dbBtn}
        variant="outline-dark"
        onClick={download}
      >
        <FontAwesomeIcon
          icon={working ? faCircleNotch : faDownload}
          className={styles.downIcon}
          spin={working}
        />
        Export All
      </Button>
    </div>
  );
}

function getPercentageString(valuesArray, totalArray) {
  const percentage = (valuesArray.length / totalArray.length) * 100;
  return `${percentage.toFixed(2)}%`;
}

function sortByKey(a, b, key = "sortKey") {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
}

function Analitica({ tennant }) {
  const saveItem = useDataSaveItem({ confirm: true, notify: true });
  const [onlyProperty, setOnlyProperty] = useState(false);
  const data = useGetMemTable("endpoints");

  const endpoints = useMemo(() => {
    return [...data.values()]
      .filter(({ active }) => active)
      .map((endpoint) => {
        if (endpoint.propertyId) {
          endpoint.property = db.get("properties", endpoint.propertyId)?.title;
        }
        endpoint.sortKey = `${endpoint.property ?? ""} ${
          endpoint.title
        }`.trim();

        return endpoint;
      });
  }, [data]);

  const updateEndpoint = useCallback(
    (id, mode) => {
      const endpoint = data.get(id);
      endpoint.enableAnalitica = mode === "enabled" ? false : true;

      console.log(endpoint);

      saveItem({
        accessor: "endpoints",
        Id: endpoint.id,
        prevId: endpoint.id,
        isNew: false,
        data: endpoint,
      });
    },
    [data, saveItem]
  );

  const enabled = useMemo(() => {
    if (onlyProperty)
      return endpoints
        .filter(
          ({ enableAnalitica, propertyId }) => enableAnalitica && propertyId
        )
        .sort(sortByKey);
    return endpoints
      .filter(({ enableAnalitica }) => enableAnalitica)
      .sort(sortByKey);
  }, [endpoints, onlyProperty]);

  const disabled = useMemo(() => {
    if (onlyProperty)
      return endpoints
        .filter(
          ({ enableAnalitica, propertyId }) => !enableAnalitica && propertyId
        )
        .sort(sortByKey);
    return endpoints
      .filter(({ enableAnalitica }) => !enableAnalitica)
      .sort(sortByKey);
  }, [endpoints, onlyProperty]);

  const draw = useCallback(
    (mode) => {
      const data = mode === "enabled" ? enabled : disabled;

      return (
        <div className={styles.analiticaBox}>
          {data.map(({ reference, property, id }) => {
            return (
              <div className={styles.endpoint} key={id}>
                {property && (
                  <>
                    <div className={styles.property}>{property}</div>{" "}
                    <div className={styles.separator} />
                  </>
                )}
                <div className={styles.title}>{reference}</div>
                <div className={styles.divider} />
                <Button
                  size="sm"
                  variant={`outline-${
                    mode === "enabled" ? "danger" : "success"
                  }`}
                  onClick={() => updateEndpoint(id, mode)}
                >
                  {mode === "enabled" ? "Disable" : "Enable"}
                </Button>
              </div>
            );
          })}
        </div>
      );
    },
    [enabled, disabled, updateEndpoint]
  );

  return (
    <Container
      title={
        <div className={styles.analiticaTitle}>
          Endpoints{" "}
          <div className={styles.length}>
            {enabled.length + disabled.length}
          </div>
        </div>
      }
      foldable={false}
      className={styles.analiticaContainer}
      extra={
        <>
          <Form.Check
            type="switch"
            value={true}
            checked={onlyProperty}
            onChange={(e) => setOnlyProperty(e.target.checked)}
            id={`onlyProperty`}
            name={`onlyProperty`}
            label="Show Only Property Endpoints"
          />
        </>
      }
    >
      {disabled.length > 0 && (
        <Container
          title={
            <div className={styles.analiticaTitle}>
              Disabled <div className={styles.length}>{disabled.length}</div>
              <div className={styles.percentage}>
                {getPercentageString(disabled, endpoints)}
              </div>
            </div>
          }
          foldable={false}
        >
          {draw("disabled")}
        </Container>
      )}

      {enabled.length > 0 && (
        <Container
          title={
            <div className={styles.analiticaTitle}>
              Enabled <div className={styles.length}>{enabled.length}</div>
              <div className={styles.percentage}>
                {getPercentageString(enabled, endpoints)}
              </div>
            </div>
          }
          foldable={false}
        >
          {draw("enabled")}
        </Container>
      )}
    </Container>
  );
}
