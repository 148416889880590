import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelectMem } from "../../hooks/useMemoryDB";
import { formatCondition } from "../conditionsBox";
import Container from "../container";
import InputFieldsSelect from "../selects/selectInputFields";

import styles from "./styles.module.scss";
import { explorer } from "../../transport";

export default function EndpointExplorer(props) {
  const { endpointId, onData, defaultCacheKeys, paginateFromCache } = props;
  const { id_token, tenant } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const loadData = useCallback(() => {
    if (!tenant || !endpointId) return;
    setLoading(true);
    explorer
      .get(`endpoint/info/${tenant}/${endpointId}`, {
        headers: { id_token },
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setData(response.data);
          if (onData) onData(response.data.fields);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [endpointId, id_token, onData, tenant]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!data) return null;

  return (
    <Container
      title="Cache Keys"
      help="endpoint-cache-keys"
      color="#111"
      header={
        <CacheKeys
          data={data.cacheKeys}
          defaults={defaultCacheKeys}
          paginateFromCache={paginateFromCache}
        />
      }
      extra={
        <FontAwesomeIcon
          icon={faSyncAlt}
          pulse={loading}
          onClick={loadData}
          className={styles.reloadIcon}
        />
      }
      folded={true}
      headerOnlyOnFolded={false}
    >
      <UserIdFields
        endpoint={props.endpoint}
        keys={data.cacheKeys}
        onChange={props.onChangeUserIdFields}
      />
      <Filters endpoint={props.endpoint} onChange={props.onChangeFilters} />
      <div className={styles.body}>
        <Metadata data={data.metadata} />
        <Block table="blocks" label="Blocks" ids={data.blocks} />
        <Block table="queries" label="Queries" ids={data.queries} />
        <Block table="rulesPresets" label="Presets" ids={data.presets} path="presets" />
        <Block table="rules" label="Rules" ids={data.rules} />
        <Values data={data.values} />
        <Conditions data={data.conditions} />
      </div>
    </Container>
  );
}

function CacheKeys(props) {
  const { data, paginateFromCache, defaults = [] } = props;

  if (!data) return null;
  return (
    <div className={styles.cache}>
      {[...data, ...defaults]
        .filter((k) => (paginateFromCache && ["page", "hitsPerPage"].includes(k) ? false : true))
        .map((key) => (
          <div key={key} className={`${styles.key} ${styles[key]}`}>
            {key}
          </div>
        ))}
    </div>
  );
}

function Metadata(props) {
  const data = props.data;

  if (!data) return null;
  return (
    <div className={`${styles.block} ${styles.metadata}`}>
      <div className={styles.label}>Metadata</div>
      <div className={styles.elements}>
        {data.map((key) => (
          <div key={key} className={`${styles.element} ${styles[key]}`}>
            {key}
          </div>
        ))}
      </div>
    </div>
  );
}

function Conditions(props) {
  const data = props.data;

  const format = useCallback((basic) => {
    return formatCondition(basic);
  }, []);

  if (!data) return null;

  return (
    <div className={styles.conditions}>
      <div className={styles.label}>Conditions</div>
      <div className={styles.box}>
        {data.map((key) => (
          <div key={key} className={styles.element}>
            {format(key)}
          </div>
        ))}
      </div>
    </div>
  );
}

function Block(props) {
  const { ids, table, label, path } = props;
  const navigate = useNavigate();
  const data = useSelectMem(
    table,
    useCallback((i) => (ids ?? []).includes(i.id), [ids])
  );

  if (!ids) return null;
  if (!data) return null;

  return (
    <div className={`${styles.block} ${styles[label]}`}>
      <div className={styles.label}>{label}</div>
      <div className={styles.elements}>
        {(Array.isArray(data) ? data : [data]).map((key) => (
          <div
            key={key.id}
            className={`${styles.element} ${styles[key.id]}`}
            onClick={() => navigate(`/${path || table}/${key.id}`)}
          >
            {key.title}
          </div>
        ))}
      </div>
    </div>
  );
}

function Values(props) {
  const data = props.data;

  const filtered = useMemo(() => {
    return data.filter((d) => {
      const k = String(d);
      return k.includes("context_") || k.includes("query_");
    });
  }, [data]);

  if (!data || filtered.length === 0) return null;

  return (
    <div className={styles.block}>
      <div className={styles.label}>Values</div>
      <div className={styles.elements}>
        {filtered.map((key) => (
          <div key={key} className={styles.element}>
            {key}
          </div>
        ))}
      </div>
    </div>
  );
}

const filters = ["spaces", "diacritics", "interpunction", "lowercase"];

function Filters(props) {
  const { endpoint, onChange } = props;

  const handleAll = useCallback(
    (e) => {
      const all = e.target.checked;

      if (onChange) {
        if (all) onChange(filters);
        else onChange([]);
      }
    },
    [onChange]
  );

  const onChangeFilter = useCallback(
    (e, filter) => {
      if (onChange) {
        const checked = e.target.checked;
        const current = [...(endpoint?.params?.cacheConfig?.keysFilters || [])];

        if (checked) current.push(filter);
        else {
          const index = current.findIndex((k) => k === filter);
          if (index > -1) {
            current.splice(index, 1);
          }
        }
        onChange(current);
      }
    },
    [endpoint?.params?.cacheConfig?.keysFilters, onChange]
  );

  const allChecked = useMemo(() => {
    if (!endpoint?.params?.cacheConfig?.keysFilters) return true;
    return endpoint.params?.cacheConfig.keysFilters.length === filters.length;
  }, [endpoint.params?.cacheConfig.keysFilters]);

  const getFilterState = useCallback(
    (filter) => {
      if (allChecked) return true;
      const current = endpoint?.params?.cacheConfig?.keysFilters || [];

      return current.includes(filter);
    },
    [allChecked, endpoint?.params?.cacheConfig?.keysFilters]
  );

  return (
    <Container
      title="Filters"
      folded={true}
      extra={
        <Form.Check
          key="endpoint-filters-all"
          id="endpoint-filters-all"
          type="switch"
          label="All"
          onChange={handleAll}
          checked={allChecked}
        />
      }
    >
      {filters.map((filter) => (
        <Form.Check
          key={`endpoint-filters-${filter}`}
          id={`endpoint-filters-${filter}`}
          type="switch"
          label={filter}
          onChange={(e) => onChangeFilter(e, filter)}
          checked={getFilterState(filter)}
        />
      ))}
    </Container>
  );
}

function UserIdFields(props) {
  const { endpoint, keys, onChange } = props;
  const [field, setField] = useState();

  const show = useMemo(() => {
    return keys.includes("userId") || endpoint.params?.userIdFields?.active;
  }, [endpoint.params?.userIdFields?.active, keys]);

  const active = useMemo(
    () => endpoint.params?.userIdFields?.active || false,
    [endpoint.params?.userIdFields?.active]
  );
  const handleActive = useCallback(
    (e) => {
      const active = e.target.checked;
      if (onChange) {
        onChange({ ...(endpoint.params?.userIdFields || {}), active });
      }
    },
    [endpoint.params?.userIdFields, onChange]
  );

  const addField = useCallback(() => {
    if (field) {
      const fields = [...(endpoint.params?.userIdFields?.fields || []), field];
      if (onChange) {
        onChange({ ...(endpoint.params?.userIdFields || {}), fields });
      }
      setField(undefined);
    }
  }, [endpoint.params?.userIdFields, field, onChange]);

  const removeField = useCallback(
    (index) => {
      if (onChange) {
        const fields = [...(endpoint.params?.userIdFields?.fields || [])];
        fields.splice(index, 1);
        onChange({ ...(endpoint.params?.userIdFields || {}), fields });
      }
    },
    [endpoint.params?.userIdFields, onChange]
  );

  if (!show) return null;

  return (
    <Container
      title="UserId Fields"
      help="endpoint-userid-fields"
      foldable={active}
      folded={!active}
      extra={
        <Form.Check
          key="endpoint-useId-Fields-Active"
          id="endpoint-useId-Fields-Active"
          type="switch"
          label="Override userId Field"
          onChange={handleActive}
          checked={active}
        />
      }
    >
      <div className={styles.idFieldsBox}>
        <div className={styles.idFieldsSelectBox}>
          <InputFieldsSelect
            onChange={setField}
            value={field}
            excludes={endpoint.params?.userIdFields?.fields}
            className={styles.idFieldsSelect}
          />
          <Button size="sm" className={styles.addBtn} variant="outline-primary" onClick={addField}>
            <FontAwesomeIcon icon={faPlusSquare} className={styles.addIcon} />
            Add
          </Button>
        </div>
        <div className={styles.idFieldsListBox}>
          {(endpoint.params?.userIdFields?.fields || []).map((field, index) => {
            return (
              <div className={styles.field}>
                {field}{" "}
                <span className={styles.delete} onClick={() => removeField(index)}>
                  &#10005;
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
