import { Fragment, useMemo, useCallback, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import Editor from "@monaco-editor/react";

import cacheLabel from "./cacheLabel";
import RotaryKnob from "../rotaryKnob";
import SelectAllMultiple from "../selects/selectAllMultiple";
import ErrorMessage from "../errorMessage";

export default function Rest(props) {
  const { setFieldValue, values, handleChange, handleBlur } = useFormikContext();
  const kacheLabel = useMemo(() => cacheLabel(values.cache, "minutes"), [values.cache]);
  const filters = useMemo(() => {
    return {
      objectType: "external-source-decorator",
    };
  }, []);

  const handleChangeDecorator = useCallback(
    (decorators) => setFieldValue("decorators", decorators),
    [setFieldValue]
  );

  return (
    <div className="ext-box">
      <Row className="row">
        <Col sm={1} className="label">
          Verb
        </Col>
        <Col sm={2}>
          <Form.Control
            as="select"
            onChange={handleChange}
            onBlur={handleBlur}
            name="verb"
            value={values.verb || ""}
          >
            <option></option>
            <option>GET</option>
            <option>POST</option>
            <option>PUT</option>
          </Form.Control>
          <ErrorMessage name="verb" />
        </Col>
      </Row>
      <Row className="row">
        <Col sm={1} className="label">
          Url
        </Col>
        <Col sm={11}>
          <Form.Control
            type="text"
            placeholder="Enter URL eg. https://www.example.com/"
            onChange={handleChange}
            onBlur={handleBlur}
            name="url"
            value={values.url || ""}
          />
          <ErrorMessage name="url" />
        </Col>
      </Row>
      <Row className="row">
        <Col sm={1} className="label">
          Params
        </Col>
        <Col sm={11}>
          <Params />
        </Col>
      </Row>
      <Row className="row">
        <Col sm={1} className="label">
          Body
        </Col>
        <Col sm={11}>
          <Editor
            width="50vw"
            height="34vh"
            defaultLanguage="json"
            value={values.body}
            defaultValue="{}"
            theme="vs-dark"
            onChange={(value) => setFieldValue("body", value)}
            options={{ minimap: { enabled: false }, folding: true }}
          />
        </Col>
      </Row>
      <Row className="row">
        <Col sm={1} className="label">
          Decorators
        </Col>
        <Col sm={11}>
          <SelectAllMultiple
            accessor="decorators"
            filters={filters}
            values={values.decorators}
            handleChange={handleChangeDecorator}
          />
        </Col>
      </Row>
      <Row className="row">
        <Col sm={{ cols: 11, offset: 1 }}>
          <RotaryKnob
            value={Number(values.cache || 0)}
            min={0}
            max={1440}
            step={1}
            label={kacheLabel}
            labelPosition="bottom"
            skin="s9"
            onChange={(v) => setFieldValue("cache", v)}
            className="knob-group-generic"
          />
          <div className="knob-gorup">
            <Form.Check
              type="switch"
              id="compliant-switch"
              checked={values.compliant || false}
              value="true"
              onChange={(e) => setFieldValue("compliant", e.target.checked)}
              onBlur={handleBlur}
              name="compliant"
              label="ARES Compliant Response"
            />
            <Form.Check
              type="switch"
              id="sendPayload-switch"
              checked={values.sendPayload || false}
              value="true"
              onChange={(e) => setFieldValue("sendPayload", e.target.checked)}
              onBlur={handleBlur}
              name="sendPayload"
              label="Send Original Payload"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

// <Form.Check
//   type="switch"
//   id="defer-switch"
//   checked={values.defer || false}
//   value="true"
//   onChange={(e) => setFieldValue("defer", e.target.checked)}
//   onBlur={handleBlur}
//   name="defer"
//   label="Defer Call"
// />

function Params(props) {
  const { setFieldValue, values } = useFormikContext();

  const [key, setKey] = useState();
  const [value, setValue] = useState();

  const onAdd = useCallback(() => {
    if (key && value) {
      const newParams = [...(values?.params || [])];
      newParams.push(`${key}=${value}`);
      setFieldValue("params", newParams);
      setKey("");
      setValue("");
    }
  }, [key, setFieldValue, value, values]);

  const onRemove = useCallback(
    (index) => {
      const newParams = [...(values?.params || [])];
      newParams.splice(index, 1);
      setFieldValue("params", newParams);
    },
    [setFieldValue, values]
  );

  return (
    <Fragment>
      <div className="params-fields">
        <Form.Control
          type="text"
          value={key || ""}
          placeholder="Key"
          onChange={(e) => setKey(e.target.value)}
          name="key"
          className="input"
        />
        <span className="equals"> = </span>
        <Form.Control
          type="text"
          value={value || ""}
          placeholder="Value"
          onChange={(e) => setValue(e.target.value)}
          name="value"
          className="input"
        />
        <Button size="sm" variant="outline-primary" onClick={onAdd} className="btnAdd">
          <FontAwesomeIcon icon={faPlusSquare} /> Add
        </Button>
      </div>
      <div className="params-list">
        {values.params?.map((param, index) => {
          const [k, v] = param.split("=");
          return (
            <Fragment key={param}>
              <Button size="sm" variant="outline-light">
                {k} <span className="equals"> = </span> {v}
                <span
                  aria-hidden="true"
                  onClick={() => onRemove(index)}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                >
                  &times;
                </span>
              </Button>
              <span className="ampersend"></span>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
}
