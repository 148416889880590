import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import Injects from "../loaders/injects";

import styles from "./styles.module.scss";

export default function Output() {
  const { values, errors, setFieldValue } = useFormikContext();

  const extra = useMemo(() => {
    if (!values.userBased) return null;

    return (
      <Form.Check
        type="switch"
        value="true"
        checked={values.writeOutput ?? false}
        onChange={(e) => setFieldValue("writeOutput", e.target.checked)}
        id="activate-writeOutput"
        name="activate-writeOutput"
        label="Write Output in User Deck"
        className={styles.switch}
      />
    );
  }, [setFieldValue, values.userBased, values.writeOutput]);

  return (
    <Injects
      title="Output"
      extra={extra}
      checkPipes={false}
      showEgress={false}
      color="#043e69"
      coloredBars={false}
      errori={errors}
      showIds={true}
    />
  );
}
