import { useState, useCallback, useMemo } from "react";

import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import AutoSizer from "react-virtualized-auto-sizer";
import Carousel from "../../../../analitica/carousel";
import Container from "../../../../container";
import { Virtuoso } from "react-virtuoso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { CSVLink } from "react-csv";

//    "react-virtuoso": "^1.11.1",
import { short } from "../../../utils";
import DiffIcon from "../../../diffIcon";
import styles from "./styles.module.scss";

const difStyle = { display: "flex" };
export default function Searches(props) {
  const { data, title } = props;

  const [folded, setFolded] = useState(new Map());

  const DrawRow = useCallback(
    (index) => {
      const {
        query,
        count,
        userCount,
        avgResults,
        catalogs,
        userDiffPercentage,
        diffPercentage,
        avgResultsDiffPercentage,
        synonymsCount,
        synonyms,
        percentage,
        progressivePercentage,
      } = data[index];

      return (
        <Container
          title={
            <div className={styles.titleBox}>
              <FontAwesomeIcon icon={faHashtag} className={styles.titleIcon} />
              <span className={styles.titlePosition}>{index + 1}</span>
              <div className={styles.queryTitle}>
                {query}
                {synonymsCount > 0 ? (
                  <OverlayTrigger
                    key={query}
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                      <Tooltip id={`tooltip-${query}`}>
                        <div className={styles.tooltip}>
                          <strong>Aggregated Queries:</strong>
                          <ul>
                            {synonyms.map((s, index) => (
                              <li key={`${s}-${index}`}>{s}</li>
                            ))}
                          </ul>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className={styles.synonymsCount}> {synonymsCount} </div>
                  </OverlayTrigger>
                ) : null}
              </div>
            </div>
          }
          folded={!folded.get(index)}
          foldable={avgResults > 0}
          hover={true}
          coloredBars={false}
          onFold={(fold) => setFolded((state) => state.set(index, !fold))}
          style={{ "margin-bottom": "5px !important" }}
          extra={
            <div className={styles.extra}>
              {percentage ? (
                <div className={styles.percentages}>
                  <div className={styles.single}>{percentage}%</div>
                  <div className={styles.progressive}>{progressivePercentage}%</div>
                </div>
              ) : null}
              <div className={styles.elm}>
                <FontAwesomeIcon icon={faUser} className={`${styles.icon} ${styles.user}`} />
                {short(userCount)}
                <DiffIcon data={userDiffPercentage} style={difStyle} />
              </div>

              <div className={styles.elm}>
                <FontAwesomeIcon icon={faSearch} className={`${styles.icon} ${styles.search}`} />
                {short(count)}
                <DiffIcon data={diffPercentage} style={difStyle} />
              </div>
              <div className={`${styles.elm}  ${avgResults === 0 ? styles.emptyResponse : ""}`}>
                <FontAwesomeIcon icon={faFileAlt} className={`${styles.icon} ${styles.average}`} />
                {short(avgResults)}
                <DiffIcon data={avgResultsDiffPercentage} style={difStyle} />
              </div>
            </div>
          }
        >
          <Carousel catalogs={catalogs} />
        </Container>
      );
    },
    [folded, data]
  );

  const titolo = useMemo(() => {
    return (
      <div className={styles.searchTitle}>
        {title ?? "Searches"} <div className={styles.count}>{data.length}</div>
      </div>
    );
  }, [data.length, title]);

  // const csv = useMemo(() => {
  //   const csv = [["blockTitle", "itemId", "itemTitle", "itemCategory", "itemDate"]];

  //   return csv;
  // }, []);

  const extra = useMemo(() => {
    if (!data?.length > 0) return null;

    const csv = [
      [
        "query",
        "count",
        "userCount",
        "percentage",
        "progressivePercentage",
        "avgResults",
        "synonymsCount",
        "synonyms",
        "ids",
      ],
    ];

    data.forEach(
      ({
        query,
        count,
        userCount,
        percentage,
        progressivePercentage,
        avgResults,
        synonymsCount,
        synonyms,
        catalogs,
      }) => {
        const data = [
          query,
          count,
          userCount,
          percentage,
          progressivePercentage,
          avgResults,
          synonymsCount,
          synonyms ? synonyms?.join(", ") : "",
        ];

        if (catalogs) {
          const ids = [];
          catalogs.forEach(({ content }) => {
            content.forEach(({ id, count, userCount }) => {
              ids.push(`${id} [C:${count} | U:${userCount}]`);
            });
          });

          data.push(ids.join(", "));
        } else data.push(undefined);

        csv.push(data);
      }
    );

    return (
      <CSVLink
        data={csv}
        filename={`analitica-searchs-${Date.now()}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <Button size="sm" className={styles.csvBtn} variant="outline-warning">
          Download CSV
        </Button>
      </CSVLink>
    );
  }, [data]);

  if (!data?.length) return null;

  return (
    <Container title={titolo} className={styles.searchesBox} folded={true} extra={extra}>
      <div className={styles.data}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Virtuoso
                style={{ height, width }}
                totalCount={data?.length || 0}
                itemContent={DrawRow}
                className={styles.searchesVirtuoso}
              />
            );
          }}
        </AutoSizer>
      </div>
    </Container>
  );
}
