import {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
  useReducer,
} from "react";
import { useFormikContext } from "formik";
import Switch from "react-bootstrap-switch";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faTrashAlt,
  faPowerOff,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useDataRefObj } from "../../hooks/useDataRef";
import md5 from "md5";

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import get from "lodash.get";
import has from "lodash.has";
import isEqual from "lodash.isequal";
import { applyPatch } from "fast-json-patch";

import ErrorMessage from "../errorMessage";
import SelectMeta from "../selects/selectMeta";
import ValuesSelect from "../selects/selectValues";
import RotaryKnob from "../rotaryKnob";
import Container from "../container";
import AndOrSelector from "../query/andOrSelector";
import { useDataSettings } from "../../hooks/useData";
import usePermissions from "../../hooks/usePermissions";
import useMetadataValues from "../../hooks/useMetadataValues";
import idGen from "../../utils/idGen";
import { db } from "../../db";

import styles from "./styles.module.scss";
import "./style.scss";

const styleInlineMarginRight = { display: "inline-block", marginRight: "4px" };
const styleInline = { display: "inline-block" };
const styleMarginLeft = { marginLeft: "4px" };
const styleSelect = { width: "466px" };

const defaults = {
  output: "content_pack_ids",
  tieBreaker: 0.9,
  numElements: {
    value: 150,
    min: 10,
    max: 1500,
  },
  score: {
    type: "percentage",
    value: 100,
    min: 10,
    max: 100,
  },
  fingerprint: {
    boost: {
      min: 1,
      max: 500,
      value: 10,
    },
    minTerms: {
      min: 1,
      max: 50,
      value: 1,
    },
    maxTerms: {
      min: 25,
      max: 250,
      value: 200,
    },
    minDocs: {
      min: 5,
      max: 50,
      value: 5,
    },
    maxDocs: {
      min: 500,
      max: 2147483647,
      value: 2147483647,
    },
  },
  matchFields: ["fingerprint"],
};

function intToString(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

function isUsingSimilarity(value) {
  if (value.fingerprint?.matchFields?.length) {
    return true;
  }

  for (const key of ["included", "excluded"]) {
    const accessor = value.metadata?.[key];
    if (accessor) {
      for (const meta of accessor) {
        const split = meta.split("=");
        if (split.length === 1) return true;
      }
    }
  }

  if (value.date?.range > 0 && !value.date?.absolute) return true;

  return false;
}

export default function ContentQuery(props) {
  const { onChange, metadata, index } = props;

  const { values, errors } = useFormikContext();
  const info = useDataRefObj("contentPacks", values);
  const [foldedHeader, setFoldedHeader] = useState(new Map());
  const permissions = usePermissions();
  const queryExpert = useMemo(
    () => permissions.expert.includes("query-settings"),
    [permissions]
  );
  const [contentIncludeMeta, setContentIncludeMeta] = useState("");
  const [contentExcludeMeta, setContentExcludeMeta] = useState("");
  const uniqueId = useRef(idGen());

  const data = useMemo(() => {
    if (index > -1) {
      const { id, override } = metadata.contentPacks.packs[index];
      const origin = db.get("contentPacks", id);

      if (override) {
        return applyPatch(cloneDeep(origin), JSON.parse(override)).newDocument;
      }
      return origin;
    }

    return values;
  }, [index, metadata?.contentPacks?.packs, values]);

  const [realms, catalogs] = useMemo(() => {
    return [["content"], [data.contentType]];
  }, [data.contentType]);

  const excludeExcluded = useMemo(
    () => data.metadata?.excluded?.map((e) => e.split("=")[0]) || [],
    [data.metadata?.excluded]
  );

  const includeExcluded = useMemo(
    () => data.metadata?.included?.map((e) => e.split("=")[0]) || [],
    [data.metadata?.included]
  );

  const onFold = useCallback(
    (statement, status) => {
      const newFolded = new Map(foldedHeader);
      newFolded.set(statement, status);
      setFoldedHeader(newFolded);
    },
    [foldedHeader]
  );

  const updateValue = useCallback(
    (key, value) => {
      const valori = cloneDeep(data);
      set(valori, key, value);
      valori.useSimilarity = isUsingSimilarity(valori);
      onChange(valori, index);
    },
    [data, index, onChange]
  );

  const removeContentIncExcMetadataIndex = (index, incExc) => {
    const key = `metadata.${incExc}`;
    const newValues = [...(get(data, key) || [])];
    newValues.splice(index, 1);

    updateValue(key, newValues);
  };

  const updateContentIncExcMetadataIndex = (index, value, incExc) => {
    const key = `metadata.${incExc}`;
    const newValues = [...(get(data, key) || [])];
    newValues[index] = value;

    updateValue(key, newValues);
  };

  const addContentIncExcMetadata = useCallback(
    (incExc) => {
      const key = `metadata.${incExc}`;
      const values = cloneDeep(get(data, key) || []);

      if (incExc === "included") {
        contentIncludeMeta.length && values.push(contentIncludeMeta);
        setContentIncludeMeta("");
      }
      if (incExc === "excluded") {
        contentExcludeMeta.length && values.push(contentExcludeMeta);
        setContentExcludeMeta("");
      }
      updateValue(key, values);
    },
    [contentExcludeMeta, contentIncludeMeta, data, updateValue]
  );

  const updateGroup = useCallback(
    (type, value) => {
      const groupby = {
        metadata: data.groupby?.metadata ?? "",
        results: data.groupby?.results ?? 1,
        sortMetadata: data.groupby?.sortMetadata ?? "",
        sortDirection: data.groupby?.sortDirection ?? "asc",
      };

      switch (type) {
        case "reset": {
          groupby.metadata = "";
          groupby.sortMetadata = "";
          groupby.sortDirection = "";
          groupby.results = 0;

          break;
        }
        case "metadata": {
          groupby.metadata = value;
          break;
        }
        case "sort": {
          const [meta, dir] = value;
          groupby.sortMetadata = meta;
          groupby.sortDirection = dir;
          break;
        }

        case "results": {
          value = Math.trunc(value);
          groupby.results = value;
          break;
        }
        default: {
        }
      }

      updateValue(`groupby`, groupby);
    },
    [
      data.groupby?.metadata,
      data.groupby?.results,
      data.groupby?.sortDirection,
      data.groupby?.sortMetadata,
      updateValue,
    ]
  );

  const resetTimeRange = useCallback(
    () => updateValue(`date`, undefined),
    [updateValue]
  );

  const accessorIsEmpty = useCallback(
    (accessor) => {
      if (accessor === "exclude") return !data.metadata?.excluded?.length > 0;
      if (accessor === "filter") return !data.metadata?.included?.length > 0;
    },
    [data.metadata?.excluded?.length, data.metadata?.included?.length]
  );

  const filterHeader = useMemo(() => {
    if (accessorIsEmpty("filter") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) => updateValue(`filterOperator`, value)}
        onChangeBreaker={(value) => updateValue(`filterBreaker`, value)}
        operator={data.filterOperator}
        breaker={data.filterBreaker}
        includeFolded={foldedHeader.get("filter")}
        defaultBreaker={defaults.tieBreaker}
      />
    );
  }, [
    accessorIsEmpty,
    data.filterBreaker,
    data.filterOperator,
    foldedHeader,
    queryExpert,
    updateValue,
  ]);

  const excludeHeader = useMemo(() => {
    if (accessorIsEmpty("exclude") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) => updateValue(`mustNotOperator`, value)}
        onChangeBreaker={(value) => updateValue(`mustNotBreaker`, value)}
        operator={data.mustNotOperator}
        breaker={data.mustNotBreaker}
        includeFolded={foldedHeader.get("exclude")}
        defaultBreaker={0.9}
      />
    );
  }, [
    accessorIsEmpty,
    data.mustNotBreaker,
    data.mustNotOperator,
    foldedHeader,
    queryExpert,
    updateValue,
  ]);

  const outputHeader = useMemo(() => {
    return (
      <div className={styles.outputHeader}>
        <div className={styles.label}>Score</div>
        <Form.Control
          as="select"
          value={data.scoreType ?? defaults.score.type}
          onChange={(e) => updateValue("scoreType", e.target.value)}
          className={styles.selectScore}
        >
          <option value="percentage">Percentage</option>
          <option value="positional">Positional</option>
          <option value="origin">Origin</option>
        </Form.Control>
        {(!data.scoreType || data.scoreType === "percentage") && (
          <RotaryKnob
            value={data.maxScore ?? defaults.score.value}
            min={defaults.score.min}
            max={defaults.score.max}
            step={1}
            label="Score Max"
            labelPosition="left"
            skin="s10"
            onChange={(v) => updateValue("maxScore", v)}
            className={styles.scoreMax}
          />
        )}
        <RotaryKnob
          value={data.numResults ?? defaults.numElements.value}
          min={defaults.numElements.min}
          max={defaults.numElements.max}
          step={1}
          label="Results"
          labelPosition="left"
          skin="s10"
          onChange={(v) => updateValue("numResults", v)}
          className={styles.results}
        />
      </div>
    );
  }, [data.maxScore, data.numResults, data.scoreType, updateValue]);

  return (
    <div className={styles.contentPackBox}>
      <Container
        title="Output"
        help="content-pack-output"
        coloredBars={false}
        foldable={false}
        folded={true}
        header={outputHeader}
        headerOnlyOnFolded={false}
      />

      <Container
        title="Keep Only"
        help="meta-similar-keep-only"
        foldable={true}
        folded={false}
        onFold={(status) => onFold("filter", status)}
        color="#015301"
        coloredBars={false}
        coloredTitle={false}
        warning={errors.keepOnly}
        header={filterHeader}
        headerOnlyOnFolded={false}
        extra={
          <FontAwesomeIcon
            icon={faPowerOff}
            onClick={resetTimeRange}
            className="power-off-icon"
          />
        }
      >
        <div id="content-similar-include-metadata">
          <div className={styles.row} key="content-similar-include-metadata">
            <div className={styles.column} style={{ width: "70px" }}>
              Metadata
            </div>
            <div className={styles.column} style={{ width: "466px" }}>
              <SelectMeta
                name="contentIncludeMetadata"
                value={contentIncludeMeta}
                handleChange={setContentIncludeMeta}
                catalogs={catalogs}
                realms={realms}
                excludes={includeExcluded}
              />
            </div>
            <div className={styles.column} style={{ width: "70px" }}>
              <Button
                onClick={() => addContentIncExcMetadata("included")}
                variant="outline-primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faPlusSquare} className="add-icon" /> Add
              </Button>
            </div>
          </div>

          {has(data, "metadata.included") && (
            <div className={styles.metadataBox}>
              {data.metadata.included.map((m, index) => {
                const length = data.metadata.included.length;
                const addOperator = length > 1 && index !== length - 1;

                return (
                  <>
                    <Meta
                      field={m}
                      index={index}
                      contentType={data.contentType}
                      type="included"
                      variant="outline-success"
                      operator={data.filterOperator ?? "and"}
                      remove={removeContentIncExcMetadataIndex}
                      update={updateContentIncExcMetadataIndex}
                      excluded={data.metadata.included || []}
                      excludeFallbackMetadata={info?.restricted}
                      key={`${index}-${m}`}
                    />
                    {addOperator && (
                      <div className="formula-operator">
                        {data.filterOperator ?? "and"}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>

        <div className="content-similar-date-range">
          <div className={styles.row}>
            <div className={styles.column} style={{ width: "70px" }}>
              Range
            </div>
            <div className={styles.column} style={{ width: "800px" }}>
              <div className="period-box">
                <RotaryKnob
                  value={Number(get(data, "date.range") || 0)}
                  min={0}
                  max={1000}
                  step={1}
                  label="Period"
                  labelPosition="top"
                  skin="s10"
                  onChange={(v) => updateValue("date.range", v)}
                  className={styles.periodInput}
                />

                <Form.Control
                  as="select"
                  name={`date.period`}
                  value={get(data, "date.period") || ""}
                  onChange={(e) => updateValue("date.period", e.target.value)}
                  style={styleSelect}
                >
                  <option></option>
                  <option value="s">Seconds</option>
                  <option value="m">Minute</option>
                  <option value="h">Hour</option>
                  <option value="d">Day</option>
                  <option value="w">Week</option>
                  <option value="M">Month</option>
                  <option value="y">Year</option>
                </Form.Control>

                <SelectMeta
                  isErrored={has(errors, `date.field`)}
                  name={`date.field`}
                  value={get(data, "date.field") || ""}
                  handleChange={(v) => updateValue("date.field", v)}
                  catalogs={catalogs}
                  realms={realms}
                  types="date"
                  style={styleSelect}
                />

                <Form.Check
                  type="switch"
                  id={`date_absolute-${uniqueId.current}`}
                  checked={get(data, `date.absolute`) || false}
                  value="true"
                  onChange={(e) =>
                    updateValue("date.absolute", e.target.checked)
                  }
                  label="Absolute"
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column} style={{ width: "800px" }}>
              <div className="error-box">
                <ErrorMessage name={`date.period`} />
                <ErrorMessage name={`date.field`} />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container
        title="Exclude"
        help="meta-similar-exclude"
        foldable={true}
        folded={false}
        onFold={(status) => onFold("exclude", status)}
        color="#5a0000"
        coloredBars={false}
        coloredTitle={false}
        warning={errors?.excluded}
        header={excludeHeader}
        headerOnlyOnFolded={false}
      >
        <div className={styles.row} key="content-similar-exclude-metadata">
          <div className={styles.column} style={{ width: "70px" }}>
            Metadata
          </div>
          <div className={styles.column} style={{ width: "466px" }}>
            <SelectMeta
              name="contentIncludeMetadata"
              value={contentExcludeMeta}
              handleChange={setContentExcludeMeta}
              catalogs={catalogs}
              realms={realms}
              excludes={excludeExcluded}
            />
          </div>
          <div className={styles.column} style={{ width: "70px" }}>
            <Button
              onClick={() => addContentIncExcMetadata("excluded")}
              variant="outline-primary"
              size="sm"
            >
              <FontAwesomeIcon icon={faPlusSquare} className="add-icon" /> Add
            </Button>
          </div>
        </div>

        {has(data, "metadata.excluded") && (
          <div className={styles.metadataBox}>
            {data.metadata.excluded.map((m, index) => {
              const length = data.metadata.excluded.length;
              const addOperator = length > 1 && index !== length - 1;

              return (
                <>
                  <Meta
                    field={m}
                    index={index}
                    contentType={data.contentType}
                    type="excluded"
                    variant="outline-danger"
                    remove={removeContentIncExcMetadataIndex}
                    update={updateContentIncExcMetadataIndex}
                    excluded={data.metadata.excluded || []}
                    excludeFallbackMetadata={info.restricted}
                    key={m}
                  />
                  {addOperator && (
                    <div className="formula-operator">
                      {data.mustNotOperator ?? "and"}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        )}
      </Container>

      {!info.restricted && (
        <Container
          title="Similarity"
          help="meta-similar-similarity"
          foldable={true}
          folded={false}
          color="#CCC"
          coloredBars={false}
          coloredTitle={true}
        >
          <Fingerprint
            data={get(data, "fingerprint") || {}}
            update={(value) => updateValue(`fingerprint`, value)}
            contentType={data.contentType}
          />
        </Container>
      )}

      <Container
        title="Group & Order"
        help="meta-similar-group-order"
        foldable={true}
        folded={false}
        color="#915e4d"
        coloredBars={false}
        coloredTitle={true}
        hoverFactor={0.2}
        extra={
          <FontAwesomeIcon
            icon={faPowerOff}
            onClick={() => updateGroup("reset")}
            className="power-off-icon"
          />
        }
      >
        <div className={styles.row}>
          <div className={styles.column} style={{ width: "70px" }}>
            GroupBy
          </div>
          <div className={styles.column} style={{ width: "70%" }}>
            <GroupByBox
              value={data.groupby?.metadata ?? ""}
              contentType={data.contentType}
              onChange={(e) => updateGroup("metadata", e)}
            />
          </div>
          <div
            className={`${styles.column} ${styles.resultsPerGroup}`}
            style={{ width: "30%" }}
          >
            <RotaryKnob
              value={data.groupby?.results ?? 1}
              min={0}
              max={data.resultsNum}
              step={1}
              label="Results Per Group"
              labelPosition="right"
              skin="s10"
              onChange={(v) => updateGroup("results", v)}
              className="knob-group-results"
            />
          </div>
        </div>
        {data.groupby?.metadata && (
          <div className={styles.row}>
            <div className={styles.column} style={{ width: "70px" }}>
              OrderBy
            </div>
            <div className={styles.column} style={{ width: "100%" }}>
              <OrderByBox
                sortValue={data.groupby?.sortMetadata ?? ""}
                directionValue={data.groupby?.sortDirection ?? "asc"}
                contentType={data.contentType}
                update={(meta, dir) => {
                  updateGroup("sort", [meta, dir]);
                }}
              />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

function OrderByBox(props) {
  const { sortValue, directionValue, contentType, update } = props;
  const [metas, setMetas] = useState(sortValue?.split(",") ?? []);
  const [directions, setDirections] = useState(
    directionValue?.split(",") ?? []
  );
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    setMetas(sortValue?.split(","));
    setDirections(directionValue?.split(","));
  }, [directionValue, sortValue]);

  const metasLength = useMemo(() => metas?.length ?? 0, [metas]);

  const handleChange = useCallback(
    (what, value, index) => {
      const newMetas = [...metas];
      const newDirections = [...directions];

      if (what === "meta") {
        newMetas[index] = value;
        setMetas(newMetas);

        if (!newDirections[index]) {
          newDirections[index] = "asc";
          setDirections(newDirections);
        }
      } else {
        newDirections[index] = value ? "asc" : "desc";
        setDirections(newDirections);
      }
      update(newMetas.join(","), newDirections.join(","));
    },
    [directions, metas, update]
  );

  const handleAdd = useCallback(() => {
    const newMetas = [...metas];
    newMetas.push("");
    setMetas(newMetas);
  }, [metas]);

  const handleDelete = useCallback(
    (index) => {
      const newMetas = [...metas];
      const newDirections = [...directions];

      newMetas.splice(index, 1);
      newDirections.splice(index, 1);

      setMetas(newMetas);
      setDirections(newDirections);

      update(newMetas.join(","), newDirections.join(","));
    },
    [directions, metas, update]
  );

  return (
    <div className={styles.orderByBox}>
      {(metas ?? []).map((meta, i) => (
        <div className="order-by-select-row" key={meta}>
          <div className="order-by-select">
            <SelectMeta
              name="contentIncludeMetadata"
              value={meta}
              handleChange={(e) => handleChange("meta", e, i)}
              catalogs={catalogs}
              realms={realms}
              excludes={metas}
            />
          </div>
          {meta && (
            <div className="order-by-switch">
              <Switch
                bsSize="small"
                value={directions[i] === "asc" ? true : false}
                onText="asc"
                onColor="warning"
                offText="desc"
                offColor="danger"
                onChange={(elm, state) => handleChange("dir", state, i)}
              />
            </div>
          )}

          {i !== 0 && (
            <Button
              variant="outline-danger"
              onClick={() => handleDelete(i)}
              style={styleInlineMarginRight}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {i + 1 === metasLength && meta && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleAdd}
              style={styleInline}
            >
              <FontAwesomeIcon icon={faPlusSquare} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

function GroupByBox(props) {
  const { value, contentType, onChange } = props;
  const [groups, setGroups] = useState(new Set(value?.split(",") ?? []));
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    setGroups(value?.split(","));
  }, [value]);

  const handleChange = useCallback(
    (value, index) => {
      setImmediate(() => {
        const newGroups = [...groups];
        newGroups[index] = value;
        setGroups(new Set(newGroups));

        if (onChange) onChange(newGroups.join(","));
      });
    },
    [groups, onChange]
  );
  const handleAdd = useCallback(() => {
    const newGroups = [...groups];
    newGroups.push("");
    setGroups(new Set(newGroups));
  }, [groups]);

  const handleDelete = useCallback(
    (index) => {
      const newGroups = [...groups];
      newGroups.splice(index, 1);
      setGroups(new Set(newGroups));
      if (onChange) onChange(newGroups.join(","));
    },
    [groups, onChange]
  );

  const groupsArray = useMemo(() => [...(groups ?? [])], [groups]);
  const groupsLength = useMemo(() => groupsArray?.length ?? 0, [groupsArray]);

  return (
    <div className={styles.groupByBox}>
      {groupsArray.map((value, i) => (
        <div className="group-by-select-row" key={value}>
          <div className="group-by-select">
            <SelectMeta
              name="groupbyselect"
              value={value}
              handleChange={(v) => handleChange(v, i)}
              catalogs={catalogs}
              realms={realms}
              excludes={groupsArray}
            />
          </div>
          {i !== 0 && (
            <Button
              variant="outline-danger"
              onClick={() => handleDelete(i)}
              style={styleInlineMarginRight}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {i + 1 === groupsLength && value && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleAdd}
              style={styleInline}
            >
              <FontAwesomeIcon icon={faPlusSquare} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

function Meta(props) {
  const {
    field,
    index,
    contentType,
    type,
    variant,
    remove,
    update,
    defaultLabel = "origin",
    excludeFallbackMetadata,
  } = props;
  const [extra, setExtra] = useState(false);
  const [key, value] = field.split("=");
  const [extraValue, setExtraValue] = useState(
    value
      ? value.split(" OR ").map((k) => k.replace("(", "").replace(")", ""))
      : [""]
  );

  const changeValue = useCallback(
    (index, value) => {
      const newValues = [...extraValue];
      newValues[index] = value;
      setExtraValue(newValues);
    },
    [extraValue]
  );

  const confirmValue = useCallback(() => {
    setExtra(false);
    if (extraValue && extraValue.filter(Boolean).length > 0) {
      let value;
      if (extraValue.length === 1) value = `${key}=${extraValue}`;
      else
        value = `${key}=${extraValue
          .filter((_) => _)
          .map((k) => `(${k})`)
          .join(" OR ")}`;

      update(index, value, type);
    } else {
      update(index, key, type);
    }
  }, [extraValue, index, key, type, update]);

  const resetValue = useCallback(() => {
    setExtra(false);
    update(index, key, type);
  }, [index, key, type, update]);

  const addValue = useCallback(() => {
    const newValues = [...extraValue];
    newValues.push("");
    setExtraValue(newValues);
  }, [extraValue]);

  const deleteValue = useCallback(
    (index) => {
      const newValues = [...extraValue];
      newValues.splice(index, 1);
      setExtraValue(newValues);
    },
    [extraValue]
  );

  return (
    <>
      {extra ? (
        <div className="from-extra">
          <div className="meta-value-label">{key}</div>
          {extraValue.map((value, index) => (
            <div className="meta-value-row" key={`${key}-${index}`}>
              <MetaValue
                contentType={contentType}
                meta={key}
                value={value}
                index={index}
                changeValue={changeValue}
                excludes={extraValue}
              />

              {index === extraValue.length - 1 && (
                <Button size="sm" variant="outline-primary">
                  <FontAwesomeIcon icon={faPlusSquare} onClick={addValue} />
                </Button>
              )}

              {index > 0 && (
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => deleteValue(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              )}
            </div>
          ))}

          <div className="confirmExtra">
            <Button size="sm" variant="outline-success" onClick={confirmValue}>
              OK
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={resetValue}
              style={styleMarginLeft}
            >
              Reset
            </Button>
          </div>
        </div>
      ) : (
        <span className="singleMeta">
          <Button size="sm" variant={variant} onClick={() => setExtra(true)}>
            {key}
            <SimpleValue
              value={value}
              defaultLabel={defaultLabel}
              excludeFallbackMetadata={excludeFallbackMetadata}
            />
            &nbsp;
            <span
              aria-hidden="true"
              onClick={() => remove(index, type)}
              className="close-x"
            >
              &times;
            </span>
          </Button>
        </span>
      )}
    </>
  );
}

function SimpleValue(props) {
  if (props.excludeFallbackMetadata && !props.value)
    return (
      <span className={styles.noOriginIcon}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
    );

  const value = props.value || props.defaultLabel;
  const splitted = value
    .split(" OR ")
    .map((k) => k.replace("(", "").replace(")", ""));

  return (
    <span className="meta-from">
      {splitted.map((v) => (
        <span className="singleValue" key={v}>
          {v}
        </span>
      ))}
    </span>
  );
}
function MetaValue(props) {
  const { contentType, meta, value, index, changeValue, excludes } = props;
  const fieldValues = useMetadataValues(contentType, meta);

  return (
    <>
      {fieldValues && fieldValues.length > 0 ? (
        <ValuesSelect
          debugLabel="from-extra"
          selectOptions={fieldValues}
          errored={false}
          name={meta}
          handleChange={(value) => changeValue(index, value)}
          value={value || ""}
          excludes={excludes}
          style={styleSelect}
        />
      ) : (
        <Form.Control
          type="text"
          onChange={(e) => changeValue(index, e.target.value)}
          value={value || ""}
        />
      )}
    </>
  );
}

function hashFingerprint(arr) {
  let ret = [];
  arr.forEach((values) => {
    const [key, value] = values.split("=");
    if (value) ret.push(md5(`${key}${value}`));
  });
  return ret.join(" ");
}

const emptyArray = [];
function FingerprintFields(props) {
  const { data, contentType, defaults, dispatch } = props;
  const [fields, setFields] = useState(data || emptyArray); //useState(data || defaults);
  const [value, setValue] = useState();
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    const d = data || emptyArray;
    if (!isEqual(d, fields)) {
      setFields(d);
    }
  }, [data, fields]);

  const remove = useCallback(
    (index) => {
      let newFields = [...fields];
      newFields.splice(index, 1);
      //if (newFields.length === 0) newFields = defaults;

      setFields(newFields);
      dispatch({ type: "matchFields", value: newFields });
    },
    [dispatch, fields]
  );

  const add = useCallback(() => {
    if (value) {
      const newFields = [...fields];
      newFields.push(value);
      setFields(newFields);
      setValue(null);
      dispatch({ type: "matchFields", value: newFields });
    }
  }, [dispatch, fields, value]);

  return (
    <>
      <div className={styles.row} key="content-similar-finger-metadata">
        <div className={styles.column} style={{ width: "70px" }}>
          Metadata
        </div>
        <div className={styles.column} style={{ width: "466px" }}>
          <SelectMeta
            name="FingerprintFields"
            value={value}
            handleChange={setValue}
            contentType={contentType}
            catalogs={catalogs}
            realms={realms}
            excludes={fields}
            types="string"
          />
        </div>
        <div className={styles.column} style={{ width: "70px" }}>
          <Button size="sm" variant="outline-primary" onClick={add}>
            <FontAwesomeIcon icon={faPlusSquare} /> Add
          </Button>
        </div>
      </div>
      <div className={`${styles.metadataBox}  ${styles.fingerMeta}`}>
        {(fields || defaults).map((field, index) => (
          <div className="singleMeta" key={`${index}-${field}`}>
            <Button size="sm" variant="outline-success">
              {field}{" "}
              <span
                aria-hidden="true"
                onClick={() => remove(index)}
                className="close-x"
              >
                &times;
              </span>
            </Button>
          </div>
        ))}
        <div className="spacer" />
      </div>
    </>
  );
}

function Fingerprint(props) {
  const [currentUnlike, setCurrentUnlike] = useState("");
  const [unlikes, setUnlikes] = useState(props.data?.unlikesClean || []);
  const kDef = useDataSettings(
    (settings) =>
      settings.contentSimilar?.[props.contentType]?.fingerprint || {}
  );
  const defaultMatchFields = useDataSettings(
    (settings) =>
      settings.contentSimilar?.[props.contentType]?.matchFields || []
  );

  const [data, dispatch] = useReducer((state, action) => {
    const newState = { ...state };

    if (action.type === "clean") {
      setCurrentUnlike("");
      setUnlikes(action.value.unlikesClean || []);
      return action.value;
    }

    if (action.type === "unlike") {
      if (action.index !== undefined) {
        if (action.value !== undefined) unlikes[action.index] = action.value;
        else unlikes.splice(action.index, 1);
      } else if (currentUnlike) {
        unlikes.push(currentUnlike);
        setCurrentUnlike("");
      }
      setUnlikes(unlikes);
      newState["unlikesClean"] = unlikes;
      const hashed = hashFingerprint(unlikes);
      if (hashed) newState["unlikes"] = hashed;
    } else {
      newState[action.type] = action.value;
    }

    if (props.update) props.update(newState);
    return newState;
  }, props.data || {});

  // useEffect(() => {
  //   if (props.data && !isEqual(props.data, data)) {
  //     dispatch({ type: "clean", value: props.data });
  //   }
  // }, [data, props.data]);

  const canUnlike = useMemo(() => {
    if (data.matchFields) {
      return data.matchFields.some((k) => defaultMatchFields.includes(k));
    }

    return true;
  }, [data.matchFields, defaultMatchFields]);

  const catalogs = useMemo(() => [props.contentType], [props.contentType]);
  const realms = useMemo(() => ["content"], []);
  const excludes = useMemo(
    () => unlikes.map((u) => u.split("=")[0]),
    [unlikes]
  );

  return (
    <div className="fingerprint-container">
      <FingerprintFields
        data={data.matchFields}
        contentType={props.contentType}
        defaults={defaultMatchFields}
        dispatch={dispatch}
      />

      {data.matchFields?.length > 0 ? (
        <div className={styles.metadataBox}>
          <div className="fingerprint-kobs">
            <RotaryKnob
              value={data.boost || kDef.boost?.value}
              min={kDef.boost.min}
              max={kDef.boost.max}
              step={1}
              label="Boost"
              skin="s11"
              onChange={(value) => dispatch({ type: "boost", value })}
            />
            <RotaryKnob
              value={data.minTerms || kDef.minTerms?.value}
              min={kDef.minTerms?.min}
              max={kDef.minTerms?.max}
              step={1}
              label="Min Terms"
              skin="s12"
              onChange={(value) => dispatch({ type: "minTerms", value })}
            />
            <RotaryKnob
              value={data.maxTerms || kDef.maxTerms?.value}
              min={kDef.maxTerms?.min}
              max={kDef.maxTerms?.max}
              step={1}
              label="Max Terms"
              skin="s12"
              onChange={(value) => dispatch({ type: "maxTerms", value })}
            />
            <RotaryKnob
              value={data.minDocs || kDef.minDocs?.value}
              min={kDef.minDocs?.min}
              max={kDef.minDocs?.max}
              step={1}
              label="Min Docs"
              onChange={(value) => dispatch({ type: "minDocs", value })}
            />
            <RotaryKnob
              value={data.maxDocs || kDef.maxDocs?.value}
              min={kDef.maxDocs?.min}
              max={kDef.maxDocs?.max}
              className={styles.maxDocs}
              clickStep={100}
              step={1}
              divider={10000}
              label={`Max Docs ${
                data.maxDocs ? intToString(data.maxDocs) : ""
              }`}
              skin="s13"
              onChange={(value) => dispatch({ type: "maxDocs", value })}
            />
          </div>

          {canUnlike ? (
            <div className="fingerprint-unlike">
              <div className="title">Excludes Key-Value Pairs</div>
              <div className="selectContainer">
                <div className="selectMeta">
                  <SelectMeta
                    name="contentIncludeMetadata"
                    value={currentUnlike}
                    handleChange={(e) => setCurrentUnlike(e)}
                    catalogs={catalogs}
                    realms={realms}
                    style={styleSelect}
                    excludes={excludes}
                  />
                </div>
                <div className="addBtn">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => dispatch({ type: "unlike" })}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} /> Add
                  </Button>
                </div>
              </div>
              <div className="unlikes">
                {unlikes.map((unlike, index) => (
                  <Meta
                    field={unlike}
                    index={index}
                    contentType={props.contentType}
                    type="excluded"
                    variant="outline-danger"
                    remove={(index) => dispatch({ type: "unlike", index })}
                    update={(index, value) =>
                      dispatch({ type: "unlike", index, value })
                    }
                    key={unlike}
                    defaultLabel="TBD"
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
