import { useState, useCallback, useEffect } from "react";
import { useDataSettings } from "./useData";
import { refManager } from "../db";
import { useObserveDbTable } from "./useMemoryDB";
import { doBlockRefs } from "../components/block";
import { doEndpointRefs } from "../components/endpoint";
import { doRuleRefs } from "../components/businessRule";
import { doExperimentRefs } from "../components/experiments";
import { doQueryRefs } from "../components/query";
import { doPresetRefs } from "../components/rulesPreset";
import { doListRefs } from "../components/list";
import { doConversionPackRefs } from "../components/conversionPacks";
import { doConversionRefs } from "../components/conversions";
import { doExternalSourceRefs } from "../components/externalSource";
import { doParamsRefs } from "../components/params";
import { doMetaPackRefs } from "../components/metaPacks";

const emptyObj = {};

export default refManager;

export function useDoRef() {
  const { queryClauses, queryDefaults } =
    useDataSettings("queryClauses", "queryDefaults") ?? emptyObj;

  return useCallback(
    (accessor, obj) => {
      if (accessor === "blocks") doBlockRefs(obj);
      if (accessor === "endpoints") doEndpointRefs(obj);
      if (accessor === "rules") doRuleRefs(obj);
      if (accessor === "experiments") doExperimentRefs(obj);
      if (accessor === "rulesPresets") doPresetRefs(obj);
      if (accessor === "lists") doListRefs(obj);
      if (accessor === "conversionPacks") doConversionPackRefs(obj);
      if (accessor === "conversions") doConversionRefs(obj);
      if (accessor === "externalSources") doExternalSourceRefs(obj);
      if (accessor === "params") doParamsRefs(obj);
      if (accessor === "metaPacks") doMetaPackRefs(obj);
      if (accessor === "queries") doQueryRefs(obj, queryClauses, queryDefaults);

      return obj;
    },
    [queryClauses, queryDefaults]
  );
}

export function useDataRefObj(accessor, obj) {
  const doRefs = useDoRef();
  const [data, setData] = useState(emptyObj);

  useEffect(() => {
    if (obj && accessor) {
      doRefs(accessor, obj);

      const data = new Map([[accessor, [obj]]]);
      const ret = refManager.fillBelongs(data, null, null, false);
      const item = ret.get(accessor)[0];
      const refs = refManager.process(accessor, item);
      const info = refManager.getItem(accessor, refs);

      setData(info);
    }
  }, [accessor, doRefs, obj]);

  return data;
}

// REACTIVE HOOKS
function useReactToDBChange(accessor) {
  const [change, setChange] = useState(Date.now());
  useObserveDbTable(accessor, () => setChange(Date.now()));

  return change;
}

export function useDataItemKV(accessor) {
  const change = useReactToDBChange(accessor);

  const fetch = useCallback(
    async (id, cb) => {
      if (id && accessor) {
        const data = refManager.getAllKW(accessor, { id });
        if (cb) return cb({ id, label: data[0]?.value });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessor, change]
  );

  return fetch;
}

export function useDataItems(accessor) {
  const change = useReactToDBChange(accessor);

  const fetch = useCallback(
    (filters, cb) => {
      if (!cb) return [];
      const data = refManager.getAll(accessor, filters);
      cb(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessor, change]
  );

  return fetch;
}

export function useDataItemsKV(accessor) {
  const change = useReactToDBChange(accessor);

  const fetch = useCallback(
    async (filters, cb) => {
      if (!cb) return;

      const data = refManager.getAllKW(accessor, filters);
      return cb(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessor, change]
  );

  return fetch;
}

export function useDataItemsIds(accessor, filters, returnType = "array") {
  const [ids, setIds] = useState(returnType === "array" ? [] : new Set());
  const change = useReactToDBChange(accessor);

  useEffect(() => {
    const data = refManager.getAllIds(accessor, filters);
    setIds(returnType === "array" ? data : new Set(data));
  }, [accessor, filters, returnType, change]);

  return ids;
}

export function useDataRefItem(accessor, id) {
  const [data, setData] = useState(emptyObj);
  const change = useReactToDBChange(accessor);

  useEffect(() => {
    if (id && accessor) {
      const data = refManager.getAll(accessor, { id });
      setData(data[0]);
    }
  }, [accessor, id, change]);

  return data;
}
