import { memo, Fragment, useState, useMemo, useCallback, useReducer } from "react";
import { useFormikContext } from "formik";
import { useGetMemItem } from "../../hooks/useMemoryDB";

import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import useMetadataValues from "../../hooks/useMetadataValues";

import RotaryKnob from "../rotaryKnob";
import ValuesSelect from "../selects/selectValues";
import SelectAllMultiple from "../selects/selectAllMultiple";
import Container from "../container";
import get from "lodash.get";

import styles from "./styles.module.scss";

export default function Filters(props) {
  const { handleUpdate, block, blockIndex } = props;

  return (
    <>
      <Container
        title="Metadata"
        help="block-filters-repeated-metadata"
        foldable={false}
        coloredBars={false}
      >
        <RepeatedFields
          block={block}
          blockIndex={blockIndex}
          onChange={(values) => handleUpdate("repeatedMetadata", values)}
        />
      </Container>
      <Container title="Scripts" help="block-filters-scripts" foldable={false} coloredBars={false}>
        <FilterScripts
          block={block}
          blockIndex={blockIndex}
          onChange={(values) => handleUpdate("scripts", values)}
        />
      </Container>
    </>
  );
}

function FilterScripts(props) {
  const { onChange, block } = props;

  const filters = useMemo(() => {
    if (block.results) {
      return {
        exclude: block.results.scripts ?? [],
        objectTypes: ["block-filter"],
      };
    }
  }, [block.results]);

  return <SelectAllMultiple accessor="decorators" filters={filters} handleChange={onChange} />;
}

function RepeatedFields(props) {
  const { onChange, block, blockIndex } = props;
  const [selectedMeta, setSelectedMeta] = useState(null);
  const [fields, setFields] = useState(get(block, "results.filters.repeatedMetadata") || []);
  const rs = useGetMemItem("responseFields", block.results?.responseFieldsId);

  const metadata = useMemo(() => {
    if (rs) {
      const all = rs.fields;
      const alreadyIn = fields.map((f) => f.meta);

      if (!all) return [];
      return all.filter((a) => !alreadyIn.includes(a));
    }

    return [];
  }, [fields, rs]);

  const removeField = useCallback(
    (index) => {
      const newFields = [...fields];
      newFields.splice(index, 1);
      setFields(newFields);
      onChange(newFields);
    },
    [fields, onChange]
  );

  const updateFields = useCallback(
    (index, field) => {
      const newFields = [...fields];
      newFields[index] = field;

      setImmediate(() => {
        setFields(newFields);
        onChange(newFields);
      });
    },
    [fields, onChange]
  );

  const addMeta = useCallback(() => {
    if (!selectedMeta) return;

    const newFields = [...fields];
    newFields.push({ meta: selectedMeta, moreThan: "1%" });
    setFields(newFields);
    onChange(newFields);
    setSelectedMeta(null);
  }, [fields, onChange, selectedMeta]);

  return (
    <div className="repeated-metadata-box">
      <h6 className="title">Repeated More Than</h6>
      <div className={styles.repeatedBox}>
        <div className="selectMeta">
          <Form.Control
            as="select"
            //
            style={{ width: "300px" }}
            placeholder="Pick a Metadata"
            onChange={(e) => setSelectedMeta(e.target.value)}
          >
            <option></option>
            {metadata.map((field, index) => (
              <option key={`repeatedMetadata-${blockIndex}-${field}`}>{field}</option>
            ))}
          </Form.Control>
        </div>
        <span className="addBtn">
          <Button size="sm" variant="outline-primary" onClick={addMeta}>
            <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: "2px" }} /> Add Metadata
          </Button>
        </span>
      </div>

      <div className="response-fields-container">
        {fields.map((field, index) => (
          <Field
            key={`repeatedMetadata-field-${blockIndex}-${field.meta}`}
            index={index}
            blockIndex={blockIndex}
            field={field}
            fieldId={`repeatedMetadata-field-${blockIndex}-${field.meta}`}
            handleClick={() => removeField(index)}
            handleChange={updateFields}
          />
        ))}
      </div>
    </div>
  );
}

const marginLeft6 = { marginLeft: "6px" };
const marginLeft4 = { marginLeft: "4px" };

const Field = memo(function Field(props) {
  const { field, fieldId, handleClick, index, blockIndex, handleChange } = props;
  const { values } = useFormikContext();
  const [show, setShow] = useState(false);
  const [currentValue, setCurrentValue] = useState(field.value);
  const [{ value, perc }, dispatchField] = useReducer(
    (state, action) => {
      const { value, metaValue, perc } = state;
      let newState;

      if (action.type === "perc")
        newState = { value: !perc && value > 100 ? 100 : value, perc: !perc, metaValue };
      if (action.type === "value") newState = { value: action.value, perc, metaValue };
      if (action.type === "metaValue") newState = { metaValue: action.value, value, perc };

      handleChange(index, {
        meta: field.meta,
        value: newState.metaValue,
        moreThan: `${newState.value}${newState.perc ? "%" : ""}`,
      });
      return newState;
    },
    [1, false],
    () => {
      const mt = field.moreThan || "";
      const perc = mt.includes("%");
      const value = perc ? mt.substring(0, mt.length - 1) : mt;

      return { value, perc };
    }
  );

  const contentType = useMemo(
    () => values.blocks[blockIndex]?.results?.contentType,
    [blockIndex, values.blocks]
  );

  // const fieldValues = useSelector((state) =>
  //   get(state.data, `metadataValues.${contentType}[${field.meta}]`)
  // );

  const fieldValues = useMetadataValues(contentType, field.meta);

  const confirmMetaValue = useCallback(() => {
    dispatchField({ type: "metaValue", value: currentValue });
    setShow(false);
  }, [currentValue]);

  const resetMetaValue = useCallback(() => {
    dispatchField({ type: "metaValue", value: "" });
    setShow(false);
  }, []);

  return (
    <Fragment>
      <div key={fieldId} id={fieldId} variant="outline-danger" className="repeated-meta-button">
        <div className="repeated-meta-header">
          <RotaryKnob
            value={Number(value)}
            min={1}
            max={100}
            step={1}
            label=""
            labelPosition="left"
            skin="s10"
            onChange={(value) => dispatchField({ type: "value", value })}
            className="repeated-meta-knob"
          />
          <div className="repeated-meta-all-values">
            <Form.Check
              inline
              label="%"
              type="checkbox"
              id={`inline-${fieldId}`}
              checked={perc}
              onChange={() => dispatchField({ type: "perc" })}
              className="repeated-meta-perc"
            />
            <div className="repeated-meta-metabox">
              {field.meta}
              <div className="repeated-meta-equals"> = </div>
              <div className="repeated-meta-value" onClick={() => setShow((state) => !state)}>
                {field.value || "ANY"}
              </div>

              <div aria-hidden="true" onClick={handleClick} className="delete">
                &times;
              </div>
            </div>
          </div>
        </div>
        {show ? (
          <div className="repeated-meta-value-select">
            <div className="popup">
              {fieldValues && fieldValues.length > 0 ? (
                <ValuesSelect
                  debugLabel="from-extra"
                  selectOptions={fieldValues}
                  errored={false}
                  name={field.value}
                  handleChange={setCurrentValue}
                  value={currentValue || ""}
                  excludes={currentValue}
                />
              ) : (
                <Form.Control
                  type="text"
                  onChange={(e) => setCurrentValue(e.target.value)}
                  value={currentValue || ""}
                />
              )}
            </div>
            <div className="buttons">
              <Button
                variant="outline-success"
                style={marginLeft6}
                size="sm"
                className="repeated-meta-ok"
                onClick={confirmMetaValue}
              >
                OK
              </Button>
              <Button
                variant="outline-secondary"
                style={marginLeft4}
                size="sm"
                className="repeated-meta-ok"
                onClick={resetMetaValue}
              >
                Reset
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
});
