import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { initAudio, playSound } from "./utils/audio";
import Snowfall from "react-snowfall";
import store from "./redux/store";
import "./index.css";
import App from "./App";

console.disableRedBox = true;

const container = document.getElementById("root");
const root = createRoot(container);
initAudio();

function ChristmasTime() {
  const today = new Date();

  // Start Date -> 8 December
  const start = new Date();
  start.setMonth(11);
  start.setDate(8);

  // End Date -> 6 January
  const end = new Date();
  end.setFullYear(today.getFullYear() + 1);
  end.setMonth(0);
  end.setDate(6);

  const isChristmasTime = today >= start && today <= end;

  if (isChristmasTime) {
    setInterval(() => playSound("bells"), 1000 * 60 * 5);
    return (
      <>
        {/* <img alt="Santa" src={`${process.env.PUBLIC_URL}/santa.png`} className="santa" /> */}
        <Snowfall
          style={{
            zIndex: "-1",
            opacity: 0.5,
          }}
        />
      </>
    );
  }

  return null;
}

root.render(
  <Provider store={store}>
    <ChristmasTime />
    <Auth0Provider
      domain="4r3s.eu.auth0.com"
      clientId="8FoTujFcF4yzYr92fp43RlKv43rCQ4Sv"
      redirectUri={window.location.origin}
    >
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </Auth0Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//reportWebVitals(console.log);
