import { useMemo } from "react";
import {
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import moment from "moment";
import Container from "../../../../../components/container";

import colors from "../../../../../settings/chartColors";
import styles from "./styles.module.scss";

const now = moment();
const alignConfig = {
  points: ["bl", "br"], // align top left point of sourceNode with top right point of targetNode
  offset: [0, 150], // the offset sourceNode by 10px in x and 20px in y,
  targetOffset: ["200%", "0%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
};

// Flat conversions Data in single timeseries or two timeseries if compare is true
function flatData(data) {
  if (!data?.conversions?.length > 0) return data;

  const conversions = data.conversions;
  const doCompare = data.compare;
  const flat = [];
  const flatCompare = [];
  const conversionSet = new Set();
  const flatDates = [];
  const flatCompareDates = [];

  conversions.forEach(({ id, timeline }) => {
    conversionSet.add(id);
    timeline.forEach(({ name, nameCompare, base, compare }, index) => {
      if (!flat[index]) flat[index] = { name };
      flat[index][id] = base;
      flatDates.push(name);
      if (doCompare) {
        if (!flatCompare[index]) flatCompare[index] = { name: nameCompare };
        flatCompare[index][id] = compare;
        flatCompareDates.push(nameCompare);
      }
    });
  });

  const ranges = {
    base: { start: flatDates[0], end: flatDates[flatDates.length - 1] },
    compare: { start: flatCompareDates[0], end: flatCompareDates[flatCompareDates.length - 1] },
  };

  return {
    base: flat,
    compare: flatCompare,
    labels: Array.from(conversionSet),
    ranges,
    doCompare,
  };
}
export default function Display(props) {
  const data = useMemo(
    () => (props.mode === "conversions" ? flatData(props.data) : props.data),
    [props.data, props.mode]
  );

  const baseContainer = useMemo(() => {
    if (!data?.labels?.length > 0) return null;

    const display = (
      <ResponsiveContainer width={"100%"} height={250}>
        <AreaChart data={data.base} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            {colors.map((c, index) => {
              return (
                <linearGradient
                  id={`grad-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                  key={`${c.stop1}-${c.stop2}-${index}`}
                >
                  <stop offset="5%" stopColor={c.stop1} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={c.stop2} stopOpacity={0} />
                </linearGradient>
              );
            })}
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          {data.labels.map((v, index) => {
            const strokeIndex = (() => {
              const length = colors.length;
              return index >= length ? index % length : index;
            })();
            return (
              <Area
                type="monotone"
                dataKey={v}
                stroke={colors[strokeIndex].stroke}
                fillOpacity={1}
                fill={`url(#grad-${strokeIndex})`}
                name={v}
                key={v}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    );

    if (data.doCompare) {
      return (
        <Container
          title="Base"
          help="analitica-display-explorer"
          color="#212f45"
          coloredBars={false}
          foldable={false}
          headerOnlyOnFolded={false}
        >
          {display}
        </Container>
      );
    }
    return display;
  }, [data.base, data.doCompare, data.labels]);

  return (
    <>
      {baseContainer}
      {data.doCompare && (
        <Container
          title="Compare"
          help="analitica-display-explorer-compare"
          color="#212f45"
          coloredBars={false}
          foldable={false}
          headerOnlyOnFolded={false}
        >
          <ResponsiveContainer width={"100%"} height={250}>
            <AreaChart data={data.compare} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                {colors.map((c, index) => {
                  return (
                    <linearGradient
                      id={`grad-${index}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                      key={`${c.stop1}-${c.stop2}-${index}`}
                    >
                      <stop offset="5%" stopColor={c.stop1} stopOpacity={0.8} />
                      <stop offset="95%" stopColor={c.stop2} stopOpacity={0} />
                    </linearGradient>
                  );
                })}
              </defs>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              {data.labels.map((v, index) => {
                return (
                  <Area
                    type="monotone"
                    dataKey={v}
                    stroke={colors[index].stroke}
                    fillOpacity={1}
                    fill={`url(#grad-${index})`}
                    name={v}
                    key={v}
                  />
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        </Container>
      )}
    </>
  );
}
