import { useState, useEffect, useMemo, useCallback } from "react";
import { Dropdown, Button } from "react-bootstrap";
import cloneDeep from "lodash.clonedeep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import Select from "../selectWithSearch";
import LabelsFilter from "../filters/labels";
import { useDataItemKV, useDataItemsKV } from "../../../hooks/useDataRef";
import styles from "./styles.module.scss";

const none = (
  <Dropdown.Item key={`empty-seg`} value={""} style={{ height: "16px" }}>
    {" "}
  </Dropdown.Item>
);

const emptyObj = {};
const noResults = (
  <div className="select-no-results" key="select-no-results">
    No Results...
  </div>
);
const btnLabelDefault = "Add";
export default function SelectAll(props) {
  const {
    accessor,
    labelsFilter,
    handleChange,
    handleAdd,
    startWithEmptyOption,
    className,
    style,
    value,
    isInvalid,
    haveButton = false,
    buttonLabel = btnLabelDefault,
  } = props;

  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [title, setTitle] = useState();
  const [valore, setValore] = useState({});
  const [filters, setFilters] = useState(props.filters ?? emptyObj);
  const getItem = useDataItemKV(accessor);
  const getItems = useDataItemsKV(accessor);

  const filtri = useMemo(() => {
    if (labelsFilter) {
      if (Array.isArray(labelsFilter)) {
        const filters = [];
        labelsFilter.forEach((type) => filters.push(<LabelsFilter types={[type]} label={type} />));
        return filters;
      }

      return [<LabelsFilter types={[accessor]} />];
    }
  }, [accessor, labelsFilter]);

  const onFilter = useCallback((t) => setTitle(t), []);
  const onSubFilter = useCallback(({ labels }) => setLabels(labels), []);

  const translateAndSetValue = useCallback(
    (id) => getItem(id, (data) => setValore(data)),
    [getItem]
  );

  const updateFilters = useCallback(
    (e) => {
      const val = value ?? valore.id;

      if (val) {
        const filtri = cloneDeep(filters);
        const index = filtri.exclude.findIndex((k) => k === val);

        if (index > -1) filtri.exclude.splice(index, 1);
        filtri.exclude.push(e);
        setFilters(filtri);
      }
    },
    [filters, valore.id, value]
  );

  const onSelect = useCallback(
    (e) => {
      if (e) {
        translateAndSetValue(e);
        updateFilters(e);

        if (handleChange) handleChange(e);
      }
    },
    [handleChange, translateAndSetValue, updateFilters]
  );

  const onAdd = useCallback(() => {
    if (valore.id) {
      if (handleAdd) handleAdd(valore.id);
      updateFilters(valore.id);
      setValore({});
    }
  }, [handleAdd, updateFilters, valore.id]);

  useEffect(() => {
    if (labels.length > 0) filters.labels = labels;
    else delete filters.labels;

    if (title) filters.title = title;
    else delete filters.title;

    if (!filters.exclude) filters.exclude = [];

    getItems(filters, (data) => {
      const items = [];
      if (startWithEmptyOption) items.push(none);
      let index = 0;
      for (const { key, value } of data) {
        items.push(
          <Dropdown.Item eventKey={key} key={`${accessor}-${key}-${index++}`} value={key}>
            {value}
          </Dropdown.Item>
        );
      }

      if (data.length === 0) items.push(noResults);

      setData(items);
    });
  }, [accessor, filters, getItems, labels, startWithEmptyOption, title]);

  useEffect(() => {
    if (!value) setValore({});
    else translateAndSetValue(value);
  }, [translateAndSetValue, value]);

  useEffect(() => {
    setFilters(props.filters ?? emptyObj);
  }, [props.filters]);

  useEffect(() => {
    if (value) {
      updateFilters(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <Select
        className={`${styles.select} ${className}`}
        name={`select-block`}
        value={valore.label}
        disabled={false}
        canFilter={true}
        isInvalid={isInvalid}
        onFilter={onFilter}
        onSubFilter={onSubFilter}
        onSelect={onSelect}
        style={style}
        filters={filtri}
        info={filters}
      >
        {data}
      </Select>
      {haveButton && (
        <Button size="sm" variant="outline-primary" onClick={onAdd} className={styles.btn}>
          <FontAwesomeIcon icon={faPlusSquare} className={styles.icon} />{" "}
          <span className="addLabel">{buttonLabel}</span>
        </Button>
      )}
    </div>
  );
}
