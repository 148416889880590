import { faPowerOff, faPlusSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useGetMemItem } from "../../../hooks/useMemoryDB";
import capitalize from "lodash/capitalize";
import set from "lodash.set";
import cloneDeep from "lodash.clonedeep";
import Container from "../../container";
import AndOrSelector from "../../query/andOrSelector";
import styles from "./styles.module.scss";

const emptyData = {};
const colors = new Map([
  ["context", "#ff0000"],
  ["params", "#00ff00"],
]);

const operators = [
  { label: "Greater Than", value: "gt" },
  { label: "Greater Than or Equal to", value: "gte" },
  { label: "Less Than", value: "lt" },
  { label: "Less Than or Equal to", value: "lte" },
  { label: "Equal to", value: "eq" },
  { label: "Between", value: "bt" },
];

function OperatorsSelect({ value, onChange }) {
  return (
    <Form.Control
      as="select"
      className={styles.operatorsSelect}
      onChange={onChange}
      value={value}
      kye={Date.now()}
    >
      <option></option>
      {operators.map(({ label, value }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Form.Control>
  );
}

function ValueInput({ value, value2, onChange, onChange2, operator }) {
  return (
    <div className={styles.valuesBox}>
      <Form.Control
        id="value1"
        type="number"
        className={styles.positionInput}
        value={value ?? 0}
        onChange={onChange}
      />
      {operator === "bt" && (
        <Form.Control
          id="value2"
          type="number"
          className={styles.positionInput}
          value={value2 ?? 0}
          onChange={onChange2}
        />
      )}
    </div>
  );
}

export default function Filters(props) {
  const {
    data = emptyData,
    onChange,
    color,
    errors,
    hover = true,
    foldable = true,
    folded = false,
    coloredTitle = false,
    title = "Filters",
  } = props;

  const analitica = useGetMemItem("analitica", "settings");

  const header = useMemo(() => {
    return (
      <AndOrSelector
        operator={data.operator}
        onChangeOperator={(operator) => onChange({ ...data, operator })}
        showTie={false}
      />
    );
  }, [data, onChange]);

  return (
    <Container
      title={title}
      help="analitica-context-params-filters"
      color={color}
      coloredTitle={coloredTitle}
      coloredBars={color ? false : true}
      hover={hover}
      foldable={foldable}
      folded={folded}
      header={header}
      headerOnlyOnFolded={false}
      warning={errors ?? false}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.column}>
            <FilterBox accessor="context" analitica={analitica} {...props} />
          </div>
          <div className={styles.column}>
            <FilterBox accessor="params" analitica={analitica} {...props} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <PositionsBox {...props} />
          </div>
          <div className={styles.column}>
            <ValueBox {...props} />
          </div>
        </div>
      </div>
    </Container>
  );
}

function PositionsBox(props) {
  const { data = emptyData, onChange } = props;

  const onReset = useCallback(() => {
    if (onChange) onChange({ ...data, positions: undefined });
  }, [data, onChange]);

  const handleChange = (accessor, type, e) => {
    const value = e.target.value;
    if (!data.positions) data.positions = {};

    if (onChange) {
      onChange({
        ...data,
        positions: {
          ...data.positions,
          [accessor]: { ...(data.positions[accessor] ?? {}), [type]: value },
        },
      });
    }
  };

  return (
    <Container
      title="Positions"
      foldable={false}
      extra={<FontAwesomeIcon icon={faPowerOff} className={styles.restIcon} onClick={onReset} />}
    >
      <div className={styles.positionBox}>
        <div className={styles.row}>
          <div className={styles.label}>Block</div>
          <OperatorsSelect
            onChange={(e) => handleChange("block", "operator", e)}
            value={data.positions?.block?.operator ?? ""}
          />
          <ValueInput
            value={data.positions?.block?.value ?? 0}
            value2={data.positions?.block?.value2 ?? 0}
            operator={data.positions?.block?.operator}
            onChange={(e) => handleChange("block", "value", e)}
            onChange2={(e) => handleChange("block", "value2", e)}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Item</div>
          <OperatorsSelect
            onChange={(e) => handleChange("item", "operator", e)}
            value={data.positions?.item?.operator ?? ""}
          />
          <ValueInput
            value={data.positions?.item?.value ?? 0}
            value2={data.positions?.item?.value2 ?? 0}
            operator={data.positions?.item?.operator}
            onChange={(e) => handleChange("item", "value", e)}
            onChange2={(e) => handleChange("item", "value2", e)}
          />
        </div>
      </div>
    </Container>
  );
}

function ValueBox(props) {
  const { data = emptyData, onChange } = props;

  const onReset = useCallback(() => {
    if (onChange) onChange({ ...data, value: undefined });
  }, [data, onChange]);

  const handleChange = (type, e) => {
    const value = e.target.value;
    if (!data.value) data.value = {};

    if (onChange) {
      onChange({
        ...data,
        value: { ...data.value, [type]: value },
      });
    }
  };

  return (
    <Container
      title="Value"
      foldable={false}
      extra={<FontAwesomeIcon icon={faPowerOff} className={styles.restIcon} onClick={onReset} />}
    >
      <div className={styles.valueBox}>
        <div className={styles.row}>
          <div className={styles.label}>Event Value</div>
          <OperatorsSelect
            onChange={(e) => handleChange("operator", e)}
            value={data.value?.operator ?? ""}
          />
          <ValueInput
            value={data.value?.value ?? 0}
            value2={data.value?.value2 ?? 0}
            operator={data.value?.operator}
            onChange={(e) => handleChange("value", e)}
            onChange2={(e) => handleChange("value2", e)}
          />
        </div>
      </div>
    </Container>
  );
}

function FilterBox(props) {
  const { data = emptyData, onChange, accessor, analitica, errors } = props;

  const onReset = useCallback(() => {
    if (onChange) onChange({ ...data, [accessor]: undefined });
  }, [accessor, data, onChange]);

  const header = useMemo(() => {
    return (
      <AndOrSelector
        operator={data[accessor]?.operator}
        onChangeOperator={(operator) =>
          onChange({ ...data, [accessor]: { ...data[accessor], operator } })
        }
        showTie={false}
      />
    );
  }, [accessor, data, onChange]);

  const handleDeleteRule = useCallback(
    (index) => {
      data[accessor].rules.splice(index, 1);
      onChange(cloneDeep(data));
    },
    [accessor, data, onChange]
  );

  const handleChangeRule = useCallback(
    (index, rule) => {
      data[accessor].rules[index] = rule;
      onChange(cloneDeep(data));
    },
    [accessor, data, onChange]
  );

  return (
    <Container
      title={capitalize(accessor)}
      help="analitica-filters-context"
      foldable={false}
      coloredBars={false}
      hover={false}
      extra={<FontAwesomeIcon icon={faPowerOff} className={styles.restIcon} onClick={onReset} />}
      header={header}
      headerOnlyOnFolded={false}
      warning={errors?.[accessor] ?? false}
    >
      <div className={styles.keySelectBox}>
        <SelectKey analitica={analitica} accessor={accessor} data={data} onChange={onChange} />
      </div>
      <div className={styles.rulesBox}>
        {data[accessor]?.rules?.map((rule, index) => {
          return (
            <span key={`filterbox-rule-${index}`}>
              <Rule
                key={`filterbox-rule-${index}`}
                data={rule}
                index={index}
                values={
                  analitica?.request?.[accessor]?.values?.filter(({ key }) => key === rule.key)?.[0]
                    ?.values
                }
                errors={errors?.[accessor]}
                color={colors.get(accessor)}
                onDelete={handleDeleteRule}
                onChange={handleChangeRule}
              />
              {index < data[accessor].rules.length - 1 ? (
                <div className={styles.operator}>
                  {data[accessor]?.operator ? data[accessor]?.operator.toUpperCase() : "AND"}
                </div>
              ) : null}
            </span>
          );
        })}
      </div>
    </Container>
  );
}

function SelectKey(props) {
  const { data = emptyData, analitica, accessor, onChange } = props;
  const [key, setKey] = useState();

  const addValue = useCallback(() => {
    if (key) {
      const newRules = data[accessor]?.rules ?? [];
      newRules.push({
        operator: "OR",
        key,
        values: [],
      });

      set(data, `${accessor}.rules`, newRules);
      onChange(cloneDeep(data));

      setKey(undefined);
    }
  }, [accessor, data, key, onChange]);

  const existing = useMemo(() => {
    const existing = new Set();

    (data[accessor]?.rules || []).forEach(({ key }) => existing.add(key));

    return existing;
  }, [accessor, data]);

  const values = useMemo(() => {
    const values = analitica?.request?.[accessor]?.values ?? [];

    if (values.length > 0) {
      return values
        .filter(({ key }) => !existing.has(key))
        .map(({ label, key }) => ({ label, key }));
    }

    return values;
  }, [accessor, analitica?.request, existing]);

  return (
    <>
      <Form.Control
        as="select"
        onChange={(e) => setKey(e.target.value)}
        value={key || ""}
        className={styles.selectKey}
        //
      >
        <option></option>
        {values.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </Form.Control>
      <Button
        size="sm"
        onClick={addValue}
        variant="outline-primary"
        className={styles.keySelectBtn}
      >
        <FontAwesomeIcon icon={faPlusSquare} className={styles.keySelectIcon} /> Add
      </Button>
    </>
  );
}

function Rule({ data, index, errors, color, values, onDelete, onChange }) {
  const [value, setValue] = useState("");

  const header = useMemo(() => {
    const length = data.values.length - 1;

    if (!data.values.length > 0) return <span className={styles.valueError}>?</span>;

    return data.values.map((v, index) => {
      return (
        <span key={`${v}-${index}`}>
          <span className={styles.single}>{v}</span>
          {index !== length ? (
            <span className={styles.operatorHead}>{data.operator.toUpperCase()}</span>
          ) : null}
        </span>
      );
    });
  }, [data.operator, data.values]);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faTrashAlt}
        className={styles.deleteRuleIcon}
        onClick={() => onDelete(index)}
      />
    );
  }, [index, onDelete]);

  const addValue = useCallback(() => {
    if (value) {
      data.values.push(value);
      onChange(index, data);
      setValue("");
    }
  }, [data, index, onChange, value]);

  const changeOperator = useCallback(
    (operator) => {
      data.operator = operator.toUpperCase();
      onChange(index, data);
    },
    [data, index, onChange]
  );

  const deleteValue = useCallback(
    (index) => {
      data.values.splice(index, 1);
      onChange(index, data);
    },
    [data, onChange]
  );

  if (!values) return null;

  return (
    <Container
      title={
        <>
          {data.key} <span className={styles.ruleTitle}>=</span>
        </>
      }
      color={color}
      isButton={true}
      noMarginLast={true}
      headerOnlyOnFolded={false}
      header={header}
      folded={true}
      extra={extra}
      warningMessage={errors?.[index]}
    >
      <div className={styles.ruleHead} key={`data.key`}>
        <Form.Control
          as="select"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className={styles.ruleValuesSelect}
        >
          <option></option>
          {values
            .filter((v) => !data?.values?.includes(v.value))
            .map((v) => (
              <option value={v.value} key={v.label}>
                {v.label}
              </option>
            ))}
        </Form.Control>
        <Button
          size="sm"
          onClick={addValue}
          variant="outline-primary"
          className={styles.valueSelectBtn}
        >
          <FontAwesomeIcon icon={faPlusSquare} className={styles.keySelectIcon} /> Add
        </Button>
        <AndOrSelector
          onChangeOperator={changeOperator}
          operator={data.operator?.toLowerCase() ?? "or"}
          showTie={false}
        />
      </div>
      <div className={styles.ruleValuesBox}>
        {data?.values?.map((value, index) => (
          <span key={`${value}-${index}`}>
            <div className={styles.singleValue}>
              {value}
              <div aria-hidden="true" className={styles.closeX} onClick={() => deleteValue(index)}>
                ×
              </div>
            </div>
            {index !== data.values.length - 1 ? (
              <span className={styles.operatorHead}>{data.operator.toUpperCase()}</span>
            ) : null}
          </span>
        ))}
      </div>
    </Container>
  );
}
