import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

function LessThan(props) {
  const { rootNodePath, execute, handleChange, showDateFormat } = props;

  return (
    <div className={styles.lessBox}>
      <div className={styles.select}>
        <div className={styles.label}>Operator</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            className="form-control form-control-sm"
            name={`${rootNodePath}execute.otherFields.lessOperator`}
            onChange={(e) => handleChange("lessOperator", e)}
            value={execute.lessOperator}
          >
            <option value="lt">Less Than</option>
            <option value="lte">Less Than or Equal To</option>
          </Form.Control>
        </div>
      </div>
      <div className="column">
        {showDateFormat ? (
          <Form.Control
            type="text"
            className="form-control form-control-sm"
            name={`${rootNodePath}execute.otherFields.format`}
            placeholder="Optional Date Format eg. (yyyy)"
            onChange={(e) => handleChange("format", e)}
            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
            value={execute.min_term_freq}
          />
        ) : null}
      </div>
    </div>
  );
}

export default LessThan;
