import { Fragment, useMemo } from "react";
import { useDataParams } from "../../../hooks/useData";
import { Form } from "react-bootstrap";

const selectStyle = { width: "366px" };
const emptyObj = {};

export default function ContentTypesSelect(props) {
  const { value, handleChange, show, style = emptyObj, isInvalid } = props;
  const params = useDataParams();
  const types = useMemo(() => {
    if (params) {
      return Object.keys(params.users.clusters.types).map((value) => {
        return { value, label: params.users.clusters.types[value].name };
      });
    }

    return [];
  }, [params]);

  if (show === false) return null;

  return (
    <Fragment>
      <Form.Control
        as="select"
        onChange={(e) => handleChange(e.target.value)}
        value={value || ""}
        style={{ ...style, ...selectStyle }}
        placeholder="Select User Catalog"
        isInvalid={isInvalid}
        className={props.className}
      >
        <option value="">Select User Catalog...</option>
        {(types || []).map((type, i) => (
          <option key={`${type.value}-${i}`} value={type.value}>
            {type.label}
          </option>
        ))}
      </Form.Control>
    </Fragment>
  );
}
