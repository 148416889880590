import { useMemo } from "react";

import { Form } from "react-bootstrap";
import { faBan, faCompass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from "../../container";
import Loading from "../../loading";

import styles from "./styles.module.scss";
import "./global.scss";

export default function Results(props) {
  const [showResults, canSplitResults, allowSubDay] =
    props.mode === "metrics" ? [false, false, false] : [true, true, true];
  const { values, errors, setFieldValue, submitForm } = props.formik;

  const extra = useMemo(() => {
    const error = Object.keys(errors).length > 0;

    return (
      <button
        type="button"
        className={styles.exploreButton}
        onClick={() => submitForm()}
        disabled={error}
      >
        <FontAwesomeIcon
          icon={error ? faBan : faCompass}
          spin={props.loading}
          className={styles.loadingIcon}
        />{" "}
        <span className={styles.exploreButtonText}>Explore</span>
      </button>
    );
  }, [errors, props.loading, submitForm]);

  const header = useMemo(() => {
    return (
      <div className={styles.header}>
        {showResults && (
          <Form.Control
            type="number"
            min={1}
            max={1000}
            className={styles.numResultsBox}
            value={values.nunOfResponses}
            onChange={(e) => setFieldValue("nunOfResponses", Number(e.target.value))}
          />
        )}
        <div className={styles.label}>Interval</div>
        <Form.Control
          as="select"
          value={values.interval || "day"}
          onChange={(e) => setFieldValue("interval", e.target.value)}
          className={styles.intervalSelect}
        >
          <option></option>
          {allowSubDay && <option value="hour">Hour</option>}
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
        </Form.Control>
        {canSplitResults && (
          <Form.Check
            type="switch"
            value="true"
            checked={values.splitResults || false}
            onChange={(e) => setFieldValue("splitResults", e.target.checked)}
            id="splitResults-results"
            name="splitResults-results"
            label="Split Results"
            className={styles.splitResults}
          />
        )}
        {props.isSearch ? (
          <Form.Check
            type="switch"
            value="true"
            checked={values.zeroResults || false}
            onChange={(e) => setFieldValue("zeroResults", e.target.checked)}
            id="zero-results"
            name="zero-results"
            label="Zero Items Responses Only"
            className={styles.zeroResults}
          />
        ) : null}
      </div>
    );
  }, [
    allowSubDay,
    canSplitResults,
    props.isSearch,
    setFieldValue,
    showResults,
    values.interval,
    values.nunOfResponses,
    values.splitResults,
    values.zeroResults,
  ]);

  return (
    <Container
      title={
        <div className={styles.resultsTitleBox}>
          Results
          {props.numResults !== null ? (
            <div className={styles.numResultsBadge}>{props.numResults}</div>
          ) : null}
        </div>
      }
      help="analitica-explorer-results"
      color="#212f45"
      coloredBars={false}
      header={header}
      headerOnlyOnFolded={false}
      extra={extra}
    >
      {props.loading ? (
        <Loading height="200px" />
      ) : props.numResults <= 0 ? (
        <Loading height="200px" message="No Data Analitica" />
      ) : (
        props.children
      )}
    </Container>
  );
}
