import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import { RectClipPath } from "@visx/clip-path";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { ParentSize } from "@visx/responsive";
import { LinkHorizontal } from "@visx/shape";
import { Zoom } from "@visx/zoom";
import { Tooltip, useTooltip } from "@visx/tooltip";
import { useNavigate } from "react-router";
import RotaryKnob from "../../rotaryKnob";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faExpand, faShieldAlt, faUser } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import "./styles.scss";
import { explorer } from "../../../transport";

const peach = "#fd9b93";
const pink = "#fe6e9e";
const blue = "#03c0dc";
const green = "#26deb0";
const plum = "#71248e";
const lightpurple = "#374469";
const white = "#ffffff";
const defaultMargin = { top: 10, left: 80, right: 80, bottom: 10 };
// const initialTransform = {
//   scaleX: 1.27,
//   scaleY: 1.27,
//   translateX: -211.62,
//   translateY: 162.59,
//   skewX: 0,
//   skewY: 0,
// };
const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
};
export const background = "#000";

const colorMap = new Map([
  ["query.filter", "#0F0"],
  ["query.exclude", "#F00"],
  ["query.include", "#024648"],
  ["query.boost", "#3f0058"],
  ["query.order", "#051730"],
  ["presets", "#4a4e69"],
  ["block", "#ffafcc"],
  ["page", "#b5179e"],
  ["query", "#ffc300"],
  ["decorators", "#f15bb5"],
  ["externalSource", "#ff0a54"],
  ["list", "#fb8b24"],
  ["contentExtra", "#f72585"],
  ["inject", "#b100e8"],
  ["rule", "#f0c808"],
  ["rule.sub", "#888"],
  ["deleted", "#adff2f"],
  ["fallback", "#ff1493"],
]);

const haveCondition = (node) => {
  return node.data.condition !== undefined && node.data.condition !== "*" ? "2.2" : undefined;
};

export default function TreeExplorer(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { tenant, id_token } = useSelector((state) => state.user);
  const [showMiniMap, setShowMiniMap] = useState(false);
  const [heightMultiplier, setHeightMultiplier] = useState(0.5);
  const [widthMultiplier, setWidthMultiplier] = useState(0.9);
  const { tooltip, show, hide } = useDataTooltip();
  const doNavigate = useNavigate();
  const handle = useFullScreenHandle();
  const margin = defaultMargin;

  const navigate = useCallback(
    (node) => {
      if (node.data.link) doNavigate(node.data.link);
    },
    [doNavigate]
  );

  useEffect(() => {
    if (!tenant || !props.endpointId) return;
    setLoading(true);
    explorer
      .get(`endpoint/treeMap/${tenant}/${props.endpointId}`, {
        headers: { id_token },
      })
      .then((response) => {
        setLoading(false);

        if (response.data) {
          setData(hierarchy(response.data));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [props.endpointId, id_token, tenant]);

  if (loading) return <h1>Loading...</h1>;
  if (!data) return null;

  return (
    <FullScreen handle={handle}>
      <div className="explorer-container">
        <ParentSize>
          {(parent) => {
            const height = parent.height - margin.top - margin.bottom - 10;
            const yMax = height * heightMultiplier - margin.top - margin.bottom;
            const xMax = parent.width * widthMultiplier - margin.left - margin.right;

            return (
              <Zoom
                width={parent.width}
                height={height}
                scaleXMin={1 / 50}
                scaleXMax={10}
                scaleYMin={1 / 50}
                scaleYMax={10}
                transformMatrix={initialTransform}
              >
                {(zoom) => (
                  <div className={styles.relative}>
                    <svg
                      width={parent.width}
                      height={height}
                      style={{ cursor: zoom.isDragging ? "grabbing" : "grab" }}
                    >
                      <LinearGradient id="lg" from={peach} to={pink} />
                      <RectClipPath id="zoom-clip" width={parent.width} height={height} />
                      {/*<rect width={parent.width} height={height} rx={0} fill={background} />*/}
                      <rect
                        width={parent.width}
                        height={height}
                        rx={0}
                        fill="transparent"
                        onTouchStart={zoom.dragStart}
                        onTouchMove={zoom.dragMove}
                        onTouchEnd={zoom.dragEnd}
                        onMouseDown={zoom.dragStart}
                        onMouseMove={zoom.dragMove}
                        onMouseUp={zoom.dragEnd}
                        onMouseLeave={() => {
                          if (zoom.isDragging) zoom.dragEnd();
                        }}
                        onDoubleClick={(event) => {
                          const point = localPoint(event) || { x: 0, y: 0 };
                          zoom.scale({ scaleX: 1.3, scaleY: 1.3, point });
                        }}
                      />
                      <g transform={zoom.toString()}>
                        <Tree root={data} size={[yMax, xMax]}>
                          {(tree) => (
                            <Group top={margin.top} left={margin.left}>
                              {tree.links().map((link, i) => (
                                <LinkHorizontal
                                  key={`link-${i}`}
                                  data={link}
                                  stroke={lightpurple}
                                  strokeWidth="1"
                                  fill="none"
                                />
                              ))}
                              {tree.descendants().map((node, i) => (
                                <Node
                                  key={`node-${i}`}
                                  node={node}
                                  show={show}
                                  hide={hide}
                                  navigate={navigate}
                                />
                              ))}
                            </Group>
                          )}
                        </Tree>
                      </g>

                      {showMiniMap && (
                        <g
                          clipPath="url(#zoom-clip)"
                          transform={`
                    scale(0.25)
                    translate(${parent.width * 4 - parent.width - 60}, ${height * 4 - height - 60})
                  `}
                        >
                          <rect width={parent.width} height={height} fill="#1a1a1a" />
                          <Tree root={data} size={[yMax, xMax]}>
                            {(tree) => (
                              <Group top={margin.top} left={margin.left}>
                                {tree.links().map((link, i) => (
                                  <LinkHorizontal
                                    key={`link-${i}`}
                                    data={link}
                                    stroke={lightpurple}
                                    strokeWidth="1"
                                    fill="none"
                                  />
                                ))}
                                {tree.descendants().map((node, i) => (
                                  <Node
                                    key={`node-${i}`}
                                    node={node}
                                    show={show}
                                    hide={hide}
                                    navigate={navigate}
                                  />
                                ))}
                              </Group>
                            )}
                          </Tree>
                          <rect
                            width={parent.width}
                            height={height}
                            fill="white"
                            fillOpacity={0.2}
                            stroke="white"
                            strokeWidth={4}
                            transform={zoom.toStringInvert()}
                          />
                        </g>
                      )}
                    </svg>
                    {tooltip}
                    <div className={styles.controls}>
                      <div className={styles.rotaryBox}>
                        <RotaryKnob
                          value={heightMultiplier}
                          min={0.1}
                          max={20}
                          step={0.1}
                          label="Vertical"
                          labelPosition="bottom"
                          skin="s10"
                          onChange={setHeightMultiplier}
                        />
                        <RotaryKnob
                          value={widthMultiplier}
                          min={0.1}
                          max={20}
                          step={0.1}
                          label="Horizontal"
                          labelPosition="bottom"
                          skin="s10"
                          onChange={setWidthMultiplier}
                        />
                      </div>
                      <div className={styles.plusMinusBox}>
                        <ButtonGroup className="me-2" aria-label="Zoom group">
                          <Button
                            className={styles["btn-zoom"]}
                            onClick={() => zoom.scale({ scaleX: 1.1, scaleY: 1.1 })}
                            variant="light"
                          >
                            +
                          </Button>
                          <Button
                            variant="light"
                            className={styles["btn-bottom"]}
                            onClick={() => zoom.scale({ scaleX: 0.9, scaleY: 0.9 })}
                          >
                            -
                          </Button>
                        </ButtonGroup>
                      </div>

                      <Button
                        size="sm"
                        onClick={zoom.center}
                        variant="secondary"
                        className={styles.btn}
                      >
                        Center
                      </Button>
                      <Button
                        onClick={() => {
                          setHeightMultiplier(0.5);
                          setWidthMultiplier(0.9);
                          zoom.reset();
                        }}
                        variant="secondary"
                        className={styles.btn}
                      >
                        Reset
                      </Button>
                      <Button
                        size="sm"
                        onClick={zoom.clear}
                        variant="secondary"
                        className={styles.btn}
                      >
                        Clear
                      </Button>
                      <Button
                        size="sm"
                        onClick={handle.enter}
                        variant="secondary"
                        className={styles.btn}
                      >
                        <FontAwesomeIcon icon={faExpand} />
                      </Button>
                    </div>
                    <div className={styles["mini-map"]}>
                      <Button
                        variant="dark"
                        className={styles.miniMapBtn}
                        onClick={() => setShowMiniMap(!showMiniMap)}
                      >
                        {showMiniMap ? "Hide" : "Show"} Mini Map
                      </Button>
                    </div>
                  </div>
                )}
              </Zoom>
            );
          }}
        </ParentSize>
      </div>
    </FullScreen>
  );
}

function Node({ node, show, hide, navigate }) {
  const width = (node.data.name.length + 2) * 6;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;
  const isRoot = node.depth === 0;
  const isParent = !!node.children;

  if (isRoot) return <RootNode node={node} show={show} hide={hide} navigate={navigate} />;
  if (isParent) return <ParentNode node={node} show={show} hide={hide} navigate={navigate} />;

  return (
    <Group top={node.x} left={node.y}>
      <rect
        height={height}
        width={width}
        y={centerY}
        x={centerX}
        fill={background}
        stroke={colorMap.get(node.data.type) || green}
        strokeWidth={1}
        strokeDasharray={haveCondition(node)}
        strokeOpacity={0.6}
        rx={node.data.type === "fallback" ? 0 : 10}
        onMouseOver={(e) => show(e, node)}
        onMouseOut={hide}
        onClick={() => navigate(node)}
      />
      <text
        dy=".33em"
        fontSize={9}
        fontFamily="Arial"
        textAnchor="middle"
        fill={"#EEE"}
        style={{ pointerEvents: "none" }}
      >
        {`${node.data.type === "fallback" ? "[F] " : ""}${node.data.name}`}
      </text>
    </Group>
  );
}

function RootNode({ node, show, hide }) {
  const r = node.data.name.length * 3;
  return (
    <Group top={node.x} left={node.y}>
      <circle r={r} fill="url('#lg')" onMouseOver={(e) => show(e, node)} onMouseOut={hide} />
      <text
        dy=".33em"
        fontSize={9}
        fontFamily="Arial"
        textAnchor="middle"
        style={{ pointerEvents: "none" }}
        fill={plum}
        onMouseOver={(e) => show(e, node)}
        onMouseOut={hide}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function ParentNode({ node, show, hide, navigate }) {
  const width = (node.data.name.length + 2) * 6;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
    <>
      <Group top={node.x} left={node.y}>
        <rect
          height={height}
          width={width}
          y={centerY}
          x={centerX}
          fill={background}
          stroke={colorMap.get(node.data.type) || blue}
          strokeWidth={1}
          strokeDasharray={haveCondition(node)}
          strokeOpacity={0.6}
          onClick={() => navigate(node)}
          onMouseOver={(e) => show(e, node)}
          onMouseOut={hide}
        />
        <text
          dy=".33em"
          fontSize={9}
          fontFamily="Arial"
          textAnchor="middle"
          style={{ pointerEvents: "none" }}
          fill={white}
        >
          {node.data.name}
        </text>
      </Group>
    </>
  );
}

function useDataTooltip() {
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const show = useCallback(
    (event, node) => {
      const coords = localPoint(event.target.ownerSVGElement, event);

      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: (
          <div className={styles.tooltipBox}>
            <div className={styles.row}>
              <div className={styles.label}>Title</div>
              <div className={styles.value}>{node.data.name}</div>
            </div>
            {node.data.condition && (
              <div className={styles.row}>
                <div className={styles.label}>Condition</div>
                <div className={styles.value}>{node.data.condition}</div>
              </div>
            )}
            {node.data.boost > 0 && (
              <div className={styles.row}>
                <div className={styles.label}>Boost</div>
                <div className={styles.value}>{node.data.boost}</div>
              </div>
            )}
            {node.data.metadata && node.data.metadata.length > 0 ? (
              <div className={styles.row}>
                <div className={styles.label}>Metadata</div>
                <div className={styles.value}>
                  {node.data.metadata.map((m) => (
                    <div className={styles.metadata}>{m}</div>
                  ))}
                </div>
              </div>
            ) : null}
            {node.data.type === "fallback" ? (
              <div className={styles.row}>
                <div className={styles.label}>Fallback</div>
                <div className={styles.value}>
                  <FontAwesomeIcon icon={faShieldAlt} className={styles.fallbackIcon} />
                </div>
              </div>
            ) : null}
            {node.data.blockType && (
              <div className={styles.row}>
                <div className={styles.label}>Block Type</div>
                <div className={styles.value}>{node.data.blockType}</div>
              </div>
            )}
            {node.data.min !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Min</div>
                <div className={styles.value}>{node.data.min}</div>
              </div>
            )}
            {node.data.max !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Max</div>
                <div className={styles.value}>{node.data.max}</div>
              </div>
            )}
            {node.data.haveOverrides !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Metadata Overrides</div>
                <div className={styles.value}>{node.data.haveOverrides ? "TRUE" : "FALSE"}</div>
              </div>
            )}
            {node.data.resultsMultiplier !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Results Multiplier</div>
                <div className={styles.value}>{node.data.resultsMultiplier}</div>
              </div>
            )}
            {node.data.minimumShouldMatch !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Minimum Should Match</div>
                <div className={styles.value}>{node.data.minimumShouldMatch}</div>
              </div>
            )}
            {node.data.tieBreaker !== undefined && (
              <div className={styles.row}>
                <div className={styles.label}>Tie Breaker</div>
                <div className={styles.value}>{node.data.tieBreaker}</div>
              </div>
            )}
            {node.data.username !== undefined && (
              <div className={styles.lastSaved}>
                <div className={styles.row}>
                  <div className={styles.label}>
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <div className={styles.value}>{node.data.username}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  <div className={styles.value}>{node.data.date}</div>
                </div>
              </div>
            )}
          </div>
        ),
      });
    },
    [showTooltip]
  );

  const hide = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  const tooltip = useMemo(() => {
    if (!tooltipOpen) return null;
    return (
      <Tooltip key={Math.random()} top={tooltipTop} left={tooltipLeft} className={styles.tooltip}>
        {tooltipData}
      </Tooltip>
    );
  }, [tooltipData, tooltipLeft, tooltipOpen, tooltipTop]);

  return { tooltip, show, hide };
}
