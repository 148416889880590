import { forwardRef, useCallback } from "react";
import { useFormikContext } from "formik";

import Filters from "../analitica/analiticaFilters";

const ConversionPack = forwardRef((props, ref) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const handleFiltersChange = useCallback(
    (filters) => setFieldValue("filters", filters),
    [setFieldValue]
  );

  return (
    <Filters
      data={values.filters}
      onChange={handleFiltersChange}
      foldable={true}
      folded={true}
      title="General"
      errors={errors?.filters}
      color="#143601"
      coloredTitle={false}
    />
  );
});

export default ConversionPack;
