import { useMemo } from "react";
import { useGetMemItem } from "../../../hooks/useMemoryDB";
import { Form } from "react-bootstrap";

export default function InputFieldsSelect(props) {
  const { value, onChange, className, excludes = [] } = props;
  const client = useGetMemItem("ui", "client");
  const fields = useMemo(() => {
    if (client) {
      const fields = Object.keys(
        client.uiSettings?.input?.schema?.properties?.query?.properties || {}
      );
      return fields.filter((field) => !excludes.includes(field));
    }
  }, [client, excludes]);

  if (!fields) return null;

  return (
    <Form.Control
      as="select"
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
      value={value}
      className={className}
    >
      <option></option>
      {fields.map((v) => (
        <option value={v} key={v}>
          {v}
        </option>
      ))}
    </Form.Control>
  );
}
