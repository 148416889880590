import { useMemo, useCallback, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import {
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import moment from "moment";
import Picker from "rc-calendar/lib/Picker";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { MultiSelect } from "react-multi-select-component";
import Container from "../../container";
import MetricsSelect from "../../selects/selectMetrics";

import colors from "../../../settings/chartColors";
import styles from "./styles.module.scss";
import "./global.scss";

const now = moment();
const alignConfig = {
  points: ["bl", "br"], // align top left point of sourceNode with top right point of targetNode
  offset: [0, 150], // the offset sourceNode by 10px in x and 20px in y,
  targetOffset: ["200%", "0%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
};

export default function ExperimentExplorer(props) {
  const { data, reloadData } = props;
  const { values } = useFormikContext();
  const [metricsId, setMetricsId] = useState(values.metrics);

  const extra = useMemo(() => {
    return (
      <div className={styles.extra}>
        <span className={styles.label}>Metrics</span>{" "}
        <MetricsSelect value={metricsId} onChange={setMetricsId} allowEmpty={false} />
      </div>
    );
  }, [metricsId]);

  return (
    <Container
      title="Explorer"
      help="experiment-explorer"
      color="#212f45"
      coloredBars={false}
      foldable={false}
      extra={extra}
      header={<ChooserHeader onChange={reloadData} metricsId={metricsId} />}
      headerOnlyOnFolded={false}
    >
      <ResponsiveContainer width={"100%"} height={250}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            {colors.map((c, index) => {
              return (
                <linearGradient
                  id={`grad-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                  key={`${c.stop1}-${c.stop2}-${index}`}
                >
                  <stop offset="5%" stopColor={c.stop1} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={c.stop2} stopOpacity={0} />
                </linearGradient>
              );
            })}
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          {values.variations.map((v, index) => {
            return (
              <Area
                type="monotone"
                dataKey={v.id}
                stroke={colors[index].stroke}
                fillOpacity={1}
                fill={`url(#grad-${index})`}
                name={v.label}
                key={v.id}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
}

function ChooserHeader(props) {
  const { onChange, metricsId } = props;
  const { values } = useFormikContext();
  const tenant = useSelector((state) => state.user.tenant);

  const ipotesi = useMemo(() => {
    if (values.variations) {
      return values.variations.map((v, index) => {
        return { label: v.label, value: v.id, disabled: index === 0 ? true : false };
      });
    }
    return [];
  }, [values.variations]);

  const [hypothesis, setHypothesis] = useState(ipotesi);
  const [interval, setInterval] = useState("day");
  const [hoverValue, setHoverValue] = useState([]);
  const [range, setRange] = useState(() => {
    return [
      moment(values?.scheduling?.start || Date.now()),
      moment(values?.scheduling?.end || Date.now()),
    ];
  });

  const handleChangeHypothesis = useCallback((data) => {
    setHypothesis(data);
  }, []);

  const handleChangeInterval = useCallback((e) => {
    const value = e.target.value;
    setInterval(value);
  }, []);

  const handleChangeRange = useCallback((data) => {
    setRange(data);
  }, []);

  useEffect(() => {
    if (ipotesi && hypothesis.length === 0) {
      setHypothesis(ipotesi);
    }
  }, [hypothesis.length, ipotesi]);

  useEffect(() => {
    if (
      onChange &&
      tenant &&
      values.id &&
      hypothesis &&
      hypothesis.length &&
      interval &&
      range &&
      metricsId
    ) {
      const payload = {
        tenant,
        experimentId: values.id,
        variationsId: hypothesis.map((v) => v.value),
        calendarInterval: interval,
        metricsId,
        scheduling: {
          start: range[0].toISOString(),
          end: range[1].toISOString(),
        },
      };

      onChange(payload);
    }
  }, [hypothesis, interval, metricsId, onChange, range, tenant, values.id]);

  const rangeCalendar = useMemo(
    () => (
      <RangeCalendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder={["start", "end"]}
        defaultValue={[now, now.clone().add(1, "months")]}
        //locale={cn ? zhCN : enUS}
      />
    ),
    [hoverValue]
  );

  return (
    <div className={styles.chooserHeader}>
      <div className={styles.hypothesis}>
        <div className={styles.label}>Hypothesis</div>
        <MultiSelect
          options={ipotesi}
          value={hypothesis}
          onChange={handleChangeHypothesis}
          labelledBy="Select"
          overrideStrings={{
            allItemsAreSelected: "All Hypothesis Selected.",
            selectSomeItems: "Select Hypothesis...",
          }}
        />
      </div>

      <div className={styles.range}>
        <div className={styles.label}>Time Range</div>
        <Picker
          value={range}
          onChange={handleChangeRange}
          animation="slide-up"
          calendar={rangeCalendar}
          align={alignConfig}
        >
          {({ value: currentValue }) => {
            if (!currentValue.length) {
              return (
                <Button size="sm" className="pick-date" variant={"outline-light"}>
                  Pick a Date Range...
                </Button>
              );
            }
            return (
              <div className={styles.scheduling}>
                <span>{currentValue[0].format("DD-MM-YYYY")}</span>
                <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />

                <span>{currentValue[1].format("DD-MM-YYYY")}</span>
              </div>
            );
          }}
        </Picker>
      </div>
      <div className={styles.interval}>
        <div className={styles.label}>Interval</div>
        <Form.Control
          as="select"
          value={interval}
          onChange={handleChangeInterval}
          className={styles.intervalSelect}
          size="sm"
        >
          <option></option>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
        </Form.Control>
      </div>
    </div>
  );
}
