import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash.clonedeep";
import * as serialize from "serialize-javascript";

export default class MemoryDB {
  constructor({ db, primaryKeys = {} } = {}) {
    this.db = db ?? new Map();
    this.observers = new Map();
    this.primaryKeys = primaryKeys;
    this.logs = new Map();
  }

  getId(table, data, idField = "id", forceNotGenerate = false) {
    const field = this.primaryKeys[table] ?? idField;
    const id = data[field];

    if (id || forceNotGenerate) return id;

    return uuidv4();
  }

  put(table, data, idField = "id") {
    if (!this.db.has(table)) this.db.set(table, new Map());
    const id = this.getId(table, data, idField);
    this.db.get(table).set(id, data);

    this.dispatch(table, "put", id);
  }

  bulkPut(table, data, idField = "id") {
    const ids = [];
    data = Array.isArray(data) ? data : [data];
    if (!this.db.has(table)) this.db.set(table, new Map());

    const pointer = this.db.get(table);
    for (const row of data) {
      const id = this.getId(table, row, idField);
      pointer.set(id, row);
      ids.push(id);
    }

    this.dispatch(table, "bulkPut", ids);
  }

  delete(table, id) {
    if (this.db.has(table)) {
      if (this.db.get(table).has(id)) {
        this.db.get(table).delete(id);
        this.dispatch(table, "delete", id);
      }
    }
  }

  deleteByQuery(table, query) {
    if (this.db.has(table)) {
      const ids = [];

      for (const [key, value] of this.db.get(table).entries()) {
        if (query(value)) ids.push(key);
      }

      if (ids.length > 0) {
        for (const id of ids) {
          this.db.get(table).delete(id);
        }
        this.dispatch(table, "deleteByQuery", ids);
      }

      return ids;
    }
  }

  deleteTable(table) {
    if (this.db.has(table)) {
      this.db.delete(table);
      this.dispatch(table, "deleteTable");
    }
  }

  select(table, query) {
    this.log(table, "select");
    if (this.db.has(table)) {
      return cloneDeep([...this.db.get(table).values()])
        .filter(query)
        .filter(Boolean);
    }
  }

  get(table, id) {
    this.log(table, "get");
    if (this.db.has(table)) {
      if (this.db.get(table).has(id)) {
        return cloneDeep(this.db.get(table).get(id));
      }
    }
  }

  bulkGet(table, ids) {
    const ret = [];
    this.log(table, "bulkGet");

    if (this.db.has(table)) {
      ids = Array.isArray(ids) ? ids : [ids];
      for (const id of ids) {
        if (this.db.get(table).has(id)) {
          ret.push(cloneDeep(this.db.get(table).get(id)));
        }
      }
    }

    return ret;
  }

  getFromId(table, value) {
    this.log(table, "getFromField");

    if (this.db.has(table)) {
      return [...this.db.get(table).values()].find((row) => row.id === value);
    }
  }

  getTable(table) {
    this.log(table, "getTable");

    if (this.db.has(table)) {
      return cloneDeep(this.db.get(table));
    }
  }

  getDB() {
    this.log("db", "bulkGet");
    return this.db;
  }

  observe(table, cb) {
    const observerId = uuidv4();
    if (!this.observers.has(table)) this.observers.set(table, new Map());
    this.observers.get(table).set(observerId, cb);
    return observerId;
  }

  forget(table, observerId) {
    if (this.observers.has(table)) {
      if (this.observers.get(table).has(observerId)) {
        this.observers.get(table).delete(observerId);
        if (this.observers.get(table).size === 0) this.observers.delete(table);
      }
    }
  }

  dispatch(table, action, ids) {
    this.log(table, action);

    if (this.observers.has(table)) {
      for (const cb of this.observers.get(table).values()) {
        if (cb) {
          cb(table, action, ids);
        }
      }
    }
  }

  log(table, action) {
    if (!this.logs.has(table)) this.logs.set(table, new Map());
    if (!this.logs.get(table).has(action)) this.logs.get(table).set(action, 0);

    const value = this.logs.get(table).get(action) + 1;
    this.logs.get(table).set(action, value);
  }

  getStats() {
    return {
      observers: this.observers,
      logs: this.logs,
    };
  }

  export() {
    return serialize.default(this.db);
  }
}
