import { useEffect, useMemo, useState, useCallback } from "react";
import { useSelectMem } from "../../../hooks/useMemoryDB";
import filtersStyles from "./scss/filters.module.scss";
import styles from "./scss/labels.module.scss";

export default function LabelsFilter(props) {
  const { filters, onUpdateFilters, types, label } = props;
  const [current, setCurrent] = useState(filters.labels || []);
  const labels = useSelectMem(
    "labels",
    useCallback((i) => types.includes(i.type), [types])
  );

  const toggleLabel = useCallback(
    (value) => {
      const newCurrent = [...current];
      const newFilters = { ...filters };

      if (current.includes(value)) {
        const index = current.findIndex((c) => c === value);
        newCurrent.splice(index, 1);
      } else {
        newCurrent.push(value);
      }

      newFilters.labels = newCurrent;

      onUpdateFilters(newFilters);
    },
    [current, filters, onUpdateFilters]
  );

  const items = useMemo(() => {
    let ret = [];

    if (labels) {
      const etichette = Array.isArray(labels) ? labels : [labels];

      etichette.forEach((label) => {
        label.elements.forEach((f, i) => {
          let style;

          if (current.includes(f.id)) style = { backgroundColor: f.background, color: f.color };
          else {
            style = { borderColor: f.background };
          }

          ret.push(
            <div
              key={`label-${f.id}-${i}`}
              className={styles.label}
              onClick={(e) => {
                //e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                toggleLabel(f.id);
              }}
              style={style}
            >
              {f.value}
            </div>
          );
        });
      });
    }

    return ret;
  }, [current, labels, toggleLabel]);

  useEffect(() => {
    if (filters.labels) {
      setCurrent(filters.labels);
    }
  }, [filters.labels]);

  // useEffect(() => {
  //   dispatch(requestLabels());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className={filtersStyles.box}>
      {label ? <span className={filtersStyles.label}>{label}</span> : null}
      {items}
    </div>
  );
}
