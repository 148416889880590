import { forwardRef, useEffect, useCallback, useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import useDragAndDrop from "../../../hooks/useDragAndDrop";
import styled from "styled-components";
import getMetadata, { getCatalogs } from "../../../utils/getMetadata";
import { Form } from "react-bootstrap";
import shortid from "shortid";
import set from "lodash.set";

import "./style.scss";
import Container from "../../container";
import ConditionsPlus from "../../conditionsPlus";
import Execute from "./execute";
import cloneDeep from "lodash.clonedeep";

const conditionsStyle = { border: "0px" };

const Statement = styled.span`
  display: flex;
  flex-wrap: wrap;
  font-family: "Menlo", "Courier New", Courier, monospace;
  font-size: 0.7em;
`;

const Field = styled.span`
  color: coral;

  &::after {
    content: ",";
    margin-right: 4px;
  }

  &:nth-child(n + 1)::after {
    content: "";
    margin-right: 6px;
  }
`;

const QueryType = styled.span`
  margin-right: 6px;
  font-weight: 900;
`;

const Value = styled.span`
  color: #888;
`;

const Warning = styled.span`
  color: goldenrod;
`;

const queryTypes = new Map([
  ["match", "Match"],
  ["matchAll", "Match All Terms"],
  ["listTermsId", "Match from List"],
  ["matchFromArray", "Match from Array"],
  ["contains", "Match with Wildcard"],
  ["similarTo", "Search"],
  ["beginsWith", "Begins With"],
  ["like", "Similar To"],
  ["likeContentId", "Similart to Content with Id"],
  ["ids", "Included in Ids"],
  ["listId", "Included in List"],
  ["sortOrder", "Sort"],
  ["checkExists", "Exists"],
  ["random", "Random"],
  ["range", "Range"],
  ["between", "Between"],
  ["greaterValue", "Greater Than"],
  ["lessValue", "Less Than"],
]);

export const formatStatement = (statement) => {
  if (!statement.field) return <Warning>Invalid Statement</Warning>;

  const field = Array.isArray(statement.field) ? (
    statement.field.map((f, i) => (
      <Field key={`field-${i}`} className={i === statement.field.length - 1 ? "last" : ""}>
        {f.value}
      </Field>
    ))
  ) : (
    <Field>{statement.field}</Field>
  );

  return (
    <Statement>
      {field}
      <QueryType>{queryTypes.get(statement.queryType)}</QueryType>
      <Value>{statement.value}</Value>
    </Statement>
  );
};

const Rule = forwardRef((props, ref) => {
  const { rootNode, rootNodePath, ruleIndex, onScheduled, info } = props;
  const { handleBlur, setFieldValue, values, errors } = useFormikContext();
  useEffect(() => {
    if (!rootNode._id) {
      setFieldValue(`${rootNodePath}_id`, shortid.generate());
    }
  }, [rootNode._id, rootNodePath, setFieldValue]);

  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    `${rootNodePath}${ruleIndex}`,
    ruleIndex,
    "rule"
  );

  const stile = useMemo(() => {
    const style = { opacity, cursor: "pointer" };

    if (highlighted && !hovered) {
      style.backgroundColor = "#222";

      return style;
    }
    if (hovered) {
      style.backgroundColor = "#333";
      style.marginTop = "5px";
      style.marginBottom = "5px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const doOverwrite = useCallback(
    (e) => {
      setFieldValue(`rule[${ruleIndex}].notOverwritable`, e.target.checked);
    },
    [ruleIndex, setFieldValue]
  );

  const realms = useMemo(() => getMetadata(values.metadata), [values]);
  const catalogs = useMemo(() => getCatalogs(values.metadata, values.contentType), [values]);

  const handleChangeCondition = useCallback(
    ({ condition, conditionRaw, scheduled, scheduling }) => {
      const newValues = cloneDeep(values);

      set(newValues, `rule[${ruleIndex}].condition`, condition);
      set(newValues, `rule[${ruleIndex}].conditionRaw`, conditionRaw);
      set(newValues, `rule[${ruleIndex}].scheduled`, scheduled);
      set(newValues, `rule[${ruleIndex}].scheduling`, scheduling);

      // Pass New Values to onSchedule that will set them
      onScheduled(newValues, scheduled, scheduling);
    },
    [onScheduled, ruleIndex, values]
  );

  const statementHeader = useMemo(() => {
    return formatStatement(rootNode);
  }, [rootNode]);

  const execFolded = useRef(new Set());

  if (!execFolded.current.has(rootNode._id) && errors.statementsList?.[rootNode._id]?.execute) {
    execFolded.current.add(rootNode._id);
  }

  return (
    <div style={stile}>
      <ConditionsPlus
        label="If Those Conditions are met"
        catalogs={catalogs}
        realms={realms}
        condition={rootNode.condition}
        conditionRaw={rootNode.conditionRaw}
        onChangeCondition={handleChangeCondition}
        enableReinitialize={true}
        style={conditionsStyle}
        folded={true}
        warning={errors.statementsList?.[rootNode._id]?.conditions}
        info={info}
      />

      <Container
        title="Then execute this statement"
        help="execute-block"
        color="#021302"
        header={statementHeader}
        folded={!execFolded.current.has(rootNode._id)}
        extra={
          <Form.Check
            type="switch"
            value={true}
            checked={rootNode.notOverwritable || false}
            id={`${rootNodePath}notOverwritable`}
            name={`${rootNodePath}notOverwritable`}
            onBlur={handleBlur}
            onChange={doOverwrite}
            label="Avoid Params Override"
          />
        }
        warning={errors.statementsList?.[rootNode._id]?.execute}
      >
        <Execute
          rootNodePath={rootNodePath}
          rootNode={rootNode}
          ruleIndex={ruleIndex}
          info={info}
        />
      </Container>
    </div>
  );
});

export default Rule;
