import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import styles from "./users.module.scss";

const expire = (item) => {
  const expire = (
    Number(item?.expireAfter || 0) - Math.abs((Number(item?.ts || Date.now()) - Date.now()) / 1000)
  ).toFixed(0);

  return expire < 0 ? 0 : expire;
};

const countStyle = { textAlign: "center" };
export default function UsersPane(props) {
  const logged = useSelector((state) => state.user.loggedUsers || []);
  const navigate = useNavigate();

  if (!logged?.length > 0) return null;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.pane}>
          <div className={styles.title}>
            <FontAwesomeIcon icon={faUsers} className={styles.icon} />
            Active Users
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Table striped bordered hover variant="dark" className={styles.table}>
          <thead>
            <tr className={styles.padding}>
              <th className={styles.padding}></th>
              <th className={styles.padding}>Username</th>
              <th className={styles.padding}>Current Task</th>
              <th className={styles.padding}>Expire</th>
            </tr>
          </thead>
          <tbody>
            {logged.map((user, index) => {
              const { username, active } = user;
              const expiration = expire(active);

              return (
                <tr key={`${username}-${index}`} className={styles.padding}>
                  <td className={styles.padding} style={countStyle}>
                    {index + 1}
                  </td>
                  <td className={styles.padding}>{username}</td>
                  <td className={styles.padding}>
                    {active?.title ? (
                      <div
                        className={styles.activeTask}
                        onClick={() =>
                          active.type !== "Page"
                            ? navigate(`/${active.accessor}/${active.id}`)
                            : true
                        }
                      >
                        <div className={styles.typeBox}>
                          <span className={styles.type}>{active.type}</span>
                          <span className={styles.id}>{active.id}</span>
                        </div>
                        <span className={styles.title}>{active.title}</span>
                      </div>
                    ) : (
                      "None"
                    )}
                  </td>
                  <td className={styles.padding}>
                    {!isNaN(expiration) ? (
                      <>
                        <FontAwesomeIcon icon={faHourglassHalf} className={styles.lockIcon} />
                        {`${expiration} sec`}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
