import { useMemo, useState, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { faPlusSquare, faTrashAlt, faPowerOff, faArchive } from "@fortawesome/free-solid-svg-icons";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import useMetadataValues from "../../hooks/useMetadataValues";
import cloneDeep from "lodash.clonedeep";
import ValuesSelect from "../selects/selectValues";
import Container from "../container";
import AndOrSelector from "../query/andOrSelector";
import MetaSelect from "./metaSelect";
import SelectAll from "../selects/selectAll";
import styles from "./styles.module.scss";

export default function MetadataChooser(props) {
  const { title, help, color, accessor } = props;
  const { setFieldValue, values, errors } = useFormikContext();
  const [conversionPack, setConversionPack] = useState();

  const header = useMemo(() => {
    return (
      <AndOrSelector
        onChangeOperator={(value) => setFieldValue(`${accessor}.operator`, value)}
        onChangeBreaker={(value) => setFieldValue(`${accessor}.tieBreaker`, value)}
        operator={values?.[accessor]?.operator}
        breaker={values?.[accessor]?.tieBreaker}
      />
    );
  }, [accessor, setFieldValue, values]);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faPowerOff}
        onClick={() => setFieldValue(accessor, undefined)}
        className={styles.powerOffIcon}
      />
    );
  }, [accessor, setFieldValue]);

  const rules = useMemo(() => {
    return values[accessor]?.rules || [];
  }, [accessor, values]);

  const packsIn = useMemo(() => {
    const rules = values[accessor]?.rules || [];
    const packs = [];

    if (values.id) packs.push(values.id);
    rules.forEach((rule) => {
      if (rule.conversionPackId) packs.push(rule.conversionPackId);
    });
    return packs;
  }, [accessor, values]);

  const addMetadata = useCallback(
    ({ id, dbType, dbNestedField, dbNestePath }) => {
      const rules = values[accessor]?.rules || [];
      rules.push({
        type: "metadata",
        name: id,
        values: [],
        operator: "or",
        fieldNested: dbNestedField,
        fieldNestedRoot: dbNestePath,
        dbType,
      });
      setFieldValue(`${accessor}.rules`, rules);
      setFieldValue("lastSaved.date", new Date().toISOString());
    },
    [accessor, setFieldValue, values]
  );

  const deleteMetadata = useCallback(
    (index) => {
      const rules = values[accessor]?.rules || [];
      rules.splice(index, 1);
      setFieldValue(`${accessor}.rules`, rules);
      setFieldValue("lastSaved.date", new Date().toISOString());
    },
    [accessor, setFieldValue, values]
  );

  const updateMetadata = useCallback(
    (index, value) => {
      const rules = values[accessor]?.rules || [];
      rules[index] = value;

      setFieldValue(`${accessor}.rules`, rules);
      setFieldValue("lastSaved.date", new Date().toISOString());
    },
    [accessor, setFieldValue, values]
  );

  const addPack = useCallback(() => {
    if (conversionPack) {
      console.log("ADD", conversionPack);
      const rules = values[accessor]?.rules || [];
      rules.push({
        type: "pack",
        conversionPackId: conversionPack,
        operator: "or",
      });
      setFieldValue(`${accessor}.rules`, cloneDeep(rules));
      setFieldValue("lastSaved.date", new Date().toISOString());

      setConversionPack(undefined);
    }
  }, [accessor, conversionPack, setFieldValue, values]);

  const conversionPackSelectFilters = useMemo(() => {
    return { exclude: packsIn, catalogs: [values.contentType] };
  }, [packsIn, values.contentType]);

  if (!values.contentType) return null;

  return (
    <Container
      title={title}
      help={help}
      color={color}
      coloredBars={false}
      coloredTitle={false}
      headerOnlyOnFolded={false}
      header={header}
      warningMessage={errors[accessor]}
      extra={extra}
    >
      <div className={styles.metadataChooser}>
        <div className={styles.metaBox}>
          <MetaSelect accessor={accessor} onAdd={addMetadata} key={title} />
        </div>
        <div className={styles.packBox}>
          {values.contentType && (
            <>
              <Form.Label>Conversions Pack:</Form.Label>
              <SelectAll
                accessor="conversionPacks"
                filters={conversionPackSelectFilters}
                handleChange={setConversionPack}
                value={conversionPack}
                className={styles.packSelect}
              />

              <Button
                size="sm"
                onClick={addPack}
                variant="outline-primary"
                className={styles.packBtn}
              >
                <FontAwesomeIcon icon={faPlusSquare} className={styles.metaSelectIcon} /> Add
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.metadataBox}>
        {rules.map((rule, index) => {
          return (
            <span key={`rulePack-${index}`}>
              {rule.type === "pack" ? (
                <PackRule
                  key={`rulePack-${index}`}
                  color={color}
                  data={rule}
                  index={index}
                  onDelete={deleteMetadata}
                />
              ) : (
                <Rule
                  key={`${rule.name}-${index}`}
                  data={rule}
                  index={index}
                  color={color}
                  accessor={accessor}
                  onDelete={deleteMetadata}
                  onUpdate={updateMetadata}
                />
              )}
              {index !== rules.length - 1 ? (
                <span className={styles.operatorRule}>
                  {(values?.[accessor]?.operator ?? "AND").toUpperCase()}
                </span>
              ) : null}
            </span>
          );
        })}
      </div>
    </Container>
  );
}

function PackRule(props) {
  const { color, onDelete, index, data } = props;
  const pack = useGetMemItem("conversionPacks", data.conversionPackId);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faTrashAlt}
        className={styles.deleteRuleIcon}
        onClick={() => onDelete(index)}
      />
    );
  }, [index, onDelete]);

  return (
    <>
      <Container
        title={
          <div className={styles.packTitle}>
            <FontAwesomeIcon icon={faArchive} className={styles.packIcon} />
            {pack?.title || "TBD"}
          </div>
        }
        color={color}
        isButton={true}
        noMarginLast={true}
        headerOnlyOnFolded={false}
        folded={true}
        foldable={false}
        extra={extra}
      />
    </>
  );
}

function Rule(props) {
  const { data, color, index, accessor, onDelete, onUpdate } = props;
  const { errors, values } = useFormikContext();
  const fieldValues = useMetadataValues(values.contentType, data.name);
  const [value, setValue] = useState();

  const more = useMemo(
    () => fieldValues?.length !== data.values.length,
    [data.values.length, fieldValues?.length]
  );

  const header = useMemo(() => {
    const length = data.values.length - 1;

    if (!data.values.length > 0) return <span className={styles.valueError}>?</span>;

    return data.values.map((v, index) => {
      return (
        <span key={`${v}-${index}`}>
          <span className={styles.singleMeta} key={`${v}-${index}`}>
            {v}
          </span>
          {index !== length ? (
            <span className={styles.operator}>{data.operator.toUpperCase()}</span>
          ) : null}
        </span>
      );
    });
  }, [data.operator, data.values]);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faTrashAlt}
        className={styles.deleteRuleIcon}
        onClick={() => onDelete(index)}
      />
    );
  }, [index, onDelete]);

  const addValue = useCallback(() => {
    if (value) {
      const values = [...data.values];
      values.push(value);

      onUpdate(index, { ...data, values });
      setValue(null);
    }
  }, [data, index, onUpdate, value]);

  const removeValue = useCallback(
    (i) => {
      const values = [...data.values];
      values.splice(i, 1);

      onUpdate(index, { ...data, values });
    },
    [data, index, onUpdate]
  );

  const changeOperator = useCallback(
    (operator) => {
      onUpdate(index, { ...data, operator });
    },
    [data, index, onUpdate]
  );

  return (
    <Container
      title={
        <>
          {data.name} <span className={styles.titleEquals}>=</span>
        </>
      }
      color={color}
      isButton={true}
      noMarginLast={true}
      headerOnlyOnFolded={false}
      header={header}
      folded={true}
      extra={extra}
      warningMessage={errors[`${accessor}_${index}`]}
    >
      <div className={styles.valueSelectBox} key={`${data.name}-${index}-select`}>
        {more ? (
          <>
            <ValuesSelect
              debugLabel="from-extra"
              selectOptions={fieldValues}
              name={data.name}
              handleChange={setValue}
              value={value}
              excludes={data.values}
              className={styles.valuesSelect}
            />
            <Button
              size="sm"
              onClick={addValue}
              variant="outline-primary"
              className={styles.metaSelectBtn}
            >
              <FontAwesomeIcon icon={faPlusSquare} className={styles.metaSelectIcon} /> Add
            </Button>
          </>
        ) : null}
        <AndOrSelector onChangeOperator={changeOperator} operator={data.operator} showTie={false} />
      </div>
      <div className={styles.valueBox}>
        {data.values.map((value, index) => {
          return (
            <span key={`${value}-${index}`}>
              <div className={styles.singleValue} key={`${value}-${index}`}>
                {value}
                <div
                  aria-hidden="true"
                  onClick={() => removeValue(index)}
                  className={styles.closeX}
                >
                  &times;
                </div>
              </div>
              {index !== data.values.length - 1 ? (
                <span className={styles.operator}>{data.operator.toUpperCase()}</span>
              ) : null}
            </span>
          );
        })}
      </div>
    </Container>
  );
}
