import { Fragment, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useGetMemItem, useSelectMem } from "../../../hooks/useMemoryDB";
import Select from "../selectWithSearch";
import "./style.scss";

const emptyObj = {};

export default function LabelSelect(props) {
  const { value, types, handleChange, errored, isInvalid, style } = props;

  const labels = useSelectMem(
    "labelsAll",
    useCallback(
      (i) => {
        if (!types?.includes(i.type)) return false;
        if (i.id === value) return false;

        return true;
      },
      [types, value]
    )
  );

  const items = useMemo(() => {
    let ret = [];

    if (labels) {
      const etichette = Array.isArray(labels) ? labels : [labels];

      etichette.forEach((f, i) => {
        ret.push(
          <Dropdown.Item
            eventKey={f.id}
            key={`label-${f.value}-${i}`}
            style={{ display: "inline-grid" }}
          >
            <span
              className="label-container"
              style={{
                backgroundColor: f.background,
                color: f.color,
              }}
            >
              {f.value}
            </span>
          </Dropdown.Item>
        );
      });
    }

    return ret;
  }, [labels]);

  const current = useGetMemItem("labelsAll", value) ?? emptyObj;
  const suffix = useMemo(() => Array.isArray(types) && types.join("-"), [types]);
  const canFilter = useMemo(() => items?.length > 5, [items]);

  //if (!labels || !current) return <div className="label-container" style={style}></div>;

  return (
    <Select
      key={`select-labels-${suffix}`}
      name={"select-query"}
      value={current?.value}
      disabled={false}
      canFilter={canFilter}
      errored={isInvalid || errored}
      onSelect={handleChange}
      style={{ ...style, color: current?.color }}
      backgroundColor={current?.background}
    >
      <Fragment key={`select-labels-values-${suffix}`}>{items}</Fragment>
    </Select>
  );
}
