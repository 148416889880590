import { useMemo } from "react";
import { Form } from "react-bootstrap";

function Search(props) {
  const { handleChange, execute, name } = props;

  const disableFuzziness = useMemo(
    () => ["phrase_prefix", "phrase", "cross_fields"].includes(execute.multiType),
    [execute.multiType]
  );

  return (
    <div className="multi-row">
      <div className="row">
        <div className="column">
          <div className="label">Type</div>
          <div className="content">
            <Form.Control
              as="select"
              value={execute.multiType}
              onChange={(e) => handleChange("multiType", e)}
              id={`${name}-multimatch-fuzziness`}
            >
              <option value="best_fields">Best Fields</option>
              <option value="most_fields">Most Fields</option>
              <option value="cross_fields">Cross Fields</option>
              <option value="phrase">Phrase</option>
              <option value="phrase_prefix">Phrase Prefix</option>
              <option value="bool_prefix">Bool Prefix</option>
            </Form.Control>
          </div>
        </div>
        <div className="column">
          <div className="label">Operator</div>
          <div className="content">
            <Form.Control
              as="select"
              value={execute.operator || "OR"}
              onChange={(e) => handleChange("operator", e)}
              id={`${name}-multimatch-operator`}
            >
              <option value="AND">And</option>
              <option value="OR">Or</option>
            </Form.Control>
          </div>
        </div>
        <div className="column">
          <div className="label">{disableFuzziness ? "" : "Fuzziness"}</div>
          <div className="content">
            {!disableFuzziness ? (
              <Form.Control
                as="select"
                value={execute.fuzziness}
                onChange={(e) => handleChange("fuzziness", e)}
                id={`${name}-multimatch-fuzziness`}
              >
                <option>AUTO</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="label">Prefix Length</div>
          <div className="content">
            <Form.Control
              type="number"
              min={0}
              value={execute.prefix_length || 0}
              onChange={(e) => handleChange("prefix_length", e)}
            />
          </div>
        </div>
        <div className="column">
          <div className="label">Max Expansion</div>
          <div className="content">
            <Form.Control
              type="number"
              min={0}
              value={execute.max_expansions || 50}
              onChange={(e) => handleChange("max_expansions", e)}
            />
          </div>
        </div>
        <div className="column">
          <div className="label">Tie Breaker</div>
          <div className="content">
            <Form.Control
              type="number"
              min={0.1}
              max={1}
              step={0.1}
              value={execute.tie_breaker || 1}
              onChange={(e) => handleChange("tie_breaker", e)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="label">Minimum Should Match</div>
          <div className="content">
            <Form.Control
              type="number"
              min={0}
              value={execute.minimum_should_match || 1}
              onChange={(e) => handleChange("minimum_should_match", e)}
            />
          </div>
        </div>
        <div className="column">
          <div className="label">&nbsp;</div>
          <div className="content">
            <Form.Check
              type="switch"
              id={`${name}-multimatch-auto_generate_synonyms_phrase_query`}
              checked={
                execute.auto_generate_synonyms_phrase_query !== undefined
                  ? execute.auto_generate_synonyms_phrase_query
                  : true
              }
              value="true"
              disabled={false}
              onChange={(e) =>
                handleChange("auto_generate_synonyms_phrase_query", e.target.checked)
              }
              label="Auto Generate Synonyms"
            />
          </div>
        </div>
        <div className="column">
          <div className="label">&nbsp;</div>
          <div className="content">
            <Form.Check
              type="switch"
              id={`${name}-multimatch-fuzzy_transpositions`}
              checked={
                execute.fuzzy_transpositions !== undefined ? execute.fuzzy_transpositions : true
              }
              value="true"
              disabled={false}
              onChange={(e) => handleChange("fuzzy_transpositions", e.target.checked)}
              label="Fuzzy Transpositions"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
