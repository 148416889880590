import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

function GreaterThan(props) {
  const { execute, handleChange, showDateFormat } = props;

  return (
    <div className={styles.greaterBox}>
      <div className={styles.select}>
        <div className={styles.label}>Operator</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            className="form-control form-control-sm"
            onChange={(e) => handleChange("greaterOperator", e)}
            value={execute.greaterOperator}
          >
            <option value="gt">Greater Than</option>
            <option value="gte">Greater Than or Equal To</option>
          </Form.Control>
        </div>
      </div>
      <div className="column">
        {showDateFormat ? (
          <Form.Control
            type="text"
            className="form-control form-control-sm"
            placeholder="Optional Date Format eg. (yyyy)"
            onChange={(e) => handleChange("format", e)}
            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
            value={execute.min_term_freq}
          />
        ) : null}
      </div>
    </div>
  );
}

export default GreaterThan;
