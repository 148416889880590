import { useCallback } from "react";
import set from "lodash.set";
import Item from "../item";
import External from "./external";
import "./style.scss";

export function doExternalSourceRefs(values) {
  set(values, "refs.require", [
    {
      type: "decorators",
      items: values.decorators,
    },
  ]);

  if (!values.prevReference) values.prevReference = values.reference;

  return values;
}

export default function ListContainer(props) {
  const onValidate = useCallback((values) => {
    const errors = {};
    if (values.type === "REST") {
      if (!values.verb) errors.verb = "VERB must be filled";
      if (!values.url) errors.url = "URL must be filled";
      if (values.url && !values.url.startsWith("http"))
        errors.url = "URL must be valid (including protocol)";
    }
    return errors;
  }, []);

  return (
    <Item
      name="External Source"
      accessor="externalSources"
      onValidate={onValidate}
      onSubmit={doExternalSourceRefs}
    >
      <External />
    </Item>
  );
}
