import { Button, Modal } from "react-bootstrap";

export default function ConfirmUnsavedChanges(props) {
  const { onConfirm, onClose, show } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Exit Without Save</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have unsaved changes in the form you are editing. If you exit you loose oll the changes.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onConfirm}>
          Exit Without Saving
        </Button>
        <Button variant="primary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
