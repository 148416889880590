import Item from "../item";
import List from "./list";
import set from "lodash.set";

import "./style.scss";

export function doListRefs(values) {
  if (values.type === "smartPages") {
    const blocks = new Set();

    values?.elements?.forEach((blockId) => blocks.add(blockId));

    set(values, "refs.require", [
      {
        type: "blocks",
        items: [...blocks],
      },
    ]);
  }

  return values;
}
export default function ListContainer(props) {
  return (
    <Item name="List" accessor="lists" onSubmit={doListRefs}>
      <List />
    </Item>
  );
}
