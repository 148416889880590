import md5 from "md5";

const files = [
  { label: "ok", src: "OK.mp3" },
  { label: "ko", src: "KO.mp3" },
  { label: "done", src: "DONE.mp3" },
  { label: "warning", src: "WARNING.mp3" },
  { label: "start", src: "START.mp3" },
  { label: "bells", src: "BELLS.mp3" },
];

const audioMap = new Map();

export function initAudio() {
  for (const { src, label } of files) {
    const id = md5(src);
    const existing = document.getElementById(id);
    if (!existing) {
      const sound = document.createElement("audio");
      sound.id = id;
      sound.src = src.startsWith("/") || src.startsWith("http") ? src : `/${src}`;
      sound.type = "audio/mpeg";
      document.body.appendChild(sound);
      audioMap.set(label, () => sound.play());
    }
  }
}

export const playSound = (k) => {
  audioMap.has(k) && audioMap.get(k)();
};
