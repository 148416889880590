import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMemItem, useSelectMem } from "../../hooks/useMemoryDB";
import Loading from "../loading";
import Container from "../container";

import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline/lib";

import { useDataSaveItem } from "../../hooks/useData";
import { complexToBasic } from "safe-evaluate-expression";
import moment from "moment";
import isEqual from "lodash.isequal";
import { findCondition } from "../conditionsBox";
import { strip } from "../../db/refManager";

import "./Timeline.scss";
import "./style.scss";

const groupRenderer = ({ group }) => {
  return (
    <div className={`scheduler-group-${group.type}`}>
      <span className="title">{group.title}</span>
    </div>
  );
};

const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  return (
    <div
      {...getItemProps({
        className: `scheduled-item  scheduled-${item.colorGroup} ${
          itemContext.selected ? "selected" : ""
        }`,
        onMouseDown: () => {
          //console.log("on item click", item);
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        style={{
          height: itemContext.dimensions.height,
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

export default function ScheduleDiagram(props) {
  const { itemType, itemId } = props;
  const navigate = useNavigate();
  const saveItem = useDataSaveItem({ notify: false });
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsMap, setItemsMap] = useState();
  const getItem = useGetMemItem(itemType);
  const scheduled = useSelectMem(
    itemType,
    useCallback(
      (i) => {
        if (!i.scheduled) return false;
        if (itemId && i.id !== itemId) return false;

        return true;
      },
      [itemId]
    )
  );

  //console.log(itemType, itemId, scheduled);

  const processGroupsAndItems = useCallback(
    (data) => {
      if (!data) return;

      const groups = [];
      const items = [];
      const itemsMap = new Map();

      const dati = Array.isArray(data) ? data : [data];

      dati.forEach((item) => {
        groups.push({ id: item.id, title: item.title, type: itemType });
        const accessor = item.rule || item.blocks;
        //console.log(accessor);
        accessor.forEach((rule, index) => {
          if (rule.scheduling) {
            rule.scheduling.forEach((schedule) => {
              //const rule = accessor[scheduleIndex];
              itemsMap.set(schedule.id, item.id);

              items.push({
                title: rule.field || rule.title,
                id: schedule.id,
                subId: rule._id,
                group: item.id,
                type: itemType,
                colorGroup: index,
                start_time: moment(schedule.start),
                end_time: moment(schedule.end),
              });
            });
          }
        });
      });

      setGroups((state) => (!isEqual(state, groups) ? groups : state));
      setItems((state) => (!isEqual(state, items) ? items : state));
      setItemsMap(itemsMap);
    },
    [itemType]
  );

  useEffect(() => {
    processGroupsAndItems(scheduled);
  }, [scheduled, processGroupsAndItems]);

  const onItemClick = useCallback(
    (itemId) => {
      navigate(`/${itemType}/${itemsMap.get(itemId)}`);
    },
    [itemType, itemsMap, navigate]
  );

  const onResize = useCallback(
    (itemId, time, edge) => {
      const { group, id, subId } = items.find((item) => item.id === itemId);

      getItem(group, (item) => {
        const accessor = item.rule || item.blocks;
        const rule = accessor.find((i) => i._id === subId);

        const statement = findCondition(id, rule.conditionRaw);
        let [start, end] = strip(statement.values[1].value).split("÷");
        const newDate = new Date(time).toISOString();

        // Move Dates
        if (edge === "move") {
          const diff = moment(newDate).diff(start);
          start = newDate;
          end = moment(end).add(diff).toISOString();
        } else if (edge === "left") start = newDate;
        else end = newDate;

        statement.values[1].value = `"${start}÷${end}"`;
        rule.condition = complexToBasic(rule.conditionRaw);

        // Rule Scheduling
        const scheduling = rule.scheduling.find((s) => s.id === id);
        scheduling.start = start;
        scheduling.end = end;

        // Item Scheduling
        const schedulingItem = item.scheduling.find((s) => s.id === id);
        schedulingItem.start = start;
        schedulingItem.end = end;

        // Item Scheduling Map
        const schedulingMap = new Map();
        accessor.forEach((k, index) => {
          schedulingMap.set(index, k.scheduling);
        });
        item.schedulingMap = Array.from(schedulingMap.entries());

        // Update Local Data
        const data = [...scheduled];
        const index = data.findIndex((d) => d.id === item.id);
        data[index] = item;
        processGroupsAndItems(data);

        // Update Remote Data
        saveItem({
          accessor: itemType,
          Id: item.id,
          prevId: item.id,
          isNew: false,
          data: item,
        });
      });
    },
    [getItem, itemType, items, processGroupsAndItems, saveItem, scheduled]
  );

  const onMove = useCallback(
    (itemId, time, group) => {
      onResize(itemId, time, "move");
    },
    [onResize]
  );

  if (items.length === 0)
    return (
      <Container title="Scheduling" foldable={false}>
        <Loading message="NO SCHEDULING" height="500px" width="100%" />
      </Container>
    );

  return (
    <div>
      <div className="diagram-container">
        <Timeline
          groups={groups}
          items={items}
          stackItems={true}
          canChangeGroup={false}
          defaultTimeStart={moment().add(-1, "month")}
          defaultTimeEnd={moment().add(1, "month")}
          showCursorLine
          canMove={true}
          canResize={true}
          groupRenderer={groupRenderer}
          itemRenderer={itemRenderer}
          itemHeightRatio={0.8}
          onItemResize={onResize}
          onItemMove={onMove}
          onItemDoubleClick={onItemClick}
        >
          <TimelineMarkers>
            <TodayMarker interval={2000}>
              {/* custom renderer for this marker */}
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: "deeppink",
                  width: "2px",
                };
                return <div style={customStyles} onClick={() => console.log("CLICK")} />;
              }}
            </TodayMarker>
          </TimelineMarkers>
          <TimelineHeaders className="sticky">
            <SidebarHeader>
              {({ getRootProps }) => {
                return <div {...getRootProps()}></div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      </div>
    </div>
  );
}

export function ScheduleRules(props) {
  return <ScheduleDiagram itemType="rules" />;
}

export function ScheduleBlocks(props) {
  return <ScheduleDiagram itemType="blocks" />;
}
