import { useCallback, useMemo, useEffect, useState, useRef, cloneElement } from "react";
import { useDataItemsKV } from "../../../hooks/useDataRef";
import useDragAndDrop, { DragAndDrop } from "../../../hooks/useDragAndDrop";
import Container from "../../container";
import SelectAll from "../selectAll";
import styles from "./styles.module.scss";

export default function SelectAllMultiple(props) {
  const [values, setValues] = useState(props.values ?? []);
  const [filters, setFilters] = useState(props.filters);
  const [data, setData] = useState();
  const getItems = useDataItemsKV(props.accessor);

  const value = useMemo(() => {}, []);
  const handleChange = useCallback(() => {}, []);

  const updateValues = useCallback(
    (v) => {
      setValues(v);
      setFilters({ ...filters, exclude: v });
    },
    [filters]
  );

  const handleDrag = useCallback(
    (v) => {
      updateValues(v);
      if (props.handleChange) props.handleChange(v);
    },
    [props, updateValues]
  );

  const handleAdd = useCallback(
    (e) => {
      if (!values.includes(e)) {
        const newValues = [e, ...values];
        updateValues(newValues);
        if (props.handleChange) props.handleChange(newValues);
      }
    },
    [props, updateValues, values]
  );

  const handleRemove = useCallback(
    (id) => {
      if (id) {
        const index = values.findIndex((k) => k === id);

        if (index > -1) {
          const newValues = [...values];
          newValues.splice(index, 1);

          updateValues(newValues);
          if (props.handleChange) props.handleChange(newValues);
        }
      }
    },
    [props, updateValues, values]
  );

  useEffect(() => {
    if (values) {
      getItems({ ids: values, sort: false }, (data) => {
        const ret = [];
        values.forEach((id) => {
          const item = data.find((k) => k.key === id);
          ret.push(item);
        });

        setData(ret);
      });
    }
  }, [getItems, values]);

  useEffect(() => {
    setFilters({
      ...props.filters,
      exclude: [...new Set([...(props.filters?.exclude ?? []), ...values])],
    });
  }, [props.filters, values]);

  return (
    <div className={styles.container}>
      {!props.disableAdd && (
        <div className={styles.select}>
          <SelectAll
            {...props}
            filters={filters}
            haveButton={true}
            value={value}
            handleAdd={handleAdd}
            handleChange={handleChange}
          />
        </div>
      )}

      <div className={styles.box}>
        <DragAndDrop>
          {data &&
            data.map(({ key, value }, index) => (
              <Item
                index={index}
                key={key}
                chiave={key}
                value={value}
                data={values}
                editor={props.editor}
                handleRemove={handleRemove}
                handleDrag={handleDrag}
              />
            ))}
        </DragAndDrop>
      </div>
    </div>
  );
}

function Item({ index, chiave, value, data, editor, handleRemove, handleDrag }) {
  const ref = useRef();
  const [folded, setFolded] = useState(true);

  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    chiave,
    index,
    `item-multiple`, //${chiave}
    data,
    (_, data) => {
      handleDrag(data);
    }
  );

  const stile = useMemo(() => {
    const style = { opacity, zIndex: 999 };

    if (highlighted && !hovered) {
      style.borderColor = "#222";

      return style;
    }
    if (hovered) {
      const color = "#333";
      style.borderColor = color;
      style.color = color;
      style.opacity = 0.6;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const editore = useMemo(() => (editor ? cloneElement(editor, { index }) : null), [editor, index]);

  return (
    <Container
      title={<span className={styles.itemTitle}>{value}</span>}
      className={styles.item}
      key={chiave}
      ref={ref}
      style={stile}
      noMarginLast={true}
      isButton={true}
      extra={
        !editore && (
          <div aria-hidden="true" onClick={() => handleRemove(chiave)} className={styles.delete}>
            &times;
          </div>
        )
      }
      folded={folded}
      onFold={setFolded}
      foldable={editore}
    >
      {editore}
    </Container>
  );

  // return (
  //   <div className={styles.item} key={chiave} ref={ref} style={stile}>
  //     {value}
  //     <div aria-hidden="true" onClick={() => handleRemove(chiave)} className={styles.delete}>
  //       &times;
  //     </div>
  //   </div>
  // );
}
