export default function validation(values) {
  const errors = {};

  if (!values.pack?.type || !values.pack?.id) errors.pack = "Please Specify a Pack";

  if (values.cache?.active) {
    if (!values.cache?.period || !values.cache?.unit) {
      errors.cache = "You Must Specify a Valide TimeRange for Cache";
    }
  }

  if (values.fetchUserMetadata && !values.pipelines?.dynamic?.length) {
    errors.pipelines = "You are Asking to Fetch User Metadata without Dynamic Pipelines";
  }

  if (!values.pipelines?.static?.length && !values.pipelines?.dynamic?.length) {
    errors.pipelines = "You must specify at least one pipeline";
  }

  if (!values.paramsId) {
    errors.pack = "Params Must be specified";
    errors.paramsId = "Params Must be specified";
  }

  let haveOut = false;
  Object.entries(values.injects ?? {}).forEach(([chiave, { active, key }]) => {
    if (active) haveOut = true;
    if (["ids", "tags", "segments", "fingerprint"].includes(chiave) && !key) haveOut = false;
  });

  if (!haveOut) errors.injects = "You Must Specify some Output";

  return errors;
}
