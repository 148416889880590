import { Fragment, useMemo } from "react";
import { useDataSettings } from "../../hooks/useData";

import { Form, Row, Col } from "react-bootstrap";

import RotaryKnob from "../rotaryKnob";
import { useFormikContext } from "formik";
import usePermissions from "../../hooks/usePermissions";
import OutPutField from "./outputField";
import Help from "../help";
import capitalize from "lodash.capitalize";
import stubs from "../../utils/stubs";

const selectStyle = { width: "366px" };
export default function Collaborative(props) {
  const { metadata, updateMetadata, contentType, uniqueId, rootNodeName } = props;
  const settings = useDataSettings((settings) => settings?.collaborative?.[contentType] ?? {});

  const { expert } = usePermissions();
  const { errors } = useFormikContext() || stubs.formik;

  const defaults = useMemo(() => {
    const type = settings[metadata?.collaborative?.userContentType];
    if (type) {
      const event = type[metadata.collaborative.event];
      if (event) {
        return event;
      }
    }
  }, [metadata, settings]);

  return (
    <Fragment>
      {metadata?.collaborative?.conversionMode === "metapack" || defaults ? (
        <Fragment>
          <Row className="rowMarginTop">
            <Col sm="1" className="columnLabel"></Col>
            <Col sm="10">
              <div className="rowContent">
                <Form.Check
                  type="switch"
                  value="true"
                  checked={metadata?.collaborative?.keepFrequent ?? false}
                  onChange={(e) => updateMetadata("collaborative.keepFrequent", e.target.checked)}
                  id="zero-keepFrequent"
                  name="zero-keepFrequent"
                  label="Keep Frequent"
                />
              </div>
            </Col>
          </Row>
          <Row className="rowMarginTop">
            <Col sm="1" className="columnLabel">
              <span className="label">Output</span>
              <Help code={`meta-collaborative-output-metadata`} />
            </Col>
            <Col sm="10">
              <div className="rowContent">
                <OutPutField
                  data={metadata?.collaborative?.output}
                  defaultData={defaults?.output}
                  realm="collaborative" //{"collaborative"}
                  catalog={contentType} //metadata.collaborative.userContentType
                  updateMetadataupdateMetadata={(v) => updateMetadata("collaborative.output", v)}
                />
              </div>
            </Col>
          </Row>
          <Row className="rowMarginTop">
            <Col sm="1" className="columnLabel">
              <span className="label">Options</span>
              <Help code={`meta-collaborative-options`} />
            </Col>
            <Col sm="10">
              <div className="collaborative-extra">
                <RotaryKnob
                  value={metadata.collaborative.minDocCount || defaults?.minDocs?.value}
                  min={defaults?.minDocs?.min}
                  max={defaults?.minDocs?.max}
                  step={1}
                  label="Min Rate"
                  labelPosition="bottom"
                  skin="s10"
                  onChange={(v) => updateMetadata("collaborative.minDocCount", v)}
                  className="collaborative-rotary"
                />
                <RotaryKnob
                  value={metadata.collaborative.numElements || defaults?.numElements?.value}
                  min={defaults?.numElements?.min}
                  max={defaults?.numElements?.max}
                  step={1}
                  label="Results"
                  labelPosition="bottom"
                  skin="s10"
                  onChange={(v) => updateMetadata("collaborative.numElements", v)}
                  className="collaborative-rotary"
                />
                {expert.includes("metadata-collaborative-user-similar") ? (
                  <Form.Check
                    type="switch"
                    id={`collaborative-switch-${uniqueId}`}
                    checked={metadata.collaborative.perUserBasis === true}
                    value="true"
                    onChange={(e) => updateMetadata("collaborative.perUserBasis", e.target.checked)}
                    label="Based on Similar Users"
                  />
                ) : null}
                {metadata.collaborative.perUserBasis === true ? (
                  <div className="fingerprintTypeSelect">
                    <div className="label">Similar Users Fingerprint Type</div>
                    <Form.Control
                      as="select"
                      style={selectStyle}
                      value={metadata.collaborative.perUserBasisType || ""}
                      onChange={(e) =>
                        updateMetadata("collaborative.perUserBasisType", e.target.value)
                      }
                      isInvalid={errors?.[rootNodeName]?.collaborative_perUserBasisType}
                    >
                      <option></option>
                      {defaults?.perUserBasisTypes?.map?.((type, i) => (
                        <option key={`${type}-${i}`} value={type}>
                          {capitalize(type)}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
