import moment from "moment";

export default function cacheLabel(value, unit = "seconds", decimalSeconds = false) {
  if (value) {
    const duration = moment.duration(value, unit);

    const minutes = duration.minutes();
    const hours = duration.hours();
    const days = duration.days();
    const seconds = decimalSeconds
      ? (duration.asSeconds() - minutes * 60).toFixed(2)
      : duration.seconds();
    const months = duration.months();

    return `Cache ${months ? `${months} months ` : ""}${days ? `${days} days ` : ""}${
      hours ? `${hours} hrs ` : ""
    }${minutes ? `${minutes} min ` : ""}${seconds ? `${seconds} sec` : ""}`;
  }

  return "Cache OFF";
}
