function getItemStub(write = false) {
  return {
    setItemErrors() {},
    itemId: "fake-id",
    permissions: {
      expert: { includes: () => true },
      read: { includes: () => true },
      write: { includes: () => write },
      delete: { includes: () => false },
    },
    itemErrors: {},
  };
}

export default {
  formik: {
    setFieldValue() {},
    validateForm() {},
    handleBlur() {},
    errors: {},
    values: {},
  },
  item: getItemStub(),
  itemWrite: getItemStub(true),
  itemErrors: {},
};
