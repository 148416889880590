import { useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import Switch from "react-bootstrap-switch";

import Container from "../container";
import ConversionsSelect from "../selects/selectConversions";

import styles from "./styles.module.scss";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css";
import "./global.scss";

export default function ConversionChooser() {
  const { setFieldValue, values, errors } = useFormikContext();
  //const conversions = useDexieTable("conversions");

  const handleChangeConversions = useCallback(
    (value) => setFieldValue("conversions", value),
    [setFieldValue]
  );

  const header = useMemo(() => {
    return (
      <div className={styles.conversionHeader}>
        <div className={styles.conversionSelect}>
          <ConversionsSelect
            value={values.conversions}
            onChange={handleChangeConversions}
            forceSearch={true}
            forceRecommended={true}
          />
        </div>
        <div className={styles.minOccurrences}>
          <span className={styles.label}>Min Occurrences</span>
          <div className={styles.minOccurrencesInput}>
            <Form.Control
              //
              type="number"
              min={0}
              step={1}
              value={values.minOccurrences || 0}
              onChange={(e) => setFieldValue("minOccurrences", e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }, [handleChangeConversions, setFieldValue, values.conversions, values.minOccurrences]);

  const extra = useMemo(() => {
    return (
      <Switch
        bsSize="small"
        value={values.order === "desc" ? true : false}
        onText="Less"
        onColor="warning"
        offText="Most"
        offColor="danger"
        onChange={(elm, state) => setFieldValue("order", state ? "desc" : "asc")}
      />
    );
  }, [setFieldValue, values.order]);

  return (
    <Container
      title="Conversions"
      help="conversion-pack-conversions"
      color="#1f0037"
      coloredBars={false}
      foldable={false}
      folded={true}
      header={header}
      extra={extra}
      warning={errors.conversion}
    />
  );
}

//<>Conversion | Order</>
