import { useCallback, useMemo, useState, useEffect } from "react";
import { useDataSettings } from "../../hooks/useData";
import { useFormikContext } from "formik";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import refManager from "../../hooks/useDataRef";
import { Row, Col, Form } from "react-bootstrap";
import { DragAndDrop } from "../../hooks/useDragAndDrop";
import isEqual from "lodash.isequal";

import Container from "../container";
import ContentType from "../contentType";
import Rule from "../query/rule";
import SelectAll from "../selects/selectAll";

import "../query/style.scss";
import "./preset.scss";
import styles from "./preset.module.scss";

import { colors } from "../query/query";
const emptyArray = [];
const emptyObj = {};
const selectStyle = { width: "400px" };

export default function Preset(props) {
  const { values, errors, setFieldValue } = useFormikContext();
  const queryClauses = useDataSettings("queryClauses") || emptyArray;
  const getRule = useGetMemItem("rules");
  const [filters, setFilters] = useState(emptyObj);

  const clause = useMemo(() => {
    if (values.clause && queryClauses) {
      const k = queryClauses.find((c) => c.accessor === values.clause);

      return k;
    }

    return null;
  }, [values.clause, queryClauses]);

  const onChangeClause = useCallback(
    (e) => {
      const value = e?.target?.value || e;

      setFieldValue("clause", value);
      setFieldValue("rules", undefined);
    },
    [setFieldValue]
  );

  const onAddRule = useCallback(
    (id) => {
      setFieldValue("rules", [...(values?.rules ?? []), { id }]);
    },
    [setFieldValue, values.rules]
  );

  const alreadyIn = useMemo(() => values?.rules?.map((r) => r.id), [values]);
  const onMetadataChange = useCallback(
    (id, value) => {
      getRule(id, (rule) => {
        if (!isEqual(rule.metadata, value)) {
          setImmediate(() => setFieldValue(`metadataOverrides[${id}]`, value));
        }
      });
    },
    [getRule, setFieldValue]
  );
  const handleCleanRule = useCallback(
    (id) => {
      getRule(id, (rule) => {
        const index = values.rules.findIndex((r) => r.id === id);
        setFieldValue(`rules[${index}].overides`, undefined);
        setFieldValue(`metadataOverrides[${id}]`, undefined);
      });
    },
    [getRule, setFieldValue, values]
  );

  const ruleOverrides = useMemo(() => {
    const over = new Set(Object.keys(values.metadataOverrides ?? {}));
    return over;
  }, [values.metadataOverrides]);

  const foldable = useMemo(() => {
    return !values.contentType || !clause;
  }, [clause, values.contentType]);

  const settingsHeader = useMemo(() => {
    if (!values.contentType) return null;
    return <div className={styles.contentTypeLabel}>{values.contentType}</div>;
  }, [values.contentType]);

  useEffect(() => {
    const data = refManager.getAll("rulesPresets", { id: values.id });
    const filters = {
      exclude: alreadyIn,
      catalogs: [values.contentType],
      clauses: [values.clause],
    };
    if (data[0]?.isInFallback) {
      filters.canBeFallback = true;
    }

    setFilters(filters);
  }, [alreadyIn, values.clause, values.contentType, values.id]);

  return (
    <div className={styles.container}>
      <Container
        title="Settings"
        help="preset-settings"
        color="#06141a"
        foldable={foldable}
        folded={!foldable}
        header={settingsHeader}
        warning={errors.settings}
      >
        <ContentType
          rootNode={values}
          fixedValue={values.contentType}
          onChange={(value) => {
            setFieldValue("contentType", value);
          }}
        />
        {!clause && (
          <Row>
            <Col sm="1">Clause</Col>
            <Col sm="4">
              <Form.Control
                as="select"
                onChange={onChangeClause}
                style={selectStyle}
                isInvalid={errors.clause}
              >
                <option></option>
                {queryClauses.map((c) => (
                  <option key={c.accessor} value={c.accessor}>
                    {c.label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        )}
      </Container>

      {clause ? (
        <Container
          foldable={true}
          coloredBars={false}
          color={colors[clause.accessor]}
          title={clause.label}
          help="preset-container"
          warningMessage={errors.rules}
        >
          <>
            <div className={styles.ruleSelect}>
              <div className={styles.label}>Rule</div>
              {values.clause && values.contentType ? (
                <>
                  <SelectAll
                    accessor="rules"
                    filters={filters}
                    style={selectStyle}
                    haveButton={true}
                    handleAdd={onAddRule}
                  />
                  {/* <RuleSelect
                    name="rules"
                    filterClause={values.clause}
                    contentType={values.contentType}
                    alreadyIn={alreadyIn}
                    style={selectStyle}
                  /> */}
                </>
              ) : null}
            </div>
            <DragAndDrop>
              {(values.rules || []).map((r, i) => {
                const rule = getRule(r.id);
                const over = values.metadataOverrides?.[r.id] || {};
                const metadata = Object.keys(over).length > 0 ? over : rule?.metadata;

                return (
                  <Rule
                    key={`rule-${r.id}`}
                    accessor={clause.accessor}
                    dataAccessor="rules"
                    index={i}
                    metadata={metadata}
                    id={r.id}
                    rule={rule}
                    haveMetaOverrides={ruleOverrides.has(r.id)}
                    handleCleanRule={(v) => handleCleanRule(r.id, v)}
                    onMetadataChange={(v) => onMetadataChange(r.id, v)}
                  />
                );
              })}
            </DragAndDrop>
          </>
        </Container>
      ) : null}
    </div>
  );

  // return (
  //   <div className={styles.container}>
  //     <ContentType
  //       rootNode={values}
  //       fixedValue={values.contentType}
  //       onChange={(value) => {
  //         setFieldValue("contentType", value);
  //       }}
  //     />
  //     {!clause && (
  //       <Row>
  //         <Col sm="2">Clause</Col>
  //         <Col sm="4">
  //           <Form.Control as="select"  onChange={onChangeClause}>
  //             <option></option>
  //             {queryClauses.map((c) => (
  //               <option key={c.accessor} value={c.accessor}>
  //                 {c.label}
  //               </option>
  //             ))}
  //           </Form.Control>
  //         </Col>
  //       </Row>
  //     )}

  //     {clause && (
  //       <Fragment>
  //         <Row>
  //           <Col sm="12">
  //             <div
  //               className={`query-statement query-${clause.accessor}`}
  //               style={{ paddingBottom: "0px" }}
  //             >
  //               <h5 onDoubleClick={() => onChangeClause(null)} style={{ cursor: "pointer" }}>
  //                 {clause.label}
  //               </h5>
  //               <Row>
  //                 <Col sm="12">
  //                   <div className={styles.ruleSelect}>
  //                     {values.clause && values.contentType ? (
  //                       <RuleSelect
  //                         name="rules"
  //                         filterClause={values.clause}
  //                         contentType={values.contentType}
  //                         alreadyIn={alreadyIn}
  //                         style={{ width: "466px" }}
  //                       />
  //                     ) : null}
  //                   </div>
  //                 </Col>
  //               </Row>
  //               <div className="query-rules-container" style={{ marginTop: "20px" }}>
  //                 <DragAndDrop>
  //                   {(values.rules || []).map((r, i) => {
  //                     const rule = getRule(r.id);

  //                     return (
  //                       <Rule
  //                         key={`rule-${r.id}`}
  //                         accessor={clause.accessor}
  //                         dataAccessor="rules"
  //                         index={i}
  //                         metadata={(values.metadataOverrides || {})[r.id]}
  //                         id={r.id}
  //                         rule={rule}
  //                         handleCleanRule={() => {}}
  //                         onMetadataChange={(value) => {}}
  //                       />
  //                     );
  //                   })}
  //                 </DragAndDrop>
  //               </div>
  //             </div>
  //           </Col>
  //         </Row>
  //       </Fragment>
  //     )}
  //   </div>
  // );
}
