import { useState, useMemo, useCallback } from "react";
import moment from "moment";

import { Dropdown } from "react-bootstrap";
import Select from "../selectWithSearch";

function ValuesSelect(props) {
  const {
    value,
    selectOptions,
    handleChange,
    disabled = false,
    errored,
    excludes,
    style,
    className,
  } = props;
  const [filter, setFilter] = useState();

  const values = useMemo(() => {
    if (!selectOptions) return null;

    const filtered = selectOptions
      .filter((v) => {
        v = v.id ? v.id : v;
        if ((excludes || []).includes(v)) return false;
        else return filter ? String(v).toLowerCase().includes(filter) : true;
      })
      .map((item) => {
        let label = item.label ? item.label : item;
        let value = item.id ? item.id : item;

        if (!isNaN(label)) return { label: label.toString(), value };

        const d = moment(label);
        return d.isValid()
          ? { label: d.format("D MMMM YYYY"), value }
          : { label: label.toString(), value };
      });
    if (filtered.length === 0) return <div className="select-no-results">No Results...</div>;

    return filtered.map((o, i) => (
      <Dropdown.Item
        eventKey={o.value}
        key={`${o.value}-${i}`}
        value={o.value}
        className="select-item-result"
      >
        {o.label}
      </Dropdown.Item>
    ));
  }, [excludes, filter, selectOptions]);

  //const canFilter = useMemo(() => (selectOptions ? selectOptions.length : 0), [selectOptions]);
  const onFilter = useCallback((value) => setFilter(value), []);
  const onSelect = useCallback(
    (value) => {
      handleChange(value);
    },
    [handleChange]
  );

  return (
    <Select
      value={value}
      disabled={disabled}
      canFilter={true}
      errored={errored}
      onFilter={onFilter}
      onSelect={onSelect}
      style={style}
      className={className}
    >
      {values}
    </Select>
  );
}

export default ValuesSelect;
