/* eslint-disable default-case */
import set from "lodash.set";
import Item from "../item";
import MetaPack from "./metaPack";
import validation from "./validation";

export function doMetaPackRefs(values) {
  const refArray = [];
  let type;

  switch (values.pack?.type) {
    case "list": {
      type = "lists";
      break;
    }
    case "conversions": {
      type = "conversionPacks";
      break;
    }
    case "content": {
      type = "contentPacks";
      break;
    }
    case "query": {
      type = "queries";
      break;
    }
  }

  if (type && values.pack?.id) {
    refArray.push({
      type,
      items: [values.pack.id],
    });
  }

  // Pipelines
  const { static: statici, dynamic } = values.pipelines ?? {};
  let pipelines = statici ? statici.slice() : [];
  (dynamic ?? []).forEach(({ id }) => pipelines.push(id));

  if (pipelines.length > 0) {
    refArray.push({
      type: "pipelines",
      items: pipelines,
    });
  }

  set(values, "refs.require", refArray);
  return values;
}

export default function MetaPackContainer() {
  return (
    <Item
      name="Meta Pack"
      accessor="metaPacks"
      onSubmit={doMetaPackRefs}
      onValidate={validation}
      NameAndDescriptionColor="#031727"
    >
      <MetaPack />
    </Item>
  );
}
