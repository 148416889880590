import { useState, useCallback, useEffect, forwardRef } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useUpdateMetadata } from "../../hooks/useUpdateMetadata";
import { useItemContext } from "../../hooks/useItem";

import stubs from "../../utils/stubs";

const OutputField = forwardRef((props, ref) => {
  const {
    data,
    defaultData,
    realm,
    catalog,
    updateMetadata,
    disableEdit,
    style,
    width = "366px",
  } = props;

  const [outputField, setOutputField] = useState(data || defaultData);
  const { itemId } = useItemContext() || stubs.item;
  const updateFieldMetadata = useUpdateMetadata();
  const [edit, setEdit] = useState(false);

  const updateOutput = useCallback(
    (e) => {
      const value = e.target.value.replace(" ", "_").toLowerCase();
      setOutputField(value);
    },
    [setOutputField]
  );

  const updateField = useCallback(
    (field) => {
      updateFieldMetadata({
        owner: itemId,
        nextId: field,
        types: ["string"],
        realm,
        catalog,
      });
      if (field !== data) updateMetadata(field);
    },
    [catalog, data, itemId, realm, updateFieldMetadata, updateMetadata]
  );

  if (ref && ref.current) ref.current.updateField = updateField;

  const setOutput = useCallback(() => {
    if (outputField) {
      updateField(outputField);
      setEdit(false);
    }
  }, [outputField, updateField]);

  useEffect(() => {
    if (data?.[realm]) updateField(outputField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog]);

  useEffect(() => {
    if (!data && data?.[realm] && defaultData) {
      updateField(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={style}>
      {edit ? (
        <span className="collaborative-output-edit">
          <Form.Control
            type="text"
            placeholder={defaultData}
            style={{ width }}
            value={outputField}
            onChange={updateOutput}
            className="input"
          />
          <Button size="sm" variant="outline-primary" className="btn" onClick={setOutput}>
            OK
          </Button>
        </span>
      ) : (
        <span className="collaborative-output-edit">
          <span className="field" onClick={() => (disableEdit ? null : setEdit((state) => !state))}>
            {outputField}
          </span>
          {!disableEdit && (
            <FontAwesomeIcon
              className="icon"
              icon={faEdit}
              onClick={() => setEdit((state) => !state)}
            />
          )}
        </span>
      )}
    </div>
  );
});

export default OutputField;
