import { useMemo } from "react";

import Container from "../../../../container";
import Timeline from "./timeline";
import Searches from "./searches";
import Catalog from "./catalogs";
import Positions from "./positions";

import styles from "./styles.module.scss";

export default function Display(props) {
  const data = props.data;
  const framed = useMemo(() => data?.conversions?.length > 1, [data?.conversions]);

  if (!data) return null;
  return (
    <div className={styles.conversionsBox}>
      {data?.conversions?.map((conversion) =>
        conversion.count > 0 ? (
          <Conversion
            key={conversion.id}
            data={conversion}
            framed={framed}
            compare={data.compare}
          />
        ) : null
      )}
    </div>
  );
}

function Conversion(props) {
  const { data, framed, compare } = props;

  const content = useMemo(() => {
    return (
      <div className={styles.conversionBox}>
        <Timeline
          label={data.id}
          data={data.timeline}
          userCount={data.userCount}
          count={data.count}
          userDiffPercentage={data.userDiffPercentage}
          compare={compare}
        />
        <Searches data={data.compactSearches} compare={compare} title="Searches Compacted [BETA]" />
        <Searches data={data.searches} compare={compare} title="Searches Raw" />

        <Catalog data={data.catalogs} compare={compare} />
        <Positions blocks={data.blockPos} items={data.itemPos} compare={compare} />
      </div>
    );
  }, [
    compare,
    data.blockPos,
    data.catalogs,
    data.compactSearches,
    data.count,
    data.itemPos,
    data.id,
    data.searches,
    data.timeline,
    data.userCount,
    data.userDiffPercentage,
  ]);

  return framed ? (
    <Container
      title={<span className={styles.conversionTitle}>{data.id}</span>}
      coloredBars={false}
      color="#2a3241"
    >
      {content}
    </Container>
  ) : (
    content
  );
}
