import { Fragment, useState, useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";

import SelectAll from "../selects/selectAll";
import RotaryKnob from "../rotaryKnob";
import cloneDeep from "lodash.clonedeep";
import styles from "./styles.module.scss";

export default function Injects(props) {
  const { block, handleUpdate, contentType, excludes, info } = props;
  const max = useMemo(() => block.results?.max, [block.results]);
  const length = useMemo(() => {
    const injs =
      block.results?.inject?.reduce((acc, inj) => {
        return acc + (inj.numResults - 1 || 0);
      }, 0) || 0;

    return max - injs;
  }, [block.results, max]);

  const fillState = useCallback(
    (data) => {
      const base = new Array(length || 0).fill({});
      const fill = (data || block?.results?.inject || []).sort((a, b) => {
        return a.position - b.position;
      });
      let span = 0;

      fill.forEach((f, injectIndex) => {
        base[f.position - 1 - span] = { ...f, injectIndex };
        span += (f.numResults || 1) - 1;
      });

      return base.filter((b) => b);
    },
    [block.results, length]
  );

  const dispatch = useCallback(
    (action) => {
      const blockInjects = cloneDeep(block.results?.inject || []);

      const data = action.data;
      const index = data.injectIndex > -1 ? data.injectIndex : blockInjects.length;

      if (data.position > -1) blockInjects[index] = { ...data, injectIndex: index };
      else blockInjects.splice(index, 1);

      handleUpdate(blockInjects);
    },
    [block.results, handleUpdate]
  );

  const injs = useMemo(() => fillState(), [fillState]);

  const calcSpan = useCallback(
    (index, box) => {
      const span = box.numResults - 1 || 0;
      const length = injs.length;

      for (let k = index + 1; k < length; k++) {
        if (injs[k].hasOwnProperty("position")) {
          return k + span - index;
        }
      }

      const calc = length - index + span;
      return calc <= max ? calc : max;
    },
    [injs, max]
  );

  let skip = 0;

  return (
    <div className={styles.injectBox}>
      {injs.map((box, index) => {
        const prev = injs[index - 1];
        skip += prev?.numResults ? prev.numResults - 1 : 0;
        const label = index + 1 + skip;

        return (
          <Box
            key={`inject-${index}`}
            box={box}
            info={info}
            index={index}
            injectIndex={box.injectIndex}
            label={label}
            dispatch={dispatch}
            maxResults={calcSpan(index, box)}
            contentType={contentType}
            excludes={excludes}
          />
        );
      })}
    </div>
  );
}

const labelsFilter = ["groupMain", "groupSub"];

const selectStyle = { width: "282px", marginTop: "5px" };
const inputIdStyle = { marginTop: "10px" };
const resultsKnobStyle = { margin: "auto", marginTop: "10px" };
const startFromKnobStyle = { margin: "auto", marginTop: "10px", marginLeft: "20px" };

function Box(props) {
  const { box, index, injectIndex, label, dispatch, contentType, maxResults, excludes, info } =
    props;
  const [open, setOpen] = useState(false);
  const mode = useMemo(() => {
    if (box.hasOwnProperty("contentId")) return "Content Id";
    if (box.hasOwnProperty("blockId")) return "Block";
    if (box.hasOwnProperty("position")) return "Random";
    return "Auto";
  }, [box]);

  const contentId = useMemo(() => box?.contentId || "", [box]);
  const blockId = useMemo(() => box?.blockId || "", [box]);
  const numResults = useMemo(() => box?.numResults || 1, [box]);
  const startFrom = useMemo(() => box?.startFrom || 1, [box]);

  const filled = useMemo(() => mode !== "Auto", [mode]);

  const doUpdate = useCallback(
    ({ mod, id }) => {
      const _mode = mod || mode;
      const _id = id || contentId;
      const position = label; //index + 1;

      let data = {};

      if (_mode === "Content Id") data = { position, contentId: _id }; //&& _id
      if (_mode === "Random") data = { position };
      if (_mode === "Block") data = { position, blockId: "" };

      dispatch({ index, data: { ...data, injectIndex }, caller: "doUpdate" });
    },
    [contentId, dispatch, index, injectIndex, label, mode]
  );

  const updateBlock = useCallback(
    (id, num, start) => {
      const theId = id || blockId || "";
      const theResults = num || numResults;
      const theStart = start || startFrom;

      dispatch({
        index,
        data: {
          injectIndex,
          position: label, //index + 1,
          blockId: theId,
          numResults: theResults,
          startFrom: theStart,
        },
        caller: "updateBlock",
      });
    },
    [blockId, dispatch, index, injectIndex, label, numResults, startFrom]
  );

  const onModeChange = useCallback(
    (mod) => {
      doUpdate({ mod });
    },
    [doUpdate]
  );

  const onIdChange = useCallback(
    (e) => {
      const id = e.target.value;
      doUpdate({ id });
    },
    [doUpdate]
  );

  const extendLabel = useMemo(() => {
    let ret = [];
    Array(numResults)
      .fill(0)
      .forEach((_, i) => {
        ret.push(label + i);
      });

    return ret.join(" - ");
  }, [numResults, label]);

  const blockFilters = useMemo(() => {
    const loopIds = info.loopIds ?? [];

    const filters = {
      catalogs: [contentType],
      exclude: [...new Set([...excludes, ...loopIds])],
    };

    if (info.restricted) {
      filters.canBeFallback = true;
    }

    return filters;
  }, [contentType, excludes, info.loopIds, info.restricted]);

  const handleChangeBlock = useCallback((id) => updateBlock(id), [updateBlock]);

  const toggle = useCallback(() => {
    if (!open) {
      setOpen(true);
    } else {
      if (!["Block", "Content Id"].includes(mode)) setOpen(false);
      else if ((mode === "Block" && blockId) || (mode === "Content Id" && contentId)) {
        setOpen(false);
      }
    }
  }, [blockId, contentId, mode, open]);

  return (
    <div
      className={`inject-box ${open ? "inject-box-open" : ""} ${
        mode === "Content Id" && open ? "inject-box-open-extended" : ""
      } ${mode === "Block" && open ? "inject-box-open-block-select" : ""}  ${
        filled ? (mode === "Block" ? "inject-box-filled-block" : "inject-box-filled") : ""
      }   ${numResults > 1 ? "inject-box-extra-results" : ""}`}
      key={`inject-box-${index}`}
    >
      <div
        className={`inject-box-position ${open ? "box-position-open" : "box-position-close"}  ${
          numResults > 1 ? `box-extra-results` : ""
        }`}
        onClick={toggle}
      >
        {numResults < 2 ? (
          label
        ) : (
          <span className={`extra-results ${open ? "extra-results-open" : "extra-results-close"}`}>
            {extendLabel}
          </span>
        )}
      </div>

      {open ? (
        <Fragment>
          <Form.Control as="select" onChange={(e) => onModeChange(e.target.value)} value={mode}>
            <option>Auto</option>
            <option>Random</option>
            <option>Block</option>
            <option>Content Id</option>
          </Form.Control>
          {mode === "Content Id" ? (
            <Fragment>
              <Form.Control
                type="text"
                placeholder="Content Id"
                value={contentId}
                onChange={onIdChange}
                isInvalid={!contentId}
                style={inputIdStyle}
              />
            </Fragment>
          ) : null}
          {mode === "Block" ? (
            <Fragment>
              <SelectAll
                accessor="blocks"
                filters={blockFilters}
                labelsFilter={labelsFilter}
                value={blockId}
                isInvalid={!blockId}
                handleChange={handleChangeBlock}
                style={selectStyle}
              />

              <div className="blockKnobs">
                <RotaryKnob
                  value={numResults}
                  min={1}
                  max={maxResults}
                  step={1}
                  label="Num of Items"
                  labelPosition="top"
                  skin="s10"
                  onChange={(num) => updateBlock(null, num)}
                  style={resultsKnobStyle}
                />
                <RotaryKnob
                  value={startFrom}
                  min={1}
                  max={maxResults - 1 ? maxResults - 1 : 1}
                  step={1}
                  label="Start From"
                  labelPosition="top"
                  skin="s10"
                  onChange={(num) => updateBlock(null, null, num)}
                  style={startFromKnobStyle}
                />
                <div className="max-row">
                  Max:
                  <span className="maxBadge" onClick={() => updateBlock(null, maxResults)}>
                    {maxResults}
                  </span>
                </div>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
