import { faArrowAltCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";

export default function DiffIcon(props) {
  if (!props.data) return null;

  return (
    <span
      className={`${styles.diffIcon} ${props.data >= 0 ? styles.up : styles.down}`}
      style={props.style}
    >
      <FontAwesomeIcon icon={props.data >= 0 ? faArrowAltCircleUp : faArrowCircleDown} />
      <span className={styles.diff}>{Math.abs(props.data).toFixed(1)}%</span>
    </span>
  );
}
