import { useCallback, useEffect, useState, useRef } from "react";
import isEqual from "lodash.isequal";

import { db } from "../db";

window.memDB = db;

export default db;

export function useObserveDbTable(table, cb) {
  const subRef = useRef();

  useEffect(() => {
    if (!subRef.current) {
      subRef.current = db.observe(table, () => cb());
    }

    return () => db.forget(table, subRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useGetMemItem(table, id, fromId) {
  const dataRef = useRef();
  const idRef = useRef(id);
  const [data, setData] = useState(db.get(table, id));
  useObserveDbTable(table, () => setData(db.get(table, idRef.current)));

  const fetch = useCallback(
    (objId, cb) => {
      if (objId && table) {
        const obj = fromId ? db.getFromId(table, objId) : db.get(table, objId);

        if (cb) cb(obj);
        return obj;
      }
    },
    [fromId, table]
  );

  useEffect(() => {
    if (id && table) {
      idRef.current = id;
      const data = fromId ? db.getFromId(table, idRef.current) : db.get(table, idRef.current);

      if (!isEqual(data, dataRef.current)) {
        dataRef.current = data;
        setData(dataRef.current);
      }
    }
  }, [fromId, id, table]);

  return id ? data : fetch;
}

export function useSelectMem(table, query) {
  const queryRef = useRef();
  const dataRef = useRef();
  const [data, setData] = useState();

  useObserveDbTable(table, () => setData(fetch(query)));

  const fetch = useCallback(
    (objQuery, cb) => {
      if (isEqual(objQuery, queryRef.current) && data) return data;

      queryRef.current = objQuery;
      const result = db.select(table, objQuery);
      const risposta = result?.length > 1 ? result : result?.[0] ?? [];
      if (cb) cb(risposta);
      return risposta;
    },
    [table, data]
  );

  useEffect(() => {
    if (table && query) {
      const data = fetch(query);
      if (!isEqual(data, dataRef.current)) {
        dataRef.current = data;
        setData(dataRef.current);
      }
    }
  }, [fetch, query, table]);

  return query !== undefined ? data : fetch;
}

export function useGetMemTable(table) {
  const [data, setData] = useState(db.getTable(table));
  useObserveDbTable(table, () => setData(db.getTable(table)));

  return data;
}
