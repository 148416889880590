import { useRef, useMemo, useState, useCallback, useEffect, forwardRef } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import get from "lodash.get";
import isNil from "lodash.isnil";
import Container from "../../container";
import OutPutField from "../outputField";
import SelectAll from "../../selects/selectAll";
import stubs from "../../../utils/stubs";

import styles from "./styles.module.scss";

const selectStyle = { width: "366px" };

const External = forwardRef((props, ref) => {
  const {
    metadata,
    updateMetadata,
    rootNode,
    rootNodePath,
    rootNodeName,
    contentType,
    blockFields,
    uniqueId,
  } = props;

  const fieldRef = useRef({});
  const [folded, setFolded] = useState(true);
  const { errors: errori, handleBlur } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);

  const required = useMemo(() => get(metadata, "external.required"), [metadata]);
  const filters = useMemo(() => ({ catalogs: [contentType] }), [contentType]);
  const handleRequired = useCallback(
    (e) => {
      const required = e.target.checked;
      updateMetadata("external.required", required);
    },
    [updateMetadata]
  );

  const handleUpdateOutPutField = useCallback(
    (value) => {
      if (value) updateMetadata("external.output", value);
    },
    [updateMetadata]
  );

  const externalSourcesHandleChange = useCallback(
    (value) => {
      if (value) updateMetadata("external.externalSourceId", value);
    },
    [updateMetadata]
  );

  useEffect(() => {
    if (required) {
      fieldRef.current?.updateField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      folded={!required} //false
      foldable={!isNil(required) ? required : false}
      onFold={setFolded}
      coloredBars={!folded}
      color="#030b0e"
      margin="4px"
      warning={errors?.[rootNodeName]?.external}
      hideBodyOnFold={true}
      title={
        <Form.Check
          type="switch"
          checked={get(metadata, "external.required") || false}
          onChange={handleRequired}
          onBlur={handleBlur}
          id={`${rootNodePath}external.required-${uniqueId}`}
          value="true"
          name={`${rootNodePath}external.required`}
          label={<>External Source</>}
          disabled={false}
        />
      }
      help="meta-content-pack"
    >
      <div className={styles.container}>
        <div className={styles.field}>
          <div className={styles.label}>Output Metadata</div>
          <OutPutField
            ref={fieldRef}
            data={rootNode?.external?.output}
            defaultData="external_source"
            width={400}
            disableEdit={blockFields}
            realm={"external"}
            catalog={contentType}
            updateMetadata={handleUpdateOutPutField}
            required={required}
          />
        </div>
        <div className={styles.external}>
          <div className={styles.label}>External Source</div>
          <SelectAll
            accessor="externalSources"
            filters={filters}
            value={rootNode?.external?.externalSourceId}
            handleChange={externalSourcesHandleChange}
            style={selectStyle}
            isInvalid={errors?.[rootNodeName]?.externalSourceId}
          />
        </div>
      </div>
    </Container>
  );
});

export default External;
