import {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  Fragment,
  forwardRef,
} from "react";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import shortid from "shortid";
import move from "lodash-move";
import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";

const RootContainer = forwardRef(({ className, children, id }, ref) => {
  return (
    <div ref={ref} className={className} id={id} key={id}>
      {children}
    </div>
  );
});

const Container = styled(RootContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Pane = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in;
  transform: rotate3d(0, 0, 0, 0deg);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  position: relative;
  padding: 5px;
  max-width: 97vw;

  &::-webkit-scrollbar {
    display: none;
    background-color: #111;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #111;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  * {
    z-index: 2;
  }

  & > div {
    margin-right: ${(props) => props.spacing}px;
  }
`;

const Spacer = styled.div`
  flex-grow: 4;
`;

const Extra = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5px;
`;

const Input = styled.input.attrs((props) => ({
  type: "radio",
  name: `tabs-${props.$group}`,
  id: `tab-${props.$id}`,
  checked: props.active ?? false,
  value: props.active ?? false,
}))`
  display: none;

  &:checked {
    & ~ .glider {
      transform: translateX(${(props) => props.$translate}px);
    }

    & + label {
      color: #fff !important;
      font-weight: 600;

      & > .notification {
        background-color: #185ee0;
        color: #fff;
      }
    }
  }
`;

const Glider = styled.div.attrs((props) => ({}))`
  position: absolute;
  display: flex;
  width: ${(props) => props.tabWidth ?? 200}px;
  height: 30px;
  background-color: #111;
  border: 1px solid #12406c;
  z-index: 1;
  border-radius: 8px;
  transition: 0.25s ease-out;
  padding: 0 10px;
  color: #fff;
`;

const Tab = styled.label.attrs((props) => ({
  htmlFor: `tab-${props.$id}`,
}))`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 30px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #888;

  &.active {
    color: #fff;
  }

  &:hover:not(.active) {
    color: #ccc;
    background-color: #222;
  }
`;

const HiddenButton = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  background-color: #000;
  user-select: none;
`;

const HiddenDropdown = styled.div`
  position: absolute;
  top: 30px;
  right: -30px;
  width: 350px;
  height: 300px;
  background-color: #111;
  border: 1px solid #12406c;
  border-radius: 8px;
  z-index: 3;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: height 0.4s ease;
  user-select: none;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
    background-color: #111;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #111;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const HiddenItem = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1rem;
  user-select: none;
`;

const HiddenItemIcon = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  user-select: none;

  height: 100%;
  padding: 4px;
  &::after {
    content: "...";
    color: #888;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const ArrowUp = styled.div`
  position: absolute;
  top: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #12406c;
`;

function Label({ children }) {
  const ref = useRef();

  return <span ref={ref}>{children}</span>;
}

function enrichData(tabs) {
  return tabs.map((tab) => {
    return {
      ...tab,
      label: <Label>{tab.label}</Label>,
      id: tab.id ?? shortid.generate(),
    };
  });
}

function calculatePreviousTabsWidth(index, refs, spacing) {
  const data = Array.from(refs.values());
  return data
    .slice(0, index)
    .reduce((acc, tab) => acc + (tab?.offsetWidth ?? 0) + spacing, 0);
}

const pillsContainer = forwardRef((props, ref) => {
  const {
    tabs,
    extra,
    id = shortid.generate(),
    className,
    tabsClassName,
    defaultActiveTab,
    spacing = 5,
    enableReinitialize = false,
    showHiddenItemsMenu = true,
    destroyInactiveTabPane = false,
    draggable = false,
    onChange,
    onDragTab,
  } = props;

  const [activeTab, setActiveTab] = useState();
  const [hiddenItems, setHiddenItems] = useState(new Set());
  const [showHiddenItems, setShowHiddenItems] = useState(false);
  const [refMap, setRefMap] = useState(new Map());
  const [data, setData] = useState(() => {
    const data = enrichData(tabs);
    setActiveTab(data[0].id);

    return data;
  });

  useEffect(() => {
    if (enableReinitialize) {
      setRefMap(new Map());
      const data = enrichData(tabs);

      setData(data);
      setActiveTab(data[0].id);
    }
  }, [enableReinitialize, tabs]);

  useEffect(() => {
    if (defaultActiveTab) {
      const id = data[defaultActiveTab - 1]?.id;
      if (id) setActiveTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = useCallback(
    (id, index) => {
      const tabRef = refMap.get(id);
      if (tabRef)
        tabRef.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setActiveTab(id);
      onChange?.(index, id);
    },
    [onChange, refMap]
  );

  const handleDragTab = useCallback(
    (from, to) => {
      setRefMap(new Map());
      const newData = move([...data], from, to);
      setData(newData);
      onDragTab?.(from, to, newData);
    },
    [data, onDragTab]
  );

  const drawTab = useCallback(
    (index, tab) => {
      const className = tab.id === activeTab ? "active" : "";

      const TAB = draggable ? (
        <DragTab
          key={`drag-${tab.label}-${index}`}
          index={index}
          refMap={refMap}
          className={className}
          setRefMap={setRefMap}
          tab={tab}
          moveTab={handleDragTab}
        />
      ) : (
        <SimpleTab
          key={`simple-${tab.label}-${index}`}
          index={index}
          refMap={refMap}
          className={className}
          setRefMap={setRefMap}
          tab={tab}
        />
      );

      if (showHiddenItemsMenu) {
        return (
          <VisibilitySensor
            key={`vis-${tab.label}-${index}`}
            onChange={(isVisible) => {
              setHiddenItems((state) => {
                if (isVisible) state.delete(tab.id);
                else state.add(tab.id);
                return new Set(state);
              });
            }}
          >
            {TAB}
          </VisibilitySensor>
        );
      }
      return TAB;
    },
    [activeTab, draggable, handleDragTab, refMap, showHiddenItemsMenu]
  );

  const tabBar = useMemo(() => {
    const tabs = (
      <Tabs spacing={spacing}>
        {data.map((tab, index) => {
          return (
            <Fragment key={`frag-${tab.id}-${index}`}>
              <Input
                $index={index}
                $translate={calculatePreviousTabsWidth(index, refMap, spacing)}
                $id={tab.id}
                $group={id}
                active={tab.id === activeTab}
                onClick={() => handleChangeTab(tab.id, index)}
                key={`in-${tab.label}-${tab.id}`}
                onChange={() => {}}
              />
              {drawTab(index, tab)}
            </Fragment>
          );
        })}
        <Glider
          key={`glider`}
          className="glider"
          tabWidth={
            refMap.get(activeTab ?? [...refMap.values()][0])?.offsetWidth ?? 0
          }
        />
      </Tabs>
    );

    if (draggable) {
      return <DragAndDrop>{tabs}</DragAndDrop>;
    }

    return tabs;
  }, [
    activeTab,
    data,
    draggable,
    drawTab,
    handleChangeTab,
    id,
    refMap,
    spacing,
  ]);

  if (!activeTab) return null;

  return (
    <Container key={id} className={className}>
      <Header className={tabsClassName}>
        {tabBar}
        <Spacer />
        {showHiddenItemsMenu && hiddenItems.size > 0 && (
          <HiddenButton onClick={() => setShowHiddenItems((state) => !state)}>
            <HiddenItemIcon />
            {showHiddenItems && hiddenItems.size > 0 && (
              <>
                <ArrowUp />
                <HiddenDropdown>
                  {Array.from(hiddenItems).map((id, index) => (
                    <HiddenItem
                      key={`hidden-${id}-${index}`}
                      onClick={() => {
                        handleChangeTab(id, index);
                      }}
                    >
                      {data.find((i) => i.id === id)?.label}
                    </HiddenItem>
                  ))}
                </HiddenDropdown>
              </>
            )}
          </HiddenButton>
        )}

        {extra && (
          <>
            <Extra>{extra}</Extra>
          </>
        )}
      </Header>

      {destroyInactiveTabPane ? (
        <Pane>{data.find((i) => i.id === activeTab)?.pane}</Pane>
      ) : (
        data.map(({ id, pane }) => {
          return (
            <Pane key={id} hidden={id !== activeTab}>
              {pane}
            </Pane>
          );
        })
      )}
    </Container>
  );
});

function SimpleTab({ index, refMap, setRefMap, tab, reference, className }) {
  return (
    <div key={`div-${tab.label}-${index}`}>
      <Tab
        $index={index}
        $id={tab.id}
        tabWidth={refMap.get(tab.id)?.offsetWidth ?? 20}
        className={className}
        key={`tab-${tab.label}-${index}`}
        ref={(r) => {
          if (reference) reference.current = r;
          if (r && !refMap.has(tab.id) && refMap.get(tab.id) !== r) {
            setImmediate(() => {
              setRefMap((state) => new Map(state).set(tab.id, r));
            });
          }
        }}
      >
        {tab.label}
      </Tab>
    </div>
  );
}

function DragTab(props) {
  const { index, tab, moveTab } = props;
  const reference = useRef();

  const { opacity, hovered } = useDragAndDrop(
    reference,
    `id-${tab.label}-${index}`,
    index,
    "tabs",
    [],
    (_, __, drag, hover) => moveTab && moveTab(drag, hover)
  );

  return (
    <div
      style={{
        background: hovered
          ? "repeating-linear-gradient( -55deg,#111,#111 5px,#222 5px,#222 10px)"
          : null,
        borderRadius: "8px",
        opacity,
      }}
      key={`dnd-${tab.label}-${index}`}
    >
      <SimpleTab
        reference={reference}
        {...props}
        key={`simply-${tab.label}-${index}`}
      />
    </div>
  );
}

export default pillsContainer;
