import { useCallback, useRef } from "react";
import set from "lodash.set";

import ConversionPack from "./conversionPack";
import Item from "../item";

import validate from "./validation";

export function doConversionPackRefs(values) {
  const packs = new Set();

  if (values.filter?.rules?.length > 0) {
    values.filter.rules.forEach(({ type, conversionPackId }) => {
      if (type === "pack") packs.add(conversionPackId);
    });
  }

  if (values.must_not?.rules?.length > 0) {
    values.must_not.rules.forEach(({ type, conversionPackId }) => {
      if (type === "pack") packs.add(conversionPackId);
    });
  }

  set(values, "refs.require", [
    {
      type: "conversionPacks",
      items: [...packs],
    },
  ]);

  return values;
}

export default function ConversionPacksIndex(props) {
  const conversionPackRef = useRef({});

  const onSubmitted = useCallback(() => {
    if (conversionPackRef.current.reloadPreview) {
      setTimeout(() => conversionPackRef.current.reloadPreview(), 1500);
    }
  }, []);

  return (
    <Item
      name="Conversion Pack"
      accessor="conversionPacks"
      onSubmit={doConversionPackRefs}
      onValidate={validate}
      onSubmitted={onSubmitted}
      NameAndDescriptionColor="#35005d"
    >
      <ConversionPack ref={conversionPackRef} />
    </Item>
  );
}
