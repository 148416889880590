import { useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

function Random(props) {
  const { rootNodePath, execute, handleChange } = props;
  const [dateElements, setDataElements] = useState(
    execute.dateElements ?? {
      minute: true,
      hour: true,
      day: true,
      month: true,
    }
  );

  const setStep = useCallback(
    (key, value) => {
      const newState = { ...dateElements, [key]: value };
      setDataElements(newState);
      handleChange("dateElements", newState);
    },
    [dateElements, handleChange]
  );

  //if (execute?.fieldDBType === "keyword") return null;
  return (
    <div className={styles.randomBox}>
      <div className={styles.select}>
        <div className={styles.label}>Mode</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            value={execute.mode}
            onChange={(e) => handleChange("mode", e)}
            name={`${rootNodePath}execute.otherFields.mode`}
            id={`${rootNodePath}execute.otherFields.mode`}
          >
            <option value="continuos">Continuos Seed</option>
            <option value="fixed">Fixed Seed</option>
            <option value="step">Step Seed</option>
          </Form.Control>
        </div>
      </div>

      {execute.mode === "step" && (
        <>
          <div className={styles.dateElements}>
            {["month", "day", "hour", "minute"].map((key) => {
              return (
                <Form.Check
                  type="checkbox"
                  inline
                  value={true}
                  checked={dateElements[key]}
                  onChange={(e) => setStep(key, e.target.checked)}
                  name={`${rootNodePath}execute.otherFields.dateElements.${key}`}
                  id={`${rootNodePath}execute.otherFields.dateElements.${key}`}
                  key={`${rootNodePath}execute.otherFields.dateElements.${key}`}
                  label={key}
                />
              );
            })}
          </div>
        </>
      )}

      {execute.mode === "fixed" && (
        <>
          <Form.Control
            type="number"
            className={styles.seed}
            value={execute.seed ?? 1973}
            onChange={(e) => handleChange("seed", e)}
            name={`${rootNodePath}execute.otherFields.seed`}
            id={`${rootNodePath}execute.otherFields.seed`}
          />
        </>
      )}

      {/* <div>
        {execute?.fieldDBType !== "keyword" && (
          <Form.Check
            type="switch"
            value={true}
            checked={execute.phrase}
            onChange={(e) => handleChange("phrase", e)}
            name={`${rootNodePath}execute.otherFields.phrase`}
            id={`${rootNodePath}execute.otherFields.phrase`}
            label="Whole Phrase"
          />
        )}
      </div> */}
    </div>
  );
}

export default Random;
