import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import Container from "../container";

import styles from "./styles.module.scss";

export default function DeduplicateRBB(props) {
  const { color, onFold } = props;
  const { values, setFieldValue } = useFormikContext();
  const {
    deduplicatedResultsBetweenBlocks,
    deduplicateAdvanced: { startFromBlock, bagOfIds: { enabled, block, start, end } = {} } = {},
  } = values?.results ?? {};

  const titleBagOfIds = useMemo(() => {
    return (
      <Form.Check
        type="switch"
        checked={enabled || false}
        value="true"
        onChange={(e) =>
          setFieldValue(
            "results.deduplicateAdvanced.bagOfIds",
            e.target.checked ? { enabled: true, block: 0, start: 0, end: 19 } : { enabled: false }
          )
        }
        name={`results.bagOfIds.enabled`}
        id={`results.bagOfIds.enabled`}
        label="Use Bag of Ids"
      />
    );
  }, [setFieldValue, enabled]);

  const title = useMemo(() => {
    return (
      <Form.Check
        type="switch"
        checked={deduplicatedResultsBetweenBlocks || false}
        value="true"
        onChange={(e) =>
          setFieldValue("results.deduplicatedResultsBetweenBlocks", e.target.checked)
        }
        name={`results.deduplicatedResultsBetweenBlocks`}
        id={`results.deduplicatedResultsBetweenBlocks`}
        label={<span className={styles.checkLabel}>Deduplicate Results Between Blocks</span>}
      />
    );
  }, [setFieldValue, deduplicatedResultsBetweenBlocks]);

  const foldable = useMemo(() => {
    if (deduplicatedResultsBetweenBlocks !== undefined) return deduplicatedResultsBetweenBlocks;
    return false;
  }, [deduplicatedResultsBetweenBlocks]);

  return (
    <Container
      title={title}
      help="deduplicate-results-between-blocks"
      coloredBars={color ? true : false}
      hover={color ? false : true}
      isButton={color ? true : false}
      foldable={foldable}
      folded={true}
      color={color}
      background={color}
      onFold={onFold}
    >
      <div className={styles.container}>
        <div className={styles.label}>Deduplication Start From Block</div>
        <Form.Control
          aria-label="Start From Block"
          as="select"
          value={startFromBlock || 0}
          onChange={(e) =>
            setFieldValue("results.deduplicateAdvanced.startFromBlock", e.target.value)
          }
        >
          {values.blocks.map((_, i) => (
            <option key={i} value={i}>
              {i + 1}
            </option>
          ))}
        </Form.Control>
      </div>
      <Container
        title={titleBagOfIds}
        help="deduplicate-bag-of-ids"
        coloredBars={false}
        foldable={enabled}
        folded={!enabled}
        className={styles.bagOfIdsContainer}
        color={props.color}
        background={props.color}
      >
        <div className={styles.container}>
          <div className={styles.label}>Get Ids From Block</div>
          <Form.Control
            aria-label="From Block"
            as="select"
            value={block || 0}
            onChange={(e) =>
              setFieldValue("results.deduplicateAdvanced.bagOfIds.block", e.target.value)
            }
          >
            {values.blocks.map((_, i) => (
              <option key={i} value={i}>
                {i + 1}
              </option>
            ))}
          </Form.Control>
          <div className={styles.startEnd}>
            <div className={styles.label}>Starting from Item</div>
            <Form.Control
              type="number"
              placeholder="Enter email"
              min="1"
              step="1"
              value={Number(start) + 1}
              onChange={(e) =>
                setFieldValue(
                  "results.deduplicateAdvanced.bagOfIds.start",
                  Number(e.target.value) - 1
                )
              }
              className={styles.input}
            />
            <div className={styles.label}>To Item</div>
            <Form.Control
              type="number"
              placeholder="Enter email"
              min="1"
              step="1"
              value={Number(end) + 1}
              onChange={(e) =>
                setFieldValue(
                  "results.deduplicateAdvanced.bagOfIds.end",
                  Number(e.target.value) - 1
                )
              }
              className={styles.input}
            />
          </div>
        </div>
      </Container>
    </Container>
  );
}
