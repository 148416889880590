import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import styles from "./bugs.module.scss";

const ModalBox = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    ref.current.show = () => setShow(true);
  }, [ref]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={`${styles.modale} ${props.className}`}
        contentClassName={styles.modaleContent}
      >
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title>
            <div className={styles.title}>
              <FontAwesomeIcon icon={faBug} className={styles.icon} />
              Bug Tracker
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

export default ModalBox;
