import { forwardRef } from "react";
import Iframe from "react-iframe";
import AnaliticaBox from "../analiticaBox";
import Pills from "../../pills";

import styles from "./styles.module.scss";

const Analitica = forwardRef((props, ref) => {
  return (
    <Pills
      id="analitica-explorer-pills"
      className={styles.container}
      tabsClassName={styles.pills}
      tabs={[
        {
          label: "Conversions",
          pane: (
            <AnaliticaBox
              forceSearch={true}
              forceRecommended={true}
              mode="conversions"
              {...props}
              key="analitica-box-conversions"
            />
          ),
        },
        {
          label: "Metrics",
          pane: (
            <AnaliticaBox
              {...props}
              mode="metrics"
              key="analitica-box-metrics"
            />
          ),
        },
        // {
        //   label: "Kibana",
        //   pane: (
        //     <Iframe
        //       url="https://10.69.49.72:5601/app/dashboards#/view/5a1fe0e0-f490-11ed-9a40-9fece3942efa?_g=(filters%3A!()%2Cquery%3A(language%3Akuery%2Cquery%3A'')%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-3w%2Cto%3Anow))"
        //       width="100%"
        //       height="800"
        //       className={styles.iframe}
        //       display="initial"
        //       position="relative"
        //       frameBorder="0"
        //     />
        //   ),
        // },
      ]}
    />
  );
});

export default Analitica;
