import { useState, useMemo, useEffect } from "react";
import { useDataSettings } from "../../hooks/useData";
import { Form, Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import ErrorMessage from "../errorMessage";
import stubs from "../../utils/stubs";

import Container from "../container";
const selectStyle = { width: "366px" };

export default function Extra(props) {
  const { data, updateMetadata, uniqueId, contentType, userTypes, rootNodeName } = props;
  const types = useDataSettings("contentTypes");
  const { errors } = useFormikContext() || stubs.formik;
  const reject = useMemo(() => !userTypes.includes(contentType), [contentType, userTypes]);
  const [folded, setFolded] = useState(true);

  useEffect(() => {
    if (reject && data?.required) {
      updateMetadata(`extra.required`, false);
    }
    if (!data?.required && folded === false) {
      setFolded(true);
    }
  }, [data, folded, reject, updateMetadata]);

  if (reject) return null;

  return (
    <Container
      color="#030b0e"
      help="metadata-extra"
      folded={folded}
      foldable={data?.required}
      onFold={setFolded}
      coloredBars={!folded}
      title={
        <Form.Check
          type="switch"
          checked={data?.required || false}
          onChange={(e) => updateMetadata(`extra.required`, e.target.checked)}
          id={`extra.required-${uniqueId}`}
          value="true"
          label="Extra"
        />
      }
      warning={errors[rootNodeName]?.extra}
    >
      {data?.required === true ? (
        <>
          <Row>
            <Col sm="1">
              <span className="label">Catalog</span>
            </Col>
            <Col sm="10">
              <Form.Control
                as="select"
                style={selectStyle}
                value={data?.contentType}
                onChange={(e) => updateMetadata(`extra.contentType`, e.target.value)}
              >
                <option></option>
                {types.map((type, i) => {
                  if (type.value === contentType) return null;

                  return (
                    <option key={`${type.value}-${i}`} value={type.value}>
                      {type.label}
                    </option>
                  );
                })}
              </Form.Control>
              <ErrorMessage name={`${rootNodeName}.extra_contentType`} />
            </Col>
          </Row>
          {props.children ? props.children : null}
        </>
      ) : null}
    </Container>
  );
}
