export default function validate(data = {}, experiments = new Set(), origin = {}) {
  const errors = {};

  if (data.reference !== origin.reference) {
    if (experiments.has(data.reference)) {
      errors.reference = "Experiment Reference Already Taken";
      errors.settings = true;
    }
  }
  if (!data.reference) {
    errors.reference = "You Must Insert Reference";
    errors.settings = true;
  }
  if (!data.trafficStrategy) {
    errors.trafficStrategy = "You Must Specify a Traffic Strategy";
    errors.settings = true;
  }
  if (!data.onEnd) {
    errors.onEnd = "You Must Specify an On End Strategy";
    errors.settings = true;
  }

  // if (!data.conversions || data.conversions.length === 0) {
  //   errors.conversions = "You Must Activate at least one Conversion";
  // }
  if (!data.metrics) {
    errors.metrics = "You Must Choose a Metric to evaluate Experiment Hypothesis";
  }

  if (!data.cron) {
    const msg = "You Must Specify a Cron Schedule";
    errors.settings = msg;
    errors.cron = msg;
  }

  // if (data.conversions) {
  //   let havePrimary = false;
  //   data.conversions.forEach((c) => {
  //     if (c.primary) havePrimary = true;
  //   });

  //   if (!havePrimary) {
  //     errors.conversions = "You Must Choose a Primary Conversion";
  //   }
  // }

  if (data.variations) {
    let traffic = 0;

    data.variations.forEach((v, index) => {
      traffic += v.traffic || 0;

      if (!v.label) {
        const message = "You Must enter a Name";

        errors.variations = true;
        errors[v.id] = message;
        errors[`${v.id}:label`] = message;
      }
      if (!v.payload?.endpoint) {
        const message = "You Must Select and Endpoint";

        errors.variations = true;
        errors[v.id] = message;
        errors[`${v.id}:endpoint`] = message;
      }
    });

    if (data.trafficStrategy === "custom" && traffic > 100) {
      errors.traffic = true;
      errors.variations = true;
    }
  }
  return errors;
}
