import { explorer } from "../transport";

export function fetchPage(body, id_token, cb, errorCb) {
  const deviceId = "ARES-2097-TMX-CC";

  explorer
    .post(
      `preview/page`,
      { deviceId, ...body },
      {
        headers: { id_token },
      }
    )
    .then((response) => {
      if (cb) {
        const { preview, payload } = response.data;
        cb(JSON.parse(preview), payload);
      }
    })
    .catch((error) => {
      if (errorCb) errorCb(error);
    });
}
