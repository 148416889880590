/* eslint-disable import/no-webpack-loader-syntax */
import {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from "react";

import useNotifications from "../useNotifications";
import useTimeout from "../useTimeout";
import { playSound } from "../../utils/audio";
import { useDataSettings } from "../useData";
import useSession from "../useSession";
import { useLocation } from "react-router-dom";

import ConfirmGeneric from "./confirmGeneric";
import ConfirmDelete from "./confirmDelete";
import ConfirmUnsavedChanges from "./confirmUnsavedChanges";
import HideScreen from "./hideScreen";

export const MainContext = createContext(null);

export function useMainContext() {
  return useContext(MainContext);
}

export const Loading = HideScreen;

export default function MainContextProvider(props) {
  const { logout } = useSession();
  const loaded = useDataSettings("loaded") || true;
  const [deletingRequest, setDeletingRequest] = useState(false);
  const [unsavedRequest, setUnsavedRequest] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState(false);
  const { pathname } = useLocation();

  const [NotificationContainer, NotificationManager] = useNotifications();

  const requestConfirm = useCallback((message, callback) => {
    setConfirmRequest({ message, callback, show: true });
  }, []);

  const onConfirm = useCallback(() => {
    setConfirmRequest({ show: false });
    confirmRequest.callback();
  }, [confirmRequest]);

  const requestDeleteConfirm = useCallback((message, callback) => {
    setDeletingRequest({ message, callback, show: true });
  }, []);

  const onConfirmDelete = useCallback(() => {
    setDeletingRequest({ show: false });
    deletingRequest.callback();
  }, [deletingRequest]);

  const requestUnsavedConfirm = useCallback((callback) => {
    setUnsavedRequest({ callback, show: true });
  }, []);

  const onConfirmUnsaved = useCallback(() => {
    setUnsavedRequest({ show: false });
    unsavedRequest.callback();
  }, [setUnsavedRequest, unsavedRequest]);

  const createNotification = useCallback(
    (type, message) => {
      switch (type) {
        case "info":
          playSound("done");
          NotificationManager.info(message);
          break;
        case "success":
          playSound("ok");
          NotificationManager.success(message);
          break;
        case "warning":
          playSound("warining");
          NotificationManager.warning(message);
          break;
        case "error":
          playSound("ko");
          NotificationManager.error(message);
          break;
        default:
          throw new Error();
      }
    },
    [NotificationManager]
  );

  const timeoutRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useTimeout({
    onWarning: useCallback(() => {
      playSound("warning");
      NotificationManager.warning("Idle time exceeded! You are about to be disconnected", false);
    }, [NotificationManager]),
    onEnded: useCallback(() => logout(), [logout]),
    onRestart: useCallback(() => NotificationManager.resetAll(), [NotificationManager]),
    onTick: useCallback((obj) => {
      timeoutRef.current = obj;
    }, []),
  });

  const ctx = useMemo(
    () => ({
      ok: () => playSound("ok"),
      ko: () => playSound("ko"),
      done: () => playSound("done"),
      warning: () => playSound("warning"),
      requestConfirm,
      requestDeleteConfirm,
      requestUnsavedConfirm,
      createNotification,
      getTimeoutCounter: () => timeoutRef.current,
    }),
    [requestConfirm, requestDeleteConfirm, requestUnsavedConfirm, createNotification]
  );

  return (
    <MainContext.Provider value={ctx}>
      {NotificationContainer}

      <ConfirmGeneric
        onConfirm={onConfirm}
        onClose={() => setConfirmRequest({ show: false })}
        message={confirmRequest.message}
        show={confirmRequest.show}
      />
      <ConfirmDelete
        onConfirm={onConfirmDelete}
        onClose={() => setDeletingRequest({ show: false })}
        message={deletingRequest.message}
        show={deletingRequest.show}
      />
      <ConfirmUnsavedChanges
        onConfirm={onConfirmUnsaved}
        onClose={() => setUnsavedRequest({ show: false })}
        show={unsavedRequest.show}
      />
      {loaded && props ? props.children : <HideScreen message="L0AD1NG S3TT1NGS" />}
    </MainContext.Provider>
  );
}

// {
//   saving && <HideScreen message="S4V1NG" />;
// }
// {
//   deleting && <HideScreen message="D3L3T1NG" />;
// }
