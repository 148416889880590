import {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
  createRef,
  forwardRef
} from "react";
import { useFormikContext } from "formik";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import isEqual from "lodash.isequal";
import db from "../../hooks/useMemoryDB";
import Picker from "rc-calendar/lib/Picker";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";
import usePermissions from "../../hooks/usePermissions";
import { Cron } from "react-js-cron";
import cronstrue from "cronstrue";
import RotaryKnob from "../rotaryKnob";
import SelectAll from "../selects/selectAll";
import Container from "../container";
import Explorer from "./explorer";
import styles from "./styles.module.scss";
import "../../scss/calendar.css";
import "../../scss/time.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faClock,
  faDove,
  faFlask,
  faPowerOff,
  faTrafficLight,
  faTrophy
} from "@fortawesome/free-solid-svg-icons";
import idGen from "../../utils/idGen";
import MetricsSelect from "../selects/selectMetrics";
import { useSelector } from "react-redux";
import { explorer } from "../../transport";
import "react-js-cron/dist/styles.css";

const alignConfig = {
  points: ["bl", "br"], // align top left point of sourceNode with top right point of targetNode
  offset: [0, 150], // the offset sourceNode by 10px in x and 20px in y,
  targetOffset: ["200%", "0%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  overflow: { adjustX: true, adjustY: true } // auto adjust position when sourceNode is overflowed
};

const now = moment();
const headerKW = (k, v) => {
  if (!v) return null;

  return (
    <div className={styles.element}>
      <div className={styles.label}>{k}</div>
      <div className={styles.value}>{v}</div>
    </div>
  );
};

function getPercentage(value, total) {
  try {
    const percentage = (value / total) * 100;
    return percentage ? percentage.toFixed(2) : 0;
  } catch (error) {
    return 0;
  }
}

export default function Experiment(props) {
  const { values, errors, setFieldValue } = useFormikContext();
  const dataPayloadRef = useRef();
  const [data, setData] = useState([]);
  const [traffic, setTraffic] = useState({});
  const [winner, setWinner] = useState();
  const [stats, setStats] = useState();
  const [hoverValue, setHoverValue] = useState([]);
  const tenant = useSelector((state) => state.user.tenant);
  const { expert } = usePermissions();

  const reloadData = useCallback((payload) => {
    if (!isEqual(dataPayloadRef.current, payload)) {
      dataPayloadRef.current = payload;
      explorer
        .post("analitica/experiments/stats", payload)
        .then(({ data }) => {
          setData(data.date_histogram);
          setTraffic(data.traffic);
          setWinner(data.winner);
          setStats(data.variations);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const handleReleaseStucked = useCallback(() => {
    if ((values.id, tenant)) {
      explorer
        .delete(`analitica/experiments/stuckedUsers/${tenant}/${values.id}`)
        .then(({ data }) => {
          console.log(data);
        });
    }
  }, [values.id, tenant]);

  const handleChange = useCallback(
    (field, value) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleReferenceIdChange = useCallback(
    (id) => {
      if (id) {
        setFieldValue("referenceId", id);
        const endpoint = db.get("endpoints", id);
        if (endpoint) {
          const propertyId = endpoint.propertyId;
          const property = db.get("properties", propertyId);
          if (property) {
            setFieldValue(
              "reference",
              `${property.title}:${endpoint.reference}`
            );
          }
        }
      }
    },
    [setFieldValue]
  );

  const onChangeScheduling = useCallback(
    ([start, end]) => {
      setFieldValue("scheduling.start", start.toISOString());
      setFieldValue("scheduling.end", end.toISOString());
    },
    [setFieldValue]
  );

  const handleResetScheduling = useCallback(
    () => setFieldValue("scheduling", undefined),
    [setFieldValue]
  );

  const schedulingValue = useMemo(() => {
    if (values.scheduling) {
      return [moment(values.scheduling.start), moment(values.scheduling.end)];
    }

    return [];
  }, [values.scheduling]);

  const timePickerElement = useMemo(
    () => (
      <TimePickerPanel
        defaultValue={[
          moment("00:00:00", "HH:mm:ss"),
          moment("23:59:59", "HH:mm:ss")
        ]}
      />
    ),
    []
  );

  const rangeCalendar = useMemo(
    () => (
      <RangeCalendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder={["start", "end"]}
        defaultValue={[now, now.clone().add(1, "months")]}
        //locale={cn ? zhCN : enUS}
        timePicker={timePickerElement}
      />
    ),
    [hoverValue, timePickerElement]
  );

  const settingHeader = useMemo(() => {
    return (
      <div className={styles.header}>
        {values.reference && headerKW("Reference", <>{values.reference}</>)}
        {values.scheduling &&
          headerKW(
            "Scheduling",
            <>
              {moment(values.scheduling.start).format("DD-MM-YYYY")}
              <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
              {moment(values.scheduling.end).format("DD-MM-YYYY")}
            </>
          )}
        {headerKW("On End", values.onEnd)}
        {headerKW("Traffic Strategy", values.trafficStrategy)}
        {headerKW("Stuck User", values.stuckUser ? "ON" : "OFF")}
      </div>
    );
  }, [
    values.onEnd,
    values.reference,
    values.scheduling,
    values.stuckUser,
    values.trafficStrategy
  ]);

  return (
    <>
      <Container
        title="Settings"
        header={settingHeader}
        headerOnlyOnFolded={false}
        folded={true}
        color="#3e1f47"
        coloredBars={false}
        warning={errors.settings}
        extra={
          <>
            <Form.Check
              type="switch"
              id="experimentRunning"
              checked={Boolean(values.running)}
              value="true"
              onChange={(e) => {
                const value = e.target.checked ? true : false;
                handleChange("running", value);
              }}
              name="experimentRunning"
              label="Running"
            />
          </>
        }
      >
        <div className={styles.settings}>
          <div className={styles.row}>
            <div className={styles.cell}>
              <Form.Label className={styles.label}>Reference</Form.Label>

              <SelectAll
                accessor="endpoints"
                filters={{ exclude: [values.referenceId ?? null] }}
                isInvalid={errors.reference}
                value={values.referenceId}
                handleChange={handleReferenceIdChange}
                startWithEmptyOption={true}
              />
            </div>
            <div className={styles.cell}>
              <Form.Label className={styles.label}>
                Scheduling{" "}
                <FontAwesomeIcon
                  icon={faPowerOff}
                  className={styles.resetScheduling}
                  onClick={handleResetScheduling}
                />
              </Form.Label>
              <Picker
                value={schedulingValue}
                onChange={onChangeScheduling}
                animation="slide-up"
                align={alignConfig}
                calendar={rangeCalendar}
              >
                {({ value: currentValue }) => {
                  if (!currentValue.length) {
                    return (
                      <Button
                        className="pick-date"
                        variant={
                          errors.scheduling ? "outline-danger" : "outline-light"
                        }
                      >
                        Pick a Date Range...
                      </Button>
                    );
                  }
                  return (
                    <div className={styles.scheduling}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            <FontAwesomeIcon icon={faClock} />
                            <b>{currentValue[0].format("HH:mm")}</b>
                          </Tooltip>
                        }
                      >
                        <span>{currentValue[0].format("DD-MM-YYYY")}</span>
                      </OverlayTrigger>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className={styles.arrow}
                      />
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            <FontAwesomeIcon icon={faClock} />
                            <b>{currentValue[1].format("HH:mm")}</b>
                          </Tooltip>
                        }
                      >
                        <span>{currentValue[1].format("DD-MM-YYYY")}</span>
                      </OverlayTrigger>
                    </div>
                  );
                }}
              </Picker>
            </div>
            <div className={styles.cell}>
              <Form.Label className={styles.label}>On End</Form.Label>
              <Form.Control
                as="select"
                className={styles.select}
                onChange={(e) => handleChange("onEnd", e.target.value)}
                value={values.onEnd}
                isInvalid={errors.onEnd}
              >
                <option></option>
                <option value="keepBase">Keep Base</option>
                <option value="promoteWinner">Promote Winner</option>
                <option value="stop">Stop</option>
              </Form.Control>
            </div>
            <div className={styles.cell}>
              <Form.Label className={styles.label}>Traffic Strategy</Form.Label>
              <Form.Control
                as="select"
                className={styles.select}
                onChange={(e) =>
                  handleChange("trafficStrategy", e.target.value)
                }
                value={values.trafficStrategy}
                isInvalid={errors.trafficStrategy}
              >
                <option></option>
                <option value="roundRobin">Round Robin</option>
                <option value="custom">Custom</option>
              </Form.Control>
              <div className={styles.stuckBox}>
                <Form.Check
                  type="switch"
                  id="stuckUser"
                  checked={Boolean(values.stuckUser)}
                  value="true"
                  onChange={(e) => {
                    const value = e.target.checked ? true : false;
                    handleChange("stuckUser", value);
                  }}
                  name="stuckUser"
                  label="Stuck User"
                  className={styles.stuckUserSwitch}
                />
                {expert.includes("freeExperimentStuckedUsers") && (
                  <Button
                    variant="outline-danger"
                    className={styles.freeBtn}
                    onClick={handleReleaseStucked}
                  >
                    <FontAwesomeIcon
                      icon={faDove}
                      className={styles.releaseIcon}
                    />
                    Release
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={styles.cronRow}>
            <Container
              title="Check Experiment Schedule"
              help="experiments-schedule-cron"
              warningMessage={errors.cron}
              header={
                <div className={styles.cronString}>
                  {cronstrue.toString(values.cron ?? "* * * * *")}
                </div>
              }
              headerOnlyOnFolded={false}
              coloredBars={false}
              folded={true}
            >
              <Cron
                value={values.cron}
                setValue={(value) => handleChange("cron", value)}
              />
            </Container>
          </div>
        </div>
      </Container>
      <Metrics />
      <Variations traffic={traffic} winner={winner} stats={stats} />
      {values.variations?.length > 0 && values.metrics && (
        <Explorer data={data} reloadData={reloadData} stats={stats} />
      )}
    </>
  );
}

function Metrics(props) {
  const { setFieldValue, errors, values } = useFormikContext();

  const header = useMemo(() => {
    return (
      <div className={styles.metricsHeader}>
        <MetricsSelect
          value={values.metrics}
          onChange={(m) => setFieldValue("metrics", m)}
        />
      </div>
    );
  }, [setFieldValue, values.metrics]);

  return (
    <Container
      title="Evaluation Metrics"
      warningMessage={errors.metrics}
      header={header}
      headerOnlyOnFolded={false}
      folded={true}
      foldable={false}
      color="#312244"
      coloredBars={false}
    ></Container>
  );
}

function Variations(props) {
  const { traffic, winner, stats } = props;
  const refsMap = useRef(new Map());
  const [autoTune, setAutoTune] = useState(false);
  const { setFieldValue, errors, values } = useFormikContext();

  const onChangeTraffic = useCallback(
    (index, value) => {
      const newVariations = [...values.variations];

      const residuo = (100 - value) / 100;
      const rimasti = newVariations.length - 1;
      const percShare = (newVariations[index].traffic ?? 1) / rimasti;

      newVariations[index].traffic = value;

      if (autoTune) {
        newVariations.forEach((v, i) => {
          if (i !== index) {
            if (isNaN(v.traffic)) v.traffic = 1;
            const newPerc = v.traffic + percShare;
            v.traffic = Math.floor(residuo * newPerc);
          }
        });
      }

      setFieldValue("variations", newVariations);
    },
    [autoTune, setFieldValue, values.variations]
  );

  const handleAddVariant = useCallback(() => {
    const newVariations = [...(values.variations || [])];
    newVariations.push({ label: `New Hypothesis #${Date.now()}` });
    setFieldValue("variations", newVariations);
  }, [setFieldValue, values.variations]);

  const header = useMemo(() => {
    return (
      <div className={styles.variationHeader}>
        {(values?.variations || []).map((v, index) => {
          const perc = getPercentage(traffic[v.id], traffic.total); //(traffic[v.id] || 0 / traffic.total) * 100;

          return (
            <div className={styles.variation} key={`${v.id}-${index}`}>
              {v.id === winner && (
                <FontAwesomeIcon
                  icon={faTrophy}
                  className={styles.winnerIcon}
                />
              )}
              {index === 0 && (
                <FontAwesomeIcon icon={faFlask} className={styles.icon} />
              )}
              {v.label}
              <span className={styles.separator}>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <span className={styles.label}>Dispensed</span>
              {traffic && <span className={styles.trafficPerc}>{perc}%</span>}
              <span className={styles.score}>
                <span className={styles.label}>Score</span>
                <span className={styles.value}>{stats?.[v.id] ?? 0}</span>
              </span>
            </div>
          );
        })}
      </div>
    );
  }, [stats, traffic, values?.variations, winner]);

  const extra = useMemo(() => {
    if (values.trafficStrategy !== "custom") return null;

    return (
      <Form.Check
        type="switch"
        id="variationsAutotTune"
        checked={autoTune}
        value="true"
        onChange={(e) => setAutoTune(e.target.checked)}
        name="variationsAutotTune"
        label="Auto Adjust Traffic"
      />
    );
  }, [autoTune, values.trafficStrategy]);

  const footer = useMemo(() => {
    return (
      <div className={styles.footer}>
        <Button size="sm" variant="outline-success" onClick={handleAddVariant}>
          Add Hypothesis
        </Button>
      </div>
    );
  }, [handleAddVariant]);

  // Consider only Endpoint without a variant specified
  const alreadyIn = useMemo(
    () =>
      values?.variations
        ?.map?.(({ payload }) => !payload?.blockId && payload?.endpointId)
        .filter(Boolean),
    [values]
  );

  const blocksIn = useMemo(
    () =>
      values?.variations
        ?.map?.(({ payload }) => payload?.blockId)
        .filter(Boolean),
    [values]
  );

  return (
    <Container
      title="Hypothesis"
      color="#272640"
      coloredBars={false}
      warning={errors.variations}
      header={header}
      footer={footer}
      extra={extra}
      folded={true}
      key={"hypo"}
    >
      <DragAndDrop>
        {(values?.variations || []).map((variation, index) => {
          if (!refsMap.current.has(variation.id)) {
            refsMap.current.set(variation.id, createRef());
          }

          return (
            <Variation
              ref={refsMap.current.get(variation.id)}
              data={variation}
              index={index}
              key={`${variation.id}-${index}`}
              onChangeTraffic={onChangeTraffic}
              alreadyIn={alreadyIn}
              blocksIn={blocksIn}
              traffic={traffic}
              stats={stats}
              winner={winner}
            />
          );
        })}
      </DragAndDrop>
    </Container>
  );
}

const Variation = forwardRef((props, ref) => {
  const {
    data,
    index,
    onChangeTraffic,
    traffic,
    stats,
    winner,
    alreadyIn,
    blocksIn
  } = props;
  const [variants, setVariants] = useState([]);
  const { setFieldValue, errors, values } = useFormikContext();
  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    `variations[${index}]`,
    index,
    "variations"
  );

  const filters = useMemo(() => ({ exclude: alreadyIn ?? [] }), [alreadyIn]);

  const stile = useMemo(() => {
    const style = { opacity, cursor: "pointer" };

    if (highlighted && !hovered) {
      style.backgroundColor = "#222";

      return style;
    }
    if (hovered) {
      style.backgroundColor = "#333";
      style.marginTop = "5px";
      style.marginBottom = "5px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const getVariants = useCallback((id, cb) => {
    const endpoint = db.get("endpoints", id);
    const ids = [endpoint.blockId];
    endpoint.variants?.forEach((v) => ids.push(v.blockId));
    const blocks = db.bulkGet(
      "blocks",
      ids
      //ids.filter((id) => !blocksIn.includes(id))
    );
    if (ids.length > 1)
      setVariants(blocks.map((b) => ({ value: b.id, label: b.title })));
    if (cb) cb(endpoint);
  }, []);

  useEffect(() => {
    if (data?.payload?.endpointId && variants.length === 0) {
      getVariants(data?.payload?.endpointId);
    }
    if (!data.id) {
      setFieldValue(`variations[${index}].id`, idGen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEndpointChange = useCallback(
    (id) => {
      const payload = { ...(data.payload || {}) };
      setVariants([]);
      delete payload.blockId;
      delete payload.blockTitle;

      if (id) {
        getVariants(id);
        const endpoint = db.get("endpoints", id);
        payload.endpoint = endpoint.reference;
        payload.endpointId = id;
        if (endpoint.propertyId) {
          payload.propertyId = endpoint.propertyId;
          const property = db.get("properties", endpoint.propertyId);
          payload.property = property.title.toLowerCase();
          setFieldValue(`variations[${index}].payload`, payload);
        } else {
          delete payload.propertyId;
          delete payload.property;

          setFieldValue(`variations[${index}].payload`, payload);
        }
      } else {
        delete payload.endpoint;
        delete payload.endpointId;
        delete payload.propertyId;
        delete payload.property;

        setFieldValue(`variations[${index}].payload`, payload);
      }
    },
    [data.payload, getVariants, index, setFieldValue]
  );

  const handleVariantChange = useCallback(
    (e) => {
      const id = e.target.value;
      const payload = { ...(data.payload || {}) };

      if (id) {
        payload.blockId = id;
        const block = db.get("blocks", id);
        payload.blockTitle = block.title;
        setFieldValue(`variations[${index}].payload`, payload);
      } else {
        delete payload.blockTitle;
        delete payload.blockId;
        setFieldValue(`variations[${index}].payload`, payload);
      }
    },
    [data.payload, index, setFieldValue]
  );

  const onChangeLabel = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue(`variations[${index}].label`, value);
    },
    [index, setFieldValue]
  );

  const handleDeleteVariant = useCallback(() => {
    const newVariations = [...values.variations];
    newVariations.splice(index, 1);
    setFieldValue("variations", newVariations);
  }, [index, setFieldValue, values.variations]);

  const header = useMemo(() => {
    const dispensed = getPercentage(traffic[data.id], traffic.total);
    const score = stats?.[data?.id];

    return (
      <div className={styles.header}>
        {headerKW("Property", data.payload?.property)}
        {headerKW("Endpoint", data.payload?.endpoint)}
        {headerKW("Variant", data.payload?.blockTitle)}
        {traffic && (
          <div className={styles.trafficHead}>
            {headerKW(
              "Dispensed",
              <>
                {`${dispensed}%`}
                <span className={styles.timesCount}>
                  ({traffic[data.id] || 0} times)
                </span>
              </>
            )}
          </div>
        )}
        {score && (
          <div className={styles.statsHead}>
            {headerKW("Score", <>{score}</>)}
          </div>
        )}
      </div>
    );
  }, [
    data.id,
    data.payload?.blockTitle,
    data.payload?.endpoint,
    data.payload?.property,
    stats,
    traffic
  ]);

  const footer = useMemo(() => {
    return (
      <div className={styles.footer}>
        <Button
          size="sm"
          variant="outline-danger"
          onClick={handleDeleteVariant}
        >
          Delete Hypothesis
        </Button>
      </div>
    );
  }, [handleDeleteVariant]);

  const extra = useMemo(() => {
    return (
      <div className={styles.extra}>
        {index === 0 && (
          <div className={styles.isBase}>
            <FontAwesomeIcon icon={faFlask} className={styles.icon} />
            <span className={styles.label}>BASE</span>
          </div>
        )}
        {values.trafficStrategy === "custom" && (
          <>
            <FontAwesomeIcon
              icon={faTrafficLight}
              className={`${styles.icon} ${
                errors.traffic ? styles.trafficError : ""
              } ${data?.traffic === 0 ? styles.trafficOff : ""}`}
            />
            <span className={styles.traffic}>{data.traffic}%</span>
          </>
        )}
      </div>
    );
  }, [data.traffic, errors.traffic, index, values.trafficStrategy]);

  return (
    <Container
      title={
        <>
          {data.id === winner && (
            <FontAwesomeIcon
              icon={faTrophy}
              className={styles.titleWinnerIcon}
            />
          )}
          {data.label}
        </>
      }
      folded={true}
      key={data.id}
      ref={ref}
      style={stile}
      id={data.id}
      color={index === 0 ? "#201500" : "#060f01"}
      warningMessage={errors[data.id]}
      coloredBars={index !== 0}
      header={header}
      headerOnlyOnFolded={false}
      footer={footer}
      extra={extra}
    >
      <div className={styles.variantBox}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.labelCol}>Name</div>
            <div className={styles.contentCol}>
              <Form.Control
                type="text"
                placeholder="Enter name"
                isInvalid={errors[`${data.id}:label`]}
                value={data?.label}
                onChange={onChangeLabel}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.trafficKnob}>
              {values.trafficStrategy === "custom" && (
                <RotaryKnob
                  value={data?.traffic}
                  min={0}
                  max={100}
                  step={1}
                  label="Traffic Percentage"
                  labelPosition="right"
                  skin="s12"
                  onChange={(value) => onChangeTraffic(index, value)}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.labelCol}>Endpoint</div>
            <div className={styles.contentCol}>
              <SelectAll
                accessor="endpoints"
                filters={filters}
                isInvalid={errors[`${data.id}:endpoint`]}
                value={data?.payload?.endpointId}
                handleChange={handleEndpointChange}
                startWithEmptyOption={true}
              />
            </div>
          </div>
          {variants.length > 0 && (
            <div className={styles.column}>
              <div className={styles.labelCol}>Variant</div>
              <div className={styles.contentCol}>
                <Form.Control
                  as="select"
                  onChange={handleVariantChange}
                  value={data?.payload?.blockId}
                >
                  <option></option>
                  {variants.map((v) => (
                    <option key={v.value} value={v.value}>
                      {v.label}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
});
