import { useState, useMemo } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Cell,
  ReferenceLine,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
} from "recharts";

import Switch from "react-bootstrap-switch";
import Container from "../../../../container";

import styles from "./styles.module.scss";
import "./global.scss";

const tooltipStyle = { backgroundColor: "#000", color: "#fff", border: "1px solid #444" };
const tooltipWrapperStyle = { border: "1px solid #444" };

export default function Positions(props) {
  const { items, blocks, compare } = props;

  if (!items.length > 0 || !blocks.length > 0) return null;

  return (
    <Container title="Positions" folded={true} coloredBars={true}>
      <div className={styles.positionsBox}>
        <div className={styles.items}>
          <Position data={items} title="Items Position" compare={compare} />
        </div>
        <div className={styles.blocks}>
          <Position data={blocks} title="Blocks Position" compare={compare} />
        </div>
      </div>
    </Container>
  );
}

function Position(props) {
  const [compareMode, setCompareMode] = useState(props.compare);

  const extra = useMemo(() => {
    return props.compare ? (
      <div className={styles.switchs}>
        <Switch
          bsSize="small"
          value={compareMode}
          onText={"Compare"}
          onColor="compare"
          offText="All"
          offColor="danger"
          onChange={(elm, state) => {
            setCompareMode(state);
          }}
        />
      </div>
    ) : null;
  }, [compareMode, props.compare]);

  const graph = useMemo(() => {
    if (!compareMode) {
      return (
        <BarChart data={props.data} margin={{ top: 10, right: 0, left: -10, bottom: 0 }}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="pos" />
          <YAxis />
          <Tooltip
            cursor={{ fill: "#222", opacity: 0.5 }}
            wrapperStyle={tooltipWrapperStyle}
            contentStyle={tooltipStyle}
          />
          <Bar dataKey="count" stroke="#8884d8" fill="rgba(181, 23, 158, 0.7)" label={false} />
        </BarChart>
      );
    }

    return (
      <BarChart data={props.data} margin={{ top: 10, right: 0, left: -20, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="pos" />
        <YAxis />
        <Tooltip
          wrapperStyle={tooltipWrapperStyle}
          contentStyle={tooltipStyle}
          cursor={{ fill: "#222", opacity: 0.5 }}
        />
        <ReferenceLine y={0} stroke="#fff" />
        <Bar dataKey="diffPercentage" fill="#fff">
          {props.data.map((entry, index) => {
            return (
              <Cell
                key={`${index}-${entry.diffPercentage}`}
                fill={entry.diffPercentage >= 0 ? "#00a550" : "#e83946"}
              />
            );
          })}
        </Bar>
      </BarChart>
    );
  }, [compareMode, props.data]);

  return (
    <Container title={props.title} coloredBars={false} hover={false} foldable={false} extra={extra}>
      <ResponsiveContainer width={"100%"} height={200}>
        {graph}
      </ResponsiveContainer>
    </Container>
  );
}
