import { useCallback, useState, useMemo, forwardRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useItemContext } from "../../hooks/useItem";
import { useGetMemItem } from "../../hooks/useMemoryDB";

import InputPersonas from "../personas/inputPersonas";
import Container from "../container";
import Carousel from "../analitica/carousel";
import Loading from "../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { explorer } from "../../transport";

const Preview = forwardRef((props, ref) => {
  const { values } = useFormikContext();
  const { isNew } = useItemContext();
  const tenant = useSelector((state) => state.user.tenant);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const params = useGetMemItem("params", values.paramsId);
  const { id, outputMetadataField, contentType } = values;

  const reload = useCallback(() => {
    if (values.userBased && !userId) return;

    if (!isNew && params) {
      const catalog = params.content.types[contentType].index;

      setLoading(true);
      explorer.post(`conversionPack/${tenant}/${id}`, { tenant, id, userId }).then(({ data }) => {
        const content = (data.metadata[outputMetadataField] ?? "").split(",").filter(Boolean);

        const pack = {
          catalog,
          content: content.map((id) => ({ id })),
        };

        setData(pack);
        setLoading(false);
      });
    }
  }, [contentType, id, isNew, outputMetadataField, params, tenant, userId, values.userBased]);

  useEffect(() => {
    reload();
  }, [reload]);

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faSyncAlt}
        className={`${styles.previewReload} ${loading ? styles.previewLoading : ""}`}
        spin={loading}
        onClick={reload}
      />
    );
  }, [loading, reload]);

  const header = useMemo(() => {
    if (values.userBased) {
      return (
        <div className={styles.previewHeader}>
          <Form.Label>UserId:</Form.Label>
          <InputPersonas
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className={styles.previewUserId}
          />
        </div>
      );
    }
    return null;
  }, [userId, values.userBased]);

  if (ref?.current) ref.current.reload = reload;

  //if (!data) return null;

  return (
    <Container
      title="Preview"
      extra={extra}
      header={header}
      headerOnlyOnFolded={false}
      coloredBars={false}
      color="#111"
      folded={data ? false : true}
    >
      {loading ? <Loading width={"100%"} height="150px" /> : <Carousel catalogs={[data]} />}
    </Container>
  );
});

export default Preview;
