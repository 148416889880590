import { useMemo, useState, useCallback, useRef, forwardRef, createRef } from "react";
import toWords from "split-camelcase-to-words";
import Editor from "@monaco-editor/react";
import { Button, Form } from "react-bootstrap";
import { Virtuoso } from "react-virtuoso";
import moment from "moment";
import { useDoRef } from "../../hooks/useDataRef";
import { useDataSaveItem, useDataDeleteItem } from "../../hooks/useData";
import usePermissions from "../../hooks/usePermissions";
import Container from "../container";
import ContentType from "../contentType";
import styles from "./styles.module.scss";

const notSave = [
  "aresInternals",
  "metadata",
  "labelsAll",
  "policies",
  "health",
  "users",
  "notes",
  "help",
];

export default function DataMapExplorer({ data, canSave = false }) {
  const { write, destroy } = usePermissions();
  const deleteItem = useDataDeleteItem({ confirm: false, notify: true });
  const saveItem = useDataSaveItem({ confirm: false, notify: true });
  const foldRef = useRef(new Map());
  const [currentTable, setCurrentTable] = useState(0);
  const [idFilter, setIdFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [volatileFilter, setVolatileFilter] = useState(false);
  const [catalog, setCatalog] = useState("");
  const refMap = useRef(new Map());

  const tables = useMemo(() => {
    if (data) {
      return [...data.keys()].sort();
    }

    return [];
  }, [data]);

  const tableData = useMemo(() => {
    if (tables) {
      const key = tables[currentTable];
      return [...data.get(key).values()]
        .filter((k) => {
          if (idFilter && !k.id.includes(idFilter)) return false;
          if (titleFilter && !(k.title ?? "").includes(titleFilter)) return false;
          if (volatileFilter && k.volatile !== true) return false;
          if (catalog && k.catalog !== catalog) return false;
          return true;
        })
        .sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
    }
  }, [catalog, currentTable, data, idFilter, tables, titleFilter, volatileFilter]);

  const drawRow = useCallback(
    (index) => {
      const data = tableData[index];
      const ref = (() => {
        if (!refMap.current.has(data.id)) refMap.current.set(data.id, createRef());
        return refMap.current.get(data.id);
      })();

      return (
        <Container
          key={data.id}
          extra={<span className={styles.dataId}>{data.id}</span>}
          title={<span className={styles.dataTitle}>{data.title ?? data.id}</span>}
          header={
            <div className={styles.header}>
              <span className={styles.dataUsername}>{data.lastSaved?.username}</span>
              <span className={styles.dataDate}>
                {data.lastSaved?.date ? moment(data.lastSaved?.date).format("DD-MM-YYYY") : null}
              </span>
            </div>
          }
          headerJustify="flex-end"
          onFold={(f) => foldRef.current.set(index, f)}
          folded={foldRef.current.get(index) ?? true}
          foldable={true}
          footer={
            <>
              {!notSave.includes(tables[currentTable]) && destroy.includes("db") && canSave ? (
                <div className={styles.dataFooter}>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this item?") === true) {
                        deleteItem({
                          accessor: tables[currentTable],
                          Id: data.id,
                          reference: data.reference,
                        });
                      }
                    }}
                    style={{ marginRight: "5px" }}
                  >
                    Delete
                  </Button>
                </div>
              ) : null}
              {!notSave.includes(tables[currentTable]) && write.includes("db") && canSave ? (
                <div className={styles.dataFooter}>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => {
                      if (ref.current) {
                        saveItem({
                          accessor: tables[currentTable],
                          Id: data.id,
                          prevId: data.id,
                          isNew: false,
                          data: ref.current,
                        });
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : null}
            </>
          }
          className={styles.dataContainer}
        >
          <EditorSave ref={ref} data={data} accessor={tables[currentTable]} />
        </Container>
      );
    },
    [canSave, currentTable, deleteItem, destroy, saveItem, tableData, tables, write]
  );

  if (tables.length === 0) return null;

  return (
    <div className={styles.container}>
      <div className={styles.tables}>
        {tables.map((table, index) => {
          return (
            <div
              className={`${styles.table} ${index === currentTable ? styles.active : ""}`}
              key={toWords(table)}
              onClick={() => {
                refMap.current = new Map();
                setCurrentTable(index);
              }}
            >
              {table}
              <div className={`${styles.total} ${index === currentTable ? styles.active : ""}`}>
                {data.get(table).size}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.data}>
        <div className={styles.search}>
          <span className={styles.label}>Id</span>
          <Form.Control
            type="text"
            placeholder="Id..."
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
            className={styles.inputId}
          />
          <span className={styles.label}>Title</span>
          <Form.Control
            type="text"
            placeholder="Title..."
            value={titleFilter}
            onChange={(e) => setTitleFilter(e.target.value)}
            className={styles.input}
          />
          {tables[currentTable] === "metadata" && (
            <>
              <Form.Check
                type="switch"
                id="volatile-switch"
                label="Volatile"
                value={volatileFilter}
                onChange={(e) => setVolatileFilter(e.target.checked)}
                className={styles.volatileSwitch}
              />
              <span className={styles.catalogBox}>
                <ContentType
                  rootNode={{ contentType: catalog }}
                  onlySelect={true}
                  allowAllType={true}
                  onChange={(c) => setCatalog(c)}
                />
              </span>
            </>
          )}
        </div>
        <div className={styles.dataBox}>
          <Virtuoso
            style={{ height: "648px" }}
            totalCount={tableData?.length}
            itemContent={drawRow}
          />
        </div>
      </div>
    </div>
  );
}

const EditorSave = forwardRef(({ data, accessor }, ref) => {
  const errorRef = useRef(false);
  const doRefs = useDoRef();

  const onMount = useCallback((editor, monaco) => {
    editor.trigger("fold", "editor.foldLevel2");
    editor.setScrollPosition({ scrollTop: 0 });
    editor.revealLine(1);
  }, []);

  const onChange = useCallback(
    (data) => {
      if (!errorRef.current) {
        ref.current = doRefs(accessor, JSON.parse(data));
      }
    },
    [accessor, doRefs, ref]
  );

  return (
    <Editor
      width="100%"
      height="400px"
      defaultLanguage="json"
      value={JSON.stringify(data, null, 2)}
      theme="vs-dark"
      onMount={onMount}
      onChange={onChange}
      onValidate={(m) => (errorRef.current = m.length > 0)}
      options={{ minimap: { enabled: false }, folding: true }}
    />
  );
});
