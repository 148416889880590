import { useCallback } from "react";
//import { useDispatch } from "react-redux";
import { useDataStateSelector, useDataSetStatus } from "./useData";
import { useMainContext } from "./useMainContext";
import { useNavigate } from "react-router-dom";
//import { resetModifiche } from "../redux/actions/modifiche";

export default function useConfirmNavigation({
  resetStatus = true,
  statusField = "unsavedData",
} = {}) {
  //const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestUnsavedConfirm } = useMainContext();
  const haveUnsavedChanges = useDataStateSelector((state) => state.unsavedData);
  const setDataStatus = useDataSetStatus();

  const confirmsNavigation = useCallback(
    (destination, cb) => {
      if (!haveUnsavedChanges) {
        destination && navigate(destination);
        cb && cb();
      } else {
        requestUnsavedConfirm(() => {
          // if (resetStatus) {
          //   //dispatch(resetModifiche());
          //   setDataStatus(statusField, false);
          // }
          setDataStatus(statusField, false);
          destination && navigate(destination);
          cb && cb();
        });
      }
    },
    [haveUnsavedChanges, navigate, requestUnsavedConfirm, setDataStatus, statusField]
  );

  return confirmsNavigation;
}
