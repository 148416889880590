import { useEffect, useMemo, useCallback } from "react";
import { useSelectMem } from "../../hooks/useMemoryDB";
import useMetadataValues from "../../hooks/useMetadataValues";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faList,
  faBars,
  faKeyboard,
  faCalendar,
  faCalendarAlt,
  faEgg,
  faFont,
  faYinYang,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { Button, Form } from "react-bootstrap";

import DateTime from "./dateTime";
import Range from "./range";
import Between from "./between";
import ValuesSelect from "../selects/selectValues";
import SelectMeta from "../selects/selectMeta";
import SelectAll from "../selects/selectAll";
import moment from "moment";

//import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";

const emptyValue = "";
const emptyObj = {};
const emptyArray = [];

const strip = (value) => String(value).replace(/"/g, "");
const quote = (value, type) => (type === "number" ? Number(value) : `"${value}"`);

export function filterTypes(fieldTypes, types) {
  if (fieldTypes?.includes("date")) {
    types = types.filter((t) => !["between", "number", "boolean"].includes(t));
  }
  if (["number", "boolean"].some((k) => fieldTypes?.includes(k))) {
    types = types.filter((t) => !["date", "DateRange", "dateRange"].includes(t));
  }

  return types;
}

function filterTypesFactory(fieldTypes) {
  return (types) => filterTypes(fieldTypes, types);
}

export default function MultipleValues(props) {
  const { handleBlur, errors } = useFormikContext() || emptyObj;

  const {
    name,
    valueType,
    metadataTypes,
    compactTypes,
    catalogs,
    contentType,
    field,
    realms,
    metadata,
    forceNoError,
    sanitizeTextTypes = false,
    handleChange,
    handleValueTypeChange,
    info,
  } = props;

  const fieldTypes = useSelectMem(
    "metadata",
    useCallback((i) => i.id === field, [field])
  )?.types;

  const fieldValues = useMetadataValues(catalogs, field);
  const errored = useMemo(() => get(errors, name), [errors, name]);
  const filterTypes = useMemo(() => filterTypesFactory(fieldTypes), [fieldTypes]);

  const value = useMemo(
    () => (props.value ? (sanitizeTextTypes ? strip(props.value) : props.value) : emptyValue),
    [props.value, sanitizeTextTypes]
  );

  //console.log(name, valueType, field, value, props.value, typeof value);

  const checkIsInList = useCallback(
    (type) => {
      if (!fieldValues) return false;
      if (fieldValues.length === 0) return false;
      if (isEmpty(value)) return true;

      const valore = (() => {
        if (isNaN(value)) {
          const d = moment(value);
          if (d.isValid()) return value;
        }

        return type === "number" ? Number(value) : value;
      })();
      //console.log("checkIsInList", type, valore);
      return fieldValues.includes(valore) || fieldValues.includes(Number(valore));
    },
    [fieldValues, value]
  );

  const types = useMemo(() => {
    let typesArray = (() => {
      if (!props.types) return emptyArray;
      if (Array.isArray(props.types)) return props.types;
      return props.types.split(",");
    })();

    typesArray = filterTypes(typesArray);

    // ***********************************************************************
    // Avoid "false" Next Type for Number + String
    // ***********************************************************************
    const numberIndex = typesArray.findIndex((t) => t === "number");
    const stringIndex = typesArray.findIndex((t) => t === "string");

    if (numberIndex > -1 && stringIndex > -1) {
      if (checkIsInList("string")) typesArray.splice(numberIndex, 1);
      else if (checkIsInList("number")) typesArray.splice(stringIndex, 1);
    }
    // ***********************************************************************

    return typesArray;
  }, [checkIsInList, filterTypes, props.types]);

  useEffect(() => {
    if (types?.length > 0 && !types.includes(valueType)) {
      const tipo = types[0];
      if (tipo) {
        if (handleValueTypeChange && tipo !== valueType) handleValueTypeChange(tipo);
      }
    }
  }, [handleValueTypeChange, types, valueType]);

  const onValueChange = useCallback(
    (e) => {
      let ret = (() => {
        const value = e && e.target ? e.target.value : e;
        if (sanitizeTextTypes && !["metadata", "list", "boolean"].includes(valueType))
          return quote(value);
        return value;
      })();

      handleChange(ret);
    },
    [handleChange, sanitizeTextTypes, valueType]
  );

  const nextType = useCallback(() => {
    const tipo = (() => {
      if (types.length === 1) return types[0];

      const currentIndex = types.findIndex((t) => t === valueType);
      const nextIndex = currentIndex + 1 < types.length ? currentIndex + 1 : 0;
      return types[nextIndex];
    })();

    if (tipo !== valueType) {
      //onValueChange("");
      handleValueTypeChange && handleValueTypeChange(tipo);
    }
  }, [handleValueTypeChange, types, valueType]);

  const currentValueIsValuesList = useMemo(
    () => checkIsInList(valueType),
    [checkIsInList, valueType]
  );

  const renderType = useMemo(() => {
    if (!valueType) return null;

    if (valueType === "between") {
      return <Between value={value} onChange={onValueChange} />;
    }

    if (valueType === "boolean") {
      return (
        <Form.Control as="select" value={value || ""} onChange={onValueChange}>
          <option></option>
          <option value={true}>True</option>
          <option value={false}>False</option>
        </Form.Control>
      );
    }
    if (valueType === "metadata") {
      return (
        <SelectMeta
          name={name}
          value={value || ""}
          handleChange={onValueChange}
          handleBlur={handleBlur}
          catalogs={catalogs || contentType}
          realms={realms || metadata}
          errored={forceNoError ? false : !value ?? errored}
          types={filterTypes(metadataTypes)} //types
          compactTypes={compactTypes}
          fieldTypes={fieldTypes}
          info={info}
        />
      );
    }
    if (valueType === "list") {
      return (
        <>
          <SelectAll
            accessor="lists"
            filters={{
              objectTypes: ["text", "contentId"],
              catalogs: catalogs ?? [contentType],
              exclude: [value],
            }}
            value={value}
            handleChange={onValueChange}
            isInvalid={!value || errored}
            style={{ width: "100%" }}
          />
        </>
      );
    }
    if (valueType === "range") {
      return <Range value={value} handleChange={onValueChange} errored={!value || errored} />;
    }
    if (valueType === "date") {
      return (
        <DateTime
          value={value}
          handleChange={onValueChange}
          errored={!value || errored}
          isRange={false}
        />
      );
    }
    if (valueType === "DateRange") {
      return (
        <DateTime
          value={value}
          handleChange={onValueChange}
          errored={!value || errored}
          isRange={true}
        />
      );
    }

    if (["number", "string", "freeText"].includes(valueType)) {
      if (valueType !== "freeText" && currentValueIsValuesList) {
        return (
          <ValuesSelect
            debugLabel={name}
            selectOptions={fieldValues}
            errored={!value || errored}
            name={name}
            handleChange={onValueChange}
            value={value || ""}
          />
        );
      }
      return (
        <Form.Control
          type={valueType === "number" ? "number" : "text"}
          min={0}
          isInvalid={!value || errored}
          name={name}
          onChange={onValueChange}
          onBlur={handleBlur}
          onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
          value={value || ""}
          placeholder="Enter value..."
        />
      );
    }
  }, [
    catalogs,
    compactTypes,
    contentType,
    currentValueIsValuesList,
    errored,
    fieldTypes,
    fieldValues,
    filterTypes,
    forceNoError,
    handleBlur,
    info,
    metadata,
    metadataTypes,
    name,
    onValueChange,
    realms,
    value,
    valueType,
  ]);

  const renderIcon = useMemo(() => {
    if (!valueType) return null;

    if (valueType === "between") return <FontAwesomeIcon icon={faExchangeAlt} fixedWidth />;
    if (valueType === "metadata") return <FontAwesomeIcon icon={faTag} fixedWidth />;
    if (valueType === "list") return <FontAwesomeIcon icon={faList} fixedWidth />;

    if (
      (valueType === "number" || valueType === "string") &&
      fieldValues &&
      fieldValues.length &&
      currentValueIsValuesList
    )
      return <FontAwesomeIcon icon={faBars} fixedWidth />;

    if (valueType === "boolean") return <FontAwesomeIcon icon={faYinYang} fixedWidth />;
    if (valueType === "number") return <span className="numberIcon" />;
    if (valueType === "string") return <FontAwesomeIcon icon={faKeyboard} fixedWidth />;
    if (valueType === "freeText") return <FontAwesomeIcon icon={faFont} fixedWidth />;
    if (valueType === "range") return <FontAwesomeIcon icon={faEgg} fixedWidth />;
    if (valueType === "date") return <FontAwesomeIcon icon={faCalendar} fixedWidth />;
    if (valueType === "DateRange") return <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />;
  }, [valueType, fieldValues, currentValueIsValuesList]);

  if (!types) return null;
  if (!valueType) return <div className="loading-value"></div>;

  return (
    <div className="value-box">
      <div className="select">{renderType}</div>
      <Button size="sm" variant="secondary" className="btn" onClick={nextType}>
        {renderIcon}
      </Button>
    </div>
  );
}

// const getType = useCallback(() => {
//   if (!listRaw || !metaRaw || !types) return;

//   //const value = _val || valueRef.current || "";
//   const typesArray = types ? (Array.isArray(types) ? types : types.split(",")) : emptyArray;

//   const isInlist = listRaw.has(value);
//   const isInMeta = metaRaw.has(value);

//   // *************************************
//   // Return Type if one of special ones
//   // *************************************

//   if (typesArray.includes("range")) return "range";
//   if (typesArray.includes("DateRange")) return "DateRange";
//   if (typesArray.includes("list") && !isInMeta) return "list";
//   if (!isInMeta && fieldValues && fieldValues.length)
//     return typesArray.includes("number") ? "number" : "string";
//   if (typesArray.length === 1) return typesArray[0];

//   // *************************************
//   // Otherwise try to guess the type
//   // *************************************
//   if (isInMeta) return "metadata";
//   if (isInlist) return "list";

//   if (typesArray.includes("freeText")) return "string";

//   if (!isNaN(Date.parse(value)) && typesArray.includes("date")) return "date";

//   const [range, period] = Array.isArray(value) ? value : value.split(" ");
//   if (
//     !isNaN(range) &&
//     ["s", "m", "h", "d", "w", "M", "y"].includes(period) &&
//     typesArray.includes("range")
//   )
//     return "range";

//   const [d1, d2] = Array.isArray(value) ? value : value.split("÷");
//   if (!isNaN(Date.parse(d1)) && !isNaN(Date.parse(d2)) && typesArray.includes("DateRange"))
//     return "DateRange";

//   if (!isNaN(value) && typesArray.includes("number")) return "number";
//   if (typesArray.includes("string")) return "string";

//   // *************************************
//   // If guessed no one return the first
//   // *************************************
//   return typesArray[0];
// }, [fieldValues, listRaw, metaRaw, types, value]);
