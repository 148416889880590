import { Form } from "react-bootstrap";
import RotaryKnob from "../../../rotaryKnob";

import styles from "./styles.module.scss";

export default function SplitterAndPerc(props) {
  const { execute, handleChange } = props;

  return (
    <div className={styles.splitterAndPercBox}>
      <div className={styles.select}>
        <div className={styles.label}>Split Char</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            onChange={(e) => handleChange("splitter", e)}
            value={execute.splitter}
          >
            <option></option>
            <option>,</option>
            <option value=" ">(space)</option>
            <option>;</option>
            <option>|</option>
          </Form.Control>
        </div>
      </div>
      <div>
        <RotaryKnob
          value={Number(execute.percentage) || 1}
          min={1}
          max={100}
          step={1}
          label="Percentage"
          labelPosition="right"
          skin="s12"
          onChange={(value) => handleChange("percentage", value)}
        />
      </div>
    </div>
  );
}
