import Item from "../item";
import Conversion from "./conversion";
import validate from "./validation";

export function doConversionRefs(values) {
  return values;
}
export default function ConversionIndex(props) {
  return (
    <Item
      name="Conversion"
      accessor="conversions"
      onSubmit={doConversionRefs}
      onValidate={validate}
    >
      <Conversion />
    </Item>
  );
}
