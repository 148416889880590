import { forwardRef } from "react";
import Iframe from "react-iframe";

import ModalBox from "./modalBox";

const ListBug = forwardRef((props, ref) => {
  return (
    <ModalBox ref={ref}>
      <Iframe
        class="airtable-embed"
        src="https://airtable.com/embed/shrTnrAB04SbOee2H?backgroundColor=black&layout=card"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="433px"
        style={{ background: "transparent", borderRadius: "0px" }}
        display="initial"
        position="relative"
        frameBorder="0"
      ></Iframe>
    </ModalBox>
  );
});

export default ListBug;
