import { useMemo, useEffect, useCallback, useState } from "react";
import { useFormikContext } from "formik";
import { useItemContext } from "../../hooks/useItem";
import { useDataRefObj } from "../../hooks/useDataRef";
import { useGetMemItem, useSelectMem } from "../../hooks/useMemoryDB";
import { useSelector } from "react-redux";
import { useDataSettings } from "../../hooks/useData";
import Link from "../link";
import Tabs, { TabPane } from "rc-tabs";
import {
  Form,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faFlask,
  faHourglassHalf,
  faLink,
  faTools,
} from "@fortawesome/free-solid-svg-icons";

import get from "lodash.get";
import set from "lodash.set";
import camelCase from "lodash.camelcase";

import SelectAll from "../selects/selectAll";
import SelectAllMultiple from "../selects/selectAllMultiple";
import Duration from "./duration";
import RequiredFields from "./requiredFields";
import EndpointExplorer from "../endpointExplorer";
import Snapshots from "../snapshots";
import Container from "../container";
import TreeExplorer from "./treeExplorer";
//import AnaliticaBox from "../analitica/analiticaBox";
import AnaliticaExplorer from "../analitica/analiticaExplorer";
import RefBox from "../item/ref";
import Filters from "./filters";

import styles from "./styles.module.scss";
import "./style.scss";

const requiredPath = "properties.query.required";
const selectStyle = { width: "366px" };
const inputDecoratorFilters = { objectTypes: ["endpoint-in"] };
const outputDecoratorFilters = { objectTypes: ["endpoint-out"] };
const emptyObj = {};

export default function Endpoint(props) {
  const {
    showUX = true,
    showBlock = true,
    showActive = true,
    showCacheKeys = true,
    showSnapshots = true,
    showSiteMap = true,
    showRef = false,
  } = props;
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const info = useDataRefObj("endpoints", values);

  const {
    endpointsParamsDefaults,
    endpointsSchemaDefaults,
    endpointsMandatoryInputFields,
    endpointsMandatoryInputFieldsPerMeta,
  } =
    useDataSettings(
      "endpointsParamsDefaults",
      "endpointsSchemaDefaults",
      "endpointsMandatoryInputFields",
      "endpointsMandatoryInputFieldsPerMeta"
    ) || {};

  const [currentTab, setCurrentTab] = useState("settings");
  const { nickname, id_token, tenant } = useSelector((state) => state.user);
  const getBlock = useGetMemItem("blocks");
  const getQuery = useGetMemItem("queries");
  const params = useGetMemItem("params", values.paramsId);
  const block = useGetMemItem("blocks", values.blockId);
  const [mandatoryFields, setMandatoryFields] = useState(
    endpointsMandatoryInputFields || []
  );
  const { itemId } = useItemContext() || {};
  // const existingReference =
  //   useSelectMem("endpoints", (i) => i.reference === values?.reference)
  //     ?.length > 0;
  // const referenceError = useMemo(
  //   () => (isNew && existingReference ? true : false),
  //   [isNew, existingReference]
  // );

  // useEffect(() => {
  //   if (referenceError && !errors.reference && !errors.settings) {
  //     setFieldError("settings", true);
  //     setFieldError("reference", true);
  //   }
  // }, [errors.reference, errors.settings, referenceError, setFieldError]);

  const blockFilters = useMemo(() => {
    const filters = { isPage: false };
    if (info?.restricted) filters.canBeFallback = true;
    return filters;
  }, [info?.restricted]);

  const cacheActive = useMemo(() => {
    const cache = get(values, "params.cacheConfig.cache");

    if (cache) return true;

    return false;
  }, [values]);

  const handleChangeFilters = useCallback(
    (filters) => {
      setFieldValue("params.cacheConfig.keysFilters", filters);
    },
    [setFieldValue]
  );

  const handleChangeUserIdFields = useCallback(
    (fields) => {
      setFieldValue("params.userIdFields", fields);
    },
    [setFieldValue]
  );

  const handleReferenceChange = useCallback(
    (e) => {
      const ux = camelCase(e.target.value);

      setFieldValue("reference", ux);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (values.params === undefined) {
      setFieldValue("params", endpointsParamsDefaults);
    }
    if (values.active === undefined) {
      setFieldValue("active", true);
    }

    if (
      !Object.keys(get(values, "input.schema") || {}).length > 0 &&
      endpointsSchemaDefaults &&
      endpointsMandatoryInputFields
    ) {
      set(endpointsSchemaDefaults, requiredPath, endpointsMandatoryInputFields);
      setMandatoryFields(endpointsMandatoryInputFields);
      setFieldValue("input.schema", endpointsSchemaDefaults);
    }
  }, [
    endpointsMandatoryInputFields,
    endpointsParamsDefaults,
    endpointsSchemaDefaults,
    setFieldValue,
    values,
  ]);

  useEffect(() => {
    if (values.blockId) {
      getBlock(values.blockId, (block) => {
        let metas = [];
        let mandatory = [];

        for (const sub of block?.blocks || []) {
          if (sub.queryId) {
            const query = getQuery(sub.queryId);
            metas = [...metas, ...(query?.metadataInRules || [])];
          }
        }

        metas = [...new Set(metas)];

        for (const meta of metas) {
          if (endpointsMandatoryInputFieldsPerMeta[meta]) {
            mandatory = [
              ...mandatory,
              ...endpointsMandatoryInputFieldsPerMeta[meta],
            ];
          }
        }
        mandatory = [...new Set(mandatory)];
        //setRequiredIn((state) => [...new Set([...state, ...mandatory])]);
        setMandatoryFields([
          ...(endpointsMandatoryInputFields || []),
          ...mandatory,
        ]);
      });
    }
  }, [
    endpointsMandatoryInputFields,
    endpointsMandatoryInputFieldsPerMeta,
    getBlock,
    getQuery,
    values.blockId,
  ]);

  const handleChangeOutputFilters = useCallback(
    (obj) => {
      setFieldValue("output.filters", obj);
    },
    [setFieldValue]
  );

  const handleChangeBlock = useCallback(
    (value) => {
      setFieldValue("blockId", value);
    },
    [setFieldValue]
  );

  const endpointId = useMemo(() => values.id, [values.id]);
  const fieldsPath = useMemo(() => `input.schema.${requiredPath}`, []);

  useEffect(() => {
    if (!values.prevReference) setFieldValue("prevReference", values.reference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputFieldsValue = useMemo(() => {
    return [
      ...new Set([
        ...(get(values, fieldsPath) || []),
        ...(endpointsMandatoryInputFields || []),
      ]),
    ];
  }, [endpointsMandatoryInputFields, fieldsPath, values]);

  const [suggestedFields, setSuggestedFields] = useState([]);
  const updateFields = useCallback(
    (fields) => {
      setSuggestedFields(fields.filter((f) => !inputFieldsValue.includes(f)));
    },
    [inputFieldsValue]
  );

  const addLabel = useCallback((elements, key, value) => {
    elements.push(
      <div className="item" key={`${key}-${value}`}>
        <div className="key">{key}</div>
        <div className="value">{value}</div>
      </div>
    );
  }, []);

  const handleChangeParams = useCallback(
    (value) => setFieldValue("paramsId", value),
    [setFieldValue]
  );

  const toggleSiteMap = useCallback(
    (e) => {
      const checked = e.target.checked;
      setFieldValue("siteMapped", checked);
    },
    [setFieldValue]
  );

  const settingsExtra = useMemo(() => {
    return (
      <div className="settings-extra">
        <Form.Check
          type="switch"
          id="enableAnalitica"
          checked={Boolean(values.enableAnalitica || false)}
          value="true"
          onChange={handleChange}
          onBlur={handleBlur}
          name="enableAnalitica"
          label={"Enable Analitica"}
        />
        {showSiteMap ? (
          <Form.Check
            type="switch"
            id={`${values.id}-siteMap`}
            checked={values.siteMapped}
            value="true"
            onChange={toggleSiteMap}
            name="Include in SiteMap"
            label="Include in SiteMap"
            className="sitemap-check"
          />
        ) : null}
        {showActive ? (
          <Form.Check
            type="switch"
            id="active"
            checked={Boolean(values.active || false)}
            value="true"
            onChange={handleChange}
            onBlur={handleBlur}
            name="active"
            label={"Active"}
            className="active-check"
          />
        ) : null}
      </div>
    );
  }, [
    handleBlur,
    handleChange,
    showActive,
    showSiteMap,
    toggleSiteMap,
    values.active,
    values.id,
    values.enableAnalitica,
    values.siteMapped,
  ]);

  const settingsHeader = useMemo(() => {
    const e = [];

    if (values?.reference) addLabel(e, "UX", values.reference);
    if (block?.title) addLabel(e, "Block", block.title);
    if (params?.title) addLabel(e, "Params", params.title);

    return <div className="endpoint-header">{e}</div>;
  }, [addLabel, block, params, values.reference]);

  const paramsHeader = useMemo(() => {
    const e = [];
    const cache = values?.params?.cacheConfig?.cache;

    if (cache === "onlyAnonymousUsers") {
      addLabel(e, "Cache", "Only Anonymous Sessions");
    } else addLabel(e, "Cache", !!cache ? "Always" : "Never");

    if (![undefined, false, 0].includes(cache)) {
      addLabel(
        e,
        "Cache Duration",
        <Duration
          value={values.params?.cacheConfig?.cacheTTL || 0}
          showIcon={false}
        />
      );
    }

    addLabel(
      e,
      "Timeout",
      <Duration
        value={values.params?.timeout}
        unit="milliseconds"
        decimalSeconds={true}
        showIcon={false}
      />
    );

    return <div className="endpoint-header">{e}</div>;
  }, [addLabel, values.params]);

  const paramsExtra = useMemo(() => {
    if (!values?.params?.cacheConfig.cache) return null;

    return (
      <Form.Check
        type="switch"
        id="paginateFromCache"
        checked={Boolean(values.paginateFromCache || false)}
        value="true"
        onChange={(e) =>
          setFieldValue("paginateFromCache", e.target.checked ? 1 : 0)
        }
        name="paginateFromCache"
        label={"Paginate From Cache"}
      />
    );
  }, [
    setFieldValue,
    values.paginateFromCache,
    values?.params?.cacheConfig.cache,
  ]);

  const requiredFieldsHeader = useMemo(() => {
    const required = get(values, `input.schema.${requiredPath}`) || [];
    return (
      <div className="required-badge">
        <span>{required.length}</span> Required
      </div>
    );
  }, [values]);

  const decoratorsHeader = useMemo(() => {
    return (
      <div className="decorator-header">
        <div className="decorator-badge">
          <span>{values.input?.decorators?.length || 0}</span> Input
        </div>
        <div className="decorator-badge">
          <span>{values.output?.decorators?.length || 0}</span> Output
        </div>
      </div>
    );
  }, [values]);

  const handleInputDecorators = useCallback(
    (d) => setFieldValue("input.decorators", d),
    [setFieldValue]
  );
  const handleOutputDecorators = useCallback(
    (d) => setFieldValue("output.decorators", d),
    [setFieldValue]
  );

  return (
    <>
      <Tabs
        tabBarGutter={0}
        activeKey={currentTab}
        onTabClick={setCurrentTab}
        tabBarExtraContent={
          showRef ? (
            <RefBox data={values ?? emptyObj} accessor="endpoints" />
          ) : null
        }
      >
        <TabPane
          key="settings"
          tab={
            <div className={styles.pageTab}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faTools} className={styles.icon} />
              </span>
              <span className={styles.label}>Settings</span>
            </div>
          }
        >
          <div className={styles.tabContainer}>
            <Container
              name="Settings"
              help="endpoint-settings"
              title="Settings"
              folded={true}
              header={settingsHeader}
              extra={settingsExtra}
              warning={errors.settings}
            >
              <>
                {showUX ? (
                  <Row>
                    <Col sm="1">UX</Col>
                    <Col sm="11">
                      <Form.Control
                        type="text"
                        placeholder="Enter UX Reference"
                        name="reference"
                        value={values.reference || ""}
                        onChange={handleReferenceChange}
                        onBlur={handleBlur}
                        isInvalid={errors.reference}
                        style={selectStyle}
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col sm="1">Params</Col>
                  <Col sm="11">
                    <SelectAll
                      accessor="params"
                      value={values.paramsId}
                      handleChange={handleChangeParams}
                      isInvalid={errors.paramsId}
                      style={selectStyle}
                    />
                  </Col>
                </Row>
                {showBlock ? (
                  <Row>
                    <Col sm="1">Block</Col>
                    <Col sm="11">
                      <div className="blockRow">
                        <SelectAll
                          accessor="blocks"
                          value={values.blockId}
                          handleChange={handleChangeBlock}
                          isInvalid={errors.blockId}
                          style={selectStyle}
                          filters={blockFilters}
                        />
                        {values.blockId && (
                          <Link to={`/blocks/${values.blockId}`}>
                            <Button
                              size="sm"
                              variant="dark"
                              className="linkButton"
                            >
                              <FontAwesomeIcon icon={faLink} />
                            </Button>
                          </Link>
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </>
            </Container>
            <Container
              name="Params"
              help="endpoint-params"
              title="Params"
              folded={true}
              header={paramsHeader}
              extra={paramsExtra}
            >
              <div className="params">
                <div className="params-box">
                  <div className="label">Cache</div>
                  <Form.Control
                    as="select"
                    name="params.cacheConfig.cache"
                    value={get(values, "params.cacheConfig.cache")}
                    onChange={(e) => {
                      const target = e.target.value;
                      const value = !isNaN(target) ? Number(target) : target;

                      setFieldValue("params.cacheConfig.cache", value);
                      if (!value && get(values, "paginateFromCache")) {
                        setFieldValue("paginateFromCache", 0);
                      }
                    }}
                    onBlur={handleBlur}
                    style={selectStyle}
                  >
                    <option value={0}>Never</option>
                    <option value={1}>Always </option>
                    <option value="onlyAnonymousUsers">
                      Only Anonymous Sessions
                    </option>
                  </Form.Control>
                  {cacheActive ? (
                    <>
                      <div className="label">Duration</div>
                      <InputGroup className="input-cache-duration-group">
                        <InputGroup.Text
                          id="inputGroupPrepend"
                          className="prepend-time"
                        >
                          <OverlayTrigger
                            key="tooltip-cache-durration"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-cache-durration-top">
                                Cache Duration in <strong>Seconds</strong>.
                              </Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon={faHourglassHalf} />
                            </span>
                          </OverlayTrigger>
                        </InputGroup.Text>

                        <Form.Control
                          type="number"
                          name="params.cacheConfig.cacheTTL"
                          value={get(values, "params.cacheConfig.cacheTTL")}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setFieldValue("params.cacheConfig.cacheTTL", value);
                            setFieldValue(
                              "params.inveverseCacheConfig.cacheTTL",
                              value
                            );
                          }}
                          onBlur={handleBlur}
                          max={31536000}
                          min={60}
                          className="input-cache-duration"
                        />
                      </InputGroup>

                      <Duration
                        value={get(values, "params.cacheConfig.cacheTTL")}
                      />
                    </>
                  ) : null}
                </div>
                <div className="params-box">
                  <div className="label">Timeout</div>

                  <InputGroup className="input-cache-duration-group">
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      className="prepend-time"
                    >
                      <OverlayTrigger
                        key="tooltip-cache-durration"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-cache-durration-top">
                            Timeout Duration in <strong>Seconds</strong>.
                          </Tooltip>
                        }
                      >
                        <span>
                          <FontAwesomeIcon icon={faHourglassHalf} />
                        </span>
                      </OverlayTrigger>
                    </InputGroup.Text>

                    <Form.Control
                      type="number"
                      name="timeout"
                      value={
                        (get(values, "params.timeout") ||
                          get(endpointsParamsDefaults, "timeout")) / 1000
                      }
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setFieldValue("params.timeout", value * 1000);
                      }}
                      onBlur={handleBlur}
                      max={600}
                      min={0.01}
                      step={0.01}
                    />
                  </InputGroup>
                  <Duration
                    value={get(values, "params.timeout")}
                    unit="milliseconds"
                    decimalSeconds={true}
                  />
                </div>
              </div>
            </Container>
            <Container
              title="Required Fields"
              help="endpoint-required-fields"
              folded={suggestedFields.length > 0 ? false : true}
              header={requiredFieldsHeader}
            >
              <RequiredFields
                fields={inputFieldsValue}
                mandatory={mandatoryFields}
                onChange={(flds) =>
                  setFieldValue(`input.schema.${requiredPath}`, flds)
                }
                suggested={suggestedFields}
              />
            </Container>
            <Container
              title="Decorators"
              help="endpoint-decorators"
              folded={true}
              header={decoratorsHeader}
            >
              <div className="decorators-box">
                <Container
                  title="Input"
                  help="endpoint-decorators-input"
                  folded={false}
                  foldable={false}
                  coloredBars={false}
                  className="decorators-sub"
                >
                  <SelectAllMultiple
                    accessor="decorators"
                    filters={inputDecoratorFilters}
                    handleChange={handleInputDecorators}
                    values={values.input?.decorators}
                  />
                </Container>
                <Container
                  title="Output"
                  help="endpoint-decorators-output"
                  folded={false}
                  foldable={false}
                  coloredBars={false}
                  className="decorators-sub"
                  noMarginLast={true}
                >
                  <SelectAllMultiple
                    accessor="decorators"
                    filters={outputDecoratorFilters}
                    handleChange={handleOutputDecorators}
                    values={values.output?.decorators}
                  />
                </Container>
              </div>
            </Container>
            <Container
              title="Accessors"
              help="endpoint-output-accessors"
              folded={true}
              foldable={true}
              className="accessors"
              header={
                <div className="accessor-header">
                  <div className="accessor-header-label">Blocks</div>
                  <div className="accessor-header-value">
                    {values.blocksAccessor || "blocks"}
                  </div>
                  <div className="accessor-header-label">Items</div>
                  <div className="accessor-header-value">
                    {values.itemsAccessor || "items"}
                  </div>
                </div>
              }
            >
              <div className="accessor-label">Blocks</div>
              <Form.Control
                type="text"
                placeholder="blocks"
                onChange={(e) =>
                  setFieldValue("blocksAccessor", e.target.value)
                }
              />
              <div className="accessor-label last">Items</div>
              <Form.Control
                type="text"
                placeholder="items"
                onChange={(e) => setFieldValue("itemsAccessor", e.target.value)}
              />
            </Container>
            {showCacheKeys ? (
              <>
                <EndpointExplorer
                  endpointId={endpointId}
                  endpoint={values}
                  onChangeFilters={handleChangeFilters}
                  onChangeUserIdFields={handleChangeUserIdFields}
                  onData={updateFields}
                  defaultCacheKeys={endpointsMandatoryInputFields}
                  paginateFromCache={values.paginateFromCache}
                />
                <Filters
                  data={values.output?.filters}
                  onChange={handleChangeOutputFilters}
                />
              </>
            ) : null}
            {showSnapshots ? (
              <Snapshots
                itemId={values.blockId}
                endpointId={values.id || itemId}
                endpointRef={values.reference}
                tenant={tenant}
                id_token={id_token}
                user={nickname}
              />
            ) : null}
          </div>
        </TabPane>
        <TabPane
          key="analitica"
          tab={
            <div className={styles.pageTab}>
              <span className={`${styles.icon} ${styles.iconAnalitica}`}>
                <FontAwesomeIcon
                  icon={faFlask}
                  className={`${styles.icon} ${styles.iconAnalitica}`}
                />
              </span>
              <span className={styles.label}>Analitica</span>
            </div>
          }
        >
          <div className={styles.tabContainer}>
            {/* <AnaliticaBox
              forceSearch={true}
              forceRecommended={true}
              endpointId={values.id || itemId}
            /> */}
            <AnaliticaExplorer
              forceSearch={true}
              forceRecommended={true}
              endpointId={values.id || itemId}
              disableSelect={true}
            />
          </div>
        </TabPane>
        <TabPane
          key="explorer"
          tab={
            <div className={styles.pageTab}>
              <span className={`${styles.icon} ${styles.iconLab}`}>
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  className={`${styles.icon} ${styles.iconLab}`}
                />
              </span>
              <span className={styles.label}>Explorer</span>
            </div>
          }
        >
          <div className={styles.tabContainer}>
            <TreeExplorer endpointId={endpointId} />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}
