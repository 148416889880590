import { useCallback, useMemo, Fragment } from "react";
import { useFormikContext } from "formik";
import db from "../../hooks/useMemoryDB";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import AndOrSelector from "../query/andOrSelector";
import Container from "../container";
import ViewAll from "../viewAll";

import styles from "./styles.module.scss";

export default function UseCaseFilter() {
  const { values, errors, setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (data, index) => {
      const newCases = values.useCases.cases.slice();

      const updateData = (property) => {
        newCases[index] = {
          id: data.id,
          endpoint: data.reference,
          variantId: data.blockId,
          blockId: data.subBlockId,
          property,
        };

        setFieldValue("useCases", { ...values.useCases, cases: newCases });
      };

      const endpoint = db.get("endpoints", data.id);
      if (endpoint.propertyId) {
        const property = db.get("properties", endpoint.propertyId);
        updateData(property.title);
      } else updateData();
    },
    [setFieldValue, values.useCases]
  );

  const convertUseCase = useCallback((data) => {
    return {
      id: data.id,
      reference: data.endpoint,
      blockId: data.variantId,
      subBlockId: data.blockId,
    };
  }, []);

  const addCase = useCallback(() => {
    const newData = values.useCases ? { ...values.useCases } : { operator: "and", cases: [] };
    const newCases = (newData.cases ?? []).slice();

    newCases.push({});
    newData.cases = newCases;

    setFieldValue("useCases", newData);
  }, [values.useCases, setFieldValue]);

  const deleteCase = useCallback(
    (index) => {
      const newCases = values.useCases.cases.slice();
      newCases.splice(index, 1);

      setFieldValue("useCases", { ...values.useCases, cases: newCases });
    },
    [setFieldValue, values.useCases]
  );

  const header = useMemo(() => {
    return (
      <AndOrSelector
        showTie={false}
        onChangeOperator={(value) => setFieldValue(`useCases.operator`, value)}
        operator={values?.useCases?.operator}
      />
    );
  }, [setFieldValue, values?.useCases?.operator]);

  const extra = useMemo(() => {
    return (
      <div className={styles.useCasesExtra}>
        <Button
          size="sm"
          onClick={addCase}
          variant="outline-primary"
          className={styles.metaSelectBtn}
        >
          <FontAwesomeIcon icon={faPlusSquare} className={styles.metaSelectIcon} /> Add
        </Button>
      </div>
    );
  }, [addCase]);

  return (
    <Container
      title="Endpoints"
      help="content-pack-use-cases-filter"
      color="#1a4301"
      coloredBars={false}
      coloredTitle={false}
      footer={extra}
      header={header}
      headerOnlyOnFolded={false}
      foldable={true}
      folded={!values.useCases?.cases?.length > 0}
      noMarginLast={true}
      warning={errors.useCases}
    >
      <div className={styles.useCasesBox}>
        {values.useCases?.cases?.map((data, index) => {
          return (
            <Fragment key={`view-all-${index}`}>
              <ViewAll
                key={`view-all-${index}`}
                data={convertUseCase(data)}
                onChange={(value) => handleChange(value, index)}
                uniqueId="use-case-filter"
                title={<span className={styles.caseTitle}>🎯</span>}
                color="#b5179e"
                foldable={true}
                folded={true}
                siteMap={false}
                forceEditorial={true}
                pageBlockChooser={true}
                onlyWithAnalitica={true}
                noMarginLast={true}
                isButton={true}
                className={styles.subCase}
                extra={
                  <span className={styles.subExtra} onClick={() => deleteCase(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                }
              />
              {index < values.useCases.cases.length - 1 ? (
                <div className={styles.operator}>{values.useCases.operator}</div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </Container>
  );
}
