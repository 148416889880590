import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";

import rootReducer from "./reducers";

const composeEnhancers = composeWithDevTools({
  name: "ARES-CONTROL-CENTER"
});
export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);
