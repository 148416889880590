import { useMemo, useState, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import moment from "moment";

import Parameters from "./parameters";
import Results from "./results";
import Split from "./display/split";
import Flat from "./display/flat";
import { explorer } from "../../../transport";

function getTodayDate() {
  const date = new Date();
  // zero hours, minutes and seconds
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
}

const AnaliticaBox = forwardRef((props, ref) => {
  const {
    splitView,
    flatView,
    parameters,
    conversions,
    endpointId,
    forceSearch,
    forceRecommended,
    onReload,
  } = props;

  const mode = props.mode ?? "conversions";
  const [loading, setLoading] = useState(false);
  const [numResults, setNumResults] = useState(0);
  const [data, setData] = useState([]);
  const { tenant, id_token } = useSelector((state) => state.user);

  const startLoading = useCallback(() => {
    setLoading(true);
    setNumResults(null);
  }, []);

  const stopLoading = useCallback((count) => {
    setLoading(false);
    setNumResults(count);
  }, []);

  const reload = useCallback(
    (parameters) => {
      startLoading();

      explorer.post("analitica/conversions", parameters).then(({ data }) => {
        //console.log(data);
        setData(data);
        stopLoading(data.conversions?.length || 1);
      });
    },
    [startLoading, stopLoading]
  );

  const formik = useFormik({
    initialValues: parameters || {
      nunOfResponses: 50,
      dateRange: { start: moment().subtract(1, "week").toISOString(), end: moment().toISOString() },
      relativeRange: { period: 2, unit: "d", startDate: getTodayDate(), comparePrevious: false },
      rangeMode: "relative",
      splitResults: false,
      endpointId,
    },
    onSubmit: (params, { setSubmitting }) => {
      setSubmitting(true);
      const newParams = { ...params, tenant };

      if (!newParams.conversions?.length > 0) newParams.conversions = conversions;
      else newParams.conversions = newParams.conversions.map((c) => c.value);

      if (newParams.metrics?.length > 0) newParams.metrics = newParams.metrics.map((m) => m.value);

      if (onReload) onReload(newParams, id_token);
      else reload(newParams);

      setSubmitting(false);
    },
    validate: (values) => {
      let errors = {};

      if (!values.endpoint) {
        errors.endpoint = "Select an Endpoint";
      }

      if (mode === "conversions") {
        if ((conversions || []).length === 0 && !values.conversions?.length > 0)
          errors.conversions = "Select a Conversion";
      } else if (mode === "metrics") {
        if (!values.metrics?.length > 0) {
          errors.metrics = "Select a Metric";
        }
      }

      return errors;
    },
  });

  const isSearch = useMemo(
    () =>
      conversions?.includes("search") ||
      formik.values.conversions?.findIndex((p) => p.value === "search") > -1,
    [conversions, formik.values.conversions]
  );

  const children = useMemo(() => {
    if (formik.values.splitResults) return splitView ?? <Split data={data} mode={mode} />;
    else return flatView ?? <Flat data={data} mode={mode} />;
  }, [data, flatView, formik.values.splitResults, mode, splitView]);

  if (ref?.current) ref.current = { formik, loading, startLoading, stopLoading };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Parameters
        formik={formik}
        forceSearch={forceSearch}
        forceRecommended={forceRecommended}
        isSearch={isSearch}
        conversions={conversions}
        mode={mode}
      />
      <Results
        children={children}
        formik={formik}
        isSearch={isSearch}
        loading={loading}
        numResults={numResults}
        mode={mode}
      />
    </Form>
  );
});

export default AnaliticaBox;
