import { forwardRef } from "react";
import Iframe from "react-iframe";

import ModalBox from "./modalBox";

import styles from "./bugs.module.scss";

const SubmitBug = forwardRef((props, ref) => {
  return (
    <ModalBox ref={ref} className={styles.submitNewBug}>
      <Iframe
        class="airtable-embed"
        src="https://airtable.com/embed/shrZSg8hMOBmjozKc?backgroundColor=black"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="433px"
        style={{ background: "transparent", borderRadius: "0px" }}
        display="initial"
        position="relative"
        frameBorder="0"
      ></Iframe>
    </ModalBox>
  );
});

export default SubmitBug;
