import { useMemo, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useGetMemTable } from "../../../hooks/useMemoryDB";
import { useDataItemsIds } from "../../../hooks/useDataRef";
import cloneDepp from "lodash.clonedeep";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import "./styles.scss";
import styles from "./styles.module.scss";

const stripPages = (dx) => {
  if (!dx) return;

  const data = cloneDepp(dx).filter((node) => {
    if (node.children && node.children.length > 0) {
      node.children = stripPages(node.children);
      return true;
    }

    return false;
  });

  return data;
};

const enableNodes = (dx, selected = new Set(), expanded = new Set(), onlyEndpoints, endpoints) => {
  if (!dx) return;
  endpoints = endpoints?.has ? endpoints : new Set(endpoints);

  const data = cloneDepp(dx).map((node) => {
    node.disabled = (() => {
      if (onlyEndpoints) {
        if (endpoints.has(node.value)) return false;
        return true;
      }
      return false;
    })();

    if (selected.has(node.value)) {
      node.checked = true;
    }

    if (expanded.has(node.value)) {
      node.expanded = true;
    }

    if (node.children && node.children.length > 0) {
      node.children = enableNodes(node.children, selected, expanded, onlyEndpoints, endpoints);
    }

    return node;
  });

  return data;
};

const removeChildren = (dx, expanded = new Set()) => {
  if (!dx) return expanded;

  dx.forEach((node) => {
    expanded.delete(node.value);
    if (node.children) removeChildren(node.children, expanded);
  });

  return expanded;
};

const addChildren = (dx, expanded = new Set()) => {
  if (!dx) return expanded;

  dx.forEach((node) => {
    expanded.add(node.value);
    if (node.children) addChildren(node.children, expanded);
  });

  return expanded;
};

const findNodeChildren = (id, data) => {
  if (data.value === id) return data.children;
  for (const node of data.children) {
    if (node.value === id) return node.children;
    if (node.children) {
      const kid = findNodeChildren(id, node);
      if (kid) return kid;
    }
  }
};

export default function PropertiesSelect(props) {
  const {
    propertyId,
    onlyFolders,
    onlyEndpoints = true,
    selected,
    onChange,
    exclude,
    multiple = true,
    placeholder = "Choose Property",
    container,
    style,
  } = props;

  const properties = useGetMemTable("properties");
  const endpoints = useDataItemsIds("endpoints");
  const [expanded, setExpanded] = useState(new Set());
  const [checked, setChecked] = useState(new Set([...(selected || [])]));

  const data = useMemo(() => {
    if (!properties) return;

    const proprieta = [...properties.values()];

    const ret = {
      value: "*",
      label: "All",
      disabled: true,
      expanded: true,
      children: [],
    };

    let data = propertyId ? [proprieta.find((p) => p.id === propertyId)] : proprieta;

    data.forEach((prop) => {
      if (exclude?.includes(prop.id)) return;

      const children = enableNodes(
        onlyFolders ? stripPages(prop.tree) : prop.tree,
        checked,
        expanded,
        onlyEndpoints,
        endpoints
      );

      ret.children.push({
        value: prop.id,
        label: prop.title,
        disabled: onlyEndpoints, //true,
        expanded: expanded.has(prop.id),
        children,
      });
    });

    return ret;
  }, [checked, endpoints, exclude, expanded, onlyEndpoints, onlyFolders, properties, propertyId]);

  const onToggle = useCallback(
    (node) => {
      const newExp = new Set([...expanded]);

      if (node.expanded) {
        newExp.add(node.value);
      } else {
        newExp.delete(node.value);
        removeChildren(findNodeChildren(node.value, data), newExp);
      }
      setExpanded(newExp);
    },
    [data, expanded]
  );

  const handleCheck = useCallback(
    (node) => {
      const newCheck = multiple ? new Set([...checked]) : new Set();

      if (node.checked) {
        newCheck.add(node.value);
        if (multiple) addChildren(findNodeChildren(node.value, data), newCheck);
      } else {
        newCheck.delete(node.value);
        if (multiple) removeChildren(findNodeChildren(node.value, data), newCheck);
      }
      setChecked(newCheck);
      if (onChange) onChange(newCheck);
    },
    [checked, data, multiple, onChange]
  );

  const select = useMemo(() => {
    return (
      <DropdownTreeSelect
        data={data}
        className={styles.dropdown}
        onChange={handleCheck}
        texts={{ placeholder }}
        inlineSearchInput={true}
        style={style}
        onNodeToggle={onToggle}
      />
    );
  }, [data, handleCheck, onToggle, placeholder, style]);

  if (!data) return null;
  if (container) return ReactDOM.createPortal(select, container);
  return select;
}
