import { useMemo } from "react";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isNil from "lodash.isnil";
import Container from ".";

import styles from "./onoff.module.scss";

export default function OnOffContainer(props) {
  const active = props.active ?? false;

  const extra = useMemo(() => {
    if (!isNil(props.canBeActivated) && !props.canBeActivated) return null;

    return (
      <span onClick={() => props.onToggle && props.onToggle(!active)}>
        <FontAwesomeIcon
          icon={faPowerOff}
          className={active ? styles.activeSwitch : styles.inactive}
        />
      </span>
    );
  }, [active, props]);

  return (
    <Container
      {...props}
      key={props.id ?? props.title}
      title={<span className={active ? styles.active : styles.inactive}>{props.title}</span>}
      extra={
        <div className={styles.extra}>
          {props.extra}
          {!isNil(props.canBeActivated) && !props.canBeActivated ? null : (
            <div className={styles.onOffBtn}>{extra}</div>
          )}
        </div>
      }
      foldable={props.forceNotFoldable === true ? false : active}
      hover={active}
      folded={active === false ? true : props.folded ?? true}
      color={active ? props.color ?? "#283618" : "#111"}
      coloredBars={!active ? false : isNil(props.coloredBars) ? false : props.coloredBars}
    />
  );
}
