import set from "lodash.set";
import Item from "../item";
import Block from "./block";

import "./style.scss";

import validate from "./validation";

export function doBlockRefs(values) {
  const items = new Set();
  const blocks = new Set();
  const lists = new Set();
  const labels = new Set();
  const fields = new Set();
  const externalSources = new Set();
  const endpoints = new Set();
  const metaPacks = new Set();
  let packs;
  let contentPacks;
  let enrichers;

  if (values.metadata?.request?.length > 0) {
    enrichers = values.metadata.request.map(({ id }) => id);
  }

  if ((values.results || {}).fallbackBlockId) blocks.add(values.results.fallbackBlockId);

  values.blocks?.forEach((block) => {
    if (block.type === "conversions") {
      if (!packs) packs = [];
      block.packs?.forEach?.(({ conversionPackId }) => packs.push(conversionPackId));
    }

    if (block.group) labels.add(block.group);
    if (block.groupSub) labels.add(block.groupSub);
    if (block.placeholderMain) labels.add(block.placeholderMain);
    if (block.placeholderSub) labels.add(block.placeholderSub);

    if (["conversions", "query"].includes(block.type) && block.queryId) items.add(block.queryId);
    if (block.type === "nested" && block.blockId) blocks.add(block.blockId);
    if (block.type === "smart" && block.smartPageListId) lists.add(block.smartPageListId);
    if (block.type === "external" && block.externalSourceId)
      externalSources.add(block.externalSourceId);

    if (block.listId) lists.add(block.listId);
    if (block.results?.fallbackBlockId) blocks.add(block.results.fallbackBlockId);
    if (block.results?.responseFieldsId) fields.add(block.results.responseFieldsId);

    if (block.viewAll) endpoints.add(block.viewAll.id);
    if (block.results?.inject) {
      block.results.inject.forEach(({ blockId }) => blocks.add(blockId));
    }

    // Metadata
    if (block.metadata?.external && block.metadata?.external?.required) {
      externalSources.add(block.metadata.external.externalSourceId);
    }

    if (block.metadata?.conversionPacks && block.metadata?.conversionPacks?.required) {
      packs = block.metadata?.conversionPacks?.ids;
    }

    if (block.metadata?.contentPacks && block.metadata?.contentPacks?.required) {
      contentPacks = block.metadata?.contentPacks?.packs?.map?.(({ id }) => id);
    }

    ["user_similar", "collaborative"].forEach((accessor) => {
      const type = block.metadata?.[accessor];
      if (type?.required && type?.conversionMode === "metapack" && type?.metaPackId) {
        metaPacks.add(type.metaPackId);
      }
    });
  });

  const refArray = [
    {
      type: "queries",
      items: [...items],
    },
    {
      type: "endpoints",
      items: [...endpoints],
    },
    {
      type: "blocks",
      items: [...blocks],
    },
    {
      type: "lists",
      items: [...lists],
    },
    {
      type: "labels",
      items: [...labels],
    },
    {
      type: "responseFields",
      items: [...fields],
    },
    {
      type: "externalSources",
      items: [...externalSources],
    },
  ];

  if (enrichers) {
    refArray.push({
      type: "enrichers",
      items: enrichers,
    });
  }

  if (packs) {
    refArray.push({
      type: "conversionPacks",
      items: packs,
    });
  }

  if (contentPacks) {
    refArray.push({
      type: "contentPacks",
      items: contentPacks,
    });
  }

  if (metaPacks.size > 0) {
    refArray.push({
      type: "metaPacks",
      items: [...metaPacks],
    });
  }

  set(values, "refs.require", refArray);

  return values;
}

export default function BlockContainer() {
  return (
    <Item name="Block" accessor="blocks" onSubmit={doBlockRefs} onValidate={validate}>
      <Block />
    </Item>
  );
}
