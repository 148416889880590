import { useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import { NumericFormat } from "react-number-format";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import ContentType from "../contentType";
import Container from "../container";
import SelectAllMultiple from "../selects/selectAllMultiple";

import styles from "./styles.module.scss";

const color = "#5f025f";

export default function Filters(props) {
  const {
    data = {},
    title = "Filters",
    showCatalog = true,
    error,
    parent,
    contentType,
    onChange,
  } = props;

  const packsIn = useMemo(
    () => data?.packs?.map(({ conversionPackId }) => conversionPackId) || [],
    [data?.packs]
  );

  const handleChangePacks = useCallback(
    (packs) => {
      onChange({ ...data, packs: packs.map((conversionPackId) => ({ conversionPackId })) });
    },
    [data, onChange]
  );

  const handleChangeContentType = useCallback(
    (contentType) => {
      onChange({
        ...data,
        contentType,
      });
    },
    [data, onChange]
  );

  const header = useMemo(() => {
    return (
      <div className={styles.header}>
        {data.packs?.map((pack, index) => {
          return <PackRule key={index} data={pack} color={color} />;
        })}
      </div>
    );
  }, [data.packs]);

  const extra = useMemo(() => {
    if (props.extra) return props.extra;

    return (
      <div className={styles.extra}>
        <span className={styles.extraText}>Max Items per Block Filter Percentage: </span>
        <NumericFormat
          defaultValue="100"
          value={data.maxFilterPercentage}
          displayType={"input"}
          thousandSeparator={true}
          suffix={"%"}
          allowNegative={false}
          isAllowed={({ floatValue }) => {
            return floatValue <= 100;
          }}
          onValueChange={({ floatValue }) => {
            onChange({ ...data, maxFilterPercentage: floatValue });
          }}
          className={styles.maxFilterPercentage}
        />
      </div>
    );
  }, [data, onChange, props.extra]);

  const filters = useMemo(() => {
    const filters = { exclude: packsIn, catalogs: [data.contentType ?? contentType] };
    if (parent?.isFallback || parent?.isInFallback) filters.isUserBased = false;

    return filters;
  }, [contentType, data.contentType, packsIn, parent?.isFallback, parent?.isInFallback]);

  return (
    <Container
      title={title}
      help="endpoint-output-filters"
      header={header}
      extra={extra}
      folded={true}
      warning={error}
    >
      <div className={styles.filtersBox}>
        {showCatalog ? (
          <div className={styles.contentType}>
            <Form.Label className={styles.label}>Catalog:</Form.Label>
            <ContentType rootNode={data} onChange={handleChangeContentType} onlySelect={true} />
          </div>
        ) : null}

        {(contentType || data?.contentType) && (
          <>
            <Form.Label className={styles.label}>Conversions Pack:</Form.Label>
            <div className={styles.selectBox}>
              <SelectAllMultiple
                accessor="conversionPacks"
                filters={filters}
                values={packsIn}
                handleChange={handleChangePacks}
                className={styles.packSelect}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

function PackRule(props) {
  const { color, data } = props;
  const pack = useGetMemItem("conversionPacks", data.conversionPackId);

  return (
    <>
      <Container
        title={
          <div className={styles.packTitle}>
            <FontAwesomeIcon icon={faArchive} className={styles.packIcon} />
            {pack?.title || "TBD"}
          </div>
        }
        className={styles.packContainer}
        color={color}
        isButton={true}
        noMarginLast={true}
        headerOnlyOnFolded={false}
        folded={true}
        foldable={false}
      />
    </>
  );
}
