import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import useImageError from "../../hooks/useImageError";
import styles from "./styles.module.scss";

export default function Image({ src, alt, className }) {
  const [setImg, hasError] = useImageError();

  if (hasError)
    return (
      <div className={`${styles.image} ${className}`}>
        <FontAwesomeIcon className={styles.icon} icon={faImage} />
      </div>
    );

  return <img ref={setImg} src={src} alt={alt} className={className} />;
}
