import { useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { Row, Col, Form } from "react-bootstrap";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import get from "lodash.get";

export default function Order(props) {
  const { accessor, dataAccessor, rule, ruleIndex } = props;
  const { setFieldValue, values } = useFormikContext();

  const order = useMemo(() => {
    if (!rule || rule.queryType !== "sortOrder") return;

    return (
      get(values[dataAccessor || accessor], `${ruleIndex}.overides.${rule._id}.sortOrder`) ||
      rule.sortOrder ||
      "asc"
    );
  }, [accessor, dataAccessor, rule, ruleIndex, values]);

  const handleOrderChange = useCallback(
    (e) => {
      let newValues = cloneDeep(values[dataAccessor || accessor]);
      set(newValues, `${ruleIndex}.overides.${rule._id}.sortOrder`, e.target.value);
      setFieldValue(dataAccessor || accessor, newValues);
    },
    [accessor, dataAccessor, rule._id, ruleIndex, setFieldValue, values]
  );

  if (!order) return null;

  return (
    <div className="sort-override">
      <div className="sort-override-label">Sort Order</div>
      <Form.Control
        as="select"
        value={order}
        onChange={handleOrderChange}
        disabled={rule.notOverwritable}
        className="sort-override-select"
      >
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </Form.Control>
    </div>
  );
}
