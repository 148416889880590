import { useMemo } from "react";
import { useGetMemTable } from "../../../hooks/useMemoryDB";
import { MultiSelect } from "react-multi-select-component";

import "./global.scss";

export default function ConversionSelect(props) {
  const { onChange, value, forceSearch, forceRecommended } = props;

  const conversions = useGetMemTable("conversions");

  const conversioni = useMemo(() => {
    if (!conversions) return [];

    const conversioni = [...conversions.values()].map((c) => ({ label: c.id, value: c.id }));
    if (forceSearch) conversioni.unshift({ label: "search", value: "search" });
    if (forceRecommended) conversioni.unshift({ label: "recommended", value: "recommended" });
    return conversioni;
  }, [conversions, forceRecommended, forceSearch]);

  if (!conversioni || conversioni.length === 0) return null;

  return (
    <>
      <MultiSelect
        options={conversioni}
        value={value || []}
        onChange={onChange}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: "All Conversions Selected.",
          selectSomeItems: "Select Conversions...",
        }}
      />
    </>
  );
}
