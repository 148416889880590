import { useMemo, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import db from "../../hooks/useMemoryDB";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import SelectMeta from "../selects/selectMeta";

import styles from "./styles.module.scss";

export default function MetaSelect(props) {
  const { onAdd, accessor, className } = props;
  const { values } = useFormikContext();
  const [id, setId] = useState();

  const [realms, catalogs] = useMemo(() => {
    return [["content"], [values.contentType]];
  }, [values.contentType]);

  const excludes = useMemo(() => {
    return values[accessor]?.rules?.map((r) => r.name);
  }, [accessor, values]);

  const addMetadata = useCallback(() => {
    if (id) {
      onAdd(db.getFromId("metadata", id));
      setId(null);
    }
  }, [id, onAdd]);

  return (
    <div className={`${styles.metadataSelectBox} ${className ? className : ""}`}>
      <div className={styles.metaTitle}>Metadata</div>
      <SelectMeta
        name={`metadata-select-${accessor}`}
        value={id}
        handleChange={setId}
        catalogs={catalogs}
        realms={realms}
        excludes={excludes}
        className={styles.metaSelect}
      />
      <Button
        size="sm"
        onClick={addMetadata}
        variant="outline-primary"
        className={styles.metaSelectBtn}
      >
        <FontAwesomeIcon icon={faPlusSquare} className={styles.metaSelectIcon} /> Add
      </Button>
    </div>
  );
}
