import { useSelector } from "react-redux";

export default function usePermissions() {
  const read = useSelector((state) => state.user.permissions[state.user.tenant].read || []);
  const write = useSelector((state) => state.user.permissions[state.user.tenant].write || []);
  const expert = useSelector((state) => state.user.permissions[state.user.tenant].expert || []);
  const destroy = useSelector((state) => state.user.permissions[state.user.tenant].delete || []);

  return { read, write, destroy, expert };
}
