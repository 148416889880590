import get from "lodash.get";
import set from "lodash.set";

export default function validateMetadata(values, excludeFallbackMetadata = false) {
  const errors = {};
  if (!values) return errors;

  const contentSimilarDate = get(values, "content_similar.date.range");

  if (contentSimilarDate > 0) {
    const contentPeriodField = get(values, "content_similar.date.period");
    if (!contentPeriodField || contentPeriodField.length === 0) {
      set(errors, "content_similar.date.period", "You Must Define a Period for Date Range");
      errors.keepOnly = true;
    }

    const contentSimilarField = get(values, "content_similar.date.field");

    if (!contentSimilarField || contentSimilarField.length === 0) {
      set(errors, "content_similar.date.field", "You Must Define a Field for Date Range");
      errors.keepOnly = true;
    }
  }

  if (values.extra?.required) {
    if (!values.extra.contentType) {
      errors.extra_contentType = "You Must Select Extra Catalog";
      errors.extra = true;
    }
  }

  if (values.session?.required) {
    if (values.session.recommended === undefined && values.session.consumed === undefined) {
      errors.session = true;
    }
  }

  if (values.conversionPacks?.required) {
    if (!values.conversionPacks?.ids?.length > 0) errors.conversionPacks = true;
  }

  if (values.contentPacks?.required) {
    if (!values.contentPacks?.packs?.length > 0) errors.contentPacks = true;
  }

  const userContentTypeSet = new Set();

  ["user", "user_similar", "collaborative"].forEach((accessor) => {
    const type = values[accessor];

    if (type?.required) {
      if (type.conversionMode !== "metapack") {
        const userContentType = type.userContentType;

        if (!userContentType) {
          errors[accessor] = true;
          errors[`${accessor}_userContentType`] = true;
        } else {
          userContentTypeSet.add(userContentType);
        }

        if (accessor !== "user") {
          if (!type?.event) {
            errors[accessor] = true;
            errors[`${accessor}_event_type`] = true;
          }

          if (type?.event && !type?.perUserBasisType) {
            if (accessor === "user_similar") {
              errors[accessor] = true;
              errors[`${accessor}_perUserBasisType`] = true;
            }

            if (accessor === "collaborative" && type.perUserBasis) {
              errors[accessor] = true;
              errors[`${accessor}_perUserBasisType`] = true;
            }
          }
        }
      } else {
        if (!type.metaPackId) {
          errors[accessor] = true;
          errors[`${accessor}_metaPackId`] = true;
        }
      }
    }
  });

  if (userContentTypeSet.size > 1) {
    ["user", "user_similar", "collaborative"].forEach((accessor) => {
      if (values[accessor]?.required) {
        errors[accessor] = true;
        errors[`${accessor}_userContentType`] = true;
      }
    });
  }

  if (excludeFallbackMetadata) {
    [
      ["included", "keepOnly"],
      ["excluded", "excluded"],
    ].forEach(([accessor, errorAccessor]) => {
      const pack = get(values, `content_similar.metadata.${accessor}`);

      if (pack) {
        pack.forEach((meta) => {
          const kw = meta.split("=");
          if (kw.length === 1) errors[errorAccessor] = true;
        });
      }
    });
  }

  if (Object.keys(errors).length > 0) errors.error = true;

  return errors;
}
