import { useState, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import capitalize from "lodash.capitalize";
import Container from "../container";
import OnOffContainer from "../pipelines/onOffContainer";

import styles from "./styles.module.scss";

export default function Injects(props) {
  const showEgress = props.showEgress ?? true;
  const checkPipes = props.checkPipes ?? true;
  const showIds = props.showIds ?? false;
  const { errors, values, setFieldValue } = useFormikContext();

  if (checkPipes && !values.pipelines?.active) return null;

  return (
    <Container
      title={props.title ?? "Injects"}
      help="loader-injects"
      warningMessage={errors.injects}
      folded={true}
      extra={props.extra}
      color={props.color}
      coloredBars={props.coloredBars}
    >
      <Opzione label="synthesis" keyLabel="Prefix" checkInvalid={false} />
      <Opzione label="fingerprint" />
      <Opzione label="tags" />
      <Opzione label="segments" />
      {showEgress && (
        <OnOffContainer
          title="Egress"
          foldable={false}
          folded={true}
          headerOnlyOnFolded={false}
          forceNotFoldable={true}
          active={values.injects?.egress?.active ?? false}
          onToggle={(v) => setFieldValue(`injects.egress.active`, v)}
        />
      )}
      {showIds && <Opzione label="ids" />}
    </Container>
  );
}

function Opzione({ label, keyLabel = "Key", checkInvalid = true }) {
  const { values, setFieldValue } = useFormikContext();
  const active = values.injects?.[label]?.active ?? false;
  const valore = values.injects?.[label]?.key ?? "";
  const [value, setValue] = useState(valore);
  const [edit, setEdit] = useState(!valore);
  const errore = (label === "fingerprint" && !value?.includes?.("fingerprint")) ?? false;

  const setKey = useCallback(() => {
    if (value && !errore) {
      setFieldValue(`injects.${label}.key`, value);
      setEdit(false);
    }
  }, [errore, label, setFieldValue, value]);

  return (
    <OnOffContainer
      title={capitalize(label)}
      foldable={false}
      folded={true}
      headerOnlyOnFolded={false}
      forceNotFoldable={true}
      active={active}
      onToggle={(v) => setFieldValue(`injects.${label}.active`, v)}
      header={
        <>
          {active && (
            <div className={styles.injectHeader}>
              {edit ? (
                <>
                  <span className={styles.label}>{keyLabel}</span>
                  <Form.Control
                    type="text"
                    placeholder={`${label}_*`}
                    className={styles.input}
                    isInvalid={checkInvalid ? !valore || errore : false}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <Button
                    className={styles.btn}
                    variant="outline-secondary"
                    size="sm"
                    onClick={setKey}
                  >
                    OK
                  </Button>
                  <Button
                    className={styles.btn}
                    variant="outline-dark"
                    size="sm"
                    onClick={() => setEdit(false)}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <div className={styles.valore} onDoubleClick={() => setEdit(true)}>
                  {valore}
                </div>
              )}
            </div>
          )}
        </>
      }
    />
  );
}
