import { Fragment, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons";
import Container from "../container";
import { useAuth0 } from "@auth0/auth0-react";
import { aresUserTokenVerifyKey, aresUserLoginSignKey } from "../../keys";
import { initSocket } from "../../transport";

import {
  userLoginRequest,
  userLogged,
  userLogOut,
  userSelectTenant,
} from "../../redux/actions/user";
import Loading from "../loading";

import "./style.scss";
import styles from "./styles.module.scss";
import pJson from "../../../package.json";
import axios from "axios";

const apiEndp = process.env.REACT_APP_API_ENDPOINT;

// if (process.env.NODE_ENV !== "development") {
//   deleteLocal("ares-user");
// }

function createLoginToken(user) {
  const iss = "ares-control-center";
  const iat = Math.floor(Date.now() / 1000);
  const exp = iat + 36000;
  const data = { ...user, iss, iat, exp };

  const loginToken = jwt.sign(data, aresUserLoginSignKey, { algorithm: "RS256" });

  return loginToken;
}

function Login() {
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  const utente = useSelector((state) => state.user);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    //logout();
    console.log(error);
    return (
      <Loading
        message={
          <>
            Oops... {error.message}
            <br />
            <button size="sm" onClick={() => logout({ returnTo: window.location.origin })}>
              Log out
            </button>
          </>
        }
      ></Loading>
    );
  }

  if (utente?.nickname) return <Tenant user={utente} logout={logout} />;

  if (isAuthenticated) {
    const loginToken = createLoginToken(user);

    axios
      .get(`${apiEndp}/loginToken`, { headers: { loginToken } })
      .then(async ({ data }) => {
        if (data) {
          const decoded = await jwt.verify(data, aresUserTokenVerifyKey, { algorithms: ["RS256"] });
          dispatch(userLogged({ ...decoded, id_token: data }));
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return null;
  } else {
    loginWithRedirect();
    return null;
    //return <Button size="sm" onClick={loginWithRedirect}>Log in</button>;
  }
}

function Tenant(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("");
  const [error, setError] = useState("");
  const user = useSelector((state) => state.user);

  const doLogout = useCallback(() => {
    setUsername("");
    setPassword("");
    setError("");
    dispatch(userLogOut());
    props.logout({ returnTo: window.location.origin });
  }, [dispatch, props]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        userLoginRequest(
          username,
          password,
          () => setError(""),
          (error) => {
            setError("Som3thing w3nt wrong: T4k3 a br34th r3fr3sh your m3mory");
          }
        )
      );
    },
    [dispatch, password, username]
  );

  const onChooseTenant = useCallback(
    (e) => {
      e.preventDefault();

      if (tenant && tenant.length) {
        initSocket(tenant, (socket) => {
          socket.emit("login", { tenant, id_token: user.id_token });
          dispatch(userSelectTenant(tenant, user));
        });
      }
    },
    [dispatch, tenant, user]
  );

  // useEffect(() => {
  //   if (!user.active && userStored && Object.keys(userStored).length)
  //     dispatch(userLogged(userStored, false));
  // }, [dispatch, user, userStored]);

  const title = useMemo(() => {
    return (
      <div className={styles.titleBox}>
        <FontAwesomeIcon icon={faFingerprint} className={styles.logo} />
        <div className={styles.title} title="AR3S CONTROL CENTER">
          <span>AR3S</span>
          <span>CONTROL</span>
          <span>CENTER</span>
        </div>
      </div>
    );
  }, []);

  const footer = useMemo(() => {
    if (user.logging && !error) return null;

    if (!user.tenants) {
      return (
        <Button
          size="sm"
          type="submit"
          variant="outline-primary"
          onClick={onSubmit}
          key="login-btn"
        >
          Login
        </Button>
      );
    }

    const buttons = [
      <Button
        size="sm"
        variant="outline-dark"
        onClick={doLogout}
        className="logOutBtn"
        key="log-out-btn"
      >
        {error ? "Retry" : "Log Out"}
      </Button>,
    ];

    if (user.tenants?.length > 0) {
      buttons.push(
        <Button size="sm" variant="outline-primary" onClick={onChooseTenant} key="go-btn">
          Go
        </Button>
      );
    }

    return <>{buttons}</>;
  }, [doLogout, error, onChooseTenant, onSubmit, user.logging, user.tenants]);

  const extra = useMemo(() => {
    return <div className={styles.version}>{pJson.version}</div>;
  }, []);

  return (
    <div className={styles.animatedBackground}>
      {new Array(20).fill(1).map((_, i) => (
        <span key={`particle-${i}`} className={styles.particle} />
      ))}
      <Container
        title={title}
        className={styles.loginBox}
        color="#000f14"
        foldable={false}
        hover={false}
        background="rgba(0, 0, 0, 0.7)"
        coloredBars={false}
        footer={footer}
        extra={extra}
      >
        <div className="form-container">
          {error ? (
            <div className={styles.errorContainer}>
              <div className={styles.loginError}>{error}</div>
            </div>
          ) : null}

          {user.logging && !error ? (
            <div className="logging">
              <div className="logging-label">Authenticating...</div>
              <div className="lds-ellipsis">
                {new Array(4).fill(1).map((_, i) => (
                  <div key={`loading-${i}`} />
                ))}
              </div>
            </div>
          ) : (
            <Form onSubmit={onSubmit}>
              {!user.tenants && (
                <Fragment>
                  {user.errored && <div className="login-error error-message">Access Denied</div>}
                  <label className={styles.loginLabel} htmlFor="exampleInputEmail1">
                    Username
                  </label>
                  <Form.Control
                    type="email"
                    id="username"
                    placeholder="Enter your username"
                    autoComplete="username"
                    onChange={(e) => setUsername(e.target.value)}
                    className={styles.loginInput}
                  />
                  <label className={styles.loginLabel} htmlFor="exampleInputPassword1">
                    Password
                  </label>
                  <Form.Control
                    type="password"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                    className={styles.loginInput}
                  />
                </Fragment>
              )}

              {user.tenants && (
                <div className="form-group">
                  <div className="login-welcome">
                    Welcome <span className="login-username">{user.nickname}</span>
                  </div>
                  {user.nickname && (!user.tenants || user.tenants.length === 0) && (
                    <div className="user-no-tenant">
                      Your Profile is associated with no Tenants. <br />
                      Please contact the 4R3S Administrator to activate your account.
                    </div>
                  )}
                  {user.tenants.length > 0 && (
                    <Fragment>
                      <Form.Label>Choose the Tenant you want to operate on...</Form.Label>
                      <Form.Control
                        as="select"
                        id="teantControl"
                        onChange={(e) => setTenant(e.target.value)}
                        className={styles.loginSelect}
                        size="sm"
                      >
                        <option></option>
                        {user.tenants.map((tenant) => (
                          <option key={tenant}>{tenant}</option>
                        ))}
                      </Form.Control>
                    </Fragment>
                  )}
                </div>
              )}
            </Form>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Login;
