import { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrashAlt, faPowerOff } from "@fortawesome/free-solid-svg-icons";

import Container from "../container";
import RotaryKnob from "../rotaryKnob";
import MetaSelect from "./metaSelect";

import styles from "./styles.module.scss";

const COLOR = "#915e4d";

export default function GroupBy(props) {
  const { setFieldValue, values } = useFormikContext();
  const addMetadata = useCallback(
    ({ id, dbType, dbNestedField, dbNestePath }) => {
      const rules = values.groupBy?.rules || [];
      rules.push({
        name: id,
        fieldNested: dbNestedField,
        fieldNestedRoot: dbNestePath,
        dbType,
      });
      setFieldValue(`groupBy.rules`, rules);
      setFieldValue("lastSaved.date", new Date().toISOString());
    },
    [setFieldValue, values]
  );

  const updateOccurrences = useCallback(
    (value) => {
      setFieldValue("groupBy.occurrences", value);
    },
    [setFieldValue]
  );

  if (!values.contentType) return null;

  return (
    <Container
      title="Group By"
      help="conversion-pack-groupby"
      color={COLOR}
      coloredBars={false}
      coloredTitle={true}
      headerOnlyOnFolded={false}
      extra={
        <FontAwesomeIcon
          icon={faPowerOff}
          onClick={() => setFieldValue("groupBy", undefined)}
          className={styles.powerOffIcon}
        />
      }
    >
      <div className={styles.groupBySelectBox}>
        <MetaSelect accessor="groupBy" onAdd={addMetadata} className={styles.selectBox} />
        <div className={styles.rotary}>
          <RotaryKnob
            value={values.groupBy?.occurrences || 0}
            min={1}
            max={100}
            step={1}
            label="Results Per Group"
            labelPosition="right"
            skin="s10"
            onChange={updateOccurrences}
          />
        </div>
      </div>
      <div className={styles.groupByBox}>
        {values.groupBy?.rules?.map((data, index) => (
          <span key={`group-by-${index}`}>
            {<Meta data={data} index={index} />}
            {values.groupBy.rules.length - 1 !== index ? (
              <span className={styles.operatorRule}>AND</span>
            ) : null}
          </span>
        ))}
      </div>
    </Container>
  );
}

function Meta(props) {
  const { index, data } = props;
  const { setFieldValue, values } = useFormikContext();

  const extra = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={faTrashAlt}
        className={styles.deleteRuleIcon}
        onClick={() => {
          const rules = values.groupBy?.rules || [];
          rules.splice(index, 1);
          setFieldValue(`groupBy.rules`, rules);
          setFieldValue("lastSaved.date", new Date().toISOString());
        }}
      />
    );
  }, [setFieldValue, values.groupBy?.rules, index]);

  return (
    <Container
      title={data.name}
      color={COLOR}
      isButton={true}
      noMarginLast={true}
      headerOnlyOnFolded={false}
      folded={true}
      foldable={false}
      extra={extra}
    ></Container>
  );
}
