import { forwardRef, useCallback, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import db from "../../../hooks/useMemoryDB";
import { useItemContext } from "../../../hooks/useItem";
import { useUpdateMetadata } from "../../../hooks/useUpdateMetadata";
import get from "lodash.get";
import md5 from "md5";

import { compare } from "fast-json-patch";
import cloneDeep from "lodash.clonedeep";

import Container from "../../container";
import ContentQuery from "../../contentPacks/contentQuery";
import SelectAllMultiple from "../../selects/selectAllMultiple";
import stubs from "../../../utils/stubs";

const checkStyle = { display: "inline-block" };

const CPack = forwardRef((props, ref) => {
  const {
    metadata,
    updateMetadata,
    rootNodeName,
    contentType,
    enableOverrides,
    info,
  } = props;

  const updateFieldMetadata = useUpdateMetadata();
  const { itemId } = useItemContext() || stubs.item;
  const { errors: errori } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);

  const required = useMemo(
    () => get(metadata, "contentPacks.required"),
    [metadata]
  );
  const handleRequired = useCallback(
    (e) => {
      const required = e.target.checked;
      if (!required) updateMetadata("contentPacks", undefined);
      else updateMetadata("contentPacks.required", required);
    },
    [updateMetadata]
  );

  const ids = useMemo(() => {
    const ids = (metadata.contentPacks?.packs ?? []).map(({ id }) => id);

    return ids.length > 0 ? ids : undefined;
  }, [metadata.contentPacks]);

  const contentPackFilters = useMemo(() => {
    const filters = {};
    // const filters = {
    //   catalogs: [contentType],
    // };

    if (info.restricted) {
      filters.canBeFallback = true;
    }

    return filters;
  }, [info.restricted]);

  const handleChange = useCallback(
    (ids = []) => {
      const current = metadata.contentPacks?.packs ?? [];
      const packs = [];

      ids.forEach((id) => {
        const pack = current.find((p) => p.id === id);
        if (pack) packs.push(pack);
        else packs.push({ id, override: "" });
      });

      const update = {
        packs,
        required: true,
      };

      updateMetadata("contentPacks", update);
    },
    [metadata.contentPacks?.packs, updateMetadata]
  );

  const handleEdit = useCallback(
    (modifiche, index) => {
      const origin = db.get("contentPacks", modifiche.id);
      const data = compare(cloneDeep(origin), modifiche);
      const packs = metadata.contentPacks.packs ?? [];
      packs[index].override = JSON.stringify(data);
      packs[index].cacheKey = md5(`${origin.id}${packs[index].override}`);

      const update = {
        packs,
        required: true,
      };

      updateMetadata("contentPacks", update);
    },
    [metadata.contentPacks?.packs, updateMetadata]
  );

  useEffect(() => {
    if (ids) {
      const data = db.bulkGet("contentPacks", ids);
      const fields = data.map((p) => p.output);

      updateFieldMetadata({
        owner: itemId,
        nextId: fields,
        types: ["string"],
        realm: "contentPacks",
        catalog: contentType,
      });
    }
  }, [contentType, ids, itemId, updateFieldMetadata]);

  return (
    <Container
      folded={true}
      foldable={required || false}
      coloredBars={false}
      color="#030b0e"
      margin="4px"
      warning={errors?.[rootNodeName]?.contentPacks}
      title={
        <Form.Check
          type="switch"
          id="contentPacksSwitch"
          checked={get(metadata, "contentPacks.required") || false}
          value="true"
          onChange={handleRequired}
          name="contentPacksSwitch"
          label="Content Packs"
          style={checkStyle}
          disabled={enableOverrides}
        />
      }
      help="meta-content-packs"
    >
      <SelectAllMultiple
        accessor="contentPacks"
        filters={contentPackFilters}
        values={ids}
        disableAdd={enableOverrides}
        editor={
          enableOverrides ? (
            <ContentQuery
              onChange={handleEdit}
              metadata={metadata}
              blockFields={true}
            />
          ) : null
        }
        handleChange={handleChange}
      />
    </Container>
  );
});

export default CPack;
