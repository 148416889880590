import { useCallback, useMemo } from "react";
import { useSelectMem } from "./useMemoryDB";

export default function useMetadataValues(contentType, field) {
  const catalogs = useMemo(
    () => (Array.isArray(contentType) ? contentType : [contentType]),
    [contentType]
  );

  const data = useSelectMem(
    "metadata",
    useCallback(
      (i) => {
        if (!(i.id === field)) return false;
        if (![...catalogs, "*"].includes(i.catalog)) return false;
        return true;
      },
      [catalogs, field]
    )
  );

  if (!Array.isArray(data)) return data?.values ?? [];
  return data[0]?.values ?? [];

  // return useDexieTable(
  //   "metadata",
  //   useMemo(
  //     () => ({
  //       where: [
  //         {
  //           field: "id",
  //           operator: "equals",
  //           value: field,
  //           and: [{ filter: "(obj) =>  param.includes(obj.catalog)", param: [...catalogs, "*"] }],
  //         },
  //       ],
  //     }),
  //     [catalogs, field]
  //   ),
  //   (m) => m[0]?.values
  // );
}
