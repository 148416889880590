import { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import Picker from "rc-calendar/lib/Picker";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import Calendar from "rc-calendar";

import "../../scss/calendar.css";
import "../../scss/time.css";

import Container from "../container";

import styles from "./styles.module.scss";

const units = [
  { label: "Minutes", value: "m" },
  { label: "Hours", value: "h" },
  { label: "Days", value: "d" },
  { label: "Weeks", value: "w" },
  { label: "Months", value: "M" },
  { label: "Years", value: "y" },
];

const now = moment();

export default function Range({
  startDate,
  period,
  unit,
  onChangeStartDate,
  onChangePeriod,
  onChangeUnit,
  extra,
  errors,
  coloredBars,
  color,
}) {
  const [hoverValue, setHoverValue] = useState([]);

  const timePickerElement = useMemo(
    () => <TimePickerPanel defaultValue={moment("00:00:00", "HH:mm:ss")} />,
    []
  );

  const calendar = useMemo(
    () => (
      <Calendar
        hoverValue={hoverValue}
        onHoverChange={setHoverValue}
        showWeekNumber={false}
        dateInputPlaceholder="Pick a Date..."
        defaultValue={now}
        timePicker={timePickerElement}
      />
    ),
    [hoverValue, timePickerElement]
  );

  const header = useMemo(() => {
    return (
      <div className={styles.rangeHeader}>
        <div className={styles.period}>
          <Form.Control
            type="number"
            min={1}
            step={1}
            value={period || 0}
            onChange={(e) => onChangePeriod(e.target.value)}
            isInvalid={errors?.rangePeriod}
            className={styles.numericField}
          />
        </div>
        <div className={styles.unit}>
          <Form.Control
            as="select"
            value={unit || ""}
            onChange={(e) => onChangeUnit(e.target.value)}
            isInvalid={errors?.rangeUnit}
          >
            <option></option>
            {units.map((u) => (
              <option value={u.value} key={u.label}>
                {u.label}
              </option>
            ))}
          </Form.Control>
        </div>
        <div className={styles.start}>
          <span className={styles.label}>Before</span>
          <Picker
            value={startDate ? moment(startDate) : null}
            onChange={(v) => onChangeStartDate(v.toISOString())}
            animation="slide-up"
            calendar={calendar}
          >
            {({ value }) => {
              if (!value) {
                return <span className={styles.startDate}>Now</span>;
              }
              return (
                <div>
                  <span className={styles.startDate}>{value.format("DD-MM-yyyy")}</span>
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className={styles.resetStartDate}
                    onClick={() => onChangeStartDate(undefined)}
                  />
                </div>
              );
            }}
          </Picker>
        </div>
      </div>
    );
  }, [
    calendar,
    errors?.rangePeriod,
    errors?.rangeUnit,
    onChangePeriod,
    onChangeStartDate,
    onChangeUnit,
    period,
    startDate,
    unit,
  ]);

  return (
    <Container
      title="Range"
      help="relative-range"
      color={color}
      coloredBars={false}
      foldable={false}
      folded={true}
      extra={extra}
      header={header}
      warning={errors?.range}
    ></Container>
  );
}
