import { useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import ContentType from "../contentType";
import Container from "../container";
import Rest from "./rest";

const selectStyle = { width: "366px" };

export default function External(props) {
  const { handleChange, handleBlur, setFieldValue, values } = useFormikContext();

  const handleChangeCatalog = useCallback(
    (catalog) => {
      setFieldValue("contentType", catalog);
    },
    [setFieldValue]
  );
  const header = useMemo(() => {
    return (
      <div className="header">
        {values.compliant ? (
          <>
            <span className="header-label">Catalog</span>
            <ContentType
              rootNode={values}
              fixedValue={values.contentType}
              allowAllType={false}
              onlySelect={true}
              onChange={handleChangeCatalog}
            />
          </>
        ) : null}

        <span className="header-label">Type</span>
        <Form.Control
          as="select"
          onChange={handleChange}
          onBlur={handleBlur}
          name="type"
          value={values.type || ""}
          style={selectStyle}
        >
          <option></option>
          <option>REST</option>
        </Form.Control>
      </div>
    );
  }, [handleBlur, handleChange, handleChangeCatalog, values]);

  return (
    <Container
      title="Config"
      help="external-source-config"
      header={header}
      headerOnlyOnFolded={false}
    >
      {values.type === "REST" ? <Rest /> : null}
    </Container>
  );
}
