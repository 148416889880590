import { useMemo } from "react";
import { Row, Col, OverlayTrigger, Popover, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useDataParams } from "../../hooks/useData";
import UserTypesSelect from "../selects/selectUserTypes";
import ContentTypesSelect from "../selects/selectContentTypes";
import SelectAll from "../selects/selectAll";
import Container from "../container";

import styles from "./styles.module.scss";
import capitalize from "lodash.capitalize";
import "./style.scss";

const selectStyle = { width: "366px" };

export default function UserPref(props) {
  const { values, catalog } = props;

  const isInvalid = useMemo(() => {
    if (values.conversionMode !== "metapack") {
      if (!catalog) return true;
      for (const prop of ["userContentType", "event", "perUserBasisType"]) {
        if (!values[prop]) return true;
      }
    } else {
      if (!values.metaPackId) return true;
    }

    return false;
  }, [catalog, values]);

  if (props.minified)
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        key="user-pref"
        overlay={
          <Popover id={`user-panel`} bsPrefix="ares-user-pref-panel">
            <RadioTab {...props} />
          </Popover>
        }
      >
        <div>
          <FontAwesomeIcon icon={faCog} className={isInvalid ? styles.iconInvalid : styles.icon} />
        </div>
      </OverlayTrigger>
    );

  return <RadioTab {...props} />;
}

function Historicized(props) {
  const {
    values,
    errors = {},
    errorsPrefix = "",
    catalog,
    onChange,
    style,
    showCatalog = false,
    showEvent = true,
    showFingerprint = true,
    forceUserCatalog = false,
  } = props;
  const params = useDataParams();
  const eventTypes = useMemo(() => {
    if (params && values.userContentType) {
      return Object.keys(params?.users?.clusters?.types?.[values.userContentType]?.events || {});
    }

    return [];
  }, [params, values.userContentType]);

  const fingerprint = useMemo(() => {
    if (params && catalog && values.userContentType && values.event) {
      return Object.entries(
        params?.users?.clusters?.types?.[values.userContentType]?.events?.[values.event]
          ?.contentTypes?.[catalog]?.fields?.fingerprint || {}
      ).map(([label, value]) => {
        return { label, value };
      });
    }
  }, [catalog, params, values.event, values.userContentType]);

  const isCatalogInvalid = useMemo(() => {
    if (errors[`${errorsPrefix}contentType`]) return true;
    return !(values.contentType || catalog);
  }, [catalog, errors, errorsPrefix, values.contentType]);

  const panel = useMemo(() => {
    return (
      <div className={styles.popOver} style={style}>
        {showCatalog ? (
          <Row className={styles.row}>
            <Col sm="1" className={styles.label}>
              Catalog
            </Col>
            <Col sm="11">
              <ContentTypesSelect
                value={values.contentType || catalog}
                handleChange={(value) => onChange("contentType", value)}
                isInvalid={isCatalogInvalid}
                style={selectStyle}
                show={true}
              />
            </Col>
          </Row>
        ) : null}
        {forceUserCatalog || catalog ? (
          <div className={styles.userBox}>
            <Row className={styles.row}>
              <Col sm={2} md={1} className={styles.label}>
                User Catalog
              </Col>
              <Col md={11} sm={10}>
                <UserTypesSelect
                  value={values.userContentType}
                  handleChange={(value) => onChange("userContentType", value)}
                  isInvalid={errors[`${errorsPrefix}userContentType`]}
                  style={selectStyle}
                  show={true}
                />
              </Col>
            </Row>
            {showEvent && values.userContentType && eventTypes ? (
              <>
                <Row className={styles.row}>
                  <Col sm="1" className={styles.label}>
                    Event Type
                  </Col>
                  <Col sm="11">
                    <Form.Control
                      as="select"
                      style={selectStyle}
                      value={values.event || ""}
                      onChange={(e) => onChange("event", e.target.value)}
                      isInvalid={errors[`${errorsPrefix}event_type`]}
                    >
                      <option></option>
                      {eventTypes.map((event, i) => (
                        <option key={`${event}-${i}`} value={event}>
                          {capitalize(event)}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                {showFingerprint && values.event && fingerprint ? (
                  <Row className={styles.row}>
                    <Col sm="1" className={styles.label}>
                      Fingerprint
                    </Col>
                    <Col sm="11">
                      <Form.Control
                        as="select"
                        style={selectStyle}
                        value={values.perUserBasisType || ""}
                        onChange={(e) => onChange("perUserBasisType", e.target.value)}
                        isInvalid={errors[`${errorsPrefix}perUserBasisType`]}
                      >
                        <option></option>
                        {fingerprint.map((f, i) => (
                          <option key={`${f.value}-${i}`} value={f.value}>
                            {f.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }, [
    catalog,
    errors,
    errorsPrefix,
    eventTypes,
    fingerprint,
    forceUserCatalog,
    isCatalogInvalid,
    onChange,
    showCatalog,
    showEvent,
    showFingerprint,
    style,
    values.contentType,
    values.event,
    values.perUserBasisType,
    values.userContentType,
  ]);

  return panel;
}

function MetaPack(props) {
  const filters = useMemo(() => {
    const filters = { isUserBased: true };
    if (props.values?.metaPackId) filters.exclude = [props.values.metaPackId];

    return filters;
  }, [props.values.metaPackId]);

  return (
    <div className={styles.metaPackBox}>
      <span>MetaPack:</span>
      <SelectAll
        accessor="metaPacks"
        filters={filters}
        value={props.values?.metaPackId}
        handleChange={(v) => props.onChange("metaPackId", v)}
        isInvalid={!props.values?.metaPackId}
      />
    </div>
  );
}

function RadioTab(props) {
  const mode = props.values?.conversionMode ?? "historicized";

  const header = useMemo(() => {
    return (
      <div className={styles.radioContainer}>
        <div className={styles.tab}>
          <Form.Check
            type="radio"
            id="historicized-tab"
            label="Historicized"
            checked={mode === "historicized"}
            onChange={(e) => props.onChange("conversionMode", "historicized")}
          />
        </div>
        <div className={styles.tab}>
          <Form.Check
            type="radio"
            id="metapack-tab"
            label="MetaPack"
            checked={mode === "metapack"}
            onChange={(e) => props.onChange("conversionMode", "metapack")}
          />
        </div>
      </div>
    );
  }, [mode, props]);

  if (props.hideTabs) return <Historicized {...props} />;

  return (
    <Container
      title="User Fingerprint"
      help="user-fingerprint"
      foldable={false}
      coloredBars={false}
      header={header}
      headerOnlyOnFolded={false}
    >
      <div className={styles.userFingerprintBox}>
        {mode === "historicized" ? <Historicized {...props} /> : <MetaPack {...props} />}
      </div>
    </Container>
  );
}
