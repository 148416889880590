import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { ButtonToolbar, ButtonGroup, Button, NavDropdown, Form } from "react-bootstrap";
import { factory, basicToComplex, complexToBasic } from "safe-evaluate-expression";
import operators from "safe-evaluate-expression-operators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faPlusSquare,
  faTrashAlt,
  faChevronRight,
  faChevronDown,
  faClipboard,
  faCopy,
  faPaste,
  faPowerOff,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import cloneDeep from "lodash.clonedeep";
import { useDataSettings } from "../../hooks/useData";
import styled from "styled-components";
import useDragAndDrop, { DragAndDrop } from "../../hooks/useDragAndDrop";
import useClipboard from "../../hooks/useClipboard";
import shortid from "shortid";
import niceTry from "nice-try";
import isEqual from "lodash.isequal";
import pDebounce from "p-debounce";
import Container from "../container";
import { getScheduling } from "../../db/refManager";

import styles from "./styles.module.scss";
import "./globals.scss";

const debounceDelay = 1000;
const emptyArray = [];

const evaluate = factory({
  operators,
  multipleParams: true,
  translateLogical: true,
});

const onSave = pDebounce(
  (onChangeCondition, data) => onChangeCondition && onChangeCondition(data),
  debounceDelay
);

const onScheduling = pDebounce(
  (onScheduled, data) => onScheduled && onScheduled(...getScheduling(data)),
  debounceDelay
);

export const getOperatorsAndValues = (data, operators = new Map()) => {
  if (data.and || data.or) return getOperatorsAndValues(data.and || data.or, operators);

  data.forEach((d) => {
    if (d.and || d.or) getOperatorsAndValues(d.and || d.or, operators);
    else {
      const values = operators.get(d.operator) || new Set();
      const value = d.values?.[1]?.value;
      if (value) values.add(value);
      operators.set(d.operator, values);
    }
  });

  return operators;
};

export const findCondition = (id, data) => {
  if (data.and || data.or) return findCondition(id, data.and || data.or);
  return data.find((d) => d.id === id);
};

const ConditionBox = styled.span`
  font-family: "Menlo", "Courier New", Courier, monospace;
  font-size: 11px !important;
  font-color: #888;
`;

const RangeOperator = styled.span`
  color: darkmagenta;
  font-size: 1em;
  font-weight: 900;
`;

const NotOperator = styled.span`
  color: red !important;
  font-weight: 900;
`;

const AndOperator = styled.span`
  color: darkcyan !important;
  font-weight: 900;
`;

const OrOperator = styled.span`
  color: orange !important;
  font-weight: 900;
`;

const Operator = styled.span`
  color: #fff !important;
  font-weight: 900;
`;

const AlwaysOperator = styled.span`
  color: rgb(28, 197, 28);
  font-weight: 900;
`;

export const formatCondition = (b, caller) => {
  let basic = cloneDeep(b);

  if (basic.startsWith("(") && basic.endsWith(")")) {
    basic = basic.substring(1, basic.length - 1);
  }

  const elements = basic.split(/(\s|÷|!)/);

  return (
    <ConditionBox key="ConditionBox">
      {elements.map((_, i) => {
        const key = `frag-${i}`;
        const matchOperator = _.match(/\b\w+(\w(\())/g);

        if (matchOperator) {
          const operator = matchOperator[0].substring(0, matchOperator[0].length - 1);
          const value = _.substring(_.lastIndexOf("("));

          return (
            <span key={key}>
              {_.startsWith("(") ? "(" : ""}
              <Operator>{operator}</Operator>
              {value}
            </span>
          );
        }
        if (_ === "&&" || _ === "and" || _ === "AND") _ = <AndOperator>{" AND "}</AndOperator>;
        if (_ === "||" || _ === "or" || _ === "OR") _ = <OrOperator>{" OR "}</OrOperator>;
        if (_ === "!" || _ === "not" || _ === "NOT") _ = <NotOperator>{" NOT "}</NotOperator>;
        if (_ === "÷") _ = <RangeOperator>{" <-> "}</RangeOperator>;
        if (_ === "*") _ = <AlwaysOperator>{"✅ ALWAYS"}</AlwaysOperator>;
        return <span key={key}>{_}</span>;
      })}
    </ConditionBox>
  );
};
export default function Conditions(props) {
  const {
    evaluateData,
    compact = false,
    folded,
    showLevel = 2,
    canEdit = true,
    expandable = true,
    extra,
    label = "Conditions",
    enableReinitialize = false,
    defaultOperator = "*",
    onChangeCondition,
    onScheduled,
    warning,
    getKeyComponent,
    getValueComponent,
    operatorsFilter,
  } = props;

  const { clipboard, copy } = useClipboard();
  const [condition, setCondition] = useState(props.condition || defaultOperator);
  const [conditionRaw, setConditionRaw] = useState(props.conditionRaw || basicToComplex(condition));
  const resetData = useRef(conditionRaw);
  const clip = useMemo(() => niceTry(() => JSON.parse(clipboard)) || {}, [clipboard]);

  useEffect(() => {
    if (enableReinitialize) {
      if (props.conditionRaw) setConditionRaw(props.conditionRaw);
      if (props.condition) setCondition(props.condition);
    }
  }, [enableReinitialize, props]);

  useEffect(() => {
    const condition = complexToBasic(conditionRaw);
    setCondition(condition);
  }, [conditionRaw]);

  const handleChange = useCallback(
    (raw) => {
      if (!canEdit) return;
      const condition = complexToBasic(raw);
      setCondition(condition);
      setConditionRaw(raw);

      const [scheduled, scheduling] = getScheduling(raw);
      onSave(onChangeCondition, { condition, conditionRaw: raw, scheduled, scheduling });
      onScheduling(onScheduled, raw);
    },
    [canEdit, onChangeCondition, onScheduled]
  );

  const copyConditions = useCallback(
    () => copy(JSON.stringify({ _ares: "conditions", condition: conditionRaw })),
    [conditionRaw, copy]
  );
  const pasteConditions = useCallback(
    () => handleChange(clip.condition),
    [clip.condition, handleChange]
  );

  const resetCondition = useCallback(() => handleChange(resetData.current), [handleChange]);

  const header = useMemo(() => {
    return formatCondition(condition, "header");
  }, [condition]);

  const extraBox = useMemo(() => {
    return (
      <div className={styles.extraBox}>
        {extra ? <div className={styles.extra}>{extra}</div> : null}
        <div className={styles.toolbar}>
          <NavDropdown
            title={<FontAwesomeIcon icon={faClipboard} />}
            id="nav-dropdown-conditions-clip"
            className={styles.extraMenu}
          >
            <NavDropdown.Item eventKey="1.1" onClick={copyConditions}>
              <FontAwesomeIcon icon={faCopy} className={styles.copyIcon} />
              Copy Conditions
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="1.2"
              onClick={pasteConditions}
              disabled={clip._ares !== "conditions"}
              className={clip._ares !== "conditions" ? styles.disabledMenuItem : ""}
            >
              <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
              Paste Conditions
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              eventKey="1.3"
              onClick={resetCondition}
              className={isEqual(conditionRaw, resetData.current) ? styles.disabledMenuItem : ""}
              disabled={isEqual(conditionRaw, resetData.current)}
            >
              <FontAwesomeIcon icon={faPowerOff} className={styles.resetIcon} />
              Reset
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    );
  }, [clip, conditionRaw, copyConditions, extra, pasteConditions, resetCondition]);

  return (
    <Container
      title={label}
      color="#200020"
      help="conditions"
      extra={extraBox}
      header={header}
      folded={folded}
      foldable={!compact}
      warning={warning}
    >
      <div className={styles.body}>
        <DragAndDrop>
          <Group
            data={conditionRaw}
            evaluateData={evaluateData}
            onChange={handleChange}
            showLevel={showLevel}
            canEdit={canEdit}
            expandable={expandable}
            getKeyComponent={getKeyComponent}
            getValueComponent={getValueComponent}
            operatorsFilter={operatorsFilter}
            defaultOperator={defaultOperator}
          />
        </DragAndDrop>
      </div>
    </Container>
  );
}

function Group(props) {
  const {
    data,
    evaluateData,
    onChange,
    index = 0,
    parent,
    showLevel,
    parentConditions = emptyArray,
    nestingLevel = 1,
    expandable,
    canEdit,
    defaultOperator,
    getKeyComponent,
    getValueComponent,
    operatorsFilter,
  } = props;

  const [open, setOpen] = useState(nestingLevel <= showLevel);
  const [inError, setInError] = useState(false);
  const logical = useMemo(() => (data.and ? "and" : "or"), [data]);
  const conditions = useMemo(() => data.and || data.or, [data]);
  const length = useMemo(() => conditions.length, [conditions]);
  const id = useMemo(
    () => `group-${parent}-${index}-${nestingLevel}`,
    [index, nestingLevel, parent]
  );

  const ref = useRef(null);
  const dndRealm = useRef(shortid.generate());
  const verified = useMemo(() => {
    if (evaluateData?.length > 0) {
      try {
        const expression = complexToBasic(data);

        const judge = expression === "*" ? true : evaluate(expression, ...evaluateData);
        setInError(false);
        return judge;
      } catch (error) {
        //console.log(error);
        setInError(true);
      }
    }

    return null;
  }, [data, evaluateData]);

  const onDrop = useCallback(
    (newConditions) => {
      const newData = { ...data };
      newData[logical] = newConditions;

      onChange(newData, index);
    },
    [data, index, logical, onChange]
  );

  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    id,
    index,
    parent,
    parentConditions,
    (_, data) => {
      props.onDrop && props.onDrop(data);
    }
  );

  const stile = useMemo(() => {
    const style = { opacity, borderWidth: `${nestingLevel}px` };

    if (highlighted && !hovered) {
      style.borderColor = "#aaa";

      return style;
    }
    if (hovered) {
      style.borderColor = "greenyellow";
      style.borderBox = "4px 4px 8px deeppink";
      style.marginTop = "20px";
      style.marginBottom = "20px";
      style.opacity = 0.2;

      return style;
    }
    style.borderColor = logical === "and" ? "darkcyan" : "orange";

    return style;
  }, [highlighted, hovered, logical, nestingLevel, opacity]);

  const onChangeLogical = useCallback(
    (newLogic) => {
      const newData = { ...data };
      newData[newLogic] = data[logical];
      delete newData[logical];

      onChange(newData, index);
    },
    [data, index, logical, onChange]
  );

  const onChangeData = useCallback(
    (updatedData, changeIndex) => {
      const newData = { ...data };
      if (changeIndex !== undefined) {
        if (updatedData !== undefined) {
          newData[logical][changeIndex] = updatedData;
        } else {
          newData[logical].splice(changeIndex, 1);
        }
      }

      onChange(newData, index);
    },
    [data, index, logical, onChange]
  );

  const onAddGroup = useCallback(() => {
    const newData = { ...data };
    newData[logical].push({ and: [] });
    onChange(newData, index);
  }, [data, index, logical, onChange]);

  const onAddCondition = useCallback(() => {
    const newData = { ...data };
    newData[logical].push({ id: shortid.generate(), operator: defaultOperator, values: [] });
    onChange(newData, index);
  }, [data, index, logical, onChange]);

  const onDeleteGroup = useCallback(() => onChange(undefined, index), [index, onChange]);

  const onDeleteRule = useCallback(
    (deleteIndex) => {
      const newData = { ...data };
      newData[logical].splice(deleteIndex, 1);

      onChange(newData, index);
    },
    [data, index, logical, onChange]
  );

  const toggleOpen = useCallback(() => setOpen((state) => !state), []);
  const style = useMemo(
    () => (!open ? { borderBottom: "0px", marginBottom: "0px", paddingBottom: "0px" } : {}),
    [open]
  );
  const syntheticData = useMemo(() => {
    const basic = complexToBasic(data);
    return formatCondition(basic, "syntheticData");
  }, [data]);

  return (
    <div className={styles.group} style={stile} ref={ref}>
      <div className={styles.header} style={style}>
        <div className={styles.headerBox}>
          {verified !== null || inError === true ? (
            <div className={styles.verified}>
              <FontAwesomeIcon
                icon={inError ? faExclamationCircle : verified ? faCheckCircle : faTimesCircle}
                className={inError ? styles.error : verified ? styles.ok : styles.ko}
              />
            </div>
          ) : null}
          {expandable ? (
            <div className={styles.toggler}>
              <FontAwesomeIcon
                icon={open ? faChevronDown : faChevronRight}
                onClick={toggleOpen}
                className={styles.toggleIcon}
                fixedWidth
              />
            </div>
          ) : null}

          <div className={styles.logic}>
            <AndOrButtons logical={logical} onChange={onChangeLogical} canEdit={canEdit} />
          </div>

          <div className={styles.spacer} onClick={toggleOpen}>
            {!open ? <div className={styles.basicRule}>{syntheticData}</div> : null}
          </div>
          <div className={styles.toolbar}>
            <ConditionToolbar
              includeGroup={true}
              canEdit={canEdit}
              canAdd={true}
              canDelete={nestingLevel > 1}
              onAddGroup={onAddGroup}
              onAdd={onAddCondition}
              onDelete={onDeleteGroup}
            />
          </div>
        </div>
      </div>

      {open ? (
        <div className={styles.groupBody}>
          {conditions.map((condition, indice) => {
            if (condition.and || condition.or) {
              return (
                <Group
                  key={`${logical}-${indice}`}
                  data={condition}
                  evaluateData={evaluateData}
                  index={indice}
                  parent={dndRealm.current}
                  parentConditions={conditions}
                  uuid={shortid.generate()}
                  onDrop={onDrop}
                  onChange={onChangeData}
                  nestingLevel={nestingLevel + 1}
                  showLevel={showLevel}
                  canEdit={canEdit}
                  expandable={expandable}
                  getKeyComponent={getKeyComponent}
                  getValueComponent={getValueComponent}
                  operatorsFilter={operatorsFilter}
                  defaultOperator={defaultOperator}
                />
              );
            }
            //`condition-${parent || dndRealm.current}-${index}-${nestingLevel}-${indice}`
            if (!condition.id) condition.id = shortid.generate();

            return (
              <Condition
                id={condition.id}
                key={condition.id}
                index={indice}
                data={condition}
                evaluateData={evaluateData}
                conditions={conditions}
                canEdit={canEdit}
                onChange={onChangeData}
                canDelete={length > 1}
                onDelete={onDeleteRule}
                onAdd={onAddCondition}
                onDrop={onDrop}
                parent={dndRealm.current}
                getKeyComponent={getKeyComponent}
                getValueComponent={getValueComponent}
                operatorsFilter={operatorsFilter}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

function ConditionToolbar({
  includeGroup,
  canAdd = false,
  canDelete = true,
  onAddGroup,
  onAdd,
  onDelete,
  canEdit = true,
}) {
  const handleDelete = useCallback(() => onDelete(), [onDelete]);
  if (!canEdit) return null;

  return (
    <ButtonToolbar aria-label="Toolbar Group" className={styles.buttonGroups}>
      <ButtonGroup
        className="mr-2"
        aria-label="Second group"
        ref={(node) => {
          if (node) {
            node.style.setProperty("margin-right", "0px", "important");
          }
        }}
      >
        {includeGroup ? (
          <Button
            size="sm"
            variant="outline-secondary"
            className={styles.button}
            onClick={onAddGroup}
          >
            <FontAwesomeIcon icon={faLayerGroup} fixedWidth />
          </Button>
        ) : null}
        {canAdd ? (
          <Button size="sm" variant="outline-success" className={styles.button} onClick={onAdd}>
            <FontAwesomeIcon icon={faPlusSquare} fixedWidth />
          </Button>
        ) : null}

        {canDelete ? (
          <Button
            size="sm"
            variant="outline-danger"
            className={styles.button}
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
          </Button>
        ) : null}
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export function AndOrButtons(props) {
  const { logical, onChange, canEdit } = props;
  const changeAND = useCallback(
    () => canEdit && logical !== "and" && onChange && onChange("and"),
    [canEdit, logical, onChange]
  );
  const changeOR = useCallback(
    () => canEdit && logical !== "or" && onChange && onChange("or"),
    [canEdit, logical, onChange]
  );

  return (
    <ButtonToolbar aria-label="Toolbar Group" className={(props.styles || styles).andOrButtonGroup}>
      <ButtonGroup
        className="mr-2"
        aria-label="Second group"
        ref={(node) => {
          if (node) {
            node.style.setProperty("margin-right", "0px", "important");
          }
        }}
      >
        <Button size="sm" variant={logical === "and" ? "info" : "dark"} onClick={changeAND}>
          <span style={{ color: logical === "and" ? "#fff" : "#444" }}>AND</span>
        </Button>
        <Button size="sm" variant={logical === "or" ? "warning" : "dark"} onClick={changeOR}>
          <span style={{ color: logical === "or" ? "#000" : "#444" }}>OR</span>
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
}

const canShowValue = (operator) => !["*", "isEmpty", "!isEmpty"].includes(operator);

function Condition({
  id,
  parent,
  index,
  conditions,
  onDelete,
  onChange,
  onDrop,
  data,
  evaluateData,
  getKeyComponent,
  getValueComponent,
  operatorsFilter,
}) {
  const ref = useRef(null);

  const {
    logical,
    operator,
    values: [v1, v2],
  } = data;
  const [operatorTypes, setOperatorTypes] = useState();
  const [inError, setInError] = useState(false);

  const verified = useMemo(() => {
    if (evaluateData?.length > 0) {
      try {
        const expression = complexToBasic({ and: [data] });
        const judge = expression === "*" ? true : evaluate(expression, ...evaluateData);
        setInError(false);
        return judge;
      } catch (error) {
        setInError(true);
      }
    }

    return null;
  }, [data, evaluateData]);

  const valueType = useMemo(() => {
    if (v2?.type) return Array.isArray(v2.type) ? v2.type[0] : v2.type;
    return operatorTypes?.expected?.[0];
  }, [operatorTypes, v2]);

  //console.log(valueType, "expected", operatorTypes?.expected);
  const handleDelete = useCallback(() => onDelete(index), [index, onDelete]);
  const handleChangeOperator = useCallback(
    (operator, types) => {
      if (operator !== data.operator) {
        const newData = cloneDeep(data); //{ ...data };
        newData.operator = operator;
        newData.values[0] = { type: "metadata", value: "" };
        newData.values[1] = { type: "", value: "" };

        setOperatorTypes(types);
        onChange(newData, index);
      }
    },
    [data, index, onChange]
  );

  const handleChangeKeyType = useCallback(
    (type) => {
      if (data.values[0].type !== "metadata") {
        const newData = cloneDeep(data); //{ ...data };
        newData.values[0].type = "metadata";
        onChange(newData, index);
      }
    },
    [data, index, onChange]
  );

  const handleChangeKeyValue = useCallback(
    (value) => {
      if (value !== data.values[0]?.value) {
        const newData = cloneDeep(data); //{ ...data };
        if (data.values[0]) newData.values[0].value = value;
        if (data.values[1]) newData.values[1].value = "";
        onChange(newData, index);
      }
    },
    [data, index, onChange]
  );

  const handleChangeValueType = useCallback(
    (type) => {
      const tipo = Array.isArray(type) ? type[0] : type;

      if (tipo !== data.values[1].type) {
        const newData = cloneDeep(data);
        // TODO: CHECK SIDE EFFECTS
        //⚠️ newData.values[1] = { type: tipo, value: "" };
        newData.values[1].type = tipo;
        onChange(newData, index);
      }
    },
    [data, index, onChange]
  );

  const handleChangeValue = useCallback(
    (value) => {
      if (value !== data.values[1].value) {
        const newData = cloneDeep(data); //{ ...data };
        newData.values[1] = { value, type: valueType };

        onChange(newData, index);
      }
    },
    [data, index, onChange, valueType]
  );

  const handleResetOperator = useCallback(
    (operator, types) => {
      const newData = { ...data, operator };
      if (!isEqual(data, newData)) {
        setOperatorTypes(types);
        onChange(newData, index);
      }
    },
    [data, index, onChange]
  );

  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    id,
    index,
    parent,
    conditions,
    (_, data) => {
      onDrop(data);
    }
  );

  const stile = useMemo(() => {
    const style = { opacity };

    if (highlighted && !hovered) {
      style.color = "#aaa";

      return style;
    }
    if (hovered) {
      style.color = "deeppink";
      style.marginTop = "10px";
      style.marginBottom = "10px";
      style.opacity = 0.2;

      return style;
    }
    style.borderColor = logical === "and" ? "darkcyan" : "orange";

    return style;
  }, [highlighted, hovered, logical, opacity]);

  return (
    <div className={styles.condition} ref={ref} style={stile}>
      {verified !== null || inError === true ? (
        <div className={styles.verified}>
          <FontAwesomeIcon
            icon={inError ? faExclamationCircle : verified ? faCheckCircle : faTimesCircle}
            className={inError ? styles.error : verified ? styles.ok : styles.ko}
          />
        </div>
      ) : null}
      <div className={styles.logic}>
        {operator !== "*" ? (
          <div className={styles.valueOne}>
            {getKeyComponent({
              id,
              value: v1?.value,
              handleChangeValue: handleChangeKeyValue,
              handleChangeType: handleChangeKeyType,
              types: operatorTypes?.metadata,
              inError: !v1?.value,
            })}
          </div>
        ) : null}

        <div className={styles.operator}>
          <Operators
            value={operator}
            keyValue={v1?.value}
            handleChange={handleChangeOperator}
            handleChangeTypes={setOperatorTypes}
            handleResetOperator={handleResetOperator}
            operatorsFilter={operatorsFilter}
          />
        </div>
        {canShowValue(operator) && v1?.value ? (
          <div className={styles.valueTwo}>
            {getValueComponent({
              id,
              operatorTypes,
              field: v1?.value,
              value: v2?.value,
              valueType,
              handleChangeValue: handleChangeValue,
              handleChangeType: handleChangeValueType,
            })}
          </div>
        ) : null}
      </div>
      <div className={styles.toolbar}>
        <ConditionToolbar includeGroup={false} canDelete={true} onDelete={handleDelete} />
      </div>
    </div>
  );
}

function getTypes(operators, value) {
  if (!operators || !value) return [];

  const operator = operators.find((o) => o.value === value || o.valueRaw === value);

  if (!operator) return [];

  return {
    expected: operator.expectedTypes,
    value: operator.valueTypes,
    metadata: operator.metadataTypes,
  };
}

function Operators(props) {
  const {
    name,
    keyValue,
    handleChange,
    handleChangeTypes,
    handleResetOperator,
    operatorsFilter,
    value,
  } = props;
  const operatori = useDataSettings("operators");

  const [operators, restricted] = useMemo(
    () => operatorsFilter(keyValue, operatori),
    [keyValue, operatori, operatorsFilter]
  );

  // Reset Operator to EQUALS is is restricted
  useEffect(() => {
    if (restricted) {
      handleResetOperator("equals", getTypes(operators, "equals"));
    }
  }, [handleResetOperator, operators, restricted]);

  useEffect(() => {
    handleChangeTypes(getTypes(operators, value));
  }, [handleChangeTypes, operators, value]);

  const onChange = useCallback(
    (evt) => {
      const value = evt.target.value;
      handleChange(value, getTypes(operators, value));
    },
    [handleChange, operators]
  );

  if (!operators) return <div className={styles.operatorsLoading}></div>;

  return (
    <Form.Control
      as="select"
      name={name}
      value={value}
      onChange={onChange}
      isInvalid={!value}
      className={restricted ? styles.operatorsRestricted : ""}
    >
      {operators.map((o, i) => (
        <option key={`operator-${i}`} value={o.valueRaw || o.value}>
          {o.label}
        </option>
      ))}
    </Form.Control>
  );
}
