import { useMemo, useCallback, useState, useEffect } from "react";
import { DragAndDrop } from "../../hooks/useDragAndDrop";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";
import { useDataRefItem } from "../../hooks/useDataRef";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faCubes, faVirus } from "@fortawesome/free-solid-svg-icons";
import LabelSelect from "../selects/selectLabel";
import { Row, Col, Form } from "react-bootstrap";
import usePermissions from "../../hooks/usePermissions";

import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";

import SelectAll from "../selects/selectAll";
import Container from "../container";
import RotaryKnob from "../rotaryKnob";
import Rule from "./rule";
import ContentType from "../contentType";
import PresetBox from "./presetBox";
import AndOrSelector from "./andOrSelector/";

import "./style.scss";
import styles from "./query.module.scss";

export const colors = {
  filter: "#015301",
  exclude: "#5a0000",
  include: "#002f30",
  boost: "#2a003b",
  order: "#040f20",
};

const labelTypes = ["queries"];
const labelStyle = { width: "400px" };
const ruleSelectStyle = { width: "466px" };
const presetSelectStyle = { width: "388px" };
const knobStyle = { marginLeft: "0px", width: "400px" };

export default function Query() {
  const { values, errors, setFieldValue } = useFormikContext();
  const info = useDataRefItem("queries", values.id);
  const getRule = useGetMemItem("rules");
  const labelMain = useGetMemItem("labelsAll", values.label);
  const { queryClauses, queryDefaults } = useDataSettings("queryClauses", "queryDefaults") || {};
  const { expert } = usePermissions();
  const queryExpert = useMemo(() => expert.includes("query-settings"), [expert]);
  const [alreadyInClauses, setAlreadyInClauses] = useState({});

  useEffect(() => {
    if (queryClauses)
      queryClauses.forEach((c) => {
        const inValue = (values[c.accessor] || []).map((a) => a.id);
        const inState = alreadyInClauses[c.accessor] || [];
        const diff1 = inValue.filter((f) => !inState.includes(f));
        const diff2 = inState.filter((f) => !inValue.includes(f));

        if (diff1.length || diff2.length) {
          setAlreadyInClauses((state) => {
            state[c.accessor] = inValue;
            return state;
          });
        }
      });
  }, [alreadyInClauses, queryClauses, values]);

  const rulesIds = useMemo(() => {
    const rules = new Set();
    if (queryClauses) {
      queryClauses.forEach((c) => {
        (values[c.accessor] || []).forEach((a) => {
          rules.add(a.id);
        });
      });
    }

    return rules;
  }, [queryClauses, values]);

  const contentType = useMemo(() => values.contentType, [values.contentType]);

  const onMinShouldPerc = useCallback(() => {
    const val = get(values, "general.minimumShouldMatch");
    if (val) {
      const newVal = val.includes("%") ? val.replace("%", "") : `${val}%`;

      setFieldValue("general.minimumShouldMatch", newVal);
    }
  }, [setFieldValue, values]);

  const onTypeChange = useCallback(
    (type) => {
      setFieldValue("contentType", type);
      queryClauses.forEach((c) => setFieldValue(c.accessor, []));
    },
    [queryClauses, setFieldValue]
  );

  const updateMetadataOverrides = useCallback(
    (key, value) => {
      let existing = cloneDeep(values.metadataOverrides || {});
      existing[key] = value;
      setFieldValue("metadataOverrides", existing);
      setFieldValue("lastSaved.date", new Date());
    },
    [setFieldValue, values]
  );

  const cleanOverrides = useCallback(
    (id, accessor) => {
      let statement = [...values[accessor]];
      let existing = cloneDeep(values.metadataOverrides || {});
      delete existing[id];
      const index = statement.findIndex((k) => k.id === id);

      statement[index] = { id: statement[index].id };

      setFieldValue("metadataOverrides", existing);
      setFieldValue(accessor, statement);
    },
    [setFieldValue, values]
  );

  const settingsHeader = useMemo(() => {
    const multiplier =
      values?.general?.resultsMultiplier || queryDefaults?.general?.resultsMultiplier || 4;
    return (
      <div className="settings-header">
        <div className="label">Catalog</div>
        <div className="catalog">{values.contentType || "TBD"}</div>
        {queryExpert && (
          <>
            <div className="multiplier">Results Multiplier</div>
            <div
              className="value"
              style={{
                backgroundColor: multiplier > 1 ? "goldenrod" : "",
                color: multiplier > 1 ? "black" : "white",
              }}
            >
              {multiplier}
              {values.uselessMultiplier && (
                <FontAwesomeIcon icon={faVirus} className={styles.queryDisease} />
              )}
            </div>
          </>
        )}
      </div>
    );
  }, [values, queryDefaults, queryExpert]);

  const extraLabel = useMemo(() => {
    if (labelMain) {
      const { background, color, value } = labelMain;
      return (
        <div className="label-main" style={{ backgroundColor: background, color: color }}>
          {value}
        </div>
      );
    }
  }, [labelMain]);

  const accessorIsEmpty = useCallback(
    (accessor) => !values[accessor]?.length > 0 && !values.presets?.[accessor]?.length > 0,
    [values]
  );

  const [folded, setFolded] = useState(new Map());

  const onFold = useCallback(
    (statement, status) => {
      const newFolded = new Map(folded);
      newFolded.set(statement, status);
      setFolded(newFolded);
    },
    [folded]
  );

  const includeHeader = useMemo(() => {
    if (accessorIsEmpty("include") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) => setFieldValue("general.mustOperator", value)}
        onChangeBreaker={(value) => setFieldValue("general.tieBreaker", value)}
        operator={values?.general?.mustOperator}
        breaker={values?.general?.tieBreaker}
        includeFolded={folded.get("include")}
        defaultOperator="or"
        defaultBreaker={queryDefaults?.general?.tieBreaker}
      />
    );
  }, [accessorIsEmpty, queryExpert, values, folded, queryDefaults, setFieldValue]);

  const excludeHeader = useMemo(() => {
    if (accessorIsEmpty("exclude") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) => setFieldValue("general.mustNotOperator", value)}
        onChangeBreaker={(value) => setFieldValue("general.tieBreakerMustNot", value)}
        operator={values?.general?.mustNotOperator}
        breaker={values?.general?.tieBreakerMustNot}
        includeFolded={folded.get("exclude")}
        defaultBreaker={queryDefaults?.general?.tieBreaker}
      />
    );
  }, [accessorIsEmpty, queryExpert, values, folded, queryDefaults, setFieldValue]);

  const filterHeader = useMemo(() => {
    if (accessorIsEmpty("filter") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) => setFieldValue("general.filterOperator", value)}
        onChangeBreaker={(value) => setFieldValue("general.tieBreakerFilter", value)}
        operator={values?.general?.filterOperator}
        breaker={values?.general?.tieBreakerFilter}
        includeFolded={folded.get("filter")}
        defaultBreaker={queryDefaults?.general?.tieBreaker}
      />
    );
  }, [accessorIsEmpty, queryExpert, values, folded, queryDefaults, setFieldValue]);

  const boostHeader = useMemo(() => {
    if (accessorIsEmpty("boost") || !queryExpert) return null;

    const minMatchValue =
      values?.general?.minimumShouldMatch || queryDefaults?.general?.minimumShouldMatch || 1;

    const boostMinMatch = Number(String(minMatchValue).replace("%", ""));
    const boostMinMatchInPerc = minMatchValue.includes("%");

    return (
      <div className={styles.header}>
        <div className={styles.label}>Min Match</div>
        {folded.get("boost") ? (
          <div className={styles.value}>
            {boostMinMatch}
            {boostMinMatchInPerc ? "%" : ""}
          </div>
        ) : (
          <>
            <Form.Control
              type="number"
              min={0}
              max={100}
              step={1}
              value={boostMinMatch}
              className={styles.input}
              onChange={(e) =>
                setFieldValue(
                  "general.minimumShouldMatch",
                  `${e.target.value}${boostMinMatchInPerc ? "%" : ""}`
                )
              }
            />
            <Form.Check
              type="switch"
              id="expert-query-settings-percentage-switch"
              checked={boostMinMatchInPerc}
              value="true"
              onChange={onMinShouldPerc}
              label="Percentage"
              className={styles.check}
            />
          </>
        )}
      </div>
    );
  }, [accessorIsEmpty, folded, onMinShouldPerc, queryDefaults, queryExpert, setFieldValue, values]);

  const getRuleFilters = useCallback(
    (accessor) => {
      const exclude = [...new Set([...(alreadyInClauses[accessor] || []), ...rulesIds])];
      const filters = { catalogs: [contentType], clauses: [accessor], exclude };
      if (info?.isInFallback) filters.canBeFallback = true;

      return filters;
    },
    [alreadyInClauses, contentType, info?.isInFallback, rulesIds]
  );

  const handleAddRule = useCallback(
    (id, accessor) => {
      const newValues = [...values[accessor]];
      newValues.push({ id });
      setFieldValue(accessor, newValues);
    },
    [setFieldValue, values]
  );

  const getPresetFilters = useCallback(
    (accessor) => {
      const filters = { catalogs: [contentType], clauses: [accessor] };

      if (info?.isInFallback) filters.canBeFallback = true;
      if (values.presets?.[accessor]) {
        filters.exlcude = values.presets[accessor].map(({ id }) => id);
      }

      return filters;
    },
    [contentType, info?.isInFallback, values.presets]
  );

  const handleAddPreset = useCallback(
    (id, accessor) => {
      const newValues = [...(values.presets?.[accessor] ?? [])];
      newValues.push({ id });
      setFieldValue(`presets.${accessor}`, newValues);
    },
    [setFieldValue, values]
  );

  if (!queryClauses) return null;

  return (
    <>
      <Container
        color="#06141a"
        foldable={true}
        folded={!errors.contentType}
        coloredBars={true}
        title="Settings"
        extra={extraLabel}
        help="query-settings"
        header={settingsHeader}
        warningMessage={errors.contentType}
      >
        <Row>
          <Col sm="1">Label</Col>
          <Col sm="3">
            <LabelSelect
              types={labelTypes}
              style={labelStyle}
              value={values.label}
              handleChange={(v) => setFieldValue("label", v)}
            />
          </Col>
        </Row>
        <ContentType rootNode={values} onChange={onTypeChange} fixedValue={values.contentType} />
        {queryExpert && (
          <Row>
            <Col sm={{ offset: 1, cols: 3 }}>
              <RotaryKnob
                value={
                  values?.general?.resultsMultiplier ||
                  queryDefaults?.general?.resultsMultiplier ||
                  4
                }
                min={1}
                max={10}
                step={1}
                label="Result Multiplier"
                labelPosition="right"
                skin="s10"
                style={knobStyle}
                onChange={(value) => setFieldValue("general.resultsMultiplier", value)}
              />
            </Col>
          </Row>
        )}
      </Container>

      {values.contentType && (
        <span>
          {queryClauses.map((c) => {
            const folded = accessorIsEmpty(c.accessor);
            const header = (() => {
              if (c.accessor === "include") return includeHeader;
              if (c.accessor === "exclude") return excludeHeader;
              if (c.accessor === "filter") return filterHeader;
              if (c.accessor === "boost") return boostHeader;
              return null;
            })();

            return (
              <Container
                key={c.accessor}
                color={colors[c.accessor]}
                foldable={true}
                folded={folded} //
                onFold={(state) => onFold(c.accessor, state)}
                coloredBars={false}
                title={c.label}
                help={`query-${c.accessor}`}
                warning={errors[c.accessor]}
                warningMessage={errors.clauses}
                header={header}
                headerOnlyOnFolded={false}
                extra={
                  <div className="rules-count">
                    <span className="count">{values.presets?.[c.accessor]?.length || 0}</span>
                    <span className="label">Presets</span>
                    <span className="count">{values[c.accessor]?.length || 0}</span>
                    <span className="label">Rules</span>
                  </div>
                }
              >
                <div className="rules-presets-box">
                  <Container
                    className="rule-box-container"
                    help="query-rules-box"
                    coloredBars={false}
                    foldable={false}
                    hover={false}
                    title={
                      <div className="rules-label">
                        <FontAwesomeIcon icon={faCubes} className="icon" /> rules
                      </div>
                    }
                  >
                    <div className="query-statement-popup">
                      <SelectAll
                        accessor="rules"
                        filters={getRuleFilters(c.accessor)}
                        handleAdd={(id) => handleAddRule(id, c.accessor)}
                        labelsFilter={true}
                        haveButton={true}
                        style={ruleSelectStyle}
                      />
                    </div>
                    {values?.[c.accessor]?.length > 0 ? (
                      <div className="query-rules-container">
                        <DragAndDrop>
                          {values[c.accessor] &&
                            values[c.accessor].map((r, i) => {
                              //if (rules) {
                              const rule = getRule(r.id);

                              if (!rule) return null;

                              const overrides = values.metadataOverrides;
                              const ovrMetadata = overrides && overrides[r.id];
                              const ruleMetadata = ovrMetadata || rule.metadata;

                              //(values.metadataOverrides || {})[r.id]
                              return (
                                <Rule
                                  key={`rule-${r.id}-${c.accessor}`}
                                  accessor={c.accessor}
                                  index={i}
                                  metadata={ruleMetadata}
                                  haveMetaOverrides={Object.keys(ovrMetadata || {}).length > 0}
                                  id={r.id}
                                  rule={rule}
                                  handleCleanRule={() => cleanOverrides(r.id, c.accessor)}
                                  onMetadataChange={(value) => {
                                    updateMetadataOverrides(r.id, value);
                                  }}
                                />
                              );
                              //}
                            })}
                        </DragAndDrop>
                      </div>
                    ) : null}
                  </Container>

                  <div className="spacer" />
                  <Container
                    className="presets-box-container"
                    coloredBars={false}
                    foldable={false}
                    hover={false}
                    help="query-presets-box"
                    title={
                      <div className="presets-label">
                        <FontAwesomeIcon icon={faThLarge} className="icon" /> presets
                      </div>
                    }
                  >
                    <SelectAll
                      accessor="rulesPresets"
                      filters={getPresetFilters(c.accessor)}
                      handleAdd={(id) => handleAddPreset(id, c.accessor)}
                      haveButton={true}
                      style={presetSelectStyle}
                    />

                    {values.presets?.[c.accessor]?.length > 0 ? (
                      <div className="query-presets-container">
                        {values.presets?.[c.accessor]?.map((preset, index) => (
                          <PresetBox
                            key={preset.id}
                            preset={preset}
                            index={index}
                            clause={c.accessor}
                          />
                        ))}
                      </div>
                    ) : null}
                  </Container>
                </div>
              </Container>
            );
          })}
        </span>
      )}
    </>
  );
}
