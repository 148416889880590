import { types } from "../actions/user";

const initialState = { logging: false, haveUnsavedChanges: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST: {
      return { logging: true };
    }
    case types.USER_LOGIN_ERROR: {
      return { ...state, errored: true, logging: false };
    }
    case types.USER_LOGGED: {
      return action.payload;
    }
    case types.USER_SELECT_TENANT: {
      return { ...state, tenant: action.payload };
    }
    case types.USER_LOGGED_STATUS: {
      return { ...state, loggedStatus: action.payload };
    }
    case types.USER_LOGGED_USERS: {
      return { ...state, loggedUsers: action.payload };
    }
    case types.USER_LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
