/* eslint-disable no-restricted-globals */

import axios from "axios";
import pDebounce from "p-debounce";
import defaultsDeep from "lodash.defaultsdeep";
import figlet from "figlet";
import standard from "figlet/importable-fonts/Standard.js";
import pJson from "../../../package.json";
import { db, refManager } from "../../db";
import { socket } from "../../transport";

// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24#issuecomment-672816401
// self.$RefreshReg$ = () => {};
// self.$RefreshSig$$ = () => () => {};

const apiEndp = process.env.REACT_APP_API_ENDPOINT;
const deserializeMap = (map) => new Map(JSON.parse(map));
const debounceWait = 3000;

const debouncedReloadVolatile = pDebounce((tenant, id_token) => {
  return updateVolatileRequestMetadata(tenant, id_token);
}, debounceWait);

const rejectSave = (data, error, options) => {
  const { accessor, Id } = options;
  data.error = error;
  data.accessor = accessor;
  data.Id = Id;

  saving = false;

  return JSON.stringify(data);
};

let TENANT;
let USER;
let TOKEN;

export let startRequested = false;
export let started = false;
export let loadingData = false;
export let saving = false;
export let healthCheck;

function booleanToIndex(data) {
  Object.values(data).forEach((elm) => {
    Object.entries(elm).forEach(([key, value]) => {
      if (value === true) elm[key] = 1;
      else if (value === false) elm[key] = 0;
    });
  });
  return data;
}

const toObjId = (data) => ({ [data.id]: data });

function computeLabelsColors(labels) {
  const all = [];

  for (const id in labels) {
    const label = labels[id];
    label.elements.forEach((elm) => {
      elm.type = label.type;
      elm.parent = label.id;
      all.push(elm);
    });
  }

  return [
    ["labels", Object.values(labels)],
    ["labelsAll", all],
  ];
}

function computeKeys(keys) {
  const ret = [];

  Object.entries(keys).map(([id, key]) => ret.push({ id, key }));

  return ret;
}

function socketRequest(key, payload) {
  return new Promise((resolve, reject) => {
    console.time(`🤟🏻 REQUEST-${key.toUpperCase()}`);
    socket.emit(key, payload, (data) => {
      if (data.data) resolve(data.data);
      else reject(data.error);
      console.timeEnd(`🤟🏻 REQUEST-${key.toUpperCase()}`);
    });
  });
}

function requestData(tenant, id_token, what) {
  return new Promise((resolve, reject) => {
    console.time(`🤟🏻 REQUEST-${what.toUpperCase()}`);
    socket.emit("data", { what, tenant, id_token }, (data) => {
      if (data.data) {
        if (what === "keys") resolve(computeKeys(data.data));
        if (what === "labels") resolve(computeLabelsColors(data.data));
        console.timeEnd(`🤟🏻 REQUEST-${what.toUpperCase()}`);
        resolve(booleanToIndex(data.data));
      } else {
        console.error(
          `${what.toUpperCase()} ${data.error}` || `⚠️ NO DATA FOR ${what.toUpperCase()}`
        );
        resolve([]);
        console.timeEnd(`🤟🏻 REQUEST-${what.toUpperCase()}`);
      }
    });
  });
}

function requestHealthData(tenant) {
  return new Promise((resolve, reject) => {
    socket.emit("health", false, (data) => {
      resolve(data);
    });
  });
}

function requestSettings(tenant) {
  const defaults = require("../../settings/client.json");

  return new Promise((resolve, reject) =>
    axios
      .get(`${apiEndp}/settings/${tenant}`)
      .then(({ data }) => {
        if (data) {
          resolve(defaultsDeep(data, defaults));
        }
      })
      .catch((e) => reject(e))
  );
}

function requestNotes(tenant) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${apiEndp}/notes/${tenant}/all`)
      .then(({ data }) => {
        if (data) resolve(data);
      })
      .catch((e) => reject(e))
  );
}

function requestHelp() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${apiEndp}/help/all`)
      .then(({ data }) => {
        if (data) resolve(data);
      })
      .catch((e) => reject(e))
  );
}

function requestMetadata(tenant) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${apiEndp}/metadata/all/${tenant}`)
      .then(({ data }) => {
        if (data) resolve(data);
      })
      .catch((e) => reject(e))
  );
}

function requestPolicies(tenant, id_token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiEndp}/users/policies/${tenant}`, { headers: { id_token } })
      .then(({ data }) => {
        if (data) resolve(data);
      })
      .catch((e) => reject(e));
  });
}

function requestUsers(tenant, id_token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiEndp}/users/${tenant}`, { headers: { id_token } })
      .then(({ data }) => {
        if (data) resolve(data);
      })
      .catch((e) => reject(e));
  });
}

async function refreshData(payload) {
  const map = deserializeMap(payload.data2Refresh);
  const keys = Array.from(map.keys())
    .map((k) => k.toUpperCase())
    .join(" + ");

  console.time(`⚡️ REFRESH:[${keys}]`);

  for (let [table, items] of map.entries()) {
    for (let { action, data } of items) {
      if (action === "delete") {
        if (table === "labels") {
          const [_, labelsAll] = computeLabelsColors(toObjId(data));
          const ids = labelsAll[1].map(({ id }) => id);
          db.deleteByQuery("labelsAll", (l) => ids.includes(l.id));
        }
        refManager.delete(table, data.id);
        db.delete(table, data.id);
        refManager.fillBelongs(db.getDB());
      } else {
        if (table === "labels") {
          const [_, labelsAll] = computeLabelsColors(toObjId(data));
          const ids = labelsAll[1].map(({ parent }) => parent);
          db.deleteByQuery("labelsAll", (l) => ids.includes(l.parent));
          db.bulkPut("labelsAll", labelsAll[1]);
        }
        db.put(table, data);
        refManager.fillBelongs(db.getDB(), [[data]], table);
      }
    }
  }

  console.timeEnd(`⚡️ REFRESH:[${keys}]`);
  refManager.checkQueriesHealth();
}

export async function updateData(tenant, id_token) {
  return new Promise(async (resolve, reject) => {
    loadingData = true;
    const standardTables = [
      "analitica",
      "params",
      "properties",
      "endpoints",
      "blocks",
      "queries",
      "rules",
      "rulesPresets",
      "decorators",
      "lists",
      "externalSources",
      "responseFields",
      "ui",
      "keys",
      "services",
      "enrichers",
      "conversions",
      "experiments",
      "conversionPacks",
      "contentPacks",
      "metaPacks",
      "pipelines",
      "loaders",
      "personas",
    ];
    const allTables = standardTables.concat(["labels", "labelsAll"]);

    const data = new Map(await requestData(tenant, id_token, "labels"));
    for (const dataType of standardTables) {
      data.set(dataType, await requestData(tenant, id_token, dataType));
    }

    // ****************
    // MEMORY DB
    // ***************
    for (const dataType of allTables) {
      console.time(`⚡️ MEM-UPDATE-${dataType.toUpperCase()}`);
      const refresh = data.get(dataType);
      const obj = Array.isArray(refresh) ? refresh : Object.values(refresh ?? {});
      db.deleteTable(dataType);
      db.bulkPut(dataType, obj);
      console.timeEnd(`⚡️ MEM-UPDATE-${dataType.toUpperCase()}`);
    }

    refManager.fillBelongs(data);

    loadingData = false;
    db.put("aresInternals", {
      id: "dataFetch",
      lastUpdate: Date.now(),
      realms: allTables,
    });
    resolve(allTables);
  });
}

export async function updateHealthData(tenant) {
  loadingData = true;
  const health = await requestHealthData(tenant);
  loadingData = false;
  db.put("health", health);
  db.put("aresInternals", {
    id: "healthFetch",
    lastUpdate: Date.now(),
    regressions: health?.regressions?.isCritical,
    versions: health?.versions?.isCritical,
  });
}

export async function updatePolicies(tenant, id_token) {
  loadingData = true;
  const policies = await requestPolicies(tenant, id_token);
  loadingData = false;
  db.put("policies", { ...policies, id: "p1" });
  db.put("aresInternals", {
    id: "policiesFetch",
    lastUpdate: Date.now(),
    tenants: policies?.tenants?.length,
    groups: Object.keys(policies?.groups || {}).length,
    permissions: Object.keys(policies?.permissions || {}).length,
    roles: Object.keys(policies?.roles || {}).length,
  });
}

export async function updateSettings(tenant) {
  loadingData = true;
  const settings = await requestSettings(tenant);
  loadingData = false;
  db.put("settings", settings);
  db.put("aresInternals", {
    id: "settingsFetch",
    lastUpdate: Date.now(),
  });
}

export async function updateHelp(tenant) {
  loadingData = true;
  const help = await requestHelp(tenant);
  loadingData = false;

  db.deleteTable("help");
  db.bulkPut("help", help);
  db.put("aresInternals", {
    id: "helpFetch",
    lastUpdate: Date.now(),
    length: help.length,
  });
}

export async function updateNotes(tenant) {
  loadingData = true;
  const notes = await requestNotes(tenant);
  loadingData = false;

  db.deleteTable("notes");
  db.bulkPut("notes", notes);
  db.put("aresInternals", {
    id: "notesFetch",
    lastUpdate: Date.now(),
    length: notes.length,
  });
}

export async function updateUsers(tenant, id_token) {
  loadingData = true;
  const users = await requestUsers(tenant, id_token);
  loadingData = false;

  db.deleteTable("users");
  db.bulkPut("users", users);
  db.put("aresInternals", {
    id: "usersFetch",
    lastUpdate: Date.now(),
    userLength: users.length,
  });
}

export async function updateMetadata(tenant) {
  loadingData = true;
  console.time("🌼 MEM-UPDATE-METADATA");
  const metadata = await requestMetadata(tenant);

  db.delete("metadata");
  db.bulkPut("metadata", metadata);
  db.put("aresInternals", {
    id: "metadataFetch",
    lastUpdate: Date.now(),
    metadataLength: metadata.length,
  });

  console.timeEnd("🌼 MEM-UPDATE-METADATA");
  loadingData = false;
}

export async function updateVolatileRequestMetadata(tenant, id_token) {
  return new Promise((resolve, reject) => {
    loadingData = true;
    console.time("🦅 MEM-UPDATE-VOLATILE-METADATA");
    axios
      .get(`${apiEndp}/metadata/request/volatile/${tenant}`, {
        headers: { id_token },
      })
      .then(({ data }) => {
        if (data) {
          db.deleteByQuery("metadata", (i) => i.volatile);
          db.bulkPut("metadata", data);
          db.put("aresInternals", {
            id: "metadataVolatile",
            lastUpdate: Date.now(),
            length,
          });
          loadingData = false;
          resolve(data);
          console.timeEnd("🦅 MEM-UPDATE-VOLATILE-METADATA");
        } else {
          loadingData = true;
          reject(data.error);
          console.timeEnd("🦅 MEM-UPDATE-VOLATILE-METADATA");
        }
      })
      .catch((e) => {
        reject(e);
        console.timeEnd("🦅 MEM-UPDATE-VOLATILE-METADATA");
      });
  });
}

export async function saveDataItem(tenant, id_token, options = {}) {
  const { accessor, Id, prevId, isNew, data, skipdeps = false } = options;

  return new Promise((resolve, reject) => {
    saving = true;
    axios
      .put(
        `${apiEndp}/item/${accessor}/${tenant}`,
        {
          Id,
          prevId,
          isNew,
          data,
        },
        { headers: { id_token, skipdeps, caller_id: socket.id } }
      )
      .then(({ data: result }) => {
        saving = false;
        if (result.status === "OK") {
          resolve(Id);
          refreshData(result).catch((error) => reject(rejectSave(data, error, options)));
          debouncedReloadVolatile(tenant, id_token);
        } else reject(rejectSave(data, result.error, options));
      })
      .catch((error) => {
        reject(rejectSave(data, error.message, options));
      });
  });
}

export async function deleteDataItem(tenant, id_token, options = {}) {
  const { accessor, Id, reference } = options;

  return new Promise((resolve, reject) => {
    axios
      .delete(`${apiEndp}/item/${accessor}/${tenant}/${Id}`, {
        headers: { id_token, reference, caller_id: socket.id },
      })
      .then(({ data }) => {
        if (data.status === "OK") {
          resolve(Id);
          refreshData(data).catch((error) => reject(JSON.stringify({ ...options, error })));
          debouncedReloadVolatile(tenant, id_token);
        } else reject(JSON.stringify({ ...options, error: data.status }));
      })
      .catch((e) => {
        reject(JSON.stringify({ ...options, error: e.message }));
      });
  });
}

export async function saveModifiche(tenant, id_token, modifiche = {}) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${apiEndp}/modifiche/${tenant}`,
        { modifiche },
        { headers: { id_token, caller_id: socket.id } }
      )
      .then(({ data: result }) => {
        if (result.status === "OK") {
          resolve("OK", { modifiche });
          refreshData(result).catch((error) => reject(error));
        } else {
          reject(result.error);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export async function policiesSave(tenant, id_token, policies) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${apiEndp}/users/policies/${tenant}`,
        { policies },
        { headers: { id_token, caller_id: socket.id } }
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.error);
      });
  });
}

export async function helpSave(tenant, id_token, { code, dx, lang }) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${apiEndp}/help/${tenant}/${lang}/${code}`,
        { html: dx },
        { headers: { id_token, caller_id: socket.id } }
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.error);
      });
  });
}

export async function notesSave(tenant, id_token, { code, dx }) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${apiEndp}/notes/${tenant}/${code}`,
        { notes: dx },
        { headers: { id_token, caller_id: socket.id } }
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.error);
      });
  });
}

export async function userSave(tenant, id_token, body) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${apiEndp}/users/${tenant}`, body, { headers: { id_token, caller_id: socket.id } })
      .then(({ data }) => {
        if (data.status === "KO") {
          reject(data.error);
        } else {
          resolve(data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function userDelete(tenant, id_token, { user_id, nickname }) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${apiEndp}/users/${tenant}/${user_id}/${nickname}`, {
        headers: { id_token, caller_id: socket.id },
      })
      .then(({ data }) => {
        if ((data || {}).status === "KO") {
          reject(data.error);
        } else {
          resolve(data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function translateFingerprints(fingerprints) {
  const { data } = await axios.post(`${apiEndp}/fingerprints/kv/${TENANT}`, { fingerprints });
  if (data) {
    const fingerMap = new Map();
    fingerprints.forEach((id, index) => {
      const key = data[index];
      if (key) {
        const [key, value] = data[index].split("≠");
        fingerMap.set(id, { key, value });
      } else fingerMap.set(id, { key: id, value: "Not Translated" });
    });
    return fingerMap;
  }
  return new Map();
}

export function getParams() {
  const settings = db.get("ui", "server");
  return db.get("params", settings.paramsId);
}

export async function getUserFingerprintsFields(metadata) {
  const result = new Map();
  const params = getParams();

  const fields = [
    ...new Set(
      Object.values(params.users.clusters.types)
        .map((user) => {
          const fields = new Set();
          Object.values(user.events).forEach((event) => {
            Object.values(event.contentTypes).forEach((type) => {
              Object.values(type.fields.fingerprint).forEach((field) => fields.add(field));
            });
          });

          return [...fields];
        })
        .flat()
    ),
  ];

  for (const field of fields) {
    if (metadata[field]) {
      const translation = [];

      for (const value of (await translateFingerprints(metadata[field].split(" "))).values()) {
        translation.push(value);
      }

      result.set(field, translation);
    }
  }

  return result.size ? result : null;
}

export async function getUserHistoryContent({ blockId, blockIndex, placeholders, metadata }) {
  const block = db.get("blocks", blockId);
  const catalog = block.blocks[blockIndex].results.contentType;
  const responseFields = (
    await db.get("responseFields", block.blocks[blockIndex].results.responseFieldsId)
  ).fields;
  const params = getParams();
  const index = params.content.types[catalog].index;

  const fields = [
    ...new Set(
      Object.values(params.users.clusters.types)
        .map((user) => {
          const fields = new Set();
          Object.values(user.events).forEach((event) => {
            const campi = event.contentTypes[catalog].fields;
            fields.add(campi.ordered || campi.simple);
          });

          return [...fields];
        })
        .flat()
    ),
  ];

  const keys = Object.entries(placeholders).map(([key, value]) => [
    key.replace(/#|{|}/g, ""),
    value,
  ]);

  const resultsMap = [];

  for (const field of fields) {
    const meta = metadata[field];

    if (meta) {
      const [ids, tsMap] = (() => {
        const ids = [];
        const tsMap = new Map();

        try {
          let data;
          try {
            data = JSON.parse(`[${meta}]`);
          } catch (error) {
            data = meta.split(",");
          }

          data.sort(function compare(a, b) {
            if (!a.ts) return -1;

            var dateA = new Date(a.ts);
            var dateB = new Date(b.ts);
            return dateA - dateB;
          });

          data.forEach((d) => {
            if (d.id) {
              tsMap.set(d.id, d.ts);
              ids.push(d.id);
            } else ids.push(d);
          });
        } catch (error) {
          console.error("⚠️ User Data Parse Error", meta, field, error);
        }

        return [ids, tsMap];
      })();

      const results = await socketRequest("contentData", {
        _source: responseFields,
        index,
        ids,
      });

      const mapped = results.map((r) => {
        const c = {};
        keys.forEach(([key, value]) => {
          c[key] = r[value];

          if (key === "contentId") {
            c.ts = tsMap.get(r[value]);
          }
        });

        return c;
      });

      resultsMap.push([field, mapped]);
    }
  }

  return resultsMap;
}

export async function start({ tenant, username, id_token }) {
  if (startRequested) return;
  startRequested = true;

  TENANT = tenant;
  USER = username;
  TOKEN = id_token;

  const environnement = process.env["NODE_ENV"];
  figlet.parseFont("Standard", standard);
  figlet.text(
    "AR3S",
    {
      font: "Standard",
      horizontalLayout: "fitted",
    },
    function (err, data) {
      console.log(data);
      console.log(
        `Control Center v${pJson.version} || DB: ⚡️ MemoryDB 1.0 || ENV: ${environnement}`
      );
    }
  );

  socket.on("update-data", async (data) => {
    if (data.tenant !== tenant || data.callerId === socket.id) return;
    refreshData(data);
  });

  socket.on("update-request-volatile-metadata", async (data) => {
    if (data.tenant !== tenant) return;
    await debouncedReloadVolatile(data.tenant, id_token);
  });

  socket.on("update-users", async (data) => {
    await updateUsers(tenant, id_token);
  });

  const HEALTH_INTERVAL = 180000;
  healthCheck = setInterval(async () => await updateHealthData(tenant), HEALTH_INTERVAL);
  console.log(`💗 HEALTH CHECK EVERY ${HEALTH_INTERVAL}ms`);

  await Promise.all([
    updateData(tenant, id_token),
    updateMetadata(tenant),
    updateVolatileRequestMetadata(tenant, id_token),
    updateSettings(tenant),
    updateHealthData(tenant),
    updatePolicies(tenant, id_token),
    updateUsers(tenant, id_token),
    updateHelp(tenant),
    updateNotes(tenant),
  ]).then(() => {
    refManager.checkQueriesHealth();
  });

  started = true;

  return true;
}

export function stop() {
  startRequested = false;
  started = false;
  clearInterval(healthCheck);

  if (socket) socket.disconnect();
}

const worker = {
  start,
  stop,
  getUserHistoryContent,
  getUserFingerprintsFields,
  getParams,
  translateFingerprints,
  userDelete,
  userSave,
  notesSave,
  helpSave,
  policiesSave,
  saveModifiche,
  deleteDataItem,
  saveDataItem,
  updateVolatileRequestMetadata,
  updateMetadata,
  updateUsers,
  updateNotes,
  updateHelp,
  updateSettings,
  updatePolicies,
  updateHealthData,
  updateData,
  refreshData,
};

export default worker;
