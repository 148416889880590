import { refManager } from "../../db";
import validateMetadata from "../metadata/validation";
const clauses = ["filter", "exclude", "include", "boost", "order"];

export default async function validate(values) {
  const errors = {};
  const items = new Set();
  const obj = refManager.process("queries", values);
  const info = refManager.getItem("queries", obj);

  if (!values.contentType) errors.contentType = "You Must Specify a Catalog";

  if (values.presets) {
    for (const preset of Object.values(values.presets)) {
      if (preset.length > 0) {
        items.add("preset");
        break;
      }
    }
  }

  for (const accessor of clauses) {
    if (values[accessor]) {
      for (const { id } of values[accessor]) {
        items.add(items[id]);
      }
    }
  }
  if (![...items].length) errors.clauses = "You Must Fill at least one Query Clause";

  if (values.metadataOverrides) {
    let metadataErrors = false;
    Object.values(values.metadataOverrides).forEach((metadata) => {
      const validation = validateMetadata(metadata, info.restricted);
      if (validation.error) metadataErrors = true;
    });

    if (metadataErrors) errors.metadataErrors = true;
  }

  return errors;
}
