// export default function groupBy(xs, key) {
//   if (!xs) return;
//   return xs.reduce(function (rv, x) {

//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// }

export default function groupBy(xs, keys, excludes = [], prefixExcludes = []) {
  if (!xs) return;
  xs = Array.isArray(xs) ? xs : [xs];
  if (prefixExcludes.length > 0) {
    xs = xs.filter(({ id }) => !prefixExcludes.some((k) => id.includes(k)));
  }

  keys = Array.isArray(keys) ? keys : [keys];

  return xs.reduce(function (rv, x) {
    const key = keys
      .map((key) => {
        return excludes.includes(x[key]) ? null : x[key];
      })
      .filter((k) => k !== null)
      .join(": ");

    (rv[key] = rv[key] || []).push(x);
    return rv;
  }, {});
}
