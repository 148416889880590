import { useDataSettings } from "../../../../hooks/useData";
import RotaryKnob from "../../../rotaryKnob";

import styles from "./styles.module.scss";
function MltParams(props) {
  const { execute, handleChange, contentType } = props;
  const kDef = useDataSettings(
    (settings) => settings.contentSimilar?.[contentType]?.fingerprint || {}
  );

  return (
    <div className={styles.mltBox}>
      <RotaryKnob
        value={execute?.minTerms ? Number(execute?.minTerms) : kDef.minTerms?.value}
        min={kDef.minTerms?.min || 1}
        max={kDef.minTerms?.max || 200}
        step={1}
        label="Min Terms"
        skin="s12"
        onChange={(value) => handleChange("minTerms", value)}
      />

      <RotaryKnob
        value={execute?.maxTerms ? Number(execute?.maxTerms) : kDef.maxTerms?.value}
        min={kDef.maxTerms?.min || 1}
        max={kDef.maxTerms?.max || 200}
        step={1}
        label="Max Terms"
        skin="s12"
        onChange={(value) => handleChange("maxTerms", value)}
      />

      <RotaryKnob
        value={execute?.minDocs ? Number(execute?.minDocs) : kDef.minDocs?.value}
        min={kDef.minDocs?.min || 3}
        max={kDef.minDocs?.max || 500}
        step={1}
        label="Min Docs"
        onChange={(value) => handleChange("minDocs", value)}
      />

      <RotaryKnob
        value={execute?.maxDocs ? Number(execute?.maxDocs) : kDef.maxDocs?.value}
        min={kDef.maxDocs?.min || 100}
        max={kDef.maxDocs?.max || 1000000}
        skin="s13"
        clickStep={100}
        step={1}
        label={`Max Docs ${execute?.maxDocs || ""}`}
        onChange={(value) => handleChange("maxDocs", value)}
      />
    </div>
  );
}

export default MltParams;
