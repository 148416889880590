import { useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import RangePane from "../rangePane";

export default function Range(props) {
  const { setFieldValue, values, errors } = useFormikContext();

  const onChangeStartDate = useCallback(
    (v) => setFieldValue("range.startDate", v),
    [setFieldValue]
  );
  const onChangePeriod = useCallback((v) => setFieldValue("range.period", v), [setFieldValue]);
  const onChangeUnit = useCallback((v) => setFieldValue("range.unit", v), [setFieldValue]);

  const extra = useMemo(() => {
    if (!values.userBased) {
      return (
        <Form.Check
          type="switch"
          id="range-trending"
          checked={values.range?.comparePrevious || false}
          value="true"
          onChange={(e) => setFieldValue("range.comparePrevious", e.target.checked)}
          name="range-trending"
          label="Trending"
        />
      );
    } else {
      return null;
    }
  }, [setFieldValue, values.range?.comparePrevious, values.userBased]);

  return (
    <RangePane
      startDate={values.range?.startDate}
      onChangeStartDate={onChangeStartDate}
      period={values.range?.period}
      onChangePeriod={onChangePeriod}
      unit={values.range?.unit}
      onChangeUnit={onChangeUnit}
      extra={extra}
      errors={errors}
      color="#150025"
      coloredBars={false}
    />
  );
}
