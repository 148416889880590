import io from "socket.io-client";
import axios from "axios";

export let socket;
export let socketId;

export const initSocket = (tenant, onConnect) => {
  if (socket && socket.connected) return socket;

  socket = io(`${process.env.REACT_APP_API_ENDPOINT}/${tenant}`, { rememberUpgrade: true });

  socket.on("connect_error", (error) => {
    console.log(`☢️ SOCKET ERROR ${error}`);
    setTimeout(() => {
      socket.connect();
    }, 1000);
  });

  socket.io.on("reconnect_attempt", (att) => {
    console.log(`⚠️ SOCKET RECONNECT ATTEMPT ${att}`);
  });

  socket.io.on("reconnect", () => {
    console.log("⚠️ SOCKET RECONNECT");
  });

  socket.on("connect", () => {
    console.log("🚀 SOCKET CONNECTED #Id:", socket.id);
    socketId = socket.id;
    if (onConnect) onConnect(socket);
  });

  socket.on("disconnect", (reason) => {
    console.log(`🔥 SOCKET DISCONNECTED #Id: ${socketId} ${reason}`);
  });

  socket.onAny((event, ...args) => {
    console.log(`🦄 SOCKET GOT: ${event}`);
  });

  return socket;
};

export const explorer = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
