import { useCallback } from "react";
import useConfirmNavigation from "../../hooks/useConfirmNavigation";

export default function Link({ to, children, className }) {
  const navigate = useConfirmNavigation();
  const go = useCallback(() => navigate(to), [navigate, to]);

  return (
    <span className={className} onClick={go}>
      {children}
    </span>
  );
}
