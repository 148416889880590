import { useCallback, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { Form, Button } from "react-bootstrap";
import { useGetMemTable } from "../../hooks/useMemoryDB";
import Switch from "react-bootstrap-switch";
import { useDataSaveItem } from "../../hooks/useData";
import { useItemContext } from "../../hooks/useItem";
import Editor from "@monaco-editor/react";
import Container from "../container";
import set from "lodash/set";

import styles from "./styles.module.scss";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css";
import "./global.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faCircleCheck,
  faCircleXmark,
  faDatabase,
  faLock,
  faLockOpen,
  faTools,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const colors = [
  "#012a4a",
  "#013a63",
  "#01497c",
  "#014f86",
  "#936639",
  "#a68a64",
  "#b6ad90",
  "#c2c5aa",
  "#a4ac86",
  "#656d4a",
];

export default function Conversion(props) {
  const { values, errors, setFieldValue } = useFormikContext();
  const { isNew, setItemId } = useItemContext();
  const [params, setParams] = useState([...useGetMemTable("params").values()]);
  const [edit, forceSetEdit] = useState(false);
  const saveItem = useDataSaveItem({ confirm: true, notify: true });

  const setEdit = useCallback(() => {
    forceSetEdit((state) => !state);
  }, []);

  const handleIdChange = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue("id", value);
      setItemId(value);
    },
    [setFieldValue, setItemId]
  );

  const handleScopeChange = useCallback(
    (state) => {
      setFieldValue("scope", state ? "block" : "item");
    },
    [setFieldValue]
  );

  const handleChangeParams = useCallback(
    (value, contentType, field, userType, paramsId) => {
      value = (value || "").trim();

      if (value) {
        const paramIndex = params.findIndex((p) => p.id === paramsId);
        set(
          params[paramIndex],
          `users.clusters.types[${userType}].events[${values.label}].contentTypes[${contentType}].fields[${field}]`,
          value
        );

        setParams([...params]);
      }
    },
    [params, values.label]
  );

  const handleSaveParam = useCallback(
    (paramsId) => {
      const data = params.find((p) => p.id === paramsId);

      if (data) {
        saveItem({
          accessor: "params",
          Id: paramsId,
          prevId: paramsId,
          isNew: false,
          data,
          skipdeps: true,
        });
      }
    },
    [params, saveItem]
  );

  return (
    <>
      <Container
        title="Settings"
        foldable={false}
        color="#14213d"
        extra={
          <div className={styles.extraBox}>
            <Form.Check
              key="conversion-historicized"
              id="conversion-historicized"
              type="switch"
              label="Historicized"
              onChange={(e) => setFieldValue("historicized", e.target.checked)}
              checked={values.historicized || false}
              //disabled={!isNew}
            />
          </div>
        }
      >
        <Form.Label>Conversion Id</Form.Label>
        {isNew || edit ? (
          <Form.Control
            type="text"
            placeholder="Enter Conversion Id"
            isInvalid={errors.id}
            onChange={handleIdChange}
            value={values.id ?? ""}
            className={isNew ? styles.label : styles.labelDimmed}
            disabled={!isNew}
          />
        ) : (
          <div className={styles.conversionLabel} onDoubleClick={() => setEdit(true)}>
            {values.id}
          </div>
        )}
        <div className={styles.scope}>
          <Form.Label>Scope</Form.Label>
          <div
            onDoubleClick={() => setEdit()}
            className={`${styles.scopeLabel} ${
              values.scope === "block" ? styles.scopeLabelBlock : styles.scopeLabelItem
            }`}
          >
            {isNew || edit ? (
              <div className={styles.controls}>
                <Switch
                  bsSize="small"
                  value={values.scope === "block" ? true : false}
                  onText={<span className={styles.switchTitle}>Block</span>}
                  onColor="warning"
                  offText={<span className={styles.switchTitle}>Item</span>}
                  offColor="danger"
                  onChange={(elm, state) => handleScopeChange(state)}
                  //disabled={!isNew}
                />
                <Form.Check
                  key="conversion-include-params"
                  id="conversion-include-params"
                  type="switch"
                  label="Include Params"
                  onChange={(e) => setFieldValue("include.params", e.target.checked)}
                  checked={values.include?.params ?? false}
                  //disabled={!isNew}
                />
                <Form.Check
                  key="conversion-include-context"
                  id="conversion-include-context"
                  type="switch"
                  label="Include Context"
                  onChange={(e) => setFieldValue("include.context", e.target.checked)}
                  checked={values.include?.context ?? false}
                  //disabled={!isNew}
                />
                {values.scope === "block" && (
                  <Form.Check
                    key="conversion-include-ids"
                    id="conversion-include-ids"
                    type="switch"
                    label="Include Content Ids"
                    onChange={(e) => setFieldValue("include.contentIds", e.target.checked)}
                    checked={values.include?.contentIds ?? false}
                    //disabled={!isNew}
                  />
                )}
                <Form.Check
                  key="conversion-timeless"
                  id="conversion-timeless"
                  type="switch"
                  label="Timeless"
                  onChange={(e) => setFieldValue("timeless", e.target.checked)}
                  checked={values.timeless || false}
                  //disabled={!isNew}
                />
              </div>
            ) : values.scope === "block" ? (
              <>
                <span>Block</span> <div className={styles.divider} />
                <span>
                  <span className={styles.includes}>
                    Include Content Ids:{" "}
                    {values.include?.contentIds ? (
                      <FontAwesomeIcon icon={faCircleCheck} className={styles.OK} />
                    ) : (
                      <FontAwesomeIcon icon={faCircleXmark} className={styles.KO} />
                    )}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span>Item</span>
                <div className={styles.divider} />
              </>
            )}

            {!edit && !isNew && (
              <>
                <span className={styles.includes}>
                  Include Params:{" "}
                  {values.include?.params ? (
                    <FontAwesomeIcon icon={faCircleCheck} className={styles.OK} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleXmark} className={styles.KO} />
                  )}
                </span>
                <span className={styles.includes}>
                  Include Context:{" "}
                  {values.include?.context ? (
                    <FontAwesomeIcon icon={faCircleCheck} className={styles.OK} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleXmark} className={styles.KO} />
                  )}
                </span>
                <span className={styles.includes}>
                  Timeless:{" "}
                  {values.timeless ? (
                    <FontAwesomeIcon icon={faCircleCheck} className={styles.OK} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleXmark} className={styles.KO} />
                  )}
                </span>
              </>
            )}
          </div>
        </div>
        {(values.include?.context || values.include?.params) && (edit || isNew) && (
          <div className={styles.whiteContainer}>
            <Container title="Params & Context: White List" folded={false} coloredBars={false}>
              <Editor
                width="100%"
                height="24vh"
                defaultLanguage="json"
                value={JSON.stringify(values.whiteList ?? { context: {}, params: {} })}
                defaultValue="// Insert your code"
                theme="vs-dark"
                onChange={(txt) => setFieldValue("whiteList", JSON.parse(txt))}
                options={{ minimap: { enabled: false }, folding: true }}
              />
            </Container>
          </div>
        )}

        {values.historicized && (
          <Container
            title={
              <>
                <FontAwesomeIcon icon={faDatabase} className={styles.titleIcon} /> User Conversion
                History Permanent Storage Params
              </>
            }
            foldable={edit}
            folded={!edit}
            color="#023047"
            className={styles.paramsBox}
            extra={
              <FontAwesomeIcon
                icon={edit ? faLockOpen : faLock}
                onDoubleClick={() => setEdit()}
                className={styles.lockIcon}
              />
            }
          >
            {params.map((param, index) => (
              <Params
                color={colors[index]}
                key={param.id}
                data={param}
                label={values.label}
                onChange={handleChangeParams}
                onSaveParam={() => handleSaveParam(param.id)}
              />
            ))}
          </Container>
        )}
      </Container>
    </>
  );
}

function Params(props) {
  const { data, label, onChange, onSaveParam, color } = props;

  const contentTypes = useMemo(() => Object.keys(data.content.types || {}), [data.content]);

  const handleChange = useCallback(
    (value, contentType, field, userType) => {
      onChange(value, contentType, field, userType, data.id);
    },
    [data.id, onChange]
  );

  return (
    <Container
      color={color}
      title={
        <>
          <FontAwesomeIcon icon={faTools} className={styles.titleIcon} /> {data.id}
        </>
      }
      folded={true}
      footer={
        <Button size="sm" variant="outline-success" onClick={onSaveParam}>
          Save Parameters
        </Button>
      }
    >
      {Object.entries(data.users?.clusters?.types || {}).map(([id, type], typeIndex) => (
        <UserType
          key={type.name}
          userTypeId={id}
          data={type}
          label={label}
          index={typeIndex}
          contentTypes={contentTypes}
          onChange={handleChange}
        />
      ))}
    </Container>
  );
}

function UserType(props) {
  const { data, userTypeId, label, contentTypes, onChange } = props;

  const handleChange = useCallback(
    (value, contentType, field) => {
      onChange(value, contentType, field, userTypeId);
    },
    [onChange, userTypeId]
  );

  return (
    <Container
      title={
        <>
          <FontAwesomeIcon icon={faUsers} className={styles.titleIcon} /> {data.name}
        </>
      }
      coloredBars={false}
      folded={true}
      color="#001233"
    >
      {contentTypes.map((contentType, index) => (
        <ContentType
          key={`${contentType}-${index}`}
          type={contentType}
          data={data.events?.[label]?.contentTypes?.[contentType]?.fields || {}}
          onChange={handleChange}
        />
      ))}
    </Container>
  );
}

function ContentType(props) {
  const { type, data, onChange } = props;
  const [raw, setRaw] = useState(data.raw || "");
  const [simple, setSimple] = useState(data.simple || "");
  const [ordered, setOrdered] = useState(data.ordered || "");

  const handleChange = useCallback(
    (field, value) => {
      onChange(value, type, field);
    },
    [onChange, type]
  );

  return (
    <Container
      title={
        <>
          <FontAwesomeIcon icon={faArchive} className={styles.titleIcon} /> {type}
        </>
      }
      folded={true}
      coloredBars={false}
    >
      <Form.Label className={styles.fieldLabel}>Raw</Form.Label>
      <Form.Control
        type="text"
        value={raw}
        isInvalid={raw === ""}
        onChange={(e) => {
          const value = e.target.value;
          setRaw(value);
          handleChange("raw", value);
        }}
      />
      <Form.Label className={styles.fieldLabel}>Simple</Form.Label>
      <Form.Control
        type="text"
        value={simple}
        isInvalid={simple === ""}
        onChange={(e) => {
          const value = e.target.value;
          setSimple(value);
          handleChange("simple", value);
        }}
      />
      <Form.Label className={styles.fieldLabel}>Ordered</Form.Label>
      <Form.Control
        type="text"
        value={ordered}
        isInvalid={ordered === ""}
        onChange={(e) => {
          const value = e.target.value;
          setOrdered(value);
          handleChange("ordered", value);
        }}
      />
    </Container>
  );
}
