import { useCallback } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import timestring from "timestring";
import set from "lodash.set";
import Container from "../container/onOff";

import styles from "./styles.module.scss";

export default function Cache() {
  const { values, errors, setFieldValue, setValues } = useFormikContext();
  const active = values.cache?.active;
  const unit = values.cache?.unit;
  const period = values.cache?.period;

  const onToggle = useCallback((e) => setFieldValue("cache.active", e), [setFieldValue]);
  const setPeriod = useCallback(
    (period) => {
      const ttl = unit ? timestring(`${unit}${period}`) : 0;
      const valori = { ...values };

      set(valori, "cache.period", period);
      set(valori, "cache.ttl", ttl);

      setValues(valori);
    },
    [setValues, unit, values]
  );
  const setUnit = useCallback(
    (unit) => {
      const ttl = period ? timestring(`${unit}${period}`) : 0;
      const valori = { ...values };

      set(valori, "cache.unit", unit);
      set(valori, "cache.ttl", ttl);

      setValues(valori);
    },
    [period, setValues, values]
  );

  return (
    <Container
      title="Cache"
      help="element-cache"
      foldable={false}
      folded={true}
      forceNotFoldable={true}
      warningMessage={errors.cache}
      active={values.cache?.active}
      onToggle={onToggle}
      header={
        !active ? null : (
          <div className={styles.cacheHeader}>
            <Form.Control
              as="select"
              className={styles.unitSelect}
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              isInvalid={!unit}
            >
              <option></option>
              {[...Array(100).keys()].map((i, index) => (
                <option key={index} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              as="select"
              className={styles.periodSelect}
              onChange={(e) => setPeriod(e.target.value)}
              value={period}
              isInvalid={!period}
            >
              <option></option>
              <option value="m">Minutes</option>
              <option value="h">Hours</option>
              <option value="d">Days</option>
              <option value="w">Weeks</option>
              <option value="mon">Months</option>
              <option value="y">Years</option>
            </Form.Control>
          </div>
        )
      }
    ></Container>
  );
}
