import { Fragment, useCallback, useRef, useMemo, useEffect } from "react";
import { useDataSettings } from "../../hooks/useData";
import { useDataRefObj } from "../../hooks/useDataRef";
import { DragAndDrop } from "../../hooks/useDragAndDrop";
import { useFormikContext } from "formik";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faBrain, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import Switch from "react-bootstrap-switch";

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import get from "lodash.get";
import idGen from "../../utils/idGen";

import SubBlock from "./subBlock";
import SelectAll from "../selects/selectAll";
import Results from "./results";
import SelectProperties from "../selects/selectProperties";
import Container from "../container";
import UserPref from "../userPref";

import styles from "./styles.module.scss";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css";

const deleteBlockEvnt = new Event("deleted-block");
const emptyArray = [];
const userStyle = { paddingTop: "10px" };
const styleIconMarginRight = { marginRight: "4px" };
const brainStyle = { color: "green", marginLeft: "6px" };
const shieldStyle = { color: "deeppink", marginLeft: "6px" };
const emptyBlock = () => ({
  _id: idGen(),
  condition: "*",
  title: "",
  type: "query",
  pinned: true,
});

const marginTop10 = { marginTop: "10px" };

export default function Block(props) {
  const { values, errors, handleChange, handleBlur, setFieldValue } = useFormikContext();
  const boundBlocksOnProperty = useDataSettings("boundBlocksOnProperty");
  const scheduleRef = useRef(new Map(values.schedulingMap));
  const typeRef = useRef(new Map());
  const info = useDataRefObj("blocks", values);
  const metadataInPage = useMemo(() => info.metadata, [info.metadata]);

  const computeScheduling = useCallback(
    (scheduled, scheduling, index) => {
      if (scheduled) scheduleRef.current.set(index, scheduling);
      else scheduleRef.current.delete(index);

      const sumSchedule = [];
      [...scheduleRef.current.values()].forEach((arr) => {
        if (arr) arr.forEach((sked) => sumSchedule.push(sked));
      });

      // console.log("-->", "scheduled", scheduled, "scheduling", scheduling, "index", index);
      // console.log(
      //   "<--",
      //   "scheduled",
      //   scheduleRef.current.size > 0,
      //   "scheduling",
      //   sumSchedule,
      //   "schedulingMap",
      //   Array.from(scheduleRef.current.entries())
      // );

      setFieldValue("scheduled", scheduleRef.current.size > 0);
      setFieldValue("scheduling", sumSchedule);
      setFieldValue("schedulingMap", Array.from(scheduleRef.current.entries()));
    },
    [setFieldValue]
  );

  const dispatch = useCallback(
    (action) => {
      let newState = cloneDeep(values.blocks || []);
      const { type, payload: { blockIndex = 0, data = {} } = {} } = action;

      switch (type) {
        case "UPDATE": {
          newState[blockIndex] = {
            ...newState[blockIndex],
            ...data,
          };
          break;
        }
        case "UPDATE-INDEX": {
          const { scheduled, scheduling } = data;
          newState[blockIndex] = data;
          computeScheduling(scheduled, scheduling, blockIndex);
          break;
        }
        case "UPDATE-DEEP": {
          const { path, value } = action.payload;
          set(newState[blockIndex], path, value);
          break;
        }
        case "UPDATE-MULTIPLE": {
          const { path, value } = action.payload;
          path.forEach((p) => set(newState[blockIndex], p, value));
          break;
        }
        case "UPDATE-ALL": {
          newState = data;
          break;
        }
        case "ADD-EMPTY": {
          newState.splice(blockIndex + 1, 0, emptyBlock());
          break;
        }
        case "DELETE": {
          newState.splice(blockIndex, 1);
          window.dispatchEvent(deleteBlockEvnt);
          break;
        }
        default: {
          return newState;
        }
      }

      setFieldValue("blocks", newState);

      return newState;
    },
    [computeScheduling, setFieldValue, values.blocks]
  );

  const blocks = useMemo(() => values.blocks, [values.blocks]);

  const blocksContentTypesRef = useRef();
  const blocksContentTypes = useMemo(() => {
    const types = new Set();
    if (blocks) {
      blocks.forEach((block) => {
        const type = get(block, "results.contentType");
        if (type) types.add(type);
      });
    }

    const newTypes = [...types];

    if (blocksContentTypesRef.current && blocksContentTypesRef.current.length > 0) {
      const dif = newTypes.filter((k) => blocksContentTypesRef.current.includes(k));
      if (dif.length === 0) return blocksContentTypesRef.current;
    }

    blocksContentTypesRef.current = newTypes;

    return newTypes;
  }, [blocks]);

  const onTypeChange = useCallback((value, index) => typeRef.current.set(index, value), []);
  const canBeFallback = useMemo(() => {
    if (info.isFallback || info.isSmart) return true;
    return info.canBeFallback;
  }, [info?.canBeFallback, info?.isFallback, info?.isSmart]);

  // *******************************
  // Statics Handlers
  // *******************************
  const fallbackExcludes = useMemo(() => [values.id], [values.id]);
  const fallbackHandleChange = useCallback(
    (blockId) => setFieldValue("results.fallbackBlockId", blockId),
    [setFieldValue]
  );

  const blockOnTypeChange = useCallback(
    (value, blockIndex) => onTypeChange(value, blockIndex),
    [onTypeChange]
  );

  const handleSmartOrder = useCallback(
    (e) => {
      const newValues = cloneDeep(values);
      setFieldValue("results.smartOrderBlocks", e.target.checked);
      newValues.blocks.forEach((block) => {
        block.pinned = true;
      });
      dispatch({ type: "UPDATE-ALL", payload: { data: newValues.blocks } });
    },
    [dispatch, setFieldValue, values]
  );

  const title = useMemo(() => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Switch
          bsSize="small"
          value={(values.results || {}).flatAllBlocks || false}
          onText={<span className={styles.switchTitle}>Single Flatten Block</span>}
          onColor="single"
          offText={<span className={styles.switchTitle}>Multi Block</span>}
          offColor="multiple"
          onChange={(elm, state) => {
            setFieldValue("results.flatAllBlocks", state);
          }}
        />
      </div>
    );
  }, [setFieldValue, values.results]);

  const extra = useMemo(() => {
    return [undefined, false].includes(get(values, "results.flatAllBlocks")) &&
      values.blocks?.length > 2 ? (
      <div className="smart-order-selector-box">
        <Form.Check
          type="switch"
          checked={values.results?.smartOrderBlocks || false}
          value="true"
          onChange={handleSmartOrder}
          onBlur={handleBlur}
          name={`results.smartOrderBlocks`}
          id={`results.smartOrderBlocks`}
          label="Smart Order Blocks"
          disabled={values.results?.shuffleBlocks || false}
        />
        {get(values, "results.smartOrderBlocks") === true && (
          <>
            <UserPref
              style={userStyle}
              values={values.results}
              errors={errors}
              catalog={values.results?.contentType}
              showCatalog={true}
              minified={true}
              onChange={(key, value) => setFieldValue(`results.${key}`, value)}
            />
          </>
        )}
      </div>
    ) : null;
  }, [errors, handleBlur, handleSmartOrder, setFieldValue, values]);

  const boxColor = useMemo(() => {
    return (values.results || {}).flatAllBlocks ? "#111" : "#280138";
  }, [values.results]);

  const enabledPagesSet = useMemo(
    () => new Set(values.enabledPagesId || emptyArray),
    [values.enabledPagesId]
  );
  const handlePagesSelect = useCallback(
    (fSel) => setFieldValue("enabledPagesId", [...fSel]),
    [setFieldValue]
  );

  const blockSelectFilters = useMemo(() => {
    const loopIds = info.loopIds ?? [];

    return {
      catalogs: blocksContentTypes,
      exclude: [...new Set([...fallbackExcludes, ...loopIds])],
      isFallback: true,
    };
  }, [blocksContentTypes, fallbackExcludes, info.loopIds]);

  const header = useMemo(() => {
    if (!boundBlocksOnProperty) return null;

    return (
      <div className="property-box">
        <div className="property-label">Properties</div>
        <div className="property-select">
          <SelectProperties
            exclude={emptyArray}
            propertyId={null}
            onlyFolders={true}
            onlyEndpoints={false}
            selected={enabledPagesSet}
            multiple={true}
            onChange={handlePagesSelect}
            placeholder="Select Properties"
          />
        </div>
      </div>
    );
  }, [boundBlocksOnProperty, enabledPagesSet, handlePagesSelect]);

  const footer = useMemo(() => {
    return (
      <Button
        variant="outline-primary"
        style={{ float: "right" }}
        onClick={() =>
          dispatch({
            type: "ADD-EMPTY",
            payload: { blockIndex: blocks ? blocks.length : 0 },
          })
        }
      >
        <FontAwesomeIcon icon={faPlusSquare} style={styleIconMarginRight} /> Add Block
      </Button>
    );
  }, [blocks, dispatch]);

  const handleChangeCanBeFallback = useCallback(
    (e) => {
      setFieldValue("canBeFallback", e.target.checked ? true : false);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (!values.blocks) {
      setFieldValue("results", { flatAllBlocks: true, min: 0 });
      setFieldValue("blocks", [emptyBlock()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.blocks) {
      if (!blocks) {
        console.log("😱 BIZZARRO DISPATCH");
        dispatch({ type: "UPDATE-ALL", payload: { data: values.blocks } });
      }
    }
  }, [blocks, dispatch, values.blocks]);

  return (
    <>
      <Container
        title={title}
        help="blocks-container"
        color={boxColor}
        coloredBars={false}
        header={header}
        headerOverflow={"visible"}
        headerOnlyOnFolded={false}
        extra={extra}
        footer={footer}
        foldable={true}
        warningMessage={errors.general}
      >
        <DragAndDrop>
          {blocks?.map((block, blockIndex) => {
            return (
              <SubBlock
                key={`block-${blockIndex}`}
                block={block}
                blockIndex={blockIndex}
                blocks={blocks}
                masterId={values.id}
                isFallback={values.canBeFallback}
                info={info}
                smartSortable={get(values, "results.smartOrderBlocks")}
                shuffleable={get(values, "results.shuffleBlocks")}
                flatten={values?.results?.flatAllBlocks}
                dispatch={dispatch}
                onTypeChange={blockOnTypeChange}
              />
            );
          })}
        </DragAndDrop>
      </Container>
      <div className="extra-container">
        <Results info={info} />
        <Container
          title="Extended"
          help="block-global-exteded"
          foldable={false}
          folded={false}
          className="extra-container-window"
        >
          {canBeFallback ? (
            <Fragment>
              <Form.Check
                disabled={info.isUsedAsFallback}
                type="switch"
                checked={Boolean(values.canBeFallback || false)}
                value={true}
                onChange={handleChangeCanBeFallback}
                onBlur={handleBlur}
                name={`canBeFallback`}
                id={`canBeFallback`}
                label={
                  <span>
                    {info.isUsedAsFallback ? "Must be" : "Is"} a <b>Fallback</b>
                    <FontAwesomeIcon icon={faShieldAlt} style={shieldStyle} />
                  </span>
                }
              />
              <Form.Check
                disabled={info.isUsedInSmartBlockContainer}
                type="switch"
                checked={values.canBeSmart || false}
                value="true"
                onChange={handleChange}
                onBlur={handleBlur}
                name={`canBeSmart`}
                id={`canBeSmart`}
                label={
                  <span>
                    {info.isUsedInSmartBlockContainer ? "Must be" : "Is"} a <b>Smart Block</b>
                    <FontAwesomeIcon icon={faBrain} style={brainStyle} />
                  </span>
                }
              />
            </Fragment>
          ) : (
            <small className="form-text text-muted">
              You cannot use this as Fallback or Smart Block because is using (directly or via
              Queries or Injects) <b>metadata not allowed</b> or it contains <b>Smart</b> Blocks.{" "}
              {metadataInPage?.length > 0 ? (
                <div className="metadata-in-page">
                  {metadataInPage.map((meta) => (
                    <span key={`meta-in-${meta}`} className="metadata-in-page">
                      {meta}
                    </span>
                  ))}
                </div>
              ) : null}
            </small>
          )}
        </Container>
        <Container
          title="Fallback"
          help="block-global-fallback"
          foldable={false}
          folded={false}
          className="extra-container-window"
          noMarginLast={true}
        >
          <SelectAll
            accessor="blocks"
            filters={blockSelectFilters}
            isInvalid={errors.fallback}
            value={(values.results || {}).fallbackBlockId}
            handleChange={fallbackHandleChange}
          />

          {(values.results || {}).fallbackBlockId ? (
            <div style={marginTop10}>
              <Form.Check
                type="switch"
                checked={get(values, "results.fullSubstituteFallback") || false}
                value="true"
                onChange={handleChange}
                id={`results.fullSubstituteFallback`}
                label="Full Substitute Fallback"
              />
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
}
