import { useEffect, useMemo, useCallback, useRef } from "react";
import { Form } from "react-bootstrap";
import { useGetMemItem, useSelectMem } from "../../../../hooks/useMemoryDB";
import { useDataSettings } from "../../../../hooks/useData";
import { useFormikContext } from "formik";
import { getCatalogs, getRealms } from "../../../../utils/getMetadata";
import usePermissions from "../../../../hooks/usePermissions";
import Link from "../../../link";
import isNil from "lodash.isnil";
import pick from "lodash.pick";

import RotaryKnob from "../../../rotaryKnob";
import OuterField from "./outerField";
import Container from "../../../container";
import SplitterAndPerc from "./splitterAndPerc";
import ErrorMessage from "../../../errorMessage";
import GreaterThan from "./greaterThan";
import KeepOrder from "./keepOrder";
import MltParams from "./mltParams";
import LessThan from "./lessThan";
import Splitter from "./splitter";
import Values from "../../../multipleValues";
import Random from "./random";
import Phrase from "./phrase";
import Search from "./search";
import Range from "./range";
import Multi from "./multi";
import Sort from "./sort";

import "../style.scss";
import styles from "./styles.module.scss";

import { formatStatement } from "../";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const keepValues = [
  "API",
  "condition",
  "conditionRaw",
  "boost",
  "type",
  "value",
  "valueType",
  "queryType",
  "field",
  "fieldNested",
  "fieldNestedRoot",
  "fieldDBType",
  "break",
  "_id",
  "notOverwritable",
  "scheduled",
  "scheduling",
];

const needExtra = [
  "sortOrder",
  "similarTo",
  "matchMulti",
  "match",
  "lessValue",
  "greaterValue",
  "range",
  "distance",
  "likeContentId",
  "like",
  "matchAll",
  "random",
  "listId",
  "ids",
];

function getType(executionTypes) {
  return (value) =>
    executionTypes && value
      ? executionTypes.find((t) =>
          t.discoverField ? t.discoverField === value : t.valueField === value
        )
      : {};
}

function Execute(props) {
  const { rootNode, ruleIndex, info } = props;
  const { values, errors, setFieldValue } = useFormikContext();
  const { expert } = usePermissions();
  const getField = useGetMemItem("metadata", null, true);
  const fieldId = useMemo(
    () => rootNode.field || values.field,
    [rootNode.field, values.field]
  );
  const field = useSelectMem(
    "metadata",
    Array.isArray(fieldId)
      ? undefined
      : (i) => i.id === fieldId && i.catalog === values.contentType
  );

  const isMulti = useMemo(() => Array.isArray(fieldId), [fieldId]);
  const executionTypes = useDataSettings((settings) => settings.executionTypes);
  const getCurrentType = getType(executionTypes);
  const namePath = useMemo(() => `execute.rule[${ruleIndex}]`, [ruleIndex]);
  const { ruleTypes, boostMaxValue } = useDataSettings(
    "ruleTypes",
    "boostMaxValue"
  );
  const typeRef = useRef();
  const haveValue = useMemo(() => {
    const type = getCurrentType(rootNode.queryType);
    return !isNil(type.valueField);
  }, [getCurrentType, rootNode.queryType]);

  const filterExpertOperators = useCallback(
    (ops) => {
      if (!expert.includes("rule-execute-similar")) {
        return ops.filter((f) => !f.label.includes("Similar"));
      }

      return ops;
    },
    [expert]
  );

  const metadataTypes = useMemo(() => {
    const type = getCurrentType(rootNode.queryType);
    return type.metadataTypes || [];
  }, [getCurrentType, rootNode.queryType]);

  const catalogs = useMemo(
    () => getCatalogs(values.metadata, values.contentType),
    [values.metadata, values.contentType]
  );

  const realms = useMemo(() => getRealms(values.metadata), [values.metadata]);

  const executionTypesFiltered = useMemo(() => {
    if (isMulti) {
      return filterExpertOperators(
        executionTypes.filter((e) => {
          return e.multiField === true;
        })
      );
    }
    if (field?.types) {
      const tipi = Array.isArray(field.types)
        ? field.types
        : field.types.split(",");

      let filtered = executionTypes.filter((e) => {
        const expected = e.expectedTypes;

        if (expected.length === 0) return true;
        if (e.multiField === true) return false;

        return expected.some((k) => tipi.includes(k));
      });

      return filterExpertOperators(filtered);
    }

    return filterExpertOperators(executionTypes);
  }, [executionTypes, field, filterExpertOperators, isMulti]);

  const preferredTypes = useMemo(() => {
    const type = getCurrentType(rootNode.queryType);
    return type.valueTypes || [];
  }, [getCurrentType, rootNode.queryType]);

  const dispatchExecute = useCallback(
    (action) => {
      const newState = { ...rootNode, ...action.payload, API: "V2" };
      const otherFields = getCurrentType(newState.queryType).otherFields || [];

      const newValues = pick(newState, [
        ...Object.keys(otherFields),
        ...keepValues,
      ]);

      setFieldValue(`rule[${ruleIndex}]`, newValues);
    },
    [getCurrentType, rootNode, ruleIndex, setFieldValue]
  );

  const valuesFields = useMemo(() => {
    return Array.isArray(fieldId)
      ? fieldId.map((v) => (v.value ? v.value : v))
      : fieldId;
  }, [fieldId]);

  const onQueryTypeChange = useCallback(
    (e) => {
      const queryType = e.target.value;
      const type = getCurrentType(queryType);

      if (!type) return;

      dispatchExecute({
        type: "UPDATE",
        payload: {
          queryType,
          value: "",
        },
        caller: "onQueryTypeChange",
      });
    },
    [dispatchExecute, getCurrentType]
  );

  const onValueTypeChange = useCallback(
    (valueType) => {
      if (!valueType || typeRef.current === valueType) return;
      typeRef.current = valueType;

      dispatchExecute({
        type: "UPDATE",
        payload: { valueType, value: "" },
        caller: "onValueTypeChange",
      });
    },
    [dispatchExecute]
  );

  const updateOtherFields = useCallback(
    (field, e) => {
      dispatchExecute({
        type: "UPDATE-OTHER",
        payload: {
          [field]: e.target
            ? e.target.type === "checkbox"
              ? e.target.checked
              : e.target.value
            : e,
        },
      });
    },
    [dispatchExecute]
  );

  const updateValue = useCallback(
    (value = "") => {
      dispatchExecute({
        type: "UPDATE",
        payload: { value },
        caller: "updateValue",
      });
    },
    [dispatchExecute]
  );

  const prevMulti = useRef();
  useEffect(() => {
    if (prevMulti.current && prevMulti.current !== isMulti) {
      typeRef.current = null;

      dispatchExecute({
        type: "UPDATE",
        payload: {
          type: "",
          queryType: "",
          valueType: "",
          fieldNested: "",
          metaField: "",
          valueField: "",
          otherFields: {},
          value: "",
        },
        caller: "useEffect - isMulti",
      });
    }

    prevMulti.current = isMulti;
  }, [dispatchExecute, isMulti, updateValue]);

  const onOuterFieldChange = useCallback(
    (fieldId) => {
      if (!Array.isArray(fieldId)) {
        getField(fieldId, (field) => {
          typeRef.current = null;

          dispatchExecute({
            type: "UPDATE",
            payload: {
              value: "",
              type: "",
              valueType: "",
              queryType: "",
              field: fieldId,
              fieldNested: field.dbNestedField,
              fieldNestedRoot: field.dbNestedRoot,
              fieldDBType: field.dbType,
            },
            caller: "useCallback - onOuterFieldChange",
          });
        });
      } else {
        dispatchExecute({
          type: "UPDATE",
          payload: { field: fieldId, queryType: "matchMulti" },
          caller: "useCallback - onOuterFieldChange",
        });
      }
    },
    [dispatchExecute, getField]
  );

  const onBoostChange = useCallback(
    (boost) => {
      dispatchExecute({
        type: "UPDATE",
        payload: { boost },
        caller: "useCallback - onBoostChange",
      });
    },
    [dispatchExecute]
  );

  const handleTypeChange = useCallback(
    (e) => {
      const type = e.target.value;
      dispatchExecute({
        type: "UPDATE",
        payload: { type },
        caller: "useCallback - handleTypeChange",
      });
    },
    [dispatchExecute]
  );

  const outerFieldHeader = useMemo(() => {
    if (field?.id) {
      return (
        <div className="outerFieldHeader">
          <span className="label">Name</span>
          <span className="id">{field.id}</span>
          <span className="label">Type</span>
          {field.types.map((type, index) => (
            <span className="type" key={`${type}-${index}`}>
              {type}
            </span>
          ))}
          <span className="label">DBType</span>
          <span className="dbType">{field.dbType}</span>
          <span className="label">Nested</span>
          <span className="nested">
            {field.dbNestedField ? "True" : "False"}
          </span>
        </div>
      );
    }
    return null;
  }, [field]);

  const queryHeader = useMemo(() => formatStatement(rootNode), [rootNode]);

  // Match All Terms --> execute.isValueEditing && !execute.isValueList &&
  return (
    <span className="execute-box">
      <Container
        title={`Outer Field${Array.isArray(rootNode.field) ? "s" : ""}`}
        foldable={true}
        folded={false}
        help="rule-statement-outer-field"
        warningMessage={errors.statementsList?.[rootNode._id]?.field}
        header={outerFieldHeader}
        headerOnlyOnFolded={false}
      >
        <OuterField
          value={rootNode.field}
          catalogs={values.contentType}
          realms={realms}
          onOuterFieldChange={onOuterFieldChange}
          onMultiChange={(value) => true}
          error={errors.statementsList?.[rootNode._id]?.field}
        />
      </Container>
      {rootNode.field ? (
        <Container
          title="Execute"
          foldable={true}
          folded={false}
          help="rule-statement-execute"
          warning={errors.statementsList?.[rootNode._id]?.query}
          header={queryHeader}
          extra={
            <div className="rule-type-select">
              <Form.Control
                as="select"
                value={rootNode.type}
                onChange={handleTypeChange}
              >
                <option></option>
                {ruleTypes.map((rt, i) => (
                  <option key={`rule-type-${i}`} value={rt.value}>
                    {rt.lable}
                  </option>
                ))}
              </Form.Control>
            </div>
          }
        >
          <div className="main-statement">
            <div className="operator-select">
              <Form.Control
                as="select"
                name={`${namePath}.type`}
                value={rootNode.queryType}
                onChange={onQueryTypeChange}
                isInvalid={errors.statements?.[ruleIndex]?.queryType}
              >
                <option></option>
                {[...executionTypesFiltered]
                  .sort((a, b) => (a.indexUI < b.indexUI ? -1 : 1))
                  .map((t, i) => (
                    <option
                      key={`execType-${i}`}
                      value={t.discoverField || t.valueField}
                    >
                      {t.label}
                    </option>
                  ))}
              </Form.Control>
            </div>
            <div className="values">
              {haveValue && rootNode.queryType && (
                <Values
                  {...props}
                  field={valuesFields}
                  name={`${namePath}.value`}
                  value={rootNode.value}
                  valueType={rootNode.valueType}
                  types={preferredTypes}
                  metadataTypes={metadataTypes}
                  compactTypes={false}
                  catalogs={catalogs}
                  realms={realms}
                  handleChange={updateValue}
                  handleValueTypeChange={onValueTypeChange}
                  forceNoError={rootNode.queryType === "range"}
                  info={info}
                />
              )}
            </div>
            <div className="boost">
              {rootNode.queryType === "listTermsId" && rootNode.value && (
                <Link to={`/lists/${rootNode.value}`}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="list-link"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </Link>
              )}

              <RotaryKnob
                value={Number(rootNode.boost)}
                min={1}
                max={boostMaxValue || 200}
                step={1}
                label="Boost"
                labelPosition="right"
                skin="s10"
                onChange={onBoostChange}
              />
            </div>
          </div>
          <div className="error-box">
            <div className="spacer" />
            <div className="error">
              <ErrorMessage
                name={`statementsList.[${rootNode._id}].queryType`}
              />
              {rootNode.queryType && (
                <ErrorMessage name={`statementsList.[${rootNode._id}].value`} />
              )}
            </div>
            <div className="spacer" />
          </div>

          {needExtra.includes(rootNode.queryType) ? (
            <div className="extra-panels">
              {(rootNode.queryType === "ids" || rootNode.type === "listId") && (
                <div className={styles.listIdBox}>
                  <Splitter
                    {...props}
                    execute={rootNode}
                    handleChange={updateOtherFields}
                    name={`${namePath}.otherFields`}
                  />
                  <KeepOrder
                    {...props}
                    execute={rootNode}
                    handleChange={updateOtherFields}
                    name={`${namePath}.otherFields`}
                  />
                </div>
              )}

              {rootNode.queryType === "matchAll" && (
                <SplitterAndPerc
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "like" && (
                <MltParams
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                  contentType={values.contentType}
                />
              )}
              {rootNode.queryType === "likeContentId" && (
                <MltParams
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                  contentType={values.contentType}
                />
              )}
              {rootNode.queryType === "range" && (
                <Range
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "distance" && (
                <Range
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                  label="Distance"
                  timeUnitsOnly={true}
                />
              )}
              {rootNode.queryType === "greaterValue" && (
                <GreaterThan
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "lessValue" && (
                <LessThan
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "match" && (
                <Phrase
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "matchMulti" && (
                <Multi
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "similarTo" && (
                <Search
                  {...props}
                  execute={rootNode}
                  showPhraseOption={true}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "sortOrder" && (
                <Sort
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
              {rootNode.queryType === "random" && (
                <Random
                  {...props}
                  execute={rootNode}
                  handleChange={updateOtherFields}
                  name={`${namePath}.otherFields`}
                />
              )}
            </div>
          ) : null}
        </Container>
      ) : null}
    </span>
  );
}

export default Execute;
