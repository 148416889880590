import { forwardRef, useCallback, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import db from "../../../hooks/useMemoryDB";
import { useItemContext } from "../../../hooks/useItem";
import { useUpdateMetadata } from "../../../hooks/useUpdateMetadata";
import get from "lodash.get";

import Container from "../../container";
import SelectAll from "../../selects/selectAll";
import stubs from "../../../utils/stubs";

const checkStyle = { display: "inline-block" };

const mPack = forwardRef((props, ref) => {
  const { metadata, updateMetadata, rootNodeName, contentType, info } = props;
  const updateFieldMetadata = useUpdateMetadata();
  const { itemId } = useItemContext() || stubs.item;
  const { errors: errori } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);

  const required = useMemo(() => get(metadata, "metaPack.required"), [metadata]);
  const handleRequired = useCallback(
    (e) => {
      const required = e.target.checked;
      if (!required) updateMetadata("metaPack", undefined);
      else updateMetadata("metaPack.required", required);
    },
    [updateMetadata]
  );

  const ids = useMemo(() => {
    const ids = get(metadata, "metaPack.ids");

    return ids ? [...ids] : undefined;
  }, [metadata]);

  const metaPackFilters = useMemo(() => {
    const filters = {
      catalogs: [contentType],
    };

    if (info.isFallback || info.isInFallback) {
      filters.isUserBased = false;
    }

    return filters;
  }, [contentType, info?.isFallback, info?.isInFallback]);

  const handleChange = useCallback(
    (id) => {
      const data = db.get("metaPacks", id);
      const userBased = data.userBased;

      const update = {
        id,
        required: true,
        userBased,
      };

      updateMetadata("metaPack", update);
    },
    [updateMetadata]
  );

  useEffect(() => {
    if (metadata.metaPack?.id) {
      const data = db.get("metaPacks", metadata.metaPack.id);
      const pipeIds = data.pipelines?.static ?? [];
      data.pipelines?.dynamic?.forEach(({ id }) => pipeIds.push(id));
      const pipelines = db.bulkGet("pipelines", pipeIds);

      db.deleteByQuery("metadata", (i) => {
        if (i.owner !== itemId) return false;
        if (i.realm !== "metaPack") return false;
        return true;
      });

      const put = (id, values = []) => {
        const data = {
          realm: "metaPack",
          catalog: contentType,
          types: ["string"],
          owner: itemId,
          id,
          values,
          volatile: true,
        };
        db.put("metadata", data);
      };

      Object.entries(data.injects).forEach(([chiave, { active, key }]) => {
        if (active) {
          if (chiave === "fingerprint") put(key);
          else if (chiave === "tags") {
            const values = new Set();

            pipelines.forEach(({ title, tags }) => {
              if (tags?.active) {
                tags.rules.forEach(({ active, id, label }) => {
                  if (active) values.add({ id, label: `${title}:${label}` });
                });
              }
            });

            put(key, [...values]);
          } else if (chiave === "segments") {
            const values = new Set();

            pipelines.forEach(({ title, segments }) => {
              if (segments?.active) {
                segments.rules.forEach(({ active, id, label }) => {
                  if (active) values.add({ id, label: `${title}:${label}` });
                });
              }
            });

            put(key, [...values]);
          } else if (chiave === "synthesis") {
            const values = new Set();

            pipelines.forEach(({ synthesizer }) => {
              (synthesizer.rules ?? []).forEach((r) => r.active && values.add(`${key}${r.key}`));
            });

            values.forEach((id) => put(id));
          }
        }
      });
    }
  }, [contentType, itemId, metadata.metaPack?.id, updateFieldMetadata]);

  return (
    <Container
      folded={true}
      foldable={required || false}
      coloredBars={false}
      color="#030b0e"
      margin="4px"
      warning={errors?.[rootNodeName]?.metaPack}
      title={
        <Form.Check
          type="switch"
          id="metaPackSwitch"
          checked={get(metadata, "metaPack.required") || false}
          value="true"
          onChange={handleRequired}
          name="metaPackSwitch"
          label="Meta Pack"
          style={checkStyle}
        />
      }
      help="meta-meta-packs"
    >
      <SelectAll
        accessor="metaPacks"
        filters={metaPackFilters}
        value={metadata.metaPack?.id}
        handleChange={handleChange}
      />
    </Container>
  );
});

export default mPack;
