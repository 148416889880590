export const aresUserTokenVerifyKey = `
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1oofEdKjrWDHfIIWcVh1
zNy7bpOW0Sw6q89JnvSZp3rohfT2RP/8D8MST4Asvcib6xMrNWQgoNLjljHpWPKi
Tst3X2aj3FGEC0WAM5OohkgYNBktj41XfczuD/ANpByF0JuLPaolWMjhUyS/EqNe
bNAfc5ctvlf8OTGTykwX00b1jNxCBvoT4ou8dqTOkEjvfxmRDv+MaxRICOP6PUSb
nrDaVSWjyfOJIoc4bQz/uQKcbI+F4taUrXlClX/xC7M93vFzNNSyrugRm+mXRVEp
m1+BT+PnApI4xg7r3+PIhkooT+56bj/whpmWFvUFmjXToejEkXb2msso0dPmMnad
HMaSKhjU6rxnowSYmP/3SYNQRxQdOB9E7BxdprhH0LksjEbqNNAWD++zdbQTWgB9
XHSvjUjuk/fyNOQgh4yT7aYngoN/TwGiV7D1LxG4k7uLOxkGrXJzxWZIoqOORqeR
MzoAxQRfUAc17BWdKxJjRc+jFqGWkgpKOmFQX9dIipcXFOnj2Rm2huDqHKHgyi46
d59lhFdcnmo5EiwBSqc4D8u0zVDVUivlVdLvpOzR1pLn5thoM0S9qY9UdWXkTlYg
O+igNfCR9zcmZ5MyQAJQTgSo5xOgpFFoxoKoyAWgIHx5ehXuW6wihBw0Mj44Yt7+
B6tp+qH8aMr3flqySYlhQTcCAwEAAQ==
-----END PUBLIC KEY-----
`;

export const aresUserLoginSignKey = `
-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAreyk1p1H0wIUdI9G/d12ej5Rgi8OKPmi0fFI8xRAdrgKNsO8
T9YfIG/1zJBshDlVxBS9EQWWkShwMOsRQ9fMk1PR4Cg0Aw9blltBEgyye96tfgGP
+LxLvWltRtHtNlqSe0E2VJzlbcUN1hpwhcWhe9gU7U4bg9hkDc1lrIcDDGPuVHPm
/iiQyqXpmPa8MZK8I3wY06juBCTZpeIZRZUnK/7INPujBFuhQeJpEVNFrFD/nTH9
yyjIyHNicLTuWwQBjJcHCWQhP98M5G0CBYrG5DoSp4GwMp0gUpJSjOdTUXWXttEi
tGEfcCALkjo+98pht+7P5sb9xTroqM15Gn8tc/cwlWcalp2qgiuseudkjvGFhKm1
IfuIK6GFLeCD3hyuMxqbu/bSHBfIPg0N4Ywwra84sIvsD60tE1fuWtP2pn0k3iQj
f5DsGKNYU1H7bclWPqHOMNA8uzEeJUKAvbHaaxNPeZSRAtOtoFqrWj6BKFoMrTVX
Ag106M4rNpq/VNuS1fqAS3kU9DVrlrJIeOJfpmJUEgQm1iOHZ5U4dee200TE/6G6
CnjC1IL4x+P8aixol9FkRS6Mtp2R7VnI+PifbrG1cdk6JwPyCdPKLuR7U4x+WoB/
9P094592y/w29UO0KUY+vMCkYUyonXZsdzy0ABXdd80FhgSf48kfbmnVPjMCAwEA
AQKCAgEAkqis6ygfK6hiVVOIGoQH5tbr5moFd7nheUUMLaWTIkjYnAt7nJrDDpg6
XbAfQXQThU+zikn7CBZgXG+r1FiBcm5I2Mst6LKnmWcDvNOmIdvI0itBGw60k1j2
Q7S7YY0IXhTEWp8eu+xhWXxcDAF1xTeVlfG6M/hhjl338X67rFQL4X0GeKT+S1OS
YmEUmnzQVt/LE5gsdKQaP84jX1CBgpDKhBr5ALC/jw37aRed9csrcIN6LjkK7jSV
+tIm9+qt375uuZWMkA5nEat1+/Rk+IibuFIcoOFsIxSttZFO6a7OKuO5bv9DAkqT
V0/0TwCCXBZti32a+hhNniH9j8FUMkRQ2xjJixGt9hxC9NzQ+Jy7Sl3pfCd9U2Ec
3R5+fC3eIoGqk6qBK/UoHfB93aeu9qPPxqUtHIa4yY5y26BRtVU/R3nk8SB0oUCb
uaooHCXZPG547IERPlf1LXsUsmdMX7JLU2sdJ+XJpHfaYXJV8MgfLV3ylCcZWimF
0L4pa9HeB/h+R0PstjPaV5P+rqFzwsUv5GFtGaxyuiqRKnuWDB+y6Lx6Zhs8gasp
J45CK3UOZmJzGlQGqnuq6oIt65eMRTQfwc7cwjU61NmsmLxxnz9TYdztVviha0a2
67Rc+axtICzArIlcpG1tpJ5CEc/v0kDjfsU33gzBnK6GiCYUsDECggEBANrq0Ox2
Gp/T+ahJ1jan/seNX5gzL8QVH6dBRyg3TCM5Fk5NfL51W5p84K9SQLTPKRWOdXtw
ZIIhtk0KywHFnHe7JLAlSvOkV9GfEZkcGB0UZ/Hj/XUlz8dtyJNic5LjJoh9Xo7Y
Ww783UusgVzFDnCbtMZRl+NmP4J7FCY4eQMj8LINi+OtPksF1ImM1wpR0AXMOi3E
YntLkLeogxRJAbMW8BunkwL8f/PjeRIVOiDHRkZeN5mH4zMbtVjUc0xcfSYIxvLm
idUGTQJY903D6QrHZuK0Yt06DjCGHul4pBpkD8ES702WeunbHgSAIhyElk4RUBas
TMvfzL9Z+wgHOtsCggEBAMtivu+NSmVngUfxL6dr7gD0XUNVlgcGVsi63CzIUR6J
0BwVmIqnJWGnDaFsIF+MG1D1Jff7xQSGSsC0LmtlrbwbbdG2sfXinZDLzuZI6P9C
NmmNQHE/WDaEXuzIr25zFRgc/7GeFqipeCjkKvgX8nis0/ZWmNGRIth7ASJtFY8h
gMMi06jTADsCBV+jtlZI9HaGSePgYzk4oFAjbgoaSLTGWKTuuZpi5qqKThFMdS//
7uIOB/QBkSEf3+/U9BV2vB99XwN687PbQoCGshZrr+VEOsEvjV9S+CcMkgX6YDgs
rqfzqnTG1wnbFZ0hhqaPCyDKpFRkwvaJVnNL1oWO7YkCggEAcGqguqyzY1vBGnBA
NnTyWpr4efnfD3whcwwMdFRhHr1T/QXBhzRQw/ZYku9UFwVCfbVYEvlWQ5T4PnvK
X7CQdtN9NY8Q/ChqiFR8/vj0UQqHjimboCq4lhqEI1stKkLHKNPoWMY1jG7BXPjK
vU7b6Wur2yNPMhDxkBmP8UiSq9Aq6+QU7+eo03G9AJ14V+5HOpLzVUlBoTrep9E6
7pHG86ZVyn20+pWbRyyKoYSRAwAR0FTVjFvBih8yctk2FAJyqbNcJqPIJ8JCjXAO
Hrr50vUumkAzfJMxci2NdirjakHz7RvCEz+xAqHoujrqSWa0oIY3cE0uaosUYzbb
t/WufQKCAQEAgib/zuaY7EmPVs2GVFpiJWQ7ZoKXjolE8hF6IoJSPvusHLzJMDmE
V3iC5YD/OoW7WVR35rjMPHf/0E+9wOaBhnX7EhAa1CIf+PAjyARybh8eGO/WhGIn
/kM5R8epVEH7BsJP8ocbMvW4OlrBEKCqfwUCY4rJIpsBNI2KjVBHUp/6+WUJE+lr
JVfGvoxSe+cV4d9P267VcGHHXWE+4uItEto3EdzfE2gk+o+lI7bM61mZsjmOZ1y1
VgBC2wRo6m9gNbqSGfUNjVRLu1n3Dxf8qPe/ra7ThmPe/4Ywz/iIqIoXfs0UkMRK
CjmUHEi/FUlGssZa4NLWXNRIaWHntvJfGQKCAQBSibI+coF0pHqH3bFU67eGL14O
5pmnPavbXGHJpFNxQZTVPJ++t0mL1a3aPwNgf8PAbTivJ9snzA5hf2N9p1X3Y13W
qJuZnVGh/ZNLBt7ngjH8ROdJHKcw20flckyKsgP/vR3jNW6pdgLbtOb4cYbGjqFS
wAf/U3nnp+VReeGVW9kWcoLfKJhqG2YzhBfwfCHNNvAJXliuRshQVa6yIkD3lubU
xGWXX/K5DW2ZXv39ySrJK5pIL5DQd5VLI2D4r/p3nRo/259KBFWe5hJOL4hPsXv/
UVxdWDuSo1GQGHxYsImwKdFUAp9VvW67X7X1RXZWmzzFe+9TDAspzi6aqz8r
-----END RSA PRIVATE KEY-----
`;
