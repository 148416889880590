import { useMemo, useCallback } from "react";
import { useDataParams } from "../useData";

export default function useContentRealm() {
  const params = useDataParams();
  const userTypes = useMemo(() => {
    if (params) {
      return new Set(Object.keys(params.users?.clusters?.types || {}));
    }

    return new Set();
  }, [params]);

  const contentTypes = useMemo(() => {
    if (params) {
      return new Set(Object.keys(params.content?.types || {}));
    }

    return new Set();
  }, [params]);

  const getRealm = useCallback(
    (contentType) => {
      if (userTypes.has(contentType)) return "user";
      if (contentTypes.has(contentType)) return "content";

      return undefined;
    },
    [contentTypes, userTypes]
  );

  return { getRealm, userTypes: [...userTypes], contentTypes: [...contentTypes] };
}
