import { useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import Pipelines from "../loaders/pipelines";
import SelectMeta from "../selects/selectMeta";
import { db } from "../../db";

import styles from "./styles.module.scss";

export default function PipelinesBox() {
  const { values, errors, setFieldValue } = useFormikContext();

  const extra = useMemo(() => {
    if (!values.userBased) return <span />;

    return (
      <Form.Check
        type="switch"
        value="true"
        checked={values.fetchUserMetadata ?? false}
        onChange={(e) => setFieldValue("fetchUserMetadata", e.target.checked)}
        id="activate-fetchUserMetadata"
        name="activate-fetchUserMetadata"
        label="Fetch User Metas for Dynamic Pipelines"
        className={styles.switch}
      />
    );
  }, [setFieldValue, values.fetchUserMetadata, values.userBased]);

  const getKeyComponent = useCallback(({ value, handleChangeValue }) => {
    const params = [...db.getTable("params").values()].find((p) => p.default);
    const catalogs = [params.users.clusters.defaults.type];

    return (
      <SelectMeta
        name={`metadata-select-metaPack`}
        value={value}
        handleChange={handleChangeValue}
        catalogs={catalogs}
        realms={["user"]}
        //excludes={excludes}
        className={styles.metaSelect}
      />
    );
  }, []);

  return (
    <Pipelines
      extra={extra}
      showDynamic={values.fetchUserMetadata ?? false}
      getKeyComponent={getKeyComponent}
      color={"#043356"}
      coloredBars={false}
      errori={errors}
    />
  );
}
