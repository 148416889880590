import { useEffect, useCallback } from "react";
import styles from "./styles.module.scss";
import { Form } from "react-bootstrap";
import { AndOrButtons } from "../../conditionsBox";

export default function AndOrSelector(props) {
  const {
    onChangeOperator,
    onChangeBreaker = () => true,
    operator,
    breaker,
    includeFolded,
    defaultOperator = "and",
    defaultBreaker = "0.9",
    showTie = true,
  } = props;

  useEffect(() => {
    if (operator === undefined && defaultOperator !== "and" && onChangeOperator) {
      onChangeOperator(defaultOperator);
    }
    if ((operator || defaultOperator) === "or" && breaker === undefined && onChangeBreaker) {
      onChangeBreaker(defaultBreaker);
    }
  }, [breaker, defaultBreaker, defaultOperator, onChangeBreaker, onChangeOperator, operator]);

  const handleChangeBreaker = useCallback(
    (e) => {
      onChangeBreaker(e.target.value);
    },
    [onChangeBreaker]
  );

  return (
    <div className={styles.header}>
      <AndOrButtons
        canEdit={true}
        onChange={onChangeOperator}
        logical={operator || defaultOperator}
      />
      {(operator || defaultOperator) !== "and" && showTie ? (
        <div className={styles.tie}>
          <div className={styles.label}>Tie Breaker</div>
          {includeFolded ? (
            <div className={styles.value}>{breaker || defaultBreaker}</div>
          ) : (
            <Form.Control
              type="number"
              min={0}
              max={1}
              step={0.1}
              //
              value={breaker ?? 0.9}
              className={styles.input}
              onChange={handleChangeBreaker}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}
