import { forwardRef, Fragment, useState, useMemo, useCallback, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Color from "color";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Help from "../help";

const fadeIn = "0.3s";

const getBorderSize = ({ borderSize, buttonBorderSize, isButton }) =>
  isButton ? buttonBorderSize : borderSize;
const getTextColor = ({ color = "#111", isButton, background }) => {
  if (isButton) return Color(color).lighten(0.5);
  return Color(color).lightness() > 40 ? "#000" : "#bbb";
};
const getBorderColor = (c = "#111", isButton) => {
  if (isButton) return c;

  const color = Color(c);
  return color.lightness() > 40 ? color.darken(0.2).hex() : color.lighten(0.5).hex();
};

const getTitleColor = (c = "#111") => {
  const color = Color(getBorderColor(c));
  return color.lightness() > 40 ? color.hex() : color.lighten(2).hex();
};

const RootContainer = forwardRef(({ className, children, id }, ref) => {
  return (
    <div ref={ref} className={className} id={id} key={id}>
      {children}
    </div>
  );
});

const Container = styled(RootContainer)`
  background: ${(props) => props.background || "#000"};
  border: ${(props) => getBorderSize(props)} solid
    ${({ color, isButton }) => getBorderColor(color, isButton)};
  border-radius: ${({ isButton }) => (isButton ? "4px" : "8px")};
  margin-bottom: ${({ margin }) => margin};

  ${({ noMarginLast }) => (noMarginLast ? "" : " &:nth-last-child(1) {margin-bottom: 0px;}")}

  ${({ isButton, hover, hoverFactor, color }) => {
    if (isButton) {
      return `
        display: inline-block; 
        margin-right: 6px;

        &:hover{
          border-color: ${Color(color).lighten(0.5)};
        }
        &:last-child{
          margin-right: 0px;
        }
      `;
    }

    if (hover) {
      return `
        &:hover {
          border-color: ${Color(color).lighten(hoverFactor)};

          & > ${Header} {
            border-color: ${Color(color).lighten(hoverFactor)};
          }
        }
    `;
    }
  }}
  
  animation: fadeIn ease ${fadeIn};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ style }) => {
    if (style) {
      const stile = Object.entries(style).map(([k, v]) => `${k}:${v};`);
      return stile.join("\n");
    }
  }}
`;

const Header = styled.div`
  border-bottom: ${(props) => {
    if (props.folded) return "0px";
    return `${getBorderSize(props)} solid ${getBorderColor(props.color)};`;
  }};

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: ${(props) => {
    if (!props.folded) return "0px";
    return "8px";
  }};
  border-bottom-right-radius: ${(props) => {
    if (!props.folded) return "0px";
    return "8px";
  }};

  ${({ isButton, color, headerColor, background, coloredHeader }) =>
    isButton || !coloredHeader
      ? ""
      : `
          background-color: ${headerColor || color || background} 
        `}
`;

const Box = styled.div`
  cursor: pointer;
  max-width: 100%;
  padding: ${({ isButton }) => (isButton ? "2px 8px 2px 8px" : "2px 18px 2px 18px")};
  display: flex;
  align-items: baseline;
`;

const Warning = styled.div`
  color: goldenrod;
  font-size: 1em;
  margin-right: 8px;
  margin-left: -8px;
`;

const Title = styled.div`
  align-self: baseline;
  flex-shrink: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: ${({ isButton }) => (isButton ? "1em" : "1.1em")};
  color: ${(props) => (props.coloredTitle ? getTitleColor(props.color) : getTextColor(props))};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) => {
    if (props.isButton) {
      const color = getTextColor(props);
      return `
      &:hover{
        color: ${Color(color).lighten(0.7)}
      }
    `;
    }
  }}
`;

const Spacer = styled.div`
  flex-grow: 4;
`;

const Body = styled.div`
  ${({ internalPadding }) => (internalPadding ? "padding: 10px;" : "")}
  ${({ topPadding }) => (topPadding === false ? "padding-top: 0px;" : "")}
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ hide }) => hide && `display: none;`}
`;

const HeaderContent = styled.div`
  ${({ overflow }) =>
    overflow &&
    `
     overflow-x: ${overflow}
  `}

  ${({ margins }) =>
    margins &&
    `
      margin-left: 12px;
      margin-right: 12px;
  `}


  ${({ headerJustify }) => (headerJustify ? `justify-content: ${headerJustify};` : "")}
`;

const HeaderExtra = styled.div`
  ${({ extraAlign }) => (extraAlign ? `align-self: ${extraAlign};` : "")}
  margin-right: 0px;
`;

const HeaderHelp = styled.div`
  align-self: baseline;
  margin-left: 2px;
`;

const Fold = styled.div`
  cursor: pointer;
  margin-left: -4px;
  margin-right: 6px;
  font-size: 0.7em;
  color: ${(props) => getTextColor(props.color)};
`;

const Footer = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  border-top: ${(props) => {
    return `${getBorderSize(props)} solid ${getBorderColor(props.color)};`;
  }};

  ${({ isButton, color, footerColor, headerColor, background, coloredFooter }) =>
    isButton || !coloredFooter
      ? ""
      : `
        background-color: ${footerColor || headerColor || color || background} 
          `}
`;

const WarningTooltip = styled.div`
  padding: 8px;
`;

const ContainerBox = forwardRef((props, ref) => {
  const {
    id,
    borderSize = "2px",
    buttonBorderSize = "1px",
    title,
    header,
    headerJustify,
    footer,
    extra,
    extraAlign,
    color = "#111",
    headerColor,
    headerOverflow,
    footerColor,
    background = "#000",
    internalPadding = true,
    topPadding = true,
    headerOnlyOnFolded = true,
    coloredTitle = false,
    coloredBars = true,
    foldable = true,
    hideBodyOnFold = false,
    isButton = false,
    hover = true,
    hoverFactor = 1.5,
    margin = "10px",
    noMarginLast = false,
    folded,
    onFold,
    style,
    help,
    className,
    warningMessage,
    warning = false,
  } = props;

  const [fold, setFold] = useState(folded);

  useEffect(() => {
    setFold(folded);
  }, [folded, foldable]);

  const handleOnFold = useCallback(() => {
    if (!foldable) return;

    setFold((state) => {
      //if (!foldable) return true;
      onFold && onFold(!state);
      return !state;
    });
  }, [foldable, onFold]);

  const foldIcon = useMemo(() => {
    if (foldable) {
      return (
        <FontAwesomeIcon
          icon={fold ? faChevronRight : faChevronDown}
          onClick={handleOnFold}
          fixedWidth
        />
      );
    }

    return null;
  }, [foldable, fold, handleOnFold]);

  const warningIcon = useMemo(() => {
    const message = warningMessage || "There is a warning inside this container";

    return (
      <OverlayTrigger
        key={`warning-${id}`}
        placement="right"
        overlay={
          <Tooltip id={`tooltip-${id}`}>
            <WarningTooltip>{message}</WarningTooltip>
          </Tooltip>
        }
      >
        <span>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            fixedWidth
            style={{ fontSize: isButton ? "0.8em" : "1em", marginLeft: isButton ? "10px" : "" }}
          />
        </span>
      </OverlayTrigger>
    );
  }, [id, isButton, warningMessage]);

  return (
    <Container
      key={id}
      id={id}
      ref={ref}
      background={background}
      color={color}
      borderSize={borderSize}
      buttonBorderSize={buttonBorderSize}
      style={style}
      className={className}
      isButton={isButton}
      hover={hover}
      hoverFactor={hoverFactor}
      margin={margin}
      noMarginLast={noMarginLast}
    >
      <Header
        color={color}
        headerColor={headerColor}
        coloredHeader={coloredBars}
        borderSize={borderSize}
        buttonBorderSize={buttonBorderSize}
        folded={fold}
        isButton={isButton}
        background={background}
        hover={hover}
        hoverFactor={hoverFactor}
      >
        <Box isButton={isButton}>
          {warning || warningMessage ? <Warning>{warningIcon}</Warning> : null}
          {foldable ? <Fold color={color}>{foldIcon}</Fold> : null}
          <Title
            color={color}
            onClick={handleOnFold}
            isButton={isButton}
            background={background}
            coloredTitle={coloredTitle}
          >
            {title}
          </Title>
          {help ? (
            <HeaderHelp key={help}>
              <Help code={help} />
            </HeaderHelp>
          ) : null}
          {!headerOnlyOnFolded || (headerOnlyOnFolded && fold) ? (
            <HeaderContent margins={header} overflow={headerOverflow} headerJustify={headerJustify}>
              {header}
            </HeaderContent>
          ) : null}
          <Spacer />
          <HeaderExtra extraAlign={extraAlign}>{extra}</HeaderExtra>
        </Box>
      </Header>
      {hideBodyOnFold || !fold ? (
        <Fragment>
          <Body hide={fold} internalPadding={internalPadding} topPadding={topPadding}>
            {props.children}
          </Body>
          {footer && !fold ? (
            <Footer
              color={color}
              headerColor={headerColor}
              footerColor={footerColor}
              borderSize={borderSize}
              buttonBorderSize={buttonBorderSize}
              isButton={isButton}
              background={background}
              coloredFooter={coloredBars}
            >
              <Box>
                <Spacer />
                {footer}
              </Box>
            </Footer>
          ) : null}
        </Fragment>
      ) : null}
    </Container>
  );
});

export default ContainerBox;
