import { useMemo } from "react";
import { useDataSettings } from "../../hooks/useData";
import { Row, Col } from "react-bootstrap";
import RotaryKnob from "../rotaryKnob";
import OutPutField from "./outputField";
import Help from "../help";

const knobStyle = { paddingLeft: "0px" };

export default function UserSimilar(props) {
  const { metadata, updateMetadata, contentType } = props;

  const _contentType = useMemo(
    () => metadata?.user_similar?.contentType || contentType,
    [contentType, metadata.user_similar]
  );

  const settings = useDataSettings((settings) => settings?.userSimilar[_contentType]);

  const defaults = useMemo(() => {
    if (!settings) return;
    const type = settings[metadata?.user_similar?.userContentType];
    if (type) {
      const event = type[metadata.user_similar.event];
      if (event) {
        return event;
      }
    }
  }, [metadata, settings]);

  if (defaults)
    return (
      <>
        <Row className="rowMarginTop">
          <Col sm="1">
            <span className="label">Output</span>
            <Help code={`meta-user-similar-output-metadata`} />
          </Col>
          <Col sm="10">
            <Row>
              <OutPutField
                data={metadata.user_similar?.output}
                defaultData={defaults?.output}
                realm={"user_similar"}
                catalog={_contentType}
                updateMetadata={(v) => updateMetadata("user_similar.output", v)}
              />
            </Row>
          </Col>
        </Row>
        {metadata.user_similar.perUserBasisType ? (
          <Row>
            <Col sm="2" className="columnLabel">
              Options <Help code={`meta-user-similar-options`} />
            </Col>
            <Col sm="10" style={knobStyle}>
              <div className="user-similar-knobs">
                <RotaryKnob
                  value={metadata?.user_similar?.numResults || defaults.numElements.value}
                  min={defaults.numElements.min}
                  max={defaults.numElements.max}
                  step={1}
                  label="Results"
                  labelPosition="bottom"
                  skin="s10"
                  onChange={(v) => updateMetadata("user_similar.numResults", v)}
                  className="knob-group-generic"
                />
                <RotaryKnob
                  value={metadata?.user_similar?.minTerms || defaults.fingerprint?.minTerms.value}
                  min={defaults.fingerprint?.minTerms.min}
                  max={defaults.fingerprint?.minTerms.max}
                  step={1}
                  label="Min Terms"
                  labelPosition="bottom"
                  skin="s12"
                  onChange={(v) => updateMetadata("user_similar.minTerms", v)}
                  className="knob-group-generic"
                />
                <RotaryKnob
                  value={metadata?.user_similar?.maxTerms || defaults.fingerprint?.maxTerms.value}
                  min={defaults.fingerprint?.maxTerms.min}
                  max={defaults.fingerprint?.maxTerms.max}
                  step={1}
                  label="Max Terms"
                  labelPosition="bottom"
                  skin="s12"
                  onChange={(v) => updateMetadata("user_similar.maxTerms", v)}
                  className="knob-group-generic"
                />
                <RotaryKnob
                  value={metadata?.user_similar?.minDocs || defaults.fingerprint?.minDocs.value}
                  min={defaults.fingerprint?.minDocs.min}
                  max={defaults.fingerprint?.minDocs.max}
                  step={1}
                  label="Min Docs"
                  labelPosition="bottom"
                  skin="s11"
                  onChange={(v) => updateMetadata("user_similar.minDocs", v)}
                  className="knob-group-generic"
                />
                <RotaryKnob
                  value={metadata?.user_similar?.maxDocs || defaults.fingerprint?.maxDocs.value}
                  min={defaults.fingerprint?.maxDocs.min}
                  max={defaults.fingerprint?.maxDocs.max}
                  step={1}
                  label="Max Docs"
                  labelPosition="bottom"
                  skin="s11"
                  onChange={(v) => updateMetadata("user_similar.maxDocs", v)}
                  className="knob-group-generic"
                />
              </div>
            </Col>
          </Row>
        ) : null}
      </>
    );

  return null;
}
