import { useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { useDataRefItem } from "../../hooks/useDataRef";
import camelCase from "lodash.camelcase";
import Container from "../container";
import SelectAll from "../selects/selectAll";
import ContentType from "../contentType";

import styles from "./styles.module.scss";

const selectStyle = { width: "200px" };

export default function Settings() {
  const { setFieldValue, values, errors } = useFormikContext();
  const info = useDataRefItem("conversionPacks", values.id);

  useEffect(() => {
    if (values.cache === undefined) setFieldValue("cache", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extra = useMemo(() => {
    return (
      <div className={styles.extraSettings}>
        {!info.isInFallback && (
          <Form.Check
            type="switch"
            id="userBased"
            checked={values.userBased}
            value="true"
            onChange={(e) => {
              const value = e.target.checked;
              setFieldValue("range.comparePrevious", 0);
              setFieldValue("userBased", value);
              if (!value) setFieldValue("injectHistory", 0);
            }}
            name="userBased"
            label="User Based"
            className={styles.userBasedCheckbox}
          />
        )}
        <Form.Check
          type="switch"
          id="cache"
          checked={values.cache}
          value="true"
          onChange={(e) => setFieldValue("cache", e.target.checked)}
          name="cache"
          label="Cached"
          className={styles.cacheCheckbox}
        />
      </div>
    );
  }, [info?.isInFallback, setFieldValue, values.cache, values.userBased]);

  const header = useMemo(() => {
    return (
      <div className={styles.settingsHeader}>
        <span className={styles.label}>Params</span>
        <div className={styles.paramsId}>
          <SelectAll
            accessor="params"
            value={values.paramsId}
            handleChange={(value) => setFieldValue("paramsId", value)}
            isInvalid={errors.paramsId}
          />
        </div>
        <span className={styles.label}>Catalog</span>
        <div className={styles.ContentTypeBox}>
          <ContentType
            rootNode={values}
            fixedValue={values.contentType}
            onChange={(value) => setFieldValue("contentType", value)}
            onlySelect={true}
            typeOfType="content"
            displayErrorMessage={false}
            style={selectStyle}
          />
        </div>
        <span className={styles.label}>OutputField</span>
        <div className={styles.output}>
          <Form.Control
            type="text"
            placeholder="Enter Field Name..."
            value={values.outputMetadataField || ""}
            onChange={(e) => setFieldValue("outputMetadataField", camelCase(e.target.value ?? ""))}
            isInvalid={errors.outputMetadataField}
          />
        </div>
        <span className={styles.label}>Num Elements</span>
        <div className={styles.numElements}>
          <Form.Control
            type="number"
            min={1}
            step={1}
            value={values.numElements || 0}
            onChange={(e) => setFieldValue("numElements", e.target.value)}
            isInvalid={errors.numElements}
            className={styles.numericField}
          />
        </div>
      </div>
    );
  }, [errors.numElements, errors.outputMetadataField, errors.paramsId, setFieldValue, values]);

  return (
    <Container
      title="Settings"
      help="conversion-pack-settings"
      color="#2a0049"
      coloredBars={false}
      foldable={false}
      folded={true}
      header={header}
      extra={extra}
      warning={errors.settings}
    />
  );
}

//    "paramsId": "playDefault", "contentType": "video", "outputMetadataField":
//        "mostSearchedLast8Hours", "numElements": 300,
