import validateMetadata from "../metadata/validation";
import validateConditions from "../conditionsBox/validate";

const setError = (id, errors) => {
  return (key, value) => {
    if (!id) return;

    if (!errors[id]) errors[id] = {};
    errors[id][key] = value;
  };
};

export function validateSmartOrder(values, errors = {}) {
  if (values.results?.smartOrderBlocks && values.blocks?.length > 2) {
    if (values.results?.conversionMode !== "metapack") {
      if (!values.results?.userContentType) {
        const error = "You Must Choose a User Catalog";
        errors.userContentType = error;
        errors.general = error;
      }
      if (!values.results?.event) {
        const error = "You Must Choose a User Event";
        errors.event_type = error;
        errors.general = error;
      }
      if (!values.results?.perUserBasisType) {
        const error = "You Must Choose a User Fingerprint";
        errors.perUserBasisType = error;
        errors.general = error;
      }
    } else {
      if (!values.results?.metaPackId) {
        const error = "You Must Specify a Meta Pack";
        errors.metaPackId = error;
        errors.general = error;
      }
    }
  }

  return errors;
}

export default function validate(values) {
  const errors = validateSmartOrder(values);

  if (!values.blocks || values.blocks.length === 0)
    errors.general = "You Must Insert At Least One Block";
  else {
    let blockFallbacksCount = 0;

    values.blocks.forEach((block) => {
      const e = setError(block._id, errors);

      // if (!block.title) {
      //   e("title", true);
      // }
      if (!block.results?.contentType) {
        e("contentType", true);
        e("settings", true);
      }

      if (["smart"].includes(block.type) && !block.userContentType) {
        //, "conversions"
        e("userContentType", true);
        e("settings", true);
      }

      if (["smart"].includes(block.type) && !block.event) {
        // "conversions"
        e("event_type", true);
        e("settings", true);
      }

      if (block.type === "smart" && !block.perUserBasisType) {
        e("perUserBasisType", true);
        e("settings", true);
      }

      if (block.type === "smart" && !block.smartPageListId) {
        e("smartPageListId", true);
        e("settings", true);
      }

      // if (
      //   block.type === "conversions" &&
      //   !["randomIdFromUserHistory"].includes(block.idExtractMethod) &&
      //   !block.perUserBasisType
      // ) {
      //   e("perUserBasisType", true);
      //   e("settings", true);
      // }

      if (block.type === "conversions" && !block.packs?.length > 0) {
        e("packs", true);
        e("settings", true);
      }

      if (block.type === "conversions" && !block.idExtractMethod) {
        e("packs", true);
        e("idExtractMethod", true);
        e("settings", true);
      }

      if (block.type === "placeholder" && !block.placeholderMain) {
        e("placeholderMain", true);
        e("settings", true);
      }

      if (block.type === "script" && (!block.scripts || block.scripts.length === 0)) {
        e("scripts", true);
        e("settings", true);
      }

      if (block.type === "external" && !block.externalSourceId) {
        e("external", true);
        e("settings", true);
      }

      if (block.type === "deferred" && !block.endpointId) {
        e("deferred", true);
        e("settings", true);
      }

      if (["query", "conversions"].includes(block.type)) {
        if (!block.queryId) {
          e("queryId", true);
          e("settings", true);
        }
        if (block.queryId && !block.results?.responseFieldsId) {
          e("responseFieldsId", true);
          e("response", true);
        }
      }

      if (
        ["query", "conversions"].includes(block.type) &&
        !block.results?.fallbackBlockId &&
        !values.results?.fallbackBlockId &&
        !values?.canBeFallback
      ) {
        e("fallback", true);
      } else blockFallbacksCount += 1;

      if (block.metadata) {
        const metadataErrors = validateMetadata(block.metadata, values?.canBeFallback);
        if (Object.keys(metadataErrors).length > 0) {
          //e("metadata", metadataErrors);
          errors[`blocks_${block._id}_metadata`] = metadataErrors;
        }
      }

      if (validateConditions(block.conditionRaw)) {
        e("conditions", true);
      }
    });

    if (values.blocks.length - blockFallbacksCount > 0) {
      errors.fallback = true;
    }
  }
  return errors;
}
