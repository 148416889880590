import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

function Splitter(props) {
  const { execute, handleChange } = props;

  return (
    <div className={styles.splitterBox}>
      <div className={styles.select}>
        <div className={styles.label}>Split Char</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            className="form-control form-control-sm"
            onChange={(e) => handleChange("splitter", e)}
            value={execute.splitter}
          >
            <option value="">Default</option>
            <option>,</option>
            <option value=" ">(space)</option>
            <option>;</option>
            <option>|</option>
          </Form.Control>
        </div>
      </div>
    </div>
  );
}

export default Splitter;
