import { useDataSettings } from "../../hooks/useData";
import set from "lodash.set";
import defaultsDeep from "lodash.defaultsdeep";
import cloneDeep from "lodash.clonedeep";

import Item from "../item";
import Query from "./query";
import validation from "./validation";

export function doQueryRefs(values, queryClauses, queryDefaults) {
  const newValues = cloneDeep(values);
  const items = new Set();
  const presets = new Set();

  queryClauses?.forEach(({ accessor }) => {
    values[accessor] && values[accessor].forEach((k) => items.add(k.id));
    if (values.presets) {
      values.presets[accessor] && values.presets[accessor].forEach((k) => presets.add(k.id));
    }
  });

  const refArray = [
    { type: "rules", items: [...items] },
    { type: "rulesPresets", items: [...presets] },
  ];

  if (values.label) {
    refArray.push({
      type: "labels",
      items: [values.label],
    });
  }

  set(newValues, "refs.require", refArray);
  defaultsDeep(newValues, queryDefaults);

  return newValues;
}

const emptyObj = {};

export default function QueryContainer() {
  const { queryClauses, queryDefaults } =
    useDataSettings("queryClauses", "queryDefaults") ?? emptyObj;

  return (
    <Item
      name="Query"
      accessor="queries"
      onSubmit={(values) => doQueryRefs(values, queryClauses, queryDefaults)}
      onValidate={validation}
    >
      <Query />
    </Item>
  );
}
