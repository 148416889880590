import { useCallback, useRef } from "react";
import Conditions from "../conditionsBox";
import SelectMeta from "../selects/selectMeta";
import Values from "../multipleValues";
import { scheduleOperators } from "../../db/refManager";

const timeOperatorsExclude = [
  ...scheduleOperators,
  ...scheduleOperators.map((k) => `!${k}`)
];

export default function Condition(props) {
  const { catalogs, realms, info } = props;
  const restrictedRef = useRef(false);
  const operatorsRef = useRef();

  const operatorsFilter = useCallback(
    (keyValue = "", operators = []) => {
      let restricted = false;

      const operatori = (() => {
        if (
          !restrictedRef.current &&
          info?.restricted &&
          keyValue.includes("when_")
        ) {
          restricted = true;
          return operators.filter(
            (o) => !timeOperatorsExclude.includes(o.valueRaw ?? o.value)
          );
        }

        restricted = false;
        return operators;
      })();

      if (!restrictedRef.current || restricted !== restrictedRef.current) {
        operatorsRef.current = [operatori, restricted];
      }

      return operatorsRef.current;
    },
    [info?.restricted]
  );

  const getKeyComponent = useCallback(
    ({ id, value, handleChangeValue, handleChangeType, types, inError }) => {
      return (
        <SelectMeta
          name={id}
          value={value}
          handleChange={handleChangeValue}
          onMetaChangeType={handleChangeType}
          catalogs={catalogs}
          realms={realms}
          types={types}
          errored={inError}
          info={info}
          filtro={(array) => {
            if (!array) return [];
            if (!Array.isArray(array)) array = [array];
            return array.filter(({ id }) => !id.includes(".keyword"));
          }}
        />
      );
    },
    [catalogs, info, realms]
  );

  const getValueComponent = useCallback(
    ({
      id,
      operatorTypes,
      field,
      value,
      valueType,
      handleChangeValue,
      handleChangeType
    }) => {
      return (
        <Values
          name={id}
          realms={realms}
          catalogs={catalogs}
          types={operatorTypes?.value}
          metadataTypes={operatorTypes?.metadata}
          field={field}
          value={value}
          valueType={valueType}
          handleChange={handleChangeValue}
          handleValueTypeChange={handleChangeType}
          sanitizeTextTypes={true}
          info={info}
        />
      );
    },
    [catalogs, info, realms]
  );

  return (
    <Conditions
      {...props}
      getKeyComponent={getKeyComponent}
      getValueComponent={getValueComponent}
      operatorsFilter={operatorsFilter}
    />
  );
}
