import { useRef, forwardRef } from "react";

import MetadataChooser from "./metadataChooser";
import GroupBy from "./groupby";
import Range from "./range";
import ConversionChooser from "./conversionChooser";
import Settings from "./settings";
import Preview from "./preview";
import Filters from "./conversionFilters";
import UseCaseFilter from "./useCaseFilter";
import Container from "../container";

const ConversionPack = forwardRef((props, ref) => {
  const previewRef = useRef({});

  if (ref?.current) {
    ref.current.reloadPreview = previewRef.current.reload;
  }

  return (
    <>
      <Settings />
      <ConversionChooser />
      <Range />
      <Container title="Filters" folded={true} coloredBars={false} color="#19002d">
        <Filters />
        <UseCaseFilter />
        <Container
          title="Content"
          folded={true}
          coloredTitle={false}
          coloredBars={false}
          color="#245501"
        >
          <MetadataChooser
            title="Keep Only"
            help="conversion-pack-keep-only"
            color="#015301"
            accessor="filter"
          />
          <MetadataChooser
            title="Exclude"
            help="conversion-pack-exclude"
            color="#5a0000"
            accessor="must_not"
          />
          <GroupBy />
        </Container>
      </Container>
      <Preview ref={previewRef} />
    </>
  );
});

export default ConversionPack;
