import {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
  useMemo,
} from "react";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css";
import { useDataSettings } from "../../hooks/useData";

import usePermissions from "../../hooks/usePermissions";
import useMetadataValues from "../../hooks/useMetadataValues";

import { useFormikContext } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faTrashAlt,
  faPowerOff,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import md5 from "md5";

import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import has from "lodash.has";
import isEqual from "lodash.isequal";

import ErrorMessage from "../errorMessage";
import SelectMeta from "../selects/selectMeta";
import ValuesSelect from "../selects/selectValues";
import RotaryKnob from "../rotaryKnob";
import OutPutField from "./outputField";
import Container from "../container";
import AndOrSelector from "../query/andOrSelector";

import stubs from "../../utils/stubs";
import "./style.scss";

const basePath = "content_similar";
const styleInlineMarginRight = { display: "inline-block", marginRight: "4px" };
const styleInline = { display: "inline-block" };
const styleMarginLeft = { marginLeft: "4px" };
const styleSelect = { width: "466px" };

function intToString(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

export default function Similar(props) {
  const {
    metadata,
    updateMetadata,
    rootNode,
    rootNodePath,
    rootNodeName,
    contentType,
    blockFields,
    uniqueId,
    excludeFallbackMetadata,
  } = props;

  const { errors: errori, handleBlur } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);
  const permissions = usePermissions();
  const { queryDefaults } = useDataSettings("queryDefaults") || {};
  const queryExpert = useMemo(
    () => permissions.expert.includes("query-settings"),
    [permissions]
  );
  const defaults = useDataSettings(
    (settings) => settings.contentSimilar[contentType] || {}
  );
  const [folded, setFolded] = useState(true);
  const [foldedHeader, setFoldedHeader] = useState(new Map());

  const onFold = useCallback(
    (statement, status) => {
      const newFolded = new Map(foldedHeader);
      newFolded.set(statement, status);
      setFoldedHeader(newFolded);
    },
    [foldedHeader]
  );

  const fieldOutput = useMemo(
    () => rootNode?.content_similar?.output || defaults?.output,
    [defaults, rootNode]
  );
  const scoreType = useMemo(
    () => rootNode?.content_similar?.scoreType || defaults?.score?.type,
    [defaults, rootNode]
  );
  const maxScore = useMemo(
    () => rootNode?.content_similar?.maxScore || defaults?.score?.value,
    [defaults, rootNode]
  );
  const resultsNum = useMemo(
    () =>
      get(rootNode, `${basePath}.numResults`) || defaults?.numElements?.value,
    [defaults, rootNode]
  );

  const outputRef = useRef(fieldOutput);

  const [contentIncludeMeta, setContentIncludeMeta] = useState("");
  const [contentExcludeMeta, setContentExcludeMeta] = useState("");

  const contentGroupMeta = useMemo(
    () => get(rootNode, `${basePath}.groupby.metadata`) || "",
    [rootNode]
  );

  const contentGroupSortMeta = useMemo(
    () => get(rootNode, `${basePath}.groupby.sortMetadata`) || "",
    [rootNode]
  );

  const contentGroupSortDirMeta = useMemo(
    () => get(rootNode, `${basePath}.groupby.sortDirection`) || "asc",
    [rootNode]
  );

  const contentGroupNumResultsMeta = useMemo(
    () => get(rootNode, `${basePath}.groupby.results`) || 1,
    [rootNode]
  );

  // ****************************************************************************************
  // Handle Required and Foldable States
  // ****************************************************************************************
  const required = useMemo(
    () => get(metadata, "content_similar.required"),
    [metadata]
  );
  const foldable = useMemo(
    () =>
      (permissions.expert.includes("metadata-similar-content") && required) ===
      true,
    [required, permissions.expert]
  );

  const handleRequired = useCallback(
    (e) => {
      const required = e.target.checked;
      updateMetadata("content_similar.required", required);
    },
    [updateMetadata]
  );

  useEffect(() => {
    if (!required && !folded) setFolded(true);
  }, [folded, required]);
  // ****************************************************************************************

  const removeContentIncExcMetadataIndex = (index, incExc) => {
    const key = `${basePath}.metadata.${incExc}`;
    const newValues = [...(get(metadata, key) || [])];
    newValues.splice(index, 1);

    updateMetadata(key, newValues);
  };

  const updateContentIncExcMetadataIndex = (index, value, incExc) => {
    const key = `${basePath}.metadata.${incExc}`;
    const newValues = [...(get(metadata, key) || [])];
    newValues[index] = value;

    updateMetadata(key, newValues);
  };
  const addContentIncExcMetadata = (incExc) => {
    const key = `${basePath}.metadata.${incExc}`;
    const values = cloneDeep(get(metadata, key) || []);
    if (incExc === "included") {
      contentIncludeMeta.length && values.push(contentIncludeMeta);
      setContentIncludeMeta("");
    }
    if (incExc === "excluded") {
      contentExcludeMeta.length && values.push(contentExcludeMeta);
      setContentExcludeMeta("");
    }
    updateMetadata(key, values);
  };

  const updateGroup = useCallback(
    (type, value) => {
      const groupby = {
        metadata: contentGroupMeta,
        results: contentGroupNumResultsMeta,
        sortMetadata: contentGroupSortMeta,
        sortDirection: contentGroupSortDirMeta,
      };

      switch (type) {
        case "reset": {
          groupby.metadata = "";
          groupby.sortMetadata = "";
          groupby.sortDirection = "";
          groupby.results = 0;

          break;
        }
        case "metadata": {
          groupby.metadata = value;
          break;
        }
        case "sort": {
          const [meta, dir] = value;
          groupby.sortMetadata = meta;
          groupby.sortDirection = dir;
          break;
        }

        case "results": {
          value = Math.trunc(value);
          groupby.results = value;
          break;
        }
        default: {
        }
      }

      updateMetadata(`${basePath}.groupby`, groupby);
    },
    [
      contentGroupMeta,
      contentGroupNumResultsMeta,
      contentGroupSortDirMeta,
      contentGroupSortMeta,
      updateMetadata,
    ]
  );

  const updateOrigin = useCallback(
    (what, value) => {
      let key;
      if (!required) return;

      switch (what) {
        case "output": {
          key = `content_similar.output`;
          value = value.replace(" ", "_").toLowerCase();

          outputRef.current = value;
          break;
        }
        case "results": {
          key = "content_similar.numResults";
          break;
        }
        case "scoreType": {
          key = "content_similar.scoreType";
          break;
        }
        case "maxScore": {
          key = "content_similar.maxScore";
          break;
        }

        default: {
        }
      }

      updateMetadata(key, value);
    },
    [required, updateMetadata]
  );

  const resetTimeRange = useCallback(() => {
    setImmediate(() => {
      updateMetadata(`${basePath}.date`, undefined);
    });
  }, [updateMetadata]);

  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  const handleUpdateOutPutField = useCallback(
    (v) => updateOrigin("output", v),
    [updateOrigin]
  );
  const handleUpdateIncludeMeta = useCallback(
    (e) => setContentIncludeMeta(e),
    []
  );
  const handleUpdateDatePeriod = useCallback(
    (e) => updateMetadata("content_similar.date.period", e.target.value),
    [updateMetadata]
  );
  const handleUpdateDateField = useCallback(
    (value) => updateMetadata("content_similar.date.field", value),
    [updateMetadata]
  );
  const handleUpdateDateAbsolute = useCallback(
    (e) => updateMetadata(`content_similar.date.absolute`, e.target.checked),
    [updateMetadata]
  );

  const excludeExcluded = useMemo(
    () =>
      metadata?.content_similar?.metadata?.excluded?.map(
        (e) => e.split("=")[0]
      ) || [],
    [metadata.content_similar]
  );

  const includeExcluded = useMemo(
    () =>
      metadata?.content_similar?.metadata?.included?.map(
        (e) => e.split("=")[0]
      ) || [],
    [metadata.content_similar]
  );

  const accessorIsEmpty = useCallback(
    (accessor) => {
      if (accessor === "exclude")
        return !rootNode?.content_similar?.metadata?.excluded?.length > 0;
      if (accessor === "filter")
        return !rootNode?.content_similar?.metadata?.included?.length > 0;
    },
    [
      rootNode?.content_similar?.metadata?.excluded?.length,
      rootNode?.content_similar?.metadata?.included?.length,
    ]
  );

  const excludeHeader = useMemo(() => {
    if (accessorIsEmpty("exclude") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) =>
          updateMetadata(`${basePath}.mustNotOperator`, value)
        }
        onChangeBreaker={(value) =>
          updateMetadata(`${basePath}.mustNotBreaker`, value)
        }
        operator={rootNode?.content_similar?.mustNotOperator}
        breaker={rootNode?.content_similar?.mustNotBreaker}
        includeFolded={foldedHeader.get("exclude")}
        defaultBreaker={queryDefaults?.general?.tieBreaker}
      />
    );
  }, [
    accessorIsEmpty,
    foldedHeader,
    queryDefaults?.general?.tieBreaker,
    queryExpert,
    rootNode?.content_similar?.mustNotBreaker,
    rootNode?.content_similar?.mustNotOperator,
    updateMetadata,
  ]);

  const filterHeader = useMemo(() => {
    if (accessorIsEmpty("filter") || !queryExpert) return null;

    return (
      <AndOrSelector
        onChangeOperator={(value) =>
          updateMetadata(`${basePath}.filterOperator`, value)
        }
        onChangeBreaker={(value) =>
          updateMetadata(`${basePath}.filterBreaker`, value)
        }
        operator={rootNode?.content_similar?.filterOperator}
        breaker={rootNode?.content_similar?.filterBreaker}
        includeFolded={foldedHeader.get("filter")}
        defaultBreaker={queryDefaults?.general?.tieBreaker}
      />
    );
  }, [
    accessorIsEmpty,
    foldedHeader,
    queryDefaults?.general?.tieBreaker,
    queryExpert,
    rootNode?.content_similar?.filterBreaker,
    rootNode?.content_similar?.filterOperator,
    updateMetadata,
  ]);

  return (
    <Container
      folded={folded} //false
      foldable={foldable}
      onFold={setFolded}
      coloredBars={!folded}
      color="#030b0e"
      margin="4px"
      warning={errors?.[rootNodeName]?.content_similar}
      title={
        <Form.Check
          type="switch"
          checked={get(metadata, "content_similar.required") || false}
          onChange={handleRequired}
          onBlur={handleBlur}
          id={`${rootNodePath}content_similar.required-${uniqueId}`}
          value="true"
          name={`${rootNodePath}content_similar.required`}
          label={<>Content Pack</>}
          disabled={blockFields}
        />
      }
      help="meta-content-pack"
    >
      <div className="content-similar">
        <div className="originBox">
          <Row>
            <Col sm="4">
              <div className="block-label">Output Metadata</div>
              <OutPutField
                data={rootNode?.content_similar?.output}
                defaultData={defaults?.output}
                width={400}
                disableEdit={blockFields}
                realm={"content_similar"}
                catalog={contentType}
                updateMetadata={handleUpdateOutPutField}
                required={required}
              />
            </Col>

            <Col sm="3">
              <div className="block-label">Score</div>
              <Form.Control
                as="select"
                className="score-label"
                value={scoreType || ""}
                onChange={(e) => updateOrigin("scoreType", e.target.value)}
              >
                <option value="percentage">Percentage</option>
                <option value="positional">Positional</option>
                <option value="origin">Origin</option>
              </Form.Control>
            </Col>
            <Col sm={3}>
              <div className="rotary-output">
                {scoreType === "percentage" && (
                  <RotaryKnob
                    value={Number(maxScore) || 0}
                    min={Number(defaults?.score?.min) || 0}
                    max={Number(defaults?.score?.max) || 100}
                    step={1}
                    label="Score Max"
                    labelPosition="top"
                    skin="s10"
                    onChange={(v) => updateOrigin("maxScore", v)}
                    className="knob-group-generic"
                  />
                )}

                <RotaryKnob
                  value={resultsNum}
                  min={defaults?.numElements?.min || 0}
                  max={defaults?.numElements?.max || 100}
                  step={1}
                  label="Results"
                  labelPosition="top"
                  skin="s10"
                  onChange={(v) => updateOrigin("results", v)}
                  className="knob-group-generic"
                />
              </div>
            </Col>
          </Row>
        </div>
        <Container
          title="Keep Only"
          help="meta-similar-keep-only"
          foldable={true}
          folded={false}
          onFold={(status) => onFold("filter", status)}
          color="#015301"
          coloredBars={false}
          coloredTitle={false}
          warning={errors?.[rootNodeName]?.keepOnly}
          header={filterHeader}
          headerOnlyOnFolded={false}
          extra={
            <FontAwesomeIcon
              icon={faPowerOff}
              onClick={resetTimeRange}
              className="power-off-icon"
            />
          }
        >
          <div id="content-similar-include-metadata">
            <Row>
              <Col sm="1">Metadata</Col>
              <Col sm="4">
                <SelectMeta
                  name="contentIncludeMetadata"
                  value={contentIncludeMeta}
                  handleChange={handleUpdateIncludeMeta}
                  handleBlur={handleBlur}
                  catalogs={catalogs}
                  realms={realms}
                  excludes={includeExcluded}
                />
              </Col>
              <Col sm="1" className="colum-icon">
                <Button
                  onClick={() => addContentIncExcMetadata("included")}
                  variant="outline-primary"
                  size="sm"
                >
                  <FontAwesomeIcon icon={faPlusSquare} className="add-icon" />{" "}
                  Add
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 11, offset: 1 }}>
                {has(metadata, "content_similar.metadata.included") && (
                  <div className="metadata-box">
                    {metadata.content_similar.metadata.included.map(
                      (m, index) => {
                        const length =
                          metadata.content_similar.metadata.included.length;
                        const addOperator = length > 1 && index !== length - 1;

                        return (
                          <>
                            <Meta
                              field={m}
                              index={index}
                              contentType={contentType}
                              type="included"
                              variant="outline-success"
                              operator={
                                metadata.content_similar.filterOperator ?? "and"
                              }
                              remove={removeContentIncExcMetadataIndex}
                              update={updateContentIncExcMetadataIndex}
                              excluded={
                                metadata.content_similar.metadata.included || []
                              }
                              excludeFallbackMetadata={excludeFallbackMetadata}
                              key={`${index}-${m}`}
                            />
                            {addOperator && (
                              <div className="formula-operator">
                                {metadata.content_similar.filterOperator ??
                                  "and"}
                              </div>
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>

          <div className="content-similar-date-range">
            <Row>
              <Col sm="1">Range</Col>
              <Col sm="7">
                <div className="period-box">
                  <RotaryKnob
                    value={Number(
                      get(metadata, "content_similar.date.range") || 0
                    )}
                    min={0}
                    max={1000}
                    step={1}
                    label="Period"
                    labelPosition="top"
                    skin="s10"
                    onChange={(v) =>
                      updateMetadata("content_similar.date.range", v)
                    }
                    className="knob-group-period"
                  />

                  <Form.Control
                    as="select"
                    name={`${rootNodePath}content_similar.date.period`}
                    value={get(metadata, "content_similar.date.period") || ""}
                    onChange={handleUpdateDatePeriod}
                    onBlur={handleBlur}
                    style={styleSelect}
                  >
                    <option></option>
                    <option value="s">Seconds</option>
                    <option value="m">Minute</option>
                    <option value="h">Hour</option>
                    <option value="d">Day</option>
                    <option value="w">Week</option>
                    <option value="M">Month</option>
                    <option value="y">Year</option>
                  </Form.Control>

                  <SelectMeta
                    isErrored={has(
                      errors,
                      `${rootNodeName}.content_similar.date.field`
                    )}
                    name={`${rootNodePath}content_similar.date.field`}
                    value={get(metadata, "content_similar.date.field") || ""}
                    handleChange={handleUpdateDateField}
                    handleBlur={handleBlur}
                    catalogs={catalogs}
                    realms={realms}
                    types="date"
                    style={styleSelect}
                  />

                  <Form.Check
                    type="switch"
                    id={`${rootNodePath}content_similar_date_absolute-${uniqueId}`}
                    checked={
                      get(metadata, `content_similar.date.absolute`) || false
                    }
                    value="true"
                    onChange={handleUpdateDateAbsolute}
                    onBlur={handleBlur}
                    label="Absolute"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="error-box">
                  <ErrorMessage
                    name={`${rootNodePath}content_similar.date.period`}
                  />
                  <ErrorMessage
                    name={`${rootNodePath}content_similar.date.field`}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Container
          title="Exclude"
          help="meta-similar-exclude"
          foldable={true}
          folded={false}
          onFold={(status) => onFold("exclude", status)}
          color="#5a0000"
          coloredBars={false}
          coloredTitle={false}
          warning={errors?.[rootNodeName]?.excluded}
          header={excludeHeader}
          headerOnlyOnFolded={false}
        >
          <div className="form-group row">
            <Col sm="1">Metadata</Col>
            <Col sm="4">
              <SelectMeta
                name="contentIncludeMetadata"
                value={contentExcludeMeta}
                handleChange={setContentExcludeMeta}
                handleBlur={handleBlur}
                catalogs={catalogs}
                realms={realms}
                excludes={excludeExcluded}
              />
            </Col>
            <Col sm="2" className="colum-icon">
              <Button
                onClick={() => addContentIncExcMetadata("excluded")}
                variant="outline-primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faPlusSquare} className="add-icon" /> Add
              </Button>
            </Col>
          </div>
          <Row>
            <Col sm={{ span: 11, offset: 1 }}>
              {has(metadata, "content_similar.metadata.excluded") && (
                <div className="metadata-box">
                  {metadata.content_similar.metadata.excluded.map(
                    (m, index) => {
                      const length =
                        metadata.content_similar.metadata.excluded.length;
                      const addOperator = length > 1 && index !== length - 1;

                      return (
                        <>
                          <Meta
                            field={m}
                            index={index}
                            contentType={contentType}
                            type="excluded"
                            variant="outline-danger"
                            remove={removeContentIncExcMetadataIndex}
                            update={updateContentIncExcMetadataIndex}
                            excluded={
                              metadata.content_similar.metadata.excluded || []
                            }
                            excludeFallbackMetadata={excludeFallbackMetadata}
                            key={m}
                          />
                          {addOperator && (
                            <div className="formula-operator">
                              {metadata.content_similar.mustNotOperator ??
                                "and"}
                            </div>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>

        {!excludeFallbackMetadata && (
          <Container
            title="Similarity"
            help="meta-similar-similarity"
            foldable={true}
            folded={false}
            color="#CCC"
            coloredBars={false}
            coloredTitle={true}
          >
            <Fingerprint
              data={get(metadata, "content_similar.fingerprint") || {}}
              update={(value) =>
                updateMetadata(`${basePath}.fingerprint`, value)
              }
              contentType={contentType}
            />
          </Container>
        )}

        <Container
          title="Group & Order"
          help="meta-similar-group-order"
          foldable={true}
          folded={false}
          color="#915e4d"
          coloredBars={false}
          coloredTitle={true}
          hoverFactor={0.2}
          extra={
            <FontAwesomeIcon
              icon={faPowerOff}
              onClick={() => updateGroup("reset")}
              className="power-off-icon"
            />
          }
        >
          <Row>
            <Col sm="1">GroupBy</Col>
            <Col sm="9">
              <GroupByBox
                value={contentGroupMeta}
                contentType={contentType}
                onChange={(e) => updateGroup("metadata", e)}
              />
            </Col>
            <Col sm="2">
              <RotaryKnob
                value={contentGroupNumResultsMeta}
                min={0}
                max={resultsNum}
                step={1}
                label="Results Per Group"
                labelPosition="right"
                skin="s10"
                onChange={(v) => updateGroup("results", v)}
                className="knob-group-results"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="1">OrderBy</Col>
            <Col sm="9">
              <OrderByBox
                sortValue={contentGroupSortMeta}
                directionValue={contentGroupSortDirMeta}
                contentType={contentType}
                update={(meta, dir) => {
                  updateGroup("sort", [meta, dir]);
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

function OrderByBox(props) {
  const { sortValue, directionValue, contentType, update } = props;
  const [metas, setMetas] = useState(sortValue?.split(","));
  const [directions, setDirections] = useState(directionValue?.split(","));
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    setMetas(sortValue?.split(","));
    setDirections(directionValue?.split(","));
  }, [directionValue, sortValue]);

  const metasLength = useMemo(() => metas.length, [metas]);

  const handleChange = useCallback(
    (what, value, index) => {
      const newMetas = [...metas];
      const newDirections = [...directions];

      if (what === "meta") {
        newMetas[index] = value;
        setMetas(newMetas);

        if (!newDirections[index]) {
          newDirections[index] = "asc";
          setDirections(newDirections);
        }
      } else {
        newDirections[index] = value ? "asc" : "desc";
        setDirections(newDirections);
      }
      update(newMetas.join(","), newDirections.join(","));
    },
    [directions, metas, update]
  );

  const handleAdd = useCallback(() => {
    const newMetas = [...metas];
    newMetas.push("");
    setMetas(newMetas);
  }, [metas]);

  const handleDelete = useCallback(
    (index) => {
      const newMetas = [...metas];
      const newDirections = [...directions];

      newMetas.splice(index, 1);
      newDirections.splice(index, 1);

      setMetas(newMetas);
      setDirections(newDirections);

      update(newMetas.join(","), newDirections.join(","));
    },
    [directions, metas, update]
  );

  return (
    <div className="order-by-box">
      {metas.map((meta, i) => (
        <div className="order-by-select-row" key={meta}>
          <div className="order-by-select">
            <SelectMeta
              name="contentIncludeMetadata"
              value={meta}
              handleChange={(e) => handleChange("meta", e, i)}
              catalogs={catalogs}
              realms={realms}
              excludes={metas}
            />
          </div>
          {meta && (
            <div className="order-by-switch">
              <Switch
                bsSize="small"
                value={directions[i] === "asc" ? true : false}
                onText="asc"
                onColor="warning"
                offText="desc"
                offColor="danger"
                onChange={(elm, state) => handleChange("dir", state, i)}
              />
            </div>
          )}

          {i !== 0 && (
            <Button
              variant="outline-danger"
              onClick={() => handleDelete(i)}
              style={styleInlineMarginRight}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {i + 1 === metasLength && meta && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleAdd}
              style={styleInline}
            >
              <FontAwesomeIcon icon={faPlusSquare} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

function GroupByBox(props) {
  const { value, contentType, onChange } = props;
  const [groups, setGroups] = useState(new Set(value?.split(",")));
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    setGroups(value?.split(","));
  }, [value]);

  const handleChange = useCallback(
    (value, index) => {
      setImmediate(() => {
        const newGroups = [...groups];
        newGroups[index] = value;
        setGroups(new Set(newGroups));

        if (onChange) onChange(newGroups.join(","));
      });
    },
    [groups, onChange]
  );
  const handleAdd = useCallback(() => {
    const newGroups = [...groups];
    newGroups.push("");
    setGroups(new Set(newGroups));
  }, [groups]);

  const handleDelete = useCallback(
    (index) => {
      const newGroups = [...groups];
      newGroups.splice(index, 1);
      setGroups(new Set(newGroups));
      if (onChange) onChange(newGroups.join(","));
    },
    [groups, onChange]
  );

  const groupsArray = useMemo(() => [...groups], [groups]);
  const groupsLength = useMemo(() => groupsArray.length, [groupsArray]);

  return (
    <div className="group-by-box">
      {groupsArray.map((value, i) => (
        <div className="group-by-select-row" key={value}>
          <div className="group-by-select">
            <SelectMeta
              name="groupbyselect"
              value={value}
              handleChange={(v) => handleChange(v, i)}
              catalogs={catalogs}
              realms={realms}
              excludes={groupsArray}
            />
          </div>
          {i !== 0 && (
            <Button
              variant="outline-danger"
              onClick={() => handleDelete(i)}
              style={styleInlineMarginRight}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {i + 1 === groupsLength && value && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleAdd}
              style={styleInline}
            >
              <FontAwesomeIcon icon={faPlusSquare} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

function Meta(props) {
  const {
    field,
    index,
    contentType,
    type,
    variant,
    remove,
    update,
    defaultLabel = "origin",
    excludeFallbackMetadata,
  } = props;
  const [extra, setExtra] = useState(false);
  const [key, value] = field.split("=");
  const [extraValue, setExtraValue] = useState(
    value
      ? value.split(" OR ").map((k) => k.replace("(", "").replace(")", ""))
      : [""]
  );

  const changeValue = useCallback(
    (index, value) => {
      const newValues = [...extraValue];
      newValues[index] = value;
      setExtraValue(newValues);
    },
    [extraValue]
  );

  const confirmValue = useCallback(() => {
    setExtra(false);
    if (extraValue && extraValue.filter(Boolean).length > 0) {
      let value;
      if (extraValue.length === 1) value = `${key}=${extraValue}`;
      else
        value = `${key}=${extraValue
          .filter((_) => _)
          .map((k) => `(${k})`)
          .join(" OR ")}`;

      update(index, value, type);
    } else {
      update(index, key, type);
    }
  }, [extraValue, index, key, type, update]);

  const resetValue = useCallback(() => {
    setExtra(false);
    update(index, key, type);
  }, [index, key, type, update]);

  const addValue = useCallback(() => {
    const newValues = [...extraValue];
    newValues.push("");
    setExtraValue(newValues);
  }, [extraValue]);

  const deleteValue = useCallback(
    (index) => {
      const newValues = [...extraValue];
      newValues.splice(index, 1);
      setExtraValue(newValues);
    },
    [extraValue]
  );

  return (
    <>
      {extra ? (
        <div className="from-extra">
          <div className="meta-value-label">{key}</div>
          {extraValue.map((value, index) => (
            <div className="meta-value-row" key={`${key}-${index}`}>
              <MetaValue
                contentType={contentType}
                meta={key}
                value={value}
                index={index}
                changeValue={changeValue}
                excludes={extraValue}
              />

              {index === extraValue.length - 1 && (
                <Button size="sm" variant="outline-primary">
                  <FontAwesomeIcon icon={faPlusSquare} onClick={addValue} />
                </Button>
              )}

              {index > 0 && (
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => deleteValue(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              )}
            </div>
          ))}

          <div className="confirmExtra">
            <Button size="sm" variant="outline-success" onClick={confirmValue}>
              OK
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={resetValue}
              style={styleMarginLeft}
            >
              Reset
            </Button>
          </div>
        </div>
      ) : (
        <span className="singleMeta">
          <Button size="sm" variant={variant} onClick={() => setExtra(true)}>
            {key}
            <SimpleValue
              value={value}
              defaultLabel={defaultLabel}
              excludeFallbackMetadata={excludeFallbackMetadata}
            />
            &nbsp;
            <span
              aria-hidden="true"
              onClick={() => remove(index, type)}
              className="close-x"
            >
              &times;
            </span>
          </Button>
        </span>
      )}
    </>
  );
}

function SimpleValue(props) {
  if (props.excludeFallbackMetadata && !props.value)
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="icon-no-origin"
      />
    );

  const value = props.value || props.defaultLabel;
  const splitted = value
    .split(" OR ")
    .map((k) => k.replace("(", "").replace(")", ""));

  return (
    <span className="meta-from">
      {splitted.map((v) => (
        <span className="singleValue" key={v}>
          {v}
        </span>
      ))}
    </span>
  );
}
function MetaValue(props) {
  const { contentType, meta, value, index, changeValue, excludes } = props;
  const fieldValues = useMetadataValues(contentType, meta);

  return (
    <Fragment>
      {fieldValues && fieldValues.length > 0 ? (
        <ValuesSelect
          debugLabel="from-extra"
          selectOptions={fieldValues}
          errored={false}
          name={meta}
          handleChange={(value) => changeValue(index, value)}
          value={value || ""}
          excludes={excludes}
          style={styleSelect}
        />
      ) : (
        <Form.Control
          type="text"
          onChange={(e) => changeValue(index, e.target.value)}
          value={value || ""}
        />
      )}
    </Fragment>
  );
}

function hashFingerprint(arr) {
  let ret = [];
  arr.forEach((values) => {
    const [key, value] = values.split("=");
    if (value) ret.push(md5(`${key}${value}`));
  });
  return ret.join(" ");
}

const emptyArray = [];
function FingerprintFields(props) {
  const { data, contentType, defaults, dispatch } = props;
  const [fields, setFields] = useState(data || emptyArray); //useState(data || defaults);
  const [value, setValue] = useState();
  const [realms, catalogs] = useMemo(() => {
    return [["content"], [contentType]];
  }, [contentType]);

  useEffect(() => {
    const d = data || emptyArray;
    if (!isEqual(d, fields)) {
      setFields(d);
    }
  }, [data, fields]);

  const remove = useCallback(
    (index) => {
      let newFields = [...fields];
      newFields.splice(index, 1);
      //if (newFields.length === 0) newFields = defaults;

      setFields(newFields);
      dispatch({ type: "matchFields", value: newFields });
    },
    [dispatch, fields]
  );

  const add = useCallback(() => {
    if (value) {
      const newFields = [...fields];
      newFields.push(value);
      setFields(newFields);
      setValue(null);
      dispatch({ type: "matchFields", value: newFields });
    }
  }, [dispatch, fields, value]);

  return (
    <>
      <Row>
        <Col sm="1">Metadata</Col>
        <Col sm="4">
          <SelectMeta
            name="FingerprintFields"
            value={value}
            handleChange={setValue}
            contentType={contentType}
            catalogs={catalogs}
            realms={realms}
            excludes={fields}
            types="string"
          />
        </Col>
        <Col className="colum-icon">
          <Button size="sm" variant="outline-primary" onClick={add}>
            <FontAwesomeIcon icon={faPlusSquare} /> Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 11, offset: 1 }} className="fields">
          {(fields || defaults).map((field, index) => (
            <div className="singleMeta" key={`${index}-${field}`}>
              <Button size="sm" variant="outline-success">
                {field}{" "}
                <span
                  aria-hidden="true"
                  onClick={() => remove(index)}
                  className="close-x"
                >
                  &times;
                </span>
              </Button>
            </div>
          ))}
          <div className="spacer" />
        </Col>
      </Row>
    </>
  );
}

function Fingerprint(props) {
  const [currentUnlike, setCurrentUnlike] = useState("");
  const [unlikes, setUnlikes] = useState(props.data?.unlikesClean || []);
  const kDef = useDataSettings(
    (settings) =>
      settings.contentSimilar?.[props.contentType]?.fingerprint || {}
  );
  const defaultMatchFields = useDataSettings(
    (settings) =>
      settings.contentSimilar?.[props.contentType]?.matchFields || []
  );

  const [data, dispatch] = useReducer((state, action) => {
    const newState = { ...state };

    if (action.type === "clean") {
      setCurrentUnlike("");
      setUnlikes(action.value.unlikesClean || []);
      return action.value;
    }

    if (action.type === "unlike") {
      if (action.index !== undefined) {
        if (action.value !== undefined) unlikes[action.index] = action.value;
        else unlikes.splice(action.index, 1);
      } else if (currentUnlike) {
        unlikes.push(currentUnlike);
        setCurrentUnlike("");
      }
      setUnlikes(unlikes);
      newState["unlikesClean"] = unlikes;
      const hashed = hashFingerprint(unlikes);
      if (hashed) newState["unlikes"] = hashed;
    } else {
      newState[action.type] = action.value;
    }

    if (props.update) props.update(newState);
    return newState;
  }, props.data || {});

  useEffect(() => {
    if (props.data && !isEqual(props.data, data)) {
      dispatch({ type: "clean", value: props.data });
    }
  }, [data, props.data]);

  const canUnlike = useMemo(() => {
    if (data.matchFields) {
      return data.matchFields.every((k) => defaultMatchFields.includes(k));
    }

    return true;
  }, [data.matchFields, defaultMatchFields]);

  const catalogs = useMemo(() => [props.contentType], [props.contentType]);
  const realms = useMemo(() => ["content"], []);
  const excludes = useMemo(
    () => unlikes.map((u) => u.split("=")[0]),
    [unlikes]
  );

  return (
    <div className="fingerprint-container">
      <FingerprintFields
        data={data.matchFields}
        contentType={props.contentType}
        defaults={defaultMatchFields}
        dispatch={dispatch}
      />

      {data.matchFields?.length > 0 ? (
        <Col sm={{ span: 11, offset: 1 }}>
          <div className="fingerprint-kobs">
            <RotaryKnob
              value={data.boost || kDef.boost?.value}
              min={kDef.boost.min}
              max={kDef.boost.max}
              step={1}
              label="Boost"
              skin="s11"
              onChange={(value) => dispatch({ type: "boost", value })}
            />
            <RotaryKnob
              value={data.minTerms || kDef.minTerms?.value}
              min={kDef.minTerms?.min}
              max={kDef.minTerms?.max}
              step={1}
              label="Min Terms"
              skin="s12"
              onChange={(value) => dispatch({ type: "minTerms", value })}
            />
            <RotaryKnob
              value={data.maxTerms || kDef.maxTerms?.value}
              min={kDef.maxTerms?.min}
              max={kDef.maxTerms?.max}
              step={1}
              label="Max Terms"
              skin="s12"
              onChange={(value) => dispatch({ type: "maxTerms", value })}
            />
            <RotaryKnob
              value={data.minDocs || kDef.minDocs?.value}
              min={kDef.minDocs?.min}
              max={kDef.minDocs?.max}
              step={1}
              label="Min Docs"
              onChange={(value) => dispatch({ type: "minDocs", value })}
            />
            <RotaryKnob
              value={data.maxDocs || kDef.maxDocs?.value}
              min={kDef.maxDocs?.min}
              max={kDef.maxDocs?.max}
              clickStep={100}
              step={1}
              divider={10000}
              label={`Max Docs ${
                data.maxDocs ? intToString(data.maxDocs) : ""
              }`}
              skin="s13"
              onChange={(value) => dispatch({ type: "maxDocs", value })}
            />
          </div>

          {canUnlike ? (
            <div className="fingerprint-unlike">
              <div className="title">Excludes Key-Value Pairs</div>
              <div className="selectContainer">
                <div className="selectMeta">
                  <SelectMeta
                    name="contentIncludeMetadata"
                    value={currentUnlike}
                    handleChange={(e) => setCurrentUnlike(e)}
                    catalogs={catalogs}
                    realms={realms}
                    style={styleSelect}
                    excludes={excludes}
                  />
                </div>
                <div className="addBtn">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => dispatch({ type: "unlike" })}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} /> Add
                  </Button>
                </div>
              </div>
              <div className="unlikes">
                {unlikes.map((unlike, index) => (
                  <Meta
                    field={unlike}
                    index={index}
                    contentType={props.contentType}
                    type="excluded"
                    variant="outline-danger"
                    remove={(index) => dispatch({ type: "unlike", index })}
                    update={(index, value) =>
                      dispatch({ type: "unlike", index, value })
                    }
                    key={unlike}
                    defaultLabel="TBD"
                  />
                ))}
              </div>
            </div>
          ) : null}
        </Col>
      ) : null}
    </div>
  );
}
