import { useCallback, useRef, useMemo, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Link from "../link";
import { useDataRefObj, useDataRefItem } from "../../hooks/useDataRef";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleQuestion,
  faSliders,
  faUpRightFromSquare,
  faShieldAlt,
  faBrain,
  faArrowsUpToLine,
  faArrowsDownToLine,
  faCircleCheck,
  faCircle,
  faAsterisk,
  faAnglesRight,
  faCalendar,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import Editor from "@monaco-editor/react";
import Tabs, { TabPane } from "rc-tabs";
import toWords from "split-camelcase-to-words";
import pick from "lodash.pick";
import { blockMetaNotFallback } from "../../db/refManager";
import Container from "../container";

import "../../scss/tabs.scss";
import styles from "./styles.module.scss";

const refColors = {};

function sortObj(obj) {
  if (!obj) return {};

  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

function groupRefs(data) {
  const ret = {};

  data.forEach((d) => {
    if (!ret[d.type]) ret[d.type] = [];
    ret[d.type].push(d);
  });

  return sortObj(ret);
}

const LinkIcon = () => <FontAwesomeIcon icon={faUpRightFromSquare} className={styles.icon} />;

function RefLink({ info }) {
  if (!info) return null;

  return info.titlePath ? (
    <Link
      to={`/property/${info.titlePathRef[0]}/endpoint/${info.titlePathRef[1]}/variant/${info.titlePathRef[2]}`}
      className={styles.linkIcon}
    >
      <LinkIcon />
    </Link>
  ) : (
    <Link to={`/${info.type}/${info.id}`} className={styles.linkIcon}>
      <LinkIcon />
    </Link>
  );
}

function InfoIcon({ warning, showInfo = true }) {
  return (
    <span>
      {warning ? (
        <FontAwesomeIcon icon={faTriangleExclamation} className={styles.itemWarning} />
      ) : (
        <>
          {showInfo ? (
            <FontAwesomeIcon icon={faCircleQuestion} className={styles.itemTree} />
          ) : null}
        </>
      )}
    </span>
  );
}

export default function RefBox({ accessor, formikRef, data }) {
  const staticInfo = useDataRefItem(accessor, data.id);
  const liveInfo = useDataRefObj(accessor, data);
  const info = useMemo(
    () => ({ ...liveInfo, ...pick(staticInfo, ["uselessMultiplier"]) }),
    [staticInfo, liveInfo]
  );

  if (!info) return null;

  return (
    <OverlayTrigger
      trigger="click"
      key={`refbox-${accessor}`}
      placement="bottom"
      overlay={
        <Popover id={`refbox-positioned-bottom`} bsPrefix="ares-refbox-pop" placement="bottom">
          <RefEditor info={info} formik={formikRef?.current} />
        </Popover>
      }
    >
      <span>
        <InfoIcon warning={info.warnings} />
      </span>
    </OverlayTrigger>
  );
}

export function RefBoxId({ accessor, id, belongs, require }) {
  const info = useDataRefItem(accessor, id);

  if (!info) return null;

  return (
    <OverlayTrigger
      trigger="click"
      key={`refbox-${accessor}`}
      placement="bottom"
      overlay={
        <Popover id={`refbox-positioned-bottom`} bsPrefix="ares-refbox-pop" placement="bottom">
          <RefEditor info={info} />
        </Popover>
      }
    >
      <div
        className={`${styles.refBadge} ${info.warnings?.length > 0 ? styles.refBadgeWarning : ""}`}
      >
        <span className={styles.belongs}>{belongs}</span>
        <span className={styles.pipe}>
          {info.warnings?.length > 0 ? (
            <FontAwesomeIcon icon={faTriangleExclamation} className={styles.warn} />
          ) : (
            "|"
          )}
        </span>
        <span className={styles.require}>{require}</span>
      </div>
    </OverlayTrigger>
  );
}

const fixableTypes = ["blocks"];

function WarningPane({ formik, info }) {
  const [isFallback, setIsFallback] = useState(formik?.values?.canBeFallback ?? info?.isFallback);
  const [isSmart, setIsSmart] = useState(formik?.values?.canBeSmart ?? info?.isSmart);
  const [isUserBased, setIsUserBased] = useState(formik?.values?.userBased ?? info?.isUserBased);

  const fixIt = useCallback(() => {
    formik.setFieldValue("canBeFallback", isFallback);
    formik.setFieldValue("canBeSmart", isSmart);
    formik.setFieldValue("userBased", isUserBased);
  }, [formik, isFallback, isSmart, isUserBased]);

  return (
    <>
      {info.warnings && (
        <div className={styles.warning}>
          <div className={styles.label}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={styles.itemWarning} /> This
            item has an incongruent configuration
          </div>
          <div className={styles.explanation}>
            {info.warnings.map((warn, index) => (
              <div key={`warn-${index}`} className={styles.warn}>
                {warn}
              </div>
            ))}
          </div>

          {fixableTypes.includes(info.type) && (
            <div className={styles.fixBox}>
              <div className={styles.checks}>
                <Form.Check
                  type="checkbox"
                  label="Is Fallback"
                  inline
                  checked={isFallback}
                  disabled={!formik}
                  onChange={(e) => setIsFallback(e.target.checked ? true : false)}
                />
                <Form.Check
                  type="checkbox"
                  label="Is Smart Block"
                  inline
                  checked={isSmart}
                  disabled={!formik}
                  onChange={(e) => setIsSmart(e.target.checked ? true : false)}
                />
                <Form.Check
                  type="checkbox"
                  label="Is User Based"
                  inline
                  checked={isUserBased}
                  disabled={!formik}
                  onChange={(e) => setIsUserBased(e.target.checked ? true : false)}
                />
              </div>
              {formik && (
                <Button size="sm" variant="success" onClick={fixIt} className={styles.fixBtn}>
                  Fix It
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

function JSONPane({ data }) {
  const editorRef = useRef();

  const onMount = useCallback((editor, monaco) => {
    editor.trigger("fold", "editor.foldLevel2");
    editor.setScrollPosition({ scrollTop: 0 });
    editor.revealLine(1);
    editorRef.current = editor;
  }, []);

  return (
    <div className={styles.editor}>
      <Editor
        width="100%"
        height="500px"
        defaultLanguage="json"
        value={JSON.stringify(data ?? {}, null, 1)}
        defaultValue="// No Data Are you in DEV?"
        theme="vs-dark"
        onMount={onMount}
        options={{ minimap: { enabled: false }, folding: true }}
      />
    </div>
  );
}

function RefSubPane({ data, setWarning }) {
  const info = useDataRefItem(data.type, data.id);

  useEffect(() => {
    if (info?.warnings) setWarning(true);
  }, [info?.warnings, setWarning]);

  return (
    <Container
      key={data.id}
      title={
        <span>
          {data.titlePath ? data.titlePath.join(" ▸ ") : data.title}
          <RefLink info={data} />
        </span>
      }
      folded={true}
      foldable={true}
      className={styles.groupRefSubPane}
      extra={<InfoIcon warning={info?.warnings} showInfo={false} />}
    >
      <div className={styles.subBox}>
        <RefEditor info={info} />
      </div>
    </Container>
  );
}

function RefContainer({ title, refs }) {
  const [warning, setWarning] = useState(false);
  return (
    <Container
      key={title}
      title={
        <span>
          {title}
          <span className={styles.refLength}>{refs.length}</span>
        </span>
      }
      folded={true}
      foldable={true}
      color={refColors[title]}
      hideBodyOnFold={true}
      className={styles.groupRefPane}
      extra={<InfoIcon warning={warning} showInfo={false} />}
    >
      <div className={styles.scrollBox}>
        {refs.map((data) => (
          <RefSubPane key={`${data.type}-${data.id}`} data={data} setWarning={setWarning} />
        ))}
      </div>
    </Container>
  );
}

export function RefPane({ data = [] }) {
  if (!data.length > 0) return null;
  return (
    <div className={styles.groupRefBox}>
      {Object.entries(groupRefs(data)).map(([title, refs]) => {
        return <RefContainer key={title} title={title} refs={refs} />;
      })}
    </div>
  );
}

function ValueIcon({ data }) {
  if (Array.isArray(data)) return <div className={styles.badgeIcon}>{data.length}</div>;
  return data ? (
    <FontAwesomeIcon icon={faCircleCheck} className={styles.okIcon} />
  ) : (
    <FontAwesomeIcon icon={faCircle} className={styles.koIcon} />
  );
}

function SynthElement({ info, chiave, keyStyle, useValueIcon = true }) {
  const value = info[chiave];
  const stile = Array.isArray(keyStyle) ? (value ? keyStyle[0] : keyStyle[1]) : keyStyle;

  if (value === undefined || (Array.isArray(value) && value.length === 0)) return null;
  return (
    <span className={styles.metaElement}>
      <div className={styles.key} style={stile}>
        {toWords(chiave)}
      </div>
      <div className={styles.value}>
        {useValueIcon ? (
          <ValueIcon data={value} />
        ) : Array.isArray(value) ? (
          value.map((v, index) => (
            <span key={`${index}-${v}`} className={styles.subValue}>
              {v}
            </span>
          ))
        ) : (
          <span key={value} className={styles.subValue}>
            value
          </span>
        )}
      </div>
    </span>
  );
}

function SynthList({ info, keys, keyStyle }) {
  return (
    <div className={styles.list}>
      {keys.map((key, index) => {
        return (
          <div key={`${index}-${key}`} className={styles.row}>
            <SynthElement info={info} chiave={key} keyStyle={keyStyle} />
          </div>
        );
      })}
    </div>
  );
}

function haveSomeKeys(data, keys = []) {
  for (const key of keys) {
    if (data[key] !== undefined) return true;
  }
  return false;
}

const fallbacksKeys = [
  "isFallback",
  "canBeFallback",
  "isInFallback",
  "isUsedAsFallback",
  "usedInFallback",
  "requireFallbacks",
];

const smartKeys = [
  "isSmart",
  "useSmartBlocks",
  "isUsedInSmartBlockContainer",
  "belongsSmartBlockContainers",
];

const infoBoxHeaderKeyStyle = { width: "auto", marginRight: "5px" };
const infoBoxHeaderLoopStyle = { width: "auto", marginRight: "5px", color: "#f00" };
const fallbackBoxKeyStyle = { width: "130px" };
const smartBoxKeyStyle = { width: "200px" };

function SynthPane({ info }) {
  return (
    <div className={styles.synthPane}>
      {info.metadata?.length > 0 && (
        <Container
          title="Metadata"
          folded={true}
          foldable={false}
          headerOnlyOnFolded={false}
          className={styles.container}
          header={
            <div className={styles.infoMetaBox}>
              {info.metadata.map((meta, index) => (
                <div
                  key={`${index}-${meta}`}
                  className={
                    blockMetaNotFallback.includes(meta) ? styles.metadataHot : styles.metadata
                  }
                >
                  {meta}
                </div>
              ))}
            </div>
          }
        />
      )}
      <Container
        title="Info"
        folded={true}
        foldable={false}
        headerOnlyOnFolded={false}
        className={styles.container}
        header={
          <div className={styles.infoBox}>
            <SynthElement info={info} chiave={"restricted"} keyStyle={infoBoxHeaderKeyStyle} />
            <SynthElement info={info} chiave={"scheduled"} keyStyle={infoBoxHeaderKeyStyle} />
            <SynthElement info={info} chiave={"isInPage"} keyStyle={infoBoxHeaderKeyStyle} />
            <SynthElement info={info} chiave={"isUserBased"} keyStyle={infoBoxHeaderKeyStyle} />
            {/* <SynthElement
              info={info}
              chiave={"isInLoop"}
              keyStyle={[infoBoxHeaderLoopStyle, infoBoxHeaderKeyStyle]}
            /> */}

            <SynthElement
              info={info}
              chiave={"catalogs"}
              keyStyle={infoBoxHeaderKeyStyle}
              useValueIcon={false}
            />
          </div>
        }
      ></Container>

      {haveSomeKeys(info, fallbacksKeys) && (
        <Container title="Fallback" folded={false} foldable={false} className={styles.container}>
          <SynthList info={info} keys={fallbacksKeys} keyStyle={fallbackBoxKeyStyle} />
        </Container>
      )}
      {haveSomeKeys(info, smartKeys) && (
        <Container title="Smart" folded={false} foldable={false} className={styles.container}>
          <SynthList info={info} keys={smartKeys} keyStyle={smartBoxKeyStyle} />
        </Container>
      )}
    </div>
  );
}

export function RefEditor({ info, formik }) {
  const [currentTab, setCurrentTab] = useState("synth");

  if (!formik?.values && !info) return null;

  return (
    <div className={styles.editorBox}>
      <WarningPane formik={formik} info={info} />
      <Tabs
        tabBarGutter={0}
        activeKey={currentTab}
        onTabClick={setCurrentTab}
        moreIcon={<FontAwesomeIcon icon={faAnglesRight} />}
      >
        <TabPane
          key="synth"
          tab={
            <div className={styles.pageTab}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faCircleQuestion} className={styles.icon} />
              </span>
              <span className={styles.label}>Info</span>
            </div>
          }
        >
          <SynthPane info={info} />
        </TabPane>

        {info.belongsTree?.length > 0 && (
          <TabPane
            key="belongs"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faArrowsUpToLine} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Belongs<span className={styles.refLength}>{info.belongsTree.length}</span>
                </span>
              </div>
            }
          >
            <RefPane data={info.belongsTree} />
          </TabPane>
        )}
        {info.requireTree?.length > 0 && (
          <TabPane
            key="requires"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faArrowsDownToLine} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Requires <span className={styles.refLength}>{info.requireTree.length}</span>
                </span>
              </div>
            }
          >
            <RefPane data={info.requireTree} />
          </TabPane>
        )}
        {info.usedAsFallback?.length > 0 && (
          <TabPane
            key="asfallbacks"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faShieldAlt} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Used As Fallback{" "}
                  <span className={styles.refLength}>{info.usedAsFallback.length}</span>
                </span>
              </div>
            }
          >
            <RefPane data={info.usedAsFallback} />
          </TabPane>
        )}
        {info.usedInFallback?.length > 0 && (
          <TabPane
            key="infallbacks"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faShield} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Used In Fallbacks{" "}
                  <span className={styles.refLength}>{info.usedInFallback.length}</span>
                </span>
              </div>
            }
          >
            <RefPane data={info.usedInFallback} />
          </TabPane>
        )}
        {info.belongsSmartBlockContainers?.length > 0 && (
          <TabPane
            key="smarts"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faBrain} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Smart Containers
                  <span className={styles.refLength}>
                    {info.belongsSmartBlockContainers.length}
                  </span>
                </span>
              </div>
            }
          >
            <RefPane data={info.belongsSmartBlockContainers} />
          </TabPane>
        )}
        {info.requireScheduled?.length > 0 && (
          <TabPane
            key="scheduled"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faCalendar} className={styles.icon} />
                </span>
                <span className={styles.label}>
                  Scheduled
                  <span className={styles.refLength}>{info.requireScheduled.length}</span>
                </span>
              </div>
            }
          >
            <RefPane data={info.requireScheduled} />
          </TabPane>
        )}

        {formik?.values && (
          <TabPane
            key="raw"
            tab={
              <div className={styles.pageTab}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faSliders} className={styles.icon} />
                </span>
                <span className={styles.label}>Configuration</span>
              </div>
            }
          >
            <JSONPane data={formik?.values} />
          </TabPane>
        )}

        <TabPane
          key="info"
          tab={
            <div className={styles.pageTab}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faAsterisk} className={styles.icon} />
              </span>
              <span className={styles.label}>Raw Info</span>
            </div>
          }
        >
          <JSONPane data={sortObj(info)} />
        </TabPane>
      </Tabs>
    </div>
  );
}
