import { Fragment, useRef, useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { useDataSettings } from "../../hooks/useData";
import useDragAndDrop from "../../hooks/useDragAndDrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faShower, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Form, Row, Col, Button } from "react-bootstrap";
import Color from "color";
import Link from "../link";
import Boost from "./boost";
import DateRange from "./dateRange";
import Order from "./order";
import Metadata from "../metadata";
import Container from "../container";
import AndOrSelector from "./andOrSelector";

import { formatCondition } from "../conditionsBox";
import { formatStatement } from "../businessRule/rule";
import cloneDeep from "lodash.clonedeep";
import { colors } from "./query";
//import { db } from "../../db";

import styles from "./query.module.scss";
import "./style.scss";

export default function Rule(props) {
  const ref = useRef(null);
  const {
    id,
    rule,
    index,
    handleCleanRule,
    onMetadataChange,
    metadata,
    haveMetaOverrides,
    accessor,
    dataAccessor,
    style,
  } = props;

  const { queryClauses, queryRuleParamsOverridable, queryMetadataOverridable } = useDataSettings(
    "queryClauses",
    "queryRuleParamsOverridable",
    "queryMetadataOverridable"
  );

  const { setFieldValue, values, errors } = useFormikContext();
  const { opacity, highlighted, hovered } = useDragAndDrop(
    ref,
    id,
    index,
    dataAccessor || accessor
  );

  const color = useMemo(() => Color(colors[accessor]).darken(0.4).hex(), [accessor]);
  const colorStat = useMemo(() => Color(colors[accessor]).darken(0.6).hex(), [accessor]);

  const stile = useMemo(() => {
    const style = { opacity };

    if (highlighted && !hovered) {
      style.borderColor = "#222";
      style.color = "#222";

      return style;
    }
    if (hovered) {
      style.borderColor = "#333";
      style.color = "#333";
      style.margin = "5px 5px 5px 5px";
      style.opacity = 0.2;

      return style;
    }

    return style;
  }, [highlighted, hovered, opacity]);

  const boostable = useMemo(
    () => queryClauses.filter((q) => q.boostable).map((q) => q.accessor),
    [queryClauses]
  );

  const isOverridable = useCallback(
    (what) => {
      const params = !boostable.includes(accessor)
        ? queryRuleParamsOverridable.filter((p) => p !== "boost")
        : queryRuleParamsOverridable;

      for (const param of params) {
        if (what[param]) return true;
      }

      return false;
    },
    [accessor, boostable, queryRuleParamsOverridable]
  );

  const onDelete = useCallback(() => {
    let newValues = [...values[dataAccessor || accessor]];
    newValues.splice(index, 1);
    setFieldValue(dataAccessor || accessor, newValues);

    const overrides = cloneDeep(values.metadataOverrides || {});
    delete overrides[id];
    setFieldValue("metadataOverrides", overrides, false);
  }, [values, dataAccessor, accessor, index, setFieldValue, id]);

  const metadataOverridable = useMemo(() => {
    if (rule && rule.metadata) {
      const ruleMetadata = Object.keys(rule.metadata);

      const ret = ruleMetadata.filter((m) => queryMetadataOverridable.includes(m));

      if (ret.length > 0) return ret;
    }

    return null;
  }, [queryMetadataOverridable, rule]);

  const footer = useMemo(() => {
    return (
      <Button size="sm" onClick={onDelete} variant="outline-danger">
        Delete
      </Button>
    );
  }, [onDelete]);

  const extra = useMemo(() => {
    return (
      <>
        {haveMetaOverrides && (
          <span
            className="link-to-clean"
            onClick={(e) => {
              e.preventDefault();
              handleCleanRule();
            }}
          >
            <FontAwesomeIcon icon={faShower} /> clean
          </span>
        )}
        <Link to={`/rules/${id}`}>
          <FontAwesomeIcon icon={faLink} />
        </Link>
      </>
    );
  }, [handleCleanRule, haveMetaOverrides, id]);

  const warning = useCallback((r) => {
    if (r.notOverwritable) {
      return (
        <div className="not-overwrite-warning">
          <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
          Not Overridable
        </div>
      );
    }

    return null;
  }, []);

  const styleContainer = useMemo(() => {
    return { ...style, ...stile };
  }, [stile, style]);

  const ruleHeader = useMemo(() => {
    // Avoid override on Presets
    if (values.rules) return null;

    const base = values[accessor][index];

    const { grouped, operator } = base;

    if (rule.grouped !== undefined) {
      return (
        <div className={styles.ruleHeader}>
          {(grouped !== undefined ? grouped : rule.grouped) && (
            <div className={styles.andOr}>
              <AndOrSelector
                operator={operator ?? rule.operator}
                defaultOperator="or"
                onChangeOperator={(e) =>
                  setFieldValue(`${accessor}[${index}]`, { ...base, operator: e })
                }
                showTie={false}
              />
            </div>
          )}

          <Form.Check
            type="switch"
            id="group-statements"
            checked={grouped ?? rule?.grouped}
            value="true"
            onChange={(e) =>
              setFieldValue(`${accessor}[${index}]`, { ...base, grouped: e.target.checked })
            }
            name="group-statements"
            label="Group"
          />
        </div>
      );
    }
  }, [accessor, index, rule?.grouped, rule?.operator, setFieldValue, values]);

  if (!rule) return null;

  return (
    <Container
      foldable={true}
      folded={true}
      coloredBars={false}
      color={color}
      title={
        <>
          {rule.title} <span className="rule-id">Id#: {rule.id}</span>
        </>
      }
      help="preset-container"
      style={styleContainer}
      header={ruleHeader}
      headerOnlyOnFolded={false}
      footer={footer}
      extra={extra}
      warning={errors[accessor]?.[id]}
      ref={ref}
    >
      <Fragment>
        <div className="query-rule-body">
          <Row>
            <Col sm="1" className="rule-label">
              Description
            </Col>
            <Col sm="11">{rule.description || rule.title}</Col>
          </Row>

          {rule.rule.map((r, i) => (
            <Fragment key={`${r}-${i}`}>
              <Container
                foldable={true}
                folded={true}
                coloredBars={false}
                color={colorStat}
                title={`#Id: ${r._id}`}
                help="query-rule-statement"
                extra={warning(r)}
                header={
                  <div className="rule-header">
                    <div className="rule-header-if">If</div>
                    <div className="rule-header-condition">{formatCondition(r.condition)}</div>
                    <div className="rule-header-then">Then</div>
                    <div className="rule-header-statement">{formatStatement(r)}</div>
                  </div>
                }
              >
                <>
                  <Row>
                    <Col sm="1" className="rule-label">
                      Condition
                    </Col>
                    <Col sm="11">
                      <div className="query-rule-override-condition">
                        {formatCondition(r.condition)}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="1" className="rule-label">
                      Statement
                    </Col>
                    <Col sm="11">
                      <div className="query-rule-override-statement">{formatStatement(r)}</div>
                    </Col>
                  </Row>

                  {isOverridable(r) && (
                    <>
                      <Row>
                        <Col sm="1" className="rule-label">
                          Params
                        </Col>
                        <Col sm="11" className="rule-param-row">
                          <Boost
                            accessor={accessor}
                            dataAccessor={dataAccessor}
                            rule={r}
                            conditionIndex={i}
                            ruleIndex={index}
                          />
                          <DateRange
                            accessor={accessor}
                            dataAccessor={dataAccessor}
                            rule={r}
                            conditionIndex={i}
                            ruleIndex={index}
                          />
                          <Order
                            accessor={accessor}
                            dataAccessor={dataAccessor}
                            rule={r}
                            conditionIndex={i}
                            ruleIndex={index}
                          />
                          <div className="spacer" />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              </Container>
            </Fragment>
          ))}
          {metadataOverridable?.length ? (
            <Metadata
              forceOpen={false}
              rootNode={metadata}
              rootNodePath={"metadata."}
              rootNodeName={"metadata"}
              handleChange={onMetadataChange}
              contentType={values.contentType}
              showOnly={metadataOverridable}
              enableOverrides={true}
              blockFields={true}
              uniqueId={`${id}-${accessor}`}
              errors={errors[accessor]?.[id]?.metadata}
            />
          ) : null}
        </div>
      </Fragment>
    </Container>
  );
}
