//import { spawn, Thread, Worker } from "threads";

import {
  useEffect,
  useCallback,
  useContext,
  createContext,
  useReducer,
  useState,
} from "react";
import { useGetMemItem } from "../useMemoryDB";
import { useSelector } from "react-redux";

import { useMainContext } from "../useMainContext";
import { dataReducer, setDataStatus, dataActionsTypes } from "./dataState";
import nicetry from "nice-try";

import LoadingData from "./loader";
import dataWorker from "./worker";

export const DataContext = createContext();

export const DataContextProvider = ({
  tenant,
  id_token,
  username,
  children,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [state, dispatch] = useReducer(dataReducer, {});

  useEffect(() => {
    dataWorker.start({ tenant, username, id_token }).then(() => {
      dispatch(setDataStatus("loading", false));
      setDataLoaded(true);
    });

    return () => {
      if (dataWorker) {
        dataWorker.stop();
      }
    };
  }, [id_token, tenant, username]);

  const settingsData = useGetMemItem("settings", "client");
  const paramsId = useGetMemItem("ui", "server")?.paramsId;
  const params = useGetMemItem("params", paramsId);

  const getSettings = useCallback(
    (keys) => {
      if (!settingsData) return;
      if (typeof keys === "function") return keys(settingsData);

      if (keys.length === 1) {
        return settingsData[keys[0]];
      } else {
        const ret = {};
        for (const key of keys) {
          ret[key] = settingsData[key];
        }

        return ret;
      }
    },
    [settingsData]
  );

  const configurations = useCallback(() => {
    dispatch(setDataStatus("loading-configurations", true));
    (async () => {
      await dataWorker.updateData(tenant, id_token);
      dispatch(setDataStatus("loading-configurations", false));
    })();
  }, [id_token, tenant]);
  const volatile = useCallback(() => {
    dispatch(setDataStatus("loading-volatile", true));
    (async () => {
      await dataWorker.updateVolatileRequestMetadata(tenant, id_token);
      dispatch(setDataStatus("loading-volatile", false));
    })();
  }, [id_token, tenant]);
  const metadata = useCallback(() => {
    dispatch(setDataStatus("loading-metadata", true));
    (async () => {
      await dataWorker.updateMetadata(tenant, true);
      dispatch(setDataStatus("loading-metadata", false));
    })();
  }, [tenant]);
  const health = useCallback(() => {
    dispatch(setDataStatus("loading-health", true));
    (async () => {
      await dataWorker.updateHealthData(tenant);
      dispatch(setDataStatus("loading-health", false));
    })();
  }, [tenant]);
  const policies = useCallback(() => {
    dispatch(setDataStatus("loading-policies", true));
    (async () => {
      await dataWorker.updatePolicies(tenant, id_token);
      dispatch(setDataStatus("loading-policies", false));
    })();
  }, [id_token, tenant]);
  const users = useCallback(() => {
    dispatch(setDataStatus("loading-users", true));
    (async () => {
      await dataWorker.updateUsers(tenant, id_token);
      dispatch(setDataStatus("loading-users", false));
    })();
  }, [id_token, tenant]);
  const settings = useCallback(() => {
    dispatch(setDataStatus("loading-settings", true));
    (async () => {
      await dataWorker.updateSettings(tenant, id_token);
      dispatch(setDataStatus("loading-settings", false));
    })();
  }, [id_token, tenant]);
  const notes = useCallback(() => {
    dispatch(setDataStatus("loading-notes", true));
    (async () => {
      await dataWorker.updateNotes(tenant, id_token);
      dispatch(setDataStatus("loading-notes", false));
    })();
  }, [id_token, tenant]);
  const help = useCallback(() => {
    dispatch(setDataStatus("loading-help", true));
    (async () => {
      await dataWorker.updateHelp(tenant, id_token);
      dispatch(setDataStatus("loading-help", false));
    })();
  }, [id_token, tenant]);

  useEffect(() => () => dataWorker && dataWorker.stop(), []);

  if (!dataLoaded) return <LoadingData />;

  return (
    <DataContext.Provider
      value={{
        state,
        dispatch,
        dataWorker,
        update: {
          configurations,
          volatile,
          metadata,
          health,
          policies,
          users,
          settings,
          notes,
          help,
        },
        getSettings,
        paramsId,
        params,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);

export const useDataStateSelector = (cb) => {
  const { state } = useDataContext();
  return cb ? cb(state) : state;
};

export const useDataStateDispatch = () => {
  const { dispatch } = useDataContext();

  return dispatch;
};

export function useDataSetStatus() {
  const dispatch = useDataStateDispatch();

  const setStatus = useCallback(
    (k, v) => {
      if (Array.isArray(k)) {
        k.forEach(([key, value]) =>
          setImmediate(() => dispatch(setDataStatus(key, value)))
        );
      } else setImmediate(() => dispatch(setDataStatus(k, v)));
    },
    [dispatch]
  );

  return setStatus;
}

export function useDataParams() {
  const { params } = useDataContext();
  return params;
}

export function useDataSettings(...keys) {
  const { getSettings } = useDataContext();
  if (typeof keys[0] === "function") return getSettings(keys[0]);
  return getSettings(keys);
}

export function useDataResetModifiche() {
  const dispatch = useDataStateDispatch();

  const cb = useCallback(
    (cb) => {
      dispatch({ type: dataActionsTypes.RESET_MODIFICHE, cb });
    },
    [dispatch]
  );

  return cb;
}

export function useDataInsertModifiche() {
  const dispatch = useDataStateDispatch();

  const cb = useCallback(
    (payload, cb) => {
      dispatch({ type: dataActionsTypes.INSERT_MODIFICHE, payload, cb });
    },
    [dispatch]
  );

  return cb;
}

export function useDataUpdateModifiche() {
  const dispatch = useDataStateDispatch();

  const cb = useCallback(
    (payload, cb) => {
      dispatch({ type: dataActionsTypes.UPDATE_MODIFICHE, payload, cb });
    },
    [dispatch]
  );

  return cb;
}

function useDataRemote(caller, labels, options, defaultData) {
  const setStatus = useDataSetStatus();
  const resetModifiche = useDataResetModifiche();
  const { tenant, id_token } = useSelector((state) => state.user);
  const { requestConfirm, createNotification } = useMainContext();

  const doRemote = useCallback(
    async (dataArray, cb, cbError) => {
      const requests = [];
      setStatus("saving", true);

      dataArray.forEach((data) => {
        requests.push(dataWorker[caller](tenant, id_token, data));
      });

      Promise.all(requests)
        .then((responses) => {
          setStatus("saving", false);
          if (options?.notify === true || options?.notify === "onSuccess") {
            createNotification("success", labels.success(dataArray, responses));
          }
          resetModifiche();
          cb && cb("OK", responses);
        })
        .catch((response) => {
          setStatus("saving", false);

          const data = nicetry(() => JSON.parse(response.message)) || {};
          const error = data.error || response;

          if (options?.notify === true || options?.notify === "onError") {
            createNotification("error", labels.error(error, data));
          }
          cbError && cbError("KO", data);
          console.log(error);
        });
    },
    [
      caller,
      createNotification,
      id_token,
      labels,
      options,
      resetModifiche,
      setStatus,
      tenant,
    ]
  );

  const remote = useCallback(
    (inData, cb, cbError) => {
      const data = inData || defaultData;
      const dataArray = Array.isArray(data) ? data : [data];
      if (options?.confirm) {
        requestConfirm(labels.confirm(dataArray), () =>
          doRemote(dataArray, cb, cbError)
        );
      } else doRemote(dataArray, cb, cbError);
    },
    [defaultData, doRemote, labels, options, requestConfirm]
  );

  return remote;
}

export function useDataSaveItem(options) {
  return useDataRemote(
    "saveDataItem",
    {
      confirm: (data) =>
        `Are you Really sure you want to Save ${data
          .map((p) => `${p.accessor} Id: ${p.Id}`)
          .join(" and ")}?`,
      success: (data) =>
        `${data.map((p) => `${p.accessor} Id: ${p.Id} Saved!`).join(" and ")}`,
      error: (error, data) => `${data.accessor} Id: ${data.Id} ${error}!`,
    },
    options
  );
}

export function useDataDeleteItem(options) {
  return useDataRemote(
    "deleteDataItem",
    {
      confirm: (data) =>
        `Are you Really sure you want to Delete ${data
          .map((p) => `${p.accessor} Id: ${p.id}`)
          .join(" and ")}?`,
      success: (data) =>
        data.map((p) => `${p.accessor} Id: ${p.Id} Deleted!`).join(" and "),
      error: (error, data) => `${data.accessor} Id: ${data.Id} ${error}!`,
    },
    options
  );
}

export function useDataSaveModifiche(options) {
  return useDataRemote(
    "saveModifiche",
    {
      confirm: () => `Are you Really sure you want to Save Modifiche?`,
      success: () => `Modifiche Saved!`,
      error: (error) => `Modifiche Save Error: ${error}`,
    },
    options,
    useDataStateSelector((state) => state.modifiche)
  );
}

export function useDataSaveHelp(options) {
  const { update } = useDataContext();
  const lang = useGetMemItem("settings", "client")?.language;

  const func = useDataRemote(
    "helpSave",
    {
      confirm: () => `Are you Really sure you want to Save Help?`,
      success: () => `Help Saved!`,
      error: (error) => `Help Save Error: ${error}`,
    },
    options
  );

  const cb = useCallback(
    (data, cb) =>
      func({ ...data, lang }, () => {
        update.help();
        cb && cb(data);
      }),
    [func, lang, update]
  );

  return cb;
}

export function useDataSaveNotes(options) {
  const { update } = useDataContext();

  const func = useDataRemote(
    "notesSave",
    {
      confirm: () => `Are you Really sure you want to Save Notes?`,
      success: () => `Notes Saved!`,
      error: (error) => `Notes Save Error: ${error}`,
    },
    options
  );

  const cb = useCallback(
    (data, cb) =>
      func(data, () => {
        update.notes();
        cb && cb(data);
      }),
    [func, update]
  );

  return cb;
}

export function useDataSaveUser(options) {
  const { update } = useDataContext();
  const func = useDataRemote(
    "userSave",
    {
      confirm: (data) =>
        `Are you Really sure you want to Save User ${data.nickname}`,
      success: () => `User Saved!`,
      error: (error) => `Saving User ${error}!`,
    },
    options
  );

  const cb = useCallback(
    (data) => func(data, () => update.users()),
    [func, update]
  );

  return cb;
}

export function useDataDeleteUser(options) {
  const { update } = useDataContext();
  const func = useDataRemote(
    "userDelete",
    {
      confirm: (data) =>
        `Are you Really sure you want to Delete User ${data.nickname}`,
      success: () => `User Deleted!`,
      error: (error) => `Deleting User ${error}!`,
    },
    options
  );

  const cb = useCallback(
    (data) => func(data, () => update.users()),
    [func, update]
  );

  return cb;
}

export function useDataSavePolicies(options) {
  return useDataRemote(
    "policiesSave",
    {
      confirm: () => `Are you Really sure you want to Save Policies?`,
      success: () => `Policies Saved!`,
      error: (error) => `Saving Policies ${error}!`,
    },
    options
  );
}
