import {
  useState,
  useReducer,
  useContext,
  useCallback,
  createContext,
  useMemo
} from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useDataSaveItem } from "./useData";
import { useGetMemItem } from "./useMemoryDB";

import get from "lodash.get";

import idGen from "../utils/idGen";

export const ItemContext = createContext(null);

export function useItemContext() {
  return useContext(ItemContext);
}

const errorReducer = (state, data) => state;

export default function useItem(props) {
  const params = useParams();
  const { search } = useLocation();
  const saveItem = useDataSaveItem({ notify: true });

  const [itemErrors, setItemErrors] = useReducer(errorReducer, {});

  const copyId = useMemo(
    () => (search ? search.substring(search.indexOf("=") + 1) : null),
    [search]
  );
  const isCopy = useMemo(() => (copyId ? true : false), [copyId]);

  const [isNew, setIsNew] = useState(params?.itemId === "newItem");
  const [prevItemId, setPrevItemId] = useState(props?.itemId || params?.itemId);
  const [itemId, setItemId] = useState(
    isNew ? idGen() : props?.itemId || params?.itemId
  );
  const [itemIdError, setItemIdError] = useState(false);
  const rawItem = useGetMemItem(props.accessor, copyId || prevItemId);

  // const item = useMemo(() => {
  //   if (rawItem && copyId) {
  //     rawItem.title = "";
  //     rawItem.description = "";
  //     rawItem.tokens = [];
  //     rawItem.active = false;
  //     rawItem.refs = {};
  //   }
  //   return rawItem;
  // }, [copyId, rawItem]);

  const [item, setItemValues] = useState(() => {
    if (rawItem && copyId) {
      rawItem.title = "";
      rawItem.description = "";
      rawItem.refs = {};
    }
    return rawItem;
  });

  const hasBelongs = useMemo(() => {
    let count = 0;

    for (const belong of get(item, "refs.belongs") || []) {
      count += (belong.items || []).length;
    }

    return count ? true : false;
  }, [item]);

  const onSubmission = useCallback(
    (data, cb) => {
      const newValues = data;
      saveItem(
        {
          accessor: props.accessor,
          Id: itemId,
          prevId: prevItemId,
          isNew,
          data
        },
        (status, data) => {
          if (status === "OK") {
            setIsNew(false);
            setPrevItemId(itemId);
            setItemValues(newValues);
          }
          cb && cb(itemId, isNew);
        }
      );
    },
    [isNew, itemId, prevItemId, props.accessor, saveItem]
  );

  const permissions = {
    write: useSelector(
      (state) => state.user.permissions[state.user.tenant].write || []
    ),
    expert: useSelector(
      (state) => state.user.permissions[state.user.tenant].expert || []
    )
  };

  return {
    name: props.name,
    accessor: props.accessor,
    permissions,
    item,
    isNew,
    hasBelongs,
    itemId,
    itemIdError,
    itemErrors,
    isCopy,
    setItemValues,
    setItemErrors,
    setItemIdError,
    setItemId,
    onSubmission
  };
}
