import { useState, useMemo } from "react";
import {
  AreaChart,
  ResponsiveContainer,
  linearGradient,
  BarChart,
  Cell,
  ReferenceLine,
  Legend,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import Switch from "react-bootstrap-switch";

import colors from "../../../../../settings/chartColors";
import styles from "./styles.module.scss";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css";
import "./global.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faBahai } from "@fortawesome/free-solid-svg-icons";
import DiffIcon from "../../../diffIcon";
import { short } from "../../../utils";

const tooltipStyle = { backgroundColor: "#000", color: "#fff", border: "1px solid #444" };
const tooltipWrapperStyle = { border: "1px solid #444" };

export default function Timeline(props) {
  const [compareMode, setCompareMode] = useState(props.compare);

  const graph = useMemo(() => {
    if (!compareMode) {
      return (
        <AreaChart
          data={props.data}
          margin={{ top: 10, right: 40, left: 0, bottom: 0 }}
          key={props.label}
        >
          <defs>
            {colors.map((c, index) => {
              return (
                <linearGradient
                  id={`grad-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                  key={`${c.stop1}-${c.stop2}-${index}`}
                >
                  <stop offset="5%" stopColor={c.stop1} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={c.stop2} stopOpacity={0} />
                </linearGradient>
              );
            })}
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip wrapperStyle={tooltipWrapperStyle} contentStyle={tooltipStyle} />
          {["base", "compare"].map((v, index) => {
            return (
              <Area
                type="monotone"
                dataKey={v}
                stroke={colors[index].stroke}
                fillOpacity={1}
                fill={`url(#grad-${index})`}
                name={v}
                key={v}
              />
            );
          })}
        </AreaChart>
      );
    }

    return (
      <BarChart data={props.data} margin={{ top: 10, right: 0, left: -20, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          wrapperStyle={tooltipWrapperStyle}
          contentStyle={tooltipStyle}
          cursor={{ fill: "#222", opacity: 0.5 }}
        />
        <ReferenceLine y={0} stroke="#fff" />
        <Bar dataKey="diffPercentage" fill="#fff">
          {props.data.map((entry, index) => {
            return (
              <Cell
                key={`${index}-${entry.name}`}
                fill={entry.diffPercentage >= 0 ? "#00a550" : "#e83946"}
              />
            );
          })}
        </Bar>
      </BarChart>
    );
  }, [compareMode, props.data, props.label]);

  return (
    <div className={styles.timeline}>
      <div className={styles.header}>
        <div className={styles.users}>
          <FontAwesomeIcon icon={faBahai} className={styles.countIcon} />{" "}
          <span className={styles.usersText}>{short(props.count)}</span>
          <FontAwesomeIcon icon={faUsers} className={styles.usersIcon} />
          <span className={styles.usersText}>{short(props.userCount)}</span>
          <DiffIcon data={props.userDiffPercentage} />
        </div>
        {props.compare ? (
          <div className={styles.switch}>
            <Switch
              bsSize="small"
              value={compareMode}
              onText={"Compare"}
              onColor="compare"
              offText="All"
              offColor="danger"
              onChange={(elm, state) => {
                setCompareMode(state);
              }}
            />
          </div>
        ) : null}
      </div>

      <ResponsiveContainer width={"100%"} height={200}>
        {graph}
      </ResponsiveContainer>
    </div>
  );
}
