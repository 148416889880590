import { useCallback, useMemo, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDataSettings } from "../../hooks/useData";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";
const defaultStyle = { width: "366px" };

export default function RequiredFields(props) {
  const { fields, onChange, mandatory = [], suggested, style = defaultStyle } = props;
  const { endpointsMandatoryInputFields, endpointsInputFields } =
    useDataSettings("endpointsMandatoryInputFields", "endpointsInputFields") || {};

  const [mandatoryFields, setMandatoryFields] = useState([
    ...mandatory,
    ...(endpointsMandatoryInputFields || []),
  ]);
  const [requiredIn, setRequiredIn] = useState(fields || []);
  const [requiredField, setRequiredField] = useState("");

  const isMandatory = useCallback(
    (field) => (mandatoryFields || []).includes(field),
    [mandatoryFields]
  );

  const inFields = useMemo(
    () => endpointsInputFields?.filter((k) => !requiredIn.includes(k)),
    [endpointsInputFields, requiredIn]
  );

  const removeRequiredField = useCallback(
    (index) => {
      const newFields = [...requiredIn];
      newFields.splice(index, 1);
      setRequiredIn(newFields);
      onChange(newFields);
    },
    [onChange, requiredIn]
  );

  const addRequiredField = useCallback(
    (fld) => {
      const field = fld || requiredField;
      if (!field) return;

      const flds = [...requiredIn, field];
      setRequiredIn(flds);
      setRequiredField("");
      onChange(flds);
    },
    [onChange, requiredField, requiredIn]
  );

  // useEffect(() => {
  //   if (mandatory.length > 0) {
  //     setMandatoryFields((state) => [...new Set([...state, ...mandatory])]);
  //     setRequiredIn((state) => [...new Set([...state, ...mandatory])]);
  //   }
  // }, [mandatory]);

  useEffect(() => {
    if (fields?.length > 0 && requiredIn?.length === 0)
      setRequiredIn((state) => [...new Set([...state, ...fields])]);
  }, [fields, requiredIn]);

  return (
    <div className="required-fields-box">
      <div className="select-row">
        <Form.Control
          as="select"
          className="infields-select"
          value={requiredField}
          onChange={(e) => setRequiredField(e.target.value)}
          style={style}
        >
          <option></option>
          {inFields?.map((f) => (
            <option key={`infield-${f}`}>{f}</option>
          ))}
        </Form.Control>
        <span className="infields-button">
          <Button size="sm" variant="outline-primary" onClick={() => addRequiredField()}>
            <FontAwesomeIcon icon={faPlusSquare} className="icon" /> Add Field
          </Button>
        </span>
      </div>
      <div className="required-field-container">
        {requiredIn.map((req, index) => (
          <Button
            key={`required-field-${req}`}
            className="required-field"
            variant={isMandatory(req) ? "outline-info" : "outline-secondary"}
            size="sm"
          >
            {req}
            {!isMandatory(req) ? (
              <span
                aria-hidden="true"
                onClick={() => removeRequiredField(index)}
                style={{ cursor: "pointer", marginLeft: "4px" }}
              >
                &times;
              </span>
            ) : null}
          </Button>
        ))}
        {suggested?.length > 0 ? (
          <div className="suggested-box">
            <FontAwesomeIcon icon={faExclamationTriangle} className="suggested-icon" />
            {suggested.map((s) => (
              <span key={s} className="suggested-field" onClick={() => addRequiredField(s)}>
                {s}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
