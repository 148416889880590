import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

function Phrase(props) {
  const { rootNodePath, execute, handleChange } = props;

  //if (execute?.fieldDBType === "keyword") return null;
  return (
    <div className={styles.phraseBox}>
      <div className={styles.select}>
        <div className={styles.label}>Fuzziness</div>
        <div className={styles.content}>
          <Form.Control
            as="select"
            value={execute.fuzziness}
            onChange={(e) => handleChange("fuzziness", e)}
            name={`${rootNodePath}execute.otherFields.fuzziness`}
            id={`${rootNodePath}execute.otherFields.fuzziness`}
          >
            <option></option>
            <option>AUTO</option>
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </div>
      </div>

      <div>
        {execute?.fieldDBType !== "keyword" && (
          <Form.Check
            type="switch"
            value={true}
            checked={execute.phrase}
            onChange={(e) => handleChange("phrase", e)}
            name={`${rootNodePath}execute.otherFields.phrase`}
            id={`${rootNodePath}execute.otherFields.phrase`}
            label="Whole Phrase"
          />
        )}
      </div>
    </div>
  );
}

export default Phrase;
