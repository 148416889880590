import styles from "./styles.module.scss";

function Sort(props) {
  const { execute, handleChange } = props;

  return (
    <div className={styles.sortBox}>
      <div className={styles.select}>
        <div className={styles.label}>Sort Order</div>
        <div className={styles.content}>
          <select
            className="form-control form-control-sm"
            value={execute.sortOrder}
            onChange={(e) => handleChange("sortOrder", e)}
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
      </div>
      <div className={styles.select}>
        <div className={styles.label}>Sort Mode</div>
        <div className={styles.content}>
          <select
            className="form-control form-control-sm"
            value={execute.sortMode}
            onChange={(e) => handleChange("sortMode", e)}
          >
            <option value="">Alphabetical</option>
            <option value="min">Lowest Value</option>
            <option value="max">Highest Value</option>
            <option value="sum">Sum All Values</option>
            <option value="avg">Average All Values</option>
            <option value="median">Median All Values</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Sort;
