import { useState, useCallback, useMemo, useEffect } from "react";
import { useGetMemTable } from "../../hooks/useMemoryDB";
import { useFormikContext } from "formik";
import Link from "../link";
import { useUpdateRequest } from "../../hooks/useUpdateMetadata";
import { useDataRefObj } from "../../hooks/useDataRef";
import useContentRealm from "../../hooks/useContentRealm";
import { Form, Button, ButtonGroup, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { useUpdateMetadata } from "../../hooks/useUpdateMetadata";
import { useItemContext } from "../../hooks/useItem";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import unset from "lodash.unset";
import isNil from "lodash.isnil";
import ContentType from "../contentType";
import Similar from "./similar";
import UserSimilar from "./userSimilar";
import Collaborative from "./collaborative";
import UserMeta from "./userMeta";
import Extra from "./extra";
import External from "./external";
import ConversionPacks from "./conversionPacks";
import ContentPacks from "./contentPacks";
import MetaPacks from "./metaPacks";
import Container from "../container";
import SelectAllMultiple from "../selects/selectAllMultiple";
import "./style.scss";

import stubs from "../../utils/stubs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { blockMetaNotFallback } from "../../db/refManager";

const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);
const beauty = (name) => {
  if (name === "content_similar") name = "Content Pack";
  if (name === "trending_social") name = "Trending Twitter";
  if (name === "trending_news") name = "Trending Google Searches";

  return name
    .split("_")
    .map((n) => capitalize(n))
    .join("-");
};

const overlayInfo = (
  <Popover id="popover-basic" bsPrefix="ares-fallback-panel">
    <Popover.Header as="h3">
      <FontAwesomeIcon icon={faShieldAlt} className="iconShield" /> This is a Fallback or Smart
      Block or belongs to Fallback or Smart Block Container
    </Popover.Header>
    <Popover.Body>Metadata related to Content and User are not showing</Popover.Body>
  </Popover>
);

const overlayFallbacks = (blocks) => {
  return (
    <Popover id="popover-basic" bsPrefix="ares-fallback-panel">
      <Popover.Header as="h3">
        <FontAwesomeIcon icon={faShieldAlt} className="iconShield" /> Fallbacks and Smart Containers
        Including this Item
      </Popover.Header>
      <Popover.Body>
        <ul className="fallback-blocks">
          {blocks.map((block) => (
            <li className="fallback-block" key={block.id}>
              {block.titlePath ? (
                <Link
                  to={`/property/${block.titlePathRef[0]}/endpoint/${block.titlePathRef[1]}/variant/${block.titlePathRef[2]}`}
                >
                  {block.titlePath.join(" ▸ ")}
                </Link>
              ) : (
                <Link to={`/blocks/${block.id}`}> {block.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
};

const emptyObj = {};
const Metadata = (props) => {
  //useWhyDidYouUpdate("Metatada", props);
  const { handleBlur, setFieldValue, errors: errori, values } = useFormikContext() || stubs.formik;
  const errors = useMemo(() => props.errors || errori, [errori, props.errors]);

  const {
    rootNode,
    rootNodeName,
    rootNodePath,
    contentType,
    handleChange,
    showOnly,
    forceShow,
    forceUserCatalog,
    uniqueId,
    enableOverrides = false,
    checkFallback = true,
    forceOpen = false,
    blockFields = false,
  } = props;

  const updateFieldMetadata = useUpdateMetadata();
  const { itemId, accessor } = useItemContext() || stubs.item;
  const info = useDataRefObj(accessor, values ?? emptyObj);
  const metadata = useMemo(() => rootNode || {}, [rootNode]);
  const [compact, setCompact] = useState(!forceOpen);
  const { userTypes } = useContentRealm();

  const [excludeFallbackMetadata, fallbackInfo] = useMemo(() => {
    if (checkFallback && info) {
      const overlay =
        info.usedInFallback?.length > 0 || info.belongsSmartBlockContainers
          ? overlayFallbacks([
              ...info.usedInFallback,
              ...info.belongsSmartBlockContainers,
              ...info?.usedAsFallback,
            ])
          : overlayInfo;

      return [info?.restricted, overlay];
    }

    return [false, overlayInfo];
  }, [checkFallback, info]);

  const metadataCompact = useMemo(() => {
    const metas = Object.entries(metadata || {})
      .filter(([_, value]) => value.required === true || value === true)
      .map(([name, _]) => beauty(name));

    metas.splice(0, 0, "Request");
    return (
      <ButtonGroup aria-label="metadata-compact">
        {metas.map((m) => (
          <Button
            key={m}
            variant="outline-secondary"
            onClick={() => setCompact(false)}
            className="compact-meta-btn"
            size="sm"
          >
            {m}
          </Button>
        ))}
      </ButtonGroup>
    );
  }, [metadata]);

  const show = useCallback(
    (what) => {
      if (what === "user_similar") what = "userSimilarity";

      if (!showOnly || showOnly?.length === 0) {
        if (excludeFallbackMetadata) return !blockMetaNotFallback.includes(what);
        return true;
      }

      if (forceShow) {
        return showOnly.includes(what);
      }

      if (showOnly.includes(what)) {
        return metadata[what] === true || metadata[what]?.required === true;
      }
    },
    [excludeFallbackMetadata, showOnly, forceShow, metadata]
  );

  const metadataToShowCount = useMemo(() => {
    if (!showOnly || showOnly?.length === 0) return true;

    let count = 0;

    Object.keys(metadata || {}).forEach((what) => {
      if (show(what)) count += 1;
    });

    return count;
  }, [metadata, show, showOnly]);
  //console.log("metadataToShowCount", metadataToShowCount, "showOnly", showOnly, metadata);
  const updateUserContentType = useCallback(
    (value) => {
      const newValues = { ...metadata };
      set(newValues, "user.userContentType", value);
      set(newValues, "user_similar.userContentType", value);
      set(newValues, "collaborative.userContentType", value);

      setImmediate(() => setFieldValue(rootNodeName, newValues));
    },
    [metadata, rootNodeName, setFieldValue]
  );

  const updateMetadata = useCallback(
    (key, value) => {
      const newValues = cloneDeep(rootNode ?? {}); //{ ...rootNode }
      //if (key.includes("required") && !value) key = key.substring(0, key.indexOf("."));
      if (key.includes("required") && value === undefined) key = key.substring(0, key.indexOf("."));

      if (isNil(value)) {
        unset(newValues, key);
        let parentKey = key.substring(0, key.lastIndexOf("."));

        while (parentKey.length) {
          if (!Object.keys(get(newValues, parentKey)).length) {
            unset(newValues, parentKey);
          }
          parentKey = parentKey.substring(0, parentKey.lastIndexOf("."));
        }
      } else {
        set(newValues, key, value);
      }

      // CONTENT SIMILAR
      if (get(newValues, "content_similar.date.range") === "0") {
        unset(newValues, "content_similar.date");
      }

      // LOCATION & WEATHER
      if (!newValues.location) {
        unset(newValues, "weather");
      }

      if (handleChange) handleChange(newValues);
      else setFieldValue(rootNodeName, newValues);
    },
    [handleChange, rootNode, rootNodeName, setFieldValue]
  );

  const checkBox = useCallback(
    (name, disabled, label, warning) => {
      if (!show(name)) return null;
      //console.log(`meta-${name}`);
      return (
        <Container
          folded={true}
          foldable={false}
          coloredBars={false}
          color="#030b0e"
          margin="4px"
          warning={warning}
          title={
            <Form.Check
              type="switch"
              id={`${rootNodePath}${name}-${uniqueId}`}
              checked={(metadata && metadata[name]) || false}
              value="true"
              onChange={(e) => updateMetadata(name, e.target.checked)}
              onBlur={handleBlur}
              name={`${rootNodePath}${name}`}
              disabled={disabled === undefined || disabled === true ? true : false}
              label={beauty(label || name)}
              style={{ display: "inline-block" }}
            />
          }
          help={`meta-${name}`}
        />
      );
    },
    [handleBlur, metadata, rootNodePath, show, uniqueId, updateMetadata]
  );

  const extra = useMemo(() => {
    if (excludeFallbackMetadata) {
      return (
        <div className="extra-box">
          <OverlayTrigger trigger="click" placement="bottom" overlay={fallbackInfo}>
            <div>
              <FontAwesomeIcon icon={faShieldAlt} className="warning-icon" />
              Not Showing Some Metadata
            </div>
          </OverlayTrigger>
        </div>
      );
    }

    return null;
  }, [excludeFallbackMetadata, fallbackInfo]);

  /*
  <OverlayTrigger trigger="click" placement="right" overlay={fallbackInfo}>
            <FontAwesomeIcon icon={faExclamationCircle} className="warning-icon" />
            Not Showing Some Metadata
          </OverlayTrigger>
  */
  const handleUnfold = useCallback(() => {
    setCompact((state) => !state);
  }, []);

  useEffect(() => {
    if (userTypes.includes(contentType)) {
      if (metadata.content_similar?.required === true) {
        updateMetadata("content_similar", { required: false });
      }
    }
  }, [contentType, metadata.content_similar, updateMetadata, userTypes]);

  // Populate output fields in Metadata Exec Menu
  useEffect(() => {
    const keys = ["collaborative", "content_similar", "user_similar"];
    if (metadata && itemId) {
      for (const key of keys) {
        const output = metadata?.[key]?.output;
        const required = metadata?.[key]?.required;

        if (required && output) {
          updateFieldMetadata({
            owner: itemId,
            nextId: output,
            types: ["string"],
            realm: key,
            catalog: contentType,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const metaHeader = useMemo(
    () => <div className="compact">{metadataCompact}</div>,
    [metadataCompact]
  );

  if (!forceShow && metadataToShowCount === 0) return null;

  return (
    <Container
      title="Metadata"
      color="#07181f"
      help="metadata"
      folded={compact} //false
      onFold={handleUnfold}
      hideBodyOnFold={true}
      header={metaHeader}
      extra={extra}
      warning={errors[rootNodeName]}
    >
      <span className="metadata-container">
        {show("request") && (
          <Request updateMetadata={updateMetadata} values={metadata.request} id={itemId} />
        )}
        {checkBox("content", false, "content")}
        {props.onContentTypeChange && metadata?.content === true && (
          <div className="content-type-box">
            <ContentType
              rootNode={{ contentType }}
              rootNodePath={""}
              errorPath={`${uniqueId}-contentType-error`}
              fixedValue={null}
              onChange={(type) => props.onContentTypeChange?.(type)}
              typeOfType="content"
            />
          </div>
        )}
        {show("extra") && (
          <Extra
            data={metadata?.extra}
            updateMetadata={updateMetadata}
            uniqueId={uniqueId}
            contentType={contentType}
            userTypes={userTypes}
            rootNodeName={rootNodeName}
          />
        )}
        {show("content_similar") && !userTypes.includes(contentType) && (
          <Similar
            metadata={metadata}
            updateMetadata={updateMetadata}
            rootNode={rootNode}
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            contentType={contentType}
            blockFields={blockFields}
            uniqueId={uniqueId}
            excludeFallbackMetadata={excludeFallbackMetadata}
            errors={props.errors}
          />
        )}

        {show("contentPacks") && (
          <ContentPacks
            metadata={metadata}
            updateMetadata={updateMetadata}
            rootNode={rootNode}
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            contentType={contentType}
            blockFields={blockFields}
            enableOverrides={enableOverrides}
            info={info}
            uniqueId={uniqueId}
            errors={props.errors}
          />
        )}
        {show("conversionPacks") && (
          <ConversionPacks
            metadata={metadata}
            updateMetadata={updateMetadata}
            rootNode={rootNode}
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            contentType={contentType}
            blockFields={blockFields}
            info={info}
            uniqueId={uniqueId}
            errors={props.errors}
          />
        )}
        {show("metaPacks") && (
          <MetaPacks
            metadata={metadata}
            updateMetadata={updateMetadata}
            rootNode={rootNode}
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            contentType={contentType}
            blockFields={blockFields}
            info={info}
            uniqueId={uniqueId}
            errors={props.errors}
          />
        )}
        {show("user") && (
          <UserMeta
            label="User"
            accessor="user"
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            metadata={metadata}
            contentType={contentType}
            userTypes={userTypes}
            updateMetadata={updateMetadata}
            updateUserContentType={updateUserContentType}
            uniqueId={uniqueId}
            showEvent={false}
            forceUserCatalog={forceUserCatalog}
            hideTabs={true}
          />
        )}
        {show("user_similar") && (
          <UserMeta
            label="User Similar"
            accessor="user_similar"
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            metadata={metadata}
            contentType={contentType}
            userTypes={userTypes}
            updateMetadata={updateMetadata}
            updateUserContentType={updateUserContentType}
            uniqueId={uniqueId}
            showCatalog={true}
            hideTabs={true}
          >
            <UserSimilar
              metadata={metadata}
              updateMetadata={updateMetadata}
              contentType={contentType}
              userTypes={userTypes}
              rootNodeName={rootNodeName}
              uniqueId={uniqueId}
            />
          </UserMeta>
        )}
        {show("collaborative") && (
          <UserMeta
            label="Collaborative"
            accessor="collaborative"
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            metadata={metadata}
            contentType={contentType}
            userTypes={userTypes}
            updateMetadata={updateMetadata}
            updateUserContentType={updateUserContentType}
            uniqueId={uniqueId}
            showFingerprint={false}
            hideTabs={true}
          >
            <Collaborative
              metadata={metadata}
              updateMetadata={updateMetadata}
              contentType={contentType}
              userTypes={userTypes}
              uniqueId={uniqueId}
              rootNodeName={rootNodeName}
            />
          </UserMeta>
        )}
        {checkBox("location", false)}
        {checkBox("weather", rootNode ? !rootNode.location : true)}
        {checkBox("time", false)}
        {checkBox("trending_social", false)}
        {checkBox("trending_news", false)}
        {show("external") && (
          <External
            metadata={metadata}
            updateMetadata={updateMetadata}
            rootNode={rootNode}
            rootNodePath={rootNodePath}
            rootNodeName={rootNodeName}
            contentType={contentType}
            blockFields={blockFields}
            uniqueId={uniqueId}
            excludeFallbackMetadata={excludeFallbackMetadata}
            errors={props.errors}
          />
        )}
      </span>
    </Container>
  );
};

const emptyArray = [];

function Request(props) {
  const { values = emptyArray, updateMetadata, id } = props;
  const enrichers = useGetMemTable("enrichers");
  const updateRequest = useUpdateRequest();
  const alreadyIn = useMemo(() => values.map(({ id }) => id), [values]);
  const handleChange = useCallback(
    (ids) => {
      const update = [];
      ids.forEach((objId) => {
        const pack = enrichers.get(objId);
        update.push({ id: objId, title: pack.title, environnement: pack.environnement });
        updateRequest(id, pack.metadata);
      });

      updateMetadata("request", update);
    },
    [enrichers, id, updateMetadata, updateRequest]
  );

  useEffect(() => {
    alreadyIn.forEach((enricherId) => {
      const pack = enrichers.get(enricherId);
      updateRequest(id, pack.metadata);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      folded={true}
      foldable={true}
      coloredBars={false}
      color="#030b0e"
      margin="4px"
      noMarginLast={true}
      title={
        <Form.Check
          type="switch"
          checked={true}
          value="true"
          onChange={(e) => e.preventDefault()}
          label="Request"
          disabled={true}
        />
      }
      help="meta-request"
    >
      <Col sm="1" className="request-label">
        Enrichers
      </Col>
      <SelectAllMultiple accessor="enrichers" values={alreadyIn} handleChange={handleChange} />
    </Container>
  );
}

export default Metadata;
