import { useMemo } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import get from "lodash.get";
import RotaryKnob from "../../../rotaryKnob";
import styles from "./styles.module.scss";

function Range(props) {
  const { errors } = useFormikContext();
  const {
    execute,
    handleChange,
    name,
    showDateFormat,
    label,
    timeUnitsOnly = false,
  } = props;

  const periodError = useMemo(
    () => get(errors, `${name}.period`),
    [errors, name]
  );
  const formatError = useMemo(
    () => get(errors, `${name}.format`),
    [errors, name]
  );

  return (
    <div className={styles.rangeBox}>
      <div>
        <RotaryKnob
          value={Number(execute.range) || 1}
          min={1}
          max={1000}
          step={1}
          labelPosition="right"
          label={label ?? "Relative Range (+/-)"}
          skin="s12"
          onChange={(value) => handleChange("range", value)}
        />
      </div>
      <div className={styles.select}>
        <div className={styles.content}>
          <Form.Control
            as="select"
            isInvalid={periodError}
            onChange={(value) => handleChange("period", value)}
            value={execute.period || "y"}
          >
            <option value="s">Seconds</option>
            <option value="m">Minute</option>
            <option value="h">Hour</option>
            <option value="d">Day</option>
            {!timeUnitsOnly && (
              <>
                <option value="w">Week</option>
                <option value="M">Month</option>
                <option value="y">Year</option>
              </>
            )}
          </Form.Control>
        </div>
      </div>
      {showDateFormat ? (
        <div>
          <Form.Control
            type="text"
            isInvalid={formatError}
            onChange={(e) => handleChange("format", e)}
            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
            value={execute?.format || ""}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Range;
