import { useMemo, useState, useCallback } from "react";
import { useFormikContext } from "formik";
import { useGetMemItem } from "../../hooks/useMemoryDB";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown, faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import cloneDeep from "lodash.clonedeep";
import Help from "../help";

export default function PresetBox(props) {
  const { setFieldValue, values, validateForm } = useFormikContext();
  const [expand, setExpand] = useState(false);

  const existing = useMemo(() => values[props.clause]?.map((c) => c.id), [props.clause, values]);
  const preset = useGetMemItem("rulesPresets", props.preset.id);
  const getRule = useGetMemItem("rules");

  const handleRemove = useCallback(() => {
    const dataPresets = cloneDeep(values.presets);
    dataPresets[props.clause].splice(props.index, 1);

    setFieldValue(`presets`, dataPresets);
    setImmediate(validateForm);
  }, [props.clause, props.index, setFieldValue, validateForm, values.presets]);

  const activePresets = useMemo(() => {
    const actives = preset?.rules?.filter((rule) => !existing.includes(rule.id));
    return (actives || []).length;
  }, [existing, preset]);

  return (
    <div className="preset-container">
      <div className="header">
        <div className="arrow" onClick={() => setExpand((state) => !state)}>
          <FontAwesomeIcon icon={expand ? faChevronDown : faChevronRight} />
        </div>
        <div className="title">
          {preset?.title} <Help code="query-single-preset" className="help" />
        </div>
        <div className="spacer" />
        <div className="count">{activePresets}</div>
      </div>

      {expand && (
        <div className="expand">
          <div className="ruleListContainer">
            {preset?.rules?.map(({ id }) => {
              const rule = getRule(id);
              if (!rule) return null;

              return (
                <div key={id} className="rule">
                  <div className={`title ${existing.includes(id) ? "override" : ""}`}>
                    {rule.title}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="delete">
            <Button size="sm" variant="danger" onClick={handleRemove} className="delBtn">
              <FontAwesomeIcon icon={faMinusCircle} className="icon" />
              <span className="addLabel">Remove</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
