import set from "lodash.set";
import get from "lodash.get";

import Item from "../item";
import Endpoint from "./endpoint";

import validate from "./validation";

export const doEndpointRefs = (values) => {
  const inDec = get(values, "input.decorators") || [];
  const outDec = get(values, "output.decorators") || [];
  const blocks = [values.blockId];

  if (values.variants) {
    values.variants.forEach(({ blockId }) => blocks.push(blockId));
  }

  set(values, "refs.require", [
    {
      type: "blocks",
      items: blocks,
    },
    {
      type: "params",
      items: [values.paramsId],
    },
    {
      type: "decorators",
      items: [...new Set([...inDec, ...outDec])],
    },
    {
      type: "conversionPacks",
      items: (values.output?.filters?.packs ?? []).map(({ conversionPackId }) => conversionPackId),
    },
  ]);

  if (!values.prevReference) values.prevReference = values.reference;

  return values;
};

export default function EndpointContainer() {
  return (
    <Item name="Endpoint" accessor="endpoints" onSubmit={doEndpointRefs} onValidate={validate}>
      <Endpoint />
    </Item>
  );
}
