import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import { playSound } from "../../../utils/audio";

export default function LoadingData() {
  //playSound("start");

  return (
    <div className={styles.loader}>
      <FontAwesomeIcon icon={faCircleNotch} className={styles.icon} spin />
      <div className={styles.text}>Loading Data...</div>
    </div>
  );
}
