import {
  faUsersViewfinder,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Virtuoso } from "react-virtuoso";
import { useState, useEffect, useMemo, Fragment, useCallback } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { db } from "../../db";
import Select from "../selects/selectWithSearch";

import styles from "./styles.module.scss";

const DATA_DEFAULT = { personas: [], reals: [] };

export default function InputPersonas({ value, onChange, className, ignoreInvalid = false }) {
  const data = db.get("personas", "P1") ?? DATA_DEFAULT;
  const selezione = useMemo(() => {
    const item = [...data.personas, ...data.reals].find(({ id }) => id === value);
    return item ? item.name : "";
  }, [data.personas, data.reals, value]);

  const [free, setFree] = useState(!selezione);
  const [filter, setFilter] = useState();

  const [personas, reals] = useMemo(() => {
    if (!filter) return [data.personas, data.reals];
    const personas = data.personas.filter(({ name }) => name.toLowerCase().includes(filter));
    const reals = data.reals.filter(({ name }) => name.toLowerCase().includes(filter));

    return [personas, reals];
  }, [data.personas, data.reals, filter]);

  return (
    <div className={styles.inputBox}>
      {free ? (
        <Form.Control
          type="text"
          placeholder="Enter userId"
          onChange={onChange}
          value={value}
          className={`${styles.input} ${className ? className : ""}`}
          isInvalid={ignoreInvalid ? false : !value}
        />
      ) : (
        <>
          <Select
            aria-label="Personas Select"
            onSelect={(value) => onChange({ target: { value } })}
            canFilter={true}
            value={selezione}
            className={`${styles.input} ${className ? className : ""}`}
            isInvalid={ignoreInvalid ? false : !value}
            onFilter={setFilter}
          >
            <Group
              key="Real Users"
              data={reals}
              head="Real Users"
              itemSize={26}
              maxHeight={170}
              width={380}
              foldable={true}
            />
            <Group
              key="Fake Users"
              data={personas}
              head="Fake Users"
              itemSize={26}
              maxHeight={170}
              width={380}
              foldable={true}
            />
          </Select>
        </>
      )}
      <FontAwesomeIcon
        icon={faUsersViewfinder}
        onClick={() => setFree((state) => !state)}
        className={free ? styles.free : styles.personas}
      />
    </div>
  );
}

function Group({ data, head, itemSize, width, maxHeight, foldable }) {
  const [open, setOpen] = useState();
  const itemsCount = data.length;
  const height = itemsCount * itemSize > maxHeight ? maxHeight : itemsCount * itemSize;
  const icon = foldable ? (
    <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} fixedWidth />
  ) : null;

  const drawItem = useCallback(
    (index) => {
      const item = data[index];

      return (
        <Dropdown.Item
          eventKey={item.id}
          key={`${item.id}-${index}`}
          value={item.id}
          className="select-item-result"
          onClick={() => true}
        >
          {item.name}
        </Dropdown.Item>
      );
    },
    [data]
  );

  useEffect(() => {
    setOpen(!foldable);
  }, [foldable]);

  if (!data) return;

  return (
    <Fragment key={`drop-frag-${head}`}>
      <Dropdown.Header
        className="drop-header group-header"
        key={`drop-header-${head}`}
        onClick={() => setOpen((state) => !state)}
      >
        {icon} {head} <span className="group-items-count-badge">{itemsCount}</span>
      </Dropdown.Header>
      {open ? (
        <Virtuoso
          style={{ height }}
          totalCount={itemsCount}
          itemContent={drawItem}
          className="sub-meta-list"
        />
      ) : null}

      <div className="drop-divider" />
    </Fragment>
  );
}
