import { set } from "lodash";

export default function validate(data = {}) {
  const errors = {};

  ["filter", "must_not"].forEach((accessor) => {
    const rules = data[accessor]?.rules;
    if (rules) {
      rules.forEach((rule, index) => {
        if (rule.type === "metadata" && rule.values?.length === 0) {
          const error = "Missing Metadata Values";
          errors[accessor] = error;
          errors[`${accessor}_${index}`] = error;
        }
      });
    }
  });

  if (!data.range?.period) {
    errors.range = true;
    errors.rangePeriod = true;
  }

  if (!data.range?.unit) {
    errors.range = true;
    errors.rangeUnit = true;
  }

  // if (!data.conversion) {
  //   errors.conversion = true;
  // }

  if (!data.conversions || data.conversions.length === 0) {
    errors.conversion = true;
  }

  if (!data.numElements) {
    errors.numElements = true;
    errors.settings = true;
  }

  if (!data.outputMetadataField) {
    errors.outputMetadataField = true;
    errors.settings = true;
  }

  if (!data.paramsId) {
    errors.paramsId = true;
    errors.settings = true;
  }

  if (!data.contentType) {
    errors.contentType = true;
    errors.settings = true;
  }

  if (data.filters) {
    ["context", "params"].forEach((accessor) => {
      data.filters[accessor]?.rules?.forEach((rule, index) => {
        if (rule.values?.length === 0) {
          set(errors, `filters.${accessor}.${index}`, "You Must Insert Value");
        }
      });
    });
  }

  if (data.useCases) {
    if (data.useCases.cases) {
      data.useCases.cases.forEach(({ id }) => {
        if (!id) errors.useCases = true;
      });
    }
  }
  return errors;
}
