import { Toast } from "react-bootstrap";
import { useReducer, useMemo, useCallback } from "react";
import "./style.scss";

const reducer = (state, action) => {
  const newState = [...state];

  switch (action.type) {
    case "ADD":
      newState.push({
        level: action.level,
        message: action.message,
        autohide: action.autohide,
        delay: action.delay,
      });
      break;
    case "DELETE":
      newState.splice(action.index, 1);
      break;
    case "DELETE-ALL":
      return [];
    default:
      throw new Error();
  }

  return newState;
};

export default function useNotifications(defAutohide = true, defDelay = 2000) {
  const [notifications, dispatch] = useReducer(reducer, []);

  const notify = useCallback((level, message, autohide, delay) => {
    dispatch({
      type: "ADD",
      level,
      message,
      autohide,
      delay,
    });
  }, []);

  const manager = useMemo(() => {
    return {
      info() {
        notify("information", ...arguments);
      },
      success() {
        notify("success", ...arguments);
      },
      warning() {
        notify("warning", ...arguments);
      },
      error() {
        notify("error", ...arguments);
      },
      resetAll() {
        dispatch({
          type: "DELETE-ALL",
        });
      },
    };
  }, [notify]);

  const wrapper = useMemo(
    () => (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          top: 50,
          right: 10,
          minHeight: "200px",
          zIndex: "99999",
        }}
      >
        {notifications.map((noty, i) => {
          let delay = noty.delay || defDelay;
          if (noty.level === "error") delay = delay * 3;

          return (
            <Toast
              key={`notification-${i}`}
              className={`notification notification-${noty.level}`}
              onClose={() => dispatch({ type: "DELETE", index: i })}
              show={true}
              delay={delay}
              autohide={noty.autohide !== undefined ? noty.autohide : defAutohide}
              onClick={() => dispatch({ type: "DELETE", index: i })}
            >
              <Toast.Body>{noty.message}</Toast.Body>
            </Toast>
          );
        })}
      </div>
    ),
    [defAutohide, defDelay, notifications]
  );

  return [wrapper, manager];
}
