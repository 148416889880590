import { forwardRef, useState, useCallback, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { Button, Form } from "react-bootstrap";

import Container from "../container";
import ErrorMessage from "../errorMessage";
import styles from "./styles.module.scss";

const NameAndDescription = forwardRef((props, ref) => {
  const { values, errors, setValues } = useFormikContext();
  const [title, setTitle] = useState(values.title || "");
  const [desc, setDesc] = useState(values.description || "");

  const [fold, setFold] = useState(() => {
    if (props.isNew || !values.title || !values.title.length || errors.title || errors.idError) {
      return false;
    }

    return true;
  });

  ref.current = { fold, setFold };

  useEffect(() => {
    if (fold && title !== values.title) {
      setTitle(values.title);
      setFold(true);
    }
    if (fold && desc !== values.description) {
      setDesc(values.description);
      setFold(true);
    }
  }, [values.title, values.description, title, desc, fold]);

  const doUpdate = useCallback(() => {
    if (title) {
      setValues({ ...values, title, description: desc });
      setFold(true);
    }
  }, [desc, setValues, title, values]);

  const onFold = useCallback((x) => setFold(x), []);
  const header = useMemo(
    () => (
      <div className={styles.header}>
        <div className={styles.title}>{values.title}</div>
        <div className={styles.description}>{desc}</div>
      </div>
    ),
    [desc, values.title]
  );
  return (
    <Container
      title="Name"
      help="name-and-description"
      color={props.color || "#00140e"}
      coloredBars={props.color ? false : true}
      header={header}
      folded={fold}
      onFold={onFold}
      warningMessage={errors.title}
      footer={
        <div className={styles.btn}>
          <Button
            type="button"
            variant="outline-secondary"
            onClick={doUpdate}
            className={styles.doneBtn}
            size="sm"
          >
            Done
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.label}>Title</div>
          <div className={styles.content}>
            <Form.Control
              type="text"
              placeholder="Insert a title..."
              onChange={(e) => setTitle(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
              value={title}
              isInvalid={errors.title}
            />
            <ErrorMessage name="title" />
            <ErrorMessage
              name="idError"
              errorMessage="Please change Query Name: it generates a Query Id that already exist"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Description</div>
          <div className={styles.content}>
            <textarea
              className="form-control form-control-sm"
              id="description"
              rows="3"
              placeholder="Type the Description..."
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
            ></textarea>
          </div>
        </div>
      </div>
    </Container>
  );
});

export default NameAndDescription;
