import { useCallback, useMemo, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import db from "../../hooks/useMemoryDB";

import Container from "../container";
import SelectAll from "../selects/selectAll";

import styles from "./styles.module.scss";
const selectStyle = { width: "466px" };

export default function ViewAll(props) {
  const {
    data,
    propertyId,
    endpointId,
    onChange,
    folded,
    uniqueId,
    info,
    onlyWithAnalitica = false,
    pageBlockChooser = false,
    forceEditorial = false,
    foldable = true,
    color = "#12160a",
  } = props;

  const [variants, setVariants] = useState([]);
  const [title, setTitle] = useState();
  const [pageBlocks, setPageBlocks] = useState();

  const getVariants = useCallback((id, cb) => {
    const endpoint = db.get("endpoints", id);
    const ids = [endpoint.blockId];
    endpoint.variants?.forEach((v) => ids.push(v.blockId));
    setTitle(endpoint.title);
    const blocks = db.bulkGet("blocks", ids);
    setVariants(blocks.map((b) => ({ value: b.id, label: b.title })));
    if (cb) cb(endpoint);
  }, []);

  useEffect(() => {
    if (pageBlockChooser && data?.blockId) {
      const block = db.get("blocks", data.blockId);
      if (block?.blocks)
        setPageBlocks(block.blocks.map((b) => ({ value: b.id ?? b._id, label: b.title })));
    }
  }, [data?.blockId, pageBlockChooser]);

  useEffect(() => {
    if (data?.id && variants.length === 0) {
      getVariants(data.id);
    }
  }, [data?.id, getVariants, variants.length]);

  const handleEndpointChange = useCallback(
    (id) => {
      if (onChange) {
        if (!id) {
          onChange({});
          setTitle("");
        } else {
          getVariants(id, (endpoint) => {
            onChange({
              ...data,
              id,
              reference: endpoint.reference,
              blockId: undefined,
              subBlockId: undefined,
            });
          });
        }
      }
      setPageBlocks([]);
    },
    [data, getVariants, onChange]
  );

  const handleTypeChange = useCallback(
    (_, type) => {
      if (onChange) {
        onChange({
          ...data,
          type: type ? "automatic" : "editorial",
          blockId: type ? undefined : data.blockId,
        });
      }
    },
    [data, onChange]
  );

  const handleVariantChange = useCallback(
    (e) => {
      if (onChange) {
        const value = e.target.value;
        onChange({ ...data, blockId: value, subBlockId: undefined });
      }
      setPageBlocks([]);
    },
    [data, onChange]
  );

  const handlePageBlockChange = useCallback(
    (e) => {
      const value = e.target.value;
      onChange({ ...data, subBlockId: value });
    },
    [data, onChange]
  );

  const handleSiteMapChange = useCallback(
    (e) => {
      if (onChange) {
        const value = e.target.checked ? true : false;
        onChange({ ...data, siteMapped: value });
      }
    },
    [data, onChange]
  );

  const endpointFilters = useMemo(() => {
    const filters = { property: propertyId, exclude: [] };
    if (data?.id) filters.exclude.push(data.id);
    if (endpointId) filters.exclude.push(endpointId);
    if (onlyWithAnalitica) filters.analitica = true;
    if (info?.restricted) filters.canBeFallback = true;
    return filters;
  }, [data?.id, endpointId, info?.restricted, onlyWithAnalitica, propertyId]);

  return (
    <Container
      color={color}
      folded={folded}
      foldable={foldable}
      title={<div className={styles.title}>{props.title ?? "View All"}</div>}
      className={props.className}
      noMarginLast={props.noMarginLast}
      isButton={props.isButton}
      header={
        <div className={styles.header}>
          {!forceEditorial && data?.id && (
            <Switch
              bsSize="small"
              value={!data?.type || data?.type === "automatic" ? true : false}
              onText={<span className={styles.switchTitle}>Automatic</span>}
              onColor="dark"
              offText={<span className={styles.switchTitle}>Editorial</span>}
              offColor="success"
              onChange={handleTypeChange}
            />
          )}
          {title && (
            <>
              <div className={styles.label}>Endpoint</div>
              <div className={styles.endpointTitle}>{title}</div>
            </>
          )}
        </div>
      }
      headerOnlyOnFolded={false}
      extra={
        props.extra || (
          <Form.Check
            type="switch"
            id={`${uniqueId}-siteMap`}
            checked={data?.siteMapped}
            value="true"
            onChange={handleSiteMapChange}
            name={`${uniqueId}-siteMap`}
            label="Include in SiteMap"
          />
        )
      }
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.labelCol}>Endpoint</div>
          <div className={styles.contentCol}>
            <SelectAll
              accessor="endpoints"
              value={data?.id}
              filters={endpointFilters}
              handleChange={handleEndpointChange}
              startWithEmptyOption={true}
              style={selectStyle}
            />
          </div>
        </div>

        {(data?.type === "editorial" || props.forceEditorial) && variants.length > 0 && (
          <div className={styles.row}>
            <div className={styles.labelCol}>Variant</div>
            <div className={styles.contentCol}>
              <Form.Control
                as="select"
                style={selectStyle}
                onChange={handleVariantChange}
                value={data?.blockId}
                isInvalid={!forceEditorial && !data?.blockId}
              >
                <option></option>
                {variants.map((v, index) => (
                  <option value={v.value} key={`${v.value}-${index}`}>
                    {v.label}
                  </option>
                ))}
              </Form.Control>
            </div>
          </div>
        )}
        {pageBlockChooser && data?.blockId && (
          <div className={styles.row}>
            <div className={styles.labelCol}>Block</div>
            <Form.Control
              as="select"
              style={selectStyle}
              onChange={handlePageBlockChange}
              value={data?.subBlockId}
            >
              <option></option>
              {pageBlocks?.map((v, index) => (
                <option key={`${v.value}-${index}`} value={v.value}>
                  {v.label}
                </option>
              ))}
            </Form.Control>
          </div>
        )}
      </div>
    </Container>
  );
}
