import jwt from "jsonwebtoken";
import { aresUserTokenVerifyKey, aresUserLoginSignKey } from "../../keys";
import isEqual from "lodash.isequal";
import axios from "axios";

export const types = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_ERROR: "USER_LOGIN_ERROR",
  USER_LOGGED: "USER_LOGGED",
  USER_SELECT_TENANT: "USER_SELECT_TENANT",
  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGGED_STATUS: "USER_LOGGED_STATUS",
  USER_LOGGED_USERS: "USER_LOGGED_USERS",
};

const apiEndp = process.env.REACT_APP_API_ENDPOINT;

async function createLoginToken(username, password) {
  const iss = "ares-control-center";
  const iat = Math.floor(Date.now() / 1000);
  const exp = iat + 60;
  const data = { username, password, iss, iat, exp };

  const loginToken = jwt.sign(data, aresUserLoginSignKey, { algorithm: "RS256" });

  return loginToken;
}

export function userLoginRequest(username, password, cb, errorCb) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.USER_LOGIN_REQUEST,
    });

    const loginToken = await createLoginToken(username, password);

    axios
      .get(`${apiEndp}/login`, { headers: { loginToken } })
      .then(async ({ data }) => {
        if (data) {
          const decoded = await jwt.verify(data, aresUserTokenVerifyKey, { algorithms: ["RS256"] });

          dispatch(userLogged({ ...decoded, id_token: data }));
          cb(data);
        } else dispatch({ type: types.USER_LOGIN_ERROR });
      })
      .catch((e) => {
        console.log(e);
        errorCb && errorCb(e.message);
      });
  };
}

export function setLoggedStatus(status) {
  return async (dispatch, getState) => {
    const state = getState();
    const loggedStatus = state.user.loggedStatus;

    if (!isEqual(status, loggedStatus))
      dispatch({ type: types.USER_LOGGED_STATUS, payload: status });
  };
}

export function setLoggedUsers(status) {
  return async (dispatch, getState) => {
    const state = getState();
    const loggedUsers = state.user.loggedUsers;

    if (!isEqual(status, loggedUsers)) dispatch({ type: types.USER_LOGGED_USERS, payload: status });
  };
}

export function userLogged(user) {
  return {
    type: types.USER_LOGGED,
    payload: user,
  };
}

export function userLogOut(w) {
  return (dispatch, getState) => {
    window.document.title = window.document.title.split(" : ")[0];
    dispatch({ type: types.USER_LOGOUT });
  };
}

export function userSelectTenant(tenant, user) {
  return (dispatch, getState) => {
    dispatch({
      type: types.USER_SELECT_TENANT,
      payload: tenant,
    });
  };
}
