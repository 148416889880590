import PackChooser from "./packChooser";
import Cache from "./cache";
import Pipelines from "./pipelines";
import Output from "./output";
import Preview from "./preview";

export default function MetaPack() {
  return (
    <>
      <PackChooser />
      <Cache />
      <Pipelines />
      <Output />
      <Preview />
    </>
  );
}
