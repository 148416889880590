import { Form } from "react-bootstrap";

export default function KeepOrder(props) {
  const { rootNodePath, execute, handleChange } = props;

  return (
    <Form.Check
      type="switch"
      value={true}
      checked={execute?.keepOrder || false}
      onChange={(e) => handleChange("keepOrder", e)}
      name={`${rootNodePath}execute.otherFields.keepOrder`}
      id={`${rootNodePath}execute.otherFields.keepOrder`}
      label="Keep Order"
    />
  );
}
